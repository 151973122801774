import React from 'react';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import LayoutWrapperAuth from '../../component/LayoutWrapperAuth';
import PropTypes from 'prop-types';
import ForgotpasswordForm from './forgetpasswordForm/forgetPasswordForm';
import { submitForgotpassword, doForgotpasswordRes } from '../../actions/forgotPasswordActions';
import styles from '../login/login-jss';

class ForgetPasswordComponent extends React.PureComponent {

    constructor() {
        super();
        this.state = {
        }
    }


    handleSubmit(data) {
        let sendRequest = {
            "email" : data.email
        }
        this.props.handleFormSubmit(sendRequest);
    };
    render() {
        const { classes } = this.props;
        return (
            <LayoutWrapperAuth>
                <div className={classes.root}>
                    <Helmet>
                        <title>Forgot Password</title>
                    </Helmet>
                    <div className={classes.container}>
                        <div className={classes.userFormWrap}>
                            <ForgotpasswordForm handleFormSubmit={(data) => { this.handleSubmit(data) }} />
                        </div>
                    </div>
                </div>
            </LayoutWrapperAuth>
        )
    }
}


ForgetPasswordComponent.propTypes = {
    handleFormSubmit: PropTypes.func,
    forgotpasswordRes: PropTypes.any
};

const mapStateToProps = createStructuredSelector({
    forgotpasswordRes: doForgotpasswordRes
});

function mapDispatchToProps(dispatch) {
    return {
        handleFormSubmit: (data) => dispatch(submitForgotpassword(data))
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const forgotComp = compose(withConnect)(ForgetPasswordComponent);

export default withStyles(styles)(forgotComp);