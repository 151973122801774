import * as types from './actionTypes';
import sendPendingBrokerDealerApi from '../api/sendPendingBrokerDealerApi';
import { logout } from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doSendPendingBrokerDealer(request) {
    return {
        type: types.DO_SEND_PENDING_BROKERDEALER,
        request
    };
}

export function doSendPendingBrokerDealerRes(data) {
    return {
        type: types.DO_SEND_PENDING_BROKERDEALER_RES,
        data
    };
}

export function sendPendingBrokerDealer(request) {
    const TOKEN = getItem('auth_token');

    if (TOKEN) {
        return function (dispatch) {
            dispatch(loadingRes(true));
            sendPendingBrokerDealerApi.doSendPendingBrokerDealer(request).then(data => {
                if (data.error === 0) {
                    dispatch(doSendPendingBrokerDealerRes(data));
                    dispatch(doSendPendingBrokerDealerRes(null));
                }
                dispatch(loadingRes(false));
                if (data.error) {
                    Swal.fire({
                        title: data.message,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,
                        timer: 3000,
                        heightAuto: false
                    });
                }
            }).catch(error => {
                dispatch(loadingRes(false));
                return error
            });
        };
    } else {
        logout()
    }

}