import * as types from './actionTypes';  
import customerListApi from '../api/customerListApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doFetchCustomerList(request, isLoad) {

  return {
    type: types.DO_FETCH_ALL_CUSTOMERS,
    request, 
    isLoad
  };
}

export function doFetchCustomerListRes(data) {  
  return {
    type: types.DO_FETCH_ALL_CUSTOMERS_RES, 
    data
  };
}

export function fetchAllCustomers(request, isLoad) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      if (isLoad) {
        dispatch(loadingRes(true));
      }
      customerListApi.doFetchCustomerList(request).then(data => {
        dispatch(doFetchCustomerListRes(data));
        dispatch(doFetchCustomerListRes(null));
        if (isLoad) {
          dispatch(loadingRes(false));
        }
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        if (isLoad) {
          dispatch(loadingRes(false));
        }
        return error
      });
    };
  }else{
    logout()
  }
  
}

export function doFetchConnectedCustomerList(request, isLoad) {

  return {
    type: types.DO_FETCH_CONNECTED_CUSTOMERS, 
    request, isLoad
  };
}

export function doFetchConnectedCustomerListRes(data) {  
  return {
    type: types.DO_FETCH_CONNECTED_CUSTOMERS_RES, 
    data
  };
}

export function fetchConnectedCustomers(request, isLoad) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      if (isLoad) {
        dispatch(loadingRes(true));
      }
      customerListApi.doFetchConnectedCustomerList(request).then(data => {
        dispatch(doFetchConnectedCustomerListRes(data));
        dispatch(doFetchConnectedCustomerListRes(null));
        if (isLoad) {
          dispatch(loadingRes(false));
        }
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        if (isLoad) {
          dispatch(loadingRes(false));
        }
        return error
      });
    };
  }else{
    logout()
  }
  
}

export function doFetchNonConnectedCustomerList(request, isLoad) {

  return {
    type: types.DO_FETCH_NONCONNECTED_CUSTOMERS, 
    request, isLoad
  };
}

export function doFetchNonConnectedCustomerListRes(data) {  
  return {
    type: types.DO_FETCH_NONCONNECTED_CUSTOMERS_RES, 
    data
  };
}

export function fetchNonConnectedCustomers(request, isLoad) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      if (isLoad) {
        dispatch(loadingRes(true));
      }
      customerListApi.doFetchNonConnectedCustomerList(request).then(data => {
        dispatch(doFetchNonConnectedCustomerListRes(data));
        dispatch(doFetchNonConnectedCustomerListRes(null));
        if (isLoad) {
          dispatch(loadingRes(false));
        }
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        if (isLoad) {
          dispatch(loadingRes(false));
        }
        return error
      });
    };
  }else{
    logout()
  }
  
}

export function doFetchDeletedCustomerList(request, isLoad) {

  return {
    type: types.DO_FETCH_DELETED_CUSTOMERS, 
    request, isLoad
  };
}

export function doFetchDeletedCustomerListRes(data) {  
  return {
    type: types.DO_FETCH_DELETED_CUSTOMERS_RES, 
    data
  };
}

export function fetchDeletedCustomers(request, isLoad) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      if (isLoad) {
        dispatch(loadingRes(true));
      }
      customerListApi.doFetchDeletedCustomerList(request).then(data => {
        dispatch(doFetchDeletedCustomerListRes(data));
        dispatch(doFetchDeletedCustomerListRes(null));
        if (isLoad) {
          dispatch(loadingRes(false));
        }
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        if (isLoad) {
          dispatch(loadingRes(false));
        }
        return error
      });
    };
  }else{
    logout()
  }
  
}