import * as types from '../actions/actionTypes'; 
import initialState from './initialState';

export default function (state = initialState.user, action) { //we will change the state = {} soon
    switch(action.type) {
        case types.DO_CREATE_ISSUER_RES:
            return action.data
        default:
            return state
    }
}
