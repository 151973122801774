import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import ThemeWrapper from './component/LayoutWrapper';
import Loader from "react-loader";
import { loadingRes } from './actions/loadingActions';
import LoginComponent from './container/login/login';
import ForgetPasswordComponent from './container/forgetpassword/forgetPassword';
import ResetPasswordComponent from './container/passwordreset/reset-password';
import ChangePasswordComponent from './container/changepassword/changePassword';
import OfferingComponent from './container/offerings/offerings';
import CreateOfferingComponent from './container/createoffering/createOffering';
import UpdateOfferingComponent from './container/updateOffering/updateOffering';
import OrdersComponent from './container/orders/orders';
import AllocationComponent from './container/allocations/allocations';
import ShowAllocationsList from './container/allocations/showAllocationList';
import StatsComponent from './container/stats/stats';
import StatsOfferingBuildOut from './container/stats/buildout';
import AdminComponent from './container/admin/admin';
import BrokerDealerAdminList from './container/admin/brokerdealers/brokerDealerAdmins';
import CreateBrokerAdmin from './container/admin/brokerdealers/createbrokerdealeradmin';
import CreateUnderwriterAdmin from './container/admin/underwriters/createUnderwriterAdmin';
import UnderwriterAdminList from './container/admin/underwriters/underwritersAdmins';
import CreateUnderwriterForm from './container/admin/underwriters/createUnderwriter';
import CreateBrokerdealerForm from './container/admin/brokerdealers/createbrokerdealer';
import UpdateMessageForm from './container/admin/messages/updatemessage';
import AddMessageForm from './container/admin/messages/addmessage';
import CustomersComponent from './container/customers/customers';
import CreateAllocationForm from './container/allocations/createallocation';
import UpdateAllocationForm from './container/allocations/updateallocation';
import PricingWireComponent from './container/allocations/pricingWire';
import BrokerDealerCustomerGraph from './container/customers/bdGraph';
import BrokerOrdersComponent from './container/orders/brokerOrdersList';
import OfferingCallBacksComponent from './container/offerings/offeringCallBacks';
import UpdateWhiteListedIPForm from './container/admin/whiteListedIPs/updateWhiteListedIP';
import AddWhiteListedIPForm from './container/admin/whiteListedIPs/addWhiteListedIP';
import TokenError from './utils/invalidTokenError';
import { getItem } from './utils/localStore';
import IdleTimer from 'react-idle-timer';
import Swal from 'sweetalert2';
import { logout } from './helper/helper';
import IssuerComm from './container/issuerComm/issuerComm';
import CreateIssuerComm from './container/issuerComm/createIssuerComm';
import UpdateIssuerComm from './container/issuerComm/updateIssuerComm';
// import LoaderSpinner from './assets/images/spinner.gif';
// import NetworkDetector from './utils/NetworkDetector';
const TOKEN = getItem('auth_token');
const LEVEL = getItem('level');
class App extends React.PureComponent {
	constructor() {
		super()

		this.state = {
			isLoader: true,
			isLoadering: false,
			timeout: 1000 * 60 * 30,
			showModal: false,
			userLoggedIn: false,
			isTimedOut: false
		}

		this.idleTimer = null
		this.onAction = this._onAction.bind(this)
		this.onActive = this._onActive.bind(this)
		this.onIdle = this._onIdle.bind(this)
	}
	_onAction(e) {
		this.setState({
			isTimedOut: false
		})
	}

	_onActive(e) {
		this.setState({
			isTimedOut: false
		})
	}

	_onIdle(e) {
		const isTimedOut = this.state.isTimedOut
		if (isTimedOut) {
			logout();
		} else {
			this.setState({
				showModal: true
			})
			this.idleTimer.reset();
			this.setState({
				isTimedOut: true
			})
			Swal.fire({
				// position: 'top-end',
				icon: 'info',
				title: '<strong style="font-size: 24px;padding-top: 20px;padding-bottom: 20px;">You are being logged out due to inactivity</strong>',
				padding: '3em',
				width: 400,
				showConfirmButton: false,
				allowOutsideClick: true,
				timer: 30000,
				heightAuto: false,
				onClose: () => {
					logout();
				}
			}).then((result) => {
				logout();
			})
		}

	}

	static getDerivedStateFromProps(props, state) {
		if (props.loadingRes) {
			if (props.loadingRes.data) {
				if (props.loadingRes.data.isLoading === true || props.loadingRes.data.isLoading === false) {
					if (props.loadingRes.data.isLoading === false) {
						return {
							isLoader: true
						};
					} else {
						return {
							isLoader: false
						};
					}
				}

			}
		}
		return null
	}
	render() {
		
		let redirectURL = "/?red_url=" + window.location.href;
		// const spinner = <span><img src={LoaderSpinner} alt="" /></span>;
		var options = {
			lines: 13,
			length: 10,
			width: 5,
			radius: 20,
			corners: 1,
			rotate: 0,
			direction: 1,
			color: "#fff",
			speed: 1,
			trail: 60,
			shadow: false,
			hwaccel: false,
			zIndex: 2e9,
			top: "50%",
			left: "50%",
			scale: 1.0,
			loadedClassName: "loadedContent"
		};
		if (!TOKEN) {
			return (
				<div style={{ height: '100%' }} className="with-auth">
					<Loader
						loaded={this.state.isLoader}
						options={options}
						className="spinner"
					></Loader>
					<ThemeWrapper>
						<React.Fragment>
							<BrowserRouter>
								<Switch>
									<Route exact={true} path="/" component={LoginComponent} />
									<Route path="/forgetpassword" component={ForgetPasswordComponent} />
									<Route path="/reset-password" component={ResetPasswordComponent} />
									<Route path="/changepassword" component={ChangePasswordComponent} />
									<Route path="/invalidToken" component={TokenError} />
									<Route render={() => (<Redirect to={redirectURL} />)} />
								</Switch>
							</BrowserRouter>
						</React.Fragment>
					</ThemeWrapper>
					{/* </Loader> */}
				</div>
			)
		}
		if (TOKEN && (LEVEL === 0 || LEVEL === '0' || LEVEL === 3 || LEVEL === '3')) {
			return (
				<div style={{ height: '100%' }}>
					<Loader
						loaded={this.state.isLoader}
						options={options}
						className="spinner"
					></Loader>
					<IdleTimer
						ref={ref => { this.idleTimer = ref }}
						element={document}
						onActive={this.onActive}
						onIdle={this.onIdle}
						onAction={this.onAction}
						debounce={250}
						timeout={this.state.timeout} />
					<div style={{ height: '100%' }} className="without-auth">
						{/* <Loader show={this.state.isLoadering} message={spinner}> */}
						<ThemeWrapper>
							<React.Fragment>
								<BrowserRouter>
									<Switch>
										<Route path="/offerings" component={OfferingComponent} />
										<Route path="/create-offering" component={CreateOfferingComponent} />
										<Route path="/update-offering/:id" component={UpdateOfferingComponent} />
										<Route path="/orders/:id" component={OrdersComponent} />
										<Route path="/orders-buildout/:id" component={StatsOfferingBuildOut} />
										<Route path="/brokerdealer-order/:id" component={BrokerOrdersComponent} />
										<Route path="/offering-callbacks/:id" component={OfferingCallBacksComponent} />
										<Route path="/allocations" component={AllocationComponent} />
										<Route path="/show-allocation/:id" component={ShowAllocationsList} />
										<Route path="/create-allocation" component={CreateAllocationForm} />
										<Route path="/allocation-detail/:id" component={UpdateAllocationForm} />
										<Route path="/pricing-wire" component={PricingWireComponent} />
										<Route path="/stats" component={StatsComponent} />
										<Route path="/buildout/:id" component={StatsOfferingBuildOut} />
										<Route path="/system-setting" component={AdminComponent} />
										<Route path="/brokerdealer-admin" component={BrokerDealerAdminList} />
										<Route path="/create-broker-admin" component={CreateBrokerAdmin} />
										<Route path="/create-underwriter-admin" component={CreateUnderwriterAdmin} />
										<Route path="/underwriter-admin" component={UnderwriterAdminList} />
										<Route path="/create-brokerdealer" component={CreateBrokerdealerForm} />
										<Route path="/create-underwriter" component={CreateUnderwriterForm} />
										<Route path="/create-message" component={AddMessageForm} />
										<Route path="/update-message/:id" component={UpdateMessageForm} />
										<Route path="/create-whitelistedIP" component={AddWhiteListedIPForm} />
										<Route path="/update-whitelistedIP" component={UpdateWhiteListedIPForm} />
										<Route path="/customers" component={CustomersComponent} />
										<Route path="/customer-graph" component={BrokerDealerCustomerGraph} />
										<Route path="/issuers" component={IssuerComm} />
										<Route path="/create-issuer" component={CreateIssuerComm} />
										<Route path="/edit-issuer" component={UpdateIssuerComm} />
										
										<Route path="/invalidToken" component={TokenError} />
										<Route render={() => (<Redirect to="/offerings" />)} />
									</Switch>
								</BrowserRouter>
							</React.Fragment>
						</ThemeWrapper>
						{/* </Loader> */}
					</div>
				</div>
			)
		}
		if (TOKEN && (LEVEL !== 1 || LEVEL !== '1')) {
			return (
				<div style={{ height: '100%' }}>
					<Loader
						loaded={this.state.isLoader}
						options={options}
						className="spinner"
					></Loader>
					<IdleTimer
						ref={ref => { this.idleTimer = ref }}
						element={document}
						onActive={this.onActive}
						onIdle={this.onIdle}
						onAction={this.onAction}
						debounce={250}
						timeout={this.state.timeout} />
					<div style={{ height: '100%' }} className="without-auth">
						<ThemeWrapper>
							<React.Fragment>
								<BrowserRouter>
									<Switch>
										<Route path="/system-setting" component={AdminComponent} />
										<Route path="/customers" component={CustomersComponent} />
										<Route path="/customer-graph" component={BrokerDealerCustomerGraph} />
										<Route path="/brokerdealer-admin" component={BrokerDealerAdminList} />
										<Route path="/create-broker-admin" component={CreateBrokerAdmin} />
										<Route path="/create-underwriter-admin" component={CreateUnderwriterAdmin} />
										<Route path="/underwriter-admin" component={UnderwriterAdminList} />
										<Route path="/create-brokerdealer" component={CreateBrokerdealerForm} />
										<Route path="/create-underwriter" component={CreateUnderwriterForm} />
										<Route path="/create-message" component={AddMessageForm} />
										<Route path="/update-message/:id" component={UpdateMessageForm} />
										<Route path="/invalidToken" component={TokenError} />
										<Route render={() => (<Redirect to={'/customers'} />)} />
									</Switch>
								</BrowserRouter>
							</React.Fragment>
						</ThemeWrapper>
					</div>
				</div>
			)
		}
		if (TOKEN && (LEVEL !== 2 || LEVEL !== '2')) {
			return (
				<div style={{ height: '100%' }}>
					<Loader
						loaded={this.state.isLoader}
						options={options}
						className="spinner"
					></Loader>
					<IdleTimer
						ref={ref => { this.idleTimer = ref }}
						element={document}
						onActive={this.onActive}
						onIdle={this.onIdle}
						onAction={this.onAction}
						debounce={250}
						timeout={this.state.timeout} />
					<div style={{ height: '100%' }} className="without-auth">
						<ThemeWrapper>
							<React.Fragment>
								<BrowserRouter>
									<Switch>
										<Route path="/customers" component={CustomersComponent} />
										<Route path="/invalidToken" component={TokenError} />
										<Route render={() => (<Redirect to={'/customers'} />)} />
									</Switch>
								</BrowserRouter>
							</React.Fragment>
						</ThemeWrapper>
					</div>
				</div>
			)
		}
	}
}

App.propTypes = {
	loadingRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	loadingRes: loadingRes,
});

function mapDispatchToProps(dispatch) {
	return {

	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(App);
