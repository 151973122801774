import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import '../admin.scss';
import { getItem } from '../../../utils/localStore';
import { fetchMessageList, doMessageListRes } from '../../../actions/messageListActions';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import GridTable from '@nadavshaar/react-grid-table';
import FileCopyIcon from '@material-ui/icons/FileCopy';
let isMessageList = false;
class MessagesComponent extends React.PureComponent {
	constructor(props) {
		super(props);
		isMessageList = false;
		const filterByAllMessage = '';
		const columns = [
			{ id: 1, width: '33.33%', field: 'short_code', label: 'Short-Code', visible: true, sortable: true, cellRenderer: this.shortcodefeild },
			{ id: 2, width: '33.33%', field: 'name', label: 'Name', visible: true, sortable: false, cellRenderer: this.messagenamefeild },
			{ id: 3, width: '33.33%', field: 'is_mandatory', label: 'Is-Mandatory', visible: true, sortable: false, cellRenderer: this.messagemandatoryfeild }
		];
		this.state = {
			cols: columns,
			isReadOnly: JSON.parse(getItem('isReadOnly')),
			searchingMessage: null,
			filterByAllMessage: filterByAllMessage,
			sortField: 'short_code',
			sortOrder: 1,
			messageList: []
		}
	}

	componentDidMount() {
		isMessageList = true;
		this.props.fetchMessageList();
	}

	static getDerivedStateFromProps(props, state) {
		if (props.messageListRes) {
			if (props.messageListRes.data && props.messageListRes.data.messageList) {
				if (props.messageListRes.data.messageList.message === 'Success' && isMessageList) {
					isMessageList = false;
					let arrData = props.messageListRes.data.messageList.data;
                    for (let i = 0; i < arrData.length; i++) {

                        Object.keys(arrData[i]).forEach((field) => { /*eslint-env es6*/
                        if (arrData[i][field] === null) {
                            arrData[i][field] = "";
                        }
                        })
                    };
					return {
						messageList: arrData
					};
				}
			}
		}
		return null
	}
	searchMessageByTitle = (event) => {
		this.setState({
			searchingMessage: event.target.value,
		});
	}
	shortcodefeild = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return <div className="rgt-cell-inner">{data.short_code}</div>;
	}
	goUpdate = (data) => {
		this.props.history.push({
			pathname: `/update-message/${data.short_code}`,
			state: {
				tabIndex: this.props.tabIndex
			}
		})
	}
	messagenamefeild = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		if (!this.state.isReadOnly) {
			return (
				<div className='rgt-cell-inner' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
					<Tooltip title={value !== undefined ? value : ''} interactive >
						<div className="text-one-line" style={{width: '90%', color: '#8DC73F', cursor: 'pointer'}} onClick={() => this.goUpdate(data)}>{value}</div>
					</Tooltip>
					{
						value !== '' &&
						<Tooltip title={'Copy to clipboard'} interactive >
							<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small"/> </Button>
						</Tooltip>
					}
				</div>
			)
		}
		if (this.state.isReadOnly) {
			return (
				<div className='rgt-cell-inner' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
					<Tooltip title={value !== undefined ? value : ''} interactive >
						<div className="text-one-line" style={{width: '90%'}} >{value}</div>
					</Tooltip>
					{
						value !== '' &&
						<Tooltip title={'Copy to clipboard'} interactive >
							<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small"/> </Button>
						</Tooltip>
					}
				</div>
			)
		}
	}
	messagemandatoryfeild = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		if (data.is_mandatory === 1) {
			return <div className="rgt-cell-inner">True</div>;
		} else {
			return <div className="rgt-cell-inner">False</div>;
		}
	}
	dropDownSearchByAllMesssage = (event) => {
		this.setState({
			filterByAllMessage: event.target.value,
		}, () => {
		});
	}
	
	goToCreateMessage = (AllocationInfo) => {
		this.props.history.push({
			pathname: '/create-message',
			state: {
				tabIndex: this.props.tabIndex
			}
		})
	}
	render() {
		const { messageList, filterByAllMessage, isReadOnly } = this.state;
		let filterMessage = [];
		if (messageList) {
			if (filterByAllMessage === 'name') {
				filterMessage = this.state.searchingMessage ? messageList.filter((row) => (row.name.toLowerCase()).indexOf(this.state.searchingMessage.toLowerCase()) > -1) : messageList;
			}
			else if (filterByAllMessage === 'shortcode') {
				filterMessage = this.state.searchingMessage ? messageList.filter((row) => (row.short_code.toLowerCase()).indexOf(this.state.searchingMessage.toLowerCase()) > -1) : messageList;
			} else {
				// filterMessage = messageList.sort((a, b) =>{Number(a.short_code) - Number(b.short_code)});
				filterMessage = messageList;
			}
		}  
		return (
			<div className="newMessageListStyle">
				<div>
					<div className="row">
						<div className="col-md-12">
							<div style={{textAlign: 'right'}}>
								{
									(!isReadOnly) &&
									<Button onClick={() => this.goToCreateMessage()} variant="contained" color="primary">Add New Message</Button>
								}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12 message-system-setting-table" style={{ paddingTop: 15, paddingBottom: 15 }}>
							<GridTable
								columns={this.state.cols}
								rows={filterMessage}
								isPaginated={true}
								isVirtualScroll={true}
								showSearch={true}
								showRowsInformation={false}
								isHeaderSticky={true}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

MessagesComponent.propTypes = {
	messageListRes: PropTypes.any,
	history: PropTypes.any,
};
const mapStateToProps = createStructuredSelector({
	messageListRes: doMessageListRes,
});
function mapDispatchToProps(dispatch) {
	return {
		fetchMessageList: () => dispatch(fetchMessageList()),
	};
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(MessagesComponent);
