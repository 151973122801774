import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import './passwordReset.css';
import { reset_password, doResetpasswordRes } from '../../actions/resetPasswordActions';
import ResetPasswordForm from './resetpasswordForm/resetpasswordForm';
import { withStyles } from '@material-ui/core/styles';
import LayoutWrapperAuth from '../../component/LayoutWrapperAuth';
import styles from '../login/login-jss';
class ResetPasswordComponent extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            value:'',
            resetpasswordtoken:''
        }
    }

    componentDidMount() {
        let pathArray = this.props.location.pathname.split('/');
        this.setState({
            resetpasswordtoken : pathArray[2],
        })
        
    }

    handleSubmit(data){
        let sendRequest = data;
        sendRequest.reset_password_token = this.state.resetpasswordtoken;
        this.props.handleFormSubmit(sendRequest);
    }

    render() {
        const { classes } = this.props;
        return (
            <LayoutWrapperAuth>
                <div className={classes.root}>
                    <Helmet>
                        <title>Reset password</title>
                    </Helmet>
                    <div className={classes.container}>
                        <div className={classes.userFormWrap}>
                            <ResetPasswordForm handleFormSubmit={(data) => { this.handleSubmit(data) }} />
                        </div>
                    </div>
                </div>
            </LayoutWrapperAuth>
        )
    }
}


ResetPasswordComponent.propTypes = {
    handleFormSubmit: PropTypes.func,
    resetpasswordRes: PropTypes.any
};

const mapStateToProps = createStructuredSelector({
    resetpasswordRes: doResetpasswordRes
});

function mapDispatchToProps(dispatch) {
    return {
        handleFormSubmit: (data) => dispatch(reset_password(data))
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const resetComp = compose(withConnect)(ResetPasswordComponent);

export default withStyles(styles)(resetComp);
