
import { getItem } from '../utils/localStore';

export const logout=() =>{
    let orderColsArr = localStorage.getItem('columnArrOrder');
    let colsArr = localStorage.getItem('columnArr');
    localStorage.clear();
    localStorage.setItem('columnArrOrder', orderColsArr);
    localStorage.setItem('columnArr', colsArr);
    window.location.href = '/';
}

export const checkLogin=() =>{
    const TOKEN = getItem('auth_token');
    if(TOKEN !== undefined || TOKEN !== null){
        return false;
    }else{
        return true;
    }
}
export function dateTimeFormat(datetime) {
    if (datetime !== null && datetime !== '' && datetime !== undefined) {
      let d = new Date (datetime);
      return d.getFullYear() + '-' +
      ('00' + (d.getMonth() + 1)).slice(-2) + '-' +
      ('00' + d.getDate()).slice(-2) + ' ' +
      ('00' + d.getHours()).slice(-2) + ':' +
      ('00' + d.getMinutes()).slice(-2) + ':' +
      ('00' + d.getSeconds()).slice(-2);
    } else {
      return 'N/A';
    }
}
export function dateTimeFormatUS(datetime) {
    if (datetime !== null && datetime !== '' && datetime !== undefined) {
      let d = new Date (datetime);
      return ('00' + (d.getMonth() + 1)).slice(-2) + '/' +
      ('00' + d.getDate()).slice(-2) + '/' + d.getFullYear()
    } else {
      return 'N/A';
    }
}

export function numberWithCommas(number)  {
    return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function dateFormat(date){
    if (!date) return null;
    let dateNew = {date};
    var monthNames = [
        'Jan', 'Feb', 'Mar',
        'Apr', 'May', 'Jun', 'Jul',
        'Aug', 'Sep', 'Oct',
        'Nov', 'Dec',
    ];
    let splitTimeDate = dateNew.date.split(' ')
    let splitDate = splitTimeDate[0].split('-')
    let day = splitDate[2];
    let monthIndex = splitDate[1];
    return  monthNames[monthIndex - 1] + ' ' + day;
}

export function numberWithCommasAndDollar(number)  {
    if (number !== null && number !== undefined) {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number)
    } else {
        return 'NA'
    }
}

export function loadScript () {    
    var tag = document.createElement('script');
    tag.async = false;
    tag.innerHTML = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'UA-87376082-2');";
    document.head.appendChild(tag);
}

export function convertDateFormat(datestring){
  if (datestring !== null && datestring !== '' && datestring !== undefined) {
    return datestring.substr(5, 2) + '/' + datestring.substr(8, 2) + '/' + datestring.substr(0, 4);
  } else {
    return "N/A";
  }
}
export function convertDateTimeFormat(datestring){


  if(datestring !== null && datestring !== "" && datestring !== undefined){
    return datestring.substr(5,2) + '/' + datestring.substr(8,2) + '/' + datestring.substr(0,4);
  } else{
    return "Not Available";
  }
}
export function getOfferingPriceRange(offering) {
  if (offering.offering_type_name === 'IPO') {
   
    if ((offering.max_price === null || offering.max_price === 0) && (offering.min_price ===
       null || offering.min_price === 0)) {
      return 'TBD';
    } else {
      return `$${offering.min_price} - $${offering.max_price}`;
    }
  } else {
    if (offering.max_price === null || offering.max_price < 1) {
      return 'TBD';
    } else {
      return `$${offering.max_price}`;
    }
  }
}
export function getDate(offering) {
  if (offering.trade_date !== null) {
    return formatDate(offering.trade_date);
    
  } else {
    return 'TBD';
  }
}
export function formatDate(selectdate) {
  let date = new Date(selectdate);
  var monthNames = [
    'Jan', 'Feb', 'Mar',
    'Apr', 'May', 'Jun', 'Jul',
    'Aug', 'Sep', 'Oct',
    'Nov', 'Dec',
  ];

  let day = date.getDate();
  let monthIndex = date.getMonth();
  let year = date.getFullYear();

  return day + ' ' + monthNames[monthIndex] + ', ' + year;
}
export function currency(num) {
  return parseFloat(Math.round(num * 100) / 100).toFixed(2);
}
export function dateTimeFormatConvert(datestring) {

  if (datestring !== null && datestring !== "" && datestring !== undefined) {
    return datestring.substr(5, 2) + '/' + datestring.substr(8, 2) + '/' + datestring.substr(0, 4) + ' ' + datestring.substr(11, 5);
  } else {
    return "N/A";
  }
}
export function validatePrice(price, entry) {
  if (price.max == null && price.min == null) {
    return false;
  } else if (price.min == null && price.max != null) {
    let percent = (price.max / 100) * 20;
    if ((entry > price.max - percent) && (entry < price.max + percent)) {
      return true;
    } else {
      return false;
    }
  } else {
    let Maxpercent=(price.max / 100) * 20;
    let MinPercent=(price.min / 100) * 20;
    if ((entry > price.min - MinPercent) && ( entry < price.max + Maxpercent)) {
      return true;
    } else {
      return false;
    }
  }
}
export function getAllocationPriceRangeNum(allocation) {
  let max=0,min=0;
    if (allocation.anticipated_prices) {
      if(allocation.anticipated_prices.max_price==null)
      {
        max=null
      }
      else{
        max=allocation.anticipated_prices.max_price

      }
      if(allocation.anticipated_prices.min_price==null)
      {
        min=null
      }
      else{
        min= allocation.anticipated_prices.min_price

      }
      return {min,max};
    }

}
export function getAllocationUpdatePriceRangeNum(allocation) {
  let max=0,min=0;
    if (allocation.offering_price_attributes) {
      if(allocation.offering_price_attributes.max_price==null)
      {
        max=null
      }
      else{
        max=allocation.offering_price_attributes.max_price

      }
      if(allocation.offering_price_attributes.min_price==null)
      {
        min=null
      }
      else{
        min= allocation.offering_price_attributes.min_price

      }
      return {min,max};
    }

}
export function dollarAmountFormat (input) {
  if(input) {
      if(input.length>0){
          return '$' +parseFloat(input[0]).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      } else {
          return '$' +parseFloat(input[0]).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      }
  } else {
      return '$0.00'
  }
}
export function getCommaSeparatedTwoDecimalsNumber(number) {
  const fixedNumber = Number.parseFloat(number).toFixed(2);
  return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export async function saveFile(blob, suggestedName){
  // Feature detection. The API needs to be supported
  // and the app not run in an iframe.

  if (navigator.userAgent.includes("Firefox") || navigator.userAgent.includes("Mozilla")) {
    console.log(navigator.userAgent);
    const blobURL = URL.createObjectURL(blob);
      // Create the `` element and append it invisibly.
      const a = document.createElement("a");
      a.href = blobURL;
      a.download = suggestedName;
      a.style.display = "none";
      document.body.append(a);
      // Click the element.
      a.click();
      // Revoke the blob URL and remove the element.
      setTimeout(() => {
        URL.revokeObjectURL(blobURL);
        a.remove();
      }, 1000);
  } else {
    try {
      const supportsFileSystemAccess =
        "showSaveFilePicker" in window &&
        (() => {
          try {
            return window.self === window.top;
          } catch {
            return false;
          }
        })();
      // If the File System Access API is supported…
      if (supportsFileSystemAccess) {
        try {
          // Show the file save dialog.
          const handle = await window.showSaveFilePicker({
            suggestedName,
          });
          // Write the blob to the file.
          const writable = await handle.createWritable();
          await writable.write(blob);
          await writable.close();
          return;
        } catch (err) {
          // Fail silently if the user has simply canceled the dialog.
          if (err.name !== "AbortError") {
            console.error(err.name, err.message);
            return;
          }
        }
      }
    } catch (err) {
      const blobURL = URL.createObjectURL(blob);
      // Create the `` element and append it invisibly.
      const a = document.createElement("a");
      a.href = blobURL;
      a.download = suggestedName;
      a.style.display = "none";
      document.body.append(a);
      // Click the element.
      a.click();
      // Revoke the blob URL and remove the element.
      setTimeout(() => {
        URL.revokeObjectURL(blobURL);
        a.remove();
      }, 1000);
    }
    
  }
  // Fallback if the File System Access API is not supported…
  // Create the blob URL.
 
};