import * as types from './actionTypes';  
import adminUnderWriterMpidApi from '../api/adminUnderWriterMpidApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function adminUnderWriterMpidList(entity, mpid) {

  return {
    type: types.ADMIN_UNDERWRITER_MPID_LIST, 
    entity, mpid
  };
}

export function adminUnderWriterMpidListRes(data) {  
  return {
    type: types.ADMIN_UNDERWRITER_MPID_LIST_RES, 
    data
  };
}

export function getAdminUnderwriterMpid(entity, mpid) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      adminUnderWriterMpidApi.adminUnderWriterMpidList(entity, mpid).then(data => {
        dispatch(adminUnderWriterMpidListRes(data));
        dispatch(adminUnderWriterMpidListRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
}
