import * as types from './actionTypes';  
import customerOrderInfoApi from '../api/customerOrderInfoApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doFetchCustomerOrderInfo(email) {

  return {
    type: types.DO_FETCH_CUSTOMER_ORDER_INFO, 
    email
  };
}

export function doFetchCustomerOrderInfoRes(data) {  
  return {
    type: types.DO_FETCH_CUSTOMER_ORDER_INFO_RES, 
    data
  };
}

export function fetchCustomerOrderInfo(email) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      customerOrderInfoApi.doFetchCustomerOrderInfo(email).then(data => {
        dispatch(doFetchCustomerOrderInfoRes(data));
        dispatch(doFetchCustomerOrderInfoRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
  
}