import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import validate from './formValidation';
import Select from 'react-select';
import { Calendar } from 'primereact/components/calendar/Calendar';
import { dateTimeFormat, numberWithCommasAndDollar, numberWithCommas } from '../../../helper/helper';
import { submitUpdateOffering, doUpdateOfferingRes } from '../../../actions/updateOfferingActions';
import { getUnderwriterMpid, underWriterMpidListRes } from '../../../actions/underWriterMpidActions';
import { getOfferingIndustries, offeringIndustriesListRes } from '../../../actions/offeringIndustriesActions';
import { fetchLastClosingPriceData, lastClosingPriceRes } from '../../../actions/lastClosingPriceActions';
import { getBrokerDealerList, doBrokerDealerListRes } from '../../../actions/brokerDealerListActions';
import { getOfferingDetail } from '../../../actions/offeringDetailActions';
import { getBrochureUrl, brochureUrlRes, attestationUrlRes, faqUrlRes } from '../../../actions/brochureUrlActions';
import { fetchOfferingAssetType, doFetchOfferingAssetTypeRes } from '../../../actions/offeringAssetTypesActions';

import NumberFormat from 'react-number-format';
import { MultiSelect } from 'primereact/multiselect';
import Switch from "react-switch";
import Swal from 'sweetalert2';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import EditIcon from '@material-ui/icons/Edit';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';


import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import SelectMaterial from '@material-ui/core/Select';

const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
  }))(props => {
    const { children, classes, onClose } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles(theme => ({
    root: {
      borderTop: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(),
      alignItems: 'center',
      justifyContent: 'center'
    },
  }))(MuiDialogActions);
const customStyles = {
    menu: base => ({
        ...base,
        // override border radius to match the box
        borderRadius: 0,
        background: "#ffffff",
        zIndex: 9999,
        // kill the gap
        marginTop: 0
    }),
    menuList: base => ({
        ...base,
        // kill the white space on first and last option
        padding: 0
    })
};
const lower = value => value && value.toLowerCase();
const trimvalue = (value) => value.replace(/^\s+|\s+$/g, "");

const fields = ['offering_type_name', 'ticker_symbol', 'name', 'cusip_id', 'final_shares','ioi_cutoff_datetime', 'unit_increment', 'price_range', 'ext_id', 'min_price', 'max_price', 'blocked_bd_list',  'trade_date', 'settlement_date', 'industry', 'description', 'underwriter_mpid', 'active_window', 'underwriter_concession_amount', 'underwriters_list', 'blocked_bd_list', 'prospectus_url', 'exchange', 'available_to_order', 'listed_share_tbd', 'trade_date_tbd', 'settlement_date_tbd', 'min_price_tbd', 'max_price_tbd', 'dsp_attestation_file', 'dsp_faq_file', 'ordr_dollar_share', 'min_ticket_size', 'max_ticket_size'];

const renderField = ({ input, disabled, label, type, meta: { touched, error } }) => (
    <div>
        <label className="update-label">{label}</label>
        <TextField className="offering_create_input" {...input} type={type} disabled={disabled} />
    </div>
)
const renderFieldDollar = ({ input, label,startAdornment, type, disabled, meta: { touched, error } }) => (
    <div>
        <label className="update-label">{label}</label>
        <FormControl fullWidth>
            {/* <InputLabel htmlFor="standard-adornment-amount">{label}</InputLabel> */}
            <Input
                {...input} type={type} disabled={disabled}
                startAdornment={startAdornment}
            />
        </FormControl>
    </div>
)

const renderTextareaField = ({ input, label, disabled, meta: { touched, error } }) => (
    <div>
        <label className="update-label">{label}</label>
        <TextField multiline rows={4} {...input} disabled={disabled} style={{ borderStyle: 'groove', borderColor: '#ccc', width: '100%' }} />
    </div>
);

const customFileInput = (field) => {
    delete field.input.value; // <-- just delete the value property
    return <input type="file" id="file" {...field.input} disabled={field.disabled} />;
};

class UpdateOfferingForm extends React.PureComponent {

    constructor(props) {
        super(props);
        
        const today = new Date();
        const month = today.getMonth();
        const year = today.getFullYear();
        const prevMonth = (month === 0) ? 11 : month - 1;
        const prevYear = (prevMonth === 11) ? year - 1 : year;

        const minDate = new Date();
        minDate.setMonth(prevMonth + 1);
        minDate.setFullYear(prevYear);
        this.state = {
            offeringDetailsData:  props.offeringData,
            offeringStatusCheck: false,
            isDisableForm: false,
            isLoader: true,
            lastClosing: false,
            typeId: '',
            name: '',
            ticker_symbol: '',
            cusip_id: '',
            final_shares: '',
            offering_type_name: 'IPO',
            max_price: '',
            min_price: '',
            notify: false,
            listed_shares: '',
            description: '',
            industry: '',
            prospectus_url: '',
            unit_increment: 1,
            underwriters_list: null,
            underwriters_list_array: [],
            blocked_bd_list: null,
            blocked_bd_list_array: [],
            settlement_date: '',
            trade_date: '',
            exchange_value_name: '',
            active_window: '',
            available_to_order: '',
            isSubmited: false,
            UnderwriterValue: '',
            UnderwriterId: '',
            selectUserMpId: '',
            bd_underwriter_mpid_id: '',
            selected_bd_underwriter_mpid_id: '',
            underwriter_concession_amount: '0.0000',
            minDate: minDate,
            trade_date_show: '',
            settlement_date_show: '',
            file: '',
            previewLogo: '',
            date_tbd: false,
            isListedChecked: false,
            offeringListCheck: false,
            isMaxPriceChecked: false,
            isMinPriceChecked: false,
            openConfirmation: false,
            isCheckReadonly: false,
            checkLastClosingPrice: '',
            selectedUnderwriter: null,
            isLastClosingDisabled: true,
            brochure_url: '',
            selectedUnderWritersList: [],
            selectedBrokerDealers: null,
            selectedBrokerDealersList: null,
            errorBtn: true,
            issuer: '',
            security: '',
            exchange: '',
            base_deal_size: '',
            initial_offering_price: '',
            credit_rating: '',
            coupon_talk: '',
            interest_payment_dates: '',
            no_call_provision: '',
            final_redemption: '',
            denominations: '',
            expected_pricing: '',
            lead_underwriter: '',
            dsp: 0,
            isDirectedShare: false,
            isDsp: false,
            showUrlField: false,
            showFileUploadField: false,
            isEnterTickerSymbol: true,
            brochureFile: '',
            offeringindustriesList: [],
            underWriterMpidList: [],
            offeringExchangeList: [],
            riskProfileList: [],
            offeringTypeList: [],
            initialValues: null,
            selected_industry: {},
            ioi_cutoff_datetime: null,
            ioi_cutoff_datetime_show: null,
            attestationFile: '',
            faqFile: '',
            attestationFileName: 'Select your file',
            faqFileName: 'Select your file',
            dsp_attestation_file: '',
            dsp_faq_file: '',
            ordr_dollar_share: 0, // The default it initialized to dollar
            min_ticket_size: '',
            max_ticket_size: '',
            
            isRender: false,
            checkedSwitch: false,
            isListedsharesChecked: false,
            offeringAssetTypes: []
        }
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.goOffering = this.goOffering.bind(this);
        this.toggleChange = this.toggleChange.bind(this);
        this.mpIDhandleChange = this.mpIDhandleChange.bind(this);
        this.industryhandleChange = this.industryhandleChange.bind(this);
        this.toggleDateTbd = this.toggleDateTbd.bind(this);
        this.ApproxMaxPriceChange = this.ApproxMaxPriceChange.bind(this);
        this.ApproxMinPriceChange = this.ApproxMinPriceChange.bind(this);
        this.goBackOffering = this.goBackOffering.bind(this);
        this.handleShareChange = this.handleShareChange.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.handleChangeValue = this.handleChangeValue.bind(this);
        this.handleChangePriceValue = this.handleChangePriceValue.bind(this);
        this.selectInputFile = this.selectInputFile.bind(this);
        this.selectCheckbox = this.selectCheckbox.bind(this);
        this.handleChangeMinPrice = this.handleChangeMinPrice.bind(this);
        this.handleChangeMaxPrice = this.handleChangeMaxPrice.bind(this);
        this.handleChangeExchange = this.handleChangeExchange.bind(this);
        this.leadUnderwriterhandleChange = this.leadUnderwriterhandleChange.bind(this);
        this.changeBrochureMode = this.changeBrochureMode.bind(this);
        this.chooseBrochureFile = this.chooseBrochureFile.bind(this);
    }
    
    componentDidMount() {
        this.offeringDetailSet();
        this.props.getOfferingAssetType();
        this.props.getUnderwriterMpidList();
        this.props.getOfferingIndustriesList();        
        this.props.getBrokerDealers();
        this.props.changeFieldValue('underwriter_concession_amount', '0.0000');
    }

    onKeyPress = (e) => {
        if (e.which === 13) {
            e.preventDefault();
        }
    }
    offeringDetailSet() {
        this.setState({
            name: this.state.offeringDetailsData.name,
            ticker_symbol: this.state.offeringDetailsData.ticker_symbol,
            offering_type_name: this.state.offeringDetailsData.offering_type_name,
            type: this.state.offeringDetailsData.offering_type_name,
            final_shares: this.state.offeringDetailsData.final_shares,
            description: this.state.offeringDetailsData.description,
            ext_id: this.state.offeringDetailsData.ext_id,
            available_to_order: this.state.offeringDetailsData.available_to_order,
            cusip_id: this.state.offeringDetailsData.cusip_id,
            exchange: this.state.offeringDetailsData.exchange,
            active_window: this.state.offeringDetailsData.active_window,
            brochure_url: this.state.offeringDetailsData.brochure_url ? this.state.offeringDetailsData.brochure_url : '',
            total_orders_count: this.state.offeringDetailsData.total_orders_count,
            price_range: this.state.offeringDetailsData.price_range,
            unit_increment: this.state.offeringDetailsData.unit_increment,
            offeringStatus: this.state.offeringDetailsData.status,
            dsp_attestation_file: this.state.offeringDetailsData.dsp_attestation_file ? this.state.offeringDetailsData.dsp_attestation_file : '',
            dsp_faq_file: this.state.offeringDetailsData.dsp_faq_file ? this.state.offeringDetailsData.dsp_faq_file : '',
            attestationFileName: this.state.offeringDetailsData.dsp_attestation_file !== '' ? this.state.offeringDetailsData.dsp_attestation_file : 'Select your file',
            faqFileName: this.state.offeringDetailsData.dsp_faq_file !== '' ? this.state.offeringDetailsData.dsp_faq_file : 'Select your file',
            ordr_dollar_share: this.state.offeringDetailsData.ordr_dollar_share,
            min_ticket_size: this.state.offeringDetailsData.min_ticket_size,
            max_ticket_size: this.state.offeringDetailsData.max_ticket_size,
            underwriter_concession_amount: this.state.offeringDetailsData.underwriter_concession_amount,
            dsp: this.state.offeringDetailsData.dsp,
            checkedSwitch: this.state.offeringDetailsData.ordr_dollar_share === 0 ? false : true,
            ioi_cutoff_datetime: this.state.offeringDetailsData.ioi_cutoff_datetime,
        }, () => {

            if (this.state.offeringStatus !== 'active') {
                this.setState({
                    offeringStatusCheck: true
                });
            }
            this.props.changeFieldValue('underwriter_concession_amount', this.state.underwriter_concession_amount);
            this.props.changeFieldValue('ticker_symbol', this.state.ticker_symbol);
            this.props.changeFieldValue('name', this.state.name);
            this.props.changeFieldValue('description', this.state.description);
            this.props.changeFieldValue('brochure_url', this.state.brochure_url);
            this.props.changeFieldValue('ordr_dollar_share', this.state.ordr_dollar_share);
            this.props.changeFieldValue('ext_id', this.state.ext_id);
            this.props.changeFieldValue('cusip_id', this.state.cusip_id);
            this.props.changeFieldValue('min_ticket_size', this.state.min_ticket_size);
            this.props.changeFieldValue('max_ticket_size', this.state.max_ticket_size);
            this.props.changeFieldValue('unit_increment', this.state.unit_increment);


            if (this.state.ticker_symbol !== '') {
                this.setState({
                    isEnterTickerSymbol: false,
                    showUrlField: true,
                });
            }

        });
        if (this.state.offeringDetailsData.dsp) {
            if (this.state.offeringDetailsData.dsp === 0) {
                this.setState({
                    dsp: 0,
                    isDsp: false,
                    isDirectedShare: false
                }, () => { });
            } else {
                this.setState({
                    dsp: 1,
                    isDsp: true,
                    isDirectedShare: true
                }, () => { });
            }
            // } else {
            //     this.setState({
            //         dsp: 2,
            //         isDsp: true,
            //         isDirectedShare: false
            //     }, () => { });
            // }
        }
        if (this.state.offeringDetailsData.cusip_id) {
            this.setState({
                cusip_id: this.state.offeringDetailsData.cusip_id,
                isCusipChange: false,
            }, () => { });

        } else {
            this.setState({
                cusip_id: '',
                isCusipChange: true,
            }, () => { });
        }
        if (this.state.offeringDetailsData) {
            if (this.state.offeringDetailsData.ioi_cutoff_datetime) {
                this.setState({
                    ioi_cutoff_datetime: this.state.offeringDetailsData.ioi_cutoff_datetime,
                    ioi_cutoff_datetime_show: new Date(this.state.offeringDetailsData.ioi_cutoff_datetime),
                }, () => { });
            } else {
                this.setState({
                    ioi_cutoff_datetime: null,
                    ioi_cutoff_datetime_show: null,
                }, () => { });
            }
        }
        if (this.state.offeringDetailsData.max_price) {
            this.setState({
                max_price: this.state.offeringDetailsData.max_price,
                isMaxPriceChecked: false,
            }, () => {
                this.props.changeFieldValue('max_price', this.state.offeringDetailsData.max_price);

            });
        } else {
            this.setState({
                max_price: '',
                isMaxPriceChecked: true,
            }, () => {
                this.props.changeFieldValue('max_price', '');
            });
        }

        // if final_shares is greater than 0 then show the final shares else show tbd
        if (Number(this.state.offeringDetailsData.final_shares) > 0) {
            this.setState({
                final_shares: this.state.offeringDetailsData.final_shares,
                isListedsharesChecked: false,
            }, () => {
                this.props.changeFieldValue('final_shares', this.state.offeringDetailsData.final_shares);
            });
        } else {
            this.setState({
                final_shares: '',
                isListedsharesChecked: true,
            }, () => {
                this.props.changeFieldValue('final_shares', '');
            });
        }

        if (this.state.offeringDetailsData.min_price) {
            this.setState({
                min_price: this.state.offeringDetailsData.min_price,
                isMinPriceChecked: false,
            }, () => {
                this.props.changeFieldValue('min_price', this.state.offeringDetailsData.min_price);
            });
        } else {
            this.setState({
                min_price: '',
                isMinPriceChecked: true,
            }, () => {
                this.props.changeFieldValue('min_price', '');
            });
        }

        if (this.state.offeringDetailsData.underwriter_concession_amount) {
            if (this.state.offeringDetailsData.underwriter_concession_amount == null) {
                this.setState({
                    underwriter_concession_amount: '',
                }, () => { });
            } else {
                this.setState({
                    underwriter_concession_amount: this.state.offeringDetailsData.underwriter_concession_amount,
                }, () => { });
            }
        }

        if (this.state.offeringDetailsData.bd_underwriter_mpid_id) {
            const selectedMPIDObject = {
                value: this.state.offeringDetailsData.bd_underwriter_mpid_id,
                label: this.state.offeringDetailsData.bd_underwriter_mpid_id,
            }
            this.setState({
                selected_bd_underwriter_mpid_id: selectedMPIDObject,
                bd_underwriter_mpid_id: selectedMPIDObject.value
            }, () => { });
        }

        if (this.state.offeringDetailsData.underwriters_list) {
            this.setState({ underwriters_list: this.state.offeringDetailsData.underwriters_list }, () => { });
        }
        if (this.state.offeringDetailsData) {
            if (this.state.offeringDetailsData.settlement_date) {
                this.setState({
                    settlement_date: this.state.offeringDetailsData.settlement_date,
                    settlement_date_show: new Date(this.state.offeringDetailsData.settlement_date),
                    isSettlementDateChecked: false,
                }, () => { });
            } else {
                this.setState({
                    date_tbd: true,
                    trade_date: null,
                    trade_date_show: null,
                }, () => { });
            }

            if (this.state.offeringDetailsData.trade_date) {
                this.setState({
                    trade_date: this.state.offeringDetailsData.trade_date,
                    trade_date_show: new Date(this.state.offeringDetailsData.trade_date),
                    isApproxDateChecked: false,
                }, () => { });
            } else {
                this.setState({
                    date_tbd: true,
                    settlement_date: null,
                    settlement_date_show: null,
                }, () => { });
            }

        }
    }
    static getDerivedStateFromProps(props, state) {
        if(props.offeringIndustriesListRes && state.offeringindustriesList.length === 0){
			if(props.offeringIndustriesListRes.data && props.offeringIndustriesListRes.data.offeringIndustries){
                if (props.offeringIndustriesListRes.data.offeringIndustries.message === 'Success') {
                    if (props.offeringIndustriesListRes.data.offeringIndustries.data.length > 0) {
                        let indSelected = null;
                        let industryVal = null;
                        for (const items of props.offeringIndustriesListRes.data.offeringIndustries.data) {
                            if (state.offeringDetailsData.industry === items.name) {
                                const selectedIndustryObject = {
                                    value: items.id,
                                    label: state.offeringDetailsData.industry,
                                };
                                indSelected = selectedIndustryObject;
                                industryVal = selectedIndustryObject.label;
                            }
                        }
                        return {
                            selected_industry: indSelected,
                            industry: industryVal,
                            offeringindustriesList: props.offeringIndustriesListRes.data.offeringIndustries.data
                        };
                    }
                }
			}
        }
        if (props.underWriterMpidListRes && state.underWriterMpidList.length === 0) {
            if (props.underWriterMpidListRes.data && props.underWriterMpidListRes.data.underWriterMpid) {
                if (props.underWriterMpidListRes.data.underWriterMpid.message === 'Success') {
                    if (props.underWriterMpidListRes.data.underWriterMpid.data.length > 0) {
                        let selectedUnderW = null;
                        if (props.underWriterMpidListRes.data.underWriterMpid.data) {
                            const localArray = state.offeringDetailsData.underwriters_list;
                            const selectedTmp = [];
                            for (const item of props.underWriterMpidListRes.data.underWriterMpid.data) {
                                for (const items of localArray) {
                                    if (item.executing_broker_mpid === items.executing_broker_mpid) {
                                        if (state.offeringDetailsData.bd_underwriter_mpid_id !== items.executing_broker_mpid) {
                                            selectedTmp.push(item);
                                        }
                                    }
                                }
                            }
                            props.changeFieldValue('underwriters_list', selectedTmp);
                            selectedUnderW = selectedTmp;
                        }
                        return {
                            selectedUnderwriter: selectedUnderW,
                            underWriterMpidList: props.underWriterMpidListRes.data.underWriterMpid.data
                        };
                    }
                }
            }
        }
        if(props.brokerDealerListRes){
			if(props.brokerDealerListRes.data && props.brokerDealerListRes.data.bdList) {
                if (props.brokerDealerListRes.data.bdList.message === 'Success') {
                    let bdLists = [];
                    if (props.brokerDealerListRes.data.bdList.data.length > 0) {
                        for (let item of props.brokerDealerListRes.data.bdList.data) {
                            if (item.type === "BrokerDealer") {
                                bdLists.push(item)
                            }
                        }
                    }
                    const localArr = state.offeringDetailsData.blocked_bd_list;
                    const selectedTmpArr = [];
                    for (const item of bdLists) {
                        for (const items of localArr) {
                            if (item.name === items.name) {
                                selectedTmpArr.push(item);
                            }
                        }
                    }
                    return {
                        brokerdealers: bdLists,
                        selectedBrokerDealers: selectedTmpArr,
                    };
                }
			}
        }
        if (props.brochureUrlRes) {
            if(props.brochureUrlRes.data && props.brochureUrlRes.data.brochureUrl){
				if(props.brochureUrlRes.data.brochureUrl.message === "Success") {
                    return{
                        brochure_url: props.brochureUrlRes.data.brochureUrl.data,
                    };
                }
			}
        }
        if (props.attestationUrlRes) {
            if(props.attestationUrlRes.data && props.attestationUrlRes.data.attestationUrl){
				if(props.attestationUrlRes.data.attestationUrl.message === "Success") {
                    return{
                        dsp_attestation_file: props.attestationUrlRes.data.attestationUrl.data,
                    };
                }
			}
        }
        if (props.faqUrlRes) {
            if(props.faqUrlRes.data && props.faqUrlRes.data.faqUrl){
				if(props.faqUrlRes.data.faqUrl.message === "Success") {
                    return{
                        dsp_faq_file: props.faqUrlRes.data.faqUrl.data,
                    };
                }
			}
        }
        if (props.fetchOfferingAssetTypeRes) {
            if(props.fetchOfferingAssetTypeRes.data && props.fetchOfferingAssetTypeRes.data.offeringAssetTypes){
				if(props.fetchOfferingAssetTypeRes.data.offeringAssetTypes.message === "Success") {
                    return {
                        offeringAssetTypes: props.fetchOfferingAssetTypeRes.data.offeringAssetTypes.data,
                    };
                }
			}
        }
		return null;
    }
    
    listedSharesChange = () => {
        if (this.state.isListedsharesChecked === false) {
          this.setState({
            isListedsharesChecked: true,
            final_shares: '',
            isSubmited: true,
    
          }, () => {
            this.props.changeFieldValue('final_shares', '');
          });
        } else {
          this.setState({
            isListedsharesChecked: false,
            isSubmited: true,
          }, () => { });
        }
      }
    selectCheckboxDirectedShare = () => {
        if (this.state.isDirectedShare === false) {
          this.setState({
            isDirectedShare: true,
            isDsp: false,
            dsp: 1,
            ioi_cutoff_datetime: null,
            ioi_cutoff_datetime_show: null,
          }, () => {});
        } else {
          this.setState({
            isDirectedShare: false,
            dsp: 0,
            isDsp: false,
          }, () => {});
        }
      }
    selectIoiCutoff = (e) => {
        if (e.value) {
          this.setState({
            ioi_cutoff_datetime: dateTimeFormat(e.value),
            ioi_cutoff_datetime_show: e.value,
          }, () => {});
        } else {
          this.setState({
            ioi_cutoff_datetime: null,
            ioi_cutoff_datetime_show: null,
          }, () => {});
        }
      }
    handleShareChange(event) {
        if (event.target.value) {
          this.setState({
            final_shares: (event.target.value) ? event.target.value : null,
            isListedsharesChecked: false,
          }, () => {});
        } else {
          this.setState({
            final_shares: '',
            isListedsharesChecked: false,
          }, () => {});
        }
      }
    onBrokerDealersToggle = (event) => {
        this.setState({ selectedBrokerDealers: event.value });
    }
    addComma = (text) => {
        let chunks = [];
        for(let i = 0; i < text.length; i+=1){
          chunks.push(text.substr(i,1));
        }
        return chunks;
    }
    onCloseModal = () => {
        this.setState({
            openConfirmation: false,
        });
    };
    onOpenModal = () => {
        this.setState({
            isSubmited: true,
            notify: false,
        }, () => { 
        });
        validate(this.state);
        const errors = validate(this.state);
        if (Object.keys(errors).length === 0) {
            const selectedUnderWriters = [];
            if (this.state.selectedUnderwriter) {
                for (const items of this.state.selectedUnderwriter) {
                  selectedUnderWriters.push(items.executing_broker_mpid);
                }
              }
            
            const selectedBrokerDealers = [];
            const formSelectedBrokerDealerTemp = [];
            if (this.state.selectedBrokerDealers.length > 0) {
                for (var j = 0; j < this.state.selectedBrokerDealers.length; j++) {
                    const item = this.state.selectedBrokerDealers[j];
                    selectedBrokerDealers.push(item.name);
                    formSelectedBrokerDealerTemp.push(item.original);
                }
            }
            this.setState({
                openConfirmation: true,
                selectedUnderWritersList: selectedUnderWriters,
                selectedBrokerDealersList: selectedBrokerDealers,
                formSelectedBrokerDealers: formSelectedBrokerDealerTemp,
            });
        } else {
            fields.find((field) => {
                if (errors[field]) {
                    const elem = document.querySelector(`input[name=${field}]`);
                    if (elem) {
                        elem.focus();
                        return true;
                    }
                }
                return false;
            });
        }
    };

    handleChangeValue(e) {
        if (e.target.name === 'brochure_url') {
            this.setState({
                [e.target.name]: e.target.value.trim(),
            }, () => {
                if (e.target.name === 'brochure_url') {
                    this.props.changeFieldValue('brochure_url', e.target.value.trim());
                }
                if (this.state.ticker_symbol !== '') {
                    this.setState({
                        isEnterTickerSymbol: false,
                    });
                } else {
                    this.setState({
                        isEnterTickerSymbol: true,
                    });
                }
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value,
            }, () => {
                if (this.state.ticker_symbol !== '') {
                    this.setState({
                        isEnterTickerSymbol: false,
                    });
                } else {
                    this.setState({
                        isEnterTickerSymbol: true,
                    });
                }
            });
        }

    }

    handleChangePriceValue(e) {
        if (e.target.value === '') {
            this.setState({
                max_price: this.state.exchange,
                name: this.state.exchange,
                description: this.state.exchange,
                exchange: this.state.exchange,
            }, () => {
                this.props.changeFieldValue('max_price', '');
                this.props.changeFieldValue('name', '');
                this.props.changeFieldValue('description', '');
                this.props.changeFieldValue('exchange', '');
                this.setState({
                    isEnterTickerSymbol: true,
                });
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value,
            }, () => {
                if (this.state.ticker_symbol !== '') {
                    this.setState({
                        isEnterTickerSymbol: false,
                    },()=>{ });
                }
            });
        }
    }

    changeBrochureMode(value) {
        if (this.state.isEnterTickerSymbol) {
            Swal.fire({
                title: "Please enter Ticker Symbol first",
                icon: 'info',
                confirmButtonText: 'OK',
                allowOutsideClick: false,
                timer: 3000,
                heightAuto: false
            });
        } else {
            if (value === 'url') {
                if(this.state.showUrlField===false){
                    this.props.changeFieldValue('brochure_url', '');
                    this.setState({
                        showUrlField: true,
                        showFileUploadField: false,
                        brochure_url: ''
                    });
                }else{
                    this.setState({
                        showUrlField: false,
                        showFileUploadField: false,
                    });
                }
            } else {
                if(this.state.showFileUploadField===false){
                    this.props.changeFieldValue('brochure_url', '');
                    this.setState({
                        showFileUploadField: true,
                        showUrlField: false,
                        brochure_url: ''
                    });
                }else{
                    this.setState({
                        showFileUploadField: false,
                        showUrlField: false,
                    });
                }
            }
        }
    }

    chooseBrochureFile(e) {
        this.setState({
            brochureFile: e.target.files,
        }, () => {
            this.props.getBrochureUrl(this.state.brochureFile, this.state.ticker_symbol, 'brochure');
        });
    }

    selectInputFile(e) {
        this.setState({
            previewLogo: URL.createObjectURL(e.target.files[0]),
            file: e.target.files,
        }, () => { });
    }

    selectCheckbox() {
        // e.preventDefault();
        if (this.state.isDsp === false) {
            this.setState({
              isDsp: true,
              dsp: 1,
              isDirectedShare: false,
            }, () => {});
          } else {
            this.setState({
              isDsp: false,
              dsp: 0,
              isDirectedShare: false,
            }, () => {});
          }
    }

    // ** Select Exchange   **//
    handleChangeExchange = (e) => {
        if (e.target.value) {
            this.setState({
                exchange: e.target.value,
            });
        } else {
            this.setState({
                exchange: '',
            });
        }
    }

    handleFormSubmit() {
        const finalSubmitData = {
            'cusip_id': this.state.cusip_id,
            'ext_id': this.state.ext_id,
            'description': this.state.description,
            'underwriters_list': this.state.selectedUnderWritersList.join(),
            'industry': this.state.industry,
            'available_to_order': Number(this.state.available_to_order),
            'offering_type_name': this.state.offering_type_name,
            'trade_date': this.state.trade_date,
            'settlement_date': this.state.settlement_date,
            'min_price': Number(this.state.min_price) ? this.state.min_price : null,
            'max_price': Number(this.state.max_price) ? this.state.max_price : null,
            'final_shares' : this.state.final_shares === '' ? null : this.state.final_shares,
            'underwriter_concession_amount': Number(this.state.underwriter_concession_amount),
            'bd_underwriter_mpid_id': this.state.selected_bd_underwriter_mpid_id.value,
            'notify' : false,
            'exchange' : this.state.exchange,
            'brochure_url' : this.state.brochure_url,
            'active_window':this.state.active_window,
            'blocked_bd_list': this.state.selectedBrokerDealersList.join(),
            'dsp': this.state.dsp,
            'dsp_attestation_file': this.state.dsp === 1 ? this.state.dsp_attestation_file === '' ? null : this.state.dsp_attestation_file : null,
            'dsp_faq_file': this.state.dsp === 1 ? this.state.dsp_faq_file === '' ? null : this.state.dsp_faq_file: null,
            'unit_increment': this.state.unit_increment,
            'ordr_dollar_share': Number(this.state.ordr_dollar_share),
            'min_ticket_size': this.state.min_ticket_size ? Number(this.state.min_ticket_size) : null,
            'max_ticket_size': this.state.max_ticket_size ? Number(this.state.max_ticket_size) : null,
            'ioi_cutoff_datetime' : null,
        }
        if (this.state.offering_type_name === 'IPO') {
            if (Number(this.state.min_price) || Number(this.state.max_price)) {
                finalSubmitData.price_range = ('$' + this.state.min_price + ' - $' + this.state.max_price);
            } else {
                finalSubmitData.price_range = null;
            }
        }
        if (this.state.offering_type_name === 'Follow-on' || this.state.offering_type_name === 'ETF' || this.state.offering_type_name === 'UIT') {
            if (Number(this.state.max_price)) {
                finalSubmitData.price_range = ('$' + this.state.max_price);
            } else {
                finalSubmitData.price_range = null;
            }
        }
        if (this.state.offering_type_name === 'Overnight') {
            if (Number(this.state.min_price) || Number(this.state.max_price)) {
                finalSubmitData.price_range = ('$' + this.state.min_price + ' - $' + this.state.max_price);
            } else {
                finalSubmitData.price_range = null;
            }
        }
        // console.log("finalSubmitData ", finalSubmitData);
        this.props.submitUpdateOffering(finalSubmitData, this.state.file, this.state.offeringDetailsData.ext_id, 'update');
        this.setState({
            openConfirmation: false,
        }, () => { });
    }
    mpIDhandleChange(event) {
        this.setState({
            selected_bd_underwriter_mpid_id: event,
            bd_underwriter_mpid_id: event.value,
        }, () => {
            let selectedUnder = this.state.selectedUnderwriter;
            let removeItem = null;
            for (let item of selectedUnder) {
                if (item.executing_broker_mpid === this.state.selected_bd_underwriter_mpid_id.value) {
                    removeItem = item;
                }
            }
            if (removeItem !== null) {
                selectedUnder.splice(removeItem, 1);
                this.setState({
                    selectedUnderwriter: selectedUnder
                })
            }
        });
    }

    leadUnderwriterhandleChange(event) {
        this.setState({
            lead_underwriter: event.value,
        }, () => { });
    }

    toggleChange(e) {
        this.setState({
            available_to_order: e.target.value,
        });
    }
    toggleOrderChange = (value) => {
        this.setState({
            ordr_dollar_share: value,
        });
    }
    goOffering = () => {
        // this.props.history.push('/offerings');
        this.props.history.goBack();
    }

    trimvalue(value) {
        if (value) {
            return value.replace(/^\s+|\s+$/g, "");
        } else {
            return value.replace(/^\s+|\s+$/g, " ");
        }
    }

    selectSettlementDate(e) {
        if (e.value) {
            this.setState({
                settlement_date: dateTimeFormat(e.value),
                settlement_date_show: e.value,
                date_tbd: false,
            }, () => { });
        } else {
            this.setState({
                settlement_date: '',
                date_tbd: false,
            }, () => { });
        }
    }

    selectTradeDate(e) {
        if (e.value) {
            const settlementDate = new Date(e.value);
            settlementDate.setDate(e.value.getDate() + 2);
            this.setState({
                trade_date: dateTimeFormat(e.value),
                settlement_date: dateTimeFormat(settlementDate),
                trade_date_show: e.value,
                settlement_date_show: settlementDate,
                date_tbd: false,
            }, () => {
                this.props.changeFieldValue('settlement_date', this.state.settlement_date_show);
            });
        } else {
            this.setState({
                trade_date: '',
                date_tbd: false,
            }, () => {
                this.props.changeFieldValue('trade_date', '');
            });
        }
    }
    onColumnToggle = (event) => {
        this.setState({ selectedUnderwriter: event.value });
    }
    
    blockedBdHandle = (value) => {
        if (value === null) {
            this.setState({
                blocked_bd_list_array: [],
                blocked_bd_list: value,
            }, () => { });
        } else {
            this.setState({
                blocked_bd_list_array: Object.values(value),
                blocked_bd_list: value,
            }, () => {
            });
        }
    }
    industryhandleChange(event, data) {
        this.setState({
          selected_industry: event,
          industry: event.label
        }, () => {});
      }
    toggleDateTbd = () => {
        this.setState({
            settlement_date_show: null,
            settlement_date: null,
            trade_date_show: null,
            trade_date: null,
            date_tbd: !this.state.date_tbd,
        }, () => {
            this.props.changeFieldValue('trade_date', null);
            this.props.changeFieldValue('settlement_date', null);
        });
    }

    ApproxMinPriceChange() {
        if (this.state.isMinPriceChecked === false) {
            this.setState({
                isMinPriceChecked: true,
                min_price: '',
            }, () => {
                this.props.changeFieldValue('min_price', '');
            });
        } else {
            this.setState({
                isMinPriceChecked: false,
            }, () => { });
        }
    }

    ApproxMaxPriceChange() {
        if (this.state.isMaxPriceChecked === false) {
            this.setState({
                isMaxPriceChecked: true,
                max_price: '',
            }, () => {
                this.props.changeFieldValue('max_price', '');
            });
        } else {
            this.setState({
                isMaxPriceChecked: false,
            }, () => { });
        }
    }

    handleChangeMinPrice(event) {
        this.setState({
            min_price: event.target.value,
            isMinPriceChecked: false,
        }, () => { });
    }

    handleChangeMaxPrice(event) {
        this.setState({
            max_price: event.target.value,
            isMaxPriceChecked: false,
        }, () => { });
    }
    goBackOffering() {
        if (this.state.offeringListCheck === false) {
            this.props.fetchOfferings();
        }
        this.props.history.goBack();
        this.props.reset();
    }

    toggleLastClosingDisabled = (e) => {
        // preventDefault is needed to not submit the form. since html submits when a form if a button is clicked within a <form> tag
        e.preventDefault();
        this.setState({
            isLastClosingDisabled: !this.state.isLastClosingDisabled,
        });
    }

    selectAttestationFile = (e) => {
        e.persist();
        if (this.state.isEnterTickerSymbol) {
            Swal.fire({
                title: "Please enter Ticker Symbol first",
                icon: 'info',
                confirmButtonText: 'OK',
                allowOutsideClick: false,
                timer: 3000,
                heightAuto: false
            });
        } else {
            this.setState({
                attestationFileName: e.target.files[0].name,
                attestationFile: e.target.files,
            }, () => {
                this.props.getBrochureUrl(this.state.attestationFile, this.state.ticker_symbol, 'attestation');
            });
        }
    }
    selectFaqFile = (e) => {
        e.persist();
        if (this.state.isEnterTickerSymbol) {
            Swal.fire({
                title: "Please enter Ticker Symbol first",
                icon: 'info',
                confirmButtonText: 'OK',
                allowOutsideClick: false,
                timer: 3000,
                heightAuto: false
            });
        } else {
            this.setState({
                faqFileName: e.target.files[0].name,
                faqFile: e.target.files,
            }, () => {
                this.props.getBrochureUrl(this.state.faqFile, this.state.ticker_symbol, 'faq');
            });
        }
    }

    
    handleChangeSwitch = (checked) => {
        if (checked === true) {
            this.setState({
                ordr_dollar_share: 1
            })
        } else {
            this.setState({
                ordr_dollar_share: 0
            })
        }
        this.setState({ checkedSwitch: checked });
    }
    render() {
        const errors = validate(this.state);
        // const { typeId, isSubmited, isLastClosingDisabled } = this.state;
        const { submitting } = this.props;
        
        const indusnewOption = [];
        if (this.state.offeringindustriesList && this.state.offeringDetailsData) {
            if (this.state.offeringDetailsData.industry) {
                if (this.state.offeringindustriesList.length > 0) {
                    this.state.offeringindustriesList.map((item) => {
                        indusnewOption.push({ value: item.name, label: item.name });
                        if (this.state.offeringDetailsData.industry !== undefined) {
                            if (item.name === this.state.offeringDetailsData.industry) {
                                return <option key={item.name} selected value={item.name}>{item.name}</option>
                            }
                        }
                    return <option key={item.name} value={item.name}>{item.name}</option>
                });
                }
            } else {
                if (this.state.offeringindustriesList.length > 0) {
                    this.state.offeringindustriesList.map((item) => {
                    indusnewOption.push({ value: item.name, label: item.name });
                    if (this.state.offeringDetailsData.industry !== undefined) {
                    if (item.name === this.state.offeringDetailsData.industry) {
                        return <option key={item.name} selected value={item.name}>{item.name}</option>
                    }
                    }
                    return <option key={item.name} value={item.name}>{item.name}</option>
                });
                }
            }
        }
        // let UserMpidList = [];
        const typeAheadUserMpidList = [];
        const userMpidListForUnderwriters = [];
        if (this.state.underWriterMpidList && this.state.underWriterMpidList.length > 0) {
            this.state.underWriterMpidList.map((item) => {
                typeAheadUserMpidList.push({ value: item.executing_broker_mpid, label: item.executing_broker_mpid + ' ' + item.clearing_broker });
                userMpidListForUnderwriters.push({ value: item.executing_broker_mpid, label: item.executing_broker_mpid });
                if (this.state.offeringDetailsData) {
                if (this.state.offeringDetailsData.bd_underwriter_mpid_id !== undefined) {
                    if (item.executing_broker_mpid === this.state.offeringDetailsData.bd_underwriter_mpid_id) {
                        return <option key={item.executing_broker_mpid} selected value={item.executing_broker_mpid}>{item.executing_broker_mpid}</option>;
                    }
                }
                }
                return <option key={item.executing_broker_mpid} value={item.executing_broker_mpid}>{item.executing_broker_mpid}</option>;
            });
        }
        const underWriterMpidListRemaining = [];
        if (this.state.underWriterMpidList && this.state.underWriterMpidList.length > 0) {
            for (let item of this.state.underWriterMpidList) {

                if (item.executing_broker_mpid !== this.state.selected_bd_underwriter_mpid_id.value) {
                    underWriterMpidListRemaining.push(item);
                }
            }
        }
        const brokerDealerList = [];
        if (this.state.brokerdealers && this.state.brokerdealers.length > 0) {
            this.state.brokerdealers.map((item) => {
                brokerDealerList.push(item);
                return (
                    <option value={item.name} id={item.mpid} key={item.mpid}>
                        {item.name}
                    </option>
                );
            });
        }
        let Cusip = [];

        if (this.state.offeringDetailsData) {
        Cusip = <div>
            { !this.state.isCusipChange &&
            <Field
                name="cusip_id"
                component={renderField}
                label="CUSIP ID"
                autoComplete="off"
                type="text"
                onChange={this.handleChangeValue}
                disabled
            />
            }

            { this.state.isCusipChange &&
            <Field
                name="cusip_id"
                component={renderField}
                label="CUSIP ID"
                autoComplete="off"
                type="text"
                onChange={this.handleChangeValue}
                disabled={this.state.offeringStatusCheck}
            />
            }
        </div>;
        }
        const minMaxTemplate = (this.state.type !== "IPO") ? (
            <div>
                <div className="row">
                    <div className="col-md-6 col-sm-12" style={{ paddingTop: '25px' }}>
                        <div>
                        <span className="fa fa-dollar form-control-feedback"></span>
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                {/* if spot or block then lastClosingPrice is min_price */}
                                <Field
                                    name="max_price"
                                    component={renderFieldDollar}
                                    label="Last Closing Price*"
                                    autoComplete="off"
                                    type="text"
                                    ref={(Elmt) => { this.max_price = Elmt; }}
                                    onChange={this.handleChangeValue}
                                    normalize={trimvalue}
                                    style={{ width: '95%' }}
                                    disabled={this.state.isLastClosingDisabled || this.state.offeringStatusCheck}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    />
                            </div>
                            {/* the button that makes last closing price editable for admin to change manually */}
                            {(this.state.isLastClosingDisabled) &&
                            <div className="col-md-6 col-sm-12" style={{paddingTop: 33}}>
                            <input type='submit' value="Modify price" disabled={this.state.offeringStatusCheck} className="toggleLastClosingBtn btn-success" onClick={(e) => this.toggleLastClosingDisabled(e)} />
                            </div>
                            }
                        </div>
                        </div>
                    </div>
                    {(this.state.offering_type_name === 'Overnight') &&
                        <div className="col-md-6 col-sm-12">
                            <div className="row" style={{ paddingTop: '25px' }}>
                                <div className="col-md-6 col-sm-12" >
                                    <span className="fa fa-dollar form-control-feedback"></span>
                                    <Field
                                    name="min_price"
                                    component={renderFieldDollar}
                                    label="Min Price *"
                                    autoComplete="off"
                                    type="number"
                                    ref={(Elmt) => { this.min_price = Elmt; }}
                                    normalize={trimvalue}
                                    onChange={this.handleChangeValue}
                                    style={{ width: '95%' }}
                                    disabled={this.state.offeringStatusCheck}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    />
                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.min_price}</span>}
                                </div>
                                <div className="col-md-6 col-sm-12" >
                                    <span className="fa fa-dollar form-control-feedback"></span>
                                    <Field
                                    name="max_price"
                                    component={renderFieldDollar}
                                    label="Max Price *"
                                    autoComplete="off"
                                    type="number"
                                    ref={(Elmt) => { this.max_price = Elmt; }}
                                    normalize={trimvalue}
                                    onChange={this.handleChangeValue}
                                    style={{ width: '95%' }}
                                    disabled={this.state.offeringStatusCheck}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    />
                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.max_price}</span>}
                                </div>
                            </div>
                        </div>
                    }
                    </div>
            </div>
        ) :
        (
            <div>
            {/* {
                this.state.offering_type_name === 'IPO' && */}
                <div className="row">
                    <div className="col-md-6">
                        <div className="mt-4">
                            <div className="row">
                                <div className="col-8">
                                    <Field
                                        name="min_price"
                                        component={renderFieldDollar}
                                        label="Min Price*"
                                        autoComplete="off"
                                        type="number"
                                        ref={(Elmt) => { this.min_price = Elmt; }}
                                        onChange={this.handleChangeMinPrice}
                                        normalize={trimvalue}
                                        disabled={this.state.offeringStatusCheck}
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    />
                                </div>
                                <div className="col-4 update-label" style={{ fontSize: 20, paddingTop: 27 }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="min_price_tbd"
                                                autoComplete="off"
                                                checked={this.state.isMinPriceChecked}
                                                onChange={this.ApproxMinPriceChange}
                                                color="primary"
                                                disabled={this.state.offeringStatusCheck}
                                            />
                                        }
                                        label={'TBD'}
                                    />
                                </div>
                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.min_price}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mt-4">
                            <div className="row">
                                <div className="col-8">
                                    <Field
                                        name="max_price"
                                        component={renderFieldDollar}
                                        label="Max Price*"
                                        autoComplete="off"
                                        type="number"
                                        ref={(Elmt) => { this.max_price = Elmt; }}
                                        onChange={this.handleChangeMaxPrice}
                                        normalize={trimvalue}
                                        disabled={this.state.offeringStatusCheck}
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    />
                                </div>
                                <div className="col-4 update-label" style={{ fontSize: 20, paddingTop: 27 }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="max_price_tbd"
                                                autoComplete="off"
                                                checked={this.state.isMaxPriceChecked}
                                                onChange={this.ApproxMaxPriceChange}
                                                color="primary"
                                                disabled={this.state.offeringStatusCheck}
                                            />
                                        }
                                        label={'TBD'}
                                    />
                                </div>
                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.max_price}</span>}
                            </div>
                        </div>
                    </div>
                </div>
            {/* } */}
            </div>
        );
        
        return (
            <div>
                <div >
                    <p style={{ marginBottom: 0, color: '#B2B2B2' }}>* is required</p>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-12">
                                    <div style={{ marginTop: 25 }}>
                                        
                                        <label className="update-label">Offering Type *</label>
                                        <div>
                                            <FormControl variant="outlined" fullWidth>
                                                <SelectMaterial
                                                    labelId="offering_type_name-label"
                                                    id="offering_type_name"
                                                    value={this.state.offering_type_name}
                                                    disabled
                                                >
                                                    {
                                                        this.state.offeringAssetTypes && this.state.offeringAssetTypes?.map((op, i) => 
                                                            <MenuItem key={i} value={op.asset_name}>{op.asset_name}</MenuItem>
                                                        )
                                                    }
                                                    {/* <MenuItem value={'IPO'}>IPO</MenuItem>
                                                    <MenuItem value={'Follow-on'}>Follow-on</MenuItem>
                                                    <MenuItem value={'Overnight'}>Overnight</MenuItem>
                                                    <MenuItem value={'ETF'}>ETF</MenuItem>
                                                    <MenuItem value={'UIT'}>UIT</MenuItem> */}
                                                </SelectMaterial>
                                            </FormControl>
                                        </div>
                                        {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.offering_type_name}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mt-4">
                                <Field
                                    name="ticker_symbol"
                                    component={renderField}
                                    label="Ticker Symbol *"
                                    autoComplete="off"
                                    onChange={this.handleChangePriceValue}
                                    type="text"
                                    disabled={(this.state.offering_type_name === 'IPO' ? this.state.isDisableForm : true) || this.state.offeringStatusCheck}
                                />
                                {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.ticker_symbol}</span>}
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="mt-4">
                                <Field
                                    name="name"
                                    component={renderField}
                                    label="Company Name *"
                                    autoComplete="off"
                                    type="text"
                                    onChange={this.handleChangeValue}
                                    disabled= {true}
                                />
                                {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.name}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="mt-4">
                                {Cusip}
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="mt-4">
                                <Field
                                    name="ext_id"
                                    component={renderField}
                                    label="FILE ID"
                                    autoComplete="off"
                                    type="text"
                                    onChange={this.handleChangeValue}
                                    disabled= {true}
                                />
                            </div>
                        </div>
                    </div>
                    {/* {
                        this.state.offering_type_name === 'IPO' && */}
                        <div>
                            {minMaxTemplate}
                        </div>
                    {/* } */}
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mt-4">
                                <div className="row">
                                    <div className={((this.state.offering_type_name !== 'Overnight') ? 'col-8 col-sm-8 prospectus-calender ' : 'col-12 col-sm-12 prospectus-calender ')} >
                                        <label className="update-label TradeDate">Trade Date *</label>
                                        <Calendar classNmae="calendar prospectus-calender-ui" name="trade_date" minDate={this.state.minDate} value={this.state.trade_date_show} onChange={(e) => this.selectTradeDate(e)} style={{height: '42px'}} disabled={this.state.offeringStatusCheck} />
                                    </div>
                                    {(this.state.offering_type_name !== 'Overnight') && <div className="col-sm-4 update-label" style={{ fontSize: 20, paddingTop: 25 }}>
                                       <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="trade_date_tbd"
                                                    autoComplete="off"
                                                    checked={this.state.date_tbd}
                                                    onChange={this.toggleDateTbd}
                                                    color="primary"
                                                    disabled={this.state.offeringStatusCheck}
                                                />
                                            }
                                            label={'TBD'}
                                        />
                                    </div>}
                                </div>
                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.trade_date}</span>}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mt-4">
                                <div className="row">
                                    <div className={((this.state.offering_type_name !== 'Overnight') ? 'col-8 col-sm-8' : 'col-12 col-sm-12')}>
                                        <label className="update-label TradeDate">Settlement Date *</label>
                                        <Calendar classNmae="prospectus-calender-ui" name="settlement_date" minDate={this.state.minDate} value={this.state.settlement_date_show} onChange={(e) => this.selectSettlementDate(e)} style={{height: '42px'}} disabled={this.state.offeringStatusCheck}/>
                                    </div>
                                    {(this.state.offering_type_name !== 'Overnight') && <div className="col-sm-4 update-label" style={{ fontSize: 20, paddingTop: 25 }}>
                                        
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="settlement_date_tbd"
                                                    autoComplete="off"
                                                    checked={this.state.date_tbd}
                                                    onChange={this.toggleDateTbd}
                                                    color="primary"
                                                    disabled={this.state.offeringStatusCheck}
                                                />
                                            }
                                            label={'TBD'}
                                        />
                                    </div>}
                                </div>
                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.settlement_date}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-md-6">
                            <div className="mt-4">
                                <div className="row">
                                    <div className={((this.state.offering_type_name !== 'Overnight') ? 'col-8 col-sm-8' : 'col-12 col-sm-12')}>
                                        <Field
                                            name="final_shares"
                                            component={renderField}
                                            label="Listed Shares *"
                                            autoComplete="off"
                                            type="number"
                                            ref={(Elmt) => { this.final_shares = Elmt; }}
                                            onChange={this.handleShareChange}
                                            normalize={trimvalue}
                                            disabled={this.state.offeringStatusCheck}
                                        />
                                    </div>
                                    {this.state.offering_type_name !== 'Overnight' &&
                                    <div className="col-md-4 update-label" style={{ fontSize: 20, paddingTop: 27 }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="listed_share_tbd"
                                                    autoComplete="off"
                                                    checked={this.state.isListedsharesChecked}
                                                    onChange={this.listedSharesChange}
                                                    color="primary"
                                                    disabled={this.state.offeringStatusCheck}
                                                />
                                            }
                                            label={'TBD'}
                                        />
                                    </div>}
                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.final_shares}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="mt-4">
                                <label className="update-label" style={{ display: 'block', lineHeight: 1.2 }}>
                                    Industries *
                                </label>
                                <Select
                                    classNamePrefix="allocationofffering"
                                    name="selected_industry"
                                    id="selected_industry"
                                    value={this.state.selected_industry}
                                    onChange={(event) => this.industryhandleChange(event)}
                                    options={indusnewOption}
                                    isDisabled={this.state.isDisableForm || this.state.offeringStatusCheck}
                                    styles={customStyles}
                                />
                                {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.industry}</span>}
                            </div>
                        </div>
                    </div>            
                        
                    <div className="row has-Dollar">
                        {(this.state.offering_type_name === 'Overnight') &&
                        <div className="col-6 col-sm-12" style={{ padding: '5px 0px 0px 5px' }}>
                            <div className="col-12 col-sm-12" >
                            <Field
                                name="price_range"
                                component={renderField}
                                label=" Price Range*"
                                autoComplete="off"
                                type="text"
                                ref={(Elmt) => { this.price_range = Elmt; }}
                                onChange={this.handleChangeValue}
                                normalize={trimvalue}
                                style={{ width: '100%' }}
                                disabled={this.state.offeringStatusCheck}
                            />
                            </div>
                        </div>
                        }
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="mt-4">
                                <label className="update-label" style={{ marginBottom: 15 }}>
                                    Order Type
                                </label>
                                <div className="brochure_url_groupbtn">
                                    <div className="btn-group brochure_url_groupbtn_inner" style={{ paddingTop: 8, paddingRight: 12}}>
                                        Dollar
                                    </div>
                                    <Switch 
                                        onChange={this.handleChangeSwitch} 
                                        checked={this.state.checkedSwitch} 
                                        className="react-switch" 
                                        uncheckedIcon={
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 20,
                                                color: "white",
                                                paddingRight: 2
                                              }}
                                            >
                                            </div>
                                          }
                                          checkedIcon={
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 22,
                                                color: "white",
                                                paddingRight: 2
                                              }}
                                            >
                                            </div>
                                          }
                                        handleDiameter={28}
                                        offColor="#65a214"
                                        onColor="#689F38"
                                        // offHandleColor="#689F38"
                                        // onHandleColor="#65a214"
                                        height={40}
                                        width={80}
                                        disabled={this.state.offeringStatusCheck}
                                    />
                                    <div className="btn-group brochure_url_groupbtn_inner" style={{ paddingTop: 8, paddingLeft: 12}}>
                                        Shares
                                    </div>
                                </div>
                                {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.ordr_dollar_share}</span>}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mt-4">
                                {
                                    this.state.ordr_dollar_share === 0 ?
                                    <Field
                                        name="min_ticket_size"
                                        component={renderFieldDollar}
                                        label="Min ticket size"
                                        autoComplete="off"
                                        type="number"
                                        onChange={this.handleChangeValue}
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        disabled={this.state.offeringStatusCheck}
                                    />
                                    :
                                    <Field
                                        name="min_ticket_size"
                                        component={renderField}
                                        label="Min ticket size"
                                        autoComplete="off"
                                        type="number"
                                        onChange={this.handleChangeValue}
                                        disabled={this.state.offeringStatusCheck}
                                    />
                                }
                                {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.min_ticket_size}</span>}
                                
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mt-4">
                                {
                                    this.state.ordr_dollar_share === 0 ?
                                    <Field
                                        name="max_ticket_size"
                                        component={renderFieldDollar}
                                        label="Max ticket size"
                                        autoComplete="off"
                                        type="number"
                                        onChange={this.handleChangeValue}
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        disabled={this.state.offeringStatusCheck}
                                    />
                                    :
                                    <Field
                                        name="max_ticket_size"
                                        component={renderField}
                                        label="Max ticket size"
                                        autoComplete="off"
                                        type="number"
                                        onChange={this.handleChangeValue}
                                        disabled={this.state.offeringStatusCheck}
                                    />
                                }
                                {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.max_ticket_size}</span>}
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="mt-4">
                                <label className="update-label" >Increment (Dollars or Shares)</label>
                                <div>
                                    <NumberFormat
                                    value={this.state.unit_increment}
                                    decimalScale={0}
                                    allowNegative={false}
                                    allowLeadingZeros={true}
                                    isNumericString={true}
                                    onValueChange={
                                        (values) => {
                                            const {value} = values;
                                            this.setState({unit_increment: value === '' ? 1 : value})
                                        }
                                    }
                                    className="update-input"
                                    style={{ width:' 100%',  borderRadius: '4px', marginTop: 0}}
                                    disabled={this.state.offeringStatusCheck}
                                    />
                                </div>
                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.unit_increment}</span>}
                            </div>
                       </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="mt-4">
                                <Field
                                    name="description"
                                    component={renderTextareaField}
                                    label="Description *"
                                    autoComplete="off"
                                    onChange={this.handleChangeValue}
                                    disabled={this.state.offeringStatusCheck}
                                />
                                {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.description}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="mt-4">
                                <label className="update-label">Underwriter MPID *</label>
                                <Select
                                    classNamePrefix="allocationofffering"
                                    name="selected_bd_underwriter"
                                    id="bd_underwriter_mpid_id"
                                    value={this.state.selected_bd_underwriter_mpid_id}
                                    ref={(select) => { this.bd_underwriter_mpid_id = select; }}
                                    onChange={(event) => this.mpIDhandleChange(event)}
                                    options={typeAheadUserMpidList}
                                    styles={customStyles}
                                    isDisabled={this.state.isDisableForm || this.state.offeringStatusCheck}
                                />
                                {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.bd_underwriter_mpid_id}</span>}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mt-4">
                                <Field
                                    name="underwriter_concession_amount"
                                    component={renderField}
                                    label="Underwriter concession amount *"
                                    autoComplete="off"
                                    type="number"
                                    ref={(Elmt) => { this.underwriter_concession_amount = Elmt; }}
                                    onChange={this.handleChangeValue}
                                    normalize={trimvalue}
                                    disabled={this.state.offeringStatusCheck}
                                />
                                {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.underwriter_concession_amount}</span>}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mt-4">
                                <label className="update-label">Underwriter(s)</label>
                                <MultiSelect optionLabel="executing_broker_mpid" filter value={this.state.selectedUnderwriter} options={underWriterMpidListRemaining} placeholder={'Select multiple underwriter(s)'} onChange={this.onColumnToggle} style={{ width: '100%', border: '1px solid #ced4da', height: '46px' }} disabled={this.state.offeringStatusCheck} styles={customStyles}/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mt-4">
                                <label className="update-label">Blocked Broker Dealers</label>
                                <MultiSelect optionLabel="name" filter value={this.state.selectedBrokerDealers} options={brokerDealerList} placeholder={'Select multiple Blocked Broker Dealers'} onChange={this.onBrokerDealersToggle} style={{ width: '100%', border: '1px solid #ced4da', height: '46px' }} disabled={this.state.offeringStatusCheck} />
                               
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mt-4">
                                <div className="brochure_url_groupbtn">
                                    <div style={{width: '80%'}}>
                                        <label className="update-label" style={{ marginBottom: 15 }}>
                                            Brochure Link Option
                                        </label>
                                        <div className="btn-group brochure_url_groupbtn_inner" role="group" aria-label="Basic example">
                                            <button type="button" className={"btn btn-brochure_url_groupbtn" + (this.state.showUrlField ? ' brochure_url_groupbtn-active': '')} onClick={() => this.changeBrochureMode('url')}><EditIcon style={{marginRight: 10}} disabled={this.state.offeringStatusCheck}/>  URL</button>
                                            <button type="button" className={"btn btn-brochure_url_groupbtn" + (this.state.showFileUploadField ? ' brochure_url_groupbtn-active': '')} onClick={() => this.changeBrochureMode('file')} disabled={this.state.offeringStatusCheck}><CloudUploadIcon  style={{marginRight: 10}}/> Upload</button>
                                        </div>
                                    </div>
                                    {
                                    (!this.state.isEnterTickerSymbol && this.state.showUrlField) &&
                                        <div style={{ display: 'block', width: '100%' }}>
                                            <Field
                                                name="brochure_url"
                                                component={renderField}
                                                label="Brochure Link"
                                                autoComplete="off"
                                                type="text"
                                                ref={(Elmt) => { this.brochure_url = Elmt; }}
                                                onChange={this.handleChangeValue}
                                                disabled={this.state.offeringStatusCheck}
                                            />
                                            {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.brochure_url}</span>}
                                        </div>
                                    }
                                    {
                                        (!this.state.isEnterTickerSymbol && this.state.showFileUploadField) &&
                                        <div style={{ display: 'block', width: '100%', marginTop: 30 }} disabled={this.state.offeringStatusCheck}>
                                            <input type="file" onChange={(e) => { this.chooseBrochureFile(e) }} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mt-4">
                                <label className="update-label">
                                    Exchange *
                                </label>
                                <FormControl variant="outlined" fullWidth>
                                    <SelectMaterial
                                        labelId="exchange-label"
                                        id="exchange"
                                        value={this.state.exchange}
                                        onChange={this.handleChangeExchange}
                                        disabled={this.state.offeringStatusCheck}
                                    >
                                        <MenuItem value={'XNYS'}>XNYS - NEW YORK STOCK EXCHANGE</MenuItem>
                                        <MenuItem value={'XNAS'}>XNAS - NASDAQ GLOBAL / CAPITAL / GLOBAL SELECT MARKET</MenuItem>
                                        <MenuItem value={'CBOE'}>CBOE - CHICAGO BOARD OF EXCHANGE</MenuItem>
                                    </SelectMaterial>
                                </FormControl>
                                {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.exchange_value_nameErr}</span>}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mt-4">
                                <label className="update-label">
                                Availability
                                </label>
                                <FormControl variant="outlined" fullWidth>
                                    <SelectMaterial
                                        labelId="available_to_order-label"
                                        id="available_to_order"
                                        value={this.state.available_to_order}
                                        onChange={this.toggleChange}
                                        disabled={this.state.offeringStatusCheck}
                                    >
                                        <MenuItem value={'1'}>Available To Order</MenuItem>
                                        <MenuItem value={'0'}>Not available to order</MenuItem>
                                        <MenuItem value={'2'}>Order Book Closed</MenuItem>
                                    </SelectMaterial>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    {(this.state.offering_type_name !== 'Overnight') &&
                        <div className="row" style={{ marginTop: 35, border: '1px solid #ddd', marginLeft: 0, marginRight: 0, padding: '20px 0px', width: '100%' }}>
                            <div className="col-md-3 col-sm-12 update-label" style={{ fontSize: 18, padding: '10px 20px 5px 20px', marginTop: 14 }}>
                                <FormGroup row>
                                    {/* <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.isDirectedShare}
                                                onChange={this.selectCheckboxDirectedShare}
                                                name="directedShare"
                                                color="primary"
                                                disabled={this.state.offeringStatusCheck}
                                            />
                                        }
                                        label="Directed Allocation"
                                    /> */}
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.isDsp}
                                                onChange={this.selectCheckbox}
                                                name="dsp"
                                                color="primary"
                                                disabled={this.state.offeringStatusCheck}
                                            />
                                        }
                                        label="DSP"
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-md-3 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
                                <label className="update-label" style={{ marginBottom: 15 }}>DSP attestation file *</label>
                                <div className="file-upload-boxes">
                                    <span id="filename" className={(this.state.isDsp || this.state.isDirectedShare ? " " : "dsp_file_disable_text")}>{this.state.attestationFileName}</span>
                                    <label htmlFor="file-upload-attestation" className={(this.state.isDsp || this.state.isDirectedShare || this.state.offeringStatusCheck ? " " : "dsp_file_disable")}>Browse<input type="file" onChange={this.selectAttestationFile} name="dsp_attestation_file" id="file-upload-attestation" disabled={this.state.offeringStatusCheck} /></label>
                                </div>
                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.dsp_attestation_file}</span>}
                            </div>
                            <div className="col-md-3 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
                                <label className="update-label" style={{ marginBottom: 15 }}>DSP faq file</label>
                                <div className="file-upload-boxes ">
                                    <span id="filename" className={(this.state.isDsp || this.state.isDirectedShare ? "  " : "dsp_file_disable_text")}>{this.state.faqFileName}</span>
                                    <label htmlFor="file-upload-faq" className={(this.state.isDsp || this.state.isDirectedShare || this.state.offeringStatusCheck ? " " : "dsp_file_disable")}>Browse<input type="file" onChange={this.selectFaqFile} name="dsp_faq_file" id="file-upload-faq" disabled={this.state.offeringStatusCheck}/></label>
                                </div>

                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.dsp_faq_file}</span>}
                            </div>
                            {/* {(this.state.isDsp) &&
                                <div className="col-md-3 col-sm-12" >
                                    <label className="update-label" style={{ padding: '0px 0px 0px 20px' }} >
                                        IOI Cutoff Datetime
                                    </label>
                                    <div style={{ padding: '0px 0px 0px 0px', marginTop: 8 }} >
                                        <Calendar
                                            name="ioi_cutoff_datetime"
                                            minDate={this.state.minDate}
                                            value={this.state.ioi_cutoff_datetime_show}
                                            showTime
                                            showSeconds
                                            onChange={(e) => this.selectIoiCutoff(e)}
                                            className="update-input"
                                            disabled={!this.state.isDsp || this.state.offeringStatusCheck}
                                        />
                                        {errors && this.state.isSubmited &&
                                            <span className="errorvalidation">
                                                {errors.ioi_cutoff_datetime}
                                            </span>
                                        }
                                    </div>
                                </div>
                            } */}
                        </div>
                    }
                    
                    {(this.state.offering_type_name === 'Overnight') ?
                      <div className="col-md-6 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
                        <Field
                          name="active_window"
                          component={renderField}
                          label="Active window in minutes *"
                          autoComplete="off"
                          type="text"
                          ref={(Elmt) => { this.active_window = Elmt; }}
                          onChange={this.handleChangeValue}
                          normalize={lower}
                          disabled={this.state.offeringStatusCheck}
                        />
                        {errors && this.state.isSubmited && <span className="errorvalidation">{errors.active_window}</span>}
                      </div>
                      :
                      null
                    }
                    
                    <div className="row mt-4">
                        <div className="col-12 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
                            <div className="row">
                                <div className="col-6 col-sm-12" style={{ padding: '5px 8px 5px 8px', color: 'rgba(0, 0, 0, 0.3)' }}>
                                    <Field
                                        name="file"
                                        type="file"
                                        component={customFileInput}
                                        ref={(ref) => { this.file = ref; }}
                                        onChange={this.selectInputFile}
                                        disabled={this.state.offeringStatusCheck}
                                    />
                                    {this.state.previewLogo && <img src={this.state.previewLogo} height="100" width="100" style={{ float: 'right', marginRight: '40%' }} alt=''/>}
                                    {!this.state.previewLogo && <img src={(this.state.offeringDetailsData) ? 'https:' + this.state.offeringDetailsData.logo_small : ''} height="100" width="100" alt=''/>}
                                </div>
                            </div>
                        </div>
                        <div className="col-4 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}></div>
                    </div>
                    {
                        (!this.state.offeringStatusCheck) &&
                        <div style={{textAlign: 'center'}}>
                            <Button variant="contained" color="primary" style={{ minWidth: 100, marginLeft: 10, marginRight: 10 }} onClick={() => this.onOpenModal()} disabled={submitting}>
                                Submit
                            </Button>
                        </div>
                    }
                </div>
                <Dialog
                    onClose={this.onCloseModal}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.openConfirmation}
                    maxWidth={"md"}
                    fullWidth={true}
                    scroll={'paper'}
                    >
                    <DialogTitle id="customized-dialog-title" onClose={this.onCloseModal}>
                        Offering Review
                    </DialogTitle>
                    <DialogContent>
                        <div className="col-12" style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 15 }}>
                            <div className="row" >
                                <div className="col-6 sidelabel">
                                    Company name
                                    </div>
                                <div className="col-6 rightlabel">
                                    {this.state.name}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 sidelabel" >
                                    Offering Type
                                </div>
                                <div className="col-6 rightlabel">
                                    {this.state.offering_type_name}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 sidelabel" >
                                    Ticker Symbol
                                </div>
                                <div className="col-6 rightlabel">
                                    {this.state.ticker_symbol}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 sidelabel" >
                                    CUSIP ID
                                </div>
                                <div className="col-6 rightlabel">
                                    {this.state.cusip_id}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 sidelabel" >
                                    EXT ID
                                </div>
                                <div className="col-md-6 rightlabel">
                                    {this.state.ext_id}
                                </div>
                            </div>
                            
                            {this.state.offering_type_name === 'IPO' &&
                                <div>
                                    <div className="row">
                                        <div className="col-md-6 sidelabel" >
                                            Min price
                                </div>
                                        <div className="col-md-6 rightlabel">
                                            {this.state.min_price ? numberWithCommasAndDollar(this.state.min_price) : 'TBD'}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 sidelabel" >
                                            Max price
                                </div>
                                        <div className="col-md-6 rightlabel">
                                            {this.state.max_price ? numberWithCommasAndDollar(this.state.max_price) : 'TBD'}
                                        </div>
                                    </div>
                                </div>
                            }
                            {(this.state.offering_type_name === 'Follow-on' || this.state.offering_type_name === 'ETF' || this.state.offering_type_name === 'UIT') &&
                                <div className="row">
                                    <div className="col-6 sidelabel" >
                                        Last closing price
                                    </div>
                                    <div className="col-6 rightlabel">
                                        {this.state.max_price ? numberWithCommasAndDollar(this.state.max_price) : 'TBD'}
                                    </div>
                                </div>
                            }
                            {(this.state.offering_type_name === 'Overnight') &&
                                <div>
                                    <div className="row">
                                        <div className="col-6 sidelabel" >
                                            Last closing price
                                        </div>
                                        <div className="col-6 rightlabel">
                                            {this.state.max_price ? numberWithCommasAndDollar(this.state.max_price) : 'TBD'}
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className="col-6 sidelabel" >
                                    Price range
                                </div>
                                {
                                    (this.state.isMinPriceChecked) && (this.state.isMaxPriceChecked) &&
                                    <div className="col-6 rightlabel">
                                        null
                                    </div>
                                }
                                {
                                    (!this.state.isMinPriceChecked) && (!this.state.isMaxPriceChecked) &&
                                    <div className="col-6 rightlabel">
                                        {numberWithCommasAndDollar(this.state.min_price)} - {numberWithCommasAndDollar(this.state.max_price)}
                                    </div>
                                }
                            </div>
                            <div className="row">
                                <div className="col-6 sidelabel" >
                                    Trade  Date
                                </div>
                                <div className="col-6 rightlabel">
                                    {
                                        this.state.trade_date &&
                                        <div>{this.state.trade_date}</div>
                                    }
                                    {
                                        !this.state.trade_date &&
                                        <div>TBD</div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 sidelabel" >
                                    Settlement Date
                                </div>
                                <div className="col-6 rightlabel">
                                    {
                                        this.state.settlement_date &&
                                        <div>{this.state.settlement_date}</div>
                                    }
                                    {
                                        !this.state.settlement_date &&
                                        <div>TBD</div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 sidelabel" >
                                    Listed Shares
                                </div>
                                <div className="col-md-6 rightlabel">
                                    {
                                        this.state.final_shares &&
                                        <div>{numberWithCommas(Number(this.state.final_shares))}</div>
                                    }
                                    {
                                        !this.state.final_shares &&
                                        <div>TBD</div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 sidelabel" >
                                    Industries
                                </div>
                                <div className="col-6 rightlabel">
                                    {this.state.industry}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 sidelabel" >
                                    Min ticket size
                                </div>
                                <div className="col-6 rightlabel">
                                    {
                                        this.state.ordr_dollar_share === 0 ? 
                                        <div>{numberWithCommasAndDollar(Number(this.state.min_ticket_size))}</div>
                                        :
                                        <div>{this.state.min_ticket_size}</div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 sidelabel" >
                                    Max ticket size
                                </div>
                                <div className="col-6 rightlabel">
                                    {
                                        this.state.ordr_dollar_share === 0 ? 
                                        <div>{numberWithCommasAndDollar(Number(this.state.max_ticket_size))}</div>
                                        :
                                        <div>{this.state.max_ticket_size}</div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 sidelabel" >
                                    Increment 
                                </div>
                                <div className="col-md-6 rightlabel">
                                    {
                                        <div>{this.state.unit_increment}</div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 sidelabel" >
                                    Description
                                </div>
                                <div className="col-6 rightlabel">
                                    {this.state.description}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 sidelabel" >
                                    Underwriter MPID
                                </div>
                                <div className="col-6 rightlabel">
                                    {this.state.bd_underwriter_mpid_id}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 sidelabel" >
                                    Underwriter concession amount
                                </div>
                                <div className="col-6 rightlabel">
                                    {this.state.underwriter_concession_amount}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 sidelabel" >
                                    Underwriter(s)
                                </div>
                                <div className="col-6 rightlabel">
                                    <div>
                                        {this.state.selectedUnderWritersList && this.state.selectedUnderWritersList.map((item, i) => (
                                            <span key={i}>{item}{(this.state.selectedUnderWritersList.length - 1) !== i ? ', ' : ''} </span>
                                        ))}
                                        {this.state.selectedUnderWritersList.length === 0 &&
                                            <div>Null</div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 sidelabel" >
                                    Blocked Broker dealers
                                </div>
                                <div className="col-md-6 rightlabel">
                                    <div>
                                        { this.state.selectedBrokerDealersList && this.state.selectedBrokerDealersList.map((item, i) => (
                                        <span key={i}>{item}{(this.state.selectedBrokerDealersList.length - 1) !== i ? ', ' : ''} </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 sidelabel" >
                                    Exchange
                                </div>
                                <div className="col-6 rightlabel">
                                    {this.state.exchange}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 sidelabel" >
                                    Brochure Link
                                </div>
                                <div className="col-6 rightlabel" style={{wordWrap:'break-word'}}>
                                    {this.state.brochure_url === '' ? 'null' : this.state.brochure_url }
                                </div>
                            </div>
                            {
                                (this.state.isDsp) &&

                                <div className="row">
                                    <div className="col-6 sidelabel" >
                                        DSP attestation file
                                    </div>
                                    <div className="col-6 rightlabel" style={{wordWrap:'break-word'}}>
                                        {this.state.dsp_attestation_file}
                                    </div>
                                </div>
                            }
                            {
                                (this.state.isDsp) &&

                                <div className="row">
                                    <div className="col-6 sidelabel" >
                                        DSP faq file
                                    </div>
                                    <div className="col-6 rightlabel" style={{wordWrap:'break-word'}}>
                                        {this.state.dsp_faq_file}
                                    </div>
                                </div>
                            }                            

                            {
                                this.state.dsp === 2 &&
                                <div className="row">
                                <div className="col-6 sidelabel" >
                                    IOI Cutoff Datetime
                                </div>
                                <div className="col-6 rightlabel">
                                    { this.state.ioi_cutoff_datetime}
                                </div>
                                </div>
                            }
                            
                            {
                                this.state.offering_type_name === 'Overnight' &&
                                <div className="row">
                                <div className="col-6 sidelabel" >
                                    Active window in minutes
                                </div>
                                <div className="col-6 rightlabel">
                                    {this.state.active_window}
                                </div>
                                </div>
                            }
                            
                            {
                                this.state.offering_type_name === 'Overnight' &&
                                <div className="row">
                                <div className="col-6 sidelabel" >
                                    Price Range
                                </div>
                                <div className="col-6 rightlabel">
                                    {this.state.price_range}
                                </div>
                                </div>
                            }
                            <div className="row">
                                    <div className="col-6 sidelabel" >
                                        Offering Logo
                                    </div>
                                <div className="col-6 rightlabel">
                                    {this.state.previewLogo && <img src={this.state.previewLogo} height="100" width="100" style={{ float: 'right', marginRight: '40%' }} alt=''/>}
                                    {!this.state.previewLogo && <img src={(this.state.offeringDetailsData) ? 'https:' + this.state.offeringDetailsData.logo_small : ''} height="100" width="100" alt=''/>}
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <div className="text_center">
                            <Button variant="contained" color="primary" style={{ minWidth: 100, marginLeft: 10, marginRight: 10 }} onClick={() => {this.handleFormSubmit(null)}}>
                                Submit
                            </Button>
                            <Button variant="contained" color="primary" style={{ minWidth: 100, marginLeft: 10, marginRight: 10 }} onClick={() => {this.onCloseModal()}}>
                                Cancel
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

UpdateOfferingForm.propTypes = {
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    submitUpdateOffering: PropTypes.func,
    updateOfferingRes: PropTypes.any,
    offeringIndustriesListRes: PropTypes.any,
    underWriterMpidListRes: PropTypes.any,
    lastClosingPriceRes: PropTypes.any,
    initialValues: PropTypes.any,
    brochureUrlRes: PropTypes.any,
    attestationUrlRes: PropTypes.any,
    faqUrlRes: PropTypes.any,
    brokerDealerListRes: PropTypes.any,
    fetchOfferingAssetTypeRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    updateOfferingRes: doUpdateOfferingRes,
    offeringIndustriesListRes: offeringIndustriesListRes,
    underWriterMpidListRes: underWriterMpidListRes,
    lastClosingPriceRes: lastClosingPriceRes,
    brochureUrlRes: brochureUrlRes,
    brokerDealerListRes: doBrokerDealerListRes,
    attestationUrlRes: attestationUrlRes,
    faqUrlRes: faqUrlRes,
    fetchOfferingAssetTypeRes: doFetchOfferingAssetTypeRes,
});

function mapDispatchToProps(dispatch, ownProps) {
    return {
        changeFieldValue: (field, value) => { dispatch(change('ReduxUpdateOfferingForm', field, value)); },
        submitUpdateOffering: (data, file, ext_id, url) => dispatch(submitUpdateOffering(data, file, ext_id, url)),
        getOfferingIndustriesList: () => dispatch(getOfferingIndustries()),
        getUnderwriterMpidList: () => dispatch(getUnderwriterMpid()),
        lastClosingPrice: (data) => dispatch(fetchLastClosingPriceData(data)),
        getOfferingDetail: (data) => dispatch(getOfferingDetail(data)),
        getBrokerDealers: () => dispatch(getBrokerDealerList()),
        getBrochureUrl: (data, ticker_symbol, type) => dispatch(getBrochureUrl(data, ticker_symbol, type)),
        getOfferingAssetType: () => dispatch(fetchOfferingAssetType()),
    };
}

const ReduxUpdateOfferingForm = reduxForm({
    form: 'ReduxUpdateOfferingForm',
    validate,
    fields,
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnBlur: false,
})(UpdateOfferingForm)

export default connect(mapStateToProps, mapDispatchToProps)(ReduxUpdateOfferingForm);
