import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './offerings.scss';
import Dashboard from '../../component/Dashboard';
import { getItem } from '../../utils/localStore';
import { getOfferingCallBacks, fetchOfferingCallBacksRes } from '../../actions/offeringCallBacksActions';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import GridTable from '@nadavshaar/react-grid-table';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import FileCopyIcon from '@material-ui/icons/FileCopy';
class OfferingCallBacksComponent extends React.PureComponent {
	
	constructor(props) {
		super(props);
		
		var columns = [
			{ id: 1, width: '10%', field: 'verb', label: 'Verb', visible: true, sortable: true, cellRenderer: this.callBackTemp },
			{ id: 2, width: '15%', field: 'sent_at', label: 'Sent At', visible: true, sortable: true, cellRenderer: this.callBackTemp },
			{ id: 3, width: '25%', field: 'tenant_callback', label: 'Tenant Callback', visible: true, sortable: true, cellRenderer: this.callBackTemp },
			{ id: 4, width: '40%', field: 'payload', label: 'Payload', visible: true, sortable: true, cellRenderer: this.callBackTemp },
			{ id: 5, width: '10%', field: 'state', label: 'State', visible: true, sortable: true, cellRenderer: this.callBackTemp }
			
		];
		this.state = {
			isReadOnly: JSON.parse(getItem('isReadOnly')),
			offeringCallBacks: [],
			cols: columns,
			offering: '',
			tabIndex: 0
		}
	}
	
	componentDidMount() {
		if (this.props.location.state) {
			let offeringCreate = this.props.location.state.offering;
			if (offeringCreate) {
				this.setState({
					offering: offeringCreate,
					tabIndex: this.props.location.state.tabIndex
				}, () => {
				});
            }
			let payload = {
				ext_id: this.props.location.state.offering.ext_id
			}
			this.props.getOfferingCallBacks(payload);
		}
	}
	
	static getDerivedStateFromProps(props, state) {
		if (props.getOfferingCallBacksRes) {
			if (props.getOfferingCallBacksRes.data && props.getOfferingCallBacksRes.data.offeringCallBacks) {
				if (props.getOfferingCallBacksRes.data.offeringCallBacks.message === 'Success') {
				
					return {
						offeringCallBacks: props.getOfferingCallBacksRes.data.offeringCallBacks.data,
					};
				}
			}
		}
		
		return null;
	}
	callBackTemp = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return  <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
			<Tooltip title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
				<div className="text-one-line" style={{width: '90%'}} >{value}</div>
			</Tooltip>
			{
				value !== '' &&
				<Tooltip title={'Copy to clipboard'} interactive >
					<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
				</Tooltip>
			}
		</div>;
	}
	goBack = () => {
		this.props.history.goBack();
		this.props.history.push({
			pathname: `/offerings`,
			state: {
				tabIndex: this.state.tabIndex,
			}
		});
	}
	opencollapse = (data) => {
		let callbacks = this.state.offeringCallBacks
		for (let item of callbacks) {
			if (item.name !== data.name) {
				item.show = false;
			}
		}
		if (data.show !== true) {
			data.show = true;
		} else if (data.show === true) {
			data.show = false;
		}
		this.setState({
			renderUI: !this.state.renderUI,
			offeringCallBacks: callbacks
		}, () => { });
	}
	render() {
		const { offering } = this.state;
		return (
			<Dashboard title="ClickIPO Offerings - CallBacks" pageTitle="Offering CallBacks" >
				<div className="offering_callbacks_content">
					<div>
						<div className="row offeringSubheader">
							<div className="col-md-12">
								<div className="orders-page_header">
									<div className="Headingname">
										<ArrowBackIosIcon onClick={() => this.goBack()} style={{ cursor: 'pointer' }} /> {offering.name} ({offering.ticker_symbol}) CallBacks {this.state.uiRender}
									</div>
								</div>
							</div>
						</div>
						<div>
							<div className="offering-callback-body-content">
								<div className="mt-4">
									{
										(this.state.offeringCallBacks) && this.state.offeringCallBacks.map((item, i) => (
											<div key={i} className="callback-lists">
												<div className="callback-lists-name-box">
													<div className="callback-lists-name-plus" onClick={() => {
														this.opencollapse(item)
													}}>
														{
															(!item.show) ?
																<div className="callback-lists-name-plus-icon" >+</div>
																:
																<div className="callback-lists-name-plus-icon" >-</div>
														}
														<div className="callback-lists-name">{item.name} ({item.mpid})</div>
													</div>
												</div>
												{
													(item.show) &&
													<div className="callback-lists-callback_items">
														
														<GridTable
															columns={this.state.cols}
															rows={item.callbacks}
															isPaginated={true}
															isVirtualScroll={true}
															showSearch={true}
															showRowsInformation={false}
															isHeaderSticky={true}
														/>
													</div>
												}
											</div>
										))
									}
									{
										this.state.offeringCallBacks.length === 0 &&
										<div style={{fontWeight: 600, textAlign: 'center', marginTop: 70}}>No data found!</div>
									}
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</Dashboard>
		)
	}
}

OfferingCallBacksComponent.propTypes = {
	getOfferingCallBacksRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	getOfferingCallBacksRes: fetchOfferingCallBacksRes,
});


function mapDispatchToProps(dispatch) {
	return {
		getOfferingCallBacks: (request) => {dispatch(getOfferingCallBacks(request)); },
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OfferingCallBacksComponent);