import * as types from './actionTypes';  
import userCreationByDateApi from '../api/userCreationByDateApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';
export function doFetchCreatedUsers() {
  return {
    type: types.DO_FETCH_CREATED_USERS, 
    
  };
}
export function doFetchCreatedUsersRes(data) {  
  return {
    type: types.DO_FETCH_CREATED_USERS_RES, 
    data
  };
}
export function fetchCreatedUsers() {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      userCreationByDateApi.doFetchCreatedUsers().then(data => {
        dispatch(doFetchCreatedUsersRes(data));
        dispatch(doFetchCreatedUsersRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  } 
}