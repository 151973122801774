import { BASE_URL } from '../constants';
import { getItem } from '../utils/localStore';
import { errorHandling, errorHandler } from '../utils/errorHandling';

class ordersMetaDataApi {
    static doFetchOrdersMetadata(ext_id) {
        const TOKEN = getItem('auth_token');
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'GET',
                headers: ajaxRequestHeaders,
            }
            return fetch(BASE_URL + `/offerings/orders/meta_data?ext_id=${ext_id}`, body).then(response => {
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }
}

export default ordersMetaDataApi;