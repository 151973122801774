import * as types from './actionTypes';
import {logout} from '../helper/helper';
import updateOfferingApi from '../api/updateOfferingApi';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { uploadOfferingLogo } from './logoUploadForOfferingActions';
import { loadingRes } from './loadingActions';
export function doUpdateOffering(data, ext_id, url) {
  return {
    type: types.UPDATE_OFFERING,
    data,
    ext_id, 
    url
  };
}

export function doUpdateOfferingRes(data) {
  return {
    type: types.UPDATE_OFFERING_RES,
    data
  };
}

export function submitUpdateOffering(payload, file, ext_id) {
  let extId = ext_id;
  const TOKEN = getItem('auth_token');
  let logo = file;
  if (logo) {
    logo = logo[0];
  } else {
    logo = null;
  }
  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      
      updateOfferingApi.doUpdateOffering(payload, ext_id).then(data => {
        if (data.message === "Success") {
          
          if (logo) {
            dispatch(uploadOfferingLogo(logo, extId));
            setTimeout(() => {
              dispatch(doUpdateOfferingRes(data));
              dispatch(doUpdateOfferingRes(null));
            }, 1500);
          } else {
            dispatch(doUpdateOfferingRes(data));
            dispatch(doUpdateOfferingRes(null));
          }
        }
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        } 
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
}