import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './createOffering.scss';
import Dashboard from '../../component/Dashboard';
import CreateOfferingForm from './createOfferingForm/createOfferingForm';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { doCreateOfferingRes } from '../../actions/createOfferingActions';

class CreateOfferingComponent extends React.PureComponent {

    constructor() {
        super();
        this.state = {
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.createOfferingRes) {
            if(props.createOfferingRes.data && props.createOfferingRes.data.createOffering){
				if(props.createOfferingRes.data.createOffering.message === "Success") {
                    props.history.goBack();
                }
			}
        }
		return null;
	}
    handleBack = () => {
        this.props.history.goBack();
    }

    handleSubmit(data){
        this.setState({isLoader: false});
    }

    render() {
        // const spinner = <span><img src={loaderImg} alt="" /></span>;
        return (
            <Dashboard title="ClickIPO Admin - Create Offering" pageTitle="Create Offering">
                    <div className="create_offering_content_wrapper create_offering">
                        <div className="createprofile_heading">
                            <div className="createprofile_back_icon_text"  onClick={this.handleBack}>
                                {/* <img src={BackIcon} alt="" className="createprofile_back_icon" /> */}
                                <span className="createprofile_go_back"><ArrowBackIosIcon />Back to offering</span>
                            </div>
                        </div>
                        <div className="editprofile_content">
                            <div className="form_content_editprofile edit_profile_form_fields_wrapper">
                                <CreateOfferingForm {...this.props} handleFormSubmit={(data) => { this.handleSubmit(data) }} />
                            </div>
                        </div>
                    </div>
            </Dashboard>
        )
    }
}

CreateOfferingComponent.propTypes = {
    handleFormSubmit: PropTypes.func,
    createOfferingRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    createOfferingRes: doCreateOfferingRes,
});

function mapDispatchToProps(dispatch) {
    return {
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CreateOfferingComponent);
