import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { createStructuredSelector } from 'reselect';
import Dashboard from '../../../../component/Dashboard';
import { updateWhiteListedIPs, doUpdateWhiteListedIPsRes } from '../../../../actions/whitelistedIPsActions';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import validate from './formValidation';
class UpdateWhiteListedIPForm extends React.Component { 
  constructor() {
    super();
    this.state = {
      oldHostIP: '',
      hostIP: '',
      description: '',
      whiteListedDetails: '',
      tabIndex: 0,
      isSubmitted: false,
    }
  }
  componentDidMount() {
    let whiteListData = this.props.location.state.whitelistedIP;
    if (whiteListData) {
      this.setState({
        oldHostIP: whiteListData.ip_host,
        hostIP: whiteListData.ip_host,
        description: whiteListData.description,
        tabIndex: this.props.location.state.tabIndex,
      })
    }
  }
  
  static getDerivedStateFromProps(props, state) {
    if (props.updateWhiteListedIPsRes) {
      if (props.updateWhiteListedIPsRes.data && props.updateWhiteListedIPsRes.data.whiteListedIPs) {
        if (props.updateWhiteListedIPsRes.data.whiteListedIPs.message === "Success") {
          props.history.push({
            pathname: `/system-setting`,
            state: {
                tabIndex: state.tabIndex,
            }
          });
        }
      }
    }
		return null
	}
  goBack = () => {
    this.props.history.push({
      pathname: `/system-setting`,
      state: {
          tabIndex: this.state.tabIndex,
      }
    });
  }
  updateWhiteListedIP = () => {
    this.setState({
      isSubmitted: true,
    }, () => { });
    validate(this.state);
    const errors = validate(this.state);
    
    if (Object.keys(errors).length === 0) {
      let payload = {
        old_ip_host: this.state.oldHostIP,
        ip_host: this.state.hostIP,
        description: this.state.description,
      }
      this.props.handleFormSubmit(payload);
    } 
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  render() {
    const errors = validate(this.state);
    return (
      <Dashboard title="ClickIPO - Update WhiteListed IP" pageTitle="Update WhiteListed IP">
        <div className="admin_offering_content">
          <div className="">
            <div  className="Headingname">
              <ArrowBackIosIcon onClick={() => this.goBack()} style={{ cursor: 'pointer' }} /> Back
            </div>
            <form autoComplete="off"  style={{ marginTop: 30 }}>
              <div className="row">
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="oldHostIP" fullWidth label="Old Host IP" value={this.state.oldHostIP !== undefined ? this.state.oldHostIP : ''} InputProps={{ readOnly: true, }} />
                  {errors && this.state.isSubmitted && <span className="errorvalidation">{errors.oldHostIP}</span>}
                </div>
                <div className="col-md-6 col-sm-12" style={{ marginTop: 20 }}>
                  <TextField name="hostIP" fullWidth label="Host IP" value={this.state.hostIP !== undefined ? this.state.hostIP : ''} onChange={this.handleChange} />
                  {errors && this.state.isSubmitted && <span className="errorvalidation">{errors.hostIP}</span>}
                </div>
                <div className="col-md-12 col-sm-12" style={{ marginTop: 20 }}>
                  <TextField multiline name="description" fullWidth label="Description" value={this.state.description !== undefined ? this.state.description : ''} onChange={this.handleChange} />
                  {errors && this.state.isSubmitted && <span className="errorvalidation">{errors.description}</span>}
                </div>
              </div>
            </form>
            <div className="row">
              <div className="col-md-12" style={{ textAlign: 'center' }}>
                <Button variant="contained" color="primary" onClick={this.updateWhiteListedIP} style={{ marginTop: '30px' }} >Update</Button>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    );
  }
}

UpdateWhiteListedIPForm.propTypes = {
  updateWhiteListedIPsRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
  updateWhiteListedIPsRes: doUpdateWhiteListedIPsRes,
});

function mapDispatchToProps(dispatch) {
  return {
    handleFormSubmit: (data) => dispatch(updateWhiteListedIPs(data)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateWhiteListedIPForm);
