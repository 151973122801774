import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import './allocations.scss';
import Dashboard from '../../component/Dashboard';
import { getItem } from '../../utils/localStore';
import { fetchAllocationList, doFetchPendingAllocationListRes, doFetchCompleteAllocationListRes } from '../../actions/allocationListActions';
import { fetchAllocationOfferingList, doFetchAllocationOfferingListRes } from '../../actions/allocationOfferingListActions';
import { uploadIntactFile, doCallIntactFileRes } from '../../actions/callIntactFileActions';
import { uploadBraggart, doCallBraggartRes } from '../../actions/callBraggartActions';
import { callShowIntactFile, doCallShowIntactFileRes } from '../../actions/callShowIntactFileActions';
import { doRunAllocationRes } from '../../actions/runAllocationActions';
import AllocationListComponent from './allocationList';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
// import { Calendar } from 'primereact/components/calendar/Calendar';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { DialogActions } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
	}))(props => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});
  
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

let isPendingAllocation = false;
let isCompleteAllocation = false;
class AllocationComponent extends React.PureComponent {

	constructor(props) {
		super(props);
		isPendingAllocation = false;
		isCompleteAllocation = false;
		
		this.state = {
			isReadOnly: JSON.parse(getItem('isReadOnly')),
			pendingAllocation: [],
			completeAllocation: [],
			searching: '',
			showIntactData: false, 
			datesRange: null,
			tabIndex: 0,
			uiRender: false,
			getIntactData: '',
			sendToIntactOpen: false,
			pagenumPending: 1,
			pagenumCompleted: 1,
			isFetchPending: false,
			isFetchCompleted: false,
			lastPendingAllocation: 0,
			lastCompletedAllocation: 0,
			pendingAllocationLength: 0,
			completedAllocationLength: 0,
			min_alloc_amount:'',
			min_alloc_shares:'',
			isSelectedIntact: true,
			isSelectedBr: true,
		}
	}

	componentDidMount() {
		if (this.props.location.state) {
			if (this.props.location.state.tabIndex) {
				this.setState({
					tabIndex: this.props.location.state.tabIndex
				}, () => {
					if (this.state.tabIndex === 1) {
						isCompleteAllocation = true;
						let request = {
							status: 'completed',
							pagenum: 1,
						}
						this.props.fetchAllocationList(request, true);
						delete this.props.location.state.tabIndex;
					}
				});
			}
		}
		if (this.state.pendingAllocation.length === 0) {
			isPendingAllocation = true;
			let request = {
				status: 'pending',
				pagenum: 1,
			}
			this.props.fetchAllocationList(request, true);
		}
	}
	static getDerivedStateFromProps(props, state) {
		if (props.pendingAllocationListRes) {
			if (props.pendingAllocationListRes.data && props.pendingAllocationListRes.data.allocationPendingList) {
				if (props.pendingAllocationListRes.data.allocationPendingList.message === 'Success' && isPendingAllocation) {
					isPendingAllocation = false;
					const pendingAllocationList = [];
					if(props.pendingAllocationListRes.data.allocationPendingList.data.data) {
						for (const items of props.pendingAllocationListRes.data.allocationPendingList.data.data) {
							pendingAllocationList.push(items.offering_info);
						}
					}
					var isAllPendingAllocation = state.pendingAllocationLength;
                    if(state.pendingAllocationLength === 0){
                        isAllPendingAllocation = pendingAllocationList.length
                    }
					if (state.isFetchPending) {
						return {
							lastPendingAllocation: pendingAllocationList.length,
							pendingAllocationLength: isAllPendingAllocation,
							pendingAllocation: state.pendingAllocation.concat(pendingAllocationList),
							isFetchPending: false,							
							min_alloc_amount:props.pendingAllocationListRes.data.allocationPendingList.data.min_alloc_amount,
							min_alloc_shares:props.pendingAllocationListRes.data.allocationPendingList.data.min_alloc_shares,
						};
					} else {
						return {
							lastPendingAllocation: pendingAllocationList.length,
							pendingAllocationLength: isAllPendingAllocation,
							pendingAllocation: pendingAllocationList,
							min_alloc_amount:props.pendingAllocationListRes.data.allocationPendingList.data.min_alloc_amount,
							min_alloc_shares:props.pendingAllocationListRes.data.allocationPendingList.data.min_alloc_shares,
						};
					}
				}
			}
		}
		if (props.completeAllocationListRes) {
			if (props.completeAllocationListRes.data && props.completeAllocationListRes.data.allocationCompletedList) {
				if (props.completeAllocationListRes.data.allocationCompletedList.message === 'Success' && isCompleteAllocation) {
					isCompleteAllocation = false;
					const completeAllocationList = [];
					if(props.completeAllocationListRes.data.allocationCompletedList.data.data){
						for (const item of props.completeAllocationListRes.data.allocationCompletedList.data.data) {
							completeAllocationList.push(item.offering_info);
						}
					}
					var isAllCompleteAllocation = state.completedAllocationLength;
                    if(state.completedAllocationLength === 0){
                        isAllCompleteAllocation = completeAllocationList.length
                    }
					if (state.isFetchCompleted) {
						return {
							lastCompletedAllocation: completeAllocationList.length,
							completedAllocationLength: isAllCompleteAllocation,
							completeAllocation: state.completeAllocation.concat(completeAllocationList),
							isFetchCompleted: false,
						};
					} else {
						return {
							lastCompletedAllocation: completeAllocationList.length,
							completedAllocationLength: isAllCompleteAllocation,
							completeAllocation: completeAllocationList
						};
					}
				}
			}
		}
		if (props.callShowIntactFileRes) {
			if (props.callShowIntactFileRes.data && props.callShowIntactFileRes.data.callShowIntactFile) {
				if (props.callShowIntactFileRes.data.callShowIntactFile.message === 'Success') {
					return {
						getIntactData: props.callShowIntactFileRes.data.callShowIntactFile.data
					};
				}
			}
		}
		return null
	}
	searchAllocation = (event) => {
		this.setState({
			searching: event.target.value,
		}, () => {
		});
	}
	doSearchAllocation = () => {
		if (this.state.searching !== '') {
			if (this.state.tabIndex === 0) {
				isPendingAllocation = true;
				let request = {
					status: 'pending',
					pagenum: 1,
					search_key: 'name',
					search_keyword: this.state.searching
				}
				this.props.fetchAllocationList(request, true);
				this.setState({
					pagenumPending: 1
				})
			} else if (this.state.tabIndex === 1) {
				isCompleteAllocation = true;
				let request = {
					status: 'completed',
					pagenum: 1,
					search_key: 'name',
					search_keyword: this.state.searching
				}
				this.props.fetchAllocationList(request, true);
				this.setState({
					pagenumCompleted: 1
				})
				
			}
		}
	}
	sendIntactUpload = () => {
		if (this.state.completeAllocation.length > 0) {
			let extIds = [];
			for (const items of this.state.completeAllocation) {
				if (items.checked === true) {
					extIds.push(items.ext_id);
				}
			}
			extIds = extIds.join(',');
			this.props.callIntactUpload(extIds);
		}
	}
	sendBraggartUpload = () => {
		if (this.state.completeAllocation.length > 0) {
			let extIds = [];
			for (const items of this.state.completeAllocation) {
				if (items.selected === true) {
					extIds.push(items.ext_id);
				}
			}
			let allExtIds = [];
			for (const ext of extIds) {
				for (const extIds of this.state.completeAllocation) {
					let checkExt = extIds.ext_id.includes(ext);
					if (checkExt) {
						allExtIds.push(extIds.ext_id)
					}
				}
			}
			extIds = allExtIds.join(',');
			this.props.uploadBraggart(extIds);
		}
	}
	documentIntactFile = () => {
		if (this.state.completeAllocation.length > 0) {
			let extIds = [];
			for (const items of this.state.completeAllocation) {
				if (items.selected === true) {
					extIds.push(items.ext_id);
				}
			}
			let allExtIds = [];
			for (const ext of extIds) {
				for (const extIds of this.state.completeAllocation) {
					let checkExt = extIds.ext_id.includes(ext);
					if (checkExt) {
						allExtIds.push(extIds.ext_id)
					}
				}
			}
			extIds = allExtIds.join(',');
			this.props.callShowIntactFile(extIds);
			this.setState({
				sendToIntactOpen: true,
				showIntactData: true,
			});
		}
	}

	sendToIntactClose = () => {
		this.setState({
			sendToIntactOpen: false,
			showIntactData: false,
		});
	}

	createDateRange = () => {
		this.setState({
			datesRange: null
		}, () => { });
	}
	
	handleTabChange = (event, newValue) => {
		if (this.state.tabIndex !== newValue) {
			this.setState({
				tabIndex: newValue,
				uiRender: !this.state.uiRender,
			}, () => {
				if (this.state.tabIndex === 0) {
					isPendingAllocation = true;
					let request = {
						status: 'pending',
						pagenum: 1,
					}
					this.props.fetchAllocationList(request, true);
					this.setState({
						searching: '',
						completeAllocation: [],
					})
				} else {
					isCompleteAllocation = true;
					let request = {
						status: 'completed',
						pagenum: 1,
					}
					this.props.fetchAllocationList(request, true);
					this.setState({
						searching: '',
						pendingAllocation: [],
					})
				}
			});
		}
	}
	
	downloadFile = () => {
		var data = this.state.getIntactData;
		let csvContent = 'data:text/plain;charset=utf-8,'+encodeURIComponent(data);
		const link = document.createElement('a');
		link.setAttribute('href', csvContent);
		link.setAttribute('download', 'intact.dat');
		document.body.appendChild(link);
		link.click();
	}

	fetchAllocationData = () => {
		if (this.state.tabIndex === 0) {
			if ((this.state.lastPendingAllocation === this.state.pendingAllocationLength) && !isPendingAllocation) {
				isPendingAllocation = true;
				let request = {
					status: 'pending',
					pagenum: this.state.pagenumPending + 1,
				}
				if (this.state.searching !== '') {
					request.search_key = 'name';
					request.search_keyword = this.state.searching;
				}
				this.setState({
					pagenumPending: request.pagenum,
					isFetchPending: true
				})
				this.props.fetchAllocationList(request, true);
			}
			
		} else if (this.state.tabIndex === 1) {
			if ((this.state.lastCompletedAllocation === this.state.completedAllocationLength) && !isCompleteAllocation) {
				isCompleteAllocation = true;
				let request = {
					status: 'completed',
					pagenum: this.state.pagenumCompleted + 1,
				}
				if (this.state.searching !== '') {
					request.search_key = 'name';
					request.search_keyword = this.state.searching;
				}
				this.setState({
					pagenumCompleted: request.pagenum,
					isFetchCompleted: true
				})
				this.props.fetchAllocationList(request, true);
			}
		}
	}
	doRefreshAllocation = () => {
		if (this.state.tabIndex === 0) {
			isPendingAllocation = true;
			let request = {
				status: 'pending',
				pagenum: 1,
			}
			this.setState({pagenumPending: 1});
			this.props.fetchAllocationList(request, true);
		} else if (this.state.tabIndex === 1) {
			isCompleteAllocation = true;
			let request = {
				status: 'completed',
				pagenum: 1
			}
			this.setState({pagenumCompleted: 1});
			this.props.fetchAllocationList(request, true);
			
		}
	}
	handleBlur = () => {
		if (this.state.searching === '') {
			this.doRefreshAllocation();
		}
	}
	reloadAllocations = () => {
		isCompleteAllocation = true;
		let request = {
			status: 'completed',
			pagenum: 1
		}
		this.setState({pagenumCompleted: 1});
		this.props.fetchAllocationList(request, true);
	}
	selectedFiles = (data) => {
		let isChecked = 0;
		for (let item of data) {
			if (item.checked) {
				isChecked = 1;
			}
		}
		if (isChecked === 1) {
			this.setState({
				isSelectedIntact: false,
				isSelectedBr: true,
			})
		} else {
			this.setState({
				isSelectedIntact: true,
				isSelectedBr: true,
			})
		}
	}
	selectedIntactBr = (data) => {
		let isSelect = 0;
		for (let item of data) {
			if (item.selected) {
				isSelect = 1;
			}
		}
		if (isSelect === 1) {
			this.setState({
				isSelectedIntact: true,
				isSelectedBr: false,
			})
		} else {
			this.setState({
				isSelectedIntact: true,
				isSelectedBr: true,
			})
		}
	}
	render() {
		const { tabIndex, completeAllocation, pendingAllocation, isReadOnly } = this.state;
		let completeAllocationFilter = completeAllocation;
		let pendingAllocationFilter = pendingAllocation;
		return (
			// <Loader show={this.state.isLoader} message={spinner}>
				<Dashboard title="ClickIPO Admin - Allocation" pageTitle="Allocations" >
					<div className="allocation_content">
						<div>
							<div className="row">
								<div className="col-md-12">
									<div className=" offeringGlobalSearch">
										{/* <div className="row calenderBox" >
											<div className="col col-12" style={{position: 'relative'}} >
												<Calendar placeholder="Date Range" value={this.state.datesRange} onChange={(e) => this.setState({datesRange: e.value})} selectionMode="range" readonlyInput={true} />

												{(this.state.datesRange !== null) &&
												<span className="calenderBoxCross" onClick={this.createDateRange} >&times;</span>
												}
												
											</div>
										</div> */}
										<div style={{ padding: '20px 0px 0px 15px' }}>
											<TextField id="searchInput" type="text" placeholder="Search" onChange={(e) => this.searchAllocation(e)} onBlur={() => this.handleBlur()} value={this.state.searching} 
												InputProps={{
													endAdornment: (
													  	<InputAdornment position="start">
															<Button variant="contained" color="primary" style={{borderRadius: '0px 4px 4px 0px', minWidth: 50, top: 19, right: 0, left: 8, height: 38}} onClick={() => this.doSearchAllocation()} disabled={this.state.searching !== '' ? false : true}><SearchIcon /></Button>
													  	</InputAdornment>
													)
												}}
											/>
										</div>
										<div style={{ padding: '20px 0px 0px 15px' }}>
											<Button variant="contained" color="primary" onClick={() => this.documentIntactFile()}>Intact File Download</Button>
										</div>
										{/* {
											(!isReadOnly) &&
											<div style={{ padding: '20px 0px 0px 15px' }}>
												<Button variant="contained" color="primary" onClick={() => this.sendIntactUpload()} disabled={this.state.isSelectedIntact ? true : false}>Upload Intact File</Button>
											</div>
										} */}
										{
											(!isReadOnly) &&
											<div style={{ padding: '20px 0px 0px 15px' }}>
												<Button variant="contained" color="primary" onClick={() => this.sendBraggartUpload()} disabled={this.state.isSelectedBr ? true : false}>Upload Braggart</Button>
											</div>
										}
										{
											(!isReadOnly) &&
											<div style={{ padding: '20px 0px 0px 15px' }}>
												<Button component={Link} to="/create-allocation" variant="contained" color="primary">New Allocation</Button>
											</div>
										}
									</div>
								</div>
							</div>
							
							<Tabs value={tabIndex} onChange={this.handleTabChange} style={{ padding: '20px 0px 0px 0px' }}>
								<Tab label="PENDING" />
								<Tab label="COMPLETE" />
							</Tabs>
							{
								tabIndex === 0 &&
								<Typography component="div" style={{ paddingTop: 24, paddingBottom: 24 }} className="offering-box-conent">
									<div style={{ maxWidth: "100%" }}>
										<AllocationListComponent min_alloc_amount={this.state.min_alloc_amount}	min_alloc_shares={this.state.min_alloc_shares} AllocationView={pendingAllocationFilter} history={this.props.history} tabIndex={tabIndex} fetchAllocationData={(data) => this.fetchAllocationData(data)} isLoadAllocation={isPendingAllocation} lastData={this.state.lastPendingAllocation} reloadAllocation={() => this.reloadAllocations()} />
										{
											pendingAllocationFilter && pendingAllocationFilter.length === 0 && (!isPendingAllocation) &&
											<div className="nodatafound" style={{ paddingTop: '30px', paddingLeft: '20px' }}>
												No allocation found!
											</div>
										}
									</div>
								</Typography>
							}
							{
								tabIndex === 1 &&
								<Typography component="div" style={{ paddingTop: 24, paddingBottom: 24 }} className="offering-box-conent">
									<div style={{ maxWidth: "100%" }}>
										<AllocationListComponent min_alloc_amount={this.state.min_alloc_amount}	min_alloc_shares={this.state.min_alloc_shares} AllocationView={completeAllocationFilter} history={this.props.history} tabIndex={tabIndex} fetchAllocationData={(data) => this.fetchAllocationData(data)} isLoadAllocation={isCompleteAllocation} lastData={this.state.lastCompletedAllocation} reloadAllocation={() => this.reloadAllocations()} selectedIntact={(data) => this.selectedFiles(data)} selectedBr={(data) => this.selectedIntactBr(data)}/>
										{
											completeAllocationFilter && completeAllocationFilter.length === 0 && (!isCompleteAllocation) &&
											<div className="nodatafound" style={{ paddingTop: '30px', paddingLeft: '20px' }}>
												No allocation found!
											</div>
										}
									</div>
								</Typography>
							}
							<Dialog
								onClose={() => this.sendToIntactClose()}
								aria-labelledby="customized-dialog-title"
								open={this.state.sendToIntactOpen}
								maxWidth={"sm"}
								fullWidth={true}
								scroll={'paper'}
								>
								<DialogTitle onClose={() => this.sendToIntactClose()} className="offering-detail-title">Intact Data</DialogTitle>
								<DialogContent>
									<div className="col-md-12">
										<span>{this.state.getIntactData}</span>
									</div>
								</DialogContent>
								
								<DialogActions style={{display: 'block'}}>
									<div style={{textAlign: 'center'}}>
										<Button variant="contained" color="primary" onClick={() => this.downloadFile()} disabled={this.state.getIntactData !== '' ? false : true}>Download</Button>
									</div>
								</DialogActions>
							</Dialog>
						</div>
					</div>
				</Dashboard>
			// </Loader>
		)
	}
}

AllocationComponent.propTypes = {
	pendingAllocationListRes: PropTypes.any,
	completeAllocationListRes: PropTypes.any,
	allocationOfferingListRes: PropTypes.any,
	runAllocationRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	pendingAllocationListRes: doFetchPendingAllocationListRes,
	completeAllocationListRes: doFetchCompleteAllocationListRes,
	allocationOfferingListRes: doFetchAllocationOfferingListRes,
	callIntactFileRes: doCallIntactFileRes,
	callBraggartRes: doCallBraggartRes,
	callShowIntactFileRes: doCallShowIntactFileRes,
	runAllocationRes: doRunAllocationRes,
});

function mapDispatchToProps(dispatch) {
	return {
		fetchAllocationList: (request, isLoading) => dispatch(fetchAllocationList(request, isLoading)),
		fetchAllocationOfferingList: () => dispatch(fetchAllocationOfferingList()),
		callIntactUpload: (ext_id) => { dispatch(uploadIntactFile(ext_id)) },
		uploadBraggart: (ext_ids) => { dispatch(uploadBraggart(ext_ids)) },
		callShowIntactFile: (ext_ids) => { dispatch(callShowIntactFile(ext_ids)) },
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AllocationComponent);
