import * as types from './actionTypes';  
import brochureUrlApi from '../api/brochureUrlApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';

export function brochureUrl(data, ticker_symbol, selectType) {

  return {
    type: types.BROCHURE_URL_OFFERING, 
    data,
    ticker_symbol,
    selectType
  };
}

export function brochureUrlRes(data) {  
  return {
    type: types.BROCHURE_URL_OFFERING_RES, 
    data
  };
}
export function attestationUrlRes(data) {  
  return {
    type: types.ATTESTATION_URL_OFFERING_RES, 
    data
  };
}
export function faqUrlRes(data) {  
  return {
    type: types.FAQ_URL_OFFERING_RES, 
    data
  };
}
export function additionalUrlRes(data) {  
  return {
    type: types.ADDITIONAL_URL_OFFERING_RES, 
    data
  };
}
export function getBrochureUrl(data, ticker_symbol, selectType) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      brochureUrlApi.brochureUrl(data, ticker_symbol).then(data => {
        
        if (selectType === 'brochure') {
          dispatch(brochureUrlRes(data));
          dispatch(brochureUrlRes(null));
        }else
        if (selectType === 'attestation') {
          dispatch(attestationUrlRes(data));
          dispatch(attestationUrlRes(null));
        }else
        if (selectType === 'faq') {
          dispatch(faqUrlRes(data));
          dispatch(faqUrlRes(null));
        }else
        if (selectType === 'additional') {
          dispatch(additionalUrlRes(data));
          dispatch(additionalUrlRes(null));
        }else
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
  
}