export default function validate(values) {
  const errors = {};
  if (!values.name) {
    errors.name = 'Please enter name';
  }
  if (!values.subject) {
    errors.subject = 'Please enter subject';
  }
  if (!values.body) {
    errors.body = 'Please enter a message';
  }
  return errors;
}
