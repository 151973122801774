import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { sendNotifications, doSendNotificationRes } from '../../actions/sendNotificationActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
	}))(props => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});
  
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

let sendNoti = false;
class NotificationModal extends React.Component {


  constructor() {
    super();

    sendNoti = false;
    this.state = {
      isSubmit: null,
      title: '',
      message: '',
      class: 1, // the default value of email is MANDATORY
      chkbox: null,
      file: null,
      checkedMand: true,
    };

    this.onFileChange = this.onFileChange.bind(this); 
  }

  setTitle(event) {
    this.setState({
      title: event.target.value,
    });
  }
  setMessage(event) {
    this.setState({
      message: event.target.value,
    });
  }
  isMandatory(event) {
    if (event.target.checked === true) {
      this.setState({
        class: 1, // MANDOTORY EMAIL
      });
    } else {
      this.setState({
        class: 16, // PROMOTIONAL EMAIL
      });
    }
  }
  allBDCheck(event) {
    if (event.target.checked === true) {
      this.setState({
        chkbox: true,
      });
    } else {
      this.setState({
        chkbox: false,
      });
    }
  }
  sendNotifications() {
    const checkChecked = [];

    const activeAll = [];
    const activeCustomer = [];

    const closeAll = [];
    const closeCustomer = [];

    const cancelAll = [];
    const cancelCustomer = [];

    let mpids = [];
    let activeOrderStatus = "";
    let ClosedOrderStatus = "";
    let CancelledOrderStatus = "";

    if (this.props.selectedOption === 'active') {
      activeOrderStatus = "0x1";
    }
    if (this.props.selectedOption === 'closed') {
      ClosedOrderStatus = "0x10";
    }
    if (this.props.selectedOption === 'cancelled') {
      CancelledOrderStatus = "0x100";
    }
    
    let orderStatus = "";
    if(activeOrderStatus !== ""){
      orderStatus = activeOrderStatus;
    }
    if(ClosedOrderStatus !== ""){
      orderStatus = ClosedOrderStatus;
    }
    if(CancelledOrderStatus !== ""){
      orderStatus = CancelledOrderStatus;
    }
    if(activeOrderStatus !== "" && ClosedOrderStatus !==""){
      orderStatus = "0x11";
    }
    if(activeOrderStatus !== "" && CancelledOrderStatus !==""){
      orderStatus = "0x101";
    }
    if(ClosedOrderStatus !== "" && CancelledOrderStatus !==""){
      orderStatus = "0x110";
    }
    if(activeOrderStatus !== "" && ClosedOrderStatus !== "" && CancelledOrderStatus !==""){
      orderStatus = "0x111";
    }

    if (this.props.totalCustomer.length > 0) {
      for (const items of this.props.totalCustomer){
        mpids.push(items.mpid);
      }
    }

    if (checkChecked.length > 1) {
    //   isSendFlag = 0;
    } else {
      if (this.props.selectedOption === 'active') {
        for (const items of this.props.totalCustomer) {
          if (items.order_info) {
            if (items.order_info.status === 'active') {
              activeAll.push(items);
            }
          }
        }
        for (const items of this.props.customerListing) {
          if (items.order_info) {
            if (items.order_info.status === 'active') {
              activeCustomer.push(items);
            }
          }
        }
      }

      if (this.props.selectedOption === 'closed') {
        for (const items of this.props.totalCustomer) {
          if (items.order_info) {
            if (items.order_info.status === 'closed') {
              closeAll.push(items);
            }
          }
        }

        for (const items of this.props.customerListing) {
          if (items.order_info) {
            if (items.order_info.status === 'closed') {
              closeCustomer.push(items);
            }
          }
        }
      }
      if (this.props.selectedOption === 'cancelled') {
        for (const items of this.props.totalCustomer) {
          if (items.order_info) {
            if (items.order_info.status === 'cancelled') {
              cancelAll.push(items);
            }
          }
        }

        for (const items of this.props.customerListing) {
          if (items.order_info) {
            if (items.order_info.status === 'cancelled') {
              cancelCustomer.push(items);
            }
          }
        }
      }
    }
    const sendRequest = {
      type: this.props.notificationType,
      message_title: this.state.title,
      message_body: this.state.message,
      ext_id: this.props.offeringId,
      class: this.state.class,
      order_status: orderStatus,
      bd_users: this.props.selectedBD,
      attachments: this.state.file,
    };
    sendNoti = true;
    this.props.sendNotification(sendRequest);
  }

  onFileChange = (e) => {
    this.setState({
      file: e.target.files,
    });
  }
  
  static getDerivedStateFromProps(props, state) {
    if (props.notificationRes) {
      if (props.notificationRes.data && props.notificationRes.data.sendNotification ) {
          if (props.notificationRes.data.sendNotification.message === "Success" && sendNoti) {
              sendNoti = false;
              props.onClose()
              return {
                  file: null,
              };
          }
      }
    }
    return null;
  }

  closeSendModal = () => {
    this.setState({
      file: null,
    });
    this.props.onClose();
  }
  render() {
    const { notificationType, selectedBD } = this.props;
    let fieldDisable = false;
    if (selectedBD.length === 0) {
      fieldDisable = true;
    }
    
    if (!this.props.notificationshow) {
      return null;
    }
    const less = '<';
    const greater = '>';

    return (
        <Dialog
            onClose={this.closeSendModal}
            aria-labelledby="customized-dialog-title"
            open={this.props.notificationshow}
            maxWidth={"sm"}
            fullWidth={true}
            scroll={'paper'}
        >
            <DialogTitle onClose={this.closeSendModal} className="offering-detail-title">{notificationType && notificationType === 'EMAIL' ? 'EMAIL Notification' : 'PUSH Notification'}</DialogTitle>
            <DialogContent>
                <div className="col-md-12">
                    <div className="sendNotificationContainer">
                        <div>
                            {
                                selectedBD.length === 0 &&
                                <h4 style={{ color: 'red', textAlign: 'center' }}>Please select any orders first</h4>
                            }
                            
                            <div>
                                <div>
                                    <label className="update-label labelheader" style={{ marginBottom: 5 }}> Title </label>
                                </div>
                                <div>
                                    <TextField style={{ width: '100%' }} type="text" disabled={fieldDisable} onChange={this.setTitle.bind(this)} onMouseDown={e => e.stopPropagation()} />
                                </div>
                            </div>
                            <div >
                                <div>
                                    <label className="update-label labelheader" style={{ marginBottom: 5 }}>Message </label>
                                </div>
                                <div>
                                    <TextField multiline={true} style={{ width: '100%' }} disabled={fieldDisable} onChange={this.setMessage.bind(this)} onMouseDown={e => e.stopPropagation()} />
                                </div>
                            </div>
                            {
                                notificationType === 'EMAIL' &&
                                <div style={{ marginTop: '20px' }}>
                                <input type="file" onChange={this.onFileChange} />
                                </div>
                            }
                            <div style={{ marginTop: 20, marginBottom: 20 }}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.state.checkedMand}
                                        onChange={this.isMandatory}
                                        name="broker_dealer_check"
                                        color="primary"
                                    />
                                    }
                                    label="Is Mandatory?"
                                />
                            </div>
                            <Button variant="contained" color="primary" fullWidth={true} disabled={fieldDisable} onClick={() => { this.sendNotifications(); }}> Send </Button>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <p className="notification_offeringTitle_bold">Replacements in subject: </p>
                            <p className="notificationModal_bottom_text ">
                                {less}%=@offering_name%{greater} - Name of the offering
                            </p>
                            <p className="notification_offeringTitle_bold">Replacements in body: </p>
                            <p className="notificationModal_bottom_text">  {less}%=@customer_first_name%{greater} - Customer's First Name</p>
                            <p className="notificationModal_bottom_text">{less}%=@customer_last_name%{greater} - Customer's Last Name</p>
                            <p className="notificationModal_bottom_text">{less}%=@customer_name%{greater} - Customer's Full name</p>
                            <p className="notificationModal_bottom_text">{less}%=@offering_name%{greater} - Name Of The Offering</p>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
  }
}

NotificationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  notificationshow: PropTypes.bool,
  notificationType: PropTypes.any,
  sendNotification: PropTypes.func,
  notificationRes: PropTypes.any,
  offeringId: PropTypes.any,
  totalCustomer: PropTypes.any,
  selectedOption: PropTypes.any,
  customerListing: PropTypes.any,
  selectedBD: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
  notificationRes: doSendNotificationRes,
});
function mapDispatchToProps(dispatch) {
  return {
    sendNotification: (data) => dispatch(sendNotifications(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationModal);
