import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import '../admin.scss';
import { fetchCreatedUsers, doFetchCreatedUsersRes } from '../../../actions/userCreationByDateActions';
import { fetchOfferingAttribution, doFetchOfferingAttributionRes } from '../../../actions/offeringAttributionActions';
// import { LineChart, CartesianGrid, XAxis, YAxis, Line, Tooltip, ResponsiveContainer, Brush, Legend } from 'recharts';
// import Button from '@material-ui/core/Button';
import Chart from 'react-apexcharts';
class OfferingImpactComponent extends React.PureComponent {

	constructor() {
		super();
		this.state = {
			seriesFirst: [
				{
				  name: "User Count",
				  data: []
				}
			  ],
			optionsFirst: {
				noData: {
					text: 'No data in this range'
				},
				grid: {
					show: true,
					position: 'back'
				},
				stroke: {
					curve: 'smooth',
					show: true,
					width: 3,
					dashArray: 0,
					lineCap: 'butt'
				},
				marker: {
					size: 2, 
				},
				fill: {
					type: 'gradient', 
				},
				colors: ["#82ca9d"],
				chart: {
				  type: 'line',
				  stacked: false,
				  toolbar: {
					  show: true,
					  tools: {
						  download: false
					  }
				  }
				},
				xaxis: {
				  categories: [],
				},
				legend: {
					show: true,
					horizontalAlign: "left",
					offsetX: 40
				},
				yaxis: [
					{
					  axisTicks: {
						show: true
					  },
					  axisBorder: {
						show: true,
						color: "#82ca9d"
					  },
					  labels: {
						style: {
						  colors: "#82ca9d"
						}
					  },
					  title: {
						text: "User Count",
						style: {
						  color: "#82ca9d"
						}
					  }
					}
				],
			},
			series: [
				{
				  name: "Asked shares",
				  data: []
				},
				{
				  name: "Received shares",
				  data: []
				}
			  ],
			options: {
				noData: {
					text: 'No data in this range'
				},
				grid: {
					show: true,
					position: 'back'
				},
				stroke: {
					curve: 'smooth',
					show: true,
					width: 3,
					dashArray: 0,
					lineCap: 'butt'
				},
				marker: {
					size: 2, 
				},
				fill: {
					type: 'gradient', 
				},
				colors: ["#8884d8", "#82ca9d"],
				chart: {
				  type: 'line',
				  stacked: false,
				  toolbar: {
					  show: true,
					  tools: {
						  download: false
					  }
				  }
				},
				xaxis: {
				  categories: [],
				},
				legend: {
				  show: true,
				  horizontalAlign: "left",
				  offsetX: 40
				},
				yaxis: [
					{
					  axisTicks: {
						show: true
					  },
					  axisBorder: {
						show: true,
						color: "#8884d8"
					  },
					  labels: {
						style: {
						  colors: "#8884d8"
						}
					  },
					  title: {
						text: "Asked shares",
						style: {
						  color: "#8884d8"
						}
					  }
					},
					{
					  opposite: true,
					  axisTicks: {
						show: true
					  },
					  axisBorder: {
						show: true,
						color: "#82ca9d"
					  },
					  labels: {
						style: {
						  colors: "#82ca9d"
						}
					  },
					  title: {
						text: "Received shares",
						style: {
						  color: "#82ca9d"
						}
					  }
					}
				],
			},
			createdUsers: [],
			offeringAttribution: [],
		}
	}

	componentDidMount() {
		this.props.fetchCreatedUsers();
		this.props.fetchOfferingAttribution();
	}

	static getDerivedStateFromProps(props, state) {
		if (props.fetchCreatedUsersRes) {
			if (props.fetchCreatedUsersRes.data && props.fetchCreatedUsersRes.data.userCreationByDate) {
				if (props.fetchCreatedUsersRes.data.userCreationByDate.message === 'Success') {
					if (props.fetchCreatedUsersRes.data.userCreationByDate.data.result) {
						let createdUsersList = [];
						let createdUsersCount = [];
						let createdUsersDate = [];
						if (props.fetchCreatedUsersRes.data.userCreationByDate.data.result) {
							for (let item of props.fetchCreatedUsersRes.data.userCreationByDate.data.result) {
								let d = new Date(item.account_created);
								let fullData = ('00' + d.getDate()).slice(-2) + '/' +
								('00' + (d.getMonth() + 1)).slice(-2) + '/' +
								d.getFullYear();
								createdUsersList.push({
									name: fullData,
									count: item.count
								})
								item.name = fullData;
								createdUsersCount.push(item.count);
								createdUsersDate.push(fullData);
							}
						}
						return {
							createdUsers: createdUsersList,
							seriesFirst: [
								{
								  name: "User Count",
								  data: createdUsersCount
								}
							],
							optionsFirst: {
								noData: {
									text: 'No data in this range'
								},
								grid: {
									show: true,
									position: 'back'
								},
								stroke: {
									curve: 'smooth',
									show: true,
									width: 3,
									dashArray: 0,
									lineCap: 'butt'
								},
								marker: {
									size: 2, 
								},
								fill: {
									type: 'gradient', 
								},
								colors: ["#82ca9d"],
								chart: {
								  type: 'line',
								  stacked: false,
								  toolbar: {
									  show: true,
									  tools: {
										  download: false
									  }
								  }
								},
								xaxis: {
								  	categories: createdUsersDate,
								},
								legend: {
									show: true,
									horizontalAlign: "left",
									offsetX: 40
								},
								yaxis: [
									{
									  axisTicks: {
										show: true
									  },
									  axisBorder: {
										show: true,
										color: "#82ca9d"
									  },
									  labels: {
										style: {
										  colors: "#82ca9d"
										}
									  },
									  title: {
										text: "User Count",
										style: {
										  color: "#82ca9d"
										}
									  }
									}
								],
							},
						};
					}
					
				}
			}
		}
		if (props.fetchOfferingAttributionRes) {
			if (props.fetchOfferingAttributionRes.data && props.fetchOfferingAttributionRes.data.offeringAttribution) {
				if (props.fetchOfferingAttributionRes.data.offeringAttribution.message === 'Success') {
					if (props.fetchOfferingAttributionRes.data.offeringAttribution.data.result) {
						let categoriesOfSecond = [];
						let askedShares = [];
						let receivedShares = [];
						let allData = props.fetchOfferingAttributionRes.data.offeringAttribution.data.result;
						for (let category of allData) {
							categoriesOfSecond.push(category.name);
							askedShares.push(category.asked_shares);
							receivedShares.push(category.received_shares);
						}
						return {
							offeringAttribution: props.fetchOfferingAttributionRes.data.offeringAttribution.data.result,
							series: [
								{
								  name: "Asked shares",
								  data: askedShares
								},
								{
								  name: "Received shares",
								  data: receivedShares
								}
							],
							options: {
								noData: {
									text: 'No data in this range'
								},
								grid: {
									show: true,
									position: 'back'
								},
								stroke: {
									curve: 'smooth',
									show: true,
									width: 3,
									dashArray: 0,
									lineCap: 'butt'
								},
								marker: {
									size: 2, 
								},
								fill: {
									type: 'gradient', 
								},
								colors: ["#8884d8", "#82ca9d"],
								chart: {
								  type: 'line',
								  stacked: false,
								  toolbar: {
									  show: true,
									  tools: {
										  download: false
									  }
								  }
								},
								xaxis: {
								  	categories: categoriesOfSecond,
								},
								legend: {
									show: true,
								  	horizontalAlign: "left",
								  	offsetX: 40
								},
								yaxis: [
									{
									  axisTicks: {
										show: true
									  },
									  axisBorder: {
										show: true,
										color: "#8884d8"
									  },
									  labels: {
										style: {
										  colors: "#8884d8"
										}
									  },
									  title: {
										text: "Asked shares",
										style: {
										  color: "#8884d8"
										}
									  }
									},
									{
									  opposite: true,
									  axisTicks: {
										show: true
									  },
									  axisBorder: {
										show: true,
										color: "#82ca9d"
									  },
									  labels: {
										style: {
										  colors: "#82ca9d"
										}
									  },
									  title: {
										text: "Received shares",
										style: {
										  color: "#82ca9d"
										}
									  }
									}
								],
							}
						};
					}
				}
			}
		}
		return null
	}
	render() {
		return (
			<div className="stats_offering_content_body">
				<div>
					<div className="row">
						<div className="col-md-12" style={{ paddingBottom: 15 }}>
							<div style={{ textAlign: 'center', paddingBottom: 15, marginTop: 16 }}><h4>User creation by date</h4></div>
							<div className="highlight-bar-charts" style={{ userSelect: 'none' }}>
								
								<Chart options={this.state.optionsFirst} series={this.state.seriesFirst} type="line" width="100%" height={400}  />
							</div>
						</div>
						<div className="col-md-12" style={{ paddingBottom: 15 }} >
							<div style={{ textAlign: 'center', paddingBottom: 15, marginTop: 16 }}><h4>Offering attribution</h4></div>
							<div className="highlight-bar-charts" style={{ userSelect: 'none' }}>
								<Chart options={this.state.options} series={this.state.series} type="line" width="100%" height={400}  />
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

OfferingImpactComponent.propTypes = {
	fetchCreatedUsersRes: PropTypes.any,
	fetchOfferingAttributionRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	fetchCreatedUsersRes: doFetchCreatedUsersRes,
	fetchOfferingAttributionRes: doFetchOfferingAttributionRes,
});

function mapDispatchToProps(dispatch) {
	return {
		fetchCreatedUsers: () => dispatch(fetchCreatedUsers()),
		fetchOfferingAttribution: () => dispatch(fetchOfferingAttribution()),
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OfferingImpactComponent);