import * as types from './actionTypes';  
import currentCallApi from '../api/currentCallApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doCurrentCall(ext_id) {

  return {
    type: types.DO_OFFERING_CURRENT_CALL, 
    ext_id
  };
}

export function doCurrentCallRes(data) {  
  return {
    type: types.DO_OFFERING_CURRENT_CALL_RES, 
    data
  };
}

export function makeCurrentCall(ext_id) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      currentCallApi.doCurrentCall(ext_id).then(data => {
        dispatch(doCurrentCallRes(data));
        dispatch(doCurrentCallRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        } else {
          Swal.fire({
						title: 'Make Current Successfully',
						icon: 'success',
						confirmButtonText: 'OK',
						allowOutsideClick: false,
						timer: 3000,
						heightAuto: false
					})
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
  
}