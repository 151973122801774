import * as types from './actionTypes';  
import callIntactFileApi from '../api/callIntactFileApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doCallIntactFile(id) {

  return {
    type: types.DO_CALL_INTACT_FILE, 
    id
  };
}

export function doCallIntactFileRes(data) {  
  return {
    type: types.DO_CALL_INTACT_FILE_RES, 
    data
  };
}
export function uploadIntactFile(id) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      callIntactFileApi.doCallIntactFile(id).then(data => {
        dispatch(doCallIntactFileRes(data));
        dispatch(doCallIntactFileRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
  
}