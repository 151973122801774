
import { BASE_URL } from '../constants';
import { getItem } from '../utils/localStore';
import { sha3_512 } from 'js-sha3';
import Swal from 'sweetalert2';
import { logout } from '../helper/helper';
import { errorHandling, errorHandler } from '../utils/errorHandling';

class remainingDaysChangePasswordApi {
    static remainingDaysChangePassword(data) {
        const TOKEN = getItem('auth_token_2');

        let sendBody={
            old_encrypted_password:sha3_512(data.old_encrypted_password),
            encrypted_password:sha3_512(data.encrypted_password),
        }
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'PUT',
                headers: ajaxRequestHeaders,
                body: JSON.stringify(sendBody)
            }
            return fetch(BASE_URL + '/password/new', body).then(response => {
                if(response.ok === true){
                    Swal.fire({
                        title: 'Password update successfully',
                        type: 'success',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,
                        timer: 3000,
                        heightAuto: false
                    })
                    localStorage.removeItem('auth_token_2');
                    logout();
                    window.location.href = '/';
                }
                if (response.status === 401) {
                    localStorage.removeItem('auth_token_2');
                }
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){

        }
    }
}

export default remainingDaysChangePasswordApi;
