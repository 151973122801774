import * as types from './actionTypes';  
import updateRequestedAmountApi from '../api/updateRequestedAmountApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doUpdateRequestedAmount(request) {

  return {
    type: types.DO_UPDATE_REQUESTED_AMOUNT, 
    request
  };
}

export function doUpdateRequestedAmountRes(data) {  
  return {
    type: types.DO_UPDATE_REQUESTED_AMOUNT_RES, 
    data
  };
}

export function updateRequestedAmount(request) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      updateRequestedAmountApi.doUpdateRequestedAmount(request).then(data => {
        
        dispatch(doUpdateRequestedAmountRes(data));
        dispatch(doUpdateRequestedAmountRes(null));
        dispatch(loadingRes(false));
        if (data.error) {
            Swal.fire({
                title: data.message,
                icon: 'error',
                confirmButtonText: 'OK',
                allowOutsideClick: false,
                timer: 5000,
                heightAuto: false
            });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
  
}