

export default function validate(values) {
    const errors = {};
  
    if (!values.warrantprice) {
      errors.Warrant_PriceErr = 'Please enter warrant price';
    } else {
      if (typeof values['warrantprice'] !== 'undefined') {
        if (!values['warrantprice'].match(/^-?\d*(\.\d+)?$/)) {
          errors['Warrant_PriceErr'] = 'Please enter only number';
        }
      }
    }
    if (!values.Warrant_share) {
      errors.Warrant_shareErr = 'Please enter warrant share';
    } else {
      if (typeof values['Warrant_share'] !== 'undefined') {
        if (!values['Warrant_share'].match(/^-?\d*(\.\d+)?$/)) {
          errors['Warrant_shareErr'] = 'Please enter only number';
        }
      }
    }
    if (!values.warrant_cusip) {
      errors.warrant_cusip = 'Please enter CUSIP ID';
    }
    if (!values.ticker_symbol) {
      errors.ticker_symbol = 'Please enter Ticker Symbol';
    }
    return errors;
  }
  