import * as types from '../actions/actionTypes';
import initialState from './initialState';

//all the offering related reducers here
export default function (state = initialState.user, action) { //we will change the state = {} soon
    switch (action.type) {
        case types.DO_SEND_PENDING_BROKERDEALER_RES:
            if (action.data && action.data.data) {
                let tempArrayShow = [];
                if (action.data.data) {
                    for (const item of action.data.data) {
                        const csvReponse = item.csv_string.split('\n');
                        const showCSV = [];
                        for (const items of csvReponse) {
                            if (items !== '') {
                                showCSV.push(items.split(','));
                            }
                        }
                        item.showCSV = showCSV;
                        tempArrayShow.push(item);
                    }
                } else {
                    tempArrayShow = [];
                }
                let returnData = {
                    data: tempArrayShow,
                    error: 0,
                    message: 'Success'
                }
                return returnData
            } else {
                return action.data
            }
        default:
            return state
    }
}