

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Dashboard from '../../../component/Dashboard';
import { Link } from 'react-router-dom';
import { getItem } from '../../../utils/localStore';
import { getBrokerDealerAdmins, doBrokerDealerAdminsRes } from '../../../actions/brokerDealerAdminsActions';
import { sendCustomerResetPassword, doCustomerResetPassRes } from '../../../actions/customerResetPassActions';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import GridTable from '@nadavshaar/react-grid-table';
import Tooltip from '@material-ui/core/Tooltip';
import LockIcon from '@material-ui/icons/Lock';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { DialogActions } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
    color: theme.palette.grey[500],
  },
}))(props => {
const { children, classes, onClose } = props;
return (
  <MuiDialogTitle disableTypography className={classes.root}>
  <Typography variant="h6">{children}</Typography>
  {onClose ? (
    <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
    <CloseIcon />
    </IconButton>
  ) : null}
  </MuiDialogTitle>
);
});

const DialogContent = withStyles(theme => ({
root: {
  margin: 0,
  padding: theme.spacing(2),
},
}))(MuiDialogContent);
let isUnderWriterAdmins = false;
class UnderwriterAdminList extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    isUnderWriterAdmins = false;
    let columns = [
			{ id: 1, width: '20%', field: 'first_name', label: 'First Name', visible: true, sortable: true, cellRenderer: this.namesTemp },
			{ id: 2, width: '20%', field: 'last_name', label: 'Last Name', visible: true, sortable: true, cellRenderer: this.namesTemp },
			{ id: 3, width: '20%', field: 'email', label: 'Email', visible: true, sortable: true, cellRenderer: this.namesTemp },
      { id: 4, width: '20%', field: 'level', label: 'Level', visible: true, sortable: true, cellRenderer: this.namesTemp }
    ];
    let readOnly = JSON.parse(getItem('isReadOnly'));
		if (!readOnly) {
			columns.push({ id: 5, width: '20%', field: 'action', label: 'Action', visible: true, sortable: false, cellRenderer: this.actionTemplate})
		}
    this.state = {
      cols: columns,
			isReadOnly: JSON.parse(getItem('isReadOnly')),
      userWriterMpid: [],
      underwriterData: {},
      tabIndex: 0,
      customerDetail: null,
			openConfirmation: false
    };
  }

  componentDidMount() {
    let brokerData = this.props.location.state.underwriterData;
    this.setState({
        underwriterData: brokerData,
        tabIndex: this.props.location.state.tabIndex
    });
    isUnderWriterAdmins = true;
    this.props.fetchAllAssociatedMpid(brokerData.mpid);
  }
  
  static getDerivedStateFromProps(props, state) {
    if (props.brokerDealerAdminsRes) {
      if (props.brokerDealerAdminsRes.data && props.brokerDealerAdminsRes.data.brokerDealerAdmins) {
        if (props.brokerDealerAdminsRes.data.brokerDealerAdmins.message === 'Success' && isUnderWriterAdmins) {
          isUnderWriterAdmins = false;
          let arrData = props.brokerDealerAdminsRes.data.brokerDealerAdmins.data;
          for (let i = 0; i < arrData.length; i++) {

              Object.keys(arrData[i]).forEach((field) => { /*eslint-env es6*/
              if (arrData[i][field] === null) {
                  arrData[i][field] = "";
              }
              })
          };
          return {
            userWriterMpid: arrData,
          };
        }
      }
    }
    if (props.customerResetPassRes) {
			if (props.customerResetPassRes.data && props.customerResetPassRes.data.customerResetPass) {
				if (props.customerResetPassRes.data.customerResetPass.message === 'Success') {
					return {
						openConfirmation: false
					};
				}
			}
		}
    return null
  }
  backBrokerDealer = () => {

    this.props.fetchAllAssociatedMpidSuccess(null);
    // this.props.history.goBack();
    this.props.history.push({
      pathname: `/system-setting`,
      state: {
        tabIndex: this.state.tabIndex,
      }
    });

  }
  namesTemp = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return <div className="rgt-cell-inner rgt-text-truncate" style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
			<Tooltip title={value !== undefined ? value : ''}  interactive>
				<div className="text-one-line" style={{width: '90%'}}>{value}</div>
			</Tooltip>
      {
				value !== '' &&
				<Tooltip title={'Copy to clipboard'} interactive >
					<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small"/> </Button>
				</Tooltip>
			}
		</div>;
	}
  actionTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return (<div className="rgt-cell-inner">
			<Tooltip title="Reset password">
				<Button style={{ width: 'auto', margin: 3 }} variant="outlined" size="medium" color="primary" onClick={() => this.openConfirmationModal(data)}><LockIcon /></Button>
			</Tooltip>
		</div>);
	}
	openConfirmationModal = (data) => {
		this.setState({
			customerDetail: data,
			openConfirmation: true
		});
	}
	closeConfirmationModal = () => {
		this.setState({
			openConfirmation: false
		});
	}
  resetPasswordClick = () => {
		let request = {
			email: this.state.customerDetail.email
		}
		this.props.sendCustomerResetPassword(request);
	}
  render() {
      const { userWriterMpid, underwriterData, isReadOnly, customerDetail } = this.state;
    return (
      <Dashboard  title="ClickIPO Admin - brokerdealer admin">
        <div className="admin_offering_content">
            <div className="admin_Main_outer_wrapper_message underwriterAdminList">
                <div className="row" style={{ paddingTop: 15 }}>
                    <div className="col-4">
                        <div className="EditMessage">
                            <h3 style={{ marginLeft: '16px', cursor: 'pointer', color: '#8DC73F'}}>
                              <ArrowBackIosIcon onClick={this.backBrokerDealer} style={{ cursor: 'pointer' }} />  {underwriterData.name} 
                            </h3>
                        </div>
                    </div>
                    <div className="col-4">
                        <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            Admins
                        </h3>
                    </div>
                    <div className="col-4">
                      {
                        (!isReadOnly) &&
                        <div style={{ textAlign: 'right', marginTop: 10, marginBottom: 10, marginRight: 12 }}>
                          <Button component={Link} to={{ pathname: `/create-underwriter-admin`, state: { borkerAllData: underwriterData } }} variant="contained" color="primary" style={{ margin: '5px' }} >Add An User</Button>
                        </div>
                      }
                    </div>
                </div>
                <div className="col-12 underwriter-admin-table" style={{ paddingTop: 15 }}>
                    <div>
                        <GridTable
                            columns={this.state.cols}
                            rows={userWriterMpid}
                            isPaginated={true}
                            isVirtualScroll={true}
                            showSearch={true}
                            showRowsInformation={false}
                            isHeaderSticky={true}
                        />
                        <Dialog
                          onClose={this.closeConfirmationModal}
                          aria-labelledby="customized-dialog-title"
                          open={this.state.openConfirmation}
                          maxWidth={"sm"}
                          fullWidth={true}
                          scroll={'paper'}
                        >
                          <DialogTitle onClose={this.closeConfirmationModal} className="offering-detail-title">Reset Password
                                </DialogTitle>
                          <DialogContent>
                            <div className="col-md-12">
                              <h5>
                                Are you sure you want to send password recovery email to
                                      &nbsp; <span > {customerDetail && customerDetail.email ? customerDetail.email : ''} ?</span>
                              </h5>
                            </div>
                          </DialogContent>
                          <DialogActions style={{ display: 'block' }}>
                            <div style={{ textAlign: 'center' }}>
                              <Button style={{ marginLeft: 10, marginRight: 10 }} variant="contained" size="large" color="primary" onClick={() => this.resetPasswordClick()}> Send </Button>
                              <Button style={{ marginLeft: 10, marginRight: 10 }} variant="contained" size="large" color="primary" onClick={() => this.closeConfirmationModal()}>
                                Cancel
                                    </Button>
                            </div>
                          </DialogActions>
                        </Dialog>
                    </div>
                </div>
            </div>
        </div>
      </Dashboard>
    );
  }
}

UnderwriterAdminList.propTypes = {
  brokerDealerAdminsRes: PropTypes.any,
  setBrokerAdminItem: PropTypes.any,
	customerResetPassRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
  brokerDealerAdminsRes: doBrokerDealerAdminsRes,
	customerResetPassRes: doCustomerResetPassRes,

});
function mapDispatchToProps(dispatch) {
  return {
    // setBrokerAdminItem: (item) => dispatch(setBrokerAdminItem(item)),
    fetchAllAssociatedMpid: (mpid) => dispatch(getBrokerDealerAdmins(mpid)),
    fetchAllAssociatedMpidSuccess: (data) => dispatch(doBrokerDealerAdminsRes(data)),
		sendCustomerResetPassword: (data) => dispatch(sendCustomerResetPassword(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UnderwriterAdminList);
