import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { sendNotifications, doSendNotificationRes } from '../../actions/sendNotificationActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
	}))(props => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

let sendNoti = false;
class NotificationModal extends React.Component {


  constructor() {
    super();

    sendNoti = false;
    this.state = {
      isSubmit: null,
      title: '',
      message: '',
      class: 1, // the default value of email is MANDATORY
      file: null,
      checkedMand: true,
      selectAll: false,
      callback: false
    };

    this.onFileChange = this.onFileChange.bind(this);
    this.handleChangeSelectAll = this.handleChangeSelectAll.bind(this);
    this.isMandatory = this.isMandatory.bind(this);
  }

  handleChangeSelectAll() {
    this.setState({
      selectAll: !this.state.selectAll
    });
  };

  handleChangeCallbacks = (e) => {
    this.setState({
      callback: e.target.checked
    });
  };
  setTitle(event) {
    this.setState({
      title: event.target.value,
    });
  }
  setMessage(event) {
    this.setState({
      message: event.target.value,
    });
  }
  isMandatory(event) {
    if (event.target.checked === true) {
      this.setState({
        class: 1, // MANDOTORY EMAIL
      });
    } else {
      this.setState({
        class: 16, // PROMOTIONAL EMAIL
      });
    }
  }
  sendNotifications() {
    // isAllStatus is set to 0 if false and 1 if true
    // let isAllStatus = false; // false
    const usersMail = [];
    if (this.props.customerListing) {
      for (const items of this.props.customerListing) {
        usersMail.push(items.email);
      }
    }
    // else {
    //   isAllStatus = true;  // true
    // }

    // if (this.props.customerListing && this.props.totalCustomer) {
    //   if (this.props.customerListing.length === this.props.totalCustomer.length) {
    //     isAllStatus = true;
    //   } else {
    //     isAllStatus = false;
    //   }
    // }
    const sendRequest = {
      type: this.props.notificationType,
      message_title: this.state.title,
      message_body: this.state.message,
      user_email_list: this.state.selectAll ? [] : usersMail,
      all: this.state.selectAll,
      class: this.state.class,
      connected: true,
      attachments: this.state.file,
      callback: this.state.callback
    };
    this.props.sendNotification(sendRequest);
  }

  onFileChange = (e) => {
    this.setState({
      file: e.target.files,
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.notificationRes) {
      if (props.notificationRes.data && props.notificationRes.data.sendNotification ) {
          if (props.notificationRes.data.sendNotification.message === "Success" && sendNoti) {
              sendNoti = false;
              props.onClose()
              return {
                  file: null,
              };
          }
      }
    }
    return null;
  }

  closeSendModal = () => {
    this.setState({
      file: null,
    });
    this.props.onClose();
  }
  render() {
    const { notificationType } = this.props;
    // let fieldDisable = false;


    // let isAllStatus = false; // false
    // const usersMail = [];
    // if (this.props.customerListing) {
    //   for (const items of this.props.customerListing) {
    //     usersMail.push(items.email);
    //   }
    // }
    // else {
    //   isAllStatus = true;  // true
    // }

    // if (this.props.customerListing && this.props.totalCustomer) {
    //   if (this.props.customerListing.length === this.props.totalCustomer.length) {
    //     isAllStatus = true;
    //   } else {
    //     isAllStatus = false;
    //   }
    // }
    // if (isAllStatus) {
    //   fieldDisable = false;
    // } else {
    //   if (usersMail.length > 0) {
    //     fieldDisable = false;
    //   } else {
    //     fieldDisable = true;
    //   }
    // }
    if (!this.props.notificationshow) {
      return null;
    }
    const less = '<';
    const greater = '>';

    return (
        <Dialog
            onClose={this.closeSendModal}
            aria-labelledby="customized-dialog-title"
            open={this.props.notificationshow}
            maxWidth={"sm"}
            fullWidth={true}
            scroll={'paper'}
        >
            <DialogTitle onClose={this.closeSendModal} className="offering-detail-title">{notificationType && notificationType === 'EMAIL' ? 'EMAIL Notification' : 'PUSH Notification'}</DialogTitle>
            <DialogContent>
                <div className="col-md-12">
                    <div className="sendNotificationContainer">
                        <div>
                            {
                                (!this.state.selectAll && this.props?.customerListing?.length === 0) &&
                                <h4 style={{ color: 'red', textAlign: 'center' }}>Please select any customer first</h4>
                            }
                            <div>
                              {
                                (!this.state.selectAll && this.props?.customerListing?.length > 0)  &&
                                  <p>{this.props?.customerListing?.length} Users(s) are selected</p>
                              }
                              <div style={{display: "flex", alignItems: "center"}}>
                                <div>
                                  <Checkbox
                                  checked={this.state.checkedAll}
                                  onChange={(e) => this.handleChangeSelectAll(e)}
                                  value="selectAll"
                                  color="primary"
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                /> Select all users
                                </div>
                                <div style={{marginLeft: 20}}>
                                  <Checkbox
                                  checked={this.state.callback}
                                  onChange={(e) => this.handleChangeCallbacks(e)}
                                  color="primary"
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                /> Callbacks
                                </div>
                              
                              </div>
                            </div>
                            <div>
                                <div>
                                    <label className="update-label labelheader" style={{ marginBottom: 5 }}> Title </label>
                                </div>
                                <div>
                                    <TextField style={{ width: '100%' }} type="text" onChange={this.setTitle.bind(this)} onMouseDown={e => e.stopPropagation()} disabled={!this.state.selectAll && this.props?.customerListing?.length === 0}/>
                                </div>
                            </div>
                            <div >
                                <div>
                                    <label className="update-label labelheader" style={{ marginBottom: 5 }}>Message </label>
                                </div>
                                <div>
                                    <TextField multiline={true} style={{ width: '100%' }} onChange={this.setMessage.bind(this)} onMouseDown={e => e.stopPropagation()} disabled={!this.state.selectAll && this.props?.customerListing?.length === 0}/>
                                </div>
                            </div>
                            {
                                notificationType === 'EMAIL' &&
                                <div style={{ marginTop: '20px' }}>
                                <input type="file" onChange={this.onFileChange} />
                                </div>
                            }
                            <div style={{ marginTop: 20, marginBottom: 20 }}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.state.checkedMand}
                                        onChange={this.isMandatory}
                                        name="broker_dealer_check"
                                        color="primary"
                                    />
                                    }
                                    label="Is Mandatory?"
                                />
                            </div>
                            <Button variant="contained" color="primary" fullWidth={true} onClick={() => { this.sendNotifications(); }} disabled={!this.state.selectAll && this.props?.customerListing?.length === 0}> Send </Button>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <p className="notification_offeringTitle_bold">Replacements in body: </p>
                            <p className="notificationModal_bottom_text"> {less}%=@customer_first_name%{greater} - Customer's First Name</p>
                            <p className="notificationModal_bottom_text">{less}%=@customer_last_name%{greater} - Customer's Last Name</p>
                            <p className="notificationModal_bottom_text">{less}%=@customer_name%{greater} - Customer's Full name</p>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
  }
}

NotificationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  notificationshow: PropTypes.bool,
  notificationType: PropTypes.any,
  sendNotification: PropTypes.func,
  notificationRes: PropTypes.any,
  totalCustomer: PropTypes.any,
  customerListing: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
  notificationRes: doSendNotificationRes,
});
function mapDispatchToProps(dispatch) {
  return {
    sendNotification: (data) => dispatch(sendNotifications(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationModal);
