import * as types from './actionTypes';
import offeringAssetTypesApi from '../api/offeringAssetTypesApi';
import { logout } from '../helper/helper';
import { getItem } from '../utils/localStore';
import { loadingRes } from './loadingActions';


export function doFetchOfferingAssetTypeRes(data) {
    return {
        type: types.DO_FETCH_OFFERING_ASSET_TYPES_RES,
        data
    };
}

export function fetchOfferingAssetType() {
    const TOKEN = getItem('auth_token');
    if (TOKEN) {
        return function (dispatch) {
            dispatch(loadingRes(true));
            offeringAssetTypesApi.offeringAssetTypes().then(data => {
                if (data.error === 0) {
                    dispatch(doFetchOfferingAssetTypeRes(data));
                    dispatch(doFetchOfferingAssetTypeRes(null));
                } else {
                    let arr = [{asset_name: "IPO"}, {asset_name: "Follow-on"}, {asset_name: "Overnight"}, {asset_name: "ETF"}, {asset_name: "UIT"}]
                    let resp = {
                        error: 0,
                        message: "Success",
                        data: arr
                    }
                    dispatch(doFetchOfferingAssetTypeRes(resp));
                    dispatch(doFetchOfferingAssetTypeRes(null));
                }
                dispatch(loadingRes(false));
            }).catch(error => {
                console.log(error);
                dispatch(loadingRes(false));
                return error
            });
        };
    } else {
        logout()
    }
}

