export const DO_LOGIN = 'DO_LOGIN';
export const DO_LOGIN_RES = 'DO_LOGIN_RES';

export const DO_LOADING_RES = 'DO_LOADING_RES';

export const CHECK_USER = 'CHECK_USER';
export const CHECK_USER_RES = 'CHECK_USER_RES';

export const DO_FORGOTPASSWORD = 'DO_FORGOTPASSWORD';
export const DO_FORGOTPASSWORD_RES = 'DO_FORGOTPASSWORD_RES';

export const DO_RESETPASSWORD = 'DO_RESETPASSWORD';
export const DO_RESETPASSWORD_RES = 'DO_RESETPASSWORD_RES';

export const REMAINING_DAYS_CHANGEPASSWORD = 'REMAINING_DAYS_CHANGEPASSWORD';
export const REMAINING_DAYS_CHANGEPASSWORD_RES = 'REMAINING_DAYS_CHANGEPASSWORD_RES';

export const DO_EDITPROFILE = 'DO_EDITPROFILE';
export const DO_EDITPROFILE_RES = 'DO_EDITPROFILE_RES';

export const DO_OFFERING = 'DO_OFFERING';
export const DO_OFFERING_ACTIVE_RES = 'DO_OFFERING_ACTIVE_RES';
export const DO_OFFERING_EFFECTIVE_RES = 'DO_OFFERING_EFFECTIVE_RES';
export const DO_OFFERING_CANCELLED_RES = 'DO_OFFERING_CANCELLED_RES';
export const DO_OFFERING_CLOSED_RES = 'DO_OFFERING_CLOSED_RES';

export const DO_OFFERING_DETAILS = 'DO_OFFERING_DETAILS';
export const DO_OFFERING_DETAILS_RES = 'DO_OFFERING_DETAILS_RES';

export const DO_GETPROFILE = 'DO_GETPROFILE';
export const DO_GETPROFILE_RES = 'DO_GETPROFILE_RES';

export const DO_OFFERING_USER_INFO = 'DO_OFFERING_USER_INFO';
export const DO_OFFERING_USER_INFO_RES = 'DO_OFFERING_USER_INFO_RES';

export const DO_ORDER = 'DO_ORDER';
export const DO_ORDER_RES = 'DO_ORDER_RES';

export const DO_PROSPECTUS = 'DO_PROSPECTUS';
export const DO_PROSPECTUS_RES = 'DO_PROSPECTUS_RES';

export const DO_BROKERDEALER_LIST = 'DO_BROKERDEALER_LIST';
export const DO_BROKERDEALER_LIST_RES = 'DO_BROKERDEALER_LIST_RES';

export const UNDERWRITER_MPID_LIST = 'UNDERWRITER_MPID_LIST';
export const UNDERWRITER_MPID_LIST_RES = 'UNDERWRITER_MPID_LIST_RES';

export const OFFERING_INDUSTRIES_LIST = 'OFFERING_INDUSTRIES_LIST';
export const OFFERING_INDUSTRIES_LIST_RES = 'OFFERING_INDUSTRIES_LIST_RES';

export const CREATE_OFFERING = 'CREATE_OFFERING';
export const CREATE_OFFERING_RES = 'CREATE_OFFERING_RES';
export const UPDATE_OFFERING = 'UPDATE_OFFERING';
export const UPDATE_OFFERING_RES = 'UPDATE_OFFERING_RES';

export const LAST_CLOSING_PRICE = 'LAST_CLOSING_PRICE';
export const LAST_CLOSING_PRICE_RES = 'LAST_CLOSING_PRICE_RES';

export const OFFERING_DETAIL_RES = 'OFFERING_DETAIL_RES';

export const UPLOAD_OFFERING_LOGO = 'UPLOAD_OFFERING_LOGO';
export const UPLOAD_OFFERING_LOGO_RES = 'UPLOAD_OFFERING_LOGO_RES';

export const BROCHURE_URL_OFFERING = 'BROCHURE_URL_OFFERING';
export const BROCHURE_URL_OFFERING_RES = 'BROCHURE_URL_OFFERING_RES';
export const ATTESTATION_URL_OFFERING_RES = 'ATTESTATION_URL_OFFERING_RES';
export const FAQ_URL_OFFERING_RES = 'FAQ_URL_OFFERING_RES';
export const ADDITIONAL_URL_OFFERING_RES = 'ADDITIONAL_URL_OFFERING_RES';

export const DO_NOTIFY_USER = 'DO_NOTIFY_USER';
export const DO_NOTIFY_USER_RES = 'DO_NOTIFY_USER_RES';

export const DO_DSP_USERS_LIST = 'DO_DSP_USERS_LIST';
export const DO_DSP_USERS_LIST_RES = 'DO_DSP_USERS_LIST_RES';

export const DO_SEND_SIXTY_MINUTE_EMAIL = 'DO_SEND_SIXTY_MINUTE_EMAIL';
export const DO_SEND_SIXTY_MINUTE_EMAIL_RES = 'DO_SEND_SIXTY_MINUTE_EMAIL_RES';

export const DO_ARCHIVE_OFFERING = 'DO_ARCHIVE_OFFERING';
export const DO_ARCHIVE_OFFERING_RES = 'DO_ARCHIVE_OFFERING_RES';

export const DO_UNARCHIVE_OFFERING = 'DO_UNARCHIVE_OFFERING';
export const DO_UNARCHIVE_OFFERING_RES = 'DO_UNARCHIVE_OFFERING_RES';

export const DO_UPDATE_PROSPECTUS = 'DO_UPDATE_PROSPECTUS';
export const DO_UPDATE_PROSPECTUS_RES = 'DO_UPDATE_PROSPECTUS_RES';

export const DO_RATINGLIST = 'DO_RATINGLIST';
export const DO_RATINGLIST_RES = 'DO_RATINGLIST_RES';

export const DO_UPDATE_OFFERING_RECONFIRM_REQUEST = 'DO_UPDATE_OFFERING_RECONFIRM_REQUEST';
export const DO_UPDATE_OFFERING_RECONFIRM_REQUEST_RES = 'DO_UPDATE_OFFERING_RECONFIRM_REQUEST_RES';

export const DO_SET_UNDERWRITER = 'DO_SET_UNDERWRITER';
export const DO_SET_UNDERWRITER_RES = 'DO_SET_UNDERWRITER_RES';

export const DO_OFFERING_CURRENT_CALL = 'DO_OFFERING_CURRENT_CALL';
export const DO_OFFERING_CURRENT_CALL_RES = 'DO_OFFERING_CURRENT_CALL_RES';

export const DO_FETCH_OFFERING_ORDERS = 'DO_FETCH_OFFERING_ORDERS';
export const DO_FETCH_OFFERING_ORDERS_RES = 'DO_FETCH_OFFERING_ORDERS_RES';

export const DO_FETCH_FOLLOWER_LIST = 'DO_FETCH_FOLLOWER_LIST';
export const DO_FETCH_FOLLOWER_LIST_RES = 'DO_FETCH_FOLLOWER_LIST_RES';

export const DO_UPDATE_REQUESTED_AMOUNT = 'DO_UPDATE_REQUESTED_AMOUNT';
export const DO_UPDATE_REQUESTED_AMOUNT_RES = 'DO_UPDATE_REQUESTED_AMOUNT_RES';

export const DO_DELETE_ORDER = 'DO_DELETE_ORDER';
export const DO_DELETE_ORDER_RES = 'DO_DELETE_ORDER_RES';

export const DO_SEND_NOTIFICATION = 'DO_SEND_NOTIFICATION';
export const DO_SEND_NOTIFICATION_RES = 'DO_SEND_NOTIFICATION_RES';

export const DO_FETCH_USERS_STATS = 'DO_FETCH_USERS_STATS';
export const DO_FETCH_USERS_STATS_RES = 'DO_FETCH_USERS_STATS_RES';

export const DO_FETCH_OFFERING_STATS = 'DO_FETCH_OFFERING_STATS';
export const DO_FETCH_OFFERING_STATS_RES = 'DO_FETCH_OFFERING_STATS_RES';

export const DO_FETCH_ALL_OFFERINGS = 'DO_FETCH_ALL_OFFERINGS';
export const DO_FETCH_ALL_OFFERINGS_RES = 'DO_FETCH_ALL_OFFERINGS_RES';

export const DO_FETCH_STATS_FOLLOWER_LIST = 'DO_FETCH_STATS_FOLLOWER_LIST';
export const DO_FETCH_STATS_FOLLOWER_LIST_RES = 'DO_FETCH_STATS_FOLLOWER_LIST_RES';

export const DO_FETCH_BIZ_INSIGHTS_LIST = 'DO_FETCH_BIZ_INSIGHTS_LIST';
export const DO_FETCH_BIZ_INSIGHTS_LIST_RES = 'DO_FETCH_BIZ_INSIGHTS_LIST_RES';

export const DO_FETCH_STATS_OFFERING_ORDERS = 'DO_FETCH_STATS_OFFERING_ORDERS';
export const DO_FETCH_STATS_OFFERING_ORDERS_RES = 'DO_FETCH_STATS_OFFERING_ORDERS_RES';

export const ADMIN_UNDERWRITER_MPID_LIST = 'ADMIN_UNDERWRITER_MPID_LIST';
export const ADMIN_UNDERWRITER_MPID_LIST_RES = 'ADMIN_UNDERWRITER_MPID_LIST_RES';

export const DO_BROKERDEALER_ADMINS = 'DO_BROKERDEALER_ADMINS';
export const DO_BROKERDEALER_ADMINS_RES = 'DO_BROKERDEALER_ADMINS_RES';

export const DO_CREATE_ADMIN = 'DO_CREATE_ADMIN';
export const DO_CREATE_ADMIN_RES = 'DO_CREATE_ADMIN_RES';

export const DO_UNDERWRITER_PERFORMANCE = 'DO_UNDERWRITER_PERFORMANCE';
export const DO_UNDERWRITER_PERFORMANCE_RES = 'DO_UNDERWRITER_PERFORMANCE_RES';

export const DO_FETCH_CONFIGS = 'DO_FETCH_CONFIGS';
export const DO_FETCH_CONFIGS_RES = 'DO_FETCH_CONFIGS_RES';

export const DO_UPDATE_CONFIG = 'DO_UPDATE_CONFIG';
export const DO_UPDATE_CONFIG_RES = 'DO_UPDATE_CONFIG_RES';

export const DO_ADD_MESSAGE = 'DO_ADD_MESSAGE';
export const DO_ADD_MESSAGE_RES = 'DO_ADD_MESSAGE_RES';

export const DO_UPDATE_MESSAGE = 'DO_UPDATE_MESSAGE';
export const DO_UPDATE_MESSAGE_RES = 'DO_UPDATE_MESSAGE_RES';

export const DO_MESSAGE_LIST = 'DO_MESSAGE_LIST';
export const DO_MESSAGE_LIST_RES = 'DO_MESSAGE_LIST_RES';

export const DO_MESSAGE_DETAIL = 'DO_MESSAGE_DETAIL';
export const DO_MESSAGE_DETAIL_RES = 'DO_MESSAGE_DETAIL_RES';

export const DO_CREATE_BROKERDEALER = 'DO_CREATE_BROKERDEALER';
export const DO_CREATE_BROKERDEALER_RES = 'DO_CREATE_BROKERDEALER_RES';

export const DO_CREATE_UNDERWRITER = 'DO_CREATE_UNDERWRITER';
export const DO_CREATE_UNDERWRITER_RES = 'DO_CREATE_UNDERWRITER_RES';

export const DO_FETCH_ALL_CUSTOMERS = 'DO_FETCH_ALL_CUSTOMERS';
export const DO_FETCH_ALL_CUSTOMERS_RES = 'DO_FETCH_ALL_CUSTOMERS_RES';

export const DO_FETCH_CUSTOMER_ACTIVITY = 'DO_FETCH_CUSTOMER_ACTIVITY';
export const DO_FETCH_CUSTOMER_ACTIVITY_RES = 'DO_FETCH_CUSTOMER_ACTIVITY_RES';

export const DO_FETCH_CUSTOMER_ORDER_INFO = 'DO_FETCH_CUSTOMER_ORDER_INFO';
export const DO_FETCH_CUSTOMER_ORDER_INFO_RES = 'DO_FETCH_CUSTOMER_ORDER_INFO_RES';

export const DO_CUSTOMER_RESET_PASSWORD = 'DO_CUSTOMER_RESET_PASSWORD';
export const DO_CUSTOMER_RESET_PASSWORD_RES = 'DO_CUSTOMER_RESET_PASSWORD_RES';

export const DO_DELETE_CUSTOMER = 'DO_DELETE_CUSTOMER';
export const DO_DELETE_CUSTOMER_RES = 'DO_DELETE_CUSTOMER_RES';

export const DO_UPDATE_CUSTOMER = 'DO_UPDATE_CUSTOMER';
export const DO_UPDATE_CUSTOMER_RES = 'DO_UPDATE_CUSTOMER_RES';

export const DO_FETCH_ALLOCATION_LIST = 'DO_FETCH_ALLOCATION_LIST';
export const DO_FETCH_PENDING_ALLOCATION_LIST_RES = 'DO_FETCH_PENDING_ALLOCATION_LIST_RES';
export const DO_FETCH_COMPLETE_ALLOCATION_LIST_RES = 'DO_FETCH_COMPLETE_ALLOCATION_LIST_RES';

export const DO_CREATE_ALLOCATION = 'DO_CREATE_ALLOCATION';
export const DO_CREATE_ALLOCATION_RES = 'DO_CREATE_ALLOCATION_RES';

export const DO_DELETE_ALLOCATION = 'DO_DELETE_ALLOCATION';
export const DO_DELETE_ALLOCATION_RES = 'DO_DELETE_ALLOCATION_RES';

export const DO_FETCH_ALLOCATION_OFFERING_LIST = 'DO_FETCH_ALLOCATION_OFFERING_LIST';
export const DO_FETCH_ALLOCATION_OFFERING_LIST_RES = 'DO_FETCH_ALLOCATION_OFFERING_LIST_RES';

export const DO_FETCH_ALLOCATION_OFFERING = 'DO_FETCH_ALLOCATION_OFFERING';
export const DO_FETCH_ALLOCATION_OFFERING_RES = 'DO_FETCH_ALLOCATION_OFFERING_RES';

export const DO_SEND_ALLOCATION_EMAIL = 'DO_SEND_ALLOCATION_EMAIL';
export const DO_SEND_ALLOCATION_EMAIL_RES = 'DO_SEND_ALLOCATION_EMAIL_RES';

export const DO_CREATE_WARRANT = 'DO_CREATE_WARRANT';
export const DO_CREATE_WARRANT_RES = 'DO_CREATE_WARRANT_RES';
export const DO_UPDATE_WARRANT_RES = 'DO_UPDATE_WARRANT_RES';

export const DO_SEND_IOI_NOTIFICATION = 'DO_SEND_IOI_NOTIFICATION';
export const DO_SEND_IOI_NOTIFICATION_RES = 'DO_SEND_IOI_NOTIFICATION_RES';

export const DO_RUN_ALLOCATION = 'DO_RUN_ALLOCATION';
export const DO_RUN_ALLOCATION_RES = 'DO_RUN_ALLOCATION_RES';

export const DO_FETCH_BROKERDEALER_ALLOCATION = 'DO_FETCH_BROKERDEALER_ALLOCATION';
export const DO_FETCH_BROKERDEALER_ALLOCATION_RES = 'DO_FETCH_BROKERDEALER_ALLOCATION_RES';

export const DO_SEND_BROKERDEALER = 'DO_SEND_BROKERDEALER';
export const DO_SEND_BROKERDEALER_RES = 'DO_SEND_BROKERDEALER_RES';

export const DO_SEND_BROKERDEALER_PRICE = 'DO_SEND_BROKERDEALER_PRICE';
export const DO_SEND_BROKERDEALER_PRICE_RES = 'DO_SEND_BROKERDEALER_PRICE_RES';

export const DO_FETCH_SHOW_ALLOCATION = 'DO_FETCH_SHOW_ALLOCATION';
export const DO_FETCH_SHOW_ALLOCATION_RES = 'DO_FETCH_SHOW_ALLOCATION_RES';

export const DO_FETCH_SHOW_ALLOCATION_METADATA = 'DO_FETCH_SHOW_ALLOCATION_METADATA';
export const DO_FETCH_SHOW_ALLOCATION_METADATA_RES = 'DO_FETCH_SHOW_ALLOCATION_METADATA_RES';

export const DO_SAVE_ALLOCATION = 'DO_SAVE_ALLOCATION';
export const DO_SAVE_ALLOCATION_RES = 'DO_SAVE_ALLOCATION_RES';

export const DO_COMPLETE_ALLOCATION = 'DO_COMPLETE_ALLOCATION';
export const DO_COMPLETE_ALLOCATION_RES = 'DO_COMPLETE_ALLOCATION_RES';

export const DO_CALL_INTACT_FILE = 'DO_CALL_INTACT_FILE';
export const DO_CALL_INTACT_FILE_RES = 'DO_CALL_INTACT_FILE_RES';

export const DO_CALL_SHOW_INTACT_FILE = 'DO_CALL_SHOW_INTACT_FILE';
export const DO_CALL_SHOW_INTACT_DOCUMENT_RES = 'DO_CALL_SHOW_INTACT_DOCUMENT_RES';

export const DO_FETCH_ALLOCATION_DETAIL = 'DO_FETCH_ALLOCATION_DETAIL';
export const DO_FETCH_ALLOCATION_DETAIL_RES = 'DO_FETCH_ALLOCATION_DETAIL_RES';

export const DO_DELETE_ALL_ORDER = 'DO_DELETE_ALL_ORDER';
export const DO_DELETE_ALL_ORDER_RES = 'DO_DELETE_ALL_ORDER_RES';

export const DO_FETCH_CREATED_USERS = 'DO_FETCH_CREATED_USERS';
export const DO_FETCH_CREATED_USERS_RES = 'DO_FETCH_CREATED_USERS_RES';

export const DO_FETCH_OFFERING_ATTRIBUTION = 'DO_FETCH_OFFERING_ATTRIBUTION';
export const DO_FETCH_OFFERING_ATTRIBUTION_RES = 'DO_FETCH_OFFERING_ATTRIBUTION_RES';

export const DO_FETCH_CONNECTED_CUSTOMERS = 'DO_FETCH_CONNECTED_CUSTOMERS';
export const DO_FETCH_CONNECTED_CUSTOMERS_RES = 'DO_FETCH_CONNECTED_CUSTOMERS_RES';

export const DO_FETCH_NONCONNECTED_CUSTOMERS = 'DO_FETCH_NONCONNECTED_CUSTOMERS';
export const DO_FETCH_NONCONNECTED_CUSTOMERS_RES = 'DO_FETCH_NONCONNECTED_CUSTOMERS_RES';

export const DO_FETCH_DELETED_CUSTOMERS = 'DO_FETCH_DELETED_CUSTOMERS';
export const DO_FETCH_DELETED_CUSTOMERS_RES = 'DO_FETCH_DELETED_CUSTOMERS_RES';

export const DO_FETCH_OFFERING_STATS_GRAPH = 'DO_FETCH_OFFERING_STATS_GRAPH';
export const DO_FETCH_OFFERING_STATS_GRAPH_RES = 'DO_FETCH_OFFERING_STATS_GRAPH_RES';

export const DO_FETCH_ORDERS_METADATA = 'DO_FETCH_ORDERS_METADATA';
export const DO_FETCH_ORDERS_METADATA_RES = 'DO_FETCH_ORDERS_METADATA_RES';

export const DO_SEND_PENDING_BROKERDEALER = 'DO_SEND_PENDING_BROKERDEALER';
export const DO_SEND_PENDING_BROKERDEALER_RES = 'DO_SEND_PENDING_BROKERDEALER_RES';

export const FETCH_REVENUE_LIST = 'FETCH_REVENUE_LIST';
export const FETCH_REVENUE_LIST_RES = 'FETCH_REVENUE_LIST_RES';

export const FETCH_OFFERING_CALLBACKS = 'FETCH_OFFERING_CALLBACKS';
export const FETCH_OFFERING_CALLBACKS_RES = 'FETCH_OFFERING_CALLBACKS_RES';

export const FETCH_WHITELISTED_IP = 'FETCH_WHITELISTED_IP';
export const FETCH_WHITELISTED_IP_RES = 'FETCH_WHITELISTED_IP_RES';

export const CREATE_WHITELISTED_IP = 'CREATE_WHITELISTED_IP';
export const CREATE_WHITELISTED_IP_RES = 'CREATE_WHITELISTED_IP_RES';

export const UPDATE_WHITELISTED_IP = 'UPDATE_WHITELISTED_IP';
export const UPDATE_WHITELISTED_IP_RES = 'UPDATE_WHITELISTED_IP_RES';

export const DELETE_WHITELISTED_IP = 'DELETE_WHITELISTED_IP';
export const DELETE_WHITELISTED_IP_RES = 'DELETE_WHITELISTED_IP_RES';

export const DO_CALL_BRAGGART_RES = 'DO_CALL_BRAGGART_RES';
export const FETCH_CURRENT_DATE_TIME_RES = 'FETCH_CURRENT_DATE_TIME_RES';

export const DO_FETCH_ISSUER_RES = 'DO_FETCH_ISSUER_RES';
export const DO_CREATE_ISSUER_RES = 'DO_CREATE_ISSUER_RES';
export const DO_UPDATE_ISSUER_RES = 'DO_UPDATE_ISSUER_RES';
export const DO_DELETE_ISSUER_RES = 'DO_DELETE_ISSUER_RES';

export const FETCH_COMPANY_INFO_RES = 'FETCH_COMPANY_INFO_RES';

export const DO_FETCH_OFFERING_ASSET_TYPES_RES = 'DO_FETCH_OFFERING_ASSET_TYPES_RES';