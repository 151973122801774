import * as types from './actionTypes';
import issuerCommApi from '../api/issuerCommApi';
import { logout } from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';


export function doFetchIssuerCommListRes(data) {
    return {
        type: types.DO_FETCH_ISSUER_RES,
        data
    };
}

export function fetchIssuerCommList() {
    const TOKEN = getItem('auth_token');
    if (TOKEN) {
        return function (dispatch) {
            dispatch(loadingRes(true));
            issuerCommApi.doFetchIssuerComm().then(data => {
                dispatch(doFetchIssuerCommListRes(data));
                dispatch(doFetchIssuerCommListRes(null));
                dispatch(loadingRes(false));
                if (data.error) {
                    Swal.fire({
                        title: data.message,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,
                        timer: 3000,
                        heightAuto: false
                    });
                }
            }).catch(error => {
                dispatch(loadingRes(false));
                return error
            });
        };
    } else {
        logout()
    }

}

export function doCreateIssuerCommRes(data) {
    return {
        type: types.DO_CREATE_ISSUER_RES,
        data
    };
}

export function createIssuerComm(req) {
    const TOKEN = getItem('auth_token');
    if (TOKEN) {
        return function (dispatch) {
            dispatch(loadingRes(true));
            issuerCommApi.doCreateIssuerComm(req).then(data => {
                dispatch(doCreateIssuerCommRes(data));
                dispatch(doCreateIssuerCommRes(null));
                dispatch(loadingRes(false));
                if (data.error) {
                    Swal.fire({
                        title: data.message,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,
                        timer: 3000,
                        heightAuto: false
                    });
                }
            }).catch(error => {
                dispatch(loadingRes(false));
                return error
            });
        };
    } else {
        logout()
    }

}

export function doUpdateIssuerCommRes(data) {
    return {
        type: types.DO_UPDATE_ISSUER_RES,
        data
    };
}

export function updateIssuerComm(req) {
    const TOKEN = getItem('auth_token');
    if (TOKEN) {
        return function (dispatch) {
            dispatch(loadingRes(true));
            issuerCommApi.doUpdateIssuerComm(req).then(data => {
                dispatch(doUpdateIssuerCommRes(data));
                dispatch(doUpdateIssuerCommRes(null));
                dispatch(loadingRes(false));
                if (data.error) {
                    Swal.fire({
                        title: data.message,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,
                        timer: 3000,
                        heightAuto: false
                    });
                }
            }).catch(error => {
                dispatch(loadingRes(false));
                return error
            });
        };
    } else {
        logout()
    }

}
export function doDeleteIssuerCommRes(data) {
    return {
        type: types.DO_DELETE_ISSUER_RES,
        data
    };
}

export function deleteIssuerComm(req) {
    const TOKEN = getItem('auth_token');
    if (TOKEN) {
        return function (dispatch) {
            dispatch(loadingRes(true));
            issuerCommApi.doDeleteIssuerComm(req).then(data => {
                dispatch(doUpdateIssuerCommRes(data));
                dispatch(doUpdateIssuerCommRes(null));
                dispatch(loadingRes(false));
                if (data.error) {
                    Swal.fire({
                        title: data.message,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,
                        timer: 3000,
                        heightAuto: false
                    });
                }
            }).catch(error => {
                dispatch(loadingRes(false));
                return error
            });
        };
    } else {
        logout()
    }

}