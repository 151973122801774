import React, { Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import styles from './sidebar-jss';
import SidebarContent from './SidebarContent';

class Sidebar extends React.Component {
  state = {
    anchorEl: null,
    turnDarker: false,
    profileDetails: ''
  };

  // Initial header style
  flagDarker = false;

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
  }


  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const doc = document.documentElement;
    const scroll = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const newFlagDarker = (scroll > 30);
    if (this.flagDarker !== newFlagDarker) {
      this.setState({ turnDarker: newFlagDarker });
      this.flagDarker = newFlagDarker;
    }
  }

  handleOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleChangeStatus = status => {
    this.setState({ status });
    this.handleClose();
  }

  render() {
    const {
      classes,
      open,
      toggleDrawerOpen,
      leftSidebar
    } = this.props;
    const { status, anchorEl, turnDarker } = this.state;
    return (
      <Fragment>
        <Hidden lgUp>
          <SwipeableDrawer
            onClose={toggleDrawerOpen}
            onOpen={toggleDrawerOpen}
            open={!open}
            anchor={leftSidebar ? 'left' : 'right'}
          >
            <div className={classes.swipeDrawerPaper}>
              <SidebarContent
                drawerPaper
                leftSidebar={leftSidebar}
                toggleDrawerOpen={toggleDrawerOpen}
                status={status}
                anchorEl={anchorEl}
                openMenuStatus={this.handleOpen}
                closeMenuStatus={this.handleClose}
                changeStatus={this.handleChangeStatus}
                profileDetails={this.state.profileDetails}
              />
            </div>
          </SwipeableDrawer>
        </Hidden>
        <Hidden mdDown>
          <Drawer
            variant="permanent"
            onClose={toggleDrawerOpen}
            className={open ? classes.drawer : ''}
            classes={{
              paper: classNames(classes.drawer, classes.drawerPaper, !open ? classes.drawerPaperClose : ''),
            }}
            open={open}
            anchor={leftSidebar ? 'left' : 'right'}
          >
            <SidebarContent
              drawerPaper={open}
              leftSidebar={leftSidebar}
              turnDarker={turnDarker}
              status={status}
              anchorEl={anchorEl}
              openMenuStatus={this.handleOpen}
              closeMenuStatus={this.handleClose}
              changeStatus={this.handleChangeStatus}
              profileDetails={this.state.profileDetails}
            />
          </Drawer>
        </Hidden>
      </Fragment>
    );
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object,
  toggleDrawerOpen: PropTypes.func,
  open: PropTypes.bool,
  leftSidebar: PropTypes.bool,
};

Sidebar.defaultProps = {
  leftSidebar: true
};

const mapStateToProps = createStructuredSelector({
});

function mapDispatchToProps(dispatch) {
  return {
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);

const SidebarComp = compose(withConnect)(Sidebar);
export default withStyles(styles)(SidebarComp);
