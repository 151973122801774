import * as types from './actionTypes';
import {logout} from '../helper/helper';
import remainingDaysChangePasswordApi from '../api/remainingDaysChangePasswordApi';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function remainingDaysChangePassword(request) {
  return {
    type: types.REMAINING_DAYS_CHANGEPASSWORD,
    request
  };
}

export function remainingDaysChangePasswordRes(user) {
  return {
    type: types.REMAINING_DAYS_CHANGEPASSWORD_RES,
    user
  };
}
export function submitRemainingDaysChangePassword(request) {
  const TOKEN = getItem('auth_token_2');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      remainingDaysChangePasswordApi.remainingDaysChangePassword(request).then(data => {
        dispatch(remainingDaysChangePasswordRes(data));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
}