import * as types from '../actions/actionTypes'; 
import initialState from './initialState';


//all the offering related reducers here
export default function (state = initialState.user, action) { //we will change the state = {} soon
  switch(action.type) {
    case types.DO_FETCH_OFFERING_STATS_GRAPH_RES:
      return action.data
    default:
      return state
  }
}