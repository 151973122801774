import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import '../admin.scss';
import { getItem } from '../../../utils/localStore';
import { getBrokerDealerList, doBrokerDealerListRes } from '../../../actions/brokerDealerListActions';
import AdminBrokerDealerOfferingList from './adminBrokerdealerOfferings';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import GridTable from '@nadavshaar/react-grid-table';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { saveFile } from '../../../helper/helper';
let isBrokerDealerList = false;
class BrokerdealersComponent extends React.PureComponent {
	constructor(props) {
		super(props);
		isBrokerDealerList = false;
		var columns = [
			{ id: 1, field: 'name', label: 'Name', visible: true, sortable: true, cellRenderer: this.actionNameBrokerDealer },
			{ id: 2, field: 'clearing_account_id', label: 'ID', visible: true, sortable: true, cellRenderer: this.actionOtherNames },
			{ id: 3, field: 'num_users', label: '# Users', visible: true, sortable: true, cellRenderer: this.actionOtherNames },
			{ id: 4, field: 'mpid', label: 'MPID', visible: true, sortable: true, cellRenderer: this.actionOtherNames },
			{ id: 5, field: 'blocker_code', label: 'Type', visible: true, sortable: true, cellRenderer: this.actionOtherNames },
		];
		let getLevel = Number(localStorage.getItem('level'));
		if (getLevel === 0) {
			columns.push({ id: 6, field: 'commission_percentage', label: 'Commission', visible: true, sortable: true, cellRenderer: this.actionOtherNames },
			{ id: 7, field: 'action', label: 'Action', visible: true, sortable: false, cellRenderer: this.actionAddBtn });
		}
		this.state = {
			cols: columns,
			isReadOnly: JSON.parse(getItem('isReadOnly')),
			showOfering: false,
			brokerdealerList: [],
			brokerDealerData: null,
		}
	}
	componentDidMount() {
		isBrokerDealerList = true;
		this.props.getBrokerDealerList();
	}
	static getDerivedStateFromProps(props, state) {
		if (props.brokerDealerListRes) {
			if (props.brokerDealerListRes.data && props.brokerDealerListRes.data.bdList) {
				if (props.brokerDealerListRes.data.bdList.message === 'Success' && isBrokerDealerList) {
					isBrokerDealerList = false;
					if (props.brokerDealerListRes.data.bdList.data.length > 0) {
						let bdData = props.brokerDealerListRes.data.bdList.data;
						for (let i = 0; i < bdData.length; i++) {
							
                            Object.keys(bdData[i]).forEach((field) => { /*eslint-env es6*/
								if(bdData[i][field] === null){
									bdData[i][field] = "";
								}
                            })                   
                    	};
						let bdArr = [];

						for (let item of bdData) {
							// this.checkProperties(item);

							if (item.type === 'BrokerDealer') {
								bdArr.push(item)
							}
						}
						return {
							brokerdealerList: bdArr,
						};
					}
					
				}
			}
		}
		return null
	}
	downLoadCSV = async (csvData, fileName) => {
		let csvContent = '';
		csvContent += 'Name, ID, MPID,Type, Commission';
		csvData.forEach(function (rowArray) {
			let newRow = rowArray.name + ',' + rowArray.clearing_account_id + ',' + rowArray.mpid + ',' + rowArray.blocker_code + ',' + rowArray.commission_percentage;
			csvContent += newRow + '\r\n'; // add carriage return
		});
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
		await saveFile(blob, fileName + '.csv');
	}
	actionAddBtn = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return (<div className="rgt-cell-inner">
			<Tooltip title="Admins">
				<Button component={Link} to={{ pathname: `/brokerdealer-admin`, state: { brokerDealerData: data } }} variant="contained" color="primary" style={{ margin: '5px' }} ><AccountBoxIcon /></Button>
			</Tooltip>
		</div>);
	}
	closeOffering = () => {
		this.setState({
			showOfering: false,
		});
	}
	openMpidListModal = (data) => {
		this.setState({
			offeringMpid: data.mpid,
			brokerDealerData: data,
		}, () => {
			this.setState({
				showOfering: true,
			});
		});
	}
	actionNameBrokerDealer = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return  <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
			{
				(value !== '' && value !== undefined) &&
				<Tooltip title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
					<div className="text-one-line green-color-with-pointer" style={{width: '90%'}} onClick={() => this.openMpidListModal(data)}>{value}</div>
				</Tooltip>
			}
			{
				(value !== '' && value !== undefined) &&
				<Tooltip title={'Copy to clipboard'} interactive >
				<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
				</Tooltip>
			}
		</div>;
	}
	actionOtherNames = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return  <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
			{
				(value !== '' && value !== undefined) &&
				<Tooltip title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
					<div className="text-one-line" style={{width: '90%'}} >{value}</div>
				</Tooltip>
			}
			{
				(value !== '' && value !== undefined) &&
				<Tooltip title={'Copy to clipboard'} interactive >
				<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
				</Tooltip>
			}
		</div>;
	}
	render() {
		const { brokerdealerList, isReadOnly } = this.state;
    	let userLevel = Number(localStorage.getItem('level'));
		return (
			<div className="stats_offering_content_body">
				{
					(!this.state.showOfering) &&
					<div>
						<div className="row">
							<div className="col-md-2">
							{
								(userLevel === 0) && (!isReadOnly) &&
								<Button component={Link} to={'/create-brokerdealer'} variant="contained" color="primary" style={{ marginTop: '15px' }} >New Broker</Button>
							}
							</div>
							<div className="col-md-10" style={{ textAlign: 'right' }}>
								{brokerdealerList && brokerdealerList.length > 0 && <Button style={{ marginTop: '15px' }} variant="contained" color="primary" onClick={() => this.downLoadCSV(brokerdealerList, 'Brokerdealers')}>Download CSV</Button>}
							</div>
						</div>
						<div className="row">
							<div className="col-md-12 brokerdealer-system-setting-table" style={{ paddingTop: 15, paddingBottom: 15 }}>
								<GridTable
									columns={this.state.cols}
									rows={brokerdealerList}
									isPaginated={true}
									isVirtualScroll={true}
									showSearch={true}
									showRowsInformation={false}
									isHeaderSticky={true}
									// onSearchTextChange={({value, searchText}) => value.toString().toUpperCase().indexOf(searchText.toString().toUpperCase()) !== -1}
								/>
							</div>

						</div>
					</div>
				}
				{
					(this.state.showOfering) &&
						<AdminBrokerDealerOfferingList mpid={this.state.offeringMpid} brokerDealer={this.state.brokerDealerData} closeOffering={this.closeOffering} offType={'brokerdealer'}/>
				}
			</div>
		)
	}
}
BrokerdealersComponent.propTypes = {
	brokerDealerListRes: PropTypes.any,
};
const mapStateToProps = createStructuredSelector({
	brokerDealerListRes: doBrokerDealerListRes,
});
function mapDispatchToProps(dispatch) {
	return {
		getBrokerDealerList: () => dispatch(getBrokerDealerList()),
	};
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(BrokerdealersComponent);
