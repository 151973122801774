import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Link } from 'react-router-dom';
import './offerings.scss';
import loaderImg from '../../assets/images/spinn_img.gif';
import { getItem } from '../../utils/localStore';
// import { getOffering, doOfferingRes } from '../../actions/offeringActions';
import OfferingImg from '../../assets/images/placeholder_company_thumb.png';
import loadingImg from '../../assets/images/loader-example.gif';
import { getOffering } from '../../actions/offeringActions';
import { getDate, numberWithCommasAndDollar, getOfferingPriceRange } from '../../helper/helper';
import { sendNotifyUser, doNotifyUserRes } from '../../actions/notifyUserActions';
import { getDspUsers, doDspUsersListRes } from '../../actions/dspUsersActions';
import { sendSixtyMinuteEmail, doSendSixtyMinuteEmailRes } from '../../actions/sixtyminuteEmailActions';
import { doArchiveOfferingRes, doUnarchiveOfferingRes } from '../../actions/archiveOfferingActions';
import { updateProspectus, doUpdateProspectusRes } from '../../actions/updateProspectusActions';
import { updateOfferingReconfirmRequest, doUpdateOfferingReconfirmRequestRes } from '../../actions/updateOfferingReconfirmRequestActions';
import { getCurrentDateTime, getCurrentDateTimeRes } from '../../actions/currentDateTimeActions';

// import { sendCreateWarrant, doCreateWarrantRes, sendUpdateWarrant, doUpdateWarrantRes } from '../../actions/createWarrantActions';

import { getRatingList, doRatingListRes } from '../../actions/ratingListActions';
import { setUnderwriters, doSetUnderwriterRes } from '../../actions/setUnderwriterActions';
// import { Calendar } from 'primereact/calendar';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import Swal from 'sweetalert2';
import StarRatingComponent from 'react-star-rating-component';
import SpinnImg from '../../assets/images/loader-example.gif';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { DialogActions } from '@material-ui/core';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import GridTable from '@nadavshaar/react-grid-table';
import Countdown from "react-countdown";
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InfoIcon from '@material-ui/icons/Info';								
import DeleteIcon from '@material-ui/icons/Delete';
// Random component

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return '00:00:00';
  } else {
    hours = hours.toString().length === 1?`0${hours}`:hours;
		minutes = minutes.toString().length === 1?`0${minutes}`:minutes;
		seconds = seconds.toString().length === 1?`0${seconds}`:seconds;
    return (
      <span>
        {hours}:{minutes}:{seconds}
      </span>
    );
  }
};
const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
	}))(props => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});
  
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

let offeringViewList = [];

class OfferingListComponent extends React.PureComponent {

	constructor() {
		super();
		const today = new Date();
		const month = today.getMonth();
		const year = today.getFullYear();
		const prevMonth = (month === 0) ? 11 : month - 1;
		const prevYear = (prevMonth === 11) ? year - 1 : year;
		const nextMonth = (month === 11) ? 0 : month + 1;
		const nextYear = (nextMonth === 0) ? year + 1 : year;

		const minDate = new Date();
		minDate.setMonth(prevMonth + 1);
		minDate.setFullYear(prevYear);
		const maxDate = new Date();
		maxDate.setMonth(nextMonth - 1);
		maxDate.setFullYear(nextYear);
		const columns = [
			{ id: 1, field: 'first_name', label: 'First Name', visible: true, sortable: true },
			{ id: 2, field: 'count', label: 'Order count', visible: true, sortable: true },
			{ id: 3, field: 'email', label: 'Email Address', visible: true, sortable: true, cellRenderer: this.emailTemp },
			{ id: 4, field: 'max_shares', label: 'Shares', visible: true, sortable: true },
			{ id: 5, field: 'state', label: 'State', visible: true, sortable: true },
			{ id: 6, field: 'requested_shares', label: 'Requested Shares', visible: true, sortable: true },
			{ id: 7, field: 'broker_connected_at', label: 'Broker Connected At', visible: true, sortable: true },
			{ id: 8, field: 'ordered_at', label: 'Ordered At', visible: true, sortable: true },
			{ id: 9, field: 'created_at', label: 'Created At', visible: true, sortable: true },
			{ id: 10, field: 'updated_at', label: 'Updated At', visible: true, sortable: true }
		];
		const warrantColumns = [
			{ id: 1, field: 'warrant_price', label: 'Warrant Price', visible: true, sortable: true },
			{ id: 2, field: 'warrant_factor', label: 'Warrant Factor', visible: true, sortable: true },
			{ id: 3, field: 'cusip_id', label: 'Cusip ID', visible: true, sortable: true },
			{ id: 4, width: '300px', field: 'underwriter_concession_amount', label: 'Underwriter Concession Amount', visible: true, sortable: true },
			{ id: 4, field: 'ticker_symbol', label: 'Ticker Symbol', visible: true, sortable: true },
			{ id: 4, width: '10%', field: 'dsp', label: 'DSP', visible: true, sortable: true },
		];
		this.state = {
			warrantCols: warrantColumns,
			cols: columns,
			isReadOnly: JSON.parse(getItem('isReadOnly')),
            loading: false,
			archiveConfirmshow: false,
			emailConfirmshow: false,
			prospectConfirmshow: false,
			offeringInfo: {},
			isLoad: false,
			isArchive: '',
			sendSixtyMinuteConfirm: false,
			sixtyMinuteofferingInfo: '',
			minDate: minDate,
			maxDate: maxDate,
			max_date_show: '',
			max_date_show_second: '',
			filterBy: '',
			reconfirmExtId: '',
			loadingimg: null,
			followingModal: null,
			followingCount: null,
			isSubmit: false,
			offeringIndex: null,
			notificationModal: false,
			offeringId: '',
			viewDetailModal: false,
			sendType: 'EMAIL',
			title: '',
			message: '',
			
			selectEmail: false,
			selectPush: false,
			selectCallback: false,
			selectAll: false,
			selectFollowers: false,
			selectConnectedCustomers: false,
			selectOrders: false,
			selectBizUsers: false,
			showCommentLoader: false,

			email: 0,
			push: 0,
			callback: 0,
			all: 0,
			followers: 0,
			connectedCustomers: 0,
			orders: 0,
			biz_users: 0,
			verb: '',
			showNotifyType: '',
			showNotifySecondType: '',
			showNotifyThirdType: '',
			submitNotifyFormModal: false,
			nCheckbox: [],
			uCheckbox: [],
			dspModal: false,
			dspUsersList: [],
			openDspUserEditModal: false,
			confirmDspUserDeleteModal: false,
			editDspData: {},
			file: null,
			dspFirstName: '',
			dspLastName: '',
			dspEmail: '',
			dspMaxShares: '',
			dspRowData: {},
			notifyAttachment: null,
			ratingModal: false,
			ratingList: [],
			offeringList:[],
            warrantList: [],
			renderUI: false,
			wListOld: [],
			offeringDetail: ''
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (props.dspUsersListRes) {
			if (props.dspUsersListRes.data && props.dspUsersListRes.data.dspUsers) {
				if (props.dspUsersListRes.data.dspUsers.message === 'Success') {
					return {
						dspUsersList: props.dspUsersListRes.data.dspUsers.data,
						loading: false
					};
				}
			}
		}
		if (props.notifyUserRes) {
			if (props.notifyUserRes.data && props.notifyUserRes.data.notifyUser) {
				if (props.notifyUserRes.data.notifyUser.message === 'Success') {
					return {
						submitNotifyFormModal: false,
						notificationModal: false,
						title: '',
						message: '',

						selectEmail: false,
						selectPush: false,
						selectCallback: false,
						selectAll: false,
						selectFollowers: false,
						selectConnectedCustomers: false,
						selectOrders: false,
						selectBizUsers: false,

						email: 0,
						push: 0,
						callback: 0,
						all: 0,
						followers: 0,
						connectedCustomers: 0,
						orders: 0,
						biz_users: 0,
						verb: '',
						showNotifyType: '',
						showNotifySecondType: '',
						showNotifyThirdType: '',
						nCheckbox: [],
						uCheckbox: [],
					};
				}
			}
		}
		if (props.sendSixtyMinuteEmailRes) {
			if (props.sendSixtyMinuteEmailRes.data && props.sendSixtyMinuteEmailRes.data.sixtyminuteEmail) {
				if (props.sendSixtyMinuteEmailRes.data.sixtyminuteEmail.message === 'Success') {
					return {
						sendSixtyMinuteConfirm: false,
					};
				}
			}
		}
		if (props.archiveOfferingRes) {
			if (props.archiveOfferingRes.data && props.archiveOfferingRes.data.archiveOffering) {
				if (props.archiveOfferingRes.data.archiveOffering.message === 'Success') {
					return {
						archiveConfirmshow: false,
					};
				}
			}
		}
		
		if (props.unarchiveOfferingRes) {
			if (props.unarchiveOfferingRes.data && props.unarchiveOfferingRes.data.unarchiveOffering) {
				if (props.unarchiveOfferingRes.data.unarchiveOffering.message === 'Success') {
					return {
						archiveConfirmshow: false,
					};
				}
			}
		}
		
		if (props.updateOfferingReconfirmRequestRes) {
			if (props.updateOfferingReconfirmRequestRes.data && props.updateOfferingReconfirmRequestRes.data.reconfirmRequest) {
				if (props.updateOfferingReconfirmRequestRes.data.reconfirmRequest.message === 'Success') {
					return {
						offeringIndex: null,
						isSubmit: false,
					};
				}
			}
		}
		
		if (props.ratingListRes) {
			if (props.ratingListRes.data && props.ratingListRes.data.ratingList) {
				if (props.ratingListRes.data.ratingList.message === 'Success') {
					return {
						ratingList: props.ratingListRes.data.ratingList.data,
						showCommentLoader: false,
					};
				}
			}
		}

		if (props.getCurrentDateTimeRes) {
			if (props.getCurrentDateTimeRes.data && props.getCurrentDateTimeRes.data.currentDateTime) {
				if (props.getCurrentDateTimeRes.data.currentDateTime.message === 'Success') {
					let currentDate = props.getCurrentDateTimeRes.data.currentDateTime.data;
					// let clientDate = new Date('2022-03-23 09:14:58.765686');
					return {
						max_date_show: new Date(currentDate),
						max_date_show_second: new Date(currentDate),
						showCommentLoader: false,
					};
				}
			}
		}
		
		return null;
	}
	emailTemp = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return <div className="rgt-cell-inner rgt-text-truncate" style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
			<Tooltip title={value !== undefined ? value : ''}  interactive={!value}>
				<div className="text-one-line" style={{width: '90%'}}>{value}</div>
			</Tooltip>
      		{
				value !== '' &&
				<Tooltip title={'Copy to clipboard'} interactive >
					<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small"/> </Button>
				</Tooltip>
			}
		</div>;
	}
	addDefaultSrc = (ev) => {
		ev.target.src = OfferingImg;
	}
	validate = (values) => {
		const errors = {};
	  
		if (!values.max_date_show) {
		  errors.max_date = 'Please select date & Time ';
		}
		if (!values.max_date_show_second) {
		  errors.max_date_second = 'Please select date';
		}
		if (!values.filterBy) {
		  errors.dropdownErr = 'Please select field';
		}
	  
		return errors;
	}
	resetAll = () => {
		this.setState({
			title: '',
			message: '',

			selectEmail: false,
			selectPush: false,
			selectCallback: false,
			selectAll: false,
			selectFollowers: false,
			selectConnectedCustomers: false,
			selectOrders: false,
			selectBizUsers: false,

			email: 0,
			push: 0,
			callback: 0,
			all: 0,
			followers: 0,
			connectedCustomers: 0,
			orders: 0,
			biz_users: 0,
			verb: '',
			showNotifyType: '',
			showNotifySecondType: '',
			showNotifyThirdType: '',
			submitNotifyFormModal: false,
			nCheckbox: [],
			uCheckbox: [],
		});
	}
	firstLayer = (e, value) => {
		if (value === 'email') {
			if (e.target.checked === true) {
				this.setState({
					selectEmail: true,
					email: 1,
				});
			} else {
				this.setState({
					selectEmail: false,
					email: 0,
				});
			}
		}
		if (value === 'push') {
			if (e.target.checked === true) {
				this.setState({
					selectPush: true,
					push: 1,
				});
			} else {
				this.setState({
					selectPush: false,
					push: 0,
				});
			}
		}
		if (value === 'callback') {
			if (e.target.checked === true) {
				this.setState({
					selectCallback: true,
					callback: 1,
				});
			} else {
				this.setState({
					selectCallback: false,
					callback: 0,
				});
			}
		}

		let checkedArray = this.state.nCheckbox;
		let selectedValue = value;
		if (e.target.checked === true) {

			checkedArray.push(selectedValue);
			this.setState({
				nCheckbox: checkedArray
			}, () => { });

		} else {

			let valueIndex = checkedArray.indexOf(selectedValue);
			checkedArray.splice(valueIndex, 1);

			this.setState({
				nCheckbox: checkedArray
			}, () => { });

		}

	}
	
	secondLayer = (e, value) => {
		if (value === 'all') {
			if (e.target.checked === true) {
				this.setState({
					selectAll: true,
					all: 1,
					followers: 0,
					orders: 0,
					biz_users: 0,
					connectedCustomers: 0,
					selectFollowers: false,
					selectOrders: false,
					selectBizUsers: false,
					selectConnectedCustomers: false,
				});
			} else {
				this.setState({
					selectAll: false,
					all: 0,
				});
			}
		}
		if (value === 'followers') {
			if (e.target.checked === true) {
				this.setState({
					selectAll: false,
					followers: 1,
					all: 0,
					orders: 0,
					connectedCustomers: 0,
					selectConnectedCustomers: false,
					selectFollowers: true,
					selectOrders: false,
				}, () => { });
			} else {
				this.setState({
					selectFollowers: false,
					followers: 0,
				});
			}
		}

		if (value === 'connectedCustomers') {
			if (e.target.checked === true) {
				this.setState({
					selectAll: false,
					connectedCustomers: 1,
					followers: 0,
					all: 0,
					orders: 0,
					selectConnectedCustomers: true,
					selectFollowers: false,
					selectOrders: false,
					selectBizUsers: false,
					biz_users: 0,
				}, () => { });
			} else {
				this.setState({
					selectConnectedCustomers: false,
					connectedCustomers: 0,
				});
			}
		}
		if (value === 'orders') {
			if (e.target.checked === true) {
				this.setState({
					selectAll: false,
					orders: 1,
					all: 0,
					followers: 0,
					connectedCustomers: 0,
					selectFollowers: false,
					selectOrders: true,
					selectConnectedCustomers: false,
				}, () => { });
			} else {
				this.setState({
					selectOrders: false,
					orders: 0,
				});
			}
		}
		if (value === 'bizinsight') {
			if (e.target.checked === true) {
				this.setState({
					selectAll: false,
					connectedCustomers: 0,
					selectConnectedCustomers: false,
					selectBizUsers: true,
					biz_users: 1,
				});
			} else {
				this.setState({
					selectBizUsers: false,
					biz_users: 0,
				});
			}
		}

		let checkedArray2 = this.state.uCheckbox;
		let selectedValue2 = value;
		if (e.target.checked === true) {

			checkedArray2.push(selectedValue2);
			this.setState({
				uCheckbox: checkedArray2
			}, () => { });

		} else {

			let valueIndex = checkedArray2.indexOf(selectedValue2);
			checkedArray2.splice(valueIndex, 1);

			this.setState({
				uCheckbox: checkedArray2
			}, () => { });

		}
	}
	
	onSelectVerb = (e, type) => {
		if (type === "create") {
			this.setState({
				verb: 'offering_create',
			});
		} else {
			this.setState({
				verb: 'offering_update',
			});
		}
	}
	
	openSubmitNotifyModal = () => {
		this.setState({
			submitNotifyFormModal: true,
		});
	}

	closeSubmitNotify = () => {
		this.setState({
			submitNotifyFormModal: false,
		});
	}

	openNotificationModal = (offering) => {
		this.setState({
			notificationModal: true,
			offeringId: offering.ext_id,
		});
	}
	closeNotificationModal = () => {
		this.resetAll();
		this.setState({
			notificationModal: false,
		});
	}
	
	openDspModal = (offering) => {
		this.setState({
			dspModal: true,
			offeringId: offering.ext_id,
		}, () => {
			this.props.getDspUsers(offering.ext_id);
			this.setState({ loading: true });
		});
	}
	openRatingModal = (offering) => {
		this.setState({
			ratingModal: true,
			offeringId: offering.ext_id,
			offeringRatingCount: offering.rating_count,
			offeringAvgRating: offering.rating_average,
			showCommentLoader: true,
		}, () => {
			this.props.doGetRatings(offering.ext_id);
		});
	}
	closeRatingModal = () => {
		this.setState({
			ratingModal: false,
		}, () => { });
	}
	closeDspModal = () => {
		this.props.doDspUsersListRes(null);
		this.setState({
			dspModal: false,
			dspUsersList: [],
		});

	}

	toggleModal = () => {
		this.setState({
			archiveConfirmshow: false,
			emailConfirmshow: false,
			prospectConfirmshow: false,
		});
	}
	openSixtyMinuteModal = (offeringInfo) => {
		this.setState({
			sixtyMinuteofferingInfo: offeringInfo,
			sendSixtyMinuteConfirm: true,
		});
	}

	makeCurrentCall = (item) => {
		this.props.makeCurrent(item.ext_id);
	}

	closeModal = () => {
		this.setState({ viewDetailModal: false });
	}

	closeSixtyMinuteModal = () => {
		this.setState({
			sendSixtyMinuteConfirm: false,
		});
	}
	closeFollowingModal = () => {
		this.setState({
			followingModal: false,
		});
	}
	openArchiveModal = (offering, archiveStatus) => {
		this.setState({
			archiveConfirmshow: true,
			offeringInfo: offering,
			isArchive: archiveStatus,
		});
	}
	selectApproxDate = (event) => {
		this.setState({
			max_date_show: event,
		});
	}

	selectApproxDateBefore = (event) => {
		this.setState({
			max_date_show_second: event,
		});
	}

	allocationEmailOffering = (id) => {
		this.props.allocationEmailOfferings(id);
	}
	collapseOffering = (item) => {
		this.setState({
			isLoad: true,
		});
		if (item.isOpen === true) {
			item.isOpen = false;
		} else {
			item.isOpen = true;
		}
		setTimeout(() => {
			this.setState({
				isLoad: false,
			});
		}, 500);
	}
	
	collapseReconfirm = (item, index) => {
		if (this.state.offeringIndex === index) {
			this.setState({
				isLoad: true,
				offeringIndex: null,
			});
		} else {
			this.props.getCurrentDateTime();
			// let clientDate = new Date('2022-03-23 09:14:58.765686');
			this.setState({
				isLoad: true,
				// max_date_show: clientDate,
				// max_date_show_second: clientDate,
				filterBy: '',
				offeringIndex: index,
				reconfirmExtId: item.ext_id,
			});
		}
		this.setState({
			reconfirmExtId: item.ext_id,
		}, () => { });
		setTimeout(() => {
			this.setState({
				isLoad: false,
			});
		}, 500);
	}
	
	updateProspectus = (offeringInfo) => {
		if(offeringInfo.prospectus_url.indexOf(' ') >= 0) {
			Swal.fire({
				title: 'Space is not allowed please retry again',
				icon: 'error',
				confirmButtonText: 'OK',
				allowOutsideClick: false,
				timer: 5000,
				heightAuto: false
			});
		} else {
			var patternPro = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/
			if(!patternPro.test(offeringInfo.prospectus_url)){
				Swal.fire({
					title: 'Please enter valid prospectus url',
					icon: 'error',
					confirmButtonText: 'OK',
					allowOutsideClick: false,
					timer: 5000,
					heightAuto: false
				});
			} else {
				offeringInfo.isOpen = false;
				let request = {
					ext_id: offeringInfo.ext_id,
					i_or_f: 'i',
					prospectus: offeringInfo.prospectus_url,
				}
				this.props.updateProspectusOffering(request);
			}
		}
	}
	
	handleProspectusChange = (e, item) => {
		this.setState({
			isLoad: true,
		});
		item.prospectus_url = e.target.value.trim();
		setTimeout(() => {
			this.setState({
				isLoad: false,
			});
		}, 100);
	}

	updateRequestReconfirm = () => {
		this.setState({
			isSubmit: true,
		});
		let dateShow = this.state.max_date_show;
		let maxDateShow = dateShow.getFullYear() + ('00' + (dateShow.getMonth() + 1)).slice(-2) + ('00' + dateShow.getDate()).slice(-2) + ('00' + dateShow.getHours()).slice(-2) + ('00' + dateShow.getMinutes()).slice(-2);

		let dateSecond = this.state.max_date_show_second;
		let maxDateShowSecond = dateSecond.getFullYear() + ('00' + (dateSecond.getMonth() + 1)).slice(-2) + ('00' + dateSecond.getDate()).slice(-2) + ('00' + dateSecond.getHours()).slice(-2) + ('00' + dateSecond.getMinutes()).slice(-2);

		const data = {
			ext_id: this.state.reconfirmExtId,
			auto_cancellation_dt: maxDateShow,
			orders_prior_to_dt: maxDateShowSecond,
			message_type: this.state.filterBy,
		};
		const errors = this.validate(this.state);
		if (Object.keys(errors).length === 0) {
			this.props.offeringReconfirmRequest(data);
		}
	}
	
	dropDownSearchBy = (event) => {
		this.setState({
			filterBy: event.target.value,
		}, () => { });
	}

	doSendSixtyMinuteEmail = (offeringInfo) => {
		this.props.sendSixtyMinuteEmail(offeringInfo.ext_id);
	}

	handleValueChange = (e) => {
		this.setState({
			sendType: e.target.value,
		});
	}

	handleInputTitle = (e) => {
		this.setState({
			title: e.target.value,
		});
	}
	handleInputMessage = (e) => {
		this.setState({
			message: e.target.value,
		});
	}
	
	sendNotification = () => {
		if (this.state.email === 1 && this.state.push === 0 && this.state.callback === 0) {
			let showType17 = '"Email"';
			this.setState({
				showNotifyType: showType17,
			});
		} else if (this.state.email === 1 && this.state.push === 1 && this.state.callback === 0) {
			let showType16 = '"Email", "Push Notification"';
			this.setState({
				showNotifyType: showType16,
			});
		} else if (this.state.email === 1 && this.state.push === 1 && this.state.callback === 1) {
			let showType15 = '"Email", "Push Notification", "Call Back"';
			this.setState({
				showNotifyType: showType15,
			});
		} else if (this.state.email === 0 && this.state.push === 1 && this.state.callback === 1) {
			let showType14 = '"Push Notification", "Call Back"';
			this.setState({
				showNotifyType: showType14,
			});
		} else if (this.state.email === 0 && this.state.push === 1 && this.state.callback === 0) {
			let showType13 = '"Push Notification"';
			this.setState({
				showNotifyType: showType13,
			});
		} else if (this.state.email === 0 && this.state.push === 0 && this.state.callback === 1) {
			let showType12 = '"Call Back"';
			this.setState({
				showNotifyType: showType12,
			});
		} else if (this.state.email === 1 && this.state.push === 0 && this.state.callback === 1) {
			let showType11 = '"Email", "Call Back"';
			this.setState({
				showNotifyType: showType11,
			});
		} else {

		}

		if (this.state.all === 1 && this.state.followers === 0 && this.state.orders === 0 && this.state.biz_users === 0 && this.state.connectedCustomers === 0) {
			let showType1 = '"All"';
			this.setState({
				showNotifySecondType: showType1,
			});
		} else if (this.state.all === 0 && this.state.followers === 1 && this.state.orders === 0 && this.state.biz_users === 0 && this.state.connectedCustomers === 0) {
			let showType2 = '"Followers"';
			this.setState({
				showNotifySecondType: showType2,
			});
		} else if (this.state.all === 0 && this.state.followers === 0 && this.state.orders === 1 && this.state.biz_users === 0 && this.state.connectedCustomers === 0) {
			let showType3 = '"Orders"';
			this.setState({
				showNotifySecondType: showType3,
			});
		} else if (this.state.all === 0 && this.state.followers === 0 && this.state.orders === 0 && this.state.biz_users === 1 && this.state.connectedCustomers === 0) {
			let showType4 = '"BizInsights"';
			this.setState({
				showNotifySecondType: showType4,
			});
		} else if (this.state.all === 0 && this.state.followers === 1 && this.state.orders === 0 && this.state.biz_users === 1 && this.state.connectedCustomers === 0) {
			let showType5 = '"Followers", "BizInsights"';
			this.setState({
				showNotifySecondType: showType5,
			});
		} else if (this.state.all === 0 && this.state.followers === 0 && this.state.orders === 1 && this.state.biz_users === 1 && this.state.connectedCustomers === 0) {
			let showType6 = '"Orders", "BizInsights"';
			this.setState({
				showNotifySecondType: showType6,
			});
		} else if (this.state.all === 0 && this.state.followers === 0 && this.state.orders === 0 && this.state.biz_users === 0 && this.state.connectedCustomers === 1) {
			let showType88 = '"connectedCustomers"';
			this.setState({
				showNotifySecondType: showType88,
			});
		} else {

		}

		if (this.state.verb === 'offering_create') {
			let showType7 = '"Create"';
			this.setState({
				showNotifyThirdType: showType7,
			});
		} else if (this.state.verb === 'offering_update') {
			let showType8 = '"Update"';
			this.setState({
				showNotifyThirdType: showType8,
			});
		} else {

		}

		this.setState({
			submitNotifyFormModal: true,
		});
	}
	
	notfyFormHandleChange = () => {
		const sendRequest = {
			ext_id: this.state.offeringId,
			email: this.state.email,
			push: this.state.push,
			callback: this.state.callback,
			all: this.state.all,
			followers: this.state.followers,
			connected_customers: this.state.connectedCustomers,
			orders: this.state.orders,
			biz_users: this.state.biz_users,
			verb: this.state.verb,
			subject: this.state.title,
			body: this.state.message,
			attachments: this.state.notifyAttachment,
		}

		this.props.sendNotifyUser(sendRequest);
	}
	handleChange = (e, index, listIndex) => {
		offeringViewList[listIndex].underwriters_list[index].display = e.target.checked;
		const data = {
			ext_id: offeringViewList[listIndex].ext_id,
			executing_broker_mpid: offeringViewList[listIndex].underwriters_list[index].executing_broker_mpid,
			display: e.target.checked,
		};
		this.props.setUnderwriter(data);

	}
	openEditDspModal = (data) => {
		this.setState({
			openDspUserEditModal: true,
			editDspData: data,
			dspFirstName: data.first_name,
			dspLastName: data.last_name,
			dspEmail: data.email,
			dspMaxShares: data.max_shares,
		});
	}
	
	closeEditDspModal = () => {
		this.setState({
			openDspUserEditModal: false,
		});
	}

	openConfirmDeleteDspUserModal = (data) => {
		this.setState({
			confirmDspUserDeleteModal: true,
			dspRowData: data,
		});
	}

	closeConfirmDeleteDspUserModal = () => {
		this.setState({
			confirmDspUserDeleteModal: false,
		});
	}

	submitDeleteDspUser = () => {
		let payloadReq = {
			'email': this.state.dspRowData.email,
		}
		this.props.doDeleteDspUser(this.state.offeringId, payloadReq);
		this.setState({
			confirmDspUserDeleteModal: false,
		});
	}
	uploadDspCSV = (e) => {
		this.setState({
			file: e.target.files,
		}, () => {
			this.props.doUploadDspUsers(this.state.offeringId, this.state.file);
		});
	}
	submitDspUpdateForm = () => {
		let request = {
			'first_name': this.state.dspFirstName,
			'last_name': this.state.dspLastName,
			'email': this.state.dspEmail,
			'max_shares': this.state.dspMaxShares,
		}
		this.props.doUpdateDspUser(this.state.offeringId, request);
		this.setState({
			openDspUserEditModal: false,
		});
	}
	changeDspValue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		}, () => { })
	}
	deleteAction = (rowData) => {
		return (<div>
		  <button style={{ width: 'auto' }} className="btn btn-reset-paswordbtn" onClick={() => this.openEditDspModal(rowData)}><i className="glyphicon glyphicon-pencil" ></i></button>
		  <button className="btn btn-delete" onClick={() => this.openConfirmDeleteDspUserModal(rowData)}> <i className="glyphicon glyphicon-trash" ></i></button>
		</div>);
	}
	onFileChange = (e) => {
		this.setState({
			notifyAttachment: e.target.files,
		});
	}
	archiveUnarchiveOffering = (status) => {
		let sendData = {
			ext_id: this.state.offeringInfo.ext_id,
			status: status
		}
		this.props.archiveOffering(sendData);
	}
	handleContainerOnBottom = () => {
        this.props.fetchOfferingData()
    };

	timeStringToFloat = (time) => {
		var timeParts = time.split(":");
		return ((+timeParts[0] * 3600) + (+timeParts[1] * 60)) * 1000;
	}

	goCallBacks = (data) => {
		this.props.history.push({
			pathname: `/offering-callbacks/${data.mpid}`,
			state: {
				offering: data
			}
		})
	}
	collapseCreatWarrant = (data, index) => {
		if (data.status === 'active') {

			for (let item of offeringViewList) {
				if (item.ext_id !== data.ext_id) {
					item.openWarrant = false;
				}
			}
			if (data.openWarrant !== true) {
				data.openWarrant = true;
				if (data.warrant_list && data.warrant_list.length > 0) {
					for (let warrant of data.warrant_list) {
						warrant.old_ticker_symbol = warrant.ticker_symbol;
						warrant.errors =  {
							ticker_symbol: null,
							//cusip_id: null,
							underwriter_concession_amount: null
						}
					}
					this.setState({
						warrantList: data.warrant_list,
						wListOld: JSON.stringify(data.warrant_list)
					}, () => {
						this.setState({
							renderUI: !this.state.renderUI
						});
					});
				} else {
					this.setState({
						warrantList: [{ 
							ticker_symbol: '', 
							warrant_price: '', 
							warrant_factor: '', 
							cusip_id: '', 
							underwriter_concession_amount: '0.00', 
							dsp: 0, 
							errors: {
								ticker_symbol: null, 
								warrant_price: null, 
								warrant_factor: null, 
								cusip_id: null, 
								underwriter_concession_amount: null
							}
						}]
					}, () => { });
				}
			} else if (data.openWarrant === true) {
				data.openWarrant = false;
				this.setState({
					warrantList: []
				}, () => { });
			}
		} else {
			for (let item of offeringViewList) {
				if (item.ext_id !== data.ext_id) {
					item.openWarrant = false;
					this.setState({
						renderUI: !this.state.renderUI
					});
				}
			}
			if (data.openWarrant !== true) {
				data.openWarrant = true;
				this.setState({
					renderUI: !this.state.renderUI
				});
			} else if (data.openWarrant === true) {
				data.openWarrant = false;
				this.setState({
					renderUI: !this.state.renderUI
				});
			}
		}
		// offeringViewList = offerings;
		this.setState({
			offeringDetail: data
		});
	}
	addFormFields() {
        this.setState({
            warrantList: [...this.state.warrantList, { 
                ticker_symbol: '', 
                warrant_price: '', 
                warrant_factor: '', 
                cusip_id: '', 
                underwriter_concession_amount: '0.00', 
                dsp: 0, 
                errors: {
                    ticker_symbol: null, 
                    warrant_price: null, 
                    warrant_factor: null, 
                    cusip_id: null, 
                    underwriter_concession_amount: null
                }
            }]
        }, () => {
            this.setState({uiRender: !this.state.uiRender});
        });
    }
    handleWarrantChange = (e, i) => {
        let warrantList = this.state.warrantList;
        if (e.target.name === 'dsp') {
            warrantList[i][e.target.name] = e.target.checked === true ? 1 : 0;
            this.setState({ warrantList }, () => {
                this.setState({uiRender: !this.state.uiRender});
            });
        } else {
            warrantList[i][e.target.name] = e.target.value;
            warrantList[i].errors[e.target.name] = e.target.value.length > 0 ? null : '';
            this.setState({ warrantList }, () => {
                this.setState({uiRender: !this.state.uiRender});
            });
        }
    }
    handleChangeWarrantPrice = (e, i) => {
        let warrantList = this.state.warrantList;
        const reg = /^\s*\d*\.?\d{0,4}\s*$/;
		if (reg.test(e.target.value)) {
			warrantList[i][e.target.name] = e.target.value;
            warrantList[i].errors[e.target.name] = e.target.value.length > 0 ? null : '';
            this.setState({ warrantList }, () => {
                this.setState({uiRender: !this.state.uiRender});
            });
		}
    }
    handleChangeWarrantUCA = (e, i) => {
        let warrantList = this.state.warrantList;
        const re = /^\s*\d*\.?\d{0,4}\s*$/;
		if (re.test(e.target.value)) {
			warrantList[i][e.target.name] = e.target.value;
            warrantList[i].errors[e.target.name] = e.target.value.length > 0 ? null : '';
            this.setState({ warrantList }, () => {
                this.setState({uiRender: !this.state.uiRender});
            });
		}
    }
    removeFormFields(i) {
        let warrantList = this.state.warrantList;
        warrantList.splice(i, 1);
        this.setState({ warrantList }, () => {
            this.setState({uiRender: !this.state.uiRender});
        });
    }
    prevIsValid = () => {
        if (this.state.warrantList.length === 0) {
            return true;
        }

        const someEmpty = this.state.offeringDetail?.cusip_id !== '' ? this.state.warrantList.some(item => item.ticker_symbol === '' || item.warrant_factor === '' || item.underwriter_concession_amount === '' || item.cusip_id === this.state.offeringDetail?.cusip_id || item.ticker_symbol === this.state.offeringDetail?.ticker_symbol) : this.state.warrantList.some(item => item.ticker_symbol === '' || item.warrant_factor === '' || item.underwriter_concession_amount === '' || item.ticker_symbol === this.state.offeringDetail?.ticker_symbol);
        if (someEmpty) {
            this.state.warrantList.forEach((item, index) => {
                const allPrev = [...this.state.warrantList];
                // if (this.state.warrantList[index].warrant_price === '') {
                //     allPrev[index].errors.warrant_price = "Please enter warrant price";
                // }
                // if (this.state.warrantList[index].warrant_factor === '') {
                //     allPrev[index].errors.warrant_factor = "Please enter warrant factor";
                // }
				if (this.state.offeringDetail?.cusip_id !== '') {
					if (this.state.warrantList[index].cusip_id === this.state.offeringDetail?.cusip_id) {
						allPrev[index].errors.cusip_id = "Offering cusip id and warrant cusip id both are same";
					}
				}
                
                if (this.state.warrantList[index].ticker_symbol === '') {
                    allPrev[index].errors.ticker_symbol = "Please enter ticker symbol";
                }
				if (this.state.warrantList[index].ticker_symbol === this.state.offeringDetail?.ticker_symbol) {
                    allPrev[index].errors.ticker_symbol = "Offering ticker symbol and warrant ticker symbol both are same";
                }
                if (this.state.warrantList[index].underwriter_concession_amount === '') {
                    allPrev[index].errors.underwriter_concession_amount = "Please enter underwriter concession amount";
                }
                
                this.setState({
                    warrantList: allPrev,
                })
            })
            
        }
        return !someEmpty;
    }
	createNewWarrant = (item) => {
		if (this.prevIsValid()) {
			let warrantListNew = [];
			if (this.state.warrantList.length > 0) {
				let updatedWarrantList = this.state.warrantList;
				for (let warrant of updatedWarrantList) {
					let newWarrant = {
						ticker_symbol: warrant.ticker_symbol, 
						warrant_price: Number(warrant.warrant_price), 
						warrant_factor: Number(warrant.warrant_factor), 
						cusip_id: warrant.cusip_id, 
						underwriter_concession_amount: Number(warrant.underwriter_concession_amount), 
						dsp: warrant.dsp
					}
					warrantListNew.push(newWarrant);
				}
			}
			const data = {
				warrant_list: warrantListNew,
				ext_id: item.ext_id
			};
			this.props.createWarrant(data);
		}
	}
	UpdateWarrant = (item) => {
		if (this.prevIsValid()) {


			let warrantListNew = [];
			if (this.state.warrantList.length > 0) {
				let updatedWarrantList = this.state.warrantList;
				let oldWarrant = JSON.parse(this.state.wListOld);
				updatedWarrantList.forEach(element => {
					element.warrant_price = Number(element.warrant_price); 
					element.warrant_factor = Number(element.warrant_factor);
					element.underwriter_concession_amount = Number(element.underwriter_concession_amount);
				});
				if (oldWarrant) {
					oldWarrant.forEach(ele => {
						ele.warrant_price = Number(ele.warrant_price); 
						ele.warrant_factor = Number(ele.warrant_factor);
						ele.underwriter_concession_amount = Number(ele.underwriter_concession_amount);
					});
				}
				
				for(let i=0; i < updatedWarrantList.length; i++) {
					if (JSON.stringify(updatedWarrantList[i]) === JSON.stringify(oldWarrant[i])) {
					  updatedWarrantList[i].update = 0
					} else {
					  updatedWarrantList[i].update = 1
					}
				}
				for (let warrant of updatedWarrantList) {
					let newWarrant = {
						ticker_symbol: warrant.ticker_symbol,
						old_ticker_symbol: warrant.old_ticker_symbol,
						warrant_price: Number(warrant.warrant_price), 
						warrant_factor: Number(warrant.warrant_factor), 
						cusip_id: warrant.cusip_id, 
						underwriter_concession_amount: Number(warrant.underwriter_concession_amount), 
						dsp: warrant.dsp,
						update: warrant.update
					}
					warrantListNew.push(newWarrant);
				}
			}
			const data = {
				warrant_list: warrantListNew,
				ext_id: item.ext_id
			};
			this.props.updateWarrant(data);
		}
	}
	render() {
		const { offeringTableView, isLoadOffering, lastData, tabIndex } = this.props;
		const { isReadOnly } = this.state;
		offeringViewList = offeringTableView;
		const errors = this.validate(this.state);
		let disableSendBtn;
		const { dspUsersList } = this.state;
		if (this.state.nCheckbox.length === 0 || this.state.uCheckbox.length === 0 || this.state.verb === '' || 	this.state.verb === '' || this.state.title === '' || this.state.message === '') {
			disableSendBtn = false; 
		} else {
			disableSendBtn = true; 
		}
		const less = '<';
    	const greater = '>';
		return (
			<div className="offering_content_body">
				<div>
					
					<div>
						<BottomScrollListener onBottom={this.handleContainerOnBottom} debounce={200} offset={100}>
						{(scrollRef) => (
							<div ref={scrollRef} style={{ height: 'calc(100vh - 240px)', overflow: "auto" }}>
								{
									offeringViewList && offeringViewList.map((item, i) =>
									<Card variant="outlined" key={i} style={{ marginBottom: 20, 
										overflow: 'unset'
									}}>
										<CardContent>
												<Typography component="div" className="row offeringcontent" >
													<Typography component="div" className="col-md-4" style={{ padding: 0 }}>
														<Typography component="div" className="row" >
															<Typography component="div" className="col-md-3">
																{/* <img /> */}
																{item.logo_small !== null && <img onError={this.addDefaultSrc} style={{ paddingTop: 12, width: "100%" }} src={(item) ? item.logo_small : ''} alt="offering img" />} {item.logo_small === null &&
																<div className="icon_column">
																	<div className={item.offering_type_name === 'IPO' ? 'icon' : 'icon_2'}>{item.name.charAt(0)}</div>
																</div>}
															</Typography>
															<Typography component="div" className="col-md-9" style={{ padding: 0 }}>
																<div>
																	{ 
																		item.status === 'effective' ?
																		<Link to={{ pathname: `/update-offering/${item.ext_id}`, state: {item,tabIndex} }}>
																			<div className="offeringInfo">
																				<span className="offeringName">{item.name}</span>
																				<span> ({item.ticker_symbol}) </span>
																				<span style={{ color: (item.offering_type_name === 'IPO') ? '#8DC73F' : 'rgba(8,57,82,1)' }}>
																					({item.offering_type_name})
																				</span>
																			</div>
																		</Link>
																		:
																		<div className="offeringInfo">
																			<span className="offeringName">{item.name}</span>
																			<span> ({item.ticker_symbol}) </span>
																				<span style={{ color: (item.offering_type_name === 'IPO') ? '#8DC73F' : 'rgba(8,57,82,1)' }}>
																					({item.offering_type_name})
																				</span>
																		</div>
																	}
																	<div className="no_margin titles">Trade Date : <span className="sub_titles">{getDate(item)}</span></div>
																	<div>
																		{(item.offering_type_name === 'IPO') && <div className="no_margin titles">Price range : <span className="sub_titles" >{getOfferingPriceRange(item)} </span></div>}
																		{(item.offering_type_name !== 'IPO') && <div className="no_margin titles">Last Closing Price : <span className="sub_titles" >{getOfferingPriceRange(item)} </span></div>}
																	</div>
																		
																	{item.status === 'cancelled' &&
																	
																		<div className="no_margin titles">Final Price : <span className="sub_titles" >{numberWithCommasAndDollar(item.final_price)}</span></div>}
																		<div className="no_margin titles">Total Orders : <span className="sub_titles" >{item.total_orders_count}</span></div>
																		{item?.total_demand !== undefined &&
																		<div className="no_margin titles">Total Demand : <span className="sub_titles" >{numberWithCommasAndDollar(item?.total_demand || 0)} </span></div>}
																		{/* {item.total_followers_count !== undefined &&
																		<div className="no_margin titles">Total Followers : <span className="sub_titles" >{ item.total_followers_count } </span></div>} */}
																		{(item.blocked_bd_list && item.blocked_bd_list.length >0) &&
																		<div className="no_margin titles">Blocked Broker Dealer : 
																			{
																			item.blocked_bd_list.map((brokerdealer, k, arr) => (
																				<span className="sub_titles" key={k} style={{marginLeft: 10}}>{brokerdealer.mpid}{k !== (arr.length-1) ? ', ' : ''} </span>
																			))
																			}
																		</div>
																	}

																	{ 
																		item.bd_underwriter_mpid_id !== null && item.bd_underwriter_mpid_id !== undefined &&
																		<div className="offering_inline_checkbox" style={{ marginTop: 10, display: 'inline-flex', width: '100%' }}>
																			<div className="checkbox_list_single" >
																				<input type="checkbox" defaultChecked disabled id={item.bd_underwriter_mpid_id} />
																				<label htmlFor={item.bd_underwriter_mpid_id}>{item.bd_underwriter_mpid_id}</label>
																			</div>
																			<div style={{margin: 'auto'}}>
																				<Button variant="contained" component={Link} style={{lineHeight: '15px'}} className="offeringbuttons" to={{ pathname: `/offering-callbacks/${item.ext_id}`, state: { offering: item, tabIndex: tabIndex } }}>CallBacks</Button>
																			</div>
																		</div>
																	}

																	<div className="offering_inline_checkbox">
																		{Array.isArray(item.underwriters_list) ? (item.underwriters_list.length > 0 ? item.underwriters_list.map((underwriterlist, j) => (
																			<div key={j}>
																				{
																					(item.bd_underwriter_mpid_id !== underwriterlist.executing_broker_mpid) &&
																					<div className="checkbox_list" >
																					{
																						item.status === 'active' && !isReadOnly ?
																						<input type="checkbox" id={`test${i}${j}${underwriterlist.executing_broker_mpid}`} defaultChecked={underwriterlist.display} onClick={(e) => { this.handleChange(e, j, i); }} />
																						:
																						<input type="checkbox" id={`test${i}${j}${underwriterlist.executing_broker_mpid}`} defaultChecked={underwriterlist.display} disabled />
																					}
																						<label htmlFor={`test${i}${j}${underwriterlist.executing_broker_mpid}`}>{underwriterlist.executing_broker_mpid}</label>
																					
																					</div>
																				}
																			</div>
																		)) : 'No underwriters available') : 'Failed to show underwriters'}
																	</div>
																</div>
															</Typography>
														</Typography>
													</Typography>
													<Typography component="div" className="col-md-8" >
														<Typography component="div" className="row" >
															<Typography component="div" className="col-md-4">
																{((item.status === 'active') || (item.status === 'upcoming')) && (!isReadOnly) &&
																	<div>
																	<Button variant="contained" fullWidth={true} onClick={() => this.openNotificationModal(item)} className="offeringbuttons" disabled={((this.state.loadingimg === item.ext_id) && (item.totalFollower === undefined))} style={{ marginTop: '16px' }}>Notify</Button>
																	{ ((this.state.loadingimg === item.ext_id) && (item.totalFollower === undefined)) && <img src={loadingImg} width="40" style={{ marginTop: '16px' }} alt="loading" />}

																	</div>
																}
																{
																	(!isReadOnly) &&
																	<div>
																		<Button variant="contained" fullWidth={true} onClick={() => this.openRatingModal(item)} className="offeringbuttons" disabled={(item.rating_count === 0)} style={{ marginTop: '16px' }}>Rating</Button>

																	</div>
																}
																{( item.dsp === 1 || item.dsp === 2) &&
																	<div>
																	<Button variant="contained" fullWidth={true} onClick={() => this.openDspModal(item)} className="offeringbuttons " style={{ marginTop: '16px' }}>DSP</Button>

																	</div>
																}
																{
																	item.time_remaining && item.time_remaining !== '00:00' &&
																	<div>
																	<Button id="remainTime" variant="contained" fullWidth={true} className="offeringbuttons text-danger" disabled style={{ marginTop: '16px' }}>
																		<Countdown date={Date.now() + this.timeStringToFloat(item.time_remaining)} renderer={renderer} /> 
																		
																		</Button>
																	</div>
																}
																<div>
																	{
																		(!isReadOnly) ? 
																		<div>
																			{
																				item.status === 'active' ? 
																				<div>
																					<Button variant="contained" fullWidth={true} className="offeringbuttons" onClick={() => { this.collapseCreatWarrant(item, i); }} style={{ marginTop: '16px' }}> { (item.warrant_list && item.warrant_list.length > 0) ?	"Update Warrant" : "Create Warrant" }</Button>
																				</div>
																				:
																				<Button variant="contained" fullWidth={true} className="offeringbuttons" style={{ marginTop: '16px' }} onClick={() => { this.collapseCreatWarrant(item, i); }} disabled={item.warrant_list.length > 0 ? false : true}> List Warrant</Button>
																			}
																			
																		</div>
																		:
																		<Button variant="contained" fullWidth={true} className="offeringbuttons" style={{ marginTop: '16px' }} 
																		disabled={item.warrant_list.length > 0 ? false : true}
																		onClick={() => { this.collapseCreatWarrant(item, i); }}> List Warrant</Button>
																	}
																</div>
															</Typography>
															<Typography component="div" className="col-md-4">
																<div className="gridButtons text_center">
																	<div>
																	{
																		item.total_orders_count === 0 &&
																		<Button variant="contained" fullWidth={true} className="offeringbuttons" disabled style={{ marginTop: '16px' }}> Orders</Button>
																	}
																	{
																		item.total_orders_count !== 0 &&
																		<Button variant="contained" fullWidth={true} component={Link} className="offeringbuttons" to={{ pathname: `/orders/${item.ext_id}`, state: { offeringData: item, tabIndex: tabIndex } }} style={{ marginTop: '16px' }}> Orders</Button>
																	}

																	</div>
																	{item.status === 'closed' &&
																	<div>
																	{/* <button className="btn btn-primary" onClick={()=>{this.openArchiveModal(item,'unarchive')}} >Unarchive </button> */}
																	</div>} {((item.status === 'active') || (item.status === 'upcoming')) && (!isReadOnly) &&
																	<div>
																	<Button variant="contained" className="offeringbuttons" fullWidth={true} style={{ marginTop: '16px' }} onClick={() => { this.collapseOffering(item); }}>Update Prospectus</Button>
																	</div> } {((item.status === 'active') || (item.status === 'upcoming')) && (item.offering_type_name !== 'Overnight') &&
																	<div>
																	{
																		(item.total_orders_count === 0) && (!isReadOnly) &&
																		<Button variant="contained" className="offeringbuttons" fullWidth={true} disabled style={{ marginTop: '16px' }}>60 min email</Button>
																	}
																	{
																		(item.total_orders_count !== 0) && (!isReadOnly) && 
																		<Button variant="contained" className="offeringbuttons" fullWidth={true} style={{ marginTop: '16px' }} onClick={() => this.openSixtyMinuteModal(item)}>60 min email</Button>
																	}
																	</div>}

																	{((item.status === 'cancelled')) && <div>
																		{
																			 (!isReadOnly) &&
																			<Button variant="contained" className="offeringbuttons" fullWidth={true} style={{ marginTop: '16px' }} onClick={() => this.makeCurrentCall(item)}>Make Current</Button>
																		}
																	{/* <Link to={{ pathname: `/update-offering/${item.ext_id}`, state: item }}>
																		<Button className="btn btn-primary" >View Details</Button>
																	</Link> */}
																	<Button variant="contained" fullWidth={true} component={Link} className="offeringbuttons" to={{ pathname: `/update-offering/${item.ext_id}`, state: {item,tabIndex} }} style={{ marginTop: '16px' }}> View Details</Button>
																	</div>}
																	{(item.status === 'closed') && <div>
																	<Button variant="contained" fullWidth={true} component={Link} className="offeringbuttons" to={{ pathname: `/update-offering/${item.ext_id}`, state: {item,tabIndex} }} style={{ marginTop: '16px' }}> View Details</Button>
																	</div>}
																</div>
															</Typography>
															<Typography component="div" className="col-md-4">
																{((item.status === 'active') || (item.status === 'upcoming')) && (!isReadOnly) &&
																	<div className="gridButtons">
																		<Button variant="contained" fullWidth={true} component={Link} className="offeringbuttons" to={{ pathname: `/update-offering/${item.ext_id}`, state: {item,tabIndex} }} style={{ marginTop: '16px' }}> Edit Offering</Button>
																		<Button variant="contained" style={{ marginTop: '16px' }} color="secondary" fullWidth={true} onClick={() => { this.openArchiveModal(item, 'archive'); }} >Archive</Button>
																	</div>
																} 
																{ ((item.status === 'active') || (item.status === 'upcoming')) && (item.offering_type_name !== 'Overnight') && (!isReadOnly) &&
																<div className="gridButtons ">
																	{
																	item.total_orders_count === 0 &&
																	<Button variant="contained" style={{ marginTop: '16px' }} className="offeringbuttons" fullWidth={true} disabled >Reconfirm Request</Button>
																	}
																	{
																	item.total_orders_count !== 0 &&
																	<Button variant="contained" style={{ marginTop: '16px' }} className="offeringbuttons" fullWidth={true} onClick={() => { this.collapseReconfirm(item, i); }} >Reconfirm Request</Button>
																	}
																</div>}
															</Typography>
														</Typography>
													</Typography>
												</Typography>
												{item.isOpen &&
												<div className="offeringcollapse">
													<p className="header">Edit Prospectus</p>
													<div>
														<TextField type="text" value={item.prospectus_url} onChange={(event) => { this.handleProspectusChange(event, item); }} style={{width: '100%'}} />
													</div>
													<div style={{ marginTop: 20 }}>
														<Button variant="contained" color="primary" onClick={() => { this.updateProspectus(item); }} >Update</Button>
													</div>
												</div>}
												{this.state.offeringIndex === i &&
													<div className="offeringcollapse">
														<p className="header">Request Order Reconfirmation</p>
														<div className="row">
															<div className="col-md-8">
																<p className="header">Select Reason for change Order </p>
															</div>
															<div className="col-md-4">
																<div className="requestReconfirm">
																	<select style={{ width: '100%', height: 34 }} value={this.state.filterBy} onChange={this.dropDownSearchBy}>
																		<option value="">Select</option>
																		<option value="1">Old Orders </option>
																		<option value="2">Material change </option>
																		<option value="3"> Offering is being priced by 20% outside of original range</option>
																	</select>
																</div>
																{this.state.isSubmit && errors && <span style={{ color: 'red', fontSize: 14 }}>{errors.dropdownErr}</span>}
															</div>
														</div>
														<div className="row" style={{ marginTop: '13px' }}>
															<div className="col-md-8">
																<p className="header">Enter the time that the orders will be cancelled in EST : </p>
															</div>
															<div className="col-md-4">
																{/* <Calendar minDate={this.state.minDate} value={this.state.max_date_show} onChange={(e) => this.selectApproxDate(e)} showTime showSeconds={false} hourFormat="12" utc={true} />  */}
																<DatePicker
																	selected={this.state.max_date_show}
																	onChange={(e) => this.selectApproxDate(e)}
																	showTimeInput
																	minDate={this.state.minDate}
																	dateFormat="MM/dd/yyyy h:mm aa"
																	style={{width: '100%'}}
																/>
																{this.state.isSubmit && errors && <span style={{ color: 'red', fontSize: 14, width: '100%' }}>{errors.max_date}</span>}
															</div>
														</div>
														<div className="row" style={{ marginTop: '13px' }}>
															<div className="col-md-8">
																<p className="header">For Orders entered before ( EST ) </p>
															</div>
															<div className="col-md-4">
																{/* <Calendar maxDate={this.state.maxDate} value={this.state.max_date_show_second} onChange={(e) => this.selectApproxDateBefore(e)} showTime showSeconds={false} hourFormat="12" />  */}
																<DatePicker
																	selected={this.state.max_date_show_second}
																	onChange={(e) => this.selectApproxDateBefore(e)}
																	maxDate={this.state.maxDate}
																	showTimeInput
																	dateFormat="MM/dd/yyyy h:mm aa"
																	style={{width: '100%'}}
																/>
																{this.state.isSubmit && errors && <span style={{ color: 'red', fontSize: 14, width: '100%' }}>{errors.max_date_second}</span>}
															</div>
														</div>
														<div>
															<Button variant="contained" color="primary" onClick={() => { this.updateRequestReconfirm(item); }} >Request</Button>

														</div>
													</div>}
											{
												item.openWarrant &&
													<div className="offeringcollapse" >
														{
															(item.status === 'active') && 
															<div>
																<p className="header" style={{ fontWeight: '700', fontSize: 20, marginBottom: 20, marginTop: 20 }}>{ (item.warrant_list && item.warrant_list.length > 0) ?	"Update Warrant" : "Create Warrant" }</p>
																
																{
																	this.state.warrantList && this.state.warrantList.map((op, ind) => 
																	<div className="col-md-12 col-sm-12" style={{paddingTop: 16, paddingBottom: 16, borderBottom: '1px solid #ddd'}} key={ind}>
																		<div className="row createwarrant_box">
																			<div className="col-md-4 mt-3">
																				<label className="update-label" >Warrant Price</label>
																				<TextField type="text" fullWidth name="warrant_price" value={op.warrant_price !== undefined ? op.warrant_price : ''} onChange={(e) => { this.handleChangeWarrantPrice(e, ind); }} />
																			</div>
																			<div className="col-md-4 mt-3">
																				<label className="update-label">Shares to warrant conversion factor

																					<Tooltip title={<div style={{ fontSize: 12 }}>2 shares to 1 warrant : warrant factor = 0.5<br />1 share to 1 warrant : warrant factor = 1</div>} placement="bottom" arrow>
																						<InfoIcon style={{ fontSize: 16, cursor: 'pointer' }} />
																					</Tooltip>
																				</label>
																				<TextField type="text" fullWidth name="warrant_factor" value={op.warrant_factor !== undefined ? op.warrant_factor : ''} onChange={(e) => { this.handleChangeWarrantPrice(e, ind); }} />
																			</div>
																			<div className="col-md-4 mt-3">
																				<label className="update-label">CUSIP ID</label>
																				<TextField type="text" fullWidth name="cusip_id" value={op.cusip_id !== undefined ? op.cusip_id : ''} onChange={(e) => { this.handleWarrantChange(e, ind); }} />
																				{
																					op.errors.cusip_id &&
																					<span style={{ color: 'red', fontSize: 14 }}>{op.errors.cusip_id}</span>
																				}
																			</div>
																			<div className="col-md-4 mt-3">
																				<label className="update-label">Ticker Symbol</label>
																				<TextField type="text" fullWidth name="ticker_symbol" value={op.ticker_symbol} onChange={(e) => { this.handleWarrantChange(e, ind); }} />
																				{
																					op.errors.ticker_symbol &&
																					<span style={{ color: 'red', fontSize: 14 }}>{op.errors.ticker_symbol}</span>
																				}
																			</div>
																			<div className="col-md-4 mt-3">
																				<label className="update-label">Underwriter Concession Amount</label>
																				<TextField type="text" fullWidth name="underwriter_concession_amount" value={op.underwriter_concession_amount !== undefined ? op.underwriter_concession_amount : '0.00'} onChange={(e) => { this.handleChangeWarrantUCA(e, ind); }} />
																				{
																					op.errors.underwriter_concession_amount &&
																					<span style={{ color: 'red', fontSize: 14 }}>{op.errors.underwriter_concession_amount}</span>
																				}
																			</div>
																			<div className="col-md-3 mt-5">
																				<div className="warrent-dsp-checkbox">
																					<FormControlLabel
																						control={
																							<Checkbox
																								checked={(op.dsp === true || op.dsp === 1) ? true : false}
																								onChange={(e) => { this.handleWarrantChange(e, ind); }}
																								name="dsp"
																								color="primary"
																							/>
																						}
																						label="Include DSP"
																					/>
																				</div>
																			</div>
																			<div className="col-md-1 mt-5">
																				<div>
																					{
																						ind ?
																							<Button variant="contained" color="secondary" onClick={() => this.removeFormFields(ind)}><DeleteIcon /></Button>
																							: null
																					}
																				</div>
																			</div>
																		</div>
																	</div>
																	)
																}
																{/* {
																	(item.warrant_list && item.warrant_list.length > 0) && (!this.state.wListOld) &&
																	<div className="col-md-12 text-center mt-3">
																		<Button variant="contained" className="offeringbuttons"  onClick={()=> this.addFormFields()}>Add more warrant</Button>
																	</div>
																} */}
																<div className="col-md-12" style={{ textAlign: 'center', marginTop: 16, marginBottom: 16 }}>
																	{
																		(item.warrant_list && item.warrant_list.length > 0) ?
																		<Button variant="contained" color="primary" onClick={() => { this.UpdateWarrant(item) }} >Update Warrant</Button>
																		:
																		<Button variant="contained" color="primary" onClick={() => { this.createNewWarrant(item) }} >Submit</Button>
																		
																	}
																</div>
															</div>
														}
														{
															(item.status !== 'active') && 
																<div>
																	<p className="header" style={{ fontWeight: '700', fontSize: 20, marginBottom: 20, marginTop: 20 }}>List Warrant</p>
																	<div className="col-md-12 col-sm-12" style={{paddingTop: 16, paddingBottom: 16, borderBottom: '1px solid #ddd'}}>
																		<GridTable
																			columns={this.state.warrantCols}
																			rows={item.warrant_list}
																			isPaginated={true}
																			isVirtualScroll={true}
																			showSearch={true}
																			showRowsInformation={false}
																			isHeaderSticky={true}
																		/>
																	</div>
																</div>
														}
													</div>
											}
										</CardContent>
									</Card>
									)
								}
								{
									lastData > 0 && isLoadOffering &&
									<div style={{textAlign: 'center'}}><img src={loaderImg} alt='Loader' style={{width: '60px'}} /></div> 
								}
								{
									offeringViewList && offeringViewList.length === 0 && (!isLoadOffering) &&
									<div className="nodatafound" style={{ paddingTop: '30px', paddingLeft: '20px' }}>
										No offering found!
									</div>
								}
							</div>
						)}
						</BottomScrollListener>
					</div>
					<Dialog
						onClose={this.closeNotificationModal}
						aria-labelledby="customized-dialog-title"
						open={this.state.notificationModal}
						maxWidth={"md"}
						fullWidth={true}
						scroll={'paper'}
						>
						<DialogTitle onClose={this.closeNotificationModal} className="offering-detail-title">Notify</DialogTitle>
						<DialogContent>
							<div className="col-md-12">
								<div className="row" style={{ padding: 40 }}>
									<div className="col-md-12 sendNotificationContainer">
										<div className="row" style={{ marginTop: 20 }}>
											<div className="col-md-4">
												<div>
													<input className="styled-checkbox" id="email" type="checkbox" defaultValue={this.state.selectEmail} defaultChecked={this.state.selectEmail} onChange={(e) => this.firstLayer(e, 'email')} />
													<label htmlFor="email">Email</label>
												</div>
											</div>
											<div className="col-md-4">
												<div>
													<input className="styled-checkbox" id="push" type="checkbox" defaultValue={this.state.selectPush} defaultChecked={this.state.selectPush} onChange={(e) => this.firstLayer(e, 'push')} />
													<label htmlFor="push">Push Notifications</label>
												</div>
											</div>
											<div className="col-md-4">
												<div>
													<input className="styled-checkbox" id="callback" type="checkbox" defaultValue={this.state.selectCallback} defaultChecked={this.state.selectCallback} onChange={(e) => this.firstLayer(e, 'callback')} />
													<label htmlFor="callback">Callback</label>
												</div>
											</div>
										</div>

										<div className="row" style={{ marginTop: 20 }}>
											<div className="col-md-2">
												<div>
													<input className="styled-checkbox" id="all" type="checkbox" onChange={(e) => this.secondLayer(e, 'all')} value={this.state.selectAll} checked={this.state.selectAll} />
													<label htmlFor="all">All</label>
												</div>
											</div>
											<div className="col-md-2">
												<div>
													<input className="styled-checkbox" id="followers" type="checkbox" onChange={(e) => this.secondLayer(e, 'followers')} value={this.state.selectFollowers} checked={this.state.selectFollowers} />
													<label htmlFor="followers">Followers</label>
												</div>
											</div>
											<div className="col-md-2">
												<div>
													<input className="styled-checkbox" id="orders" type="checkbox" onChange={(e) => this.secondLayer(e, 'orders')} value={this.state.selectOrders} checked={this.state.selectOrders} />
													<label htmlFor="orders">Orders</label>
												</div>
											</div>
											<div className="col-md-2">
												<div>
													<input className="styled-checkbox" id="bizinsight" type="checkbox" onChange={(e) => this.secondLayer(e, 'bizinsight')} value={this.state.selectBizUsers} checked={this.state.selectBizUsers} />
													<label htmlFor="bizinsight">BizInsight</label>
												</div>
											</div>
											<div className="col-md-4">
												<div>
													<input className="styled-checkbox" id="connectedCustomers" type="checkbox" onChange={(e) => this.secondLayer(e, 'connectedCustomers')} value={this.state.selectConnectedCustomers} checked={this.state.selectConnectedCustomers} />
													<label htmlFor="connectedCustomers">Connected Customers</label>
												</div>
											</div>
										</div>
										<div className="row" style={{ marginTop: 30 }}>
											<div className="col-md-6">
												<div className="radio-custom">
													<input id="create" name="radio" type="radio" onChange={(e) => this.onSelectVerb(e, 'create')} />
													<label htmlFor="create" className="radio-label">Create</label>
												</div>
											</div>
											<div className="col-md-6">
												<div className="radio-custom">
													<input id="update" name="radio" type="radio" onChange={(e) => this.onSelectVerb(e, 'update')} />
													<label htmlFor="update" className="radio-label">Update</label>
												</div>
											</div>
										</div>
										{/* <div>
										<select style={{ height: '40px' }} onChange={this.handleValueChange}>
											<option value="EMAIL">EMAIL Notification</option>
											<option value="PUSH">PUSH Notification</option>
										</select>
										</div> */}
										<div>
											<label className="update-label labelheader"> Subject </label>
											<TextField className="update-input" type="text" name="title" value={this.state.title} onChange={this.handleInputTitle} onMouseDown={e => e.stopPropagation()} style={{ width: '100%' }} />
										</div>
										<div>
											<label className="update-label labelheader" >Message </label>
											<TextField multiline={true} className="update-input notificationTextarea" name="message" value={this.state.message} onChange={this.handleInputMessage} onMouseDown={e => e.stopPropagation()} style={{ height: '100px', borderStyle: 'groove', borderColor: '#ccc', width: '100%' }} />
										</div>
										<div style={{ marginTop: '20px' }}>
											<input type="file" onChange={this.onFileChange} />
										</div>
										<button className="btn sendNotifyBtn" style={{ width: '100%' }} onClick={() => this.sendNotification()} disabled={!disableSendBtn}> Send </button>
									</div>
								</div>
								<div style={{ paddingLeft: 33 }} className="col-md-12">
									<p className="notification_offeringTitle_bold">Replacements in subject: </p>
									<p className="notificationModal_bottom_text">
										{less}%=@offering_name%{greater} - Name of the offering
										</p>
									<p className="notification_offeringTitle_bold">Replacements in body: </p>
									<p className="notificationModal_bottom_text"> {less}%=@customer_first_name%{greater} - Customer's First Name</p>
									<p className="notificationModal_bottom_text">{less}%=@customer_last_name%{greater} - Customer's Last Name</p>
									<p className="notificationModal_bottom_text">{less}%=@customer_name%{greater} - Customer's Full name</p>
									<p className="notificationModal_bottom_text">{less}%=@offering_name%{greater} - Name Of The Offering</p>
								</div>
							</div>
						</DialogContent>
					</Dialog>
					<Dialog
						onClose={this.closeSubmitNotify}
						aria-labelledby="customized-dialog-title"
						open={this.state.submitNotifyFormModal}
						maxWidth={"sm"}
						fullWidth={true}
						scroll={'paper'}
						>
						<DialogTitle onClose={this.closeSubmitNotify} >Notify Review</DialogTitle>
						<DialogContent>
							<div className="col-md-12 send-notify-confirm-modal">
								<div className="row" style={{ padding: 20 }}>
									<div className="col-md-12">
										<div className="row">
											<div className="col-md-12 send-notify-confirm-modal-title" style={{ marginBottom: 15 }}>
												You have selected the following
											</div>
										</div>
										<div className="row" style={{ marginBottom: 5 }}>
											<div className="col-md-12">
												{this.state.showNotifyType}
											</div>
										</div>
										<div className="row" style={{ marginBottom: 5 }}>
											<div className="col-md-12">
												{this.state.showNotifySecondType}
											</div>
										</div>
										<div className="row" style={{ marginBottom: 5 }}>
											<div className="col-md-12">
												{this.state.showNotifyThirdType}
											</div>
										</div>
										<div className="row" style={{ marginBottom: 5 }}>
											<div className="col-md-4 send-notify-confirm-modal-title">
												Subject : 
											</div>
											<div className="col-md-8">
												{this.state.title}
											</div>
										</div>
										<div className="row" style={{ marginBottom: 5 }}>
											<div className="col-md-4 send-notify-confirm-modal-title">
												Message :
											</div>
											<div className="col-md-8 send-notify-confirm-modal-message">
												{this.state.message}
											</div>
										</div>
									</div>
								</div>
							</div>
						</DialogContent>
						<DialogActions style={{display: 'block'}}>
							<div style={{textAlign: 'center'}}>
								<Button style={{marginLeft: 10, marginRight: 10, width: '110px'}} variant="contained" size="large" color="primary" onClick={() => this.notfyFormHandleChange()}>
									Ok
								</Button>
								<Button style={{marginLeft: 10, marginRight: 10, width: '110px'}} variant="contained" size="large" color="secondary" onClick={() => this.closeSubmitNotify()}>
									Cancel
								</Button>
							</div>
						</DialogActions>
					</Dialog>
					<Dialog
						onClose={this.closeDspModal}
						aria-labelledby="customized-dialog-title"
						open={this.state.dspModal}
						maxWidth={"md"}
						fullWidth={true}
						scroll={'paper'}
						>
						<DialogTitle onClose={this.closeDspModal} >DSP</DialogTitle>
						<DialogContent>
							<div className="col-md-12">
								<div className="dspmodalcontainer">
									<div className="row" style={{ marginTop: 20 }}>
										<div className="col-md-12">
											<div className="dsp-list-table" style={{ border: '1px solid #ddd' }}>
												<GridTable
													columns={this.state.cols}
													rows={dspUsersList}
													isPaginated={true}
													isVirtualScroll={true}
													showSearch={true}
													showRowsInformation={false}
													isHeaderSticky={true}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</DialogContent>
					</Dialog>
					<Dialog
						onClose={this.closeSixtyMinuteModal}
						aria-labelledby="customized-dialog-title"
						open={this.state.sendSixtyMinuteConfirm}
						maxWidth={"sm"}
						fullWidth={true}
						scroll={'paper'}
						>
						<DialogTitle onClose={this.closeSixtyMinuteModal} >Confirmation</DialogTitle>
						<DialogContent>
							<div className="col-md-12" style={{textAlign: 'center'}}>
								<h5> Do you want to send 60 minute email ?</h5>
							</div>
						</DialogContent>
						<DialogActions style={{display: 'block'}}>
							<div style={{textAlign: 'center'}}>
								<Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.doSendSixtyMinuteEmail(this.state.sixtyMinuteofferingInfo)}>
									Ok
								</Button>
								<Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="secondary" onClick={() => this.closeSixtyMinuteModal()}>
									Cancel
								</Button>
							</div>
						</DialogActions>
					</Dialog>
					<Dialog
						onClose={this.toggleModal}
						aria-labelledby="customized-dialog-title"
						open={this.state.archiveConfirmshow}
						maxWidth={"sm"}
						fullWidth={true}
						scroll={'paper'}
						>
						<DialogTitle onClose={this.toggleModal} >Confirmation</DialogTitle>
						<DialogContent>
							<div className="col-md-12" style={{textAlign: 'center'}}>
								<h5> Are you sure you want to {this.state.isArchive} {this.state.offeringInfo && this.state.offeringInfo.name ? this.state.offeringInfo.name: ''}</h5>
								<p>All the active orders will be cancelled and customers will receive a cancellation email</p>
							</div>
						</DialogContent>
						<DialogActions style={{display: 'block'}}>
							<div style={{textAlign: 'center'}}>
								{
									this.state.isArchive === 'archive' &&
									<Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.archiveUnarchiveOffering('archive')}>
										Archive
									</Button>
								}
								{
									this.state.isArchive === 'unarchive' &&
									<Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.archiveUnarchiveOffering('unarchive')}>
										Unarchive
									</Button>
								}
							</div>
						</DialogActions>
					</Dialog>
					
					<Dialog
						onClose={this.closeRatingModal}
						aria-labelledby="customized-dialog-title"
						open={this.state.ratingModal}
						maxWidth={"md"}
						fullWidth={true}
						scroll={'paper'}
						>
						<DialogTitle onClose={this.closeRatingModal} >Rating</DialogTitle>
						<DialogContent>
							<div className="col-md-12 comment-modal-style">
								<div className="row" style={{ marginTop: 20, marginLeft: 10, marginRight: 10 }}>
									<div className="col-md-12">
										<div style={{ display: 'inline-flex', fontSize: 16 }}>
										Average Rating : 
										
											<StarRatingComponent 
												name="rate2" 
												starCount={5}
												value={this.state.offeringAvgRating}
												editing={false}
												renderStarIcon={(index, value) => {
												return (
													<span>
														{
															index <= value ?
															<StarIcon />
															:
															<StarBorderIcon />
														}
													{/* <i className={index <= value ? 'fa fa-star' : 'fa fa-star-o'} /> */}
													</span>
												);
												}}
												style={{ marginLeft: 15 }}
											/>
										</div>
										<div style={{ fontSize: 16 }}>
										Rating Count : { this.state.offeringRatingCount }
										</div>
									</div>
									<div className="col-md-12 comment-modal-box">
										<div className="comment-modal-box-header">
											<div className="comment-title">Comments</div>
										</div>
										<div className="comment-modal-box-content">
											{
												(this.state.ratingList && this.state.ratingList.length > 0) && this.state.ratingList.map((op, i) =>
												<div className="comment-list" key={i}>
													<div className="comment-list-alies">{op.alias}</div>
													<div className="comment-list-rating">
														<StarRatingComponent 
															name="rate1" 
															starCount={5}
															value={op.rating}
															editing={false}
															renderStarIcon={(index, value) => {
															return (
																<span>
																	{
																		index <= value ?
																		<StarIcon />
																		:
																		<StarBorderIcon />
																	}
																{/* <i className={index <= value ? 'fa fa-star' : 'fa fa-star-o'} /> */}
																</span>
															);
															}}
														/>
														<span className="updated-date">{op.updated_at}</span>
													</div>
													<div className="comment-list-comment">{op.comment}</div>
												</div>
												)
											}
											{
												(this.state.showCommentLoader) &&
												<div className="comment-loading"><img src={SpinnImg} alt='loader' /></div>
											}
											{
												(this.state.ratingList) && !(this.state.showCommentLoader) && (this.state.ratingList.length === 0) &&
												<div style={{ marginTop: 20, fontSize: 20, textAlign: 'center' }}>No comments found</div>
											}
										
										</div>
									</div>
								</div>
							</div>
						</DialogContent>
					</Dialog>
				</div>
			</div>
		)
	}
}

OfferingListComponent.propTypes = {
	fetchOfferingData: PropTypes.func,
	offeringTableView: PropTypes.any,
	notifyUserRes: PropTypes.any,
	dspUsersListRes: PropTypes.any,
	sendSixtyMinuteEmailRes: PropTypes.any,
	archiveOfferingRes: PropTypes.any,
	unarchiveOfferingRes: PropTypes.any,
	updateProspectusRes: PropTypes.any,
	updateOfferingReconfirmRequestRes: PropTypes.any,
	ratingListRes: PropTypes.any,
	setUnderwriterRes: PropTypes.any,
	isLoadOffering: PropTypes.bool,
	lastData: PropTypes.any,
	archiveOffering: PropTypes.func,
	createWarrant: PropTypes.func,
	updateWarrant: PropTypes.func,
	makeCurrent: PropTypes.func,
	getCurrentDateTimeRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	notifyUserRes: doNotifyUserRes,
	dspUsersListRes: doDspUsersListRes,
	sendSixtyMinuteEmailRes: doSendSixtyMinuteEmailRes,
	archiveOfferingRes: doArchiveOfferingRes,
	unarchiveOfferingRes: doUnarchiveOfferingRes,
	updateProspectusRes: doUpdateProspectusRes,
	updateOfferingReconfirmRequestRes: doUpdateOfferingReconfirmRequestRes,
	ratingListRes: doRatingListRes,
	setUnderwriterRes: doSetUnderwriterRes,
	getCurrentDateTimeRes: getCurrentDateTimeRes,
});
function mapDispatchToProps(dispatch) {
	return {
		getOfferings: (data) => dispatch(getOffering(data)),
		sendNotifyUser: (data) => dispatch(sendNotifyUser(data)),
		getDspUsers: (ext_id) => dispatch(getDspUsers(ext_id)),
		doDspUsersListRes: () => dispatch(doDspUsersListRes(null)),
		sendSixtyMinuteEmail: (ext_id) => dispatch(sendSixtyMinuteEmail(ext_id)),
		updateProspectusOffering: (data) => dispatch(updateProspectus(data)),
		offeringReconfirmRequest: (data) => dispatch(updateOfferingReconfirmRequest(data)),
		getRatingList: (ext_id) => dispatch(getRatingList(ext_id)),
		setUnderwriter: (data) => dispatch(setUnderwriters(data)),
		getCurrentDateTime: () => dispatch(getCurrentDateTime()),
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OfferingListComponent);
