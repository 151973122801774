import { BASE_URL } from '../constants';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { errorHandling, errorHandler } from '../utils/errorHandling';

class saveAllocationApi {
    static doSaveAllocation(request) {
        const TOKEN = getItem('auth_token');
        const data = new FormData();
        data.append('data_array', JSON.stringify(request.data_str));
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Authorization': TOKEN,
                mimeType: 'multipart/form-data',
            });
            let body = {
                method: 'PUT',
                headers: ajaxRequestHeaders,
                body: data
            }
            return fetch(BASE_URL + `/offerings/save_allocation_list/${request.ext_id}`, body).then(response => {
                if (response.ok === true) {
                    Swal.fire({
                        title: 'Allocation Save Successfully',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,
                        timer: 3000,
                        heightAuto: false
                    });
                }
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }
}

export default saveAllocationApi;