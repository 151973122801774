export default function validate(values) {
    const errors = {};
    const emailReg = new RegExp(/^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i);
    if (!values.firstName) {
      errors.firstName = 'Please enter first name';
    }
    if (!values.lastName) {
      errors.lastName = 'Please enter last name';
    }
    if (!values.email) {
      errors.email = 'Please enter email';
    }
    if (!values.email || (values.email && !values.email.match(emailReg))) {
      errors.email = 'Please enter a valid email';
    }
    if (!values.tickerSymbol) {
      errors.tickerSymbol = 'Please enter ticker symbol';
    }
    if (!values.companyName) {
      errors.companyName = 'Please enter company name';
    }
    if (!values.description) {
      errors.description = 'Please enter description';
    }
    if (!values.exchange) {
      errors.exchange = 'Please select exchange';
    }
    
    return errors;
  }
  