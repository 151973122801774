import * as types from './actionTypes';  
import whitelistedIPsApi from '../api/whitelistedIPsApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doFetchWhiteListedIPsRes(data) {  
  return {
    type: types.FETCH_WHITELISTED_IP_RES, 
    data
  };
}

export function fetchWhiteListedIPs() {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      whitelistedIPsApi.doFetchWhiteListedIPs().then(data => {
        dispatch(doFetchWhiteListedIPsRes(data));
        dispatch(doFetchWhiteListedIPsRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
  
}

export function doUpdateWhiteListedIPsRes(data) {  
  return {
    type: types.UPDATE_WHITELISTED_IP_RES, 
    data
  };
}

export function updateWhiteListedIPs(request) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      whitelistedIPsApi.doUpdateWhiteListedIPs(request).then(data => {
        dispatch(doUpdateWhiteListedIPsRes(data));
        dispatch(doUpdateWhiteListedIPsRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
  
}

export function doCreateWhiteListedIPsRes(data) {  
  return {
    type: types.CREATE_WHITELISTED_IP_RES, 
    data
  };
}

export function createWhiteListedIPs(request) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      whitelistedIPsApi.doCreateWhiteListedIPs(request).then(data => {
        dispatch(doCreateWhiteListedIPsRes(data));
        dispatch(doCreateWhiteListedIPsRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
  
}

export function doDeleteWhiteListedIpRes(data) {  
  return {
    type: types.DELETE_WHITELISTED_IP_RES, 
    data
  };
}

export function deleteWhiteListedIP(request) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      whitelistedIPsApi.doDeleteWhiteListedIPs(request).then(data => {
        dispatch(doDeleteWhiteListedIpRes(data));
        dispatch(doDeleteWhiteListedIpRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
  
}