import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import validate from './formValidation';
import Select from 'react-select';
import { Calendar } from 'primereact/components/calendar/Calendar';
import { MultiSelect } from 'primereact/multiselect';
import { dateTimeFormat, numberWithCommasAndDollar, numberWithCommas } from '../../../helper/helper';
import NumberFormat from 'react-number-format';
import { submitCreateOffering } from '../../../actions/createOfferingActions';
import { getUnderwriterMpid, underWriterMpidListRes } from '../../../actions/underWriterMpidActions';
import { getOfferingIndustries, offeringIndustriesListRes } from '../../../actions/offeringIndustriesActions';
import { fetchLastClosingPriceData, lastClosingPriceRes } from '../../../actions/lastClosingPriceActions';
import { getBrokerDealerList, doBrokerDealerListRes } from '../../../actions/brokerDealerListActions';
import { getBrochureUrl, brochureUrlRes, attestationUrlRes, faqUrlRes } from '../../../actions/brochureUrlActions';
import { fetchOfferingAssetType, doFetchOfferingAssetTypeRes } from '../../../actions/offeringAssetTypesActions';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Switch from "react-switch";
import Swal from 'sweetalert2';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import SelectMaterial from '@material-ui/core/Select';
const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
  }))(props => {
    const { children, classes, onClose } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles(theme => ({
    root: {
      borderTop: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(),
      alignItems: 'center',
      justifyContent: 'center'
    },
  }))(MuiDialogActions);
  const customStyles = {
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      background: "#ffffff",
      zIndex: 9999,
      // kill the gap
      marginTop: 0
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0
    })
  };
// const lower = value => value && value.toLowerCase()
const trimvalue = (value) => value.replace(/^\s+|\s+$/g, "");

const fields = ['offering_type_name', 'ticker_symbol', 'name', 'cusip_id', 'ext_id', 'min_price', 'max_price', 'trade_date', 'settlement_date', 'final_shares', 'industry', 'description', 'underwriter_mpid', 'underwriter_concession_amount', 'underwriters_list', 'prospectus_url', 'exchange_value_name', 'available_to_order', 'listed_share_tbd', 'trade_date_tbd', 'settlement_date_tbd', 'min_price_tbd', 'max_price_tbd', 'dsp_attestation_file', 'dsp_faq_file', 'broker_sales_credit', 'ordr_dollar_share', 'min_ticket_size', 'max_ticket_size'];

const renderField = ({ input, label, type, disabled, meta: { touched, error } }) => (
    <div>
        <label className="update-label">{label}</label>
        <TextField className="offering_create_input" {...input} type={type} disabled={disabled} />
    </div>
)
const renderFieldDollar = ({ input, label,startAdornment, type, disabled, meta: { touched, error } }) => (
    <div>
        <label className="update-label">{label}</label>
        <FormControl fullWidth>
            {/* <InputLabel htmlFor="standard-adornment-amount">{label}</InputLabel> */}
            <Input
                {...input} type={type} disabled={disabled}
                startAdornment={startAdornment}
            />
        </FormControl>
    </div>
)

const renderTextareaField = ({ input, label, meta: { touched, error } }) => (
    <div>
        <label className="update-label">{label}</label>
        <TextField {...input} multiline rows={4}  style={{ borderStyle: 'groove', borderColor: '#ccc', width: '100%' }} />
    </div>
);


// const renderMultiselect = ({ input, value, name, options, placeholder, label, meta: { touched, error } }) => (
//     <div>
//         <label className="update-label">{label}</label>
//         <Select {...input} options={options} name={name} value={value} isMulti={true} isSearchable placeholder={placeholder} styles={customStyles} />
//     </div>
// );
// const renderSelect = ({ input, label, value, name, options, meta: { touched, error } }) => (
//     <div>
//         <label className="update-label">{label}</label>
//         <Select {...input} options={options} name={name} styles={customStyles} value={value} />
//     </div>
// );

const customFileInput = (field) => {
    delete field.input.value; // <-- just delete the value property
    return <input type="file" id="file" {...field.input} />;
};

class CreateOfferingForm extends React.PureComponent {

    constructor() {
        super();
        const today = new Date();
        const month = today.getMonth();
        const year = today.getFullYear();
        const prevMonth = (month === 0) ? 11 : month - 1;
        const prevYear = (prevMonth === 11) ? year - 1 : year;

        const minDate = new Date();
        minDate.setMonth(prevMonth + 1);
        minDate.setFullYear(prevYear);
        this.state = {
            isLoader: true,
            lastClosing: false,
            typeId: '',
            name: '',
            ticker_symbol: '',
            cusip_id: '',
            ext_id: '',
            offering_type_name: 'IPO',
            max_price: '',
            min_price: '',
            final_shares: '',
            description: '',
            industry: '',
            selected_industry: {},
            prospectus_url: '',
            underwriters_list: [],
            underwriters_list_array: [],
            blocked_bd_list: [],
            blocked_bd_list_array: [],
            settlement_date: '',
            trade_date: '',
            available_to_order: '1',
            isSubmited: false,
            UnderwriterValue: '',
            UnderwriterId: '',
            selectUserMpId: '',
            bd_underwriter_mpid_id: '',
            selected_bd_underwriter_mpid_id: '',
            underwriter_concession_amount: '0.00',
            minDate: minDate,
            trade_date_show: '',
            settlement_date_show: '',
            file: '',
            previewLogo: '',
            date_tbd: false,
            isListedChecked: false,
            offeringListCheck: false,
            isMaxPriceChecked: false,
            isMinPriceChecked: false,
            openConfirmation: false,
            isCheckReadonly: false,
            selectedUnderwriter: null,
            isLastClosingDisabled: true,
            exchange_value_name: '',
            brochure_url: '',
            brokerdealers: [],
            selectedUnderWritersList: [],
            selectedBrokerDealersList: [],
            errorBtn: true,
            issuer: '',
            security: '',
            exchange: '',
            base_deal_size: '',
            initial_offering_price: '',
            credit_rating: '',
            coupon_talk: '',
            interest_payment_dates: '',
            no_call_provision: '',
            final_redemption: '',
            denominations: '',
            expected_pricing: '',
            lead_underwriter: '',
            dsp: 0,
            isDsp: false,
            isEnterTickerSymbol: true,
            brochureFile: '',
            offeringindustriesList: [],
            underWriterMpidList: [],

            attestationFile: '',
            faqFile: '',
            attestationFileName: 'Select your file',
            faqFileName: 'Select your file',
            dsp_attestation_file: '',
            dsp_faq_file: '',
            isDirectedShare: false,
            broker_sales_credit: '',
            ordr_dollar_share: 0,
            min_ticket_size: '',
            max_ticket_size: '',
            unit_increment: 1,
            follow_min_price: '',
            follow_max_price: '',

            showUrlField: false,
            showFileUploadField: false,
            selected_offering: '',
            isRender: false,
            checkedSwitch: false,
            ioi_cutoff_datetime: '',
            ioi_cutoff_datetime_show: '',
            activeWindow: '',
            offeringAssetTypes: [],
            requiredCheckboxOne: false,
            requiredCheckboxTwo: false,
            requiredCheckboxThree: false,
            requiredCheckboxFour: false,
            requiredCheckboxFive: false,
            requiredCheckboxSix: false,
            requiredCheckboxSeven: false,
        }
        
    }

    componentDidMount() {
        this.props.getUnderwriterMpidList();
        this.props.getBrokerDealerList();
        this.props.getOfferingIndustriesList();
        this.props.getOfferingAssetType();
        
        this.setState({
            isLoader: false,
        });
        this.props.changeFieldValue('underwriter_concession_amount', '0.00');
    }

    static getDerivedStateFromProps(props, state) {
        if(props.offeringIndustriesListRes){
			if(props.offeringIndustriesListRes.data && props.offeringIndustriesListRes.data.offeringIndustries){
				if (props.offeringIndustriesListRes.data.offeringIndustries.message === 'Success') {
                    return {
                        offeringindustriesList: props.offeringIndustriesListRes.data.offeringIndustries.data
                    };
                }
			}
        }
        if(props.brokerDealerListRes){
			if(props.brokerDealerListRes.data && props.brokerDealerListRes.data.bdList){
                if (props.brokerDealerListRes.data.bdList.message === 'Success') {
                    
                    let bdLists = [];
                    if (props.brokerDealerListRes.data.bdList.data.length > 0) {
                        for (let item of props.brokerDealerListRes.data.bdList.data) {
                            if (item.type === "BrokerDealer") {
                                bdLists.push(item)
                            }
                        }
                    }
                    return {
                        brokerdealers: bdLists
                    };
                }
			}
        }
        if(props.underWriterMpidListRes){
			if(props.underWriterMpidListRes.data && props.underWriterMpidListRes.data.underWriterMpid){
				if (props.underWriterMpidListRes.data.underWriterMpid.message === 'Success') {
                    return {
                        underWriterMpidList: props.underWriterMpidListRes.data.underWriterMpid.data
                    };
                }
			}
        }
        if (props.lastClosingPriceRes) {
            if(props.lastClosingPriceRes.data && props.lastClosingPriceRes.data.lastClosingPrice){
				
                if (props.lastClosingPriceRes.data.lastClosingPrice.message === 'Success') {
                    let maxPriceVal = 0;
                    let nameVal = '';
                    let descriptionVal = '';
                    let exchangeVal = '';

                    if (props.lastClosingPriceRes.data.lastClosingPrice.data === null) {
                        maxPriceVal = '';
                        nameVal = state.name;
                        descriptionVal = state.description;
                        exchangeVal = state.exchange;
                    }
                    if (props.lastClosingPriceRes.data.lastClosingPrice.data) {
                        if (state.offering_type_name === 'IPO') {
                            nameVal = props.lastClosingPriceRes.data.lastClosingPrice.data.name;
                            descriptionVal = props.lastClosingPriceRes.data.lastClosingPrice.data.description;
                            exchangeVal = props.lastClosingPriceRes.data.lastClosingPrice.data.exchange;
                        } else if (state.offering_type_name === 'Follow-on' ||state.offering_type_name === 'ETF' ||state.offering_type_name === 'UIT') {
                            maxPriceVal = props.lastClosingPriceRes.data.lastClosingPrice.data.price;
                            nameVal = props.lastClosingPriceRes.data.lastClosingPrice.data.name;
                            descriptionVal = props.lastClosingPriceRes.data.lastClosingPrice.data.description;
                            exchangeVal = props.lastClosingPriceRes.data.lastClosingPrice.data.exchange;
                        } else if (state.offering_type_name === 'Overnight') {
                            maxPriceVal = props.lastClosingPriceRes.data.lastClosingPrice.data.price;
                            nameVal = props.lastClosingPriceRes.data.lastClosingPrice.data.name;
                            descriptionVal = props.lastClosingPriceRes.data.lastClosingPrice.data.description;
                            exchangeVal = props.lastClosingPriceRes.data.lastClosingPrice.data.exchange;
                        }
                    }
                    
                    
                    return {
                        errorBtn: true,
                        max_price: maxPriceVal,
                        name: nameVal,
                        description: descriptionVal,
                        exchange: exchangeVal,
                    };
                }
                if (props.lastClosingPriceRes.data.lastClosingPrice.error) {
                    props.changeFieldValue('ticker_symbol', '');
                    return {
                        ticker_symbol: ''
                    }
                }
			}
        }
        if (props.brochureUrlRes) {
            if(props.brochureUrlRes.data && props.brochureUrlRes.data.brochureUrl){
				if(props.brochureUrlRes.data.brochureUrl.message === "Success") {
                    return {
                        brochure_url: props.brochureUrlRes.data.brochureUrl.data,
                    };
                }
			}
        }
        if (props.attestationUrlRes) {
            if(props.attestationUrlRes.data && props.attestationUrlRes.data.attestationUrl){
				if(props.attestationUrlRes.data.attestationUrl.message === "Success") {
                    return {
                        dsp_attestation_file: props.attestationUrlRes.data.attestationUrl.data,
                    };
                }
			}
        }
        if (props.faqUrlRes) {
            if(props.faqUrlRes.data && props.faqUrlRes.data.faqUrl){
				if(props.faqUrlRes.data.faqUrl.message === "Success") {
                    return {
                        dsp_faq_file: props.faqUrlRes.data.faqUrl.data,
                    };
                }
			}
        }
        if (props.fetchOfferingAssetTypeRes) {
            if(props.fetchOfferingAssetTypeRes.data && props.fetchOfferingAssetTypeRes.data.offeringAssetTypes){
				if(props.fetchOfferingAssetTypeRes.data.offeringAssetTypes.message === "Success") {
                    return {
                        offeringAssetTypes: props.fetchOfferingAssetTypeRes.data.offeringAssetTypes.data,
                    };
                }
			}
        }
        
        return null;
    }
    componentWillUnmount() {
        this.resetForm();
    }
    resetForm = () => {
        this.props.reset();
        const today = new Date();
        const month = today.getMonth();
        const year = today.getFullYear();
        const prevMonth = (month === 0) ? 11 : month - 1;
        const prevYear = (prevMonth === 11) ? year - 1 : year;

        const minDate = new Date();
        minDate.setMonth(prevMonth + 1);
        minDate.setFullYear(prevYear);
        this.setState({
            lastClosing: false,
            typeId: '',
            name: '',
            ticker_symbol: '',
            cusip_id: '',
            ext_id: '',
            offering_type_name: 'IPO',
            max_price: '',
            min_price: '',
            final_shares: '',
            description: '',
            industry: '',
            selected_industry: {},
            prospectus_url: '',
            underwriters_list: [],
            underwriters_list_array: [],
            blocked_bd_list: [],
            blocked_bd_list_array: [],
            settlement_date: '',
            trade_date: '',
            available_to_order: '1',
            isSubmited: false,
            UnderwriterValue: '',
            UnderwriterId: '',
            selectUserMpId: '',
            bd_underwriter_mpid_id: '',
            selected_bd_underwriter_mpid_id: '',
            underwriter_concession_amount: '0.00',
            minDate: minDate,
            trade_date_show: '',
            settlement_date_show: '',
            file: '',
            previewLogo: '',
            date_tbd: false,
            isListedChecked: false,
            offeringListCheck: false,
            isMaxPriceChecked: false,
            isMinPriceChecked: false,
            openConfirmation: false,
            isCheckReadonly: false,
            selectedUnderwriter: null,
            isLastClosingDisabled: true,
            exchange_value_name: '',
            brochure_url: '',
            selectedUnderWritersList: [],
            selectedBrokerDealersList: [],
            errorBtn: true,
            issuer: '',
            security: '',
            exchange: '',
            base_deal_size: '',
            initial_offering_price: '',
            credit_rating: '',
            coupon_talk: '',
            interest_payment_dates: '',
            no_call_provision: '',
            final_redemption: '',
            denominations: '',
            expected_pricing: '',
            lead_underwriter: '',
            dsp: 0,
            isDsp: false,
            isEnterTickerSymbol: true,
            brochureFile: '',

            attestationFile: '',
            faqFile: '',
            attestationFileName: 'Select your file',
            faqFileName: 'Select your file',
            dsp_attestation_file: '',
            dsp_faq_file: '',
            isDirectedShare: false,
            broker_sales_credit: '',
            ordr_dollar_share: 0,
            min_ticket_size: '',
            max_ticket_size: '',
            unit_increment: 1,
            follow_min_price: '',
            follow_max_price: '',

            showUrlField: false,
            showFileUploadField: false,
            selected_offering: '',
            isRender: false,
            checkedSwitch: false,
            ioi_cutoff_datetime: '',
            ioi_cutoff_datetime_show: '',
            activeWindow: '',
            isEditValue: false,
            requiredCheckboxOne: false,
            requiredCheckboxTwo: false,
            requiredCheckboxThree: false,
            requiredCheckboxFour: false,
            requiredCheckboxFive: false,
            requiredCheckboxSix: false,
            requiredCheckboxSeven: false,
        }, () => {
            this.setState({
                isRender: !this.state.isRender
            })
        });
        
    }
    onCloseModal = () => {
        this.setState({
            openConfirmation: false,
        });
    };
    
    onOpenModal = () => {
        this.setState({
            isSubmited: true
        }, () => { });
        validate(this.state);
        const errors = validate(this.state);
        if (Object.keys(errors).length === 0) {
            const selectedUnderWriters = [];
            if (this.state.selectedUnderwriter && this.state.selectedUnderwriter.length > 0) {
                for (const items of this.state.selectedUnderwriter) {
                    selectedUnderWriters.push(items.executing_broker_mpid);
                }
            }
            const selectedBrokerDealers = [];
            if (this.state.blocked_bd_list && this.state.blocked_bd_list.length > 0) {
                for (var j = 0; j < this.state.blocked_bd_list.length; j++) {
                    const item = this.state.blocked_bd_list[j];
                    selectedBrokerDealers.push(item.name);
                }
            }
            this.setState({
                openConfirmation: true,
                selectedUnderWritersList: selectedUnderWriters,
                selectedBrokerDealersList: selectedBrokerDealers,

            });
        } else {
            fields.find((field) => {

                if (errors[field]) {
                    const elem = document.querySelector(`input[name=${field}]`);
                    if (elem) {
                        elem.focus();
                        return true;
                    }
                }

                return false;
            });

        }
    };

    handleChangeValue = (e) => {
        if (e.target.name === 'prospectus_url' || e.target.name === 'brochure_url') {
            this.setState({
                [e.target.name]: e.target.value.trim(),
            }, () => {
                if (e.target.name === 'prospectus_url') {
                    this.props.changeFieldValue('prospectus_url', e.target.value.trim());
                } else if (e.target.name === 'brochure_url') {
                    this.props.changeFieldValue('brochure_url', e.target.value.trim());
                }
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value,
            }, () => { });
        }
        
    }

    handleChangePriceValue = (e) => {
        if (e.target.value === '') {
            this.setState({
                max_price: this.state.exchange,
                name: this.state.exchange,
                description: this.state.exchange,
                exchange: this.state.exchange,
            }, () => {
                this.props.changeFieldValue('max_price', '');
                this.props.changeFieldValue('name', '');
                this.props.changeFieldValue('description', '');
                this.props.changeFieldValue('exchange', '');
                this.setState({
                    isEnterTickerSymbol: true,
                });
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value,
            }, () => {
                if (this.state.ticker_symbol !== '') {
                    this.setState({
                        isEnterTickerSymbol: false,
                    },()=>{ });
                }
            });
        }
    }

    changeBrochureMode = (value) => {
        if (this.state.isEnterTickerSymbol) {
            Swal.fire({
                title: "Please enter Ticker Symbol first",
                icon: 'info',
                confirmButtonText: 'OK',
                allowOutsideClick: false,
                timer: 3000,
                heightAuto: false
            });
        } else {
            if (value === 'url') {
                if(this.state.showUrlField===false){
                    this.props.changeFieldValue('brochure_url', '');
                    this.setState({
                        showUrlField: true,
                        showFileUploadField: false,
                        brochure_url: ''
                    });
                }else{
                    this.setState({
                        showUrlField: false,
                        showFileUploadField: false,
                    });
                }
            } else {
                if(this.state.showFileUploadField===false){
                    this.props.changeFieldValue('brochure_url', '');
                    this.setState({
                        showFileUploadField: true,
                        showUrlField: false,
                        brochure_url: ''
                    });
                }else{
                    this.setState({
                        showFileUploadField: false,
                        showUrlField: false,
                    });
                }
            }
        }
    }

    chooseBrochureFile = (e) =>  {
        this.setState({
            brochureFile: e.target.files,
        }, () => {
            this.props.getBrochureUrl(this.state.brochureFile, this.state.ticker_symbol, 'brochure');
        });
    }

    selectInputFile = (e) => {
        this.setState({
            previewLogo: URL.createObjectURL(e.target.files[0]),
            file: e.target.files,
        }, () => { });
    }

    selectCheckbox = () => {
        // e.preventDefault();
        if (this.state.isDsp === false) {
            this.setState({
              isDsp: true,
              dsp: 1,
              isDirectedShare: false,
            }, () => {});
          } else {
            this.setState({
              isDsp: false,
              dsp: 0,
              isDirectedShare: false,
            }, () => {});
          }
    }
    selectCheckboxDirectedShare = () => {
        if (this.state.isDirectedShare === false) {
          this.setState({
            isDirectedShare: true,
            isDsp: false,
            dsp: 2,
          }, () => {});
        } else {
          this.setState({
            isDirectedShare: false,
            dsp: 0,
            isDsp: false,
          }, () => {});
        }
    }

    // ** Select Exchange   **//
    handleChangeExchange = (e) => {
        if (e.target.value) {
            this.setState({
                exchange_value_name: e.target.value,
            });
        } else {
            this.setState({
                exchange_value_name: '',
            });
        }
    }
    handleValueChange = (e) => {
        if (e.target.value === 'IPO') {
            this.setState({
                offering_type_name: e.target.value,
                lastClosing: false,
                showNotify: true,
                // ticker_symbol: '',
                max_price: '',
                min_price: '',
                attestationFile: '',
                faqFile: ''
            }, () => { });
            // for secondary the min_price is an empty string.
            // and last closing price is max_price and that value if from the api
        } else if (e.target.value === 'Follow-on' || e.target.value === 'ETF' || e.target.value === 'UIT') {
            this.setState({
                offering_type_name: e.target.value,
                lastClosing: true,
                showNotify: true,
                min_price: '',
                attestationFile: '',
                faqFile: '',
                max_price: this.props.lastClosingPriceRes ? this.props.lastClosingPriceRes.price : '',
            });
            // for spot and block min_price is last closing price and max_price is anticipated price.
            // Last Closing Price is coming from the api and min_price is coming from the form that user has completed
        } else if (e.target.value === 'Overnight') {
            this.setState({
                lastClosing: true,
                showNotify: true,
                min_price: '',
                attestationFile: '',
                faqFile: '',
                max_price: this.props.lastClosingPriceRes ? this.props.lastClosingPriceRes.price : '',
                offering_type_name: e.target.value,
            });
        } else if (e.target.value === 'Notes') {
            this.setState({
                lastClosing: true,
                showNotify: true,
                min_price: '',
                attestationFile: '',
                faqFile: '',
                max_price: this.props.lastClosingPriceRes ? this.props.lastClosingPriceRes.price : '',
                offering_type_name: e.target.value,
            });
        }
    }
    fetchFinalPrice = () => {
        // if (this.state.offering_type_name === 'Follow-on' || this.state.offering_type_name === 'Overnight') {
        //     if (this.state.ticker_symbol !== '') {
        //         let payload ={
        //             ticker_symbol: this.state.ticker_symbol,
        //             offering_type_name: this.state.offering_type_name
        //         }
        //         this.props.lastClosingPrice(payload);
        //     }
        // }
    }

    handleFormSubmit = () => {
        let formData = this.state;
        var offering_date_attributes_ = {};
        if (formData.date_tbd === true) {
            offering_date_attributes_.settlement_date = null; // "TBD"
            offering_date_attributes_.trade_date = null; // "TBD"
        } else {
            offering_date_attributes_.settlement_date = formData.settlement_date;
            offering_date_attributes_.trade_date = formData.trade_date;
        }

        formData.offering_date_attributes = offering_date_attributes_;

        var offering_price_attributes_ = {};
        if (formData.isMaxPriceChecked === true) {
            offering_price_attributes_.max_price = null;
        } else {
            offering_price_attributes_.max_price = Number(formData.max_price);
        }
        if (formData.isMinPriceChecked === true) {
            offering_price_attributes_.min_price = null;
        } else {
            offering_price_attributes_.min_price = Number(formData.min_price);
        }
        if (formData.min_price === '') {
            offering_price_attributes_.min_price = null;
        } else {
            offering_price_attributes_.min_price = Number(formData.min_price);
        }
        formData.offering_price_attributes = offering_price_attributes_;


        if (formData.isListedChecked === true) {
            formData.final_shares = null;
        } else {
            formData.final_shares = Number(formData.final_shares);
        }
        
        let finalSubmitData = {
            'name': this.state.name,
            'ticker_symbol': this.state.ticker_symbol.toUpperCase(),
            'cusip_id': this.state.cusip_id,
            'ext_id': this.state.ext_id,
            'offering_type_name': this.state.offering_type_name,
            'final_shares': this.state.final_shares,
            'description': this.state.description,
            'industry': this.state.industry,
            'min_price': offering_price_attributes_.min_price,
            'max_price': offering_price_attributes_.max_price,
            'prospectus_url': this.state.prospectus_url,
            'underwriters_list': this.state.selectedUnderWritersList.join(),
            'trade_date': offering_date_attributes_.trade_date,
            'settlement_date': offering_date_attributes_.settlement_date,
            'available_to_order': Number(this.state.available_to_order),
            'bd_underwriter_mpid_id': this.state.bd_underwriter_mpid_id,
            'underwriter_concession_amount': Number(this.state.underwriter_concession_amount),
            'active_window': this.state.activeWindow,
            'notify': false,
            'exchange' : this.state.exchange_value_name,
            'brochure_url' : this.state.brochure_url === '' ? null : this.state.brochure_url,
            'dsp': this.state.dsp,
            'dsp_attestation_file': this.state.dsp === 1 ? this.state.dsp_attestation_file === '' ? null : this.state.dsp_attestation_file : null,
            'dsp_faq_file': this.state.dsp === 1 ? this.state.dsp_faq_file === '' ? null : this.state.dsp_faq_file : null,
            'unit_increment': Number(this.state.unit_increment),
            'ordr_dollar_share': Number(this.state.ordr_dollar_share),
            'min_ticket_size': this.state.min_ticket_size ? Number(this.state.min_ticket_size) : null,
            'max_ticket_size': this.state.max_ticket_size ? Number(this.state.max_ticket_size) : null,
            'blocked_bd_list': this.state.selectedBrokerDealersList.join(),
            'ioi_cutoff_datetime': null,
            'attestation': true,
        }
        //'ioi_cutoff_datetime': this.state.ioi_cutoff_datetime,
        if (this.state.dsp !== 2) {
            finalSubmitData.ioi_cutoff_datetime = null;
          }
        if (this.state.offering_type_name === 'IPO') {
            if (Number(this.state.min_price) || Number(this.state.max_price)) {
                finalSubmitData.price_range = ('$' + this.state.min_price + ' - $' + this.state.max_price);
            } else {
                finalSubmitData.price_range = null;
            }
        }
        if (this.state.offering_type_name === 'Follow-on' || this.state.offering_type_name === 'ETF' || this.state.offering_type_name === 'UIT') {
            if (Number(this.state.max_price)) {
                finalSubmitData.price_range = '$' + this.state.max_price;
            } else {
                finalSubmitData.price_range = null;
            }
        }
        if (this.state.offering_type_name === 'Overnight') {
            finalSubmitData.price_range = ('$' + this.state.follow_min_price + ' - $' + this.state.follow_min_price);
        }
          if (this.state.offering_type_name === 'IPO' || this.state.offering_type_name === 'Follow-on' || this.state.offering_type_name === 'ETF' || this.state.offering_type_name === 'UIT') {
            delete (finalSubmitData.active_window);
          }
        this.props.submitCreateOffering(finalSubmitData, this.state.file);
        this.setState({
            openConfirmation: false,
        }, () => { });
    }
    
    mpIDhandleChange = (event) => {
        this.setState({
            selected_bd_underwriter_mpid_id: event,
            bd_underwriter_mpid_id: event.value,
        }, () => { });
    }

    leadUnderwriterhandleChange = (event) => {
        this.setState({
            lead_underwriter: event.value,
        }, () => { });
    }

    industryHandleChange = (event) => {
        this.setState({
            selected_industry: event,
            industry: event.label
        }, () => {});
    }

    toggleChange = (e) => {
        this.setState({
            available_to_order: e.target.value,
        });
    }
    toggleOrderChange = (e) => {
        this.setState({
            ordr_dollar_share: e,
        });
    }
    
    goOffering = () => {
        this.props.history.push('/offerings');
    }

    trimvalue = (value) => {
        if (value) {
            return value.replace(/^\s+|\s+$/g, "");
        } else {
            return value.replace(/^\s+|\s+$/g, " ");
        }
    }

    handleShareChange = (event) => {
        if (event.target.value) {
            this.setState({
                final_shares: (event.target.value) ? this.trimvalue(event.target.value) : null,
                isListedChecked: false,
            });
        } else {
            this.setState({
                final_shares: '',
                isListedChecked: false,
            }, () => { });
        }
    }

    listedSharesChange = () => {
        if (this.state.isListedChecked === false) {
            this.setState({
                isListedChecked: true,
                final_shares: '',
            }, () => {
                this.props.changeFieldValue('final_shares', '');
            });
        } else {
            this.setState({
                isListedChecked: false,
            }, () => { });
        }
    }
    selectSettlementDate = (e) => {
        if (e.value) {
            this.setState({
                settlement_date: dateTimeFormat(e.value),
                settlement_date_show: e.value,
                date_tbd: false,
            }, () => { });
        } else {
            this.setState({
                settlement_date: '',
                date_tbd: false,
            }, () => { });
        }
    }

    selectTradeDate = (e) => {
        if (e.value) {
            const settlementDate = new Date(e.value);
            settlementDate.setDate(e.value.getDate() + 2);
            this.setState({
                trade_date: dateTimeFormat(e.value),
                settlement_date: dateTimeFormat(settlementDate),
                trade_date_show: e.value,
                settlement_date_show: settlementDate,
                date_tbd: false,
            }, () => {
                this.props.changeFieldValue('settlement_date', this.state.settlement_date_show);
            });
        } else {
            this.setState({
                trade_date: '',
                date_tbd: false,
            }, () => {
                this.props.changeFieldValue('trade_date', '');
            });
        }
    }
    
    selectIoiCutoff = (e) => {
        if (e.value) {
            this.setState({
                ioi_cutoff_datetime: dateTimeFormat(e.value),
                ioi_cutoff_datetime_show: e.value,
            }, () => { });
        } else {
            this.setState({
                ioi_cutoff_datetime: null,
            }, () => { });
        }
    }
    toggleDateTbd = () => {
        this.setState({
            settlement_date_show: null,
            settlement_date: null,
            trade_date_show: null,
            trade_date: null,
            date_tbd: !this.state.date_tbd,
        }, () => {
            this.props.changeFieldValue('trade_date', null);
            this.props.changeFieldValue('settlement_date', null);
            this.setState({
                isRender: !this.state.isRender
            })
        });
    }

    ApproxMinPriceChange = () => {
        if (this.state.isMinPriceChecked === false) {
            this.setState({
                isMinPriceChecked: true,
                min_price: '',
            }, () => {
                this.props.changeFieldValue('min_price', '');
            });
        } else {
            this.setState({
                isMinPriceChecked: false,
            }, () => { });
        }
    }

    ApproxMaxPriceChange = () => {
        if (this.state.isMaxPriceChecked === false) {
            this.setState({
                isMaxPriceChecked: true,
                max_price: '',
            }, () => {
                this.props.changeFieldValue('max_price', '');
            });
        } else {
            this.setState({
                isMaxPriceChecked: false,
            }, () => { });
        }
    }

    handleChangeMinPrice = (event) => {
        this.setState({
            min_price: event.target.value,
            isMinPriceChecked: false,
        }, () => { });
    }

    handleChangeMaxPrice = (event) => {
        this.setState({
            max_price: event.target.value,
            isMaxPriceChecked: false,
        }, () => { });
    }

    goBackOffering = () => {
        this.props.history.goBack();
        this.props.reset();
    }

    underwriterHandle = (event) => {
        // if (value === null) {
        //     this.setState({
        //         underwriters_list_array: [],
        //         underwriters_list: value,
        //     }, () => { });
        // } else {
        //     this.setState({
        //         underwriters_list_array: Object.values(value),
        //         underwriters_list: value,
        //     }, () => {
        //     });
        // }
        this.setState({ selectedUnderwriter: event.value });
    }
    blockedBdHandle = (event) => {
        this.setState({
            blocked_bd_list: event.value,
        }, () => { });
    }
    toggleLastClosingDisabled = (e) => {
        e.preventDefault();
        this.setState({
            isLastClosingDisabled: !this.state.isLastClosingDisabled,
        });
    }
    selectAttestationFile = (e) => {
        e.persist()
        if (this.state.isEnterTickerSymbol) {
            Swal.fire({
                title: "Please enter Ticker Symbol first",
                icon: 'info',
                confirmButtonText: 'OK',
                allowOutsideClick: false,
                timer: 3000
            });
        } else {
          this.setState({
            attestationFileName: e.target.files[0].name,
            attestationFile: e.target.files,
          }, () => {
            this.props.getBrochureUrl(this.state.attestationFile, this.state.ticker_symbol, 'attestation');
          });
        }
    }
    selectFaqFile = (e) => {
        e.persist()
        if (this.state.isEnterTickerSymbol) {
          Swal.fire({
            title: "Please enter Ticker Symbol first",
            icon: 'info',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000
        });
        } else {
          this.setState({
            faqFileName: e.target.files[0].name,
            faqFile: e.target.files,
          }, () => {
            this.props.getBrochureUrl(this.state.faqFile, this.state.ticker_symbol, 'faq');
          });
        }
    }
    
    handleChangeSwitch = (checked) => {
        if (checked === true) {
            this.setState({
                ordr_dollar_share: 1
            })
        } else {
            this.setState({
                ordr_dollar_share: 0
            })
        }
        this.setState({ checkedSwitch: checked });
    }
    
    print = () => {
        window.print();
    }
    printDiv = (divName) => {
        var printContents = document.getElementById(divName).innerHTML;
        var originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;

        window.print();

        document.body.innerHTML = originalContents;

    }
    
    handleEndEditing = () => {
        this.setState( { isEditValue: true, underwriter_concession_amount: '' }, () => {});
    }
    goChange = (e) => {
        if (this.state.isEditValue) {
            const re = /^\s*\d*\.?\d{0,2}\s*$/;
            if (e.target.value === '' || re.test(e.target.value)) {
                this.setState({
                    underwriter_concession_amount: e.target.value,
                    isEditValue: false
                }, () => {
                })
            }
        } else {
            const re = /^\s*\d*\.?\d{0,2}\s*$/;
            if (e.target.value === '' || re.test(e.target.value)) {
                this.setState({
                    underwriter_concession_amount: e.target.value,
                }, () => {
                })
            }
        }
    }
    selectCheckboxRequired = (event) => {
        this.setState({
            [event.target.name]: event.target.checked
        })
    }
    render() {
        const errors = validate(this.state);
        const typeAheadUserMpidList = [];
        if (this.state.underWriterMpidList && this.state.underWriterMpidList.length > 0) {
            this.state.underWriterMpidList.map((item) => {
                typeAheadUserMpidList.push({ value: item.executing_broker_mpid, label: item.executing_broker_mpid + ' ' + item.clearing_broker, original: item });
                return (
                    <option value={item.executing_broker_mpid} id={item.id} key={item.id}>
                        {item.executing_broker_mpid}  {item.clearing_broker}        
                    </option>
                );
            });
        }

        const userMpidListForUnderwriters = [];
        if (this.state.underWriterMpidList && this.state.underWriterMpidList.length > 0) {
            this.state.underWriterMpidList.map((item) => {
                let labelData = item.executing_broker_mpid +" "+item.clearing_broker;
                userMpidListForUnderwriters.push({ value: item.executing_broker_mpid, label: labelData, original: item });
                return (
                    <option value={item.executing_broker_mpid} id={item.id} key={item.id}>
                        {labelData}          
                    </option>
                );
            });
        }
        const userMpidUnderwriters = [];
        if (this.state.underWriterMpidList && this.state.underWriterMpidList.length > 0) {
            this.state.underWriterMpidList.map((item) => {
                // let labelData = item.executing_broker_mpid ;
                // userMpidUnderwriters.push({ value: item.executing_broker_mpid, label: labelData, original: item });
                // return (
                //     <option value={item.executing_broker_mpid} id={item.id} key={item.id}>
                //         {labelData}          
                //     </option>
                // );
                let labelData = item.executing_broker_mpid ;
                userMpidUnderwriters.push(item);
                return (
                    <option value={item.executing_broker_mpid} id={item.id} key={item.id}>
                        {labelData}          
                    </option>
                );
            });
        }
        
        const brokerDealerList = [];
        if (this.state.brokerdealers && this.state.brokerdealers.length > 0) {
            this.state.brokerdealers.map((item) => {
                brokerDealerList.push(item);
                return (
                    <option value={item.name} id={item.mpid} key={item.mpid}>
                        {item.name}
                    </option>
                );
            });
        }
        const indusnewOption = [];
        if (this.state.offeringindustriesList) {
            if (this.state.offeringindustriesList.length > 0) {
                for (let item of this.state.offeringindustriesList) {
                    indusnewOption.push({
                        value: item.id,
                        label: item.name,
                    });
                }
            }
        }
        return (
            <div>
                <div>
                    <p style={{ paddingLeft: '10px', color: '#B2B2B2' }}>* is required</p>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="mt-4">
                                <label className="update-label">Offering Type *</label>
                                <FormControl variant="outlined" fullWidth >
                                    <SelectMaterial
                                        labelId="offering_type_name-label"
                                        name="offering_type_name"
                                        value={this.state.offering_type_name}
                                        onChange={this.handleValueChange}
                                    >   
                                        {
                                            this.state.offeringAssetTypes && this.state.offeringAssetTypes?.map((op, i) => 
                                                <MenuItem key={i} value={op.asset_name}>{op.asset_name}</MenuItem>
                                            )
                                        }
                                        {/* <MenuItem value={'IPO'}>IPO</MenuItem>
                                        <MenuItem value={'Follow-on'}>Follow-on</MenuItem>
                                        <MenuItem value={'Overnight'}>Overnight</MenuItem>
                                        <MenuItem value={'ETF'}>ETF</MenuItem>
                                        <MenuItem value={'UIT'}>UIT</MenuItem> */}
                                    </SelectMaterial>
                                </FormControl>
                                {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.offering_type_name}</span>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mt-4">
                                <Field
                                    name="ticker_symbol"
                                    component={renderField}
                                    label="Ticker Symbol *"
                                    autoComplete="off"
                                    onChange={this.handleChangePriceValue}
                                    type="text"
                                    onBlur={() => { this.fetchFinalPrice(); }}
                                />
                                {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.ticker_symbol}</span>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mt-4">
                                <Field
                                    name="name"
                                    component={renderField}
                                    label="Company Name *"
                                    autoComplete="off"
                                    type="text"
                                    onChange={this.handleChangeValue}
                                />
                                {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.name}</span>}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mt-4">
                                <Field
                                    name="cusip_id"
                                    component={renderField}
                                    label="CUSIP ID"
                                    autoComplete="off"
                                    type="text"
                                    onChange={this.handleChangeValue}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mt-4">
                                <Field
                                    name="ext_id"
                                    component={renderField}
                                    label="FILE/EXT ID *"
                                    autoComplete="off"
                                    type="text"
                                    onChange={this.handleChangeValue}
                                />
                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.ext_id}</span>}
                            </div>
                        </div>
                    </div>
                    { (this.state.lastClosing) ? (
                        <div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12" style={{ paddingTop: '25px' }}>
                                    <div>
                                    <span className="fa fa-dollar form-control-feedback"></span>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                        {/* if spot or block then lastClosingPrice is min_price */}
                                        <Field
                                            name="max_price"
                                            component={renderFieldDollar}
                                            label="Last Closing Price*"
                                            autoComplete="off"
                                            type="text"
                                            ref={(Elmt) => { this.max_price = Elmt; }}
                                            onChange={this.handleChangeValue}
                                            normalize={trimvalue}
                                            style={{ width: '95%' }}
                                            // disabled={this.state.isLastClosingDisabled}
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            />
                                        </div>
                                        {/* the button that makes last closing price editable for admin to change manually */}
                                        {/* {(this.state.isLastClosingDisabled) &&
                                        <div className="col-md-6 col-sm-12" style={{paddingTop: 33}}>
                                        <input type='submit' value="Edit last closing price" className="toggleLastClosingBtn btn-success" onClick={(e) => this.toggleLastClosingDisabled(e)} />
                                        </div>
                                        } */}
                                    </div>
                                    </div>
                                </div>
                                {(this.state.offering_type_name === 'Overnight') &&
                                    <div className="col-md-6 col-sm-12">
                                        <div className="row" style={{ paddingTop: '25px' }}>
                                            <div className="col-md-6 col-sm-12" >
                                                <span className="fa fa-dollar form-control-feedback"></span>
                                                <Field
                                                name="follow_min_price"
                                                component={renderFieldDollar}
                                                label="Min Price *"
                                                autoComplete="off"
                                                type="number"
                                                ref={(Elmt) => { this.follow_min_price = Elmt; }}
                                                normalize={trimvalue}
                                                onChange={this.handleChangeValue}
                                                style={{ width: '95%' }}
                                                disabled={false}
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                />
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.follow_min_price}</span>}
                                            </div>
                                            <div className="col-md-6 col-sm-12" >
                                                <span className="fa fa-dollar form-control-feedback"></span>
                                                <Field
                                                name="follow_max_price"
                                                component={renderFieldDollar}
                                                label="Max Price *"
                                                autoComplete="off"
                                                type="number"
                                                ref={(Elmt) => { this.follow_max_price = Elmt; }}
                                                normalize={trimvalue}
                                                onChange={this.handleChangeValue}
                                                style={{ width: '95%' }}
                                                disabled={false}
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                />
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.follow_max_price}</span>}
                                            </div>
                                        </div>
                                    </div>
                                }
                                </div>
                        </div>
                    ) :
                    (
                        <div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mt-4">
                                        <div className="row">
                                            <div className="col-8">
                                                <Field
                                                    name="min_price"
                                                    component={renderFieldDollar}
                                                    label="Min Price*"
                                                    autoComplete="off"
                                                    type="number"
                                                    ref={(Elmt) => { this.min_price = Elmt; }}
                                                    onChange={this.handleChangeMinPrice}
                                                    normalize={trimvalue}
                                                    disabled={false}
                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                />
                                            </div>
                                            <div className="col-4 update-label" style={{ fontSize: 20, paddingTop: 27 }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="min_price_tbd"
                                                            autoComplete="off"
                                                            checked={this.state.isMinPriceChecked}
                                                            onChange={this.ApproxMinPriceChange}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={'TBD'}
                                                />
                                            </div>
                                            {errors && this.state.isSubmited && <span className="errorvalidation">{errors.min_price}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mt-4">
                                        <div className="row">
                                            <div className="col-8">
                                                <Field
                                                    name="max_price"
                                                    component={renderFieldDollar}
                                                    label="Max Price*"
                                                    autoComplete="off"
                                                    type="number"
                                                    ref={(Elmt) => { this.max_price = Elmt; }}
                                                    onChange={this.handleChangeMaxPrice}
                                                    normalize={trimvalue}
                                                    disabled={false}
                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                />
                                            </div>
                                            <div className="col-4 update-label" style={{ fontSize: 20, paddingTop: 27 }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="max_price_tbd"
                                                            autoComplete="off"
                                                            checked={this.state.isMaxPriceChecked}
                                                            onChange={this.ApproxMaxPriceChange}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={'TBD'}
                                                />
                                            </div>
                                            {errors && this.state.isSubmited && <span className="errorvalidation">{errors.max_price}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mt-4">
                                <div className="row">
                                    <div className={((this.state.offering_type_name !== 'Overnight') ? 'col-8 col-sm-8 prospectus-calender ' : 'col-12 col-sm-12 prospectus-calender ')} >
                                        <label className="update-label TradeDate">Trade Date *</label>
                                        <Calendar classNmae="calendar prospectus-calender-ui" name="trade_date" minDate={this.state.minDate} value={this.state.trade_date_show} onChange={(e) => this.selectTradeDate(e)} style={{height: '42px'}} />
                                    </div>
                                    {(this.state.offering_type_name !== 'Overnight') && <div className="col-sm-4 update-label" style={{ fontSize: 20, paddingTop: 27 }}>
                                       <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="trade_date_tbd"
                                                    autoComplete="off"
                                                    checked={this.state.date_tbd}
                                                    onChange={this.toggleDateTbd}
                                                    color="primary"
                                                />
                                            }
                                            label={'TBD'}
                                        />
                                    </div>}
                                </div>
                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.trade_date}</span>}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mt-4">
                                <div className="row">
                                    <div className={((this.state.offering_type_name !== 'Overnight') ? 'col-8 col-sm-8' : 'col-12 col-sm-12')}>
                                        <label className="update-label TradeDate">Settlement Date *</label>
                                        <Calendar classNmae="prospectus-calender-ui" name="settlement_date" minDate={this.state.minDate} value={this.state.settlement_date_show} onChange={(e) => this.selectSettlementDate(e)} style={{height: '42px'}} />
                                    </div>
                                    {(this.state.offering_type_name !== 'Overnight') && <div className="col-sm-4 update-label" style={{ fontSize: 20, paddingTop: 27 }}>
                                        
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="settlement_date_tbd"
                                                    autoComplete="off"
                                                    checked={this.state.date_tbd}
                                                    onChange={this.toggleDateTbd}
                                                    color="primary"
                                                />
                                            }
                                            label={'TBD'}
                                        />
                                    </div>}
                                </div>
                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.settlement_date}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mt-4">
                                <div className="row">
                                    <div className={((this.state.offering_type_name !== 'Overnight') ? 'col-8 col-sm-8' : 'col-12 col-sm-12')}>
                                        <Field
                                            name="final_shares"
                                            component={renderField}
                                            label="Listed Shares *"
                                            autoComplete="off"
                                            type="number"
                                            ref={(Elmt) => { this.final_shares = Elmt; }}
                                            onChange={this.handleShareChange}
                                            normalize={trimvalue}
                                            disabled={false}
                                        />
                                    </div>
                                    {this.state.offering_type_name !== 'Overnight' &&
                                    <div className="col-md-4 update-label" style={{ fontSize: 20, paddingTop: 27 }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="listed_share_tbd"
                                                    autoComplete="off"
                                                    checked={this.state.isListedChecked}
                                                    onChange={this.listedSharesChange}
                                                    color="primary"
                                                />
                                            }
                                            label={'TBD'}
                                        />
                                    </div>}
                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.final_sharesErr}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mt-4">
                                <label className="update-label" style={{ display: 'block', lineHeight: 1.2 }}>
                                    Industries *
                                </label>
                                <Select
                                    classNamePrefix="allocationofffering"
                                    name="selected_industry"
                                    id="selected_industry"
                                    value={this.state.selected_industry}
                                    onChange={(event) => this.industryHandleChange(event)}
                                    options={indusnewOption}
                                    styles={customStyles}
                                />
                                {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.industry}</span>}
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-3">
                            <div className="mt-4">
                                <label className="update-label" style={{ marginBottom: 15 }}>
                                    Order Type
                                </label>
                                <div className="brochure_url_groupbtn">
                                    <div className="btn-group brochure_url_groupbtn_inner" style={{ paddingTop: 8, paddingRight: 12}}>
                                        Dollar
                                    </div>
                                    <Switch 
                                        onChange={this.handleChangeSwitch} 
                                        checked={this.state.checkedSwitch} 
                                        className="react-switch" 
                                        uncheckedIcon={
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 20,
                                                color: "white",
                                                paddingRight: 2
                                              }}
                                            >
                                            </div>
                                          }
                                          checkedIcon={
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 22,
                                                color: "white",
                                                paddingRight: 2
                                              }}
                                            >
                                            </div>
                                          }
                                        handleDiameter={28}
                                        offColor="#65a214"
                                        onColor="#689F38"
                                        height={40}
                                        width={80}
                                    />
                                    <div className="btn-group brochure_url_groupbtn_inner" style={{ paddingTop: 8, paddingLeft: 12}}>
                                        Shares
                                    </div>
                                </div>
                                {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.ordr_dollar_share}</span>}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mt-4">
                                {
                                    this.state.ordr_dollar_share === 0 ?
                                    <Field
                                        name="min_ticket_size"
                                        component={renderFieldDollar}
                                        label="Min ticket size"
                                        autoComplete="off"
                                        type="number"
                                        onChange={this.handleChangeValue}
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    />
                                    :
                                    <Field
                                        name="min_ticket_size"
                                        component={renderField}
                                        label="Min ticket size"
                                        autoComplete="off"
                                        type="number"
                                        onChange={this.handleChangeValue}
                                    />
                                }
                                {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.min_ticket_size}</span>}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mt-4">
                                {
                                    this.state.ordr_dollar_share === 0 ?
                                    <Field
                                        name="max_ticket_size"
                                        component={renderFieldDollar}
                                        label="Max ticket size"
                                        autoComplete="off"
                                        type="number"
                                        onChange={this.handleChangeValue}
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    />
                                    :
                                    <Field
                                        name="max_ticket_size"
                                        component={renderField}
                                        label="Max ticket size"
                                        autoComplete="off"
                                        type="number"
                                        onChange={this.handleChangeValue}
                                    />
                                }
                                {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.max_ticket_size}</span>}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mt-4">
                                <label className="update-label">
                                    Increment (Dollars or Shares)
                                </label>
                                <NumberFormat 
                                   value={this.state.unit_increment} 
                                   decimalScale={0} 
                                   allowNegative={false} 
                                   allowLeadingZeros={true} 
                                   isNumericString={true} 
                                   onValueChange={
                                       (values) => {
                                           const {value} = values;
                                           this.setState({unit_increment: value === '' ? 1 : value})
                                       }
                                   } 
                                   className="form-control"  
                                   style={{ width:' 100%',  borderRadius: '4px', marginTop: 0}}
                                />
                                {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.unit_increment}</span>}
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-12">
                            <div className="mt-4">
                                <Field
                                    name="description"
                                    component={renderTextareaField}
                                    label="Description *"
                                    autoComplete="off"
                                    onChange={this.handleChangeValue}
                                />
                                {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.description}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="mt-4">
                                <label className="update-label" style={{ display: 'block', lineHeight: 1.2 }}>
                                    Underwriter MPID *
                                </label>
                                <Select
                                    classNamePrefix="allocationofffering"
                                    name="selected_bd_underwriter"
                                    id="bd_underwriter_mpid_id"
                                    value={this.state.selected_bd_underwriter_mpid_id}
                                    ref={(select) => { this.bd_underwriter_mpid_id = select; }}
                                    onChange={(event) => this.mpIDhandleChange(event)}
                                    options={typeAheadUserMpidList}
                                    styles={customStyles}
                                />
                                {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.bd_underwriter_mpid_id}</span>}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mt-4">
                                <div>
                                    <label className="update-label">Underwriter concession amount *</label>
                                    <TextField className="offering_create_input" type="number" name="underwriter_concession_amount" value={this.state.underwriter_concession_amount} onChange={this.goChange} onClick={this.handleEndEditing} disabled={false} />
                                </div>
                                {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.underwriter_concession_amount}</span>}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mt-4">
                                <label className="update-label">Underwriter(s)</label>
                                <MultiSelect optionLabel="executing_broker_mpid" filter value={this.state.selectedUnderwriter} options={userMpidUnderwriters} placeholder={'Select multiple underwriter(s)'} onChange={this.underwriterHandle} style={{ width: '100%', border: '1px solid #ced4da', height: '46px' }} styles={customStyles}/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mt-4">
                                <label className="update-label">
                                    Blocked Broker Dealers
                                </label>
                                <MultiSelect
                                    optionLabel={'name'}
                                    options={brokerDealerList}
                                    value={this.state.blocked_bd_list}
                                    filter
                                    placeholder={'Multiple Broker Dealers'}
                                    onChange={this.blockedBdHandle}
                                    style={{ width: '100%', border: '1px solid #ced4da', height: '46px' }} 
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="mt-4">
                                <Field
                                    name="prospectus_url"
                                    component={renderField}
                                    label="Prospectus link *"
                                    autoComplete="off"
                                    type="text"
                                    onChange={this.handleChangeValue}
                                />
                                {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.prospectus_url}</span>}
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="mt-4">
                                <div className="brochure_url_groupbtn">
                                    <div style={{width: '80%'}}>
                                        <label className="update-label">
                                            Brochure Link Option
                                        </label>
                                        <div className="btn-group brochure_url_groupbtn_inner" role="group" aria-label="Basic example">
                                            <button type="button" className={"btn btn-brochure_url_groupbtn" + (this.state.showUrlField ? ' brochure_url_groupbtn-active': '')} onClick={() => this.changeBrochureMode('url')}><EditIcon style={{marginRight: 10}} /> URL</button>
                                            <button type="button" className={"btn btn-brochure_url_groupbtn" + (this.state.showFileUploadField ? ' brochure_url_groupbtn-active': '')} onClick={() => this.changeBrochureMode('file')}><CloudUploadIcon  style={{marginRight: 10}}/> Upload</button>
                                        </div>
                                    </div>
                                    {
                                    (!this.state.isEnterTickerSymbol && this.state.showUrlField) &&
                                        <div style={{ display: 'block', width: '80%' }}>
                                            <Field
                                                name="brochure_url"
                                                component={renderField}
                                                label="Brochure Link"
                                                autoComplete="off"
                                                type="text"
                                                ref={(Elmt) => { this.brochure_url = Elmt; }}
                                                onChange={this.handleChangeValue}
                                                disabled={false}
                                            />
                                            {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.brochure_url}</span>}
                                        </div>
                                    }
                                    {
                                        (!this.state.isEnterTickerSymbol && this.state.showFileUploadField) &&
                                        <div style={{ display: 'block', width: '70%', marginTop: 30 }}>
                                            <input type="file" style={{width:'92%'}} onChange={(e) => { this.chooseBrochureFile(e) }} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mt-4">
                                <label className="update-label">
                                    Exchange *
                                </label>
                                <FormControl variant="outlined" fullWidth>
                                    <SelectMaterial
                                        name="exchange_value_name"
                                        value={this.state.exchange_value_name}
                                        onChange={this.handleChangeExchange}
                                    >
                                        <MenuItem value={'XNYS'}>XNYS - NEW YORK STOCK EXCHANGE</MenuItem>
                                        <MenuItem value={'XNAS'}>XNAS - NASDAQ GLOBAL / CAPITAL / GLOBAL SELECT MARKET</MenuItem>
                                    </SelectMaterial>
                                </FormControl>
                                {errors && this.state.isSubmited && <span className="errorvalidation1">{errors.exchange_value_nameErr}</span>}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mt-4">
                                <label className="update-label">
                                    Availability
                                </label>
                                <FormControl variant="outlined" fullWidth>
                                    <SelectMaterial
                                        name="available_to_order"
                                        value={this.state.available_to_order}
                                        onChange={this.toggleChange}
                                    >
                                        <MenuItem value={'1'}>Available To Order</MenuItem>
                                        <MenuItem value={'0'}>Not available to order</MenuItem>
                                        <MenuItem value={'2'}>Order Book Closed</MenuItem>
                                    </SelectMaterial>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    {(this.state.offering_type_name !== 'Overnight') &&
                        <div className="row" style={{ marginTop: 35, border: '1px solid #ddd', marginLeft: 0, marginRight: 0, padding: '20px 0px', width: '100%' }}>
                            <div className="col-md-3 col-sm-12 update-label" style={{ fontSize: 18, padding: '10px 20px 5px 20px', marginTop: 14 }}>
                                <FormGroup row>
                                    {/* <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.isDirectedShare}
                                                onChange={this.selectCheckboxDirectedShare}
                                                name="directedShare"
                                                color="primary"
                                            />
                                        }
                                        label="Directed Allocation"
                                    /> */}
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.isDsp}
                                                onChange={this.selectCheckbox}
                                                name="dsp"
                                                color="primary"
                                            />
                                        }
                                        label="DSP"
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-md-3 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
                                <label className="update-label" style={{ marginBottom: 15 }}>DSP attestation file *</label>
                                <div className="file-upload-boxes">
                                    <span id="filename" className={(this.state.isDsp || this.state.isDirectedShare ? " " : "dsp_file_disable_text")}>{this.state.attestationFileName}</span>
                                    <label htmlFor="file-upload-attestation" className={(this.state.isDsp || this.state.isDirectedShare ? " " : "dsp_file_disable")}>Browse<input type="file" onChange={this.selectAttestationFile} name="dsp_attestation_file" id="file-upload-attestation" /></label>
                                </div>
                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.dsp_attestation_file}</span>}
                            </div>
                            <div className="col-md-3 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
                                <label className="update-label" style={{ marginBottom: 15 }}>DSP faq file</label>
                                <div className="file-upload-boxes ">
                                    <span id="filename" className={(this.state.isDsp || this.state.isDirectedShare ? "  " : "dsp_file_disable_text")}>{this.state.faqFileName}</span>
                                    <label htmlFor="file-upload-faq" className={(this.state.isDsp || this.state.isDirectedShare ? " " : "dsp_file_disable")}>Browse<input type="file" onChange={this.selectFaqFile} name="dsp_faq_file" id="file-upload-faq" /></label>
                                </div>

                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.dsp_faq_file}</span>}
                            </div>
                            {/* {(this.state.isDsp) &&
                                <div className="col-md-3 col-sm-12" >
                                    <label className="update-label" style={{ padding: '0px 0px 0px 20px' }} >
                                        IOI Cutoff Datetime
                                    </label>
                                    <div style={{ padding: '0px 0px 0px 0px', marginTop: 8 }} >
                                        <Calendar
                                            name="ioi_cutoff_datetime"
                                            minDate={this.state.minDate}
                                            value={this.state.ioi_cutoff_datetime_show}
                                            showTime
                                            showSeconds
                                            onChange={(e) => this.selectIoiCutoff(e)}
                                            className="update-input"
                                            disabled={!this.state.isDsp}
                                        />
                                        {errors && this.state.isSubmited &&
                                            <span className="errorvalidation">
                                                {errors.ioi_cutoff_datetime}
                                            </span>
                                        }
                                    </div>
                                </div>
                            } */}
                        </div>
                    }
                    {(this.state.offering_type_name === 'Overnight') &&
                        <div className="row">
                        {/* if spot or block is selected show this div below. this is the active window */}
                          <div className="col-md-6 col-sm-12">
                            <Field
                              name="activeWindow"
                              component={renderField}
                              label="Active window in minutes *"
                              autoComplete="off"
                              type="text"
                              ref={(Elmt) => { this.activeWindow = Elmt; }}
                              onChange={this.handleChangeValue}
                              disabled={false}
                            />
                            {errors && this.state.isSubmited && <span className="errorvalidation">{errors.activeWindow}</span>}
                          </div>
                        </div>
                    }
                    <div className="row mt-4">
                        <div className="col-12 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
                            <label className="update-label" style={{ marginBottom: 15 }}>
                                Offering Logo
                            </label>
                            <div className="row">
                                <div className="col-12 col-sm-12" style={{ padding: '5px 8px 5px 8px', color: 'rgba(0, 0, 0, 0.3)' }}>
                                    
                                    <Field
                                        name="file"
                                        type="file"
                                        component={customFileInput}
                                        ref={(ref) => { this.file = ref; }}
                                        onChange={this.selectInputFile}
                                    />
                                    {
                                        this.state.previewLogo && 
                                        <img style={{ width: 150, marginTop: 15 }} src={this.state.previewLogo} width="100" height="100" alt="" />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-4 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}></div>
                    </div>
                    <div className="row" style={{ marginTop: 35, border: '1px solid #ddd', marginLeft: 0, marginRight: 0, width: '100%' }}>
                        <div className="col-md-12 col-sm-12 update-label-checkbox" style={{ fontSize: 14, padding: '10px 20px 5px 20px', marginTop: 14 }}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={this.selectCheckboxRequired}
                                            name="requiredCheckboxOne"
                                            color="primary"
                                            checked={this.state.requiredCheckboxOne}
                                        />
                                    }
                                    label="The underwriter acting as the distribution agent for the offering is approved by FINRA to act as an underwriter."
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={this.selectCheckboxRequired}
                                            name="requiredCheckboxTwo"
                                            color="primary"
                                            checked={this.state.requiredCheckboxTwo}
                                        />
                                    }
                                    label="The underwriter acting as the distribution agent for the offering is a contracted underwriter with Click IPO, having signed a MSDA or MAAU agreement with Click IPO."
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={this.selectCheckboxRequired}
                                            name="requiredCheckboxThree"
                                            color="primary"
                                            checked={this.state.requiredCheckboxThree}
                                        />
                                    }
                                    label="The underwriter has been reviewed and accepted through Click IPO’s written due diligence review process."
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={this.selectCheckboxRequired}
                                            name="requiredCheckboxFour"
                                            color="primary"
                                            checked={this.state.requiredCheckboxFour}
                                        />
                                    }
                                    label="The offering is an SEC registered public offering."
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={this.selectCheckboxRequired}
                                            name="requiredCheckboxFive"
                                            color="primary"
                                            checked={this.state.requiredCheckboxFive}
                                        />
                                    }
                                    label="The offering document (prospectus) has been reviewed to assure all components of the securities being offered are Depository Trust & Clearing Corp (DTCC) eligible for clearance and settlement."
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={this.selectCheckboxRequired}
                                            name="requiredCheckboxSix"
                                            color="primary"
                                            checked={this.state.requiredCheckboxSix}
                                        />
                                    }
                                    label="The prospectus does not restrict U S [sic] investors from purchasing such securities being offered."
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={this.selectCheckboxRequired}
                                            name="requiredCheckboxSeven"
                                            color="primary"
                                            checked={this.state.requiredCheckboxSeven}
                                        />
                                    }
                                    label="The offering is suitable for some investors (as required by FINRA)."
                                />
                            </FormGroup>
                            {
                                errors && this.state.isSubmited &&
                                <div className="errorvalidation" style={{lineHeight: '22px', paddingBottom: '14px'}}>
                                    {errors.allCheckBoxedRequired}
                                </div>
                            }
                        </div>
                        {/* <div className="col-md-6 col-sm-12 update-label" style={{ fontSize: 18, padding: '10px 20px 5px 20px', marginTop: 14 }}>

                        </div> */}
                    </div>
                    <div>
                        <div className="col-12">
                            <div style={{textAlign: 'center'}}>
                                <Button variant="contained" color="primary" style={{ minWidth: 100, marginLeft: 10, marginRight: 10 }} onClick={() => { this.onOpenModal(); }}>
                                    Submit
                                </Button>
                                <Button variant="contained" color="primary" style={{ minWidth: 100, marginLeft: 10, marginRight: 10 }} onClick={() => {this.resetForm()}}>
                                    Reset
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                
                <Dialog
                    onClose={this.onCloseModal}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.openConfirmation}
                    maxWidth={"md"}
                    fullWidth={true}
                    scroll={'paper'}
                    >
                    <DialogTitle id="customized-dialog-title" onClose={this.onCloseModal}>
                        Offering Review
                    </DialogTitle>
                    <DialogContent>
                        <div className="col-12" style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 15 }} ref={el => (this.componentRef = el)}>
                            <div className="row" >
                                <div className="col-md-6 sidelabel">
                                    Company name
                                    </div>
                                <div className="col-md-6 rightlabel">
                                    {this.state.name}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 sidelabel" >
                                    Offering Type
                                </div>
                                <div className="col-md-6 rightlabel">
                                    {this.state.offering_type_name}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 sidelabel" >
                                    Ticker Symbol
                                </div>
                                <div className="col-md-6 rightlabel">
                                    {this.state.ticker_symbol}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 sidelabel" >
                                    CUSIP ID
                                </div>
                                <div className="col-md-6 rightlabel">
                                    {this.state.cusip_id}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 sidelabel" >
                                    EXT ID
                                </div>
                                <div className="col-md-6 rightlabel">
                                    {this.state.ext_id}
                                </div>
                            </div>
                            {this.state.offering_type_name === 'IPO' &&
                                <div>
                                    <div className="row">
                                        <div className="col-md-6 sidelabel" >
                                            Min price
                                </div>
                                        <div className="col-md-6 rightlabel">
                                            {this.state.min_price ? numberWithCommasAndDollar(this.state.min_price) : 'TBD'}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 sidelabel" >
                                            Max price
                                </div>
                                        <div className="col-md-6 rightlabel">
                                            {this.state.max_price ? numberWithCommasAndDollar(this.state.max_price) : 'TBD'}
                                        </div>
                                    </div>
                                </div>
                            }
                            {(this.state.offering_type_name === 'Follow-on' || this.state.offering_type_name === 'ETF' || this.state.offering_type_name === 'UIT') &&
                                <div className="row">
                                    <div className="col-6 sidelabel" >
                                        Last closing price
                                    </div>
                                    <div className="col-6 rightlabel">
                                        {this.state.max_price ? numberWithCommasAndDollar(this.state.max_price) : 'TBD'}
                                    </div>
                                </div>
                            }
                            {(this.state.offering_type_name === 'Overnight') &&
                                <div>
                                    <div className="row">
                                        <div className="col-6 sidelabel" >
                                            Last closing price
                                        </div>
                                        <div className="col-6 rightlabel">
                                            {this.state.max_price ? numberWithCommasAndDollar(this.state.max_price) : 'TBD'}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 sidelabel" >
                                            Price range
                                        </div>
                                        <div className="col-6 rightlabel">
                                            {numberWithCommasAndDollar(this.state.follow_min_price)} - {numberWithCommasAndDollar(this.state.follow_max_price)}
                                            {/* {this.state.price_range ? '$' : ''}{this.state.price_range} */}
                                        </div>
                                    </div>
                                </div>
                            }
                            {(this.state.offering_type_name !== 'Overnight') &&
                                <div>
                                    <div className="row">
                                        <div className="col-6 sidelabel" >
                                            Price range
                                        </div>
                                        {
                                            (this.state.isMinPriceChecked) && (this.state.isMaxPriceChecked) &&
                                            <div className="col-6 rightlabel">
                                                null
                                            </div>
                                        }
                                        {
                                            (!this.state.isMinPriceChecked) && (!this.state.isMaxPriceChecked) &&
                                            <div className="col-6 rightlabel">
                                                {numberWithCommasAndDollar(this.state.min_price)} - {numberWithCommasAndDollar(this.state.max_price)}
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className="col-md-6 sidelabel" >
                                    Trade  Date
                                </div>
                                <div className="col-md-6 rightlabel">
                                    {
                                        this.state.trade_date &&
                                        <div>{this.state.trade_date}</div>
                                    }
                                    {
                                        !this.state.trade_date &&
                                        <div>TBD</div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 sidelabel" >
                                    Settlement Date
                                </div>
                                <div className="col-md-6 rightlabel">
                                    {
                                        this.state.settlement_date &&
                                        <div>{this.state.settlement_date}</div>
                                    }
                                    {
                                        !this.state.settlement_date &&
                                        <div>TBD</div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 sidelabel" >
                                    Listed Shares
                                </div>
                                <div className="col-md-6 rightlabel">
                                    {
                                        this.state.final_shares &&
                                        <div>{numberWithCommas(Number(this.state.final_shares))}</div>
                                    }
                                    {
                                        !this.state.final_shares &&
                                        <div>TBD</div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 sidelabel" >
                                    Industry
                                </div>
                                <div className="col-md-6 rightlabel">
                                    {this.state.industry}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 sidelabel" >
                                    Min ticket size
                                </div>
                                <div className="col-md-6 rightlabel">
                                    {
                                        this.state.ordr_dollar_share === 0 ? 
                                        <div>{numberWithCommasAndDollar(Number(this.state.min_ticket_size))}</div>
                                        :
                                        <div>{this.state.min_ticket_size}</div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 sidelabel" >
                                    Max ticket size
                                </div>
                                <div className="col-md-6 rightlabel">
                                    {
                                        this.state.ordr_dollar_share === 0 ? 
                                        <div>{numberWithCommasAndDollar(Number(this.state.max_ticket_size))}</div>
                                        :
                                        <div>{this.state.max_ticket_size}</div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 sidelabel" >
                                    Increment 
                                </div>
                                <div className="col-md-6 rightlabel">
                                    {
                                        <div>{this.state.unit_increment}</div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 sidelabel" >
                                    Description
                                </div>
                                <div className="col-md-6 rightlabel">
                                    {this.state.description}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 sidelabel" >
                                    Underwriter MPID
                                </div>
                                <div className="col-md-6 rightlabel">
                                    {this.state.bd_underwriter_mpid_id}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 sidelabel" >
                                    Underwriter concession amount
                                </div>
                                <div className="col-md-6 rightlabel">
                                    {this.state.underwriter_concession_amount}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 sidelabel" >
                                    Underwriter(s)
                                </div>
                                <div className="col-md-6 rightlabel">
                                    <div>
                                        { this.state.selectedUnderWritersList && this.state.selectedUnderWritersList.map((item, i) => (
                                        <span key={i}>{item}{(this.state.selectedUnderWritersList.length - 1) !== i ? ', ' : ''} </span>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 sidelabel" >
                                    Exchange
                                </div>
                                <div className="col-md-6 rightlabel">
                                    {this.state.exchange_value_name}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 sidelabel" >
                                    Prospectus Link
                                </div>
                                <div className="col-md-6 rightlabel" style={{wordWrap:'break-word'}}>
                                    {this.state.prospectus_url === '' ? 'null' : this.state.prospectus_url}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 sidelabel" >
                                    Brochure Link
                                </div>
                                <div className="col-md-6 rightlabel" style={{wordWrap:'break-word'}}>
                                    {this.state.brochure_url === '' ? 'null' : this.state.brochure_url}
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-md-6 sidelabel" >
                                    Blocked Broker Dealers 
                                </div>
                                <div className="col-md-6 rightlabel">
                                    <div>
                                        { this.state.selectedBrokerDealersList && this.state.selectedBrokerDealersList.map((item, i) => (
                                        <span key={i}>{item}{(this.state.selectedBrokerDealersList.length - 1) !== i ? ', ' : ''} </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {
                                (this.state.isDsp) &&

                                <div className="row">
                                    <div className="col-md-6 sidelabel" >
                                        DSP attestation file
                                    </div>
                                    <div className="col-md-6 rightlabel" style={{wordWrap:'break-word'}}>
                                        {this.state.dsp_attestation_file}
                                    </div>
                                </div>
                            }
                            {
                                (this.state.isDsp) &&

                                <div className="row">
                                    <div className="col-md-6 sidelabel" >
                                        DSP faq file
                                    </div>
                                    <div className="col-md-6 rightlabel" style={{wordWrap:'break-word'}}>
                                        {this.state.dsp_faq_file}
                                    </div>
                                </div>
                            }
                            {
                                (this.state.previewLogo) && 
                                <div className="row">
                                    <div className="col-md-6 sidelabel" >
                                        Logo
                                    </div>
                                    <div className="col-md-6 rightlabel">
                                        <img style={{ width: 150, marginTop: 15 }} src={this.state.previewLogo} width="100" height="100" alt="" />
                                    </div>
                                </div>
                            }
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <div className="text_center">
                            <Button variant="contained" color="primary" style={{ minWidth: 100, marginLeft: 10, marginRight: 10 }} onClick={() => {this.handleFormSubmit(null)}}>
                                Submit
                            </Button>
                            <Button variant="contained" color="primary" style={{ minWidth: 100, marginLeft: 10, marginRight: 10 }} onClick={() => {this.onCloseModal()}}>
                                Cancel
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

CreateOfferingForm.propTypes = {
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    submitCreateOffering: PropTypes.func,
    offeringIndustriesListRes: PropTypes.any,
    underWriterMpidListRes: PropTypes.any,
    lastClosingPriceRes: PropTypes.any,
    brochureUrlRes: PropTypes.any,
    attestationUrlRes: PropTypes.any,
    faqUrlRes: PropTypes.any,
    brokerDealerListRes: PropTypes.any,
    fetchOfferingAssetTypeRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    offeringIndustriesListRes: offeringIndustriesListRes,
    underWriterMpidListRes: underWriterMpidListRes,
    lastClosingPriceRes: lastClosingPriceRes,
    brochureUrlRes: brochureUrlRes,
    attestationUrlRes: attestationUrlRes,
    faqUrlRes: faqUrlRes,
    brokerDealerListRes: doBrokerDealerListRes,
    fetchOfferingAssetTypeRes: doFetchOfferingAssetTypeRes,
});

function mapDispatchToProps(dispatch, ownProps) {
    return {
        changeFieldValue: (field, value) => { dispatch(change('ReduxCreateOfferingForm', field, value)); },
        submitCreateOffering: (data, file) => dispatch(submitCreateOffering(data, file)),
        getBrokerDealerList: () => dispatch(getBrokerDealerList()),
        getOfferingIndustriesList: () => dispatch(getOfferingIndustries()),
        getUnderwriterMpidList: () => dispatch(getUnderwriterMpid()),
        lastClosingPrice: (data) => dispatch(fetchLastClosingPriceData(data)),
        getBrochureUrl: (data, ticker_symbol, type) => dispatch(getBrochureUrl(data, ticker_symbol, type)),
        getOfferingAssetType: () => dispatch(fetchOfferingAssetType()),
    };
}

const ReduxCreateOfferingForm = reduxForm({
    form: 'ReduxCreateOfferingForm',
    validate,
    fields,
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnBlur: false,
})(CreateOfferingForm)

export default connect(mapStateToProps, mapDispatchToProps)(ReduxCreateOfferingForm);
