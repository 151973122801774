import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import './stats.scss';
import { getStatsFollowerList, doFetchStatsFollowerListRes } from '../../actions/statsFollowerListActions';
import { getBizInsightsList, doFetchBizInsightsListRes } from '../../actions/bizInsightsListActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import GridTable from '@nadavshaar/react-grid-table';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { saveFile } from '../../helper/helper';

const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
	}))(props => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});
  
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);
let bizInsightData;
let filterData = [];
class AllocationListComponent extends React.PureComponent {

	constructor() {
		super();
		
		const searchingConnectedCust = '';
		const searching = '';
		const filterByConnCus = '';
		const filterByAllCus = '';
		const columns = [
			{ id: 1, width: '20%', field: 'name', label: 'Name', visible: true, sortable: true, cellRenderer: this.offeringNameLink },
			{ id: 2, width: '20%', field: 'followers', label: 'No. of People Interested', visible: true, sortable: true, cellRenderer: this.offeringFollowerLink },
			{ id: 3, width: '20%', field: 'orders', label: 'No. of Orders', visible: true, sortable: true, cellRenderer: this.offeringDollersign },
			{ id: 4, width: '20%', field: 'bizinsight_count', label: 'No. of BizInsight', visible: true, sortable: true, cellRenderer: this.offeringBizInsight },
			{ id: 5, width: '20%', field: 'trade_date', label: 'Trade Date ', visible: true, sortable: true, cellRenderer: this.tradeDateTemp },
		];
		const followerColumns = [
			{ id: 1, width: '50%', field: 'first_name', label: 'Name', visible: true, sortable: true, cellRenderer: this.followerNameTemp },
			{ id: 2, width: '50%', field: 'email', label: 'email', visible: true, sortable: true, cellRenderer: this.followerEmailTemp },
		];
		this.state = {
            loading: false,
			cols: columns,
			followerCols: followerColumns,
			sort: {
				searchingConnectedCust: searchingConnectedCust,
				searching: searching,
				type: null,
				field: null,
				OfferingModal: null,
				notificationshow: null,
				offeringshow: null,
				notificationType: '',
				selectedCustomerList: {},
				confirmModal: null,
				confirmationType: '',
				customerInfo: '',
				searchingMessage: null,
				filterByConnCus: filterByConnCus,
				filterByAllCus: filterByAllCus
			},
			checkCustomerData: true,
			checkOfferingData: true,
			openFollowerModal: false,
			openBizInsightModal: false,
			renderUI: false,
			offeringStatus: 'active',
			searching:'',
			level: 0,
			followerlist: [], 
			// allOfferingList: [],
			bizInsightCount: []
		}
	}

	componentDidMount() {
		let userLevel = localStorage.getItem('level');
		this.setState({
			level: userLevel
		}, () => {});
	}

	static getDerivedStateFromProps(props, state) {
		if (props.fetchAllOfferingsRes) {
			if (props.fetchAllOfferingsRes.data && props.fetchAllOfferingsRes.data.allOfferings) {
				if (props.fetchAllOfferingsRes.data.allOfferings.message === 'Success') {
					let arrData = props.fetchAllOfferingsRes.data.allOfferings.data;
                    for (let i = 0; i < arrData.length; i++) {

                        Object.keys(arrData[i]).forEach((field) => { /*eslint-env es6*/
                        if (arrData[i][field] === null) {
                            arrData[i][field] = "";
                        }
                        })
                    };
					return {
						allOfferingList: arrData,
					};
				}
			}
		}
		
		if (props.fetchStatsFollowerListRes) {
			if (props.fetchStatsFollowerListRes.data && props.fetchStatsFollowerListRes.data.statsFollowerList) {
				if (props.fetchStatsFollowerListRes.data.statsFollowerList.message === 'Success') {
					let arrayData = props.fetchStatsFollowerListRes.data.statsFollowerList.data;
                    for (let i = 0; i < arrayData.length; i++) {

                        Object.keys(arrayData[i]).forEach((field) => { /*eslint-env es6*/
                        if (arrayData[i][field] === null) {
                            arrayData[i][field] = "";
                        }
                        })
                    };
					return {
						followerlist: arrayData,
						loading: false
					};
				}
			}
		}
		if (props.fetchBizInsightsListRes) {
			if (props.fetchBizInsightsListRes.data && props.fetchBizInsightsListRes.data.bizInsightsList) {
				if (props.fetchBizInsightsListRes.data.bizInsightsList.message === 'Success') {
					let arData = props.fetchBizInsightsListRes.data.bizInsightsList.data;
                    for (let i = 0; i < arData.length; i++) {

                        Object.keys(arData[i]).forEach((field) => { /*eslint-env es6*/
                        if (arData[i][field] === null) {
                            arData[i][field] = "";
                        }
                        })
                    };
					return {
						bizInsightCount: arData,
						loading: false
					};
				}
			}
		}
		return null;
	}
	opencollapse = (data) => {
		for (let item of bizInsightData) {
			if (item.email !== data.email) {
				item.showDates = false;
			}
		}
		if (data.showDates !== true) {
			data.showDates = true;
		} else if (data.showDates === true) {
			data.showDates = false;
		}
		this.setState({
			renderUI: !this.state.renderUI
		}, () => { });
	}
	followerNameTemp = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return  <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
			<Tooltip title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
				<div className="text-one-line" style={{width: '90%'}} >{value}</div>
			</Tooltip>
			{
				value !== '' &&
				<Tooltip title={'Copy to clipboard'} interactive >
					<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
				</Tooltip>
			}
		</div>;
	}
	followerEmailTemp = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return  <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
			<Tooltip title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
				<div className="text-one-line" style={{width: '90%'}} >{value}</div>
			</Tooltip>
			{
				value !== '' &&
				<Tooltip title={'Copy to clipboard'} interactive >
					<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
				</Tooltip>
			}
		</div>;
	}
	offeringFollowerLink = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		if (this.state.level === 1) {
			return <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
			<Tooltip title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
				<div className="text-one-line" style={{width: '90%'}} >{value}</div>
			</Tooltip>
			{
				value !== '' &&
				<Tooltip title={'Copy to clipboard'} interactive >
					<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
				</Tooltip>
			}
		</div>;
		} else {
			if (data.followers > 0) {
				return <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
					<Tooltip title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
						<div className="text-one-line green-color-with-pointer" style={{width: '90%'}} onClick={() => this.toggleOfferingModal(data.id)}>{value}</div>
					</Tooltip>
					{
						value !== '' &&
						<Tooltip title={'Copy to clipboard'} interactive >
							<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
						</Tooltip>
					}
				</div>;
			} else {
				return <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
				<Tooltip title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
					<div className="text-one-line" style={{width: '90%'}} >{value}</div>
				</Tooltip>
				{
					value !== '' &&
					<Tooltip title={'Copy to clipboard'} interactive >
						<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
					</Tooltip>
				}
			</div>;
			}
		}
	}
	tradeDateTemp = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return  <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
			<Tooltip title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
				<div className="text-one-line" style={{width: '90%'}} >{value}</div>
			</Tooltip>
			{
				value !== '' &&
				<Tooltip title={'Copy to clipboard'} interactive >
					<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
				</Tooltip>
			}
		</div>;
	}
	offeringNameLink = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return  <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
			<Tooltip title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
				<div className="text-one-line" style={{width: '90%'}} >{value}</div>
			</Tooltip>
			{
				value !== '' &&
				<Tooltip title={'Copy to clipboard'} interactive >
					<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
				</Tooltip>
			}
		</div>;
	}
	offeringDollersign = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		if (this.state.level === 1) {

			return <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
				<Tooltip title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
					<div className="text-one-line" style={{width: '90%'}} >{value}</div>
				</Tooltip>
				{
					value !== '' &&
					<Tooltip title={'Copy to clipboard'} interactive >
						<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
					</Tooltip>
				}
			</div>;
		} else {
			if (data.orders > 0) {
				return <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
					<Tooltip title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
						<div className="text-one-line" style={{width: '90%'}} ><Link className="green-color-with-pointer" to={{ pathname: `/buildout/${data.id}`, state: { offeringData: data } }}>{data.orders}</Link></div>
					</Tooltip>
					{
						value !== '' &&
						<Tooltip title={'Copy to clipboard'} interactive >
							<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
						</Tooltip>
					}
				</div>;
			} else {
				return <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
					<Tooltip title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
						<div className="text-one-line" style={{width: '90%'}} >{value}</div>
					</Tooltip>
					{
						value !== '' &&
						<Tooltip title={'Copy to clipboard'} interactive >
							<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
						</Tooltip>
					}
				</div>;
			}
		}
	}

	offeringBizInsight = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		if (this.state.level === 1) {

			return <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
					<Tooltip title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
						<div className="text-one-line" style={{width: '90%'}} >{value}</div>
					</Tooltip>
					{
						value !== '' &&
						<Tooltip title={'Copy to clipboard'} interactive >
							<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
						</Tooltip>
					}
				</div>;
		} else {
			if (data.bizinsight_count > 0) {
				return <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
					<Tooltip title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
						<div className="text-one-line green-color-with-pointer" style={{width: '90%'}} onClick={() => this.toggleOfferingBizInsight(data.id)}>{value}</div>
					</Tooltip>
					{
						value !== '' &&
						<Tooltip title={'Copy to clipboard'} interactive >
							<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
						</Tooltip>
					}
				</div>;
			} else {
				return <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
					<Tooltip title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
						<div className="text-one-line" style={{width: '90%'}} >{value}</div>
					</Tooltip>
					{
						value !== '' &&
						<Tooltip title={'Copy to clipboard'} interactive >
							<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
						</Tooltip>
					}
				</div>;
			}
		}
	}

	closeFollowerModal = () => {
		this.props.doFetchStatsFollowerListRes(null);
		this.setState({
			openFollowerModal: false,
			followerlist: []
		});
	}

	toggleOfferingModal = (id) => {
		this.setState({
			openFollowerModal: true,
			loading: true
		});
		this.props.getStatsFollowerList(id);
	}

	closeBizInsightModal = () => {
		this.props.doFetchBizInsightsListRes(null);
		this.setState({
			openBizInsightModal: false,
			renderUI: false,
			bizInsightCount: []
		});
	}
	toggleOfferingBizInsight = (id) => {
		this.setState({
			openBizInsightModal: true,
			loading: true
		});
		this.props.getBizInsightsList(id);
	}
	openSendEmail = () => {
		this.setState({
			notificationshow: true,
			notificationType: 'EMAIL',
		});
	}
	openSendPush = () => {
		this.setState({
			notificationshow: true,
			notificationType: 'PUSH',
		});
	}
	searchOffering = (event) => {
		this.setState({
			searching: event.target.value,
		}, () => { });
	}
	downLoadCSV = async (csvData, fileName) => {
		let csvContent = '';
		csvContent += 'Name, BizInsights Count\r\n';
		csvData.forEach(function (rowArray) {
			const newRow = rowArray.name + ',' + rowArray.bizinsight_count.toString();
			csvContent += newRow + '\r\n'; // add carriage return
		});
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
		await saveFile(blob, fileName + '.csv');
	}
	handleChangeStatus = (e) => {
		this.setState({
			offeringStatus: e.target.value
		});
	}
	customHeader = ({tableManager}) => {
		return (
		<div style={{borderBottom: 'var(--rgt-border)'}}>
			<div className="col-md-12">
				<div className="offeringlist-stats-header">
					{filterData && filterData.length > 0 &&
						<div style={{marginRight: 15}}>
							<Button variant="contained" color="primary" onClick={() => this.downLoadCSV(filterData, 'bizInsightsCSV')}>Biz Insights CSV</Button>
						</div>
					}
					<div className="admin-offering-multiselectbox offering-status-select" style={{marginRight: 15}}>
						<FormControl variant="outlined" fullWidth>
							<Select
								value={this.state.offeringStatus}
								onChange={this.handleChangeStatus}
							>
								<MenuItem value="active">Active</MenuItem>
								<MenuItem value="effective">Effective</MenuItem>
								<MenuItem value="cancelled">Cancelled</MenuItem>
								<MenuItem value="closed">Closed</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div className="admin-offering-searchbox">
						<TextField type="text" id="searchInput" placeholder="Search" defaultValue={this.state.searching} onChange={this.searchOffering} />
					</div>
				</div>
			</div>
		</div>
		)
	}
	render() {
		const { followerlist, bizInsightCount } = this.state;
		const { allOfferingList } = this.props;
		bizInsightData = bizInsightCount;
		let filterByStatus=[];
		if (allOfferingList) {
			for (let items of allOfferingList) {
				if (this.state.offeringStatus === 'active' && items.status === 40) {
					filterByStatus.push(items);
				}
				if (this.state.offeringStatus === 'closed' && items.status === 60) {
					filterByStatus.push(items);
				}
				if (this.state.offeringStatus === 'cancelled' && items.status === 50) {
					filterByStatus.push(items);
				}

				if (this.state.offeringStatus === 'effective' && items.status === 70) {
					filterByStatus.push(items);
				}
			}
		}
		if (this.state.offeringStatus === 'active' ) {
			filterByStatus.sort((a,b) => {
				return new Date(b.created_at) - 
					new Date(a.created_at)
			});
		} else {
			filterByStatus.sort((a,b) => {
				return new Date(a.trade_date) - 
					new Date(b.trade_date)
			});
		}
		// filterData = this.state.searching ? filterByStatus.filter(row => row.name.toLowerCase().indexOf(this.state.searching.toLowerCase()) > -1) : filterByStatus;

		filterData = this.state.searching ? filterByStatus.filter(value => {
			return (
				value.name.toString().toLowerCase().includes(this.state.searching.toLowerCase()) ||
				value.followers.toString().toLowerCase().includes(this.state.searching.toLowerCase()) ||
				value.orders.toString().toLowerCase().includes(this.state.searching.toLowerCase()) ||
				value.bizinsight_count.toString().toLowerCase().includes(this.state.searching.toLowerCase()) ||
				value.trade_date.toString().toLowerCase().includes(this.state.searching.toLowerCase())
			  );
			}) : filterByStatus;
		return (
			<div className="stats_offering_content_body">
				<div>
					<div className="col-md-12 statsofferingtable" >
						<GridTable
							columns={this.state.cols}
							rows={filterData}
							isPaginated={true}
							isVirtualScroll={true}
							showSearch={false}
							showRowsInformation={false}
							isHeaderSticky={true}
							components={{ Header: this.customHeader }}
						/>
					</div>
					
					<Dialog
						onClose={this.closeFollowerModal}
						aria-labelledby="customized-dialog-title"
						open={this.state.openFollowerModal}
						maxWidth={"md"}
						fullWidth={true}
						scroll={'paper'}
						>
						<DialogTitle onClose={this.closeFollowerModal} className="offering-detail-title">Follower List</DialogTitle>
						<DialogContent>
							<div className="col-md-12 follower-list-table">
								<GridTable
									columns={this.state.followerCols}
									rows={followerlist}
									isPaginated={true}
									isVirtualScroll={true}
									showSearch={true}
									showRowsInformation={false}
									isHeaderSticky={true}
								/>
							</div>
						</DialogContent>
					</Dialog>
					<Dialog
						onClose={this.closeBizInsightModal}
						aria-labelledby="customized-dialog-title"
						open={this.state.openBizInsightModal}
						maxWidth={"md"}
						fullWidth={true}
						scroll={'paper'}
						>
						<DialogTitle onClose={this.closeBizInsightModal} >BizInsight List</DialogTitle>
						<DialogContent>
							<div className="col-md-12">
								{
									(bizInsightData) && bizInsightData.map((item, i) => (
										<div key={i} className="modal-body-bizinsight-lists">
											<div className="modal-body-bizinsight-lists-email-box">
												<div className="modal-body-bizinsight-lists-email-plus" onClick={() => {
													this.opencollapse(item)
												}}>
													{
														(!item.showDates) ?
															<div style={{ paddingLeft: 10, paddingRight: 10 }}>+</div>
															:
															<div style={{ paddingLeft: 10, paddingRight: 10 }}>-</div>
													}
												</div>
												<div className="row" style={{ marginTop: -20 }}>
													<div className="modal-body-bizinsight-lists-email col-8" style={{ paddingLeft: 60 }}>{item.email}</div>
													<div className="modal-body-bizinsight-lists-email col-1">{item.count}</div>
													{
														(!item.showDates) &&
														<div className="modal-body-bizinsight-lists-email col-3" >{item.click_details[0]}</div>
													}
												</div>
											</div>
											{
												(item.showDates) &&
												<div className="modal-body-bizinsight-lists-dates_items">
													{
														item.click_details.map((date, j) => (
															<div className="modal-body-bizinsight-lists-dates_item" key={j}>{date}</div>
														))
													}
												</div>
											}
										</div>
									))
								}
							</div>
						</DialogContent>
					</Dialog>
				</div>
			</div>
		)
	}
}

AllocationListComponent.propTypes = {
	fetchStatsFollowerListRes: PropTypes.any,
	fetchBizInsightsListRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	fetchStatsFollowerListRes: doFetchStatsFollowerListRes,
	fetchBizInsightsListRes: doFetchBizInsightsListRes,
});
function mapDispatchToProps(dispatch) {
	return {
		getStatsFollowerList: (ext_id) => dispatch(getStatsFollowerList(ext_id)),
		getBizInsightsList: (ext_id) => dispatch(getBizInsightsList(ext_id)),
		doFetchStatsFollowerListRes: (data) => dispatch(doFetchStatsFollowerListRes(data)),
		doFetchBizInsightsListRes: (data) => dispatch(getBizInsightsList(data)),
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AllocationListComponent);
