import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import '../admin.scss';
import { getItem } from '../../../utils/localStore';
import { getBrokerDealerList, doBrokerDealerListRes } from '../../../actions/brokerDealerListActions';
import { getUnderwriterPerformance, doUnderwriterPerformanceRes } from '../../../actions/underwriterPerformanceActions';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import AdminUnderwriterOfferingList from './adminUnderwriterOfferings';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Button from '@material-ui/core/Button';
import BarChartIcon from '@material-ui/icons/BarChart';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltips from '@material-ui/core/Tooltip';
import GridTable from '@nadavshaar/react-grid-table';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { saveFile } from '../../../helper/helper';
const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
	}))(props => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});
let isUnderWriterList = false;
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

class UnderwritersComponent extends React.PureComponent {
	constructor(props) {
		super(props);
		isUnderWriterList = false;
		var columns = [
			{ id: 1, field: 'name', label: 'Name', visible: true, sortable: true, cellRenderer: this.actionNameUnderWriter },
			{ id: 2, field: 'created_at', label: 'Created Date', visible: true, sortable: true, cellRenderer: this.actionNames },
			{ id: 3, field: 'num_users', label: 'Update Date', visible: true, sortable: true, cellRenderer: this.actionNames },

			{ id: 5, field: 'msda', label: 'MSDA', visible: true, sortable: true, cellRenderer: this.actionNames },
			{ id: 7, field: 'performance', label: 'Performance', visible: true, sortable: false, cellRenderer: this.actionPerformance },
		];
		let getLevel = Number(localStorage.getItem('level'));
		if (getLevel === 0) {
			columns.push({ id: 4, field: 'commission_percentage', label: 'Commission', visible: true, sortable: true, cellRenderer: this.actionNames },
			{ id: 6, field: 'action', label: 'Action', visible: true, sortable: false, cellRenderer: this.actionAddBtn });
		}
		this.state = {
			cols: columns,
			isReadOnly: JSON.parse(getItem('isReadOnly')),
			showOfering: false,
			underwriterList: [],
			underwriterData: null,
			preformanceModal: false,
			underwriterPerformanceList: [],
			startIndex: 0,
			endIndex: 4,
			left: 0,
			right: 0,
			timerId: 0,
		}
	}

	componentDidMount() {
		isUnderWriterList = true;
		this.props.getUnderwriterList();
	}
	static getDerivedStateFromProps(props, state) {
        if (props.underwriterListRes) {
			if (props.underwriterListRes.data && props.underwriterListRes.data.bdList) {
				if (props.underwriterListRes.data.bdList.message === 'Success' && isUnderWriterList) {
					isUnderWriterList = false;
					if (props.underwriterListRes.data.bdList.data.length > 0) {
						let arrData = props.underwriterListRes.data.bdList.data;
						for (let i = 0; i < arrData.length; i++) {

							Object.keys(arrData[i]).forEach((field) => { /*eslint-env es6*/
							if (arrData[i][field] === null) {
								arrData[i][field] = "";
							}
							})
						};
						let bdArr = [];
						for (let item of arrData) {
							if (item.type === 'Underwriter') {
								bdArr.push(item)
							}
						}
						return {
							underwriterList: bdArr,
						};
					}
					
				}
			}
		}
		if (props.underwriterPerformanceRes) {
			if (props.underwriterPerformanceRes.data && props.underwriterPerformanceRes.data.underwriterPerformance) {
				if (props.underwriterPerformanceRes.data.underwriterPerformance.message === 'Success') {
					if (props.underwriterPerformanceRes.data.underwriterPerformance.data >= 5) {
						return {
							underwriterPerformanceList: props.underwriterPerformanceRes.data.underwriterPerformance.data,
							endIndex: 5,
						};
					} else {
						return {
							underwriterPerformanceList: props.underwriterPerformanceRes.data.underwriterPerformance.data,
						};
					}
				}
			}
		}
		return null
	}
	downLoadCSV = async (csvData, fileName) => {
		let csvContent = '';
		// csvContent += 'Name, ID, MPID,Type, Commission' + '\r\n';
		csvContent += 'Name, ID, MPID,Type, Commission';
		csvData.forEach(function (rowArray) {
			let newRow = rowArray.name + ',' + rowArray.clearing_account_id + ',' + rowArray.mpid + ',' + rowArray.blocker_code + ',' + rowArray.commission_percentage;
			csvContent += newRow + '\r\n'; // add carriage return
		});
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
		await saveFile(blob, fileName + '.csv');
	}
	actionAddBtn = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return (<div className="rgt-cell-inner">
			<Tooltips title="Admins">
				<Button component={Link} to={{ pathname: `/underwriter-admin`, state: { underwriterData: data, tabIndex: this.props.tabIndex } }} variant="contained" color="primary" style={{ margin: '5px' }} ><AccountBoxIcon /></Button>
			</Tooltips>
		</div>);
	}
	actionPerformance = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return (<div className="rgt-cell-inner">
			<Tooltips title="Performance">
		  		<Button variant="contained" color="primary" style={{width: '35px'}} onClick={() => this.openModalPreformance(data)}><BarChartIcon /></Button>
		  	</Tooltips>
		</div>);
	}
	closeOffering = () => {
		this.setState({
			showOfering: false,
		});
	}
	openMpidListModal = (data) => {
		this.setState({
			offeringMpid: data.mpid,
			underwriterData: data,
		}, () => {
			this.setState({
				showOfering: true,
			});
		});
	}
	actionNameUnderWriter = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return  <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
			{
				(value !== '' && value !== undefined) &&
				<Tooltips title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
					<div className="text-one-line green-color-with-pointer" style={{width: '90%'}} onClick={() => this.openMpidListModal(data)}>{value}</div>
				</Tooltips>
			}
			{
				(value !== '' && value !== undefined) &&
				<Tooltips title={'Copy to clipboard'} interactive >
					<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
				</Tooltips>
			}
		</div>;
	}
	actionNames = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return  <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
			{
				(value !== '' && value !== undefined) &&
				<Tooltips title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
					<div className="text-one-line" style={{width: '90%'}} >{value}</div>
				</Tooltips>
			}
            {
                (value !== '' && value !== undefined) &&
                <Tooltips title={'Copy to clipboard'} interactive >
                	<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
                </Tooltips>
            }
        </div>;
	}
	openModalPreformance = (data) => {
		this.setState({
			preformanceModal: true,
		});
		this.props.getUnderwriterPerformance(data.mpid);
	}
	closeModalPreformance = () => {
		this.setState({
			preformanceModal: false,
			underwriterPerformanceList: []
		}, () => {
			this.props.doUnderwriterPerformanceRes(null);
		});
	}
	renderTooltip = (item) => {
		if (item && item.payload && item.payload.length > 0) {
			return <div style={{ padding: 8, background: '#fff' }}>
				<p style={{ fontWeight: 'bold', fontSize: 15, color: '#000', marginBottom: 0 }}>{item.payload[0].payload.name}</p>
				<p style={{ fontWeight: 'bold', fontSize: 15, color: '#0A5074', marginBottom: 0 }}>Asked shares: {item.payload[0].payload.Asked}</p>
				<p style={{ fontWeight: 'bold', fontSize: 15, color: '#9ac051', marginBottom: 0 }}>Received shares: {item.payload[0].payload.Received}</p>
				<p style={{ fontWeight: 'bold', fontSize: 15, color: '#000', marginBottom: 0 }}>Percentage fullfilled :  
				{
					item.payload[0].payload.Received > 0 && item.payload[0].payload.Asked > 0 ?
					<span> {Math.round((item.payload[0].payload.Received / item.payload[0].payload.Asked) * 100)}</span>
					:
					<span> 0</span>
					
				}
				%</p>
			</div>
		}
	}
	updateBrush = (pos) => {
		if (this.state.timerId !== 0) {
			clearTimeout(this.timerId)
		}
		let timerr = this.state.timerId;
		timerr = setTimeout(() => {
			this.setState({ startIndex: pos.startIndex, endIndex: pos.endIndex })
		}, 500);
		this.setState({
			timerId: timerr
		})
	}
	printUnderwriter = (csvData, divName) => {
		this.setState({
			endIndex: csvData.length,
		});
		var divContents = document.getElementById(divName).innerHTML; 
            var a = window.open('', '', 'height=500, width=500'); 
            a.document.write('<html>'); 
            a.document.write('<body >'); 
            a.document.write(divContents); 
            a.document.write('</body></html>'); 
            a.document.close(); 
            a.print(); 
	}
	goToCreateUnderwriter = (AllocationInfo) => {
		this.props.history.push({
			pathname: '/create-underwriter',
			state: {
				tabIndex: this.props.tabIndex
			}
		})
	}
	render() {
		const { underwriterList, underwriterPerformanceList, isReadOnly } = this.state;
		let userLevel = Number(localStorage.getItem('level'));
		let chartData = [];
		if (underwriterPerformanceList) {
			for(let items of underwriterPerformanceList){
			  chartData.push({
				name: items.name, 
				Received: items.received_shares,
				Asked: items.asked_shares,
			  });
			}
			for(let i=chartData.length; i<=7; i++ ){
			  chartData.push({
				name: "", 
				Received: 0,
				Asked: 0,
			  });
			}
		  }
		return (
			<div className="stats_offering_content_body">
				{
					(!this.state.showOfering) &&
					<div>
						<div className="row">
							<div className="col-md-2">
							{
								(userLevel === 0) && (!isReadOnly) &&
								<Button onClick={() => this.goToCreateUnderwriter()} variant="contained" color="primary" style={{ marginTop: '15px' }} >New Underwriter</Button>
							}
							</div>
							<div className="col-md-10" style={{ textAlign: 'right' }}>
								{underwriterList && underwriterList.length > 0 && <Button style={{ marginTop: '15px' }} variant="contained" color="primary" onClick={() => this.downLoadCSV(underwriterList, 'Underwriters')}>Download CSV</Button>}
							</div>
						</div>
						<div className="row">
							<div className="col-md-12 underwriter-list-table" style={{ paddingTop: 15, paddingBottom: 15 }}>
								<GridTable
									columns={this.state.cols}
									rows={underwriterList}
									isPaginated={true}
									isVirtualScroll={true}
									showSearch={true}
									showRowsInformation={false}
									isHeaderSticky={true}
								/>
							</div>
							<Dialog
								onClose={this.closeModalPreformance}
								aria-labelledby="customized-dialog-title"
								open={this.state.preformanceModal}
								maxWidth={"md"}
								fullWidth={true}
								scroll={'paper'}
								>
								<DialogTitle onClose={this.closeModalPreformance} >Performance</DialogTitle>
								<DialogContent>
									<div className="col-md-12 send-notify-confirm-modal">
										<div style={{width: '100%', height: '100%'}}>
											<div id="underwriterbarchart">
												<ResponsiveContainer width='100%' height={450} >
													<BarChart
														width={1024}
														height={380}
														data={chartData}
														margin={{
														top: 5, right: 30, left: 20, bottom: 5,
														}}
													>
														<XAxis dataKey="name" tick={{fontSize: 13}} />
														<YAxis />
														<Tooltip
														cursor={false}
														content={this.renderTooltip}
														wrapperStyle={{
															borderColor: 'white',
															boxShadow: '2px 2px 3px 0px rgb(204, 204, 204)',
															background: 'white'
														}}
														contentStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
														labelStyle={{ fontWeight: 'bold', color: '#666666' }}
														/>
														<Legend />
														<Bar dataKey="Asked" barSize={25} stackId="a" fill="#0A5074"></Bar>
														<Bar dataKey="Received" barSize={25} stackId="a" fill="#9ac051"></Bar>
													</BarChart>
												</ResponsiveContainer>
											</div>
											<div>
												<Button variant="contained" color="primary" style={{float: 'right'}} onClick={() => this.printUnderwriter(chartData, 'underwriterbarchart')}>Print</Button>
											</div>
										</div>
									</div>
								</DialogContent>
							</Dialog>		
						</div>
					</div>
				}
				{
					(this.state.showOfering) &&
						<AdminUnderwriterOfferingList mpid={this.state.offeringMpid} underWriter={this.state.underwriterData} closeOffering={this.closeOffering} offType={'underwriters'} tabIndex={this.props.tabIndex}/>
				}
			</div>
		)
	}
}
UnderwritersComponent.propTypes = {
	underwriterListRes: PropTypes.any,
	underwriterPerformanceRes: PropTypes.any,
	history: PropTypes.any,
};
const mapStateToProps = createStructuredSelector({
	underwriterListRes: doBrokerDealerListRes,
	underwriterPerformanceRes: doUnderwriterPerformanceRes,
});
function mapDispatchToProps(dispatch) {
	return {
		getUnderwriterList: () => dispatch(getBrokerDealerList()),
		getUnderwriterPerformance: (mpid) => dispatch(getUnderwriterPerformance(mpid)),
		doUnderwriterPerformanceRes: (data) => dispatch(doUnderwriterPerformanceRes(data)),
	};
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(UnderwritersComponent);
