import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { createStructuredSelector } from 'reselect';
import Dashboard from '../../../component/Dashboard';
import { updateIssuerComm, doUpdateIssuerCommRes } from '../../../actions/issuerCommActions';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MenuItem from '@material-ui/core/MenuItem';
import validate from './formValidation';

class UpdateIssuerForm extends React.Component {
    constructor() {
        super();

        this.state = {
            issuerId: '',
            firstName: '',
            lastName: '',
            email: '',
            tickerSymbol: '',
            companyName: '',
            description: '',
            exchange: '',
            isSubmited: false,
        }
    }
    componentDidMount() {
        let issuerData = this.props.location.state;
        if (issuerData) {
            this.setState({
                firstName: issuerData.first_name,
                lastName: issuerData.last_name,
                email: issuerData.email,
                tickerSymbol: issuerData.ticker_symbol,
                companyName: issuerData.name,
                description: issuerData.description,
                exchange: issuerData.exchange,
                issuerId: issuerData.id,
            });
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.updateIssuerCommRes) {
            if (props.updateIssuerCommRes.data && props.updateIssuerCommRes.data.updateIssuer) {
                if (props.updateIssuerCommRes.data.updateIssuer.message === "Success") {
                    props.history.goBack();
                }
            }
        }
        return null
    }
    goBack = () => {
        this.props.history.goBack()
    }
    saveIssuer = () => {
        this.setState({
            isSubmited: true,
        }, () => { });
        validate(this.state);
        const errors = validate(this.state);
        console.log(Object.keys(errors))
        if (Object.keys(errors).length === 0) {
            let payload = {
                issuerId: this.state.issuerId,
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                email: this.state.email,
                ticker_symbol: this.state.tickerSymbol,
                name: this.state.companyName,
                description: this.state.description,
                role_type: 'issuer_admin',
                exchange: this.state.exchange,
            }
            // console.log(payload)
            this.props.handleFormSubmit(payload);
        }
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    render() {
        const errors = validate(this.state);
        return (
            <Dashboard title="ClickIPO - Update Issuer" pageTitle="Update Issuer">
                <div className="admin_offering_content">
                    <div className="">
                        <div className="Headingname">
                            <ArrowBackIosIcon onClick={() => this.goBack()} style={{ cursor: 'pointer' }} /> Create New Issuer
                        </div>
                        <form autoComplete="off" style={{ marginTop: 30 }}>
                            <div className="row">
                                <div className="col-md-6 col-sm-12" style={{ marginTop: 20 }}>
                                    <label className="update-label">
                                        First Name *
                                    </label>
                                    <TextField name="firstName" value={this.state.firstName} fullWidth onChange={this.handleChange} />
                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.firstName}</span>}
                                </div>
                                <div className="col-md-6 col-sm-12" style={{ marginTop: 20 }}>
                                    <label className="update-label">
                                        Last Name *
                                    </label>
                                    <TextField name="lastName" value={this.state.lastName} fullWidth onChange={this.handleChange} />
                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.lastName}</span>}
                                </div>
                                <div className="col-md-6 col-sm-12" style={{ marginTop: 20 }}>
                                    <label className="update-label">
                                        Email *
                                    </label>
                                    <TextField name="email" value={this.state.email} fullWidth onChange={this.handleChange} />
                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.email}</span>}
                                </div>
                                <div className="col-md-6 col-sm-12" style={{ marginTop: 20 }}>
                                    <label className="update-label">
                                        Ticker Symbol *
                                    </label>
                                    <TextField name="tickerSymbol" value={this.state.tickerSymbol} fullWidth onChange={this.handleChange} disabled />
                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.tickerSymbol}</span>}
                                </div>
                                <div className="col-md-6 col-sm-12" style={{ marginTop: 20 }}>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <label className="update-label">
                                                Company Name *
                                            </label>
                                            <TextField name="companyName" value={this.state.companyName} fullWidth onChange={this.handleChange} />
                                            {errors && this.state.isSubmited && <span className="errorvalidation">{errors.companyName}</span>}
                                        </div>
                                        <div className="col-md-12 col-sm-12" style={{ marginTop: 20 }}>
                                            <label>
                                                Exchange *
                                            </label>
                                            <Select
                                                name="exchange"
                                                value={this.state.exchange}
                                                onChange={this.handleChange}
                                                fullWidth
                                            >
                                                <MenuItem value={'XNYS'}>XNYS - NEW YORK STOCK EXCHANGE</MenuItem>
                                                <MenuItem value={'XNAS'}>XNAS - NASDAQ GLOBAL / CAPITAL / GLOBAL SELECT MARKET</MenuItem>
                                            </Select>
                                            {errors && this.state.isSubmited && <span className="errorvalidation">{errors.exchange}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12" style={{ marginTop: 20 }}>
                                    <label>
                                        Exchange *
                                    </label>
                                    <Select
                                        name="exchange"
                                        value={this.state.exchange}
                                        onChange={this.handleChange}
                                        fullWidth
                                    >
                                        <MenuItem value={'XNYS'}>XNYS - NEW YORK STOCK EXCHANGE</MenuItem>
                                        <MenuItem value={'XNAS'}>XNAS - NASDAQ GLOBAL / CAPITAL / GLOBAL SELECT MARKET</MenuItem>
                                    </Select>
                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.exchange}</span>}
                                </div>
                            </div>
                        </form>
                        <div className="row">
                            <div className="col-md-12" style={{ textAlign: 'center' }}>
                                <Button variant="contained" color="primary" onClick={this.saveIssuer} style={{ marginTop: '30px' }} >Submit</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dashboard>
        );
    }
}

UpdateIssuerForm.propTypes = {
    updateIssuerCommRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    updateIssuerCommRes: doUpdateIssuerCommRes,
});

function mapDispatchToProps(dispatch) {
    return {
        handleFormSubmit: (data) => dispatch(updateIssuerComm(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateIssuerForm);
