import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid,
  Label } from 'recharts';
import { convertDateTimeFormat, numberWithCommasAndDollar, numberWithCommas } from '../../helper/helper';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
	}))(props => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});
  
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);


class ChartModal extends React.Component {


  constructor() {

    super();
    this.state = {
      
    };
    this.dateFormate = this.dateFormate.bind(this);
    this.formatXAxis = this.formatXAxis.bind(this);
    this.formatYAxis = this.formatYAxis.bind(this);
    this.renderTooltip = this.renderTooltip.bind(this);
  }
  getPaddedComp = (comp) => {
    return ((parseInt(comp, 10) < 10) ? ('0' + comp) : comp)
  } 
  dateFormate(date, format) {
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    
    formattedDate = format,
    o = {
        "y+": date.getFullYear(), // year
        "M+": months[date.getMonth()], //month
        "d+": this.getPaddedComp(date.getDate()), //day
        "h+": this.getPaddedComp((date.getHours() > 12) ? date.getHours() % 12 : date.getHours()), //hour
         "H+": this.getPaddedComp(date.getHours()), //hour
        "m+": this.getPaddedComp(date.getMinutes()), //minute
        "s+": this.getPaddedComp(date.getSeconds()), //second
        "S+": this.getPaddedComp(date.getMilliseconds()), //millisecond,
        "b+": (date.getHours() >= 12) ? 'PM' : 'AM'
    };

    for (var k in o) {
        if (new RegExp("(" + k + ")").test(format)) {
            formattedDate = formattedDate.replace(RegExp.$1, o[k]);
        }
    }
    return formattedDate;
  };
  formatXAxis(tickItem) {
    const date = new Date(tickItem);
    const newDate = this.dateFormate(date, "d M");
    return newDate
  }

  formatYAxis(tickItem) {
    const amountY = numberWithCommasAndDollar(tickItem);
    return amountY
  }

  renderTooltip(item){

    if( item && item.payload && item.payload.length > 0){
      return <div style={{padding: 8}}>
        <p style={{fontWeight: 'bold', fontSize: 15}}>{item.payload[0].payload.Date}</p>
        <p style={{fontWeight: 'bold', fontSize: 15, color: '#8DC73F'}}>{numberWithCommasAndDollar(item.payload[0].payload.Amount)}</p>
        <p style={{fontWeight: 'bold', fontSize: 15, color: '#0A5074'}}># {item.payload[0].payload.countInvestor}</p>
      </div>
    }

  }

  render() {
    const { orderData, createdOffering, offeringMaxPrice } = this.props;
    if (!this.props.chartShow) {
      return null;
    }
    let chartData = [];
    // let totalInvestor=0;
    let statisticData = [];
    let largestOrder=0;
    let smallestOrder=0;
    let medianOrder=0;
    let hundreds=0;
    let twoPointFiveThousands=0;

    let totalRequestedAmount=0;
    let totalRequestedShare=0;

    if(orderData){
      let neworderData = [];
      for(let items of orderData){
        neworderData.push({
          requested_amount: items.requested_amount,
          created_at: items.created_at,
        });
      }
      if (neworderData.length > 0) {
        for(let items of neworderData){
          statisticData.push(items.requested_amount)
          totalRequestedAmount=Number(totalRequestedAmount) + Number(items.requested_amount);
        }

        totalRequestedShare = Math.floor(totalRequestedAmount / offeringMaxPrice);

        largestOrder = Math.max(...statisticData)
        smallestOrder= Math.min(...statisticData)
        const mid = Math.floor(statisticData.length / 2)
        let nums = [...statisticData].sort((a, b) => a - b);
        medianOrder = statisticData.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;

      }
      for(let i=0 ;i<statisticData.length; i++){
          if(statisticData[i]>=100){
            hundreds+=1
          }
          if(statisticData[i]>=2500){
            twoPointFiveThousands+=1
          }
      }

      if (neworderData.length > 0) {
        if(convertDateTimeFormat(neworderData[0].created_at) !== convertDateTimeFormat(createdOffering)){
          neworderData.push({created_at: createdOffering, requested_amount: 0, investCount: 0}, {created_at: createdOffering, requested_amount: 0,investCount: 0});
        }
      }
      let sortedData = neworderData.sort((a, b) => new Date(...a.created_at.split('/').reverse()) - new Date(...b.created_at.split('/').reverse()));
      let requestedAmount = 0;
      let countInvestor = 0;
      
      for(let items of sortedData){
        // if(items.status === this.props.selectedOption){
          requestedAmount = requestedAmount + items.requested_amount;

          let graphTime = convertDateTimeFormat(items.created_at);
          let checkCount = 0;
          let chartDataIndex = 0;

          for(let chartitem of chartData){
            if(chartitem.Date === graphTime){
              requestedAmount = chartitem.Amount + items.requested_amount;
              countInvestor = chartitem.countInvestor + 1;
              checkCount = 1;
              chartData[chartDataIndex].Amount = requestedAmount;
              chartData[chartDataIndex].countInvestor = countInvestor;
              break;
            }
            chartDataIndex++;
          }
          if(checkCount===0){
            let investorCount = 0;
            if(chartData.length>0){
              investorCount = chartData[chartData.length-1].countInvestor + 1;
            }
            chartData.push({Date:graphTime, Amount: requestedAmount, countInvestor:investorCount});
          }
        // }
      }
    }
    return (
        <Dialog
            onClose={this.props.onClose}
            aria-labelledby="customized-dialog-title"
            open={this.props.chartShow}
            maxWidth={"xl"}
            fullWidth={true}
            scroll={'paper'}
            >
            <DialogTitle onClose={this.props.onClose}>Build Out</DialogTitle>
            <DialogContent>
                <div className="col-md-12">
                    <div>
                        <div className="order-buildout-detail-amountbox">
                            <div className="row" style={{ marginTop: 10 }}>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-8">
                                      <div style={{ fontSize: 16, color: '#678899' }}>Total Requested Amount : </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div style={{ fontSize: 16, color: '#678899' }}>{numberWithCommasAndDollar(totalRequestedAmount)}</div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                {(offeringMaxPrice) && <div className="row">
                                  <div className="col-md-8">
                                    <div style={{ fontSize: 16, color: '#678899' }}>Total Requested Shares : </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div style={{ fontSize: 16, color: '#678899' }}>{numberWithCommas(totalRequestedShare)}</div>
                                  </div>
                                </div>}
                              </div>
                            </div>
                        </div>
                        <div style={{ width: '100%', height: '350px', margin: 20 }}>
                            <div style={{ width: '100%', height: '100%' }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart
                                        data={chartData}
                                        margin={{ top: 40, right: 40, bottom: 20, left: 55 }}
                                    >
                                        <CartesianGrid vertical={true} stroke="#eee" strokeDasharray="5 5" />

                                        <XAxis dataKey="Date" tickFormatter={this.formatXAxis} angle={-60}>
                                            <Label value="Order Date" angle={0} position='bottom' style={{ textAnchor: 'middle', fontSize: 18, color: '#0A5074' }}>
                                            </Label>
                                        </XAxis>

                                        <YAxis tickFormatter={this.formatYAxis} angle={-60}>
                                            <Label value="Aggregate Requested Amount" angle={270} position='left' offset={40} style={{ textAnchor: 'middle', fontSize: 18, color: '#0A5074' }}>
                                            </Label>
                                        </YAxis>

                                        <Tooltip
                                            content={this.renderTooltip}
                                            wrapperStyle={{
                                                borderColor: 'white',
                                                boxShadow: '2px 2px 3px 0px rgb(204, 204, 204)',
                                            }}
                                            contentStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
                                            labelStyle={{ fontWeight: 'bold', color: '#666666' }}
                                        />

                                        <Line type="monotone" dataKey="Amount" activeDot={{ r: 4 }} stroke="#8DC73F" dot={true} strokeWidth={2} />

                                    </LineChart>
                                </ResponsiveContainer>
                                {/* <div className="noOfInvestor">Number of Investors</div> */}
                            </div>
                        </div>
                        <div className="order-buildout-detail-amountbox">
                            <div className="row" style={{ marginTop: 10 }}>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-8">
                                      <div style={{ fontSize: 16, color: '#678899' }}>Largest order amount : </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div style={{ fontSize: 16, color: '#678899' }}>{numberWithCommasAndDollar(largestOrder)}</div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-8">
                                      <div style={{ fontSize: 16, color: '#678899' }}>Smallest order amount : </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div style={{ fontSize: 16, color: '#678899' }}>{numberWithCommasAndDollar(smallestOrder)}</div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-8">
                                      <div style={{ fontSize: 16, color: '#678899' }}>Median of the order amount : </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div style={{ fontSize: 16, color: '#678899' }}>{numberWithCommasAndDollar(medianOrder)}</div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-8">
                                      <div style={{ fontSize: 16, color: '#678899' }}>Number of round lots (orders that are $100 or more) : </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div style={{ fontSize: 16, color: '#678899' }}>{numberWithCommas(hundreds)}</div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-8">
                                      <div style={{ fontSize: 16, color: '#678899' }}>Orders that are $2500 or more : </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div style={{ fontSize: 16, color: '#678899' }}>{numberWithCommas(twoPointFiveThousands)}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="row" style={{ marginTop: 10 }}>
                                <div className="col-md-8">
                                    <div style={{ fontSize: 16, color: '#678899' }}>Smallest order amount : </div>
                                </div>
                                <div className="col-md-4">
                                    <div style={{ fontSize: 16, color: '#678899' }}>{numberWithCommasAndDollar(smallestOrder)}</div>
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: 10 }}>
                                <div className="col-md-8">
                                    <div style={{ fontSize: 16, color: '#678899' }}>Median of the order amounts : </div>
                                </div>
                                <div className="col-md-4">
                                    <div style={{ fontSize: 16, color: '#678899' }}>{numberWithCommasAndDollar(medianOrder)}</div>
                                </div>
                            </div> */}
                            {/* <div className="row" style={{ marginTop: 10 }}>
                                <div className="col-md-8">
                                    <div style={{ fontSize: 16, color: '#678899' }}>Number of round lots (orders that are $100 or more) :</div>
                                </div>
                                <div className="col-md-4">
                                    <div style={{ fontSize: 16, color: '#678899' }}>{numberWithCommas(hundreds)}</div>
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: 10 }}>
                                <div className="col-md-8">
                                    <div style={{ fontSize: 16, color: '#678899' }}>Orders that are $2500 or more :</div>
                                </div>
                                <div className="col-md-4">
                                    <div style={{ fontSize: 16, color: '#678899' }}>{numberWithCommas(twoPointFiveThousands)}</div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
        
    );
  }
}

ChartModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  chartShow: PropTypes.bool,
  orderData: PropTypes.any,
  createdOffering: PropTypes.any,
  offeringMaxPrice: PropTypes.any,
  selectedOption: PropTypes.any
};

const mapStateToProps = createStructuredSelector({
});
function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartModal);
