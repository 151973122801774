import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Swal from 'sweetalert2';
import loaderImg from '../../assets/images/spinn_img.gif';
import NumberFormat from "react-number-format";
import { Link } from 'react-router-dom';
import './allocations.scss';
import { getItem } from '../../utils/localStore';
import validate from './tablevalidation';
import { sendRunAllocation, doRunAllocationRes } from '../../actions/runAllocationActions';
import { sendCreateWarrant, doCreateWarrantRes } from '../../actions/createWarrantActions';
import { sendIOInotification, doSendIOInotificationRes } from '../../actions/ioiNotificationActions';
import { sendAllocationEmail, doSendAllocationEmailRes } from '../../actions/allocationEmailActions';
import { updateProspectus, doUpdateProspectusRes } from '../../actions/updateProspectusActions';
import { fetchBrokerDealerAllocation, doFetchBrokerDealerAllocationRes } from '../../actions/brokerDealerAllocationActions';
import { submitDeleteAllocation, doDeleteAllocationRes } from '../../actions/deleteAllocationActions';
import { fetchShowAllocationMetaData, doFetchShowAllocationMetadataRes } from '../../actions/showAllocationMetaDataActions';
import OfferingImg from '../../assets/images/placeholder_company_thumb.png';
import { numberWithCommas, convertDateTimeFormat, currency } from '../../helper/helper';
import Checkbox from '@material-ui/core/Checkbox';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
// import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { DialogActions, Switch } from '@material-ui/core';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
import { encryptData } from '../../utils/util';
const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
	}))(props => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});
  
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);
const styles = {
	block: {
		maxWidth: 250,
	},
	radioButton: {
		marginBottom: 16,
		color: '#8DC73F'
	},
};
const withValueLimit = ({ floatValue }) => floatValue <= 100;
function NumberFormatCustom(props) {
	const { inputRef , onChange, ...other } = props;
	return (
	  <NumberFormat
		{...other}
		getInputRef={inputRef}
		onValueChange={values => {
		  onChange({
			target: {
			  value: values.value,
			  formattedValue: values.formattedValue,
			  floatValue: values.floatValue
			}
		  });
		}}
		decimalScale={2}
		suffix={'%'}
		isAllowed={withValueLimit}
	  />
	);
}
function NumberFormatMinMax(props) {
	const { inputRef , onChange, ...other } = props;
	return (
	  <NumberFormat
		{...other}
		getInputRef={inputRef}
		onValueChange={values => {
		  onChange({
			target: {
			  value: values.value,
			  formattedValue: values.formattedValue,
			  floatValue: values.floatValue
			}
		  });
		}}
		decimalScale={2}
	  />
	);
}
let minAllocAmount = '';
let minAllocShares = '';
class AllocationListComponent extends React.PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			isReadOnly: JSON.parse(getItem('isReadOnly')),
			loading: false,
			isLoad: false,
			final_price: '',
			click_shares: '',
			allocationInfo: '',
			open: null,
			isSave: false,
			isEdit: false,
			isOpen: false,
			isOpenWarrant: false,
			initialTotalAllocatedShare: 0,
			confirmModal: false,
			isCompleteConfirm: false,
			isComplete: false,
			sendProspectConfirm: false,
			sendProspectusAllocationInfo: '',
			allocationEmailConfirm: false,
			allocationEmailAllocationInfo: '',
			isAllocatedChange: true,
			Total_initial_shares: 0,
			isUIrender: false,
			allocatedShareDifference: 0,
			isGrater: false,
			isReOpen: false,
			Total_allocated_amount: 0,
			runSuccessModal: false,
			uploadIntactSuccess: false,
			isCount: 0,
			isChecked: false,
			isCompleteCheck: false,
			finalProsSuccess: false,
			checkFinalProsSuc: true,
			isSubmit: false,
			warrantprice: '',
			Warrant_share: '',
			reconfirmExtId: '',
			warrant_cusip: '',
			allocationIndex: null,
			brokerDealerData: null,
			runErrorModal: false,
			allocationMessage: 'Processing Allocation...',
			ticker_symbol: '',
			underwriter_concession_amount: '0.0',
			dsp: 0,
			isDsp: false,
			showAllocationIndex: null,
			runAllocationModal: false,
			allocationExtId: '',
			algo: '0',
			allocationId: '',
			allocatedData:'',
			minimum_value:'',
			maximum_value:'',
			showAllocationMeta: '',
			roundLotAllocated: '',
			lotAllocated2500: '',
			ignoreTime: false,
			allocationPercentage: '100',
			userLevel: getItem('level')
		}
	};

	static getDerivedStateFromProps(props, state) {
		if (props.runAllocationRes) {
			if (props.runAllocationRes.data && props.runAllocationRes.data.runAllocation) {
				if (props.runAllocationRes.data.runAllocation.message === 'Success') {
					for (let item of props.AllocationView) {
						if (item.ext_id === state.allocationExtId) {
							item.allocated = 'ran';
						}
					}
					return {
						runAllocationModal: false,
						roundLotAllocated: '',
						lotAllocated2500: '',
						algo: '0',
						minimum_value: '',
						maximum_value: '',
						ignoreTime: false,
						allocation_percentage: '100'
					};
				}
			}
		}
		if (props.showAllocationMetadataRes) {
            if (props.showAllocationMetadataRes.data && props.showAllocationMetadataRes.data.showAllocationMetaData) {
                if (props.showAllocationMetadataRes.data.showAllocationMetaData.message === 'Success') {
                    return {
						showAllocationMeta: props.showAllocationMetadataRes.data.showAllocationMetaData.data,
						roundLotAllocated: props.showAllocationMetadataRes.data.showAllocationMetaData.data.round_lot_requested,
						lotAllocated2500: props.showAllocationMetadataRes.data.showAllocationMetaData.data['2500_lot_requested'],
						// roundLotAllocated: 100,
						// lotAllocated2500: 200,
                    };
                }
            }
        }
		
		if (props.sendAllocationEmailRes) {
			if (props.sendAllocationEmailRes.data && props.sendAllocationEmailRes.data.allocationEmail) {
				if (props.sendAllocationEmailRes.data.allocationEmail.message === 'Success') {
					return {
						allocationEmailConfirm: false
					};
				}
			}
		}
		if (props.deleteAllocationRes) {
			if (props.deleteAllocationRes.data && props.deleteAllocationRes.data.deleteAllocation) {
				if (props.deleteAllocationRes.data.deleteAllocation.message === 'Success') {
					let allExtIds = [];
					for (const allocation of props.AllocationView) {
						let checkExt = allocation.ext_id.includes(state.allocationId);
						if (checkExt) {
							allExtIds.push(allocation.ext_id)
						}
					}
					for (let id of allExtIds) {
						const objIdRef = props.AllocationView.find(i => i.ext_id === id);
						objIdRef && props.AllocationView.splice(props.AllocationView.indexOf(objIdRef), 1);
					}
				}
			}
		}
		if (props.createWarrantRes) {
			if (props.createWarrantRes.data && props.createWarrantRes.data.createWarrant) {
				if (props.createWarrantRes.data.createWarrant.message === 'Success') {
					props.reloadAllocation();
					return {
						allocationIndex: null,
						warrantprice: '',
						Warrant_share: '',
						warrant_cusip: '',
						ticker_symbol: '',
						underwriter_concession_amount: '0.0',
						isDsp: false,
						isSubmit: false
					}
				}
			}
		}
		return null;
	}
	addDefaultSrc = (ev) => {
		ev.target.src = OfferingImg;
	}
	selectDspCheckbox = () => {
		// e.preventDefault();
		if (this.state.isDsp === false) {
			this.setState({
				isDsp: true,
				dsp: 1,
			}, () => { });
		} else {
			this.setState({
				isDsp: false,
				dsp: 0,
			}, () => { });
		}
	}
	onOpenModal = (i) => {
		this.setState({ open: true, isAllocatedChange: true, initialTotalAllocatedShare: 0, allocatedShareDifference: 0, isSave: false, isReOpen: true });

		var element = document.querySelector(`#allocationList_li${i}`);
		element.scrollIntoView();
	}
	onCloseModal = () => {
		this.props.resetshowAllocations();
		this.props.resetSuccessFinalizeAllocation();
		this.setState({ open: false, isReOpen: false });
	}

	deleteAllocation = (allocation) => {
		this.setState({
			allocationId: allocation.ext_id
		})
		let request = {
			ext_id: allocation.ext_id
		}
		this.props.doDeleteAllocation(request);
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.callIntactUpload();
	}

	openIntactModal = () => {
		this.setState({
			uploadIntactSuccess: true,
		});
	}
	closeIntactModal = () => {
		this.props.resetUploadIntactRes();
		this.setState({
			uploadIntactSuccess: false,
		});
	}

	showAllocationList = (allocation, index) => {
		// this.onCloseModal();
		let allocationKeys = {
			isEdit: allocation.allocated === 'completed' ? false : true,
			final_price: allocation.final_price,
			click_shares: allocation.click_shares,
			allocationInfo: allocation,
			showAllocationIndex: index,
			tabIndex: this.props.tabIndex
		}
		const encryptedAllData = encryptData(allocationKeys);
		localStorage.setItem('allocationKeys', encryptedAllData);
		this.props.history.push(`/show-allocation/${allocation.ext_id}`);
	}
	openConfirmModal = () => {
		this.setState({ confirmModal: true });
	}
	closeConfirmModal = () => {
		this.setState({ confirmModal: false });
	}

	allocatesSharesTemplate = (rowData) => {
		if (Number(rowData.allocated_amount) > Number(rowData.buying_power)) {
			rowData.greater = true;
		} else {
			rowData.greater = false;
		}
		return <div className={'ui-cell-data ' + (rowData.greater === true ? 'update-error' : '')}>{rowData.allocated_shares}</div>;
	}

	runAllocations = () => {
		this.setState({
			allocationMessage: 'Processing Allocation...',
		}, () => {
			// this.setState({
			// 	// runSuccessModal: true,
			// 	runAllocationModal: true,
			// });

			let minimum_value = this.state.minimum_value;
			if(this.state.minimum_value==="") {
				if(this.state.allocatedData.ordr_dollar_share === 0) {
					minimum_value = minAllocAmount;
				} else {
					minimum_value = minAllocShares;
				}
			}

			// if(this.state.allocatedData.ordr_dollar_share === 0) {
			// 	minimum_value = minimum_value.replace("$", "");
			// }
			let testShowMax = this.state.allocatedData.ordr_dollar_share === 0 ? "Maximum allocated amount": "Maximum allocated shares";
			let testShowMin = this.state.allocatedData.ordr_dollar_share === 0 ? "Minimum allocated amount": "Minimum allocated shares";
			if (minimum_value === '') {
				Swal.fire({
					title: `${testShowMin} is required`,
					icon: 'info',
					confirmButtonText: 'OK',
					allowOutsideClick: false,
					timer: 3000,
					heightAuto: false
				});
			} else if ( this.state.maximum_value === '') {
				Swal.fire({
					title: `${testShowMax} is required`,
					icon: 'info',
					confirmButtonText: 'OK',
					allowOutsideClick: false,
					timer: 3000,
					heightAuto: false
				});
			} else if (Number(minimum_value) > Number(this.state.maximum_value)) {
				
				Swal.fire({
					title: `${testShowMin} not greater then ${testShowMax}`,
					icon: 'info',
					confirmButtonText: 'OK',
					allowOutsideClick: false,
					timer: 3000,
					heightAuto: false
				});
			} else {
				let req = {
					ext_id: this.state.allocationExtId,
					algo: Number(this.state.algo),
					minimum_value: minimum_value,
					maximum_value: this.state.maximum_value,
					ignore_time: this.state.ignoreTime,
					allocation_percentage: parseFloat(this.state.allocationPercentage).toFixed(2)
				};
				if (Number(this.state.algo) === 3 || Number(this.state.algo) === 4) {
					req.round_lot_number = this.state.roundLotAllocated === '' ? Number('0') : Number(this.state.roundLotAllocated);
					req['2500_lot_number'] = this.state.lotAllocated2500 === '' ? Number('0') : Number(this.state.lotAllocated2500);
				}
				this.props.sendRunAllocation(req);
				// this.setState({
				// 	algo: '0',
				// 	minimum_value: '',
				// 	roundLotAllocated: '',
				// 	lotAllocated2500: '',
				// })
			}
			
		});
	}
	openRunAllocationModal = (allocation) => {
		this.setState({
			runAllocationModal: true,
			allocationExtId: allocation.ext_id,
			allocatedData:allocation
		})
	}
	closeRunAllocationModal = () => {
		this.setState({
			runAllocationModal: false,
			algo: '0',
			roundLotAllocated: '',
			lotAllocated2500: '',
			ignoreTime: false
		})
	}
	changeRunAllocation = (e) => {
		this.setState({
			algo: e.target.value
		}, () => {
			if (this.state.algo === '3') {
				this.props.fetchShowAllocationMetaData(this.state.allocationExtId);
			}
		})

	}
	handleSwitchChange = (e) => {
		if(e.target.checked){
			this.setState({
				algo: '4'
			})
		}else{
			this.setState({
				algo: '3'
			})
		}
	}
	
	isDollerChange = (e) => {
		this.setState({
			minimum_value: e.target.value
		});
	}
	maximumValueChange = (e) => {
		this.setState({
			maximum_value: e.target.value
		});
	}

	collapseOffering = (item, types) => {
		this.setState({ isLoad: true });
		if (item.isOpen === true) {
			item.isOpen = false;
		} else {
			item.isOpen = true;
		}
		setTimeout(() => {
			this.setState({ isLoad: false });
		}, 500);
	}
	collapsCreatWarrant = (item, index) => {
		if (this.state.allocationIndex === index) {
			this.setState({
				warrantprice: '',
				Warrant_share: '',
				warrant_cusip: '',
				isLoad: true,
				// isLoad: true,
				// isLoad: true,
				reconfirmExtId: item.ext_id,
				allocationIndex: null,
				ticker_symbol: '',
				underwriter_concession_amount: '0.0',
			});
		} else {
			this.setState({
				warrantprice: '',
				Warrant_share: '',
				warrant_cusip: '',
				isLoad: true,
				reconfirmExtId: item.ext_id,
				allocationIndex: index,
				ticker_symbol: '',
				underwriter_concession_amount: '0.0',
			});
		}
		setTimeout(() => {
			this.setState({
				isLoad: false
			});
		}, 500);
	}


	handleProspectusChange = (e, item) => {
		this.setState({ isLoad: true });
		item.prospectus_url = e.target.value;
		setTimeout(() => {
			this.setState({ isLoad: false });
		}, 100);
	}
	handleWarrantChange = (e) => {
		this.setState({
			warrantprice: e.target.value,
		});
	}

	handleWarrantChangeshare = (e) => {
		this.setState({
			Warrant_share: e.target.value,
		});
	}
	handleWarrantChangeCusip = (e) => {
		this.setState({
			warrant_cusip: e.target.value,
		});
	}

	handleTickerSymbol = (e) => {
		this.setState({
			ticker_symbol: e.target.value,
		});
	}

	handleUnderwriterConcessionAmount = (e) => {
		const re = /^\s*\d*\.?\d{0,2}\s*$/;
		if (re.test(e.target.value)) {
			this.setState({
				underwriter_concession_amount: e.target.value,
			});
		}
	}

	openRunErrorModal = () => {
		this.setState({
			runSuccessModal: true,
			allocationMessage: 'Invalid Allocation.',
		}, () => { });
	}

	closeRunErrorModal = () => {
		this.setState({
			allocationMessage: 'Processing Allocation...',
		}, () => {
			this.setState({
				runSuccessModal: false,
			});
		});
	}

	openRunSuccessModal = () => {
		this.setState({
			runSuccessModal: true,
			allocationMessage: 'Allocation Run Successfully.',
		}, () => { });
	}

	closeRunSuccessModal = () => {
		this.setState({
			allocationMessage: 'Processing Allocation...',
		}, () => {
			this.setState({
				runSuccessModal: false,
			});
		});
	}

	sendFinalProspectus = (allocationInfo) => {
		let request = {
			ext_id: allocationInfo.ext_id,
			i_or_f: 'f',
			prospectus: allocationInfo.prospectus_url,
		}
		this.props.updateProspectus(request);
	}

	openFinalProspectusModal = (AllocationInfo) => {
		if (AllocationInfo.prospectus_url && (AllocationInfo.prospectus_url !== '' || AllocationInfo.prospectus_url !== null)) {
			this.setState({
				sendProspectusAllocationInfo: AllocationInfo,
				sendProspectConfirm: true,
			});
		} else {
			Swal.fire({
				title: 'Please enter prospectus url',
				icon: 'info',
				confirmButtonText: 'OK',
				allowOutsideClick: false,
				timer: 3000,
				heightAuto: false
			  });
		}
	}

	closeFinalProspectusModal = () => {
		this.setState({
			sendProspectConfirm: false,
		});
	}

	closeFinalProspectusModalSuccess = () => {
		this.setState({
			checkFinalProsSuc: false,
			finalProsSuccess: false,
		});
	}

	openAllocationEmailModal = (AllocationInfo) => {
		this.setState({
			allocationEmailAllocationInfo: AllocationInfo,
			allocationEmailConfirm: true,
		});
	}
	closeAllocationEmailModal = () => {
		this.setState({
			allocationEmailConfirm: false,
		});
	}

	doSendAllocationEmail = (AllocationInfo) => {
		this.props.sendAllocationEmail(AllocationInfo.ext_id);
	}

	selectedOffering = (e, item, index) => {
		this.setState({ isLoad: true });
		if (item.checked === true) {
			item.checked = false;
			this.props.AllocationView[index].checked = false;
		} else {
			item.checked = true;
			this.props.AllocationView.forEach((off) => {
				off.selected = false;
			});
			
			this.props.AllocationView[index].checked = true;
		}
		setTimeout(() => {
			this.setState({ isLoad: false,isUIrender: !this.state.isUIrender },() => {
			});
			this.props.selectedIntact(this.props.AllocationView);
		}, 300);
	}
	
	selectedBrOffering = (e, item, index) => {
		this.setState({ isLoad: true });
		if (item.selected === true) {
			item.selected = false;
			this.props.AllocationView[index].selected = false;
		} else {
			item.selected = true;
			this.props.AllocationView.forEach((all) => {
				all.checked = false;
			});
			this.props.AllocationView[index].selected = true;
		}
		setTimeout(() => {
			this.setState({ isLoad: false,isUIrender: !this.state.isUIrender }, () => {
			});
			this.props.selectedBr(this.props.AllocationView);
		}, 300);
	}
	goToPricingWire = (AllocationInfo) => {
		const encryptedData = encryptData(AllocationInfo);
		localStorage.setItem('allocationDataPricing', encryptedData);
		const tabIndexPricing = encryptData(this.props.tabIndex);
		localStorage.setItem('tabIndexPricing', tabIndexPricing);
		this.props.history.push('/pricing-wire');
	}

	updateWarrant = () => {
		this.setState({
			isSubmit: true,
		});

		const errors = validate(this.state);
		if (Object.keys(errors).length === 0) {
			const data = {
				ext_id: this.state.reconfirmExtId,
				warrant_price: this.state.warrantprice,
				warrant_factor: this.state.Warrant_share,
				cusip_id: this.state.warrant_cusip,
				ticker_symbol: this.state.ticker_symbol,
				underwriter_concession_amount: Number(this.state.underwriter_concession_amount),
				dsp: this.state.dsp,
			};
			this.props.sendCreateWarrant(data);
		}
	}

	goToAllocationDetail = (rowData) => {
		if (rowData.allocated === 'completed' || rowData.allocated === 'ran' || rowData.allocated === 'in_progress') {
			this.props.allocationType(true);
		} else {
			this.props.allocationType(false);
		}
	}
	// Send IOI notification
	ioiNotification = (item) => {

		this.props.sendIOInotification(item.ext_id)
	}
	
    handleContainerOnBottom = () => {
        this.props.fetchAllocationData();
	};
	handleChangeRoundLotAllocated = (e) => {
		if (e.target.value !== '') {
			let val = new RegExp('^[0-9]');
			if (val.test(e.target.value)) {
				this.setState({
					roundLotAllocated: e.target.value
				})
			}
		} else {
			this.setState({
				roundLotAllocated: e.target.value
			})
		}
	};
	handleChangeLotAllocated2500 = (e) => {
		if (e.target.value !== '') {
			let val = new RegExp('^[0-9]');
			if (val.test(e.target.value)) {
				this.setState({
					lotAllocated2500: e.target.value
				})
			}
		} else {
			this.setState({
				lotAllocated2500: e.target.value
			})
		}
	};
	handleChangeIgnoreTime = (e) => {
		this.setState({
			ignoreTime: e.target.checked
		})
	};
	onValueChangeHandler = (values) => {
		this.setState({
			allocationPercentage: values.target.value
		})
	};
	render() {
		const { AllocationView, isLoadAllocation, lastData, min_alloc_amount, min_alloc_shares, } = this.props;
		const { isReadOnly } = this.state;
		minAllocAmount = min_alloc_amount;
		minAllocShares = min_alloc_shares;
		const errors = validate(this.state);
		return (
			<div className="allocation_content_body">
				<div>
					<BottomScrollListener onBottom={this.handleContainerOnBottom} debounce={200} offset={100}>
					{(scrollRef) => (
						<div ref={scrollRef} style={{ height: 'calc(100vh - 260px)', overflow: "auto" }}>
							{
								AllocationView && AllocationView.map((item, i) => 
								<Card variant="outlined" key={i} style={{ marginBottom: 20, 
									overflow: 'unset'
								}}>
									<CardContent>
											<Typography component="div" className="row offeringcontent" >
												<Typography component="div" className="col-md-1">
													{/* <img /> */}
													{item.logo_small !== null && <img onError={this.addDefaultSrc} style={{ paddingTop: 12, width: "100%" }} src={(item) ? 'https:' + item.logo_small : ''} alt="offering img" />} {item.logo_small === null &&
													<div className="icon_column">
														<div className={item.type === 'IPO' ? 'icon' : 'icon_2'}>{item.name.charAt(0)}</div>
													</div>}
												</Typography>
												<Typography component="div" className="col-md-5">
													<div>
														<Link to={{ pathname: `/allocation-detail/${item.ext_id}`, state: item, tabIndex: this.props.tabIndex }}>
															<div className="offeringInfo">
																<span className="offeringName">{item.name}</span>
																<span style={{ color: '#245776' }}>&nbsp;({item.type})</span>
															</div>
														</Link>
														<div className="no_margin titles">Allocated Date<span className="sub_titles">{convertDateTimeFormat(item.allocated_date)}</span></div>
														<div className="no_margin titles">Allocated Amount: <span className="sub_titles"> ${item.total_allocated_amount.toLocaleString()}</span></div>
														<div className="no_margin titles">Allocated Shares: <span className="sub_titles">{numberWithCommas(item.total_allocated_shares)}</span></div>
														<div className="no_margin titles"> Final Price <span className="sub_titles"> ${currency(item.final_price)}</span></div>
														{item.allocated === 'completed' && 
															<div style={{display: 'inline-flex'}}>
																{/* <div className="no_margin titles" style={{marginTop: 16}}> <input type="checkbox" value={item.checked !== undefined ? item.checked : false} onChange={(event) => this.selectedOffering(event, item, i)} checked={item.checked !== undefined ? item.checked : false} disabled={isReadOnly}/> Intact File </div> */}
																<div className="no_margin titles" style={{marginTop: 16}}> <input type="checkbox" value={item.selected !== undefined ? item.selected : false} onChange={(event) => this.selectedBrOffering(event, item, i)} checked={item.selected !== undefined ? item.selected : false} disabled={item.type === 'Warrant' || isReadOnly}/> Braggart File </div>
															</div>
														}
														
													</div>
												</Typography>
												<Typography component="div" className="col-md-3">
													{
														item.type !== 'Warrant' ?
														<div>
															{(item.allocated === 'in_progress' || item.allocated === 'ran') && (!isReadOnly) &&
															
																<div>
																	<Button variant="contained" fullWidth={true} onClick={() => this.openRunAllocationModal(item)} disabled={item.allocated === 'ran'} className="offeringbuttons" style={{ marginTop: '16px' }}>RUN</Button>
																</div>
															}
															{(item.allocated === 'in_progress' || item.allocated === 'ran') && (this.state?.userLevel?.toString() !== '3') &&
															
																<div>
																	<Button variant="contained" fullWidth={true} className="offeringbuttons" onClick={() => { this.goToPricingWire(item); }} style={{ marginTop: '16px' }} disabled={item.allocated === 'ran' ? false : true}> Pricing Wire</Button>
																</div>
															}
														</div>
														:
														<div>
															{(item.allocated === 'in_progress' || item.allocated === 'ran') && (!isReadOnly) &&
															
																<div>
																	<Button variant="contained" fullWidth={true} onClick={() => this.openRunAllocationModal(item)} disabled={item.type === 'Warrant'} className="offeringbuttons" style={{ marginTop: '16px' }}>RUN</Button>
																</div>
															}
															{(item.allocated === 'in_progress' || item.allocated === 'ran') && (this.state?.userLevel?.toString() !== '3') &&
																<div>
																	<Button variant="contained" fullWidth={true} className="offeringbuttons" onClick={() => { this.goToPricingWire(item); }} style={{ marginTop: '16px' }} disabled={item.type === 'Warrant' ? false : true}> Pricing Wire</Button>
																</div>
															}
														</div>
													}
													{item.allocated === 'completed' && 
														<div>
															<Button variant="contained" fullWidth={true} onClick={() => { this.showAllocationList(item, i); }} className="offeringbuttons" disabled={item.allocated !== 'completed'} style={{ marginTop: '16px' }}>Show</Button>
															{
																(!isReadOnly) &&
																<Button variant="contained" fullWidth={true} onClick={() => { this.openAllocationEmailModal(item); }} className="offeringbuttons" style={{ marginTop: '16px' }} disabled={item.type === 'Warrant'}>Send Allocation Email</Button>
															}
															{/* {
																item.type !== 'Warrant' && (!isReadOnly) &&
																	<Button variant="contained" fullWidth={true}  className="offeringbuttons" onClick={() => { this.collapsCreatWarrant(item, i); }} style={{ marginTop: '16px' }}>Create Warrant</Button>
															}
															{
																item.type === 'Warrant' && (!isReadOnly) &&
																	<Button variant="contained" fullWidth={true}  className="offeringbuttons" disabled={true} style={{ marginTop: '16px' }}>Create Warrant</Button>
															} */}
														</div>
													}
												</Typography>
												<Typography component="div" className="col-md-3">
													<div className="gridButtons text_center">
														{(item.allocated === 'in_progress' || item.allocated === 'ran') && 
														
															<div>
																<Button variant="contained" fullWidth={true} className="offeringbuttons" disabled={item.allocated !== 'ran'} onClick={() => { this.showAllocationList(item, i); }} style={{ marginTop: '16px' }}> Show</Button>
																{
																	(!isReadOnly) &&
																	<Button variant="contained" fullWidth={true} color="secondary" onClick={() => { this.deleteAllocation(item); }} style={{ marginTop: '16px' }} disabled={item.type === 'Warrant'}> Delete</Button>
																}
																{
																	item.dsp === 2 && (!isReadOnly) &&
																	<Button variant="contained" fullWidth={true} className="offeringbuttons" onClick={() => { this.ioiNotification(item); }} disabled={item.type === 'Warrant'} style={{ marginTop: '16px' }}>IOI Notification</Button>
																}
															</div>
														}
														{item.allocated === 'completed' && (this.state?.userLevel?.toString() !== '3') &&
														
															<div>
																<Button variant="contained" fullWidth={true} className="offeringbuttons" onClick={() => { this.goToPricingWire(item); }} disabled={item.type === 'Warrant'} style={{ marginTop: '16px' }}> Pricing Wire</Button>
																{
																	(!isReadOnly) &&
																	<Button variant="contained" fullWidth={true} className="offeringbuttons" onClick={() => { this.collapseOffering(item); }} style={{ marginTop: '16px' }} disabled={item.type === 'Warrant'}>Final Prospectus</Button>
																}
																{
																	item.dsp === 2 && (!isReadOnly) &&
																	<Button variant="contained" fullWidth={true} className="offeringbuttons" onClick={() => { this.ioiNotification(item); }} style={{ marginTop: '16px' }} disabled={item.type === 'Warrant'}>IOI Notification</Button>
																}
																
															</div>
														}
													</div>
												</Typography>
											</Typography>
											{item.isOpen &&
											<div className="offeringcollapse">
												<p className="header">Final Prospectus</p>
												<div>
													<TextField type="text" defaultValue={item.prospectus_url} onChange={(event) => { this.handleProspectusChange(event, item); }} style={{width: '100%'}} />
												</div>
												<div style={{ marginTop: 20 }}>
													<Button variant="contained" color="primary" onClick={() => { this.openFinalProspectusModal(item); }} >Send Final Prospectus</Button>
												</div>
											</div>}
											{this.state.allocationIndex === i && <div className="offeringcollapse" >
												<p className="header" style={{ fontWeight: '700', fontSize: 20, marginBottom: 20, marginTop: 20 }}>Create Warrant</p>
												<div className="row createwarrant_box">
													<div className="col-md-4">
														<p className="header">Warrant Price</p>
														<TextField type="text" fullWidth value={this.state.warrantprice} onChange={(e) => { this.handleWarrantChange(e); }} />
														{this.state.isSubmit && errors && <span style={{ color: 'red', fontSize: 14 }}>{errors.Warrant_PriceErr}</span>}
													</div>
													<div className="col-md-4">
														<p className="header">Shares to warrant conversion factor
															
															<Tooltip title={<div style={{fontSize: 12}}>2 shares to 1 warrant : warrant factor = 0.5<br />1 share to 1 warrant : warrant factor = 1</div>} placement="bottom" arrow>
																<InfoIcon style={{ fontSize: 16, cursor: 'pointer' }}/>
															</Tooltip>
														</p>
														<TextField type="text" fullWidth value={this.state.Warrant_share} onChange={(e) => { this.handleWarrantChangeshare(e); }} />
														{this.state.isSubmit && errors && <span style={{ color: 'red', fontSize: 14 }}>{errors.Warrant_shareErr}</span>}
													</div>
													<div className="col-md-4">
														<p className="header">CUSIP ID</p>
														<TextField type="text" fullWidth value={this.state.warrant_cusip} onChange={(e) => { this.handleWarrantChangeCusip(e); }} />
														{this.state.isSubmit && errors && <span style={{ color: 'red', fontSize: 14 }}>{errors.warrant_cusip}</span>}
													</div>
													<div className="col-md-4">
														<p className="header" style={{ marginTop: 10 }}>Ticker symbol</p>
														<TextField type="text" fullWidth value={this.state.ticker_symbol} onChange={(e) => { this.handleTickerSymbol(e); }} />
														{this.state.isSubmit && errors && <span style={{ color: 'red', fontSize: 14 }}>{errors.ticker_symbol}</span>}
													</div>
													<div className="col-md-4">
														<p className="header" style={{ marginTop: 10 }}>Underwriter Concession Amount</p>
														<TextField type="text" fullWidth value={this.state.underwriter_concession_amount} onChange={(e) => { this.handleUnderwriterConcessionAmount(e); }} />
														{this.state.isSubmit && errors && <span style={{ color: 'red', fontSize: 14 }}>{errors.underwriter_concession_amount}</span>}
													</div>
													<div className="col-md-4">
														<div className="warrent-dsp-checkbox">
															<FormControlLabel
																control={
																	<Checkbox
																		checked={this.state.isDsp}
																		onChange={(e) => { this.selectDspCheckbox(e); }}
																		name="dsp"
																		color="primary"
																	/>
																}
																label="Include DSP"
															/>
														</div>
													</div>
												</div>
												<div className="col-md-12" style={{ textAlign: 'center', marginTop: 16, marginBottom: 16 }}>
													<Button variant="contained" color="primary" onClick={() => { this.updateWarrant(item) }} >Submit</Button>
												</div>
											</div>}
									</CardContent>
								</Card>
								)
							}
						</div>
					)}
					</BottomScrollListener>
				</div>
				{
					lastData > 0 && isLoadAllocation &&
					<div style={{textAlign: 'center'}}><img src={loaderImg} alt='Loader' style={{width: '60px'}} /></div> 
				}
				{
					AllocationView && AllocationView.length === 0 && (!isLoadAllocation) &&
					<div className="nodatafound" style={{ paddingTop: '30px', paddingLeft: '20px' }}>
						No offering found!
					</div>
				}
				
				<Dialog
					onClose={this.closeRunAllocationModal}
					aria-labelledby="customized-dialog-title"
					open={this.state.runAllocationModal}
					maxWidth={"md"}
					fullWidth={true}
					scroll={'paper'}
					>
						{/* allocatedData */}
					<DialogTitle onClose={this.closeRunAllocationModal} >Run Allocation</DialogTitle>
					<DialogContent>
						<div className="col-md-12 runAllocationmodal" style={{ marginTop: 20, textAlign: 'center' }}>
							<RadioGroup row  name="run" value={this.state.algo} onChange={(e) => this.changeRunAllocation(e)} style={{ display: 'inline-block' }}>
								<FormControlLabel value="0" control={<Radio />} label="Base allocation" style={styles.radioButton} />
								<FormControlLabel value="1" control={<Radio />} label="Round lot allocation" style={styles.radioButton} />
								<FormControlLabel value="2" control={<Radio />} label="$2500 lot allocation" style={styles.radioButton} />
								<FormControlLabel value='3' control={<Radio checked={(this.state.algo === '3' || this.state.algo === '4')} />} label="Combo" style={styles.radioButton} />
							</RadioGroup>
						</div>
						<div className="row">
							<div className="col-md-6 runAllocationmodal" style={{ textAlign: 'center' }}>
								<div className="row">
									<div className="col-md-6 runAllocationmodal" style={{ marginTop: 20, textAlign: 'center' }}>
										<div style={{marginTop: 6, marginBottom: 8, fontSize: 14, textAlign: 'left'}}>
											{
												this.state.allocatedData.ordr_dollar_share === 0 ?
												"Minimum allocated amount": "Minimum allocated shares"
											}
										</div>
										<FormControl variant="outlined">
											{
												this.state.allocatedData.ordr_dollar_share === 0 ? 
												
												<TextField
													// label="Maximum allocated amount"
													id="margin-none"
													onChange={this.isDollerChange}
													defaultValue={minAllocAmount}
													fullWidth={true}
													InputProps={{
														startAdornment: (
														<InputAdornment position="start" className="runallocation-dollar-sign" style={{padding: '24px 0px 0', marginRight: 0}}>$</InputAdornment>
														),
														inputComponent: NumberFormatMinMax,
													}}
												/>
												:
												<TextField
													// label="Maximum allocated shares"
													id="margin-none"
													onChange={this.isDollerChange}
													defaultValue={minAllocShares}
													InputProps={{
														inputComponent: NumberFormatMinMax,
													}}
												/>
											}
										</FormControl>
									</div>
									<div className="col-md-6 runAllocationmodal" style={{ marginTop: 20, textAlign: 'center' }}>
										<div style={{marginTop: 6, marginBottom: 8, fontSize: 14, textAlign: 'left'}}>
											{
												this.state.allocatedData.ordr_dollar_share === 0 ?
												"Maximum allocated amount": "Maximum allocated shares"
											}
										</div>
										<FormControl variant="outlined">
											{
												this.state.allocatedData.ordr_dollar_share === 0 ? 
												
												<TextField
													// label="Minimum allocated amount"
													id="margin-none"
													onChange={this.maximumValueChange}
													defaultValue={this.state.maximum_value}
													fullWidth={true}
													InputProps={{
														startAdornment: (
															<InputAdornment position="start" className="runallocation-dollar-sign" style={{padding: '24px 0px 0', marginRight: 0}}>$</InputAdornment>
															),
														inputComponent: NumberFormatMinMax,
													}}
												/>
												:
												<TextField
													// label="Minimum allocated shares"
													id="margin-none"
													onChange={this.maximumValueChange}
													defaultValue={this.state.maximum_value}
													InputProps={{
														inputComponent: NumberFormatMinMax,
													}}
												/>
											}
										</FormControl>
									</div>
									<div className="col-md-6 runAllocationmodal" style={{ marginTop: 20, textAlign: 'left' }}>
										<div style={{marginTop: 6, marginBottom: 8}}>Allocation percentage</div>
										<TextField
											fullWidth={true}
											name="allocationPercentage"
											defaultValue={this.state.allocationPercentage}
											onChange={this.onValueChangeHandler}          
											InputProps={{
												inputComponent: NumberFormatCustom,                    
											}}
										/>
									</div>
									<div className="col-md-6 runAllocationmodal" style={{ marginTop: 20, textAlign: 'left' }}>
										<div style={{marginTop: 36}}>
											<FormControlLabel
												control={
													<Checkbox
														checked={this.state.ignoreTime}
														onChange={(e) => { this.handleChangeIgnoreTime(e); }}
														name="ignoreTime"
														color="primary"
													/>
												}
												label="Ignore time"
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6 runAllocationmodal" style={{ marginTop: 20}}>
								{(this.state.algo === '3' || this.state.algo === '4') && 
							
								// <FormGroup>
									<Typography component="div">
										<label style={{ textAlign: 'left' }}>Priority</label>
										<div className="row">
											<div className="col-md-5">
												<div className={this.state.algo === '3' ? 'selection-bold' : ''} style={{marginTop: 6, marginBottom: 8}}>Round lot</div>
												<FormControl variant="outlined">
													<TextField
														onChange={this.handleChangeRoundLotAllocated}
														value={this.state.roundLotAllocated}
													/>
												</FormControl>
												{
													this.state.showAllocationMeta && (this.state.showAllocationMeta.round_lot_requested || this.state.showAllocationMeta.round_lot_requested === 0) &&
													<div>
														{
															(this.state.roundLotAllocated === '' ? Number('0') : Number(this.state.roundLotAllocated)) > this.state.showAllocationMeta.round_lot_requested && 
															<div style={{color: 'red'}}>Round lot should not greater then {this.state.showAllocationMeta.round_lot_requested}</div>
														}
													</div>
												}
											</div>
											<div className="col-md-2">
												<Switch checked={this.state.algo === '3' ? false : true} onChange={this.handleSwitchChange} name="checkedC" color="primary" className="round-lot-switch" /> 
											</div>
											<div className="col-md-5">
												<div className={this.state.algo === '4' ? 'selection-bold' : ''} style={{marginTop: 6, marginBottom: 8}}>2500 Round lot</div>
												<FormControl variant="outlined">
													<TextField
														onChange={this.handleChangeLotAllocated2500}
														value={this.state.lotAllocated2500}
													/>
												</FormControl>
												{
													this.state.showAllocationMeta && (this.state.showAllocationMeta['2500_lot_requested'] || this.state.showAllocationMeta['2500_lot_requested'] === 0) &&
													<div>
														{
															this.state.lotAllocated2500 === '' ? Number('0') : Number(this.state.lotAllocated2500) > this.state.showAllocationMeta['2500_lot_requested'] && 
															<div style={{color: 'red'}}>2500 Round lot should not greater then {this.state.showAllocationMeta['2500_lot_requested']}</div>
														}
													</div>
												}
											</div>
										</div>
									</Typography>
								// </FormGroup>
								}
							</div>
						</div>
					</DialogContent>
					<DialogActions style={{display: 'block'}}>
						{
							((this.state.lotAllocated2500 === '' ? Number('0') : Number(this.state.lotAllocated2500)) > this.state.showAllocationMeta['2500_lot_requested']) || ((this.state.roundLotAllocated === '' ? Number('0') : Number(this.state.roundLotAllocated)) > this.state.showAllocationMeta.round_lot_requested) ?
							<div style={{textAlign: 'center'}}>
								<Button variant="contained" color="primary" disabled={true} onClick={() => this.runAllocations()}>Submit</Button>
							</div>
							:
							<div style={{textAlign: 'center'}}>
								<Button variant="contained" color="primary" onClick={() => this.runAllocations()}>Submit</Button>
							</div>
						}
					</DialogActions>
				</Dialog>
				<Dialog
					onClose={this.closeAllocationEmailModal}
					aria-labelledby="customized-dialog-title"
					open={this.state.allocationEmailConfirm}
					maxWidth={"sm"}
					fullWidth={true}
					scroll={'paper'}
					>
					<DialogTitle onClose={this.closeAllocationEmailModal} >Confirmation</DialogTitle>
					<DialogContent>
						<div className="col-md-12" style={{textAlign: 'center'}}>
							<h5> Do you want to send allocation email ?</h5>
						</div>
					</DialogContent>
					<DialogActions style={{display: 'block'}}>
						<div style={{textAlign: 'center'}}>
							<Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.doSendAllocationEmail(this.state.allocationEmailAllocationInfo)}>
								Send
							</Button>
							<Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="secondary" onClick={() => this.closeAllocationEmailModal()}>
								Cancel
							</Button>
						</div>
					</DialogActions>
				</Dialog>
				<Dialog
					onClose={this.closeRunSuccessModal}
					aria-labelledby="customized-dialog-title"
					open={this.state.runSuccessModal}
					maxWidth={"sm"}
					fullWidth={true}
					scroll={'paper'}
					>
					<DialogContent>
						<div className="col-md-12" style={{textAlign: 'center'}}>
							<h5> { this.state.allocationMessage } </h5>
						</div>
					</DialogContent>
					<DialogActions style={{display: 'block'}}>
						<div style={{textAlign: 'center'}}>
							<Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="secondary" onClick={() => this.closeRunSuccessModal()}>
							Close
							</Button>
						</div>
					</DialogActions>
				</Dialog>
				<Dialog
						onClose={this.closeFinalProspectusModal}
						aria-labelledby="customized-dialog-title"
						open={this.state.sendProspectConfirm}
						maxWidth={"sm"}
						fullWidth={true}
						scroll={'paper'}
						>
						<DialogTitle onClose={this.closeFinalProspectusModal} >Confirmation</DialogTitle>
						<DialogContent>
							<div className="col-md-12" style={{textAlign: 'center'}}>
								<h5> Please confirm this is the final  prospectus of this allocation. You want to send final prospectus ?</h5>
							</div>
						</DialogContent>
						<DialogActions style={{display: 'block'}}>
							<div style={{textAlign: 'center'}}>
								<Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.sendFinalProspectus(this.state.sendProspectusAllocationInfo)}>
									Send
								</Button>
								<Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="secondary" onClick={() => this.closeFinalProspectusModal()}>
									Cancel
								</Button>
							</div>
						</DialogActions>
					</Dialog>
			</div>
		)
	}
}

AllocationListComponent.propTypes = {
	fetchAllocationData: PropTypes.func,
	AllocationView: PropTypes.any,
	tabIndex: PropTypes.any,
	runAllocationRes: PropTypes.any,
	createWarrantRes: PropTypes.any,
	sendIOInotificationRes: PropTypes.any,
	sendAllocationEmailRes: PropTypes.any,
	updateProspectusRes: PropTypes.any,
	brokerDealerAllocationRes: PropTypes.any,
	deleteAllocationRes: PropTypes.any,
	isLoadAllocation: PropTypes.bool,
	lastData: PropTypes.any,
	reloadAllocation: PropTypes.func,
    showAllocationMetadataRes: PropTypes.any,
	selectedIntactBr: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
	runAllocationRes: doRunAllocationRes,
	createWarrantRes: doCreateWarrantRes,
	sendIOInotificationRes: doSendIOInotificationRes,
	sendAllocationEmailRes: doSendAllocationEmailRes,
	updateProspectusRes: doUpdateProspectusRes,
	brokerDealerAllocationRes: doFetchBrokerDealerAllocationRes,
	deleteAllocationRes: doDeleteAllocationRes,
    showAllocationMetadataRes: doFetchShowAllocationMetadataRes,
});
function mapDispatchToProps(dispatch) {
	return {
		sendRunAllocation: (data) => dispatch(sendRunAllocation(data)),
		sendCreateWarrant: (data) => dispatch(sendCreateWarrant(data)),
		sendIOInotification: (ext_id) => dispatch(sendIOInotification(ext_id)),
		sendAllocationEmail: (ext_id) => dispatch(sendAllocationEmail(ext_id)),
		updateProspectus: (data) => dispatch(updateProspectus(data)),
		fetchBrokerDealerAllocation: (ext_id) => dispatch(fetchBrokerDealerAllocation(ext_id)),
		doDeleteAllocation: (data) => dispatch(submitDeleteAllocation(data)),
        fetchShowAllocationMetaData: (ext_id) => { dispatch(fetchShowAllocationMetaData(ext_id)) },
	};
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AllocationListComponent);
