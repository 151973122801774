
import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './LayoutWrapperAuth-jss';
import classNames from 'classnames';

export const AppContext = React.createContext();

class LayoutWrapperAuth extends React.Component {
  render() {
    const { classes, children } = this.props;
    return (
        <div className={classNames(classes.appFrameOuter, classes.solidBg)}>
            <main className={classes.outerContent} id="mainContent">
                <div className={classes.petal} />
                {children}
            </main>
        </div>
    );
  }
}

LayoutWrapperAuth.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
};

export default withStyles(styles)(LayoutWrapperAuth);