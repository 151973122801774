export default function validate(values) {
  const errors = {};
  if (!values.name) {
    errors.name = 'Please enter name';
  }
  if (!values.mpid) {
    errors.mpid = 'Please enter mpid';
  }
  if (!values.commission_percentage) {
    errors.commission_percentage = 'Please enter commission percentage';
  }
  if (!values.address) {
    errors.address = 'Please enter address';
  }
  if (!values.city) {
    errors.city = 'Please enter city';
  }
  if (!values.state) {
    errors.state = 'Please enter state';
  }
  if (!values.country) {
    errors.country = 'Please enter country';
  }
  
  if (!values.phone) {
    errors.phone = 'Please enter phone number';
  }
  if (!values.zipcode) {
    errors.zipcode = 'Please enter postal code';
  }
  if (!values.hidden_marker) {
    errors.hidden_marker = 'Please select hidden marker';
  }
  if (values.hidden_marker) {
    if (values.hidden_marker === 'active_partner' || values.hidden_marker === 'active_bd_and_partner') {
      if (!values.namespace) {
        errors.namespace = 'Please enter namespace';
      }
    }
  }
  return errors;
}
