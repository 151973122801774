import * as types from './actionTypes';  
import offeringStatsGraphApi from '../api/offeringStatsGraphApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doFetchOfferingStatsGraph(status, pagenum) {

  return {
    type: types.DO_FETCH_OFFERING_STATS_GRAPH, 
    status,
    pagenum
  };
}

export function doFetchOfferingStatsGraphRes(data) {  
  return {
    type: types.DO_FETCH_OFFERING_STATS_GRAPH_RES, 
    data
  };
}

export function fetchOfferingStatsGraph(status, pagenum) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      offeringStatsGraphApi.doFetchOfferingStatsGraph(status, pagenum).then(data => {
        dispatch(doFetchOfferingStatsGraphRes(data));
        dispatch(doFetchOfferingStatsGraphRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
  
}