import * as types from './actionTypes';
import {logout} from '../helper/helper';
import lastClosingPriceApi from '../api/lastClosingPriceApi';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function lastClosingPrice(data) {
  return {
    type: types.LAST_CLOSING_PRICE,
    data
  };
}

export function lastClosingPriceRes(data) {
  return {
    type: types.LAST_CLOSING_PRICE_RES,
    data
  };
}

export function fetchLastClosingPriceData(data) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      lastClosingPriceApi.lastClosingPrice(data).then(data => {
        dispatch(lastClosingPriceRes(data));
        dispatch(lastClosingPriceRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          dispatch(lastClosingPriceRes(data));
          dispatch(lastClosingPriceRes(null));
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        } 
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
}