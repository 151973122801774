import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './stats.scss';
// import { fetchOfferingStats, doFetchOfferingStatsRes } from '../../actions/offeringStatsActions';
import { fetchOfferingStatsGraph, doFetchOfferingStatsGraphRes } from '../../actions/offeringStatsGraphActions';
import { numberWithCommasAndDollar } from '../../helper/helper';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import Paper from '@material-ui/core/Paper';
import CancelIcon from '@material-ui/icons/Cancel';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import Tooltips from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import GridTable from '@nadavshaar/react-grid-table';
import FileCopyIcon from '@material-ui/icons/FileCopy';
class OfferingStatsComponent extends React.PureComponent {

	constructor() {
		super();
		const columns = [
			{ id: 1, width: '25%', field: 'period', label: 'Period', visible: true, sortable: true, cellRenderer: this.periodTemplate },
			{ id: 2, width: '25%', field: 'count', label: 'Offerings', visible: true, sortable: true, cellRenderer: this.countTemplate },
			{ id: 3, width: '25%', field: 'requested_amount', label: 'Requested Amount', visible: true, sortable: true, cellRenderer: this.requestedAmountTemplate },
			{ id: 4, width: '25%', field: 'allocated_amount', label: 'Allocated Amount', visible: true, sortable: true, cellRenderer: this.allocatedAmountTemplate }
		];
		this.state = {
			cols: columns,
			isRender: false,
			offeringOrderCount:[],
			graphData: [],
			showGraph: false,
			url: '',
			pagenum: 1,
			selectedOfferingMeta: '',
			tabIndex: 0
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (props.fetchOfferingStatsGraphRes) {
			if (props.fetchOfferingStatsGraphRes.data && props.fetchOfferingStatsGraphRes.data.offeringStatsGraph) {
				if (props.fetchOfferingStatsGraphRes.data.offeringStatsGraph.message === "Success") {
					let arrData = props.fetchOfferingStatsGraphRes.data.offeringStatsGraph.data;
                    for (let i = 0; i < arrData.length; i++) {

                        Object.keys(arrData[i]).forEach((field) => { /*eslint-env es6*/
                        if (arrData[i][field] === null) {
                            arrData[i][field] = "";
                        }
                        })
                    };
					return {
						graphData: arrData,
					};
				}
			}
		}
		return null;
	}
	countTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return  <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
			<Tooltips title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
				<div className="text-one-line" style={{width: '90%'}} >{value}</div>
			</Tooltips>
			{
				value !== '' &&
				<Tooltips title={'Copy to clipboard'} interactive >
					<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
				</Tooltips>
			}
		</div>;
	}
	periodTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return  <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
			<Tooltips title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
				<div className="text-one-line" style={{width: '90%'}} >{value}</div>
			</Tooltips>
			{
				value !== '' &&
				<Tooltips title={'Copy to clipboard'} interactive >
					<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
				</Tooltips>
			}
		</div>;
	}
	requestedAmountTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return <div className='rgt-cell-inner rgt-text-truncate' style={{ overflow: 'hidden', display: 'flex', alignItems: 'center' }}>
			<Tooltips title={(value !== undefined || value !== null) ? numberWithCommasAndDollar(value) : ''} interactive={!value} >
				<div className="text-one-line" style={{ width: '90%' }} >{numberWithCommasAndDollar(value)}</div>
			</Tooltips>
			{
				value !== '' &&
				<Tooltips title={'Copy to clipboard'} interactive >
					<Button style={{ padding: 4, minWidth: 10, color: "#7e817c" }} onClick={() => navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{ fontSize: 14 }} /> </Button>
				</Tooltips>
			}
		</div>;
	}
	allocatedAmountTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return <div className='rgt-cell-inner rgt-text-truncate' style={{ overflow: 'hidden', display: 'flex', alignItems: 'center' }}>
			<Tooltips title={(value !== undefined || value !== null) ? numberWithCommasAndDollar(value) : ''} interactive={!value} >
				<div className="text-one-line" style={{ width: '90%' }} >{numberWithCommasAndDollar(value)}</div>
			</Tooltips>
			{
				value !== '' &&
				<Tooltips title={'Copy to clipboard'} interactive >
					<Button style={{ padding: 4, minWidth: 10, color: "#7e817c" }} onClick={() => navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{ fontSize: 14 }} /> </Button>
				</Tooltips>
			}
		</div>;
	}
	openBarGraph = (url) => {
		this.setState({
			graphData: [],
			selectedOfferingMeta: this.props.offeringMetaData[url],
			showGraph: true,
			url: url,
			pagenum: 1,
			tabIndex: 0
		}, () => {
			this.props.fetchOfferingStatsGraph(url, this.state.pagenum);
		})
	}
	renderTooltip = (item) => {
		if (item && item.payload && item.payload.length > 0) {
			return <div style={{ padding: 8, background: '#fff' }}>
				<p style={{ fontWeight: 'bold', fontSize: 15, color: '#000', marginBottom: 0 }}>{item.payload[0].payload.new_period}</p>
				<p style={{ fontWeight: 'bold', fontSize: 15, color: '#0A5074', marginBottom: 0 }}>Requested amount: {numberWithCommasAndDollar(item.payload[0].payload.requested_amount)}</p>
				<p style={{ fontWeight: 'bold', fontSize: 15, color: '#9ac051', marginBottom: 0 }}>Allocated amount: {numberWithCommasAndDollar(item.payload[0].payload.allocated_amount)}</p>
				<p style={{ fontWeight: 'bold', fontSize: 15, color: '#000000', marginBottom: 0 }}>Percentage Fill: {((Number(item.payload[0].payload.allocated_amount) * 100 )/Number(item.payload[0].payload.requested_amount)).toFixed(2)}%</p>
				<p style={{ fontWeight: 'bold', fontSize: 15, color: '#FF0000', marginBottom: 0 }}>Count: {item.payload[0].payload.count}</p>
			</div>
		}

	}
	formatXAxis = (tickItem) => {
		return tickItem;
	}
	doPrevious = () => {
		this.setState({
			pagenum: this.state.pagenum + 1
		}, () => {
			this.props.fetchOfferingStatsGraph(this.state.url, this.state.pagenum);
		})
	}
	doNext = () => {
		this.setState({
			pagenum: this.state.pagenum - 1
		}, () => {
			this.props.fetchOfferingStatsGraph(this.state.url, this.state.pagenum);
		})
	}
	handleTabChange = (event, newValue) => {
		this.setState({
			tabIndex: newValue,
		}, () => { });
	}
	render() {
		const { tabIndex } = this.state;
		let chartData = this.state.graphData;
		var monthNames = [
			'Jan', 'Feb', 'Mar',
			'Apr', 'May', 'Jun', 'Jul',
			'Aug', 'Sep', 'Oct',
			'Nov', 'Dec',
		];
		if (chartData.length > 0) {
			chartData.forEach((item) => {
				if (item.period) {
					let splitDate = item.period.split('/')
					item.new_period = monthNames[splitDate[0] - 1] + ' ' + splitDate[1];
				}
			});
		}
		return (
			<div className="stats_offering_content_body">
				<div>
					<div className="col-md-12" style={{ paddingLeft: 52, paddingRight: 52, paddingTop: 10, paddingBottom: 15 }}>
						<div className="total-offering-state-offering">Total Offerings : {this.props.totalCount}</div>

						<div className="row">
							<div className="col-md-3">
								<Tooltips title="Click to see more detail" placement="bottom">
									<Paper elevation={3} className="offering-stats-box" onClick={() => this.openBarGraph('active')}>
										<div className="row">
											<div className="col-md-6">
												<div className="offering-stats-box-icon-active">
													<CheckIcon style={{color: '#fff', fontSize: 30}} />
												</div>
											</div>
											<div className="col-md-6">
												<h2 className="mt-2" style={{textAlign: 'right'}}>{this.props.activeCount}</h2>
											</div>
										</div>
										<p className="mt-4" style={{textAlign: 'center'}}>Active Offerings</p>
									</Paper>
								</Tooltips>
							</div>
							<div className="col-md-3">
								<Tooltips title="Click to see more detail" placement="bottom">
									<Paper elevation={3} className="offering-stats-box" onClick={() => this.openBarGraph('effective')}>
										<div className="row">
											<div className="col-md-6">
												<div className="offering-stats-box-icon-effective">
													<TrendingUpIcon style={{color: '#fff', fontSize: 30}} />
												</div>
											</div>
											<div className="col-md-6">
												<h2 className="mt-2" style={{textAlign: 'right'}}>{this.props.effectiveCount}</h2>
											</div>
										</div>
										<p className="mt-4" style={{textAlign: 'center'}}>Effective Offerings</p>
									</Paper>
								</Tooltips>
							</div>
							<div className="col-md-3">
								<Tooltips title="Click to see more detail" placement="bottom">
									<Paper elevation={3} className="offering-stats-box" onClick={() => this.openBarGraph('cancelled')}>
										<div className="row">
											<div className="col-md-6">
												<div className="offering-stats-box-icon-cancelled">
													<BlockIcon style={{color: '#fff', fontSize: 30}} />
												</div>
											</div>
											<div className="col-md-6">
												<h2 className="mt-2" style={{textAlign: 'right'}}>{this.props.cancelledCount}</h2>
											</div>
										</div>
										<p className="mt-4" style={{textAlign: 'center'}}>Cancelled Offerings</p>
									</Paper>
								</Tooltips>
							</div>
							<div className="col-md-3">
								<Tooltips title="Click to see more detail" placement="bottom">
									<Paper elevation={3} className="offering-stats-box" onClick={() => this.openBarGraph('closed')}>
										<div className="row">
											<div className="col-md-6">
												<div className="offering-stats-box-icon-closed">
													<CancelIcon style={{color: '#fff', fontSize: 30}} />
												</div>
											</div>
											<div className="col-md-6">
												<h2 className="mt-2" style={{textAlign: 'right'}}>{this.props.closedCount}</h2>
											</div>
										</div>
										<p className="mt-4" style={{textAlign: 'center'}}>Closed Offerings</p>
									</Paper>
								</Tooltips>
							</div>
						</div>
						{
							this.state.showGraph &&
							<div className="row mt-5">
								<div className="col-md-12">
									<h3 className="col-md-12" style={{textAlign: 'center', textTransform: 'capitalize'}}>{this.state.url} offerings</h3>
									
									<div className="row justify-content-md-center">
										<div className="col-md-6 offering-state-detail">
											<div className="row offering-state-detail-row">
												<div className="col-md-6">
													Total Number of Orders
												</div>
												<div className="col-md-6">
													{(this.state.selectedOfferingMeta && this.state.selectedOfferingMeta.num_orders) ? this.state.selectedOfferingMeta.num_orders : 0}
												</div>
											</div>
											<div className="row offering-state-detail-row">
												<div className="col-md-6">
													Requested Amount:
												</div>
												<div className="col-md-6">
													{numberWithCommasAndDollar(this.state.selectedOfferingMeta.requested_amount)}
												</div>
											</div>
											<div className="row offering-state-detail-row">
												<div className="col-md-6">
													Allocated Amount:
												</div>
												<div className="col-md-6">
													{numberWithCommasAndDollar(this.state.selectedOfferingMeta.allocated_amount)}
												</div>
											</div>
										</div>
										{
											chartData && chartData.length > 1 &&
											<div className="col-md-12" style={{textAlign: 'center', marginBottom: 16}}>
												<div>(Showing data between {chartData[chartData.length - 1].period} and {chartData[0].period})</div>
											</div>
										}
										{
											chartData && chartData.length === 1 &&
											<div className="col-md-12" style={{textAlign: 'center', marginBottom: 16}}>
												<div>(Showing data of {chartData[0].period})</div>
											</div>
										}
									</div>
								</div>
								<div className="col-md-12">
									<Tabs value={tabIndex} onChange={this.handleTabChange} >
										<Tab label="Graph" />
										<Tab label="Table" />
									</Tabs>
									{
										tabIndex === 0 &&
										<Typography component="div" style={{ paddingTop: 24, paddingBottom: 24 }}>
											<div style={{ maxWidth: "100%" }}>
												<div className="col-md-12 mt-4">
													<div style={{width: '100%', height: '100%'}}>
														<div id="underwriterbarchart">
															<ResponsiveContainer width='100%' height={450} >
																<BarChart
																	// width={1024}
																	height={380}
																	data={chartData}
																	margin={{
																	top: 5, right: 30, left: 20, bottom: 5,
																	}}
																>
																	{/* <CartesianGrid strokeDasharray="3" /> */}
																	<XAxis dataKey="new_period" tick={{fontSize: 13}} tickFormatter={this.formatXAxis} angle={-60} height={90} allowDataOverflow={false} textAnchor="end" interval="preserveStart" xAxisId={2} />
																	<XAxis dataKey="new_period" tick={{fontSize: 13}} tickFormatter={this.formatXAxis} angle={-60} height={90} allowDataOverflow={false} textAnchor="end" interval="preserveStart" xAxisId={1} hide />
																	<XAxis dataKey="new_period" tick={{fontSize: 13}} tickFormatter={this.formatXAxis} angle={-60} height={90} allowDataOverflow={false} textAnchor="end" interval="preserveStart" xAxisId={0} hide />
																	<YAxis />
																	<Tooltip
																	cursor={false}
																	content={this.renderTooltip}
																	wrapperStyle={{
																		borderColor: 'white',
																		boxShadow: '2px 2px 3px 0px rgb(204, 204, 204)',
																		background: 'white'
																	}}
																	contentStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
																	labelStyle={{ fontWeight: 'bold', color: '#666666' }}
																	/>
																	<Legend />
																	
																	<Bar name="Requested amount" dataKey="requested_amount" barSize={35} xAxisId={2} fill="#0A5074" fillOpacity={0.7}></Bar>
																	<Bar name="Allocated amount" dataKey="allocated_amount" barSize={25} xAxisId={1} fill="#9ac051" ></Bar>
																	<Bar name="Count" dataKey="count" barSize={25} xAxisId={0} fill="#FF0000"></Bar>
																</BarChart>
															</ResponsiveContainer>
														</div>
														<div className="row mt-4">
															<div className="col-md-6" style={{textAlign: 'right'}}>
																<Button variant="contained" color="primary" onClick={() => this.doPrevious()} disabled={this.state.graphData.length === 0 ? true : false}>Previous</Button>
															</div>
															<div className="col-md-6">
																<Button  variant="contained" color="primary" onClick={() => this.doNext()} disabled={this.state.pagenum === 1 ? true : false}>Next</Button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</Typography>
									}
									{
										tabIndex === 1 &&
										<Typography component="div" style={{ paddingTop: 24, paddingBottom: 24 }}>
											<div style={{ maxWidth: "100%" }}>
												<div className="col-md-12 revenuetable" >
													<GridTable
														columns={this.state.cols}
														rows={this.state.graphData}
														isPaginated={false}
														isVirtualScroll={true}
														showSearch={true}
														showRowsInformation={false}
														isHeaderSticky={true}
													/>
													<div className="row mt-4">
														<div className="col-md-12">
															<Button variant="contained" color="primary" onClick={() => this.doPrevious()} disabled={this.state.graphData.length === 0 ? true : false} style={{marginRight: 16}}>Previous</Button>
															<Button  variant="contained" color="primary" onClick={() => this.doNext()} disabled={this.state.pagenum === 1 ? true : false} style={{marginLeft: 16}}>Next</Button>
														</div>
													</div>
												</div>
											</div>
										</Typography>
									}
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		)
	}
}

OfferingStatsComponent.propTypes = {
	// fetchOfferingStatsRes: PropTypes.any,
	fetchOfferingStatsGraphRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	// fetchOfferingStatsRes: doFetchOfferingStatsRes,
	fetchOfferingStatsGraphRes: doFetchOfferingStatsGraphRes,
});
function mapDispatchToProps(dispatch) {
	return {
		// fetchOfferingStats: (data) => dispatch(fetchOfferingStats(data)),
		fetchOfferingStatsGraph: (status, pagenum) => dispatch(fetchOfferingStatsGraph(status, pagenum)),
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OfferingStatsComponent);