import * as types from './actionTypes';  
import ioiNotificationApi from '../api/ioiNotificationApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doSendIOInotification(status) {

  return {
    type: types.DO_SEND_IOI_NOTIFICATION, 
    status
  };
}

export function doSendIOInotificationRes(data) {  
  return {
    type: types.DO_SEND_IOI_NOTIFICATION_RES, 
    data
  };
}
export function sendIOInotification(status) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      ioiNotificationApi.doSendIOInotification(status).then(data => {
        if (data.error === 0) {
          dispatch(doSendIOInotificationRes(data));
          dispatch(doSendIOInotificationRes(null));
        }
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
  
}