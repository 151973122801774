import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import CurrencyFormat from 'react-currency-format';
import Swal from 'sweetalert2';
// import _ from 'lodash';
import './orders.scss';
import Dashboard from '../../component/Dashboard';
import { getItem } from '../../utils/localStore';
import { fetchOfferingOrders, doFetchOfferingOrdersRes } from '../../actions/offeringOrdersActions';
import { getOfferingDetail } from '../../actions/offeringDetailActions';
import { getFollowerList, doFetchFollowerListRes } from '../../actions/followerListActions';
import { updateRequestedAmount, doUpdateRequestedAmountRes } from '../../actions/updateRequestedAmountActions';
import { deleteOrder, doDeleteOrderRes } from '../../actions/deleteOrderActions';
import { deleteAllOrder, doDeleteAllOrderRes } from '../../actions/deleteAllOrderActions';
import { getOffering } from '../../actions/offeringActions';
import { fetchStatsOfferingOrders, doFetchStatsOfferingOrdersRes } from '../../actions/statsOfferingOrdersActions';
import { dateTimeFormatConvert, numberWithCommas, numberWithCommasAndDollar, getCommaSeparatedTwoDecimalsNumber } from '../../helper/helper';
import ChartModal from './chartModal';
import NotificationModal from './notificationModal';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// import CreateIcon from '@material-ui/icons/Create';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { DialogActions } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import SortIcon from '@material-ui/icons/Sort';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import GridTable from '@nadavshaar/react-grid-table';
import DOMPurify from 'dompurify';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
	}))(props => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});
  
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

// let selectedCustomer = [];
let filterData = [];
// let dataTableData = [];
let isOrders = false;
class BrokerOrdersComponent extends React.PureComponent {

	constructor(props) {
		super(props);
		isOrders = false;
		// selectedCustomer = [];
		filterData = [];
		
		var columns = [
			{
				id: 'checkbox',
				pinned: true,
				className: '',
				width: '54px',
				minResizeWidth: 0,
				maxResizeWidth: null,
				resizable: false,
				visible: true, sortable: false,
				cellRenderer: this.singleCheckBoxTable,
				headerCellRenderer: this.allCheckBoxTable,
			},
			{ id: 'serial', label: '#S.No.', width: '100px', visible: true, sortable: false, cellRenderer: this.serialNumber },
			{ id: 1, field: 'account_name', label: 'Account Name', visible: true, sortable: false, cellRenderer: this.accountName, headerCellRenderer: this.orderCustomHeader },
			{ id: 2, field: 'name', label: 'User Name', visible: true, sortable: false, cellRenderer: this.userName, headerCellRenderer: this.orderCustomHeader },
			{ id: 3, field: 'email', label: 'User Email', visible: true, sortable: false, cellRenderer: this.userEmailTemplate, headerCellRenderer: this.orderCustomHeader },
			{ id: 4, field: 'created_at', label: 'Prospectus', visible: true, sortable: false, cellRenderer: this.createDateNew, headerCellRenderer: this.orderCustomHeader },
			{ id: 5, width: '250px', field: 'final_prospectus_datetime', label: 'Final Prospectus', visible: true, sortable: false, cellRenderer: this.prospectuDate, headerCellRenderer: this.orderCustomHeader },
			{ id: 6, width: '250px', field: 'requested_amount', label: 'Requested Amount', visible: true, sortable: false, cellRenderer: this.amountRequested, headerCellRenderer: this.orderCustomHeader },
			{ id: 7, width: '250px', field: 'allocated_amount', label: 'Allocated Amount', visible: true, sortable: false, cellRenderer: this.amountAllocated, headerCellRenderer: this.orderCustomHeader },
			{ id: 8, width: '250px', field: 'requested_shares', label: 'Requested Shares', visible: true, sortable: false, cellRenderer: this.sharesRequested, headerCellRenderer: this.orderCustomHeader },
			{ id: 9, width: '250px', field: 'allocated_shares', label: 'Allocated Shares', visible: true, sortable: false, cellRenderer: this.sharesAllocated, headerCellRenderer: this.orderCustomHeader },
			{ id: 10, width: '250px', field: 'buying_power_as_of', label: 'IPO Buying Power', visible: true, sortable: false, cellRenderer: this.availableBuyingPower, headerCellRenderer: this.orderCustomHeader },
			{ id: 11, field: 'status', label: 'Status', visible: true, sortable: false, cellRenderer: this.statusInfo, headerCellRenderer: this.orderCustomHeader },
			{ id: 12, field: 'reconfirmed_dt', label: 'Reconfirm', visible: true, sortable: false, cellRenderer: this.reconfirmDate, headerCellRenderer: this.orderCustomHeader },
			{ id: 13, field: 'dsp', label: 'DSP User', visible: true, sortable: false, cellRenderer: this.dspColumn, headerCellRenderer: this.orderCustomHeader },
			{ id: 14, width: '150px', field: 'communication', label: 'Comms', visible: true, sortable: false, cellRenderer: this.communicationtemplate, headerCellRenderer: this.orderCustomHeader },
		];
		if (JSON.parse(localStorage.getItem('columnArrOrder'))) {
			let colArr = JSON.parse(localStorage.getItem('columnArrOrder'));
            for (let item of columns) {
                for (let col of colArr) {
                    if (col.id === item.id) {
                        item.visible = col.visible
                    }
                }
            }
        }
		this.state = {
			isReadOnly: JSON.parse(getItem('isReadOnly')),
			isFormValid: false,
			searchbyCat: null,
			requestedAmountInput: '',
			amount_requested: '',
			shares_requested: '',
			requested_email: '',
			followerId: '',
			followerModal: false,
			openAdminModel: false,
			tempOrderList: [],
			offeringOrdersList: [],
			cols: columns,
			isActive: true,
			searching: '',
			notificationshow: null,
			offering_id: '',
			openEditPriceModal: false,
			orderInfo: null,
			offeringListCheck: false,
			selectedCustomerList: [],
			offeringName: '',
			brokerDealerName: [],
			filterSelectData: '',
			showChartModal: false,
			createdOffering: '',
			offeringMaxPrice: 0,
			uiRender: false,
			selectedBDs: [],
			offeringStatus: '',
			offering: '',
			offeringfollower: [],
			openOrderMessageModal: false,
			orderMessage: '',
			isOpenDeleteOrderModal: false,
			deletionReason: '',
			reason: '',
			mpid: '',
            tableFilter: {
                field: null,
                order: null,
            },
			checkedAll: false,
			lastShowOfferingOrders: 0,
			ordersLength: 0,
			isFetchOrder: false,
			filterBy: 'name',
			pagenum: 1,
			orgName: '',
            loadMoreAll: 'more',
			offeringBuildOutList: [],
			showDeleteModal: false
		}
		this.colOptions = [];

		for (const col of columns) {
		this.colOptions.push({ label: col.label, value: col });
		}
	}

	componentDidMount() {
		if (this.props.location.state) {
			let offeringCreate = this.props.location.state.offering;
			if (offeringCreate) {
				this.setState({
					offering_id: offeringCreate.ext_id,
					offering: offeringCreate,
					createdOffering: offeringCreate.created_at,
					offeringMaxPrice: offeringCreate.max_price,
					offeringName: offeringCreate.name,
					offeringStatus: offeringCreate.status === 'effective' ? 'closed': offeringCreate.status,
				}, () => {
				});
            }
            if (this.props.location.state.brokers) {
				this.setState({
                    mpid: this.props.location.state.brokers.mpid,
                    orgName: this.props.location.state.brokers.org_name
				}, () => {
				});
			}
            let payload = {
				ext_id: this.props.location.state.offering.ext_id,
				status: this.props.location.state.offering.status === 'effective' ? 'closed': this.props.location.state.offering.status,
				mpid: this.props.location.state.brokers.mpid,
				pagenum: 1
			}
			isOrders = true;
			this.props.loadofferingOrders(payload, true);
		}
	}
	
	static getDerivedStateFromProps(props, state) {
		if (props.offeringOrdersRes) {
			if (props.offeringOrdersRes.data && props.offeringOrdersRes.data.offeringOrders) {
				if (props.offeringOrdersRes.data.offeringOrders.message === 'Success' && isOrders) {
					isOrders = false;
					
					var dealName = [];
					for (let dealerName of props.offeringOrdersRes.data.offeringOrders.data) {
						if (dealerName.broker_dealer_name !== undefined) {
							let is_bd_name = dealName.find((bd_name) => bd_name.name === dealerName.broker_dealer_name);
							if (!(is_bd_name)) {
								dealName.push({ 'name': dealerName.broker_dealer_name, 'selectedItem': [] });
							}
						}
					}
					let newData = props.offeringOrdersRes.data.offeringOrders.data;
					for (let item of newData) {
						item.checked = false;
					}
                    var isOrderLength = state.ordersLength;
                    if(state.ordersLength === 0){
                        isOrderLength = newData.length
                    }
					if (state.isFetchOrder) {
						return {
							brokerDealerName: dealName,
							lastShowOfferingOrders: newData.length,
							ordersLength: isOrderLength,
							offeringOrdersList: state.offeringOrdersList.concat(newData),
							tempOrderList: newData,
							isFetchOrder: false
						};
					} else {
						return {
							brokerDealerName: dealName,
							lastShowOfferingOrders: newData.length,
							ordersLength: isOrderLength,
							offeringOrdersList: newData,
							tempOrderList: newData,
							showChartModal: false
						};
					}
				}
			}
		}
		if (props.offeringfollowerRes) {
			if (props.offeringfollowerRes.data && props.offeringfollowerRes.data.followerList) {
				if (props.offeringfollowerRes.data.followerList.message === 'Success') {
					return {
						offeringfollower: props.offeringfollowerRes.data.followerList.data,
					};
				}
			}
		}
		if (props.updateRequestedAmountRes) {
			if (props.updateRequestedAmountRes.data && props.updateRequestedAmountRes.data.updateRequestedAmount) {
				if (props.updateRequestedAmountRes.data.updateRequestedAmount.error === 0) {
					let payload = {
						ext_id: state.offering_id,
						status: state.offeringStatus,
						mpid: state.mpid,
						pagenum: state.pagenum,
					}
					if (state.tableFilter.field !== null) {
						payload.sortby = state.tableFilter.field;
						payload.sorttype = state.tableFilter.order;
					}
					if (state.searching !== '') {
						payload.search_key = state.filterBy;
						payload.search_keyword = state.searching;
					}
					isOrders = true;
					props.loadofferingOrders(payload, true);
					return {
						openEditPriceModal: false,
						orderMessage: props.updateRequestedAmountRes.data.updateRequestedAmount.message,
						openOrderMessageModal: true
					};	
				}
			}
		}
		if (props.deleteOrderRes) {
			if (props.deleteOrderRes.data && props.deleteOrderRes.data.deleteOrder) {
				if (props.deleteOrderRes.data.deleteOrder.message === 'Success') {
					let payload = {
						ext_id: state.offering_id,
						status: state.offeringStatus,
						mpid: state.mpid,
						pagenum: state.pagenum,
					}
					if (state.tableFilter.field !== null) {
						payload.sortby = state.tableFilter.field;
						payload.sorttype = state.tableFilter.order;
					}
					if (state.searching !== '') {
						payload.search_key = state.filterBy;
						payload.search_keyword = state.searching;
					}
					isOrders = true;
					props.loadofferingOrders(payload, true);
					return {
						openEditPriceModal: false,
						showDeleteModal: false,
						reason: ''
					};
				}
			}
		}
		if (props.deleteAllOrderRes) {
			if (props.deleteAllOrderRes.data && props.deleteAllOrderRes.data.deleteAllOrder) {
				if (props.deleteAllOrderRes.data.deleteAllOrder.message === 'Success') {
					let payload = {
						ext_id: state.offering_id,
						status: state.offeringStatus,
						mpid: state.mpid,
						pagenum: 1,
					}
					isOrders = true;
					props.loadofferingOrders(payload, true);
					return {
						pagenum: 1,
						isOpenDeleteOrderModal: false,
						deletionReason: '',
						selectedCustomerList: []
					};
				}
			}
		}
		if (props.offeringBuildOutRes) {
			if (props.offeringBuildOutRes.data && props.offeringBuildOutRes.data.statsOfferingOrders) {

				if (props.offeringBuildOutRes.data.statsOfferingOrders.message === 'Success') {
					return {
						offeringBuildOutList: props.offeringBuildOutRes.data.statsOfferingOrders.data,
					};
				}
			}
		}
		return null;
	}
    orderCustomHeader = ({tableManager, column, mode, ref, checked, disabled, indeterminate, onChange}) => {
        return <div>{column.label} {this.onSortFunction(column.field)}</div>
    }
	singleCheckBoxTable = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return (
			<Checkbox
				checked={data.checked !== undefined ? data.checked : false}
				onChange={(e) => this.handleChangeRowSelect(e, data, rowIndex)}
				value={data.email}
				color="primary"
				inputProps={{ 'aria-label': 'primary checkbox' }}
				disabled={this.state.isReadOnly}
			/>
		)
	}
	allCheckBoxTable = ({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => {
		return (
			<Checkbox
				checked={this.state.checkedAll}
				onChange={(e) => this.handleChangeSelectAll(e)}
				value="checkedAll"
				color="primary"
				inputProps={{ 'aria-label': 'primary checkbox' }}
				disabled={this.state.isReadOnly}
			/>
		)
	}
	serialNumber = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return (
			<div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
				<span className='rgt-text-truncate'>{rowIndex}</span>
			</div>
		)
	}
	userName = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return  <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
			<Tooltip title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
				<div className="text-one-line" style={{width: '90%'}} >{value}</div>
			</Tooltip>
			{
				value !== '' &&
				<Tooltip title={'Copy to clipboard'} interactive >
					<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
				</Tooltip>
			}
		</div>;
	}
	accountName = ({ tableManager, value, field, data, column, colIndex, rowIndex }) =>  {
		let classNameStr = 'text-one-line ';
		if (this.state.offeringStatus === 'cancelled') {
			classNameStr = 'text-one-line red_color';
		}
		return  <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
			<Tooltip title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
				<div className={classNameStr} style={{width: '90%'}} >{value}</div>
			</Tooltip>
			{
				value !== '' &&
				<Tooltip title={'Copy to clipboard'} interactive >
					<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
				</Tooltip>
			}
		</div>;
	}
	
	userEmailTemplate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) =>  {
		let classNameStr = 'text-one-line ';
		if (this.state.offeringStatus === 'cancelled') {
			classNameStr = 'text-one-line red_color';
		}
		return <div className='rgt-cell-inner rgt-text-truncate' style={{ overflow: 'hidden', display: 'flex', alignItems: 'center' }}>
			<Tooltip title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
				<div className={classNameStr} style={{ width: '90%' }} >{value}</div>
			</Tooltip>
			{
				value !== '' &&
				<Tooltip title={'Copy to clipboard'} interactive >
					<Button style={{ padding: 4, minWidth: 10, color: "#7e817c" }} onClick={() => navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{ fontSize: 14 }} /> </Button>
				</Tooltip>
			}
		</div>;
	}

	createDateNew = ({ tableManager, value, field, data, column, colIndex, rowIndex }) =>  {
		let classNameStr = 'text-one-line ';
		if (this.state.offeringStatus === 'cancelled') {
			classNameStr = 'text-one-line red_color';
		}
		return <div className='rgt-cell-inner rgt-text-truncate' style={{ overflow: 'hidden', display: 'flex', alignItems: 'center' }}>
			<Tooltip title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
				<div className={classNameStr} style={{ width: '90%' }} >{value === null ? 'N/A' : value}</div>
			</Tooltip>
			{
				value !== '' &&
				<Tooltip title={'Copy to clipboard'} interactive >
					<Button style={{ padding: 4, minWidth: 10, color: "#7e817c" }} onClick={() => navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{ fontSize: 14 }} /> </Button>
				</Tooltip>
			}
		</div>;
	}
	
	prospectuDate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) =>  {
		let classNameStr = 'text-one-line ';
		if (this.state.offeringStatus === 'cancelled') {
			classNameStr = 'text-one-line red_color';
		}
		return <div className='rgt-cell-inner rgt-text-truncate' style={{ overflow: 'hidden', display: 'flex', alignItems: 'center' }}>
			<Tooltip title={(value !== undefined || value !== null) ? dateTimeFormatConvert(data.final_prospectus_datetime) : ''} interactive={!value} >
				<div className={classNameStr} style={{ width: '90%' }} >{value === null ? 'N/A' : dateTimeFormatConvert(data.final_prospectus_datetime)}</div>
			</Tooltip>
			{
				value !== '' &&
				<Tooltip title={'Copy to clipboard'} interactive >
					<Button style={{ padding: 4, minWidth: 10, color: "#7e817c" }} onClick={() => navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{ fontSize: 14 }} /> </Button>
				</Tooltip>
			}
		</div>;
	}
	
	amountRequested = ({ tableManager, value, field, data, column, colIndex, rowIndex }) =>  {
		let classname = 'rgt-cell-inner ';
		if (this.state.offeringStatus === 'cancelled') {
		  classname = 'rgt-cell-inner red_color';
		}
		return <div className={classname} style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
			{
				data.requested_amount === null ?
					<div>N/A</div>
					:
					<div style={{ display: 'inline-flex', width: '100%' }} >
						<div style={{ textAlign: 'left', width: '100%' }}>{'$'}</div>
						<div style={{ textAlign: 'right', width: '100%' }} > {(this.state.offeringStatus === 'active' && (this.state.offering && this.state.offering.ordr_dollar_share === 0)) ?
							<span>
								{
									(!this.state.isReadOnly) ?
										<span onClick={() => this.openEditModal(data)} style={{ color: '#689F38', cursor: 'pointer', fontWeight: 'bold' }}>{getCommaSeparatedTwoDecimalsNumber(data.requested_amount)} </span>
										:
										<span>{getCommaSeparatedTwoDecimalsNumber(data.requested_amount)} </span>
								}
							</span>
							:
							<span>{getCommaSeparatedTwoDecimalsNumber(data.requested_amount)} </span>
						}</div>
					</div>
			}
		</div>;
	}
	
	amountAllocated = ({ tableManager, value, field, data, column, colIndex, rowIndex }) =>  {
		let classname = 'rgt-cell-inner ';
		if (this.state.offeringStatus === 'cancelled') {
		  classname = 'rgt-cell-inner red_color';
		}
		return (
			<div className={classname}>
				{
					data.allocated_amount === null ?
						<div>N/A</div>
						:
						<div style={{ display: 'inline-flex', width: '100%'}} >
							<div style={{ float: 'left', width: '100%' }}>{'$'}</div>
							<div style={{ float: 'right', width: '100%' }}>{getCommaSeparatedTwoDecimalsNumber(data.allocated_amount)}</div>
						</div>
				}
			</div>
		)
	}
	sharesAllocated = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		let classname = 'rgt-cell-inner ';
		if (this.state.offeringStatus === 'cancelled') {
			classname = 'rgt-cell-inner red_color';
		}
		return (
			<div>
				{
					data.allocated_shares === null ?
						<div>N/A</div>
						:
						<div className={classname}>{numberWithCommas(data.allocated_shares)} </div>
				}
			</div>
		)
	}

	sharesRequested = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		let classname = 'rgt-cell-inner ';
		if (this.state.offeringStatus === 'cancelled') {
			classname = 'rgt-cell-inner red_color';
		}
		return (
			<div>
				{
					data.requested_shares === null ?
						<div>N/A</div>
						:
						<div className={classname}> {this.state.offeringStatus === 'active' && (this.state.offering && this.state.offering.ordr_dollar_share === 1) ? 
						<span>
							{
								(!this.state.isReadOnly) ? 
								<span onClick={() => this.openEditModal(data)} style={{color: '#689F38', cursor: 'pointer', fontWeight: 'bold'}}>{numberWithCommas(data.requested_shares)}</span>
								:
								<span>{numberWithCommas(data.requested_shares)}</span>

							}
						</span>
						:
						<span>{numberWithCommas(data.requested_shares)}</span>
					}</div>
				}
			</div>
		)
	}
	
	availableBuyingPower = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		let classname = 'rgt-cell-inner ';
		if (this.state.offeringStatus === 'cancelled') {
			classname = 'rgt-cell-inner red_color';
		}
		return (
			<div className={classname}>
				{
					data.buying_power_as_of === null ?
						<div>N/A</div>
						:
						<div style={{ display: 'inline-flex', width: '100%'}} >
							<div style={{ float: 'left', width: '100%' }}>{'$'}</div>
							<div style={{ float: 'right', width: '100%' }}>{getCommaSeparatedTwoDecimalsNumber(data.buying_power_as_of)}</div>
						</div>
				}
			</div>
		)
	}

	reconfirmDate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		if (data.reconfirmed_dt === null) {
			return <div className="rgt-cell-inner"></div>;
		} else {
			return <div className="rgt-cell-inner"> {(data.reconfirmed_dt)} </div>;
		}
	}

	statusInfo = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		let classname = 'rgt-cell-inner ';
		if (this.state.offeringStatus === 'cancelled') {
			classname = 'rgt-cell-inner red_color';
		}
		return <div className={classname}> {(this.state.offeringStatus)} </div>;
	}

	communicationtemplate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return <div className="rgt-cell-inner" > <Button variant="contained" color="primary" style={{ width: 40, height: 35 }} onClick={() => { this.openInvestorModal(data); }}><AccountBoxIcon style={{ marginLeft: -4 }} /></Button> </div>;
	}

	dspColumn = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		let classname = 'rgt-cell-inner green_color';
		if (data.dsp === 2) {
			return <div className={classname}><p>DSP</p></div>;
		} else if (data.dsp === 1) {
			return <div className={classname}><p>Directed Allocation</p></div>;
		}
	}

	closeOrderMessageModal = () => {
		this.setState({
			orderMessage: '',
			openOrderMessageModal: false
		})
	}
	onColumnToggle = (event) => {
		this.setState({ cols: event.value });
	}

	setValueInput = (values) => {
		this.setState({
			amount_requested: values.value,
		}, () => {
		});
	}

	toggleNotificationModal = () => {
		// selectedCustomer = [];
		this.setState({
			selectedBDs: [],
			selectedCustomerList: [],
			notificationshow: !this.state.notificationshow,
		});
	}

	bdArraySetup = () => {
		const bdUsers = [];

		const userList = [];
		let allUsers = false;
		for (const cust of this.state.selectedCustomerList) {
			userList.push(cust.email);
		}
		if (this.state.offeringOrdersList.length === this.state.selectedCustomerList.length) {
			allUsers = true;
		}
		if (this.state.selectedCustomerList.length !== 0) {
			bdUsers.push({
				all: allUsers,
				mpid: this.state.mpid,
				user_email_list: userList,
			});
		}
		this.setState({
			selectedBDs: bdUsers,
		}, () => {
		});
	}
	openSendEmail = () => {
		let selectedArr = [];
		for (let item of this.state.offeringOrdersList) {
			if (item.checked) {
				selectedArr.push(item)
			}
			
		}
		this.setState({
			selectedCustomerList: selectedArr,
            notificationshow: true,
            notificationType: 'EMAIL',
		}, () => {
			this.bdArraySetup();
		});
		
	}
	openSendPush = () => {
		let selectedArr = [];
		for (let item of this.state.offeringOrdersList) {
			if (item.checked) {
				selectedArr.push(item)
			}
        }
        this.setState({
			selectedCustomerList: selectedArr,
            notificationshow: true,
            notificationType: 'PUSH',
		}, () => {
			this.bdArraySetup();
		});
	}
	openEditModal = (order) => {
		this.setState({
			openEditPriceModal: true,
			orderInfo: order,
			amount_requested: order.requested_amount,
			shares_requested: order.requested_shares,
			requested_email: order.email,
		});
	}
	closeEditModal = () => {
		this.setState({
			openEditPriceModal: false,
		});
	}

	openInvestorModal = (offeringInfo) => {
		this.setState({
			followerModal: true,
		})
		this.props.getfollower(this.state.offering_id, offeringInfo.email);
	}

	toggleModal = () => {
		this.setState({
			followerModal: !this.state.followerModal,
		});
		this.props.offeringOrderSuccess();
		this.setState({
		  offeringfollower: []
		}, () => {});
		// let data = null;
		// // this.props.getfollowerSuccess(data);
	}
	closeChartModal = () => {
		this.setState({
			showChartModal: false,
			offeringBuildOutList: []
		});
	}
	openChartModal = () => {
		this.setState({
			showChartModal: true,
		});
		let request = {
			ext_id: this.state.offering.ext_id,
			mpid: this.state.mpid
		}
		this.props.loadofferingBuildOut(request)
		// if (this.state.pagenum === -1) {
		// 	this.setState({
		// 		showChartModal: true,
		// 	});
		// } else {
		// 	this.setState({
		// 		buildoutShow: true,
		// 	}, () => {
		// 		this.showAll();
		// 	});
		// }
	}
	
	// userName = (rowData, column) =>  {
	// 	let classname = 'ui-cell-data ';
	// 	if (rowthis.state.offeringStatus === 'cancelled') {
	// 	  classname = 'ui-cell-data red_color';
	// 	}
	// 	return <div className={classname}> {(rowData.name)} </div>;
	// }
	
	
	toggleOfferingModal = (item) => {
		this.setState({
		  	openAdminModel: true,
		});
	}
	
	
	closeEdit = () => {
		this.setState({
			offeringOrdersList: [],
		});
		setTimeout(() => {
			this.setState({
				offeringOrdersList: this.state.tempOrderList,
			});
		}, 10);
	}
	
	dropDownChange = (event) => {
		this.setState({
			searchbyCat: event.target.value,
			searching: '',
		});
	}

	searchOffering = (event) => {
		this.setState({
			searching: event.target.value,
		});
	}
	
	goBack = () => {
		// if (this.state.offeringListCheck === false) {
		// 	this.props.fetchOfferings(this.state.offeringStatus);
		// }
		
		this.props.history.goBack();
	}

	removeByIndex(array, index) {
		return array.filter((elem, _index) => {
			return index !== _index;
		});
	}

	dataSelected = (e) => {
		const selecetedCustomerList = e.value;

		this.setState({
			uiRender: !this.state.uiRender,
			selectedCustomerList: selecetedCustomerList,
		}, () => { });
	}

	subTotal = (filterRow) => {
		let totalReq = 0;
		for (let item of filterRow) {
			totalReq += item.requested_amount;
		}

		return <CurrencyFormat value={totalReq} decimalScale={2} displayType={'text'} thousandSeparator prefix={'$'} renderText={(value) => <b style={{ paddingLeft: 10 }}>Sub-Total : {value}</b>} />
	}
	getTime = (dateTime) => {
		if (dateTime == null) {
			return <div className="ui-cell-data" > N/A </div>;
		} else if (dateTime.includes('.')) {
			const parsedTime = dateTime.split('T');
			const date = parsedTime[0];
			const parsedTime2 = parsedTime[1].split('.');
			const time = parsedTime2[0];
			const dateTimeFormatted = date + ' ' + time + ' ET';
			return dateTimeFormatted;
		} else {
			return dateTime;
		}
	}
	updateRequestedOrder = () => {
		const data = {
			email: this.state.requested_email,
			ext_id: this.state.offering_id,
			buying_power: this.state.orderInfo.buying_power_as_of,
			mpid: this.state.mpid,
			account_id: this.state.orderInfo.account_id

		};
		
		if (this.state.offering && this.state.offering.ordr_dollar_share === 0) {
			data.requested_amount = Number(this.state.amount_requested);
		}
		if (this.state.offering && this.state.offering.ordr_dollar_share === 1) {
			data.requested_shares = Number(this.state.shares_requested);
		}
		this.props.updateRequestedOrder(data);
	}

	handleChange = (event) => {
		if (event.target.value) {
			const amountValue = event.target.value.substr(1);
			if (this.state.orderInfo.requested_amount !== parseInt(amountValue)) {
				this.setState({
					isFormValid: true,
					requested_email: this.state.orderInfo.email,
				});
			} else {
				this.setState({
					isFormValid: false,
					amount_requested: event.target.value,
				});
			}
		} else {
			this.setState({
				isFormValid: false,
			});
		}
	}
	handleChangeShares = (event) => {
		if (event.target.value) {
			const sharesValue = event.target.value.substr(1);
			if (this.state.orderInfo.requested_shares !== parseInt(sharesValue)) {
				this.setState({
					isFormValid: true,
					requested_email: this.state.orderInfo.email,
				});
			} else {
				this.setState({
					isFormValid: false,
					shares_requested: event.target.value,
				});
			}
		} else {
			this.setState({
				isFormValid: false,
			});
		}
	}
	openDeleteModal = () => {
		this.setState({
			showDeleteModal: true
		});
	}
	closeDeleteModal = () => {
		this.setState({
			showDeleteModal: false,
			reason: ''
		});
	}
	
	doDeleteOrder = () => {
		if (this.state.reason !== '') {
			const sendRequest = {
				ext_id: this.state.offering_id,
				email: this.state.orderInfo.email,
				mpid: this.state.mpid,
				account_id: this.state.orderInfo.account_id,
				reason: this.state.reason
			};
			this.props.deleteOrder(sendRequest);
		} else {
			Swal.fire({
				title: 'Please enter reason',
				icon: 'info',
				confirmButtonText: 'OK',
				allowOutsideClick: false,
				timer: 3000,
				heightAuto: false
			});
		}
		
	}
	openDeleteOrderModal = () => {
		let selectedArr = [];
		for (let item of this.state.offeringOrdersList) {
			if (item.checked) {
				selectedArr.push(item)
			}
			
		}
		this.setState({
			selectedCustomerList: selectedArr
		}, () => {
			if (this.state.selectedCustomerList.length > 0) {
				this.setState({
					isOpenDeleteOrderModal: true
				})
			} else {
				Swal.fire({
					title: 'Please select orders',
					icon: 'info',
					confirmButtonText: 'OK',
					allowOutsideClick: false,
					timer: 3000,
					heightAuto: false
				});
			}
		}); 
		
		
	}
	closeDeleteOrderModal = () => {
		this.setState({
			isOpenDeleteOrderModal: false,
			deletionReason: ''
		})
	}
	deleteAllSelectedOrder = () => {
		if (this.state.deletionReason !== '') {
			let allEmailList = [];
			let emailList = [];
			for (let item of this.state.offeringOrdersList) {
				if (item.checked) {
					allEmailList.push(item.email);
				}
				
			}
			const distinct = (value, index, self) => {
				return self.indexOf(value) === index
			}
			emailList = allEmailList.filter(distinct);
			
			let payload = {
				ext_id: this.state.offering_id,
				mpid: (this.state.checkedAll) ? this.state.mpid : '',
				email: !(this.state.checkedAll) ? emailList.join() : '',
				reason: this.state.deletionReason
			}
			this.props.deleteAllOrder(payload);
		} else {
			Swal.fire({
				title: 'Please enter reason',
				icon: 'info',
				confirmButtonText: 'OK',
				allowOutsideClick: false,
				timer: 3000,
				heightAuto: false
			});
		}
		
	}
	fetchData = () => {
        if (this.state.lastShowOfferingOrders === this.state.ordersLength) {
			let payload = {
				ext_id: this.state.offering_id,
				status: this.state.offeringStatus,
				mpid: this.state.mpid,
				pagenum: this.state.pagenum + 1
			}
			if (this.state.tableFilter.field !== null) {
				payload.sortby = this.state.tableFilter.field;
				payload.sorttype = this.state.tableFilter.order;
			}
			if (this.state.searching !== '') {
				payload.search_key = this.state.filterBy;
                payload.search_keyword = this.state.searching;
			}
			this.setState({
				isFetchOrder: true,
				pagenum: payload.pagenum
			})
			isOrders = true;
			this.props.loadofferingOrders(payload, true);
        }
	}
	onSortFunction(field) {
        return (<span>
            {
                ((this.state.tableFilter.field !== field)) &&
                <IconButton onClick={() => { this.doSort(field, 'asc'); }} style={{ padding: 6 }}>
                    <SortIcon style={{ fontSize: 18 }} />
                </IconButton>
            }
            {
                ((this.state.tableFilter.order === 'asc') && (this.state.tableFilter.field === field)) &&
                <IconButton className="btn-custom-sort" onClick={() => { this.doSort(field, 'desc'); }} style={{ padding: 6 }}>
                    <ArrowDropUpIcon style={{ fontSize: 18 }} />
                </IconButton>
            }
            {
                ((this.state.tableFilter.order === 'desc') && (this.state.tableFilter.field === field)) &&
                <IconButton className="btn-custom-sort" onClick={() => { this.doSort(field, 'asc'); }} style={{ padding: 6 }}>
                    <ArrowDropDownIcon style={{ fontSize: 18 }} />
                </IconButton>
            }
        </span>);
    }
	doSort(field, order) {
        if (filterData) {
			if (this.state.pagenum !== -1) {
				if (order === null || order === 'asc') {
					this.setState({
						isSort: 'desc',
						tableFilter: {
							field,
							order,
						},
					}, () => { });
					let payload = {
						ext_id: this.state.offering_id,
						status: this.state.offeringStatus,
						mpid: this.state.mpid,
						pagenum: 1,
						sortby : field,
						sorttype: order
					}
					if (this.state.searching !== '') {
						payload.search_key = this.state.filterBy;
						payload.search_keyword = this.state.searching;
					}
					isOrders = true;
					this.props.loadofferingOrders(payload, true);
					this.setState({
						pagenum: 1
					})
				} else {
					this.setState({
						isSort: 'asc',
						tableFilter: {
							field,
							order,
						},
					}, () => { });
					let payload = {
						ext_id: this.state.offering_id,
						status: this.state.offeringStatus,
						mpid: this.state.mpid,
						pagenum: 1,
						sortby : field,
						sorttype: order,
					}
					if (this.state.searching !== '') {
						payload.search_key = this.state.filterBy;
						payload.search_keyword = this.state.searching;
					}
					isOrders = true;
					this.props.loadofferingOrders(payload, true);
					this.setState({
						pagenum: 1
					})
				}
			} else {
				if (order === null || order === 'asc') {
                    this.setState({
                        tableFilter: {
                            field,
                            order,
                        },
                    }, () => { });
                    filterData = filterData.sort((a, b) => (a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0));
                    this.setState({
                        isUIrender: !this.state.isUIrender
                    });
                } else {
                    this.setState({
                        tableFilter: {
                            field,
                            order,
                        },
                    }, () => { });
                    filterData = filterData.sort((a, b) => (a[field] < b[field] ? 1 : a[field] > b[field] ? -1 : 0));
                    this.setState({
                        isUIrender: !this.state.isUIrender
                    });
                }
			}
        }
	}
	handleChangeSelectAll = (e) => {
		let allCheck = this.state.offeringOrdersList;
		allCheck.forEach(all => all.checked = e.target.checked)
		this.setState({
			checkedAll: e.target.checked,
			offeringOrdersList: allCheck,
			uiRender: !this.state.uiRender
		})
	}
	handleChangeRowSelect = (e, data, index) => {
		let customerActive = this.state.offeringOrdersList;
		customerActive[index-1].checked = e.target.checked;
		if (customerActive.every( (val, i, arr) => val.checked === true )) {
			this.setState({
				checkedAll: true,
			})
		} else {
			this.setState({
				checkedAll: false,
			})
		}
		this.setState({
			offeringOrdersList: customerActive,
			uiRender: !this.state.uiRender
		})
	}
	doSearchOrder = () => {
		if (this.state.pagenum !== -1) {
			if (this.state.searching !== '') {
				let request = {
					ext_id: this.state.offering_id,
					status: this.state.offeringStatus,
					mpid: this.state.mpid,
					pagenum: 1,
					search_key: this.state.filterBy,
					search_keyword: this.state.searching
				}
				if (this.state.tableFilter.field !== null) {
					request.sortby = this.state.tableFilter.field;
					request.sorttype = this.state.tableFilter.order;
				}
				isOrders = true;
				this.props.loadofferingOrders(request, true);
				this.setState({
					pagenum: 1
				})
			}
		} else {

		}
	}
	doRefreshOrder = () => {
		if (this.state.pagenum !== -1) {
			let request = {
				ext_id: this.state.offering_id,
				status: this.state.offeringStatus,
				mpid: this.state.mpid,
				pagenum: 1,
			}
			if (this.state.tableFilter.field !== null) {
				request.sortby = this.state.tableFilter.field;
				request.sorttype = this.state.tableFilter.order;
			}
			this.setState({pagenum: 1})
			isOrders = true;
			this.props.loadofferingOrders(request, true);
		} else {
			this.setState({searching: ''})
		}
		
	}
	handleBlur = () => {
		this.setState({
			searching: ''
		}, () => {
			this.doRefreshOrder();
		});
	}
	changeOrderStatus = (e) => {
		this.setState({
			offeringStatus: e.target.value
		}, () => {
			if (this.state.mpid !== '') {
				this.setState({
					tableFilter: {
						field: null,
						order: null
					},
					searching: '',
					pagenum: 1,
					checkedAll: false
				})
				let request = {
					ext_id: this.state.offering_id,
					status: this.state.offeringStatus,
					mpid: this.state.mpid,
					pagenum: 1
				}
				isOrders = true;
				this.props.loadofferingOrders(request, true);
			}
			
		})
	}
	handleContainerOnBottom = () => {
        this.fetchData()
	}
	
	loadMore = () => {
		filterData = [];
		this.fetchData();
		this.setState({loadMoreAll: 'more'});
    }
    showAll = () => {
        filterData = [];
        this.setState({
            offeringOrdersList: [],
            loadMoreAll: 'all'
        }, () => {
			let request = {
				ext_id: this.state.offering_id,
				status: this.state.offeringStatus,
				mpid: this.state.mpid,
				pagenum: -1
			}
            isOrders = true;
			this.props.loadofferingOrders(request, true);
            this.setState({
                uiRender: !this.state.uiRender,
                pagenum: request.pagenum,
                searching: '',
				isFetchOrder: false
            })
        })
        
    }
    onColumnShowHide = (columns) => {
        this.setState({
            cols: columns
        }, () => {
            localStorage.setItem('columnArrOrder', JSON.stringify(this.state.cols));
        })
    }
	dropDownSearchBy = (e) => {
		this.setState({
			filterBy: e.target.value
		});
	}
	render() {
		const { offering } = this.state;
		filterData = this.state.offeringOrdersList;

		const { offeringfollower, isReadOnly } = this.state;
		let orderActivities = [];
		const showOrderInfo = [];
		if (offeringfollower && offeringfollower[0]) {
			for (const items of offeringfollower[0].order_info.order_activity) {
				showOrderInfo.push(items);
			}
			for (const items of offeringfollower[0].order_info.email_activity) {
				showOrderInfo.push(items);
			}
			showOrderInfo.sort((a, b) => {
				return new Date(a[1]) - new Date(b[1]);
			});
			orderActivities = showOrderInfo.map((item, index) => {
				return (
					<div className="row" style={{ marginLeft: 5 }} key={index}>
						<div className="col-md-12 col-sm-12 order-info-titles-email">
							{item[0]}
						</div>
						<div className="col-md-12 col-sm-12">
							<div className="row">
								<div className="col-md-6 col-sm-6 order-info-sub-titles">
									{this.getTime(item[1])}</div>
								<div className="col-md-6 col-sm-6 order-info-sub-titles-value">
								</div>
							</div>
						</div>
					</div>
				);
			});
		}
		if (this.state.pagenum === -1) {
			filterData = this.state.searching ? filterData.filter((row) => row.name.toLowerCase().indexOf(this.state.searching.toLowerCase()) > -1 || row.email.toLowerCase().indexOf(this.state.searching.toLowerCase()) > -1 || row.account_name.toLowerCase().indexOf(this.state.searching.toLowerCase()) > -1 || row.created_at.toLowerCase().indexOf(this.state.searching.toLowerCase()) > -1) : filterData;
			//  || row.allocated_shares.toString().toLowerCase().indexOf(this.state.searching.toLowerCase()) > -1 || row.allocated_amount.toString().toLowerCase().indexOf(this.state.searching.toLowerCase()) > -1 || row.requested_shares.toString().toLowerCase().indexOf(this.state.searching.toLowerCase()) > -1 || row.requested_amount.toString().toLowerCase().indexOf(this.state.searching.toLowerCase()) > -1 || row.buying_power.toString().toLowerCase().indexOf(this.state.searching.toLowerCase()) > -1
		}
		return (
			<Dashboard title="ClickIPO Offerings - Orders" pageTitle="Orders" >
				<div className="orders_content">
					<div>
						<div className="row offeringSubheader">
							<div className="col-md-12">
								<div className="orders-page_header">
									<div className="Headingname">
										<ArrowBackIosIcon onClick={() => this.goBack()} style={{ cursor: 'pointer' }} /> {this.state.offeringName} ({offering.ticker_symbol}) Orders {this.state.uiRender}
									</div>
								</div>
							</div>
							{/* <div className="col-md-7">
								<div className=" offeringGlobalSearch">
									<div className="filterbyStatus">Filter By</div>
									<FormControl style={{ width: 150 }}>
										<Select
											labelId="Offering-label"
											id="offering"
											value={this.state.offeringStatus}
											onChange={this.changeOrderStatus}
										>
											<MenuItem value={'active'}>Active</MenuItem>
											<MenuItem value={'closed'}>Closed</MenuItem>
											<MenuItem value={'cancelled'}>Cancelled</MenuItem>
										</Select>
									</FormControl>
								</div>
							</div> */}
						</div>
						<div>
							<div className="row orderpage-subheading" >
								<div className="col-md-12">
									{
										this.state.offeringStatus === 'active' ?
											<div style={{ textAlign: 'right'}}>
												<Button variant="contained" color="primary" onClick={() => { this.openChartModal(); }} style={{ marginRight: 15 }}>Build out</Button>
												{
													(!isReadOnly) &&
													<Button variant="contained" color="primary" onClick={() => { this.openSendEmail(); }} style={{ marginRight: 15 }}>Send Email</Button>
												}
												{
													(!isReadOnly) &&
													<Button variant="contained" color="primary" onClick={() => { this.openSendPush(); }} style={{ marginRight: 15 }}>Send Push Notification</Button>
												}
                                                {
                                                    (!isReadOnly) &&
                                                    <Button variant="contained" color="secondary" onClick={() => { this.openDeleteOrderModal(); }} style={{ marginRight: 15 }}>Delete Orders</Button>
                                                }
											</div>
											:
											<div style={{ textAlign: 'right' }}>
												<Button variant="contained" color="primary" onClick={() => { this.openChartModal(); }} style={{ marginRight: 15 }}>Build out</Button>
												{
													(!isReadOnly) &&
													<Button variant="contained" color="primary" disabled style={{ marginRight: 15 }}>Send Email</Button>
												}
												{
													(!isReadOnly) &&
													<Button variant="contained" color="primary" disabled style={{ marginRight: 15 }}>Send Push Notification</Button>
												}
                                                {
                                                    (!isReadOnly) &&
                                                    <Button variant="contained" color="secondary" onClick={() => { this.openDeleteOrderModal(); }} style={{ marginRight: 15 }}>Delete Orders</Button>
                                                }
											</div>

									}
								</div>
							</div>
							<div className="broker-orders-body-content">
								<div className="mt-3">
                                    <div>
                                        
										<div className="offeringOrderListScroll">
											<div className="row">
												<div className="col-md-6" style={{ textAlign: 'left', marginBottom: 16, marginTop: 16,display: 'flex' }}>
													
													<div className=" offeringGlobalSearch"  style={{ marginLeft: 16, width: 260 }}>
														<div className="filterbyStatus">Filter By</div>
														<FormControl style={{ width: 150 }}>
															<Select
																labelId="Offering-label"
																id="offering"
																value={this.state.offeringStatus}
																onChange={this.changeOrderStatus}
															>
																<MenuItem value={'active'}>Active</MenuItem>
																<MenuItem value={'closed'}>Closed</MenuItem>
																<MenuItem value={'cancelled'}>Cancelled</MenuItem>
															</Select>
														</FormControl>
													</div>
													{/* {filterData && filterData.length > 0 && 
													<MultiSelect value={this.state.cols} options={this.colOptions} onChange={(event) => this.setState({cols: event.value})} style={{ width: '250px' }} />
													} */}
												</div>
												<div className="col-md-6" style={{ textAlign: 'right', marginBottom: 16, marginTop: 16 }}>
													<div className="offeringGlobalSearch" style={{alignItems: "center", justifyContent: "end"}}>
														<div className="filterbyContent">Search</div>
														<select className="selectField" value={this.state.filterBy} onChange={this.dropDownSearchBy} style={{ background: 'transparent', height: "40px" }} >
															<option value="name" >User Name</option>
															<option value="account_name" >Account Name</option>
															<option value="email" >User Email</option>
															<option value="created_at" >Prospectus</option>
															<option value="final_prospectus_datetime" >Final Prospectus</option>
															<option value="requested_amount" >Requested Amount</option>
															<option value="allocated_amount" >Allocated Amount</option>
															<option value="requested_shares" >Requested Shares</option>
															<option value="allocated_shares" >Allocated Shares</option>
															<option value="buying_power_as_of" >IPO Buying Power</option>
															<option value="reconfirmed_dt" >Reconfirm</option>
															
														</select>
														<div style={{ paddingLeft: '20px' }}>
															<TextField 
																id="searchInput" 
																type="text" 
																placeholder="Search" 
																value={this.state.searching} 
																onChange={this.searchOffering}
																style={{width: 250}}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="start">
																			{
																				this.state.searching !== '' &&
																				<Button variant="contained" color="primary" style={{ borderRadius: '0px 4px 4px 0px', minWidth: 30, top: 19, right: 0, left: 8, height: 38, background: 'transparent', color: '#333' }} onClick={() => this.handleBlur()}  ><CloseIcon className="close-icon-for-search" /></Button>
																			}
																			<Button variant="contained" color="primary" style={{borderRadius: '0px 4px 4px 0px', minWidth: 50, top: 19, right: 0, left: 8, height: 38}} onClick={() => this.doSearchOrder()} disabled={this.state.searching !== '' ? false : true}><SearchIcon /></Button>
																		</InputAdornment>
																	)
																}}
															/>
														</div>
													</div>
												</div>
											</div>
											{/* {filterData && filterData.length > 0 &&  */}
                                            <div className="table-responsive broker-orderlist-table custom-order-table">
												<GridTable
													columns={this.state.cols}
													rows={filterData}
													isPaginated={false}
													isVirtualScroll={true}
													showSearch={false}
													showRowsInformation={false}
													isHeaderSticky={true}
													onColumnsChange={this.onColumnShowHide}
												/>
												<div className="col-md-12 mt-2">
													{
														(this.state.lastShowOfferingOrders === this.state.ordersLength) ?
														<Button variant="contained" color="primary" onClick={() => this.loadMore()} style={{ marginRight: 8, marginBottom: 8 }} disabled={this.state.pagenum === -1}>Load more</Button>
														:
														<Button variant="contained" color="primary" style={{ marginRight: 8, marginBottom: 8 }} disabled={true}>No more data</Button>
													}
													<Button variant="contained" color="primary" style={{ marginBottom: 8 }} onClick={() => this.showAll()} disabled={this.state.pagenum === -1 ? true : false}>Load all</Button>
													
												</div>
                                            </div>
											{/* } */}
                                        </div>
                                        {/* {filterData && filterData.length === 0 && (!isOrders) && <p className="noDataFound"> Order Not Found </p>} */}
                                    </div>
								</div>
							</div>
						</div>
						
						<ChartModal chartShow={this.state.showChartModal} onClose={this.closeChartModal} orderData={this.state.offeringBuildOutList} createdOffering={this.state.createdOffering} offeringMaxPrice={this.state.offeringMaxPrice} selectedOption={this.state.offeringStatus}></ChartModal>
						<NotificationModal totalCustomer={filterData} notificationType={this.state.notificationType} notificationshow={this.state.notificationshow} onClose={this.toggleNotificationModal} offeringId={this.state.offering_id} customerListing={this.state.selectedCustomerList} selectedBD={this.state.selectedBDs} selectedOption={this.state.offeringStatus} ></NotificationModal>
						<Dialog
							onClose={this.toggleModal}
							aria-labelledby="customized-dialog-title"
							open={this.state.followerModal}
							maxWidth={"sm"}
							fullWidth={true}
							scroll={'paper'}
							>
							<DialogTitle onClose={this.toggleModal} className="offering-detail-title">{offeringfollower && this.state.offeringfollower.length > 0 ? offeringfollower[0].user_info.email : ''}</DialogTitle>
							<DialogContent>
								<div className="col-md-12">
									{orderActivities}
								</div>
							</DialogContent>
						</Dialog>
						<Dialog
							onClose={this.closeEditModal}
							aria-labelledby="customized-dialog-title"
							open={this.state.openEditPriceModal}
							maxWidth={"sm"}
							fullWidth={true}
							scroll={'paper'}
							>
							<DialogTitle onClose={this.closeEditModal} className="offering-detail-title">{this.state.orderInfo && this.state.orderInfo.name ? this.state.orderInfo.name : ''}</DialogTitle>
							<DialogContent>
								<div className="col-md-12 editModelText">
									<div className="col-md-12 editAmountModelbody">
										<div className="row">
											<div className="col--md-4 col-sm-4 no_paading">
												<p>{(this.state.offering && this.state.offering.ordr_dollar_share === 0) ? 'Requested Amount' : 'Requested Shares'}</p>
												{
													(this.state.offering && this.state.offering.ordr_dollar_share === 0) ? 
													<CurrencyFormat
														value={this.state.amount_requested}
														thousandSeparator
														style={{ width: 135,
														height: 35,
														marginLeft: 10,
														}}
														prefix={'$'}
														onChange={this.handleChange}
														onValueChange={(values) => {
															const {value} = values;
															this.setState({
																amount_requested: value,
															})
														}}
														onMouseDown={e => e.stopPropagation()}
													/>
													:
													<CurrencyFormat
														value={this.state.shares_requested}
														thousandSeparator
														style={{ width: 135,
														height: 35,
														marginLeft: 10,
														}}
														onChange={this.handleChangeShares}
														onValueChange={(values) => {
															const {value} = values;
															this.setState({
																shares_requested: value,
															})
														}}
														onMouseDown={e => e.stopPropagation()}
													/>
												}
											</div>
											<div className="col--md-4 col-sm-4 no_paading">
												<p>IPO Buying Power <span></span></p>
												<p> {numberWithCommasAndDollar(this.state.orderInfo ? this.state.orderInfo.buying_power_as_of: 0)} </p>
											</div>
											<div className="col--md-4 col-sm-4 no_paading">
												
												{
													(this.state.offering && this.state.offering.ordr_dollar_share === 0) ? 
													<p>Min Buy Amount</p>
													:
													<p>Min Buy Share</p>
												}
												{
													(this.state.offering && this.state.offering.ordr_dollar_share === 0) ? 
													<p>{offering && offering.min_ticket_size ? numberWithCommasAndDollar(offering.min_ticket_size) : numberWithCommasAndDollar(0)}</p>
													:
													<p>{offering && offering.min_ticket_size ? numberWithCommas(offering.min_ticket_size) : numberWithCommas(0)}</p>
												}
											</div>
										</div>
									</div>
								</div>
							</DialogContent>
							<DialogActions style={{display: 'block'}}>
								<div style={{textAlign: 'center'}}>
									<Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.closeEditModal()}>
										Cancel
									</Button>
									<Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.updateRequestedOrder()}>
										Modify
									</Button>
									<Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="secondary" onClick={() => this.openDeleteModal()}>
										Delete
									</Button>
								</div>
							</DialogActions>
						</Dialog>
						<Dialog
							onClose={this.closeOrderMessageModal}
							aria-labelledby="customized-dialog-title"
							open={this.state.openOrderMessageModal}
							maxWidth={"sm"}
							fullWidth={true}
							scroll={'paper'}
							>
							<DialogTitle onClose={this.closeOrderMessageModal} className="offering-detail-title">Congratulations</DialogTitle>
							<DialogContent>
								<div className="col-md-12 editModelText" style={{textAlign: 'center'}}>
									<div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.orderMessage)}}></div>
								</div>
							</DialogContent>
							<DialogActions style={{display: 'block'}}>
								<div style={{textAlign: 'center'}}>
									<Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.closeOrderMessageModal()}>
										Ok
									</Button>
								</div>
							</DialogActions>
						</Dialog>
						<Dialog
							onClose={this.closeDeleteOrderModal}
							aria-labelledby="customized-dialog-title"
							open={this.state.isOpenDeleteOrderModal}
							maxWidth={"sm"}
							fullWidth={true}
							scroll={'paper'}
							>
							<DialogTitle onClose={this.closeDeleteOrderModal} className="offering-detail-title">Delete order's</DialogTitle>
							<DialogContent>
								<div className="col-md-12 editModelText" style={{textAlign: 'center'}}>
									<h5>Please enter a reason for deletion</h5>
									<div>
										<TextField type="text" label="Reason" variant="outlined" value={this.state.deletionReason} multiline rowsMax={4} fullWidth onChange={(e) => this.setState({
											deletionReason: e.target.value
										})} />
									</div>
								</div>
							</DialogContent>
							<DialogActions style={{display: 'block'}}>
								<div style={{textAlign: 'center'}}>
									<Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.deleteAllSelectedOrder()}>
										Confirm
									</Button>
									<Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="secondary" onClick={() => this.closeDeleteOrderModal()}>
										Cancel
									</Button>
								</div>
							</DialogActions>
						</Dialog>
						<Dialog
							onClose={this.closeDeleteModal}
							aria-labelledby="customized-dialog-title"
							open={this.state.showDeleteModal}
							maxWidth={"sm"}
							fullWidth={true}
							scroll={'paper'}
							>
							<DialogTitle onClose={this.closeDeleteModal} className="offering-detail-title">Delete order</DialogTitle>
							<DialogContent>
								<div className="col-md-12 editModelText" style={{textAlign: 'center'}}>
									<h5 className="mb-4">Are you sure you want to delete this order ? Please enter a reason for deletion</h5>
									<div>
										<TextField type="text" label="Reason" variant="outlined" value={this.state.reason} multiline rowsMax={4} fullWidth onChange={(e) => this.setState({
											reason: e.target.value
										})} />
									</div>
								</div>
							</DialogContent>
							<DialogActions style={{display: 'block'}}>
								<div style={{textAlign: 'center'}}>
									<Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.doDeleteOrder()}>
										Confirm
									</Button>
									<Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="secondary" onClick={() => this.closeDeleteModal()}>
										Cancel
									</Button>
								</div>
							</DialogActions>
						</Dialog>
					</div>
				</div>
			</Dashboard>
		)
	}
}

BrokerOrdersComponent.propTypes = {
	offeringOrdersRes: PropTypes.any,
	offeringfollowerRes: PropTypes.any,
	updateRequestedAmountRes: PropTypes.any,
	deleteOrderRes: PropTypes.any,
	deleteAllOrderRes: PropTypes.any,
	offeringBuildOutRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	offeringOrdersRes: doFetchOfferingOrdersRes,
	offeringfollowerRes: doFetchFollowerListRes,
	updateRequestedAmountRes: doUpdateRequestedAmountRes,
	deleteOrderRes: doDeleteOrderRes,
	deleteAllOrderRes: doDeleteAllOrderRes,
	offeringBuildOutRes: doFetchStatsOfferingOrdersRes,
});


function mapDispatchToProps(dispatch) {
	return {
		loadofferingOrders: (payload, isLoading) => dispatch(fetchOfferingOrders(payload, isLoading)),
		getfollower: (ext_id,email) => {dispatch(getFollowerList(ext_id, email)); },
		fetchOfferings: (data) => {dispatch(getOffering(data))},
		offeringOrderSuccess: () => dispatch(doFetchFollowerListRes(null)),
		getOfferingDetail: (id) => dispatch(getOfferingDetail(id)),
		updateRequestedOrder: (data) => dispatch(updateRequestedAmount(data)),
		deleteOrder: (data) => dispatch(deleteOrder(data)),
		deleteAllOrder: (data) => dispatch(deleteAllOrder(data)),
		loadofferingBuildOut: (request) => dispatch(fetchStatsOfferingOrders(request)),
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(BrokerOrdersComponent);
