import * as types from './actionTypes';  
import userStatsApi from '../api/userStatsApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doFetchUsersStats() {

  return {
    type: types.DO_FETCH_USERS_STATS, 
    
  };
}

export function doFetchUsersStatsRes(data) {  
  return {
    type: types.DO_FETCH_USERS_STATS_RES, 
    data
  };
}

export function fetchUsersStats() {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      userStatsApi.doFetchUsersStats().then(data => {
        dispatch(doFetchUsersStatsRes(data));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
  
}