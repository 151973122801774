import { BASE_URL } from '../constants';
import { getItem } from '../utils/localStore';
import { errorHandling, errorHandler } from '../utils/errorHandling';

class sendPendingBrokerDealerApi {
    static doSendPendingBrokerDealer(request) {
        const TOKEN = getItem('auth_token');
        let isError = false;
        try {
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'POST',
                headers: ajaxRequestHeaders,
                body: JSON.stringify(request)
            }
            return fetch(BASE_URL + `/offerings/sample_pricing_wire_files`, body).then(response => {
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        } catch (err) {
        }
    }
}

export default sendPendingBrokerDealerApi;