import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';
import Dashboard from '../../../component/Dashboard';
import { fetchAllocationOfferingList, doFetchAllocationOfferingListRes } from '../../../actions/allocationOfferingListActions';
import { fetchAllocationOffering, doFetchAllocationOfferingRes } from '../../../actions/allocationOfferingActions';
import { getUnderwriterMpid, underWriterMpidListRes } from '../../../actions/underWriterMpidActions';
import { submitCreateAllocation, doCreateAllocationRes } from '../../../actions/createAllocationActions';
import { Calendar } from 'primereact/calendar';
import Select from 'react-select';
import { validatePrice, dateTimeFormat } from '../../../helper/helper';
import validate from './formValidation';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const fields = ['offering', 'final_shares', 'final_price'];

const minDate = new Date();
class CreateAllocationForm extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor() {
    super();
    this.state = {
      continueCount: 0,
      sendEmail: false,
      selectedoffering_id: '',
      trade_date: '',
      settlement_date: '',
      max_time: '',
      asked_shares: '',
      max_price: '',
      amountOfShare: '',
      cusipid: '',
      allocated_shares: '',
      UnderwriterValue: '',
      DspUnderwriterValue: '0',
      UnderwriterId: '',
      selected_bd_underwriter_mpid_id: '',
      bd_underwriter_mpid_id: '',
      isPrice: false,
      isEmailShow: false,
      isSubmit: false,
      offering_bd_brokerages_attributes: [],
      offering_bd_brokerages_attributes_submit: {},
      selected_offering: '',
      selected_offering_id: '',
      isBrokerDealer: false,
      customer_exclude_list: '',
      final_price: '',
      isDsp: 0,
      showSharesField: false,
      dsp_order_value: 1,
      ioi_cutoff_datetime: null,
      ioi_cutoff_datetime_show: null,
      dsp_click_shares: '',
      offeringList: [],
      offering: null,
      userMpid: [],
      bias: 1,
      warrantList: [],
      wListOld: [],
      uiRender: false,
    };
    this.handleChangesBD_attributes = this.handleChangesBD_attributes.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.selecttrade_date = this.selecttrade_date.bind(this);
    this.handlefetchUserMpid();
    this.mpIDhandleChange = this.mpIDhandleChange.bind(this);
    this.offeringSelectChange = this.offeringSelectChange.bind(this);
  }

  componentDidMount() {
    this.props.fetchOfferings();
    this.props.resetOfferings();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.createAllocationRes) {
      if (props.createAllocationRes.data && props.createAllocationRes.data.createAllocation) {
        if (props.createAllocationRes.data.createAllocation.message === 'Success') {
          props.history.push('/allocations');
        }
      }
    }
    if (props.allocationOfferingListRes) {
      if (props.allocationOfferingListRes.data && props.allocationOfferingListRes.data.allocationOfferingList) {
        if (props.allocationOfferingListRes.data.allocationOfferingList.message === 'Success') {
          return {
            offeringList: props.allocationOfferingListRes.data.allocationOfferingList.data
          };
        }
      }
    }
    if (props.underWriterMpidListRes) {
      if (props.underWriterMpidListRes.data && props.underWriterMpidListRes.data.underWriterMpid) {
        if (props.underWriterMpidListRes.data.underWriterMpid.message === 'Success') {
          return {
            userMpid: props.underWriterMpidListRes.data.underWriterMpid.data
          };
        }
      }
    }
    if (props.allocationOfferingRes) {
      if (props.allocationOfferingRes.data && props.allocationOfferingRes.data.allocationOffering) {
        if (props.allocationOfferingRes.data.allocationOffering.message === 'Success') {
          let isDspVal = 0;
          let UnderwriterValue_val = '';
          let DspUnderwriterValue_val = '0';
          let cusipid_val = '';
          let final_price_val = '';
          let trade_date_val = '';
          let settlement_date_val = '';
          let selected_bd_underwriter_mpid_id_val = '';
          let bd_underwriter_mpid_id_val = '';
          let offering_bd_brokerages_attributes_val = [];
          let warrantListData = [];

          if (props.allocationOfferingRes.data.allocationOffering.data.underwriter_concession_amount !== null) {
            UnderwriterValue_val = props.allocationOfferingRes.data.allocationOffering.data.underwriter_concession_amount;
            DspUnderwriterValue_val = '0';
          }
          if (props.allocationOfferingRes.data.allocationOffering.data.cusip_id !== null && props.allocationOfferingRes.data.allocationOffering.data.cusip_id !== undefined) {
            cusipid_val = props.allocationOfferingRes.data.allocationOffering.data.cusip_id;
          }

          if (props.allocationOfferingRes.data.allocationOffering.data.max_price != null) {
            final_price_val = props.allocationOfferingRes.data.allocationOffering.data.max_price;
          }

          if (props.allocationOfferingRes.data.allocationOffering.data) {
            if (props.allocationOfferingRes.data.allocationOffering.data.trade_date !== null && props.allocationOfferingRes.data.allocationOffering.data.trade_date !== undefined) {
              trade_date_val = new Date(props.allocationOfferingRes.data.allocationOffering.data.trade_date);
            } else {
              trade_date_val = '';
            }
            if (props.allocationOfferingRes.data.allocationOffering.data.settlement_date !== null && props.allocationOfferingRes.data.allocationOffering.data.settlement_date !== undefined) {
              settlement_date_val = new Date(props.allocationOfferingRes.data.allocationOffering.data.settlement_date);
            } else {
              settlement_date_val = '';
            }
          }
          if (props.allocationOfferingRes.data.allocationOffering.data.bd_underwriter_mpid_id !== null || props.allocationOfferingRes.data.allocationOffering.data.bd_underwriter_mpid_id === state.bd_underwriter_mpid_id) {
            const selectedMPIDObject = {
              value: props.allocationOfferingRes.data.allocationOffering.data.bd_underwriter_mpid_id,
              label: props.allocationOfferingRes.data.allocationOffering.data.bd_underwriter_mpid_id,
            };
            selected_bd_underwriter_mpid_id_val = selectedMPIDObject;
            bd_underwriter_mpid_id_val = props.allocationOfferingRes.data.allocationOffering.data.bd_underwriter_mpid_id;
          }

          if (props.allocationOfferingRes.data.allocationOffering.data.bd_data && props.allocationOfferingRes.data.allocationOffering.data.bd_data.length > 0) {
            const bdName = [];
            for (const item of props.allocationOfferingRes.data.allocationOffering.data.bd_data) {
              bdName.push({ broker_dealer_name: item.broker_dealer_name, bd_commission: item.bd_commission, mpid: item.mpid, dsp_order: item.dsp_order, dsp_order_change: 0, value: true });
            }
            offering_bd_brokerages_attributes_val = bdName;
          }
          if (props.allocationOfferingRes.data.allocationOffering.data.dsp === 1) {
            isDspVal = 1;
          } else if (props.allocationOfferingRes.data.allocationOffering.data.dsp === 2) {
            isDspVal = 2;
          } else {
            isDspVal = 0;
          }
          if (props.allocationOfferingRes.data.allocationOffering.data.warrant_list) {
            warrantListData = props.allocationOfferingRes.data.allocationOffering.data.warrant_list;
            if (warrantListData.length > 0) {
              for (let warrant of warrantListData) {
                warrant.old_ticker_symbol = warrant.ticker_symbol;
                warrant.errors =  {
                  ticker_symbol: null,
                  cusip_id: null,
                  underwriter_concession_amount: null
                }
              }
            }
          }
          return {
            offering: props.allocationOfferingRes.data.allocationOffering.data,
            isDsp: isDspVal,
            UnderwriterValue: UnderwriterValue_val,
            DspUnderwriterValue: DspUnderwriterValue_val,
            cusipid: cusipid_val,
            final_price: final_price_val,
            trade_date: trade_date_val,
            settlement_date: settlement_date_val,
            selected_bd_underwriter_mpid_id: selected_bd_underwriter_mpid_id_val,
            bd_underwriter_mpid_id: bd_underwriter_mpid_id_val,
            offering_bd_brokerages_attributes: offering_bd_brokerages_attributes_val,
            warrantList: warrantListData,
            wListOld: JSON.stringify(warrantListData)
          };
        }
      }
    }
    return null;
  }
  mpIDhandleChange(event) {
    this.setState({
      selected_bd_underwriter_mpid_id: event,
      bd_underwriter_mpid_id: event.value,
    }, () => { });
  }

  offeringSelectChange(event) {
    this.setState({
      selected_offering: event,
      selected_offering_id: event.value,
      isEmailShow: true,
      sendEmail: false,
      max_price: '',
      amountOfShare: '',
      asked_shares: '',
      cusipid: '',
      allocated_shares: '',
      isPrice: true,
      isBrokerDealer: true,
      offering_bd_brokerages_attributes: {},
      ioi_cutoff_datetime: null,
      ioi_cutoff_datetime_show: null,
      dsp_click_shares: '',
    }, () => { });

    if (event.value) {
      this.props.fetchAllocationOffering(event.value);
    }
  }

  handleChangesBD_attributes(editData, BD_info, i) {
    const brokername = document.getElementById('BrokerDealerName' + i).value;
    const brokerCommision = document.getElementById('BrokerDealerCommission' + i).value;
    let TempBD_attribute = [];
    if (BD_info.dsp_order === 1 && editData.target.name === 'dsp_order') {
      this.setState({
        dsp_order_value: editData.target.value
      }, () => {
        TempBD_attribute[i].dsp_order_change = Number(this.state.dsp_order_value);
      })
    } else if (editData.target.name === 'dsp_order') {
      this.setState({
        dsp_order_value: '0'
      }, () => {
        TempBD_attribute[i].dsp_order_change = Number(this.state.dsp_order_value);
      })
    }
    TempBD_attribute = this.state.offering_bd_brokerages_attributes;
    TempBD_attribute[i].broker_dealer_name = brokername;
    TempBD_attribute[i].bd_commission = brokerCommision;
    this.setState({
      offering_bd_brokerages_attributes_submit: TempBD_attribute
    }, () => { });
  }
  handleChangesBIAS(editData, BD_info, i) {
    this.setState({
      bias: editData.target.value
    })
  }

  handlefetchUserMpid() {
    setTimeout(() => {
      this.props.fetchUserMpid();
      this.setState({
        continueCount: 0,
        sendEmail: false,
        selectedoffering_id: '',
        trade_date: '',
        settlement_date: '',
        max_time: '',
        max_price: '',
        amountOfShare: '',
        asked_shares: '',
        cusipid: '',
        allocated_shares: '',
        UnderwriterValue: '',
        DspUnderwriterValue: '',
        UnderwriterId: '',
        selected_bd_underwriter_mpid_id: '',
        bd_underwriter_mpid_id: '',
        isPrice: false,
        isEmailShow: false,
        isSubmit: false,
        offering_bd_brokerages_attributes: {},
        offering_bd_brokerages_attributes_submit: {},
        selected_offering: null,
        selected_offering_id: '',
        isBrokerDealer: false,
      }, () => { });
    }, 500);
  }

  handleAllChanges = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  handledspClickSharesChanges = (e) => {
    this.setState({
      dsp_click_shares: e.target.value,
    });
  }
  handleDspUnderwriterValueChanges = (e) => {
    this.setState({
      DspUnderwriterValue: e.target.value,
    })
  }


  handlePriceChanges = (e) => {
    this.setState({
      sendEmail: false,
      final_price: e.target.value,
      isPrice: false,
      isEmailShow: false,
    });

    if (e.target.value !== '') {
      const priceRange = {
        max: this.state.offering.max_price,
        min: this.state.offering.min_price,
      };

      const res = validatePrice(priceRange, parseInt(e.target.value));
      if (res) {
        this.setState({
          final_price: e.target.value,
          sendEmail: false,
          continueCount: 2,
          isPrice: false,
          isEmailShow: false,
        });
      } else {
        this.setState({
          sendEmail: true,
          isEmailShow: true,
        });
      }
    }
  }


  handleContinue(e) {
    if (this.prevIsValid()) {
      let newWarrantList = [];
      if (this.state.warrantList.length > 0) {

        let updatedWarrantList = this.state.warrantList;
        let oldWarrant = JSON.parse(this.state.wListOld);
        updatedWarrantList.forEach(element => {
          element.warrant_price = Number(element.warrant_price); 
          element.warrant_factor = Number(element.warrant_factor);
          element.underwriter_concession_amount = Number(element.underwriter_concession_amount);
        });
        oldWarrant.forEach(ele => {
          ele.warrant_price = Number(ele.warrant_price); 
          ele.warrant_factor = Number(ele.warrant_factor);
          ele.underwriter_concession_amount = Number(ele.underwriter_concession_amount);
        });
        for(let i=0; i < updatedWarrantList.length; i++) {
          if (JSON.stringify(updatedWarrantList[i]) === JSON.stringify(oldWarrant[i])) {
            updatedWarrantList[i].update = 0
          } else {
            updatedWarrantList[i].update = 1
          }
        }
        
        for (let warrant of updatedWarrantList) {
          let newWarrant = {
            ticker_symbol: warrant.ticker_symbol,
            warrant_price: Number(warrant.warrant_price), 
            warrant_factor: Number(warrant.warrant_factor), 
            cusip_id: warrant.cusip_id, 
            underwriter_concession_amount: Number(warrant.underwriter_concession_amount), 
            dsp: warrant.dsp,
            old_ticker_symbol: warrant.old_ticker_symbol,
            update: warrant.update
          }
          newWarrantList.push(newWarrant);
        }
      
      }
      const brokerDealerName = [];
      if (this.state.offering_bd_brokerages_attributes.length > 0) {
        for (const bdData of this.state.offering_bd_brokerages_attributes) {
          if (bdData.value === true) {
            brokerDealerName.push({ 'mpid': bdData.mpid, 'bd_commission_percentage': bdData.bd_commission, 'to_allocate': 1, 'dsp_commission_percentage': bdData.dsp_order_change });
          } else {
            brokerDealerName.push({ 'mpid': bdData.mpid, 'bd_commission_percentage': bdData.bd_commission, 'to_allocate': 0, 'dsp_commission_percentage': bdData.dsp_order_change });
          }
        }
      }
      // validate(this.state);
      // e.preventDefault();
      this.setState({
        isSubmit: true,
      }, () => { });
      const FinalData = {
        ext_id: this.state.selected_offering_id,
        received_shares: this.state.allocated_shares,
        cusip_id: this.state.cusipid.trim(),
        final_price: this.state.final_price,
        click_shares: this.state.amountOfShare,
        asked_shares: this.state.asked_shares,
        trade_date: dateTimeFormat(this.state.trade_date),
        settlement_date: dateTimeFormat(this.state.settlement_date),
        underwriter_mpid_id: this.state.bd_underwriter_mpid_id,
        underwriter_concession_amount: this.state.UnderwriterValue,
        offering_bd_brokerages_attributes: brokerDealerName,
        customer_exclude_list: this.state.customer_exclude_list.trim(),
        offeringName: this.state.selected_offering,
        dsp_click_shares: this.state.dsp_click_shares,
        ioi_cutoff_datetime: this.state.ioi_cutoff_datetime,
        dsp: this.state.isDsp,
        warrant_list: newWarrantList
      };
      if (this.state.isDsp === 1 || this.state.isDsp === 2) {
        FinalData.dsp_underwriter_concession_amount = Number(this.state.DspUnderwriterValue);
      }
      validate(this.state);
      const errors = validate(this.state);
      if (Object.keys(errors).length === 0) {
        this.props.postAllocation(FinalData);
      }
    }

  }

  selecttrade_date(e) {
    if (e.value) {
      this.setState({
        trade_date: new Date(dateTimeFormat(e.value)),
      }, () => { });
    } else {
      this.setState({
        trade_date: true,
        isSubmit: true,
      }, () => { });
    }
  }
  selectSettlementDate = (e) => {
    if (e.value) {
      this.setState({
        settlement_date: new Date(dateTimeFormat(e.value)),
      }, () => { });
    } else {
      this.setState({
        trade_date: true,
        isSubmit: true,
      }, () => { });
    }
  }
  goBackAllocation() {
    this.props.history.goBack();
  }
  selectIoiCutoff = (e) => {
    if (e.value) {
      this.setState({
        ioi_cutoff_datetime: dateTimeFormat(e.value),
        ioi_cutoff_datetime_show: e.value,
      }, () => { });
    } else {
      this.setState({
        ioi_cutoff_datetime: null,
      }, () => { });
    }
  }
  handleWarrantChange = (e, i) => {
    let wList = this.state.warrantList;
    if (e.target.name === 'dsp') {
      wList[i][e.target.name] = e.target.checked === true ? 1 : 0;
      this.setState({ warrantList: wList }, () => {
        this.setState({ uiRender: !this.state.uiRender });
      });
    } else {
      wList[i][e.target.name] = e.target.value;
      wList[i].errors[e.target.name] = e.target.value.length > 0 ? null : '';
      this.setState({ warrantList: wList }, () => {
        this.setState({ uiRender: !this.state.uiRender });
      });
    }
  }
  handleChangeWarrantPrice = (e, i) => {
    let wList = this.state.warrantList;
    const reg = /^\s*\d*\.?\d{0,4}\s*$/; 
    if (reg.test(e.target.value)) {
      wList[i][e.target.name] = e.target.value;
      wList[i].errors[e.target.name] = e.target.value.length > 0 ? null : '';
      this.setState({ warrantList: wList }, () => {
        this.setState({ uiRender: !this.state.uiRender });
      });
    }
  }
  handleChangeWarrantUCA = (e, i) => {
    let wList = this.state.warrantList;
    const re = /^\s*\d*\.?\d{0,4}\s*$/;
    if (re.test(e.target.value)) {
      wList[i][e.target.name] = e.target.value;
      wList[i].errors[e.target.name] = e.target.value.length > 0 ? null : '';
      this.setState({ warrantList: wList }, () => {
        this.setState({ uiRender: !this.state.uiRender });
      });
    }
  }
  prevIsValid = () => {
    if (this.state.warrantList.length === 0) {
      return true;
    }

    const someEmpty = this.state.warrantList.some(item => item.cusip_id === '' || item.ticker_symbol === '' || item.warrant_factor === '' || item.underwriter_concession_amount === '');
    if (someEmpty) {
      this.state.warrantList.forEach((item, index) => {
        const allPrev = [...this.state.warrantList];
        // if (this.state.warrantList[index].warrant_price === '') {
        //     allPrev[index].errors.warrant_price = "Please enter warrant price";
        // }
        // if (this.state.warrantList[index].warrant_factor === '') {
        //   allPrev[index].errors.warrant_factor = "Please enter warrant factor";
        // }
        if (this.state.warrantList[index].cusip_id === '') {
          allPrev[index].errors.cusip_id = "Please enter cusip id";
        }
        if (this.state.warrantList[index].ticker_symbol === '') {
          allPrev[index].errors.ticker_symbol = "Please enter ticker symbol";
        }
        if (this.state.warrantList[index].underwriter_concession_amount === '') {
          allPrev[index].errors.underwriter_concession_amount = "Please enter underwriter concession amount";
        }

        this.setState({
          warrantList: allPrev,
        })
      })

    }
    return !someEmpty;
  }
  render() {
    const { offeringList, userMpid } = this.state;
    const errors = validate(this.state);


    // console.log('warrantList', this.state.warrantList);
    // console.log('wListOld', this.state.wListOld);
    const typeAheadUserMpidList = [];
    if (userMpid && userMpid.length > 0) {
      userMpid.map((item) => {
        return typeAheadUserMpidList.push({ value: item.executing_broker_mpid, label: item.executing_broker_mpid })
      });
    }
    const typeAheadOfferingList = [];
    if (offeringList && offeringList.length > 0) {
      offeringList.map((item) => {
        return typeAheadOfferingList.push({ value: item.id, label: item.name })
      });
    }
    let review = '';

    review = (
      <div className="col-md-12">
        {(this.state.isDsp === 1 || this.state.isDsp === 2) &&
          <div className="col-md-12" style={{ margin: '0px 0px 20px 5px' }}>
            <div className="row">
              <div className="col-md-4 col-lg-4" style={{ padding: '0px 30px 0px 0px' }}>
                <label className="update-label">DSP Underwriter concession ($) *</label>
                <TextField type="text" fullWidth ref={(Elmt) => { this.DspUnderwriter = Elmt; }} defaultValue={this.state.DspUnderwriterValue} onChange={(e) => this.handleDspUnderwriterValueChanges(e)} placeholder="" />
                <label style={{ color: 'red', fontSize: 14, paddingRight: '14px' }} ref={(Elmt) => { this.DspUnderwriterErr = Elmt; }}></label>
                {this.state.isSubmit && errors && <label style={{ color: 'red', fontSize: 14 }}>{errors.DspUnderwriterErr}</label>}
              </div>
              {
                (this.state.isDsp === 1 || this.state.isDsp === 2) &&
                <div className="col-md-4 col-lg-4" style={{ padding: '0px 30px 0px 0px' }}>
                  <label className="update-label">DSP Shares *
                    <Tooltip title="List the number of Shares that are allocated to the DSP users">
                      <span style={{ cursor: 'pointer' }}><InfoIcon /></span>
                    </Tooltip>
                    {/* <b className="tooltiplable">
                      <i className="fa fa-info"></i>
                      <span className="tooltiptext">List the number of Shares that are allocated to the DSP users</span>
                    </b> */}
                  </label>
                  <div>
                    <TextField type="text" fullWidth value={this.state.dsp_click_shares} placeholder="" onChange={(e) => this.handledspClickSharesChanges(e)} />
                    <label style={{ color: 'red', fontSize: 14, paddingRight: '14px' }} ref={(Elmt) => { this.dsp_click_sharesErr = Elmt; }}></label>
                    {this.state.isSubmit && errors && <label style={{ color: 'red', fontSize: 14, paddingRight: '14px' }}>{errors.dsp_click_sharesErr}</label>}
                  </div>
                </div>
              }
              {
                (this.state.isDsp === 2) &&
                <div className="col-md-4 col-lg-4 calender-ui-allocation" style={{ padding: '0px 30px 0px 0px' }}>
                  <label className="update-label">IOI cutoff datetime  *</label>
                  <div>
                    <Calendar value={this.state.ioi_cutoff_datetime_show} ref={(Elmt) => { this.ioi_cutoff_datetime = Elmt; }} onChange={(e) => this.selectIoiCutoff(e)} minDate={minDate} placeholder="MM/DD/YYYY" showTime={true} showSeconds={true} hideOnDateTimeSelect={true} />
                    <div>
                      {this.state.isSubmit && errors && <label style={{ color: 'red', fontSize: 14, paddingRight: '14px' }}>{errors.ioi_cutoff_datetime}</label>}
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        }

        {this.state.offering_bd_brokerages_attributes.length > 0 &&
          <div className="broker_box">
            {this.state.offering_bd_brokerages_attributes.map((res, i) =>
              <div className="col-md-12" key={i} style={{ padding: '0px 20px 20px 20px', borderBottom: '1px solid #ddd' }}>
                <div className="row justify-content-center">
                  <div className="col-md-1 col-sm-12 mt-3" style={{ padding: '20px 20px 20px 30px' }}>
                    <input type="checkbox" className="broker_dealer_check" defaultChecked={res.value} onClick={() => { res.value = !(res.value); }} />
                  </div>
                  <div className="col-md-3 col-sm-12 mt-3" style={{ padding: '0px 20px 0px 20px' }}>
                    <label className="update-label">Broker Dealer Name </label>
                    <TextField type="text" id={`BrokerDealerName${i}`} fullWidth disabled defaultValue={res.broker_dealer_name} placeholder="" />
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3" style={{ padding: '0px 20px 0px 20px' }}>
                    <label className="update-label">Broker Dealer Commision (%) -- example: 10% is 0.10</label>
                    <TextField type="text" id={`BrokerDealerCommission${i}`} fullWidth defaultValue={res.bd_commission} onKeyUp={(event) => { this.handleChangesBD_attributes(event, res, i); }} placeholder="" />
                  </div>
                  {
                    (res.dsp_order === 1) &&
                    <div className="col-md-4 col-sm-12 mt-3" style={{ padding: '0px 20px 0px 20px' }}>
                      <label className="update-label">
                        DSP Broker Dealer Commision (%) -- example: 10% is 0.10
                      </label>
                      <TextField type="text" name="dsp_order" fullWidth defaultValue={this.state.dsp_order_value} onChange={(event) => { this.handleChangesBD_attributes(event, res, i); }} placeholder="" />
                    </div>
                  }
                  <div className="col-md-4 col-sm-12 mt-3" style={{ padding: '0px 20px 0px 20px' }}>
                    <label className="update-label">Bias</label>
                    <TextField type="text" fullWidth defaultValue={this.state.bias} onChange={(event) => { this.handleChangesBIAS(event, res, i); }} placeholder="" />
                  </div>
                </div>
              </div>)}
          </div>
        }
      </div>
    );
    if (offeringList && offeringList.length > 0) {
      offeringList.map((item) => {
        return (
          <option value={item.id} key={item.id}>
            {item.name}
          </option>
        );
      });
    }
    return (
      <Dashboard title="ClickIPO Admin - Create Allocation"  >
        <div className="allocation_content">
          <div >

            <div className="allocation_Detail_header">
              <div className="headingname-show-allocation" >
                <ArrowBackIosIcon onClick={() => this.goBackAllocation()} style={{ cursor: 'pointer' }} /> Create new allocation
              </div>
            </div>
            <div className="create-allocation-form" style={{ height: 'calc(100vh - 160px)', overflowY: 'auto', overflowX: 'hidden' }}>
              <div style={{ padding: '50px 0px' }}>
                <div className="row">
                  <div className="col-md-4 col-sm-12" style={{padding: '0px 20px'}}>
                    <label className="update-label">Offering name *</label>
                    <div>
                      <Select
                        className="allocationofffering"
                        name="selected_offering"
                        id="selectedoffering_id"
                        value={this.state.selected_offering}
                        onChange={this.offeringSelectChange}
                        options={typeAheadOfferingList}
                      />
                    </div>
                    {this.state.isSubmit && errors && <label style={{ color: 'red', fontSize: 14, paddingRight: '14px' }}>{errors.selectedoffering_id_Err}</label>}
                  </div>
                  <div className="col-md-4 col-sm-12 calender-ui-allocation" style={{ padding: '0px 20px 0px 20px' }}>
                    <label className="update-label">Trade Date *</label>
                    <div>
                      <Calendar value={this.state.trade_date} ref={(Elmt) => { this.trade_date = Elmt; }} onChange={(e) => this.selecttrade_date(e)} minDate={minDate} placeholder="MM/DD/YYYY" yearNavigator yearRange="2018:2050" style={{ width: '100%' }} />
                    </div>
                    <label style={{ color: 'red', fontSize: 14, paddingRight: '14px' }} ref={(Elmt) => { this.trade_dateErr = Elmt; }}></label>
                    {this.state.isSubmit && errors && <label style={{ color: 'red', fontSize: 14, paddingRight: '14px' }}>{errors.trade_dateErr}</label>}
                  </div>
                  <div className="col-md-4 col-sm-12 calender-ui-allocation" style={{ padding: '0px 20px 0px 20px' }}>
                    <label className="update-label">Settlement Date *</label>
                    <div>
                      <Calendar value={this.state.settlement_date} ref={(Elmt) => { this.settlement_date = Elmt; }} onChange={(e) => this.selectSettlementDate(e)} minDate={minDate} placeholder="MM/DD/YYYY" yearNavigator yearRange="2018:2050" style={{ width: '100%' }} />
                    </div>
                    {this.state.isSubmit && errors && <label style={{ color: 'red', fontSize: 14, paddingRight: '14px' }}>{errors.settlement_dateErr}</label>}
                    <label style={{ color: 'red', fontSize: 14, paddingRight: '14px' }} ref={(Elmt) => { this.settlement_dateErr = Elmt; }}></label>
                  </div>
                  <div className="col-md-4 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
                    <label className="update-label">CUSIP ID *</label>
                    <div>
                      <TextField type="text" name="cusipid" fullWidth ref={(Elmt) => { this.cusipid = Elmt; }} value={this.state.cusipid} placeholder="" onChange={(e) => this.handleAllChanges(e)} />
                    </div>
                    <label style={{ color: 'red', fontSize: 14, paddingRight: '14px' }} ref={(Elmt) => { this.cusipidErr = Elmt; }}></label>
                    {this.state.isSubmit && errors && <label style={{ color: 'red', fontSize: 14, paddingRight: '14px' }}>{errors.cusipidErr}</label>}
                  </div>
                  <div className="col-md-4 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
                    <label className="update-label ">
                      Underwriter Requested Shares *
                      <Tooltip title="List the shares requested by ClickIPO from the Underwriter based on demand on this offering (Could be base demand + DSP demand)">
                        <span style={{ cursor: 'pointer' }}><InfoIcon /></span>
                      </Tooltip>
                    </label>
                    <div>
                      <TextField type="text" name="asked_shares" fullWidth ref={(Elmt) => { this.asked_shares = Elmt; }} value={this.state.asked_shares} placeholder="" onChange={(e) => this.handleAllChanges(e)} />
                    </div>
                    <label style={{ color: 'red', fontSize: 14 }} ref={(Elmt) => { this.asked_sharesErr = Elmt; }}></label>
                    {this.state.isSubmit && errors && <label style={{ color: 'red', fontSize: 14 }}>{errors.asked_sharesErr}</label>}
                  </div>
                  <div className="col-md-4 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
                    <label className="update-label">Received Shares *
                      <Tooltip title="Shares received from the Underwriter (Could be base + DSP allocation)">
                        <span style={{ cursor: 'pointer' }}><InfoIcon /></span>
                      </Tooltip>
                    </label>
                    <div>
                      <TextField type="text" name="allocated_shares" fullWidth ref={(Elmt) => { this.allocated_shares = Elmt; }} value={this.state.allocated_shares} placeholder="" onChange={(e) => this.handleAllChanges(e)} />
                    </div>
                    <label style={{ color: 'red', fontSize: 14, paddingRight: '14px' }} ref={(Elmt) => { this.allocated_sharesErr = Elmt; }}></label>
                    {this.state.isSubmit && errors && <label style={{ color: 'red', fontSize: 14, paddingRight: '14px' }}>{errors.allocated_sharesErr}</label>}
                  </div>

                  <div className="col-md-4 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
                    <label className="update-label click_ipo_shares">Click IPO Shares *
                      <Tooltip title="List the number of shares that will be allocated to the retail customers who are not in the DSP category">
                        <span style={{ cursor: 'pointer' }}><InfoIcon /></span>
                      </Tooltip>
                    </label>
                    <div>
                      <TextField type="text" fullWidth ref={(Elmt) => { this.amountOfShare = Elmt; }} value={this.state.amountOfShare} name="amountOfShare" placeholder="" onChange={(e) => this.handleAllChanges(e)} />
                    </div>
                    <label style={{ color: 'red', fontSize: 14, paddingRight: '14px' }} ref={(Elmt) => { this.amountOfShareErr = Elmt; }}></label>
                    {this.state.isSubmit && errors && <label style={{ color: 'red', fontSize: 14, paddingRight: '14px' }}>{errors.amountOfShareErr}</label>}
                  </div>
                  <div className="col-md-4 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
                    <label className="update-label">Final Price ($) *</label>
                    <div>
                      <TextField type="text" fullWidth name="final_price" value={this.state.final_price} placeholder="" onChange={(e) => this.handlePriceChanges(e)} />
                    </div>
                    <label style={{ color: 'red', fontSize: 14, paddingRight: '14px' }}></label>
                    {this.state.isSubmit && errors && <label style={{ color: 'red', fontSize: 14, paddingRight: '14px' }}>{errors.final_priceErr}</label>}
                  </div>

                  <div className="col-md-4 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
                    <label className="update-label">Underwriter MPID *</label>
                    <div>
                      <Select
                        className="allocationofffering"
                        name="selected_bd_underwriter"
                        id="bd_underwriter_mpid_id"
                        value={this.state.selected_bd_underwriter_mpid_id === undefined ? '' : this.state.selected_bd_underwriter_mpid_id}
                        onChange={(event) => this.mpIDhandleChange(event)}
                        options={typeAheadUserMpidList}
                      />
                    </div>
                    {this.state.isSubmit && errors && <label style={{ color: 'red', fontSize: 14, paddingRight: '14px' }}>{errors.selected_bd_underwriter_mpid_Err}</label>}
                  </div>
                  <div className="col-md-4 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
                    <label className="update-label">Underwriter concession ($) *</label>
                    <div>
                      <TextField type="text" name="UnderwriterValue" fullWidth ref={(Elmt) => { this.Underwriter = Elmt; }} value={this.state.UnderwriterValue} onChange={(e) => this.handleAllChanges(e)} placeholder="" />
                    </div>
                    <label style={{ color: 'red', fontSize: 14 }}></label>
                    {this.state.isSubmit && errors && <label style={{ color: 'red', fontSize: 14 }}>{errors.UnderwriterErr}</label>}
                  </div>
                  {
                    review
                  }
                  <div className="col-md-4 col-sm-12" style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                    <div className="col-md-12 col-sm-12">
                      <label className="update-label">Customer Exclude List</label>
                      <div>
                        <TextField type="text" name="customer_exclude_list" fullWidth ref={(Elmt) => { this.customer_exclude_list = Elmt; }} value={this.state.customer_exclude_list} onChange={(e) => this.handleAllChanges(e)} />
                      </div>
                    </div>
                  </div>
                  {
                    this.state.warrantList && this.state.warrantList.length > 0 &&
                    <div className="col-md-12">
                      <div className="createwarrant_box mt-4 pb-3">
                        {
                          this.state.warrantList && this.state.warrantList.map((op, index) =>
                            <div className="col-md-12 col-sm-12" style={{ paddingTop: 16, paddingBottom: 16, borderBottom: '1px solid #ddd' }} key={index}>
                              <div className="row">
                                <div className="col-md-4 mt-3">
                                  <label className="update-label">Warrant Price</label>
                                  <TextField type="text" fullWidth name="warrant_price" value={op?.warrant_price} onChange={(e) => { this.handleChangeWarrantPrice(e, index); }} />
                                  {/* {
                                    op.errors.warrant_price &&
                                    <span style={{ color: 'red', fontSize: 14 }}>{op.errors.warrant_price}</span>
                                  } */}
                                </div>
                                <div className="col-md-4 mt-3">
                                  <label className="update-label">Shares to warrant conversion factor

                                    <Tooltip title={<div style={{ fontSize: 12 }}>2 shares to 1 warrant : warrant factor = 0.5<br />1 share to 1 warrant : warrant factor = 1</div>} placement="bottom" arrow>
                                      <InfoIcon style={{ fontSize: 16, cursor: 'pointer' }} />
                                    </Tooltip>
                                  </label>
                                  <TextField type="text" fullWidth name="warrant_factor" value={op?.warrant_factor} onChange={(e) => { this.handleChangeWarrantPrice(e, index); }} />
                                  {/* {
                                    op.errors.warrant_factor &&
                                    <span style={{ color: 'red', fontSize: 14 }}>{op.errors.warrant_factor}</span>
                                  } */}
                                </div>
                                <div className="col-md-4 mt-3">
                                  <label className="update-label">CUSIP ID</label>
                                  <TextField type="text" fullWidth name="cusip_id" value={op.cusip_id} onChange={(e) => { this.handleWarrantChange(e, index); }} />
                                  {op.errors.cusip_id && <span style={{ color: 'red', fontSize: 14 }}>{op.errors.cusip_id}</span>}
                                </div>
                                <div className="col-md-4 mt-3">
                                  <label className="update-label">Ticker Symbol</label>
                                  <TextField type="text" fullWidth name="ticker_symbol" value={op?.ticker_symbol} onChange={(e) => { this.handleWarrantChange(e, index); }} />
                                  {
                                    op.errors.ticker_symbol &&
                                    <span style={{ color: 'red', fontSize: 14 }}>{op.errors.ticker_symbol}</span>
                                  }
                                </div>
                                <div className="col-md-4 mt-3">
                                  <label className="update-label">Underwriter Concession Amount</label>
                                  <TextField type="text" fullWidth name="underwriter_concession_amount" value={op?.underwriter_concession_amount} onChange={(e) => { this.handleChangeWarrantUCA(e, index); }} />
                                  {
                                    op.errors.underwriter_concession_amount &&
                                    <span style={{ color: 'red', fontSize: 14 }}>{op.errors.underwriter_concession_amount}</span>
                                  }
                                </div>
                                <div className="col-md-4 mt-3">
                                  <div className="warrent-dsp-checkbox">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={(op.dsp === true || op.dsp === 1) ? true : false}
                                          onChange={(e) => { this.handleWarrantChange(e, index); }}
                                          name="dsp"
                                          color="primary"
                                        />
                                      }
                                      label="Include DSP"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  }
                  <div className="col-md-12 col-sm-12" style={{ marginTop: 40 }}>
                    <Button variant="contained" color="primary" onClick={this.handleContinue}>Submit</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>

    );
  }
}

CreateAllocationForm.propTypes = {
  allocationOfferingListRes: PropTypes.any,
  resetOfferings: PropTypes.any,
  allocationOfferingRes: PropTypes.any,
  underWriterMpidListRes: PropTypes.any,
  createAllocationRes: PropTypes.any,
};

const clickIPOCreateCreateAllocationForm = reduxForm({
  form: 'clickIPOCreateCreateAllocationForm',
  validate,
  fields,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  touchOnBlur: false,

})(CreateAllocationForm);

const mapStateToProps = createStructuredSelector({
  allocationOfferingListRes: doFetchAllocationOfferingListRes,
  allocationOfferingRes: doFetchAllocationOfferingRes,
  underWriterMpidListRes: underWriterMpidListRes,
  createAllocationRes: doCreateAllocationRes,
});

function mapDispatchToProps(dispatch) {
  return {
    fetchUserMpid: () => dispatch(getUnderwriterMpid()),
    postAllocation: (data) => dispatch(submitCreateAllocation(data)),
    resetOfferings: () => dispatch(doFetchAllocationOfferingListRes(null)),
    fetchOfferings: () => dispatch(fetchAllocationOfferingList()),
    fetchAllocationOffering: (id) => dispatch(fetchAllocationOffering(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(clickIPOCreateCreateAllocationForm);
