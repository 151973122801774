import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import validate from './formValidation';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

const fields = ['first_name', 'last_name', 'email', 'role_type'];
const renderField = ({ input, label, type, disabled, meta: { touched, error } }) => (
  <div>
      <label className="update-label">{''}</label>
      <TextField style={{width: '100%'}} {...input} type={type} disabled={disabled} label={label} />
      {touched && error && renderFromHelper({ touched, error })}
      
  </div>
)
const renderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return
  } else {
    return <FormHelperText style={{color: 'red'}}>{touched && error}</FormHelperText>
  }
}
const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <FormControl>
    <InputLabel htmlFor="role_type-native-simple">{label}</InputLabel>
    <Select
      native
      {...input}
      {...custom}
      inputProps={{
        name: 'role_type',
        id: 'role_type-native-simple'
      }}
    >
      {children}
    </Select>
    {renderFromHelper({ touched, error })}
  </FormControl>
)

const validate = values => {
  const errors = {}
  const requiredFields = [
    'first_name',
    'last_name',
    'email',
    'role_type'
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address'
  }
  return errors
}


class CreateBrokerDealerForm extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      borkerInfo: {}
    }
  } 
  render() {
    const { handleSubmit, submitting, handleFormSubmit } = this.props;
    return (
      <div className="addBrokerDealer">
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="row">
              <div className="col-xs-12 col-md-6" style={{ marginTop: 20 }}>

                <Field
                    name="first_name"
                    component={renderField}
                    label="First name *"
                    autoComplete="off"
                    type="text"
                />
              </div>
              <div className="col-xs-12 col-md-6" style={{ marginTop: 20 }}>

                <Field
                    name="last_name"
                    component={renderField}
                    label="Last name *"
                    autoComplete="off"
                    type="text"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-12" style={{ marginTop: 20 }}>

                <Field
                    name="email"
                    component={renderField}
                    label="Email *"
                    autoComplete="off"
                    type="text"
                />
              </div>
              <div className="col-xs-12 col-md-12" style={{ marginTop: 20 }}>
                <Field
                  name="role_type"
                  component={renderSelectField}
                  label="Role Type"
                >
                  <option value="" />
                  <option value={'broker_dealer_admin'}>Brokerdealer Admin</option>
                  <option value={'broker_dealer_manager'}>Brokerdealer Manager</option>
                  <option value={'broker_dealer_custodian'}>Brokerdealer Custodian</option>
                </Field>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-12">
                <div style={{ marginTop: 30 }}>
                  <Button type="submit" variant="contained" color="primary" disabled={submitting} >Submit</Button>
                </div>
              </div>
            </div>
        </form>
      </div>
    );
  }
}


CreateBrokerDealerForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  handleFormSubmit: PropTypes.func,
  brokerItem: PropTypes.any,
};

const clickIPOCreateBrokeradminForm = reduxForm({
  form: 'clickIPOCreateBrokeradminForm',
  fields,
  validate
})(CreateBrokerDealerForm);

export default clickIPOCreateBrokeradminForm;
