import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './stats.scss';
import { numberWithCommasAndDollar, numberWithCommas } from '../../helper/helper';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
	}))(props => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});
  
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);
class UserStatsComponent extends React.PureComponent {

	constructor() {
		super();
		this.state = {
			showDetailStatus: false,
			userBdData: ''
		}
	}

	showDetails = (data) => {
		this.setState({
			showDetailStatus: true,
			userBdData: data,
		}, () => {});
	}
	closeModal = () => {
		this.setState({
			showDetailStatus: false
		}, () => {});
	}
	renderTooltip = (item) => {

		if (item && item.payload && item.payload.length > 0) {
			return <div style={{ padding: 8 }}>
				<div className="user_stat_detail_box">
					<div className="row user_stat_detail">
						<div style={{ width: '50%' }} className="col-md-6 user_stat_detail_name">Total User(s) </div>
						<div style={{ width: '50%' }} className="col-md-6 user_stat_detail_value">: <span>{numberWithCommas(item.payload[0].payload.count)}</span></div>
					</div>
					<div className="row user_stat_detail">
						<div style={{ width: '50%' }} className="col-md-6 user_stat_detail_name">Unique user with order(s) </div>
						<div style={{ width: '50%' }} className="col-md-6 user_stat_detail_value">: <span>{numberWithCommas(item.payload[0].payload.unique_users_with_orders)}</span></div>
					</div>
					<div className="row user_stat_detail">
						<div style={{ width: '50%' }} className="col-md-6 user_stat_detail_name">Maximum Order Size </div>
						<div style={{ width: '50%' }} className="col-md-6 user_stat_detail_value">: <span>{numberWithCommasAndDollar(item.payload[0].payload.max_order_size)}</span></div>
					</div>
					<div className="row user_stat_detail">
						<div style={{ width: '50%' }} className="col-md-6 user_stat_detail_name">Minimum Order Size </div>
						<div style={{ width: '50%' }} className="col-md-6 user_stat_detail_value">: <span>{numberWithCommasAndDollar(item.payload[0].payload.min_order_size)}</span></div>
					</div>
					<div className="row user_stat_detail">
						<div style={{ width: '50%' }} className="col-md-6 user_stat_detail_name">Average Order Size</div>
						<div style={{ width: '50%' }} className="col-md-6 user_stat_detail_value">: <span>{numberWithCommasAndDollar(item.payload[0].payload.avg_order_size)}</span></div>
					</div>
					<div className="row user_stat_detail">
						<div style={{ width: '50%' }} className="col-md-6 user_stat_detail_name">Total Order Amount</div>
						<div style={{ width: '50%' }} className="col-md-6 user_stat_detail_value">:
				  			{
								(item.payload[0].payload.total_requested_amount) &&
								<span>{numberWithCommasAndDollar(item.payload[0].payload.total_requested_amount)}</span>
							}
							{
								(!(item.payload[0].payload.total_requested_amount)) &&
								<span>$ 0</span>
							}
						</div>
					</div>
					<div className="row user_stat_detail">
						<div style={{ width: '50%' }} className="col-md-6 user_stat_detail_name">Total Number of Orders</div>
						<div style={{ width: '50%' }} className="col-md-6 user_stat_detail_value">:
							{
								(item.payload[0].payload.total_num_orders) &&
								<span>{numberWithCommas(item.payload[0].payload.total_num_orders)}</span>
							}
							{
								(!(item.payload[0].payload.total_num_orders)) &&
								<span>0</span>
							}
						</div>
					</div>
				</div>
			</div>
		}

	}
	render() {
		const { userBdData } = this.state;
		const { userPerbdData } = this.props;

		return (
			<div className="stats_offering_content_body">
				<div className="row">
					<div className="col-md-12 tableheight" style={{ paddingLeft: 52, paddingRight: 52, paddingTop: 10, paddingBottom: 15 }}>
						<div className="total-offering-state-offering">Total Users : {this.props.totalUsers} <span style={{ paddingLeft: 20 }}>Deleted Users : {this.props.deletedUsers}</span></div>
						<div className="row">
							{
								userPerbdData && userPerbdData.map((op, i) => 
								<div className="col-md-3 mb-4" key={i}>
									{
										op.count > 0 ?
										<Tooltip title="Click to see more detail" placement="top">
											<Paper elevation={3} className="user-stats-box active-user-stats" onClick={() => this.showDetails(op)}>
												<h2 className="mt-4">{op.count}</h2>
												<p>{op.name}</p>
											</Paper>
										</Tooltip>
										:
										<Paper elevation={3} className="user-stats-box">
											<h2 className="mt-4">{op.count}</h2>
											<p>{op.name}</p>
										</Paper>
									}
									
								</div>
								)
							}
						</div>
						<Dialog
							onClose={this.closeModal}
							aria-labelledby="customized-dialog-title"
							open={this.state.showDetailStatus}
							maxWidth={"sm"}
							fullWidth={true}
							scroll={'paper'}
						>
						<DialogTitle onClose={this.closeModal} >{userBdData.name}</DialogTitle>
						<DialogContent>
							<div className="col-md-12">
								<div style={{ padding: 8 }}>
									{
										userBdData &&
									<div className="user_stat_detail_box">
										<div className="row user_stat_detail">
											<div className="col-md-6 user_stat_detail_name">Total User(s) </div>
											<div className="col-md-6 user_stat_detail_value">: <span>{numberWithCommas(userBdData.count)}</span></div>
										</div>
										<div className="row user_stat_detail">
											<div className="col-md-6 user_stat_detail_name">Unique user with order(s) </div>
											<div className="col-md-6 user_stat_detail_value">: <span>{numberWithCommas(userBdData.unique_users_with_orders)}</span></div>
										</div>
										<div className="row user_stat_detail">
											<div className="col-md-6 user_stat_detail_name">Maximum Order Size </div>
											<div className="col-md-6 user_stat_detail_value">: <span>{numberWithCommasAndDollar(userBdData.max_order_size)}</span></div>
										</div>
										<div className="row user_stat_detail">
											<div className="col-md-6 user_stat_detail_name">Minimum Order Size </div>
											<div className="col-md-6 user_stat_detail_value">: <span>{numberWithCommasAndDollar(userBdData.min_order_size)}</span></div>
										</div>
										<div className="row user_stat_detail">
											<div className="col-md-6 user_stat_detail_name">Average Order Size</div>
											<div className="col-md-6 user_stat_detail_value">: <span>{numberWithCommasAndDollar(userBdData.avg_order_size)}</span></div>
										</div>
										<div className="row user_stat_detail">
											<div className="col-md-6 user_stat_detail_name">Total Order Amount</div>
											<div className="col-md-6 user_stat_detail_value">:
												{
													// (userBdData.total_requested_amount) &&
													<span>{numberWithCommasAndDollar(userBdData.total_requested_amount)}</span>
												}
												{/* {
													(!(userBdData.total_requested_amount)) &&
													<span>$ 0</span>
												} */}
											</div>
										</div>
										<div className="row user_stat_detail">
											<div className="col-md-6 user_stat_detail_name">Total Number of Orders</div>
											<div className="col-md-6 user_stat_detail_value">:
												{
													// (userBdData.total_num_orders) &&
													<span>{numberWithCommas(userBdData.total_num_orders)}</span>
												}
												{/* {
													(!(userBdData.total_num_orders)) &&
													<span>0</span>
												} */}
											</div>
										</div>
									</div>
									}
								</div>
							</div>
						</DialogContent>
					</Dialog>
					</div>

				</div>
			</div>
		)
	}
}

UserStatsComponent.propTypes = {
};

const mapStateToProps = createStructuredSelector({
});
function mapDispatchToProps(dispatch) {
	return {
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(UserStatsComponent);