import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import './offerings.scss';
import Dashboard from '../../component/Dashboard';
import { getOffering, doOfferingActiveRes, doOfferingEffectiveRes, doOfferingCancelledRes, doOfferingClosedRes } from '../../actions/offeringActions';
import { sendArchiveOffering, doArchiveOfferingRes, sendUnarchiveOffering, doUnarchiveOfferingRes } from '../../actions/archiveOfferingActions';
import { sendCreateWarrant, doCreateWarrantRes, sendUpdateWarrant, doUpdateWarrantRes } from '../../actions/createWarrantActions';
import { makeCurrentCall, doCurrentCallRes } from '../../actions/currentCallActions';
import OfferingListComponent from './offeringList';
import { getItem } from '../../utils/localStore';
// import {Calendar} from 'primereact/calendar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
// import RefreshIcon from '@material-ui/icons/Refresh';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { DialogActions } from '@material-ui/core';
import { saveFile } from '../../helper/helper';
const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
	}))(props => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});
  
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

let isActiveOffering = false;
let isEffectiveOffering = false;
let isCancelledOffering = false;
let isClosedOffering = false;
class OfferingComponent extends React.PureComponent {

	constructor() {
		super();
		isActiveOffering = false;
		isEffectiveOffering = false;
		isCancelledOffering = false;
		isClosedOffering = false;
		this.state = {
			isReadOnly: JSON.parse(getItem('isReadOnly')),
			// currentPage: 1,
			// effectivePage: 1,
			searching: '',
			// searchbyCat: 'name',
			// fromdate: null,
			// todate: null,
			// orderBy: 'asc',
			offeringListing: [],
			tabIndex: 0,
			activeOffering: [],
			effectiveOffering: [],
			cancelledOffering: [],
			closedOffering: [],
			pagenumActive: 1,
			pagenumEffective: 1,
			pagenumCancelled: 1,
			pagenumClosed: 1,
			isFetchActive: false,
			isFetchEffective: false,
			isFetchCancelled: false,
			isFetchClosed: false,
			lastActiveOffering: 0,
			lastEffectiveOffering: 0,
			lastCancelledOffering: 0,
			lastClosedOffering: 0,
			activeOfferingLength: 0,
			effectiveOfferingLength: 0,
			cancelledOfferingLength: 0,
			closedOfferingLength: 0,
			showDownLoadCSVModal: false,
			startDate: '',
			endDate: '',
			startDateErrorMsg: '',
			endDateErrorMsg: '',
			isDownload: false,
			filterBy: 'name',
			offeringExtId: '',
			warrantList: [],
		}
	}

	componentDidMount() {
		if (this.state.activeOffering.length === 0) {
			isActiveOffering = true;
			let request = {
				status: 'active',
				pagenum: 1,
			}
			this.props.getOfferings(request, true);
		}
		if (this.props.location.state) {
			if (this.props.location.state.tabIndex) {
				this.setState({
					tabIndex: this.props.location.state.tabIndex
				}, () => {
					if (this.state.tabIndex === 1) {
						isEffectiveOffering = true;
						let request = {
							status: 'effective',
							pagenum: 1,
						}
						this.props.getOfferings(request, true);
						delete this.props.location.state.tabIndex;
					}
					if (this.state.tabIndex === 2) {
						isCancelledOffering = true;
						let request = {
							status: 'cancelled',
							pagenum: 1,
						}
						this.props.getOfferings(request, true);
						delete this.props.location.state.tabIndex;
					}
					if (this.state.tabIndex === 3) {
						isClosedOffering = true;
						let request = {
							status: 'closed',
							pagenum: 1,
						}
						this.props.getOfferings(request, true);
						delete this.props.location.state.tabIndex;
					}
				});
			}
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (props.offeringActiveRes) {
			if (props.offeringActiveRes.data && props.offeringActiveRes.data.offeringActive) {
				if (props.offeringActiveRes.data.offeringActive.message === 'Success' && isActiveOffering) {
					isActiveOffering = false;
					var isAllActiveOffering = state.activeOfferingLength;
					if (state.activeOfferingLength === 0) {
						isAllActiveOffering = props.offeringActiveRes.data.offeringActive.data.length
					}
					for (let off of props.offeringActiveRes.data.offeringActive.data) {
						if (off.warrant_list && off.warrant_list.length > 0) {
							for (let warrant of off.warrant_list) {
								warrant.errors = {
									"ticker_symbol": null, 
									"warrant_price": null, 
									"warrant_factor": null, 
									"cusip_id": null, 
									"underwriter_concession_amount": null
								}
							}
						}
					}
					if (state.isFetchActive) {
						return {
							lastActiveOffering: props.offeringActiveRes.data.offeringActive.data.length,
							activeOfferingLength: isAllActiveOffering,
							activeOffering: state.activeOffering.concat(props.offeringActiveRes.data.offeringActive.data),
							isFetchActive: false,
						};
					} else {
						return {
							lastActiveOffering: props.offeringActiveRes.data.offeringActive.data.length,
							activeOfferingLength: isAllActiveOffering,
							activeOffering: props.offeringActiveRes.data.offeringActive.data,
						};
					}

				}
			}
		}
		if (props.offeringEffectiveRes) {
			if (props.offeringEffectiveRes.data && props.offeringEffectiveRes.data.offeringEffective) {
				if (props.offeringEffectiveRes.data.offeringEffective.message === 'Success' && isEffectiveOffering) {
					isEffectiveOffering = false;
					var isAllEffectiveOffering = state.effectiveOfferingLength;
					if (state.effectiveOfferingLength === 0) {
						isAllEffectiveOffering = props.offeringEffectiveRes.data.offeringEffective.data.length
					}
					if (state.isFetchEffective) {
						return {
							lastEffectiveOffering: props.offeringEffectiveRes.data.offeringEffective.data.length,
							effectiveOfferingLength: isAllEffectiveOffering,
							effectiveOffering: state.effectiveOffering.concat(props.offeringEffectiveRes.data.offeringEffective.data),
							isFetchEffective: false
						};
					} else {
						return {
							lastEffectiveOffering: props.offeringEffectiveRes.data.offeringEffective.data.length,
							effectiveOfferingLength: isAllEffectiveOffering,
							effectiveOffering: props.offeringEffectiveRes.data.offeringEffective.data
						};
					}
				}
			}
		}
		if (props.offeringCancelledRes) {
			if (props.offeringCancelledRes.data && props.offeringCancelledRes.data.offeringCancelled) {
				if (props.offeringCancelledRes.data.offeringCancelled.message === 'Success' && isCancelledOffering) {
					isCancelledOffering = false;
					var isAllCancelledOffering = state.cancelledOfferingLength;
					if (state.cancelledOfferingLength === 0) {
						isAllCancelledOffering = props.offeringCancelledRes.data.offeringCancelled.data.length
					}
					if (state.isFetchCancelled) {
						return {
							lastCancelledOffering: props.offeringCancelledRes.data.offeringCancelled.data.length,
							cancelledOfferingLength: isAllCancelledOffering,
							cancelledOffering: state.cancelledOffering.concat(props.offeringCancelledRes.data.offeringCancelled.data),
							isFetchCancelled: false
						};
					} else {
						return {
							lastCancelledOffering: props.offeringCancelledRes.data.offeringCancelled.data.length,
							cancelledOfferingLength: isAllCancelledOffering,
							cancelledOffering: props.offeringCancelledRes.data.offeringCancelled.data
						};
					}
				}
			}
		}
		if (props.offeringClosedRes) {
			if (props.offeringClosedRes.data && props.offeringClosedRes.data.offeringClosed) {
				if (props.offeringClosedRes.data.offeringClosed.message === 'Success' && isClosedOffering) {
					isClosedOffering = false;
					var isAllClosedOffering = state.closedOfferingLength;
					if (state.closedOfferingLength === 0) {
						isAllClosedOffering = props.offeringClosedRes.data.offeringClosed.data.length
					}
					if (state.isFetchClosed) {
						return {
							lastClosedOffering: props.offeringClosedRes.data.offeringClosed.data.length,
							closedOfferingLength: isAllClosedOffering,
							closedOffering: state.closedOffering.concat(props.offeringClosedRes.data.offeringClosed.data),
							isFetchClosed: false
						};
					} else {
						return {
							lastClosedOffering: props.offeringClosedRes.data.offeringClosed.data.length,
							closedOfferingLength: isAllClosedOffering,
							closedOffering: props.offeringClosedRes.data.offeringClosed.data
						};
					}
				}
			}
		}
		if (props.archiveOfferingRes) {
			if (props.archiveOfferingRes.data && props.archiveOfferingRes.data.archiveOffering) {
				if (props.archiveOfferingRes.data.archiveOffering.message === 'Success') {
					let activeAllOfferings = state.activeOffering;
					let cancelledOfferingList = state.cancelledOffering;
					for (let item of activeAllOfferings) {
						if (item.ext_id === state.offeringExtId) {
							const index = activeAllOfferings.indexOf(item);
							if (index > -1) {
								activeAllOfferings.splice(index, 1);
							}
							item.status = 'cancelled';
							cancelledOfferingList.push(item);
						}
					}
					return {
						activeOffering: activeAllOfferings,
						cancelledOffering: cancelledOfferingList
					}
				}
			}
		}

		if (props.unarchiveOfferingRes) {
			if (props.unarchiveOfferingRes.data && props.unarchiveOfferingRes.data.unarchiveOffering) {
				if (props.unarchiveOfferingRes.data.unarchiveOffering.message === 'Success') {
					let cancelledAllOfferings = state.cancelledOffering;
					for (let item of cancelledAllOfferings) {
						if (item.ext_id === state.offeringExtId) {
							const index = cancelledAllOfferings.indexOf(item);
							if (index > -1) {
								cancelledAllOfferings.splice(index, 1);
							}
						}
					}
					return {
						cancelledOffering: cancelledAllOfferings
					}
				}
			}
		}
		if (props.createWarrantRes) {
			if (props.createWarrantRes.data && props.createWarrantRes.data.createWarrant) {
				if (props.createWarrantRes.data.createWarrant.message === 'Success') {
					let activeAllOfferings = state.activeOffering;
					for (let item of activeAllOfferings) {
						if (item.ext_id === state.offeringExtId) {
							item.warrant_list = state.warrantList;
							item.openWarrant = false;
						}
					}
					return {
						activeOffering: activeAllOfferings,
					}
				}
			}
		}
		if (props.updateWarrantRes) {
			if (props.updateWarrantRes.data && props.updateWarrantRes.data.updateWarrant) {
				if (props.updateWarrantRes.data.updateWarrant.message === 'Success') {
					let activeAllOfferings = state.activeOffering;
					for (let item of activeAllOfferings) {
						if (item.ext_id === state.offeringExtId) {
							item.warrant_list = state.warrantList;
							item.openWarrant = false;
						}
					}
					return {
						activeOffering: activeAllOfferings,
					}
				}
			}
		}
		if (props.currentCallRes) {
			if (props.currentCallRes.data && props.currentCallRes.data.currentCall) {
				if (props.currentCallRes.data.currentCall.message === 'Success') {
					// props.getOfferings('cancelled');
					// props.getOfferings('active');
					let cancelledAllOffering = state.cancelledOffering;
					let activeOfferingsList = state.activeOffering;
					for (let item of cancelledAllOffering) {
						if (item.ext_id === state.offeringExtId) {
							const index = cancelledAllOffering.indexOf(item);
							if (index > -1) {
								cancelledAllOffering.splice(index, 1);
							}
							item.status = 'active';
							activeOfferingsList.push(item);
						}
					}
					return {
						cancelledOffering: cancelledAllOffering,
						activeOffering: activeOfferingsList
					}
				}
			}
		}
		return null;
	}
	componentDidUpdate(prevProps){
		if (prevProps.offeringActiveRes) {
			if (prevProps.offeringActiveRes.data && prevProps.offeringActiveRes.data.offeringActive) {
				if (prevProps.offeringActiveRes.data.offeringActive.message === 'Success') {
					if (this.state.isDownload) {
						this.csvDownload();
						this.setState({
							isDownload: false,
							showDownLoadCSVModal: false,
							startDate: '',
							endDate: '',
							startDateErrorMsg: '',
							endDateErrorMsg: '',
						})
					}
				}
			}
		}
		if (prevProps.offeringEffectiveRes) {
			if (prevProps.offeringEffectiveRes.data && prevProps.offeringEffectiveRes.data.offeringEffective) {
				if (prevProps.offeringEffectiveRes.data.offeringEffective.message === 'Success') {
					if (this.state.isDownload) {
						this.csvDownload();
						this.setState({
							isDownload: false,
							showDownLoadCSVModal: false,
							startDate: '',
							endDate: '',
							startDateErrorMsg: '',
							endDateErrorMsg: '',
						})
					}
				}
			}
		}
		if (prevProps.offeringCancelledRes) {
			if (prevProps.offeringCancelledRes.data && prevProps.offeringCancelledRes.data.offeringCancelled) {
				if (prevProps.offeringCancelledRes.data.offeringCancelled.message === 'Success') {
					if (this.state.isDownload) {
						this.csvDownload();
						this.setState({
							isDownload: false,
							showDownLoadCSVModal: false,
							startDate: '',
							endDate: '',
							startDateErrorMsg: '',
							endDateErrorMsg: '',
						})
					}
				}
			}
		}
		if (prevProps.offeringClosedRes) {
			if (prevProps.offeringClosedRes.data && prevProps.offeringClosedRes.data.offeringClosed) {
				if (prevProps.offeringClosedRes.data.offeringClosed.message === 'Success') {
					if (this.state.isDownload) {
						this.csvDownload();
						this.setState({
							isDownload: false,
							showDownLoadCSVModal: false,
							startDate: '',
							endDate: '',
							startDateErrorMsg: '',
							endDateErrorMsg: '',
						})
					}
				}
			}
		}
	}
	goDetail = (data) => {

		this.props.history.push({
			pathname: `/offering-detail/${data.ext_id}`,
			state: {
				multiselect: this.state.multiSelect,
				data: data
			}
		});
	}
	searchOffering = (event) => {
		this.setState({
			searching: event.target.value,
		}, () => {
		});
	}
	doSearchOffering = () => {
		if (this.state.searching !== '') {
			if (this.state.tabIndex === 0) {
				isActiveOffering = true;
				let request = {
					status: 'active',
					pagenum: 1,
					search_key: this.state.filterBy,
					search_keyword: this.state.searching
				}
				this.props.getOfferings(request, true);
				this.setState({
					pagenumActive: 1
				})
			} else if (this.state.tabIndex === 1) {
				isEffectiveOffering = true;
				let request = {
					status: 'effective',
					pagenum: 1,
					search_key: this.state.filterBy,
					search_keyword: this.state.searching
				}
				this.props.getOfferings(request, true);
				this.setState({
					pagenumEffective: 1
				})

			} else if (this.state.tabIndex === 2) {
				isCancelledOffering = true;
				let request = {
					status: 'cancelled',
					pagenum: 1,
					search_key: this.state.filterBy,
					search_keyword: this.state.searching
				}
				this.props.getOfferings(request, true);
				this.setState({
					pagenumCancelled: 1
				})
			} else if (this.state.tabIndex === 3) {
				isClosedOffering = true;
				let request = {
					status: 'closed',
					pagenum: 1,
					search_key: this.state.filterBy,
					search_keyword: this.state.searching
				}
				this.props.getOfferings(request, true);
				this.setState({
					pagenumClosed: 1
				})
			}
		}
	}
	// dropDownChange = (event) => {
	// 	if (event.target.value === 'date') {
	// 		this.setState({
	// 			searchbyCat: event.target.value,
	// 			searching: null,
	// 			fromdate: null,
	// 			todate: null,
	// 		}, () => { });
	// 	} else {
	// 		this.setState({
	// 			searchbyCat: event.target.value,
	// 		}, () => { });
	// 	}

	// }
	// selectOrderBy = (event) => {
	// 	this.setState({
	// 		orderBy: event.target.value,
	// 	}, () => { });
	// }

	handleTabChange = (event, newValue) => {
		this.setState({
			tabIndex: newValue,
		}, () => { });
		if (newValue === 0) {
			if (this.state.activeOffering.length === 0) {
				isActiveOffering = true;
				let request = {
					status: 'active',
					pagenum: 1,
				}
				this.props.getOfferings(request, true);
				this.setState({
					pagenumActive: 1,
					filterBy: 'name'
				})
			}
			this.setState({
				searching: ''
			})
		}
		if (newValue === 1) {
			if (this.state.effectiveOffering.length === 0) {
				isEffectiveOffering = true; let request = {
					status: 'effective',
					pagenum: 1,
				}
				this.props.getOfferings(request, true);
				this.setState({
					pagenumEffective: 1,
					filterBy: 'name'
				})
			}
			this.setState({
				searching: ''
			})
		}
		if (newValue === 2) {
			if (this.state.cancelledOffering.length === 0) {
				isCancelledOffering = true;
				let request = {
					status: 'cancelled',
					pagenum: 1,
					filterBy: 'name'
				}
				this.props.getOfferings(request, true);
				this.setState({
					pagenumCancelled: 1
				})
			}
			this.setState({
				searching: ''
			})
		}
		if (newValue === 3) {
			if (this.state.closedOffering.length === 0) {
				isClosedOffering = true;
				let request = {
					status: 'closed',
					pagenum: 1,
					filterBy: 'name'
				}
				this.props.getOfferings(request, true);
				this.setState({
					pagenumClosed: 1,
				})
			}
			this.setState({
				searching: ''
			})
		}
	}
	fetchOfferingData = () => {
		if (this.state.tabIndex === 0) {
			if ((this.state.lastActiveOffering === this.state.activeOfferingLength) && !isActiveOffering) {
				isActiveOffering = true;
				let request = {
					status: 'active',
					pagenum: this.state.pagenumActive + 1,
				}
				if (this.state.searching !== '') {
					request.search_key = this.state.filterBy;
					request.search_keyword = this.state.searching;
				}
				this.setState({
					pagenumActive: request.pagenum,
					isFetchActive: true
				})
				this.props.getOfferings(request, true);
			}

		} else if (this.state.tabIndex === 1) {
			if ((this.state.lastEffectiveOffering === this.state.effectiveOfferingLength) && !isEffectiveOffering) {
				isEffectiveOffering = true;
				let request = {
					status: 'effective',
					pagenum: this.state.pagenumEffective + 1,
				}
				if (this.state.searching !== '') {
					request.search_key = this.state.filterBy;
					request.search_keyword = this.state.searching;
				}
				this.setState({
					pagenumEffective: request.pagenum,
					isFetchEffective: true
				})
				this.props.getOfferings(request, true);
			}
		} else if (this.state.tabIndex === 2) {
			if ((this.state.lastCancelledOffering === this.state.cancelledOfferingLength) && !isCancelledOffering) {
				isCancelledOffering = true;
				let request = {
					status: 'cancelled',
					pagenum: this.state.pagenumCancelled + 1,
				}
				if (this.state.searching !== '') {
					request.search_key = this.state.filterBy;
					request.search_keyword = this.state.searching;
				}
				this.setState({
					pagenumCancelled: request.pagenum,
					isFetchCancelled: true
				})
				this.props.getOfferings(request, true);
			}
		} else {
			if ((this.state.lastClosedOffering === this.state.closedOfferingLength) && !isClosedOffering) {
				isClosedOffering = true;
				let request = {
					status: 'closed',
					pagenum: this.state.pagenumClosed + 1,
				}
				if (this.state.searching !== '') {
					request.search_key = this.state.filterBy;
					request.search_keyword = this.state.searching;
				}
				this.setState({
					pagenumClosed: request.pagenum,
					isFetchClosed: true
				})
				this.props.getOfferings(request, true);
			}
		}
	}
	doRefreshOffering = () => {
		if (this.state.tabIndex === 0) {
			isActiveOffering = true;
			let request = {
				status: 'active',
				pagenum: 1,
			}
			this.setState({ pagenumActive: 1 });
			this.props.getOfferings(request, true);
		} else if (this.state.tabIndex === 1) {
			isEffectiveOffering = true;
			let request = {
				status: 'effective',
				pagenum: 1
			}
			this.setState({ pagenumEffective: 1 });
			this.props.getOfferings(request, true);

		} else if (this.state.tabIndex === 2) {
			isCancelledOffering = true;
			let request = {
				status: 'cancelled',
				pagenum: 1
			}
			this.setState({ pagenumCancelled: 1 });
			this.props.getOfferings(request, true);
		} else if (this.state.tabIndex === 3) {
			isClosedOffering = true;
			let request = {
				status: 'closed',
				pagenum: 1
			}
			this.setState({ pagenumClosed: 1 });
			this.props.getOfferings(request, true);
		}
	}
	handleBlur = () => {
		if (this.state.searching !== '') {
			this.setState({
				searching: ''
			}, () => {
				this.doRefreshOffering();
			})
		}
	}
	openDownLoadCSVModal = () => {
		this.setState({
			showDownLoadCSVModal: true
		});
	}
	closeDownLoadCSVModal = () => {
		this.setState({
			showDownLoadCSVModal: false,
			startDate: '',
			endDate: '',
			startDateErrorMsg: '',
			endDateErrorMsg: '',
		});
	}
	downLoadCSV = () => {
		if (this.validate(this.state)) {
			if (this.state.startDate === '' && this.state.endDate === '') {
				this.csvDownload();
			} else {
				if (this.state.tabIndex === 0) {
					isActiveOffering = true;
					let request = {
						status: 'active',
						pagenum: 1,
					}
					if (this.state.startDate !== '' && this.state.endDate !== '') {
						request.start_date = this.state.startDate;
						request.end_date = this.state.endDate;
					}
					this.setState({ pagenumActive: 1, isDownload: true, searching: '' });
					this.props.getOfferings(request, true);
				} else if (this.state.tabIndex === 1) {
					isEffectiveOffering = true;
					let request = {
						status: 'effective',
						pagenum: 1
					}
					if (this.state.startDate !== '' && this.state.endDate !== '') {
						request.start_date = this.state.startDate;
						request.end_date = this.state.endDate;
					}
					this.setState({ pagenumEffective: 1, isDownload: true, searching: '' });
					this.props.getOfferings(request, true);
		
				} else if (this.state.tabIndex === 2) {
					isCancelledOffering = true;
					let request = {
						status: 'cancelled',
						pagenum: 1
					}
					if (this.state.startDate !== '' && this.state.endDate !== '') {
						request.start_date = this.state.startDate;
						request.end_date = this.state.endDate;
					}
					this.setState({ pagenumCancelled: 1, isDownload: true, searching: '' });
					this.props.getOfferings(request, true);
				} else if (this.state.tabIndex === 3) {
					isClosedOffering = true;
					let request = {
						status: 'closed',
						pagenum: 1
					}
					if (this.state.startDate !== '' && this.state.endDate !== '') {
						request.start_date = this.state.startDate;
						request.end_date = this.state.endDate;
					}
					this.setState({ pagenumClosed: 1, isDownload: true, searching: '' });
					this.props.getOfferings(request, true);
				}
			}
        }
	}
	handleChangeStartDate = (event) => {
		this.setState({
			startDate: event.target.value
		}, () => {
			if (this.state.startDate !== '') {
				this.setState({
					startDateErrorMsg: ''
				})
			}
		})
	}
	handleChangeEndDate = (event) => {
		this.setState({
			endDate: event.target.value
		}, () => {
			if (this.state.endDate !== '') {
				this.setState({
					endDateErrorMsg: ''
				})
			}
		})
	}
	validate = (state) => {
		let formIsValid = true;
		if (state.startDate !== '' && state.endDate === '') {
			formIsValid = false;
			this.setState({
				endDateErrorMsg: 'Please choose end date'
			})
		}
		if (state.startDate === '' && state.endDate !== '') {
			formIsValid = false;
			this.setState({
				startDateErrorMsg: 'Please choose start date'
			})
		}
		return formIsValid;
	}
	csvDownload = async () => {
		let arrayOfData = [];
		if (this.state.tabIndex === 0) {
			arrayOfData = this.state.activeOffering;
		} else if (this.state.tabIndex === 1) {
			arrayOfData = this.state.effectiveOffering;
		} else if (this.state.tabIndex === 2) {
			arrayOfData = this.state.cancelledOffering;
		} else if (this.state.tabIndex === 3) {
			arrayOfData = this.state.closedOffering;
		}
		let csvContent = "";
		csvContent += 'Name, Offering type name, Ticker symbol, Ext id,  Status, Created Date, Updated Date, Prospectus url, Min price, Max price, Final price, Final shares, Trade date, Settlement date, Allocated, Sixty min mail time, Available to order, Cusip id, Underwriter concession amount, Industry, Active window, Exchange, Price range, Brochure url, DSP, DSP attestation file, DSP faq file, Unit increment, Order dollar share, IOI cutoff datetime, Min_ticket_size, Max_ticket_size, Description, Logo, BD underwriter mpid id, Total orders count, Total Demand, Rating count, Rating average, Time remaining, Blocked bd list,  Underwriters list \r\n';
		arrayOfData.forEach(function (rowArray) {
			const newRow = rowArray.name + ',' + rowArray.offering_type_name + ',' + rowArray.ticker_symbol + ',' + rowArray.ext_id + ',' + rowArray.status + ',' + rowArray.created_at + ',' + rowArray.updated_at + ',' + rowArray.prospectus_url + ',' + rowArray.min_price + ',' + rowArray.max_price + ',' + rowArray.final_price + ',' + rowArray.final_shares + ',' + rowArray.trade_date + ',' + rowArray.settlement_date + ',' + rowArray.allocated + ',' + rowArray.sixty_min_mail_time + ',' + rowArray.available_to_order + ',' + rowArray.cusip_id + ',' + rowArray.underwriter_concession_amount + ',' + rowArray.industry + ',' + rowArray.active_window + ',' + rowArray.exchange + ',' + rowArray.price_range + ',' + rowArray.brochure_url + ',' + rowArray.dsp + ',' + rowArray.dsp_attestation_file + ',' + rowArray.dsp_faq_file + ',' + rowArray.unit_increment + ',' + rowArray.ordr_dollar_share + ',' + rowArray.ioi_cutoff_datetime + ',' + rowArray.min_ticket_size + ',' + rowArray.max_ticket_size + ',' + rowArray.description.replace(/[\s,;\t\n]+/g, '') + ',' + rowArray.logo_small + ',' + rowArray.bd_underwriter_mpid_id + ',' + rowArray.total_orders_count + ',' + rowArray.total_demand + ',' + rowArray.rating_count + ',' + rowArray.rating_average + ',' + rowArray.time_remaining + ',' + Array.prototype.map.call(rowArray.blocked_bd_list, function(item) { return item.mpid; }).join(" ") + ',' + Array.prototype.map.call(rowArray.underwriters_list, function(item) { return item.executing_broker_mpid; }).join(" ");
			csvContent += newRow + '\r\n'; // add carriage return
		});
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
		await saveFile(blob, 'offerings.csv');
		this.setState({
			isDownload: false,
			showDownLoadCSVModal: false,
			startDate: '',
			endDate: '',
			startDateErrorMsg: '',
			endDateErrorMsg: '',
		})
	}
	dropDownSearchBy = (e) => {
		this.setState({
			filterBy: e.target.value
		});
	}
	archiveOffering = (data) => {
		this.setState({
			offeringExtId: data.ext_id
		});
		if (data.status === 'archive') {
			this.props.sendArchiveOffering(data.ext_id);
		} else if (data.status === 'unarchive') {
			this.props.sendUnarchiveOffering(data.ext_id);
		}
	}
	createNewWarrant = (data) => {
		this.setState({
			offeringExtId: data.ext_id,
			warrantList: data.warrant_list
		});
		this.props.sendCreateWarrant(data);
	}
	updateWarrant = (data) => {
		this.setState({
			offeringExtId: data.ext_id,
			warrantList: data.warrant_list
		});
		this.props.sendUpdateWarrant(data);
	}
	makeCurrentCall = (extId) => {
		this.setState({
			offeringExtId: extId
		});
		this.props.makeCurrentCall(extId);
	}
	render() {
		const { tabIndex, activeOffering, effectiveOffering, cancelledOffering, closedOffering, isReadOnly } = this.state;
		const currentOfferingTableViewList = activeOffering;
		const effectiveOfferingTableViewList = effectiveOffering;
		const archiveOfferingTableViewList = cancelledOffering;
		const closedOfferingTableViewList = closedOffering;
		return (
			<Dashboard title="ClickIPO Offerings" pageTitle="Offerings" >
				<div className="offering_content">
					<div>
						<div className="row">
							<div className="col-md-12">
								<div className="offeringGlobalSearch">
									<div className="filterbyContent">Search</div>
									<select className="selectField" value={this.state.filterBy} onChange={this.dropDownSearchBy} style={{ background: 'transparent' }} >
										<option value="name" >Name</option>
										<option value="ticker_symbol" >Ticker symbol</option>
									</select>
									<div style={{ padding: '20px 10px 0px 20px' }}>
										<TextField id="searchInput" type="text" placeholder="Search" onChange={this.searchOffering} value={this.state.searching} style={{width: 250}} 
											InputProps={{
												endAdornment: (
													<InputAdornment position="start">
														{
															this.state.searching !== '' &&
															<Button variant="contained" color="primary" style={{ borderRadius: '0px 4px 4px 0px', minWidth: 30, top: 19, right: 0, left: 8, height: 38, background: 'transparent', color: '#333' }} onClick={() => this.handleBlur()}  ><CloseIcon className="close-icon-for-search" /></Button>
														}
														<Button variant="contained" color="primary" style={{borderRadius: '0px 4px 4px 0px', minWidth: 50, top: 19, right: 0, left: 8, height: 38, marginLeft: 0}} onClick={() => this.doSearchOffering()} disabled={this.state.searching !== '' ? false : true}><SearchIcon /></Button>
													</InputAdornment>
												)
											}}
										/>
									</div>
									{/* {searchbyCat === 'date' && <div className="calenderArea" >
									<Calendar placeholder="Date From" value={this.state.fromdate} showButtonBar onChange={(e) => this.setState({ fromdate: e.value })}></Calendar>
									<Calendar placeholder="Date To" value={this.state.todate} showButtonBar onChange={(e) => this.setState({ todate: e.value })}></Calendar>
									</div>
									} */}
									
									<div style={{ padding: '20px 0px 0px 20px' }}>
										<Button variant="contained" color="primary" onClick={() => this.openDownLoadCSVModal()}>Download CSV</Button>
									</div>
									{
										(!isReadOnly) &&
										<div style={{ padding: '20px 0px 0px 20px' }}>
											<Button component={Link} to="/create-offering" variant="contained" color="primary">New Offering</Button>
										</div>
									}
								</div>
							</div>
						</div>

						<Tabs value={tabIndex} onChange={this.handleTabChange} style={{ padding: '20px 0px 0px 0px' }}>
							<Tab label="CURRENT" />
							<Tab label="EFFECTIVE" />
							<Tab label="CANCELLED" />
							<Tab label="CLOSED" />`
						</Tabs>
						{
							tabIndex === 0 &&
							<Typography component="div" style={{ paddingTop: 24, paddingBottom: 24 }} className="offering-box-conent">
								<div style={{ maxWidth: "100%" }}>
									{currentOfferingTableViewList && <OfferingListComponent offeringTableView={currentOfferingTableViewList} fetchOfferingData={(data) => this.fetchOfferingData(data)} isLoadOffering={isActiveOffering} lastData={this.state.lastActiveOffering} tabIndex={this.state.tabIndex} archiveOffering={(data) => this.archiveOffering(data)} createWarrant={(data) => this.createNewWarrant(data)} updateWarrant={(data) => this.updateWarrant(data)} history={this.props.history} />}
								</div>
							</Typography>
						}
						{
							tabIndex === 1 &&
							<Typography component="div" style={{ paddingTop: 24, paddingBottom: 24 }} className="offering-box-conent">
								<div style={{ maxWidth: "100%" }}>
									{effectiveOfferingTableViewList && <OfferingListComponent offeringTableView={effectiveOfferingTableViewList} fetchOfferingData={(data) => this.fetchOfferingData(data)} isLoadOffering={isEffectiveOffering} lastData={this.state.lastEffectiveOffering} tabIndex={this.state.tabIndex} history={this.props.history} />}
								</div>
							</Typography>
						}
						{
							tabIndex === 2 &&
							<Typography component="div" style={{ paddingTop: 24, paddingBottom: 24 }} className="offering-box-conent">
								<div style={{ maxWidth: "100%" }}>
									{archiveOfferingTableViewList && <OfferingListComponent offeringTableView={archiveOfferingTableViewList} fetchOfferingData={(data) => this.fetchOfferingData(data)} isLoadOffering={isCancelledOffering} lastData={this.state.lastCancelledOffering} tabIndex={this.state.tabIndex} makeCurrent={(data) => this.makeCurrentCall(data)} history={this.props.history} />}
								</div>
							</Typography>
						}
						{
							tabIndex === 3 &&
							<Typography component="div" style={{ paddingTop: 24, paddingBottom: 24 }} className="offering-box-conent">
								<div style={{ maxWidth: "100%" }}>
									{closedOfferingTableViewList && <OfferingListComponent offeringTableView={closedOfferingTableViewList} fetchOfferingData={(data) => this.fetchOfferingData(data)} isLoadOffering={isClosedOffering} lastData={this.state.lastClosedOffering} tabIndex={this.state.tabIndex} history={this.props.history} />}
								</div>
							</Typography>
						}
						<Dialog
							onClose={this.closeDownLoadCSVModal}
							aria-labelledby="customized-dialog-title"
							open={this.state.showDownLoadCSVModal}
							maxWidth={"sm"}
							fullWidth={true}
							scroll={'paper'}
						>
							<DialogTitle onClose={this.closeDownLoadCSVModal} >Download CSV</DialogTitle>
							<DialogContent>
								<div className="row">
									<div className="col-md-6 mb-2">
										<TextField
											id="date"
											label="Start date"
											type="date"
											value={this.state.startDate}
											onChange={this.handleChangeStartDate}
											style={{ width: '100%' }}
											InputLabelProps={{
												shrink: true,
											}}
										/>
										<div style={{color: 'red', marginLeft: 16, height: '10px'}}>{this.state.startDateErrorMsg}</div>
									</div>
									<div className="col-md-6 mb-2">
										<TextField
											id="date"
											label="End date"
											type="date"
											value={this.state.endDate}
											onChange={this.handleChangeEndDate}
											style={{ width: '100%' }}
											InputLabelProps={{
												shrink: true,
											}}
										/>
										<div style={{color: 'red', marginLeft: 16, height: '10px'}}>{this.state.endDateErrorMsg}</div>
									</div>
								</div>
							</DialogContent>
							<DialogActions style={{ display: 'block' }}>
								<div style={{ textAlign: 'center' }}>
									<Button style={{ marginLeft: 10, marginRight: 10 }} variant="contained" size="large" color="primary" onClick={() => this.downLoadCSV()}> Download CSV </Button>
								</div>
							</DialogActions>
						</Dialog>
					</div>
				</div>
			</Dashboard>
		)
	}
}

OfferingComponent.propTypes = {
	offeringActiveRes: PropTypes.any,
	offeringEffectiveRes: PropTypes.any,
	offeringCancelledRes: PropTypes.any,
	offeringClosedRes: PropTypes.any,
	archiveOfferingRes: PropTypes.any,
	unarchiveOfferingRes: PropTypes.any,
	createWarrantRes: PropTypes.any,
	updateWarrantRes: PropTypes.any,
	currentCallRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	offeringActiveRes: doOfferingActiveRes,
	offeringEffectiveRes: doOfferingEffectiveRes,
	offeringCancelledRes: doOfferingCancelledRes,
	offeringClosedRes: doOfferingClosedRes,
	archiveOfferingRes: doArchiveOfferingRes,
	unarchiveOfferingRes: doUnarchiveOfferingRes,
	createWarrantRes: doCreateWarrantRes,
	updateWarrantRes: doUpdateWarrantRes,
	currentCallRes: doCurrentCallRes,
});

function mapDispatchToProps(dispatch) {
	return {
		getOfferings: (data, loading) => dispatch(getOffering(data, loading)),
		sendArchiveOffering: (ext_id) => dispatch(sendArchiveOffering(ext_id)),
		sendUnarchiveOffering: (ext_id) => dispatch(sendUnarchiveOffering(ext_id)),
		sendCreateWarrant: (data) => dispatch(sendCreateWarrant(data)),
		sendUpdateWarrant: (data) => dispatch(sendUpdateWarrant(data)),
		makeCurrentCall: (ext_id) => { dispatch(makeCurrentCall(ext_id))},
	};
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OfferingComponent);
