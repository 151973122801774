import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { createStructuredSelector } from 'reselect';
import Dashboard from '../../../../component/Dashboard';
import { createBrokerdealer, doCreateBrokerdealerRes } from '../../../../actions/createBrokerdealerActions';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import validate from './formValidation';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

class CreateBrokerdealerForm extends React.Component { 
  constructor() {
    super();
    
    this.state = {
      name: '',
      commission_percentage: '',
      mpid: '',
      clearing_account_id: '',
      hidden_marker: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      phone: '',
      zipcode: '',
      namespace: '',
      previewLogo: '',
      file: null,
      isSubmited: false,
    }
  }
  
  static getDerivedStateFromProps(props, state) {
		if (props.createBrokerdealerRes) {
      if (props.createBrokerdealerRes.data && props.createBrokerdealerRes.data.createBrokerdealer) {
        if (props.createBrokerdealerRes.data.createBrokerdealer.message === "Success") {
          props.history.goBack();
        }
      }
    }
		return null
	}
  goBack = () => {
    this.props.history.goBack()
  }
  savebrokerdealer = () => {
    this.setState({
      isSubmited: true,
    }, () => { });
    validate(this.state);
    const errors = validate(this.state);
    console.log(Object.keys(errors))
    if (Object.keys(errors).length === 0) {
      let payload = {
        name: this.state.name,
        commission_percentage: this.state.commission_percentage,
        mpid: this.state.mpid,
        clearing_account_id: this.state.clearing_account_id,
        hidden_marker: this.state.hidden_marker,
        address_attributes: {
          address: this.state.address,
          address2: this.state.address2,
          city: this.state.city,
          state: this.state.state,
          country: this.state.country,
          phone: this.state.phone,
          zipcode: this.state.zipcode,
        },
        namespace: this.state.namespace,
        type: 'brokerdealer'
      }
      if (this.state.file) {
        payload.log = this.state.file
      }
      console.log(payload)
      this.props.handleFormSubmit(payload);
    } 
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  
  handleChangeCheckbox = (e) => {
    this.setState({
      [e.target.name]: e.target.checked
    })
  }
  handleChangeFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      this.setState({
        previewLogo: URL.createObjectURL(e.target.files[0]),
        file: e.target.files,
      })
    }
    
  }
  render() {
    const errors = validate(this.state);
    return (
      <Dashboard title="ClickIPO - Create New Brokerdealer">
        <div className="admin_offering_content">
          <div className="">
            <div  className="Headingname">
              <ArrowBackIosIcon onClick={() => this.goBack()} style={{ cursor: 'pointer' }} /> Create New Brokerdealer
            </div>
            <form autoComplete="off"  style={{ marginTop: 30 }}>
              <div className="row">
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="name" fullWidth label="Name of campaign" onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.name}</span>}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="address" fullWidth label="Address" onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.address}</span>}
                </div>
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="address2" fullWidth label="Address2" onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.address2}</span>}
                </div>
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="city" fullWidth label="City" onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.city}</span>}
                </div>
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="state" fullWidth label="State" onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.state}</span>}
                </div>
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="country" fullWidth label="Country" onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.country}</span>}
                </div>
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="zipcode" fullWidth label="Postal Code" type="number" onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.zipcode}</span>}
                </div>
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="phone" fullWidth label="Phone" type="number" onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.phone}</span>}
                </div>
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="commission_percentage" fullWidth type="number" label="Commission Percentage(%)" onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.commission_percentage}</span>}
                </div>
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="clearing_account_id" fullWidth label="Clearing Account ID" onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.clearing_account_id}</span>}
                </div>
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="mpid" fullWidth label="MPID" onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.mpid}</span>}
                </div>
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 36 }}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="hidden_marker-label">Hidden Marker</InputLabel>
                    <Select
                      labelId="hidden_marker-label"
                      name="hidden_marker"
                      value={this.state.hidden_marker}
                      onChange={this.handleChange}
                      label="Hidden Marker"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="inactive" >Inactive</MenuItem>
                      <MenuItem value="active_bd" >Active Broker Dealer</MenuItem>
                      <MenuItem value="active_partner" >Active Partner</MenuItem>
                      <MenuItem value="active_bd_and_partner" >Active Broker Dealer Partner</MenuItem>
                    </Select>
                  </FormControl>
                  <div>
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.hidden_marker}</span>}
                  </div>
                </div>
                {
                (this.state.hidden_marker === 'active_partner' || this.state.hidden_marker === 'active_bd_and_partner') &&
                  <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                    
                      <TextField name="namespace" fullWidth label="Namespace" onChange={this.handleChange} />
                      {errors && this.state.isSubmited && <span className="errorvalidation">{errors.namespace}</span>}
                  </div>
                }
                <div className="col-md-12 col-sm-12"  style={{ marginTop: 20 }}>
                  {
                    this.state.previewLogo &&
                    <div>
                      <img src={this.state.previewLogo} alt="logo" style={{ width: '100px' }}/>
                    </div>
                  }
                  <div style={{margin: '16px'}}>
                    <input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      style={{display: 'none'}}
                      onChange={(e) => this.handleChangeFile(e)}
                    />
                    <label htmlFor="contained-button-file">
                      <Button variant="contained" color="secondary" component="span" >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
              </div>
            </form>
            <div className="row">
              <div className="col-md-12" style={{ textAlign: 'center' }}>
                <Button variant="contained" color="primary" onClick={this.savebrokerdealer} style={{ marginTop: '30px' }} >Submit</Button>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    );
  }
}

CreateBrokerdealerForm.propTypes = {
  createBrokerdealerRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
  createBrokerdealerRes: doCreateBrokerdealerRes,
});

function mapDispatchToProps(dispatch) {
  return {
    handleFormSubmit: (data) => dispatch(createBrokerdealer(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBrokerdealerForm);
