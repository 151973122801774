import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, change } from 'redux-form';
import { doLoginRes } from '../../../actions/loginActions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import logo from '../../../assets/images/clickipo_logo.png';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import styles from '../login-jss';


const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
    return <NavLink to={props.to} {...props} innerRef={ref} />;
});


const required = value => (value == null ? 'Required' : undefined);
const email = value => (
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}$/i.test(value)
    ? 'Invalid email'
    : undefined
);
export const TextFieldRedux = ({ meta: { touched, error }, input, ...rest }) => {
    return (
      <TextField
        {...rest}
        {...input}
        value={input.value}
        error={touched && Boolean(error)}
      />
    );
};

class LoginForm extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            showPassword: false,
            emailLoader: false,
            isRemember: true,
        }
        this.passwordInput="";
    }
    componentDidMount() {
        
    }
    componentWillUnmount() {
        this.props.reset();
    }
    handleChangeEmail = (e) => {
        this.setState({
            email: e.target.value
        }, () => {
            if (this.state.email === '') {
                this.setState({
                    password: '',
                    showPassword: false,
                })
            }
        })
    }
    handleChangePass = (e) => {
        this.setState({
            password: e.target.value
        })
    }
    checkEmail = () => {
        setTimeout(() => {
            this.setState({
                showPassword: true,
            }, () => {
            });
        }, 1000)
    }
    toggleRememberMe = (e) => {
        this.setState({
            isRemember: e.target.checked
        })
    }
    handleSubmitNew = (e) => {
        if(!(this.state.showPassword)){
            this.checkEmail();
        }
        e.preventDefault();
    }
    handleSubmitNewAgain = (e) => {
        e.preventDefault();
        this.props.handleSubmit(this.props.handleFormSubmit);
    }
    
    handleClickShowPassword = () => {
        const { showPassword } = this.state;
        this.setState({ showPassword: !showPassword });
      };
    
      handleMouseDownPassword = event => {
        event.preventDefault();
      };
    render() {
        const { handleSubmit, handleFormSubmit, classes, pristine, submitting } = this.props
        return (
            <Fragment>
                <Hidden mdUp>
                <NavLink to="/" className={classNames(classes.brand, classes.outer)}>
                    <img src={logo} alt={'logo'} />
                </NavLink>
                </Hidden>
                <Paper className={classNames(classes.paperWrap, classes.petal)}>
                <Hidden smDown>
                    <div className={classes.topBar}>
                    <NavLink to="/" className={classes.brand}>
                        <img src={logo} alt={'logo'} />
                    </NavLink>
                    </div>
                </Hidden>
                <Typography variant="h4" className={classes.title} gutterBottom>
                    Web Admin Login
                </Typography>
                <section className={classes.socmedLogin}></section>
                <section className={classes.formWrap}>
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <div>
                            <FormControl className={classes.formControl}>
                                <Field
                                    name="email"
                                    component={TextFieldRedux}
                                    placeholder="Your Email"
                                    label="Your Email"
                                    required
                                    validate={[required, email]}
                                    className={classes.field}
                                />
                            </FormControl>
                        </div>
                        <div className="mt-3">
                            <FormControl className={classes.formControl}>
                                <Field
                                    name="encrypted_password"
                                    component={TextFieldRedux}
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    label="Your Password"
                                    InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Toggle password visibility"
                                            onClick={this.handleClickShowPassword}
                                            onMouseDown={this.handleMouseDownPassword}
                                        >
                                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                        </InputAdornment>
                                    )
                                    }}
                                    required
                                    validate={required}
                                    className={classes.field}
                                />
                            </FormControl>
                        </div>
                        <div style={{textAlign: 'right', marginTop: 10, marginBottom: 10}}>
                            <Button size="small" component={LinkBtn} to="/forgetpassword" className={classes.buttonLink}>Forgot Password</Button>
                        </div>
                        <div className={classes.btnArea}>
                            <Button variant="contained" color="primary" size="large" type="submit">
                                Continue
                                <ArrowForward className={classNames(classes.rightIcon, classes.iconSmall)} disabled={submitting || pristine} />
                            </Button>
                        </div>
                    </form>
                </section>
                </Paper>
            </Fragment>
        )
    }
}

LoginForm.propTypes = {
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    handleFormSubmit: PropTypes.func,
    doLoginRes: PropTypes.any,
    changeFieldValue: PropTypes.any,
};
const mapStateToProps = createStructuredSelector({
    doLoginRes: doLoginRes
});
  
function mapDispatchToProps(dispatch,ownProps) {
    return {
        changeFieldValue: function (field, value) {
            dispatch(change('ReduxLoginForm', field, value));
          },
    };
}
const ReduxLoginForm = reduxForm({
    form: 'ReduxLoginForm',
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnBlur: false,
})(LoginForm)

const loginCom = connect(mapStateToProps, mapDispatchToProps)(ReduxLoginForm);
export default withStyles(styles)(loginCom);
