
import { BASE_URL } from '../constants';
import Swal from 'sweetalert2';
import { errorHandling, errorHandler } from '../utils/errorHandling';

class forgotPasswordApi {
    static doForgotpassword(data) {
        let sendBody={
            email:data.email
        }
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
            });
            let body = {
                method: 'POST',
                headers: ajaxRequestHeaders,
                body: JSON.stringify(sendBody)
            }
            return fetch(BASE_URL + '/auth/password', body).then(response => {
                if(response.ok === true){
                    Swal.fire({
                        title: 'Message sent successfully',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,
                        timer: 3000,
                        heightAuto: false
                    });
                    window.location.href="/";
                }
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){

        }
        
    }
}

export default forgotPasswordApi;