import { BASE_URL } from '../constants';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { errorHandling, errorHandler } from '../utils/errorHandling';

class callBraggartApi {
    static doCallBraggart(id) {
        const TOKEN = getItem('auth_token');
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'POST',
                headers: ajaxRequestHeaders,
                body: JSON.stringify({
                    ext_id: id,
                    ipo_or_secondary: 2,
                })
            }
            return fetch(BASE_URL + `/offerings/send_to_braggart`, body).then(response => {
                if (response.ok === true) {
                    Swal.fire({
						title: 'Uploaded Braggart Successfully',
						icon: 'success',
						confirmButtonText: 'OK',
						allowOutsideClick: false,
						timer: 3000,
						heightAuto: false
					});
                }
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }
}

export default callBraggartApi;