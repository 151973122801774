export default function validate(values) {
  const errors = {};
  if (!values.selected_bd_underwriter_mpid_id) {
    errors.selected_bd_underwriter_mpid_Err = 'Please select MPID';
  }
  if (!values.selected_offering_id) {
    errors.selectedoffering_id_Err = 'Please select any one offering';
  }
  if (!values.final_price) {
    errors.final_priceErr = 'Please enter final price';
  }

  if (!values.asked_shares) {
    errors.asked_sharesErr = 'Please underwriter requested shares';
  } else {
    if (typeof values['asked_shares'] !== 'undefined') {
      if (!values['asked_shares'].match(/^[0-9]+$/)) {
        errors['asked_sharesErr'] = 'underwriter requested shares is invalid';
      }
    }
  }

  if (!values.allocated_shares) {
    errors.allocated_sharesErr = 'Please enter allocated shares';
  } else {
    if (typeof values['allocated_shares'] !== 'undefined') {
      if (!values['allocated_shares'].match(/^[0-9]+$/)) {
        errors['allocated_sharesErr'] = 'Received shares is invalid';
      }
    }
  }

  if (!values.amountOfShare) {
    errors.amountOfShareErr = 'Please enter share value';
  } else {
    if (typeof values['amountOfShare'] !== 'undefined') {
      if (!values['amountOfShare'].match(/^[0-9]+$/)) {
        errors['amountOfShareErr'] = 'Click ipo Share value is invalid';
      }
    }
  }

  if (Number(values.asked_shares) < Number(values.allocated_shares)) {
    errors.allocated_sharesErr = 'Recieved Shares should not be greater than Asked shares';
  }

  if (Number(values.allocated_shares) < Number(values.amountOfShare)) {
    errors.amountOfShareErr = 'ClickIPO Shares should not be greater than Recieved shares';
  }

  if (!values.UnderwriterValue) {
    errors.UnderwriterErr = 'Please enter underwriter concession amount';
  }
  if (!values.trade_date) {
    errors.trade_dateErr = 'Please enter Trade Date';
  }
  if (!values.settlement_date) {
    errors.settlement_dateErr = 'Please enter Settlement Date';
  }
  if (values.trade_date !== '' && values.settlement_date !== '') {
    if (Date.parse(values.settlement_date) <= Date.parse(values.trade_date)) {
      errors.settlement_dateErr = 'Settlement Date should be greater than Trade Date';
    }
  }
  if (!values.cusipid) {
    errors.cusipidErr = 'Please enter CUSIP ID';
  }
  if (values.isDsp === 1 || values.isDsp === 2) {
    if (!values.dsp_click_shares) {
      errors.dsp_click_sharesErr = 'Please enter dsp shares';
    } else {
      if (typeof values['dsp_click_shares'] !== 'undefined') {
        if (!values['dsp_click_shares'].match(/^[0-9]+$/)) {
          errors['dsp_click_sharesErr'] = 'Dsp shares is invalid';
        }
      }
    }
    if(values.isDsp === 2){
      if (!values.ioi_cutoff_datetime) {
        errors.ioi_cutoff_datetime = 'Please enter ioi cutoff datetime';
      }
    }
  }
  return errors;
}
