export default function validate(values) {
  const errors = {};
  if (!values.final_price) {
    errors.final_priceErr = 'Please enter final price';
  }

  if (!values.trade_date) {
    errors.trade_dateErr = 'Please enter date';
  }

  if (!values.amountOfShares) {
    errors.amoutOfShareErr = 'Please enter click IPO shares';
  }

  if (!values.asked_shares) {
    errors.asked_sharesErr = 'Please enter underwriter requested shares';
  }

  if (!values.underwriter_concession_amount) {
    errors.UnderwriterErr = 'Please enter underwriter concession amount';
  }

  if (!values.cusipid) {
    errors.cusipidErr = 'Please enter CUSIP ID';
  }

  if (!values.received_shares) {
    errors.received_sharesErr = 'Please enter received  shares';
  }

  if (!values.selected_bd_underwriter_mpid_id) {
    errors.Errselected_bd_underwriter_mpid_id = 'Please select MPID';
  }

  return errors;
}
