import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { getItem } from '../../utils/localStore';
import Dashboard from '../../component/Dashboard';
import { doFetchBrokerDealerAllocationRes, fetchBrokerDealerAllocation } from '../../actions/brokerDealerAllocationActions';
import { sendBrokerDealer, doSendBrokerDealerRes } from '../../actions/sendBrokerDealerActions';
import { sendBrokerDealerPrice, doSendBrokerDealerPriceRes } from '../../actions/sendBrokerDealerPriceActions';
import { sendPendingBrokerDealer, doSendPendingBrokerDealerRes } from '../../actions/sendPendingBrokerDealerActions';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DOMPurify from 'dompurify';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import _ from 'lodash';
import { decryptData } from '../../utils/util';
import { saveFile } from '../../helper/helper';
// import TextField from '@material-ui/core/TextField';
let isBrokerdealer = false;
class PricingWireComponent extends React.Component {

    constructor(props) {
        super(props);
        isBrokerdealer = false;
        this.state = {
            isReadOnly: JSON.parse(getItem('isReadOnly')),
            width: 600,
            brokerDealers: [],
            isEnable: true,
            brokerDealerList: [],
            sendToUWList: false,
            brokerListData: [],
            showPricingFiles: false,
            extId: '',
            showPricingWireFileBtn: true,
            showBigModal: false,
            brokerDealerData: '',
            tabIndex: 0,
            checkedAll: false,
        };
        this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this);
        this.sendBrokerDealer = this.sendBrokerDealer.bind(this);
        this.sendBrokerDealerPrice = this.sendBrokerDealerPrice.bind(this);
        this.sendToUWListOpen = this.sendToUWListOpen.bind(this);
        this.sendToUWListClose = this.sendToUWListClose.bind(this);
        this.downLoadCSV = this.downLoadCSV.bind(this);
    }
    componentDidMount() {
        let mkLocalData = localStorage.getItem('allocationDataPricing');
        const originalDataAllocation = decryptData(mkLocalData);
        let mkTabIndex = localStorage.getItem('tabIndexPricing');
        const tabIndexAllocation = decryptData(mkTabIndex);
        if (originalDataAllocation) {
            this.setState({
                brokerDealerData: originalDataAllocation,
                tabIndex: Number(tabIndexAllocation)
            }, () => {
                isBrokerdealer = true;
                this.props.fetchBrokerDealerAllocation(this.state.brokerDealerData.ext_id);
            })
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.brokerDealerAllocationRes) {
            if (props.brokerDealerAllocationRes.data && props.brokerDealerAllocationRes.data.brokerDealerAllocation) {
                if (props.brokerDealerAllocationRes.data.brokerDealerAllocation.message === 'Success' && isBrokerdealer) {
                    isBrokerdealer = false;
                    return {
                        brokerDealerList: props.brokerDealerAllocationRes.data.brokerDealerAllocation.data,
                    };
                }
            }
        }
        if (props.sendBrokerDealerRes && state.showBigModal) {
            if (props.sendBrokerDealerRes.data && props.sendBrokerDealerRes.data.sendBrokerDealer) {
                if (props.sendBrokerDealerRes.data.sendBrokerDealer.message === 'Success') {
                    let bdListData = props.sendBrokerDealerRes.data.sendBrokerDealer.data;
                    let bdDataList = []
                    if (bdListData) {
                        bdDataList = _.chain(bdListData)
                                .groupBy("name")
                                .map((value, key) => ({ bd: key, bdDataList: value }))
                                .value()
                        
                    }
                    return {
                        brokerListData: bdDataList,
                        showPricingFiles: true,
                        showPricingWireFileBtn: true,
                        showBigModal: false,
                    };
                }
            }
        }
        if (props.sendPendingBrokerDealerRes && state.showBigModal) {
            if (props.sendPendingBrokerDealerRes.data && props.sendPendingBrokerDealerRes.data.sendPendingBrokerDealer) {
                if (props.sendPendingBrokerDealerRes.data.sendPendingBrokerDealer.message === 'Success') {
                    let bdListDataPending = props.sendPendingBrokerDealerRes.data.sendPendingBrokerDealer.data;
                    let bdDataListPending = []
                    if (bdListDataPending) {
                        bdDataListPending = _.chain(bdListDataPending)
                                .groupBy("name")
                                .map((value, key) => ({ bd: key, bdDataList: value }))
                                .value()
                        
                    }
                    return {
                        brokerListData: bdDataListPending,
                        showPricingFiles: true,
                        showPricingWireFileBtn: false,
                        showBigModal: false,
                    };
                }
            }
        }
        if (props.sendBrokerDealerPriceRes) {
            if (props.sendBrokerDealerPriceRes.data && props.sendBrokerDealerPriceRes.data.sendBrokerDealerPrice) {
                if (props.sendBrokerDealerPriceRes.data.sendBrokerDealerPrice.message === 'Success') {
                    let bdListDataPrice = props.sendBrokerDealerPriceRes.data.sendBrokerDealerPrice.data;
                    let bdDataListPrice = []
                    if (bdListDataPrice) {
                        bdDataListPrice = _.chain(bdListDataPrice)
                                .groupBy("name")
                                .map((value, key) => ({ bd: key, bdDataList: value }))
                                .value()
                        
                    }
                    return {
                        showPricingFiles: true,
                        showPricingWireFileBtn: false,
                        brokerListData: bdDataListPrice,
                    };
                }
            }
        }
        return null;
    }
    componentWillUnmount() {
        localStorage.removeItem('allocationDataPricing');
        localStorage.removeItem('tabIndexPricing');
    }
    onCloseModal = () => {

        this.setState({
            showPricingFiles: false,
            brokerListData: [],
            brokerDealerList: [],
            brokerDealers: [],
        }, () => {
            if (this.state.showPricingFiles) {
                this.setPricingVariableFalse();
            }
        });

        this.props.doSendBrokerDealerRes(null);
        this.props.doSendPendingBrokerDealerRes(null);
        this.props.doSendBrokerDealerPriceRes(null);
        this.props.doFetchBrokerDealerAllocationRes(null);
        // this.props.history.goBack();
        this.props.history.push({
            pathname: `/allocations`,
            state: {
                tabIndex: this.state.tabIndex,
            }
        });
    }

    setPricingVariableFalse() {
        this.setState({
            showPricingFiles: false,
            brokerDealers: [],
            brokerListData: [],
        }, () => {
            // if (this.state.showPricingFiles) {
            //   this.setPricingVariableFalse();
            // }
        });
    }
    handleCheckboxSelectAll = (e) => {
        let allCheck = this.state.brokerDealerList;
        allCheck.forEach(all => all.checked = e.target.checked);
        this.setState({ brokerDealerList: allCheck, checkedAll: e.target.checked });
        let list = [];
        if (e.target.checked) {
            for (let item of this.state.brokerDealerList) {
                list.push({ 'mpid': item.mpid, 'email': item.email })
            }
        }
        this.setState({
            brokerDealers: list,
        });
    }
    handleCheckboxSelect(e, item, index) {
        if (e.target.checked === true) {
            let bd = this.state.brokerDealerList;
            bd[index].checked = e.target.checked;

            if (bd.every((val, i, arr) => val.checked === true)) {
                this.setState({
                    checkedAll: true,
                })
            }
        } else {
            let bd = this.state.brokerDealerList;
            bd[index].checked = e.target.checked;
            this.setState({
                checkedAll: false,
            })
        }
        let list = this.state.brokerDealers;
        const check = e.target.checked;
        if (check) {
            list.push({ 'mpid': item.mpid, 'email': item.email })
            this.setState({
                brokerDealers: list,
            }, () => { });
        } else {
            const index = list.indexOf(!check);
            list.splice(index, 1);
            this.setState({
                brokerDealers: list,
            }, () => { });
        }
    }

    async downLoadCSV(data) {
        let downloadFileName = this.state.brokerDealerData.name + data.name + data.offering_type_name + '.csv';
        const csvData = data.showCSV;
        let csvContent = '';
        csvData.forEach((rowArray) => {
            const row = rowArray.join(',');
            csvContent += `${row}\r\n`; // add carriage return
        });
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
		await saveFile(blob, downloadFileName.split(" ").join(""));
    }

    sendToUWListOpen() {
        this.setState({
            sendToUWList: true,
        });
        this.props.onClosePrice();
    }

    sendToUWListClose() {
        this.setState({
            sendToUWList: false,
        });
    }

    sendBrokerDealer(id) {
        this.setState({
            extId: id,
            showBigModal: true
        })
        const showPricingWireFile = {
            ext_id: id,
            broker_dealers: this.state.brokerDealers
        }
        if (this.state.tabIndex === 0) {
            this.props.sendPendingBrokerDealer(showPricingWireFile);
        } else {
            // Here we need to call the showPricingWireFile as this is the contition when the offering has been finalized
            this.props.sendBrokerDealer(showPricingWireFile);
            //this.props.sendPendingBrokerDealer(showPricingWireFile);
        }
    }

    sendBrokerDealerPrice() {
        const sendPricingWireFiles = {
            ext_id: this.state.extId,
            broker_dealers: this.state.brokerDealers
        }
        this.props.sendBrokerDealerPrice(sendPricingWireFiles);
    }
    goToBack = () => {
        this.onCloseModal()
    }
    render() {
        const { brokerDealerData, isReadOnly } = this.state;
        return (
            <Dashboard title="ClickIPO Show - Allocation" pageTitle="Pricing Wire" >
                <div className="allocation_content_pricing">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="headingname-show-allocation"><ArrowBackIosIcon onClick={() => this.goToBack()} style={{ cursor: 'pointer' }} /> {brokerDealerData.name} </div>
                        </div>
                        {/* <div className="col-md-8">
                            <div style={{ textAlign: 'right' }}>
                                <TextField type="text" placeholder="Search.." onChange={(e) => {
                                    this.setState({ gloableSearch: e.target.value })
                                }} />
                            </div>
                        </div> */}
                    </div>

                    <div className="col-md-12">
                        {
                            (this.state.showPricingFiles === false) &&
                            <div style={{ minWidth: 500, background: '#fff' }}>

                                <div style={{ margin: '15px 15px 15px 15px' }}>
                                    <div className="sendToTs_content1">
                                        <div className="brokerDealer_checkbox" style={{ marginTop: 5 }}>
                                            {
                                                this.state.brokerDealerList && this.state.brokerDealerList.length === 0 && (!isBrokerdealer) &&
                                                <div> Pricing wire not available</div>
                                            }
                                            <FormGroup>
                                                {this.state.brokerDealerList && this.state.brokerDealerList.length > 0 &&
                                                    <FormControlLabel
                                                        control={<Checkbox onChange={(e) => this.handleCheckboxSelectAll(e)} checked={this.state.checkedAll !== undefined ? this.state.checkedAll : false} defaultValue={this.state.checkedAll} />}
                                                        label={'Select All'}
                                                    />
                                                }
                                                {this.state.brokerDealerList && this.state.brokerDealerList.length > 0 && this.state.brokerDealerList.map((item, i) =>
                                                    <FormControlLabel
                                                        style={{ paddingLeft: 30 }}
                                                        control={<Checkbox checked={item.checked !== undefined ? item.checked : false} onChange={(e) => this.handleCheckboxSelect(e, item, i)} value={item.mpid !== undefined ? item.mpid : ''} />}
                                                        label={item.name}
                                                        key={i}
                                                    />
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    {
                                        this.state.brokerDealerList.length > 0 &&
                                        <div style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
                                            <Button variant="contained" color="primary" onClick={() => this.sendBrokerDealer(brokerDealerData.ext_id)} disabled={!this.state.brokerDealers.length > 0}>Show</Button>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        {
                            (this.state.showPricingFiles === true) &&
                            <div style={{ background: '#fff', paddingTop: '1rem', paddingBottom: '1rem' }}>
                                <div style={{ margin: '15px 0px 10px 0px' }}>
                                    <div className="sendToTs_content2">
                                        <div style={{ marginTop: 5 }}>
                                            <Tabs>
                                                <TabList>
                                                    {this.state.brokerListData && this.state.brokerListData.map((tab, i) => (
                                                        <Tab key={i}>
                                                            {tab.bd}
                                                        </Tab>
                                                    ))}
                                                </TabList>
                                                {this.state.brokerListData && this.state.brokerListData.map((brokerDealer, i) =>
                                                (<TabPanel key={i}>
                                                    
                                                    <div  style={{ padding: 16, border: '1px solid #ddd', marginBottom: 10, height: '100%' }} >
                                                    {brokerDealer.bdDataList && brokerDealer.bdDataList.map((item, j) =>
                                                        <div key={j} style={{ marginBottom: 10, borderBottom: '1px solid #ddd' }}>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="row" style={{ marginBottom: 10 }}>
                                                                        <div className="col-md-6">
                                                                            <p style={{ fontSize: 20, fontWeight: 600 }}>{item.offering_type_name}</p>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <Button variant="contained" color="primary" className="float_right" onClick={() => this.downLoadCSV(item)}>Download CSV</Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6 sendToTs_wirePrice_content_inner" >
                                                                    <table className="tbl tbl-sendToTS" style={{ width: '100%' }}>
                                                                        <tbody>
                                                                            {item.showCSV.map((showCSV, j) => (<tr key={j}>
                                                                                {/* <td>{showCSV}</td> */}
                                                                                {showCSV.map((subitem, k) => (<td key={k}>{subitem}</td>))}
                                                                            </tr>))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div className="col-md-6 sendToTs_wirePrice_content_inner" style={{ borderLeft: '1px solid #888' }} onMouseDown={e => e.stopPropagation()}>
                                                                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.pricing_wire_string) }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    </div>
                                                </TabPanel>)
                                                )}
                                            </Tabs>
                                        </div>
                                    </div>
                                    {this.state.showPricingWireFileBtn === true && !isReadOnly &&
                                        <div>
                                            {
                                                this.state.brokerDealerList.length > 0 &&
                                                <div style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
                                                    <Button variant="contained" color="primary" onClick={() => this.sendBrokerDealerPrice()} disabled={!this.state.brokerDealers.length > 0 || this.state.brokerDealerData.type === 'Warrant'}>Send Pricing Wire Files</Button>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Dashboard>
        );
    }
}

PricingWireComponent.propTypes = {
    brokerDealerData: PropTypes.any,
    openSendTOBrokerDealer: PropTypes.bool,
    brokerDealerAllocationRes: PropTypes.any,
    sendBrokerDealerRes: PropTypes.any,
    sendBrokerDealerPriceRes: PropTypes.any,
    sendPendingBrokerDealerRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    sendBrokerDealerRes: doSendBrokerDealerRes,
    sendBrokerDealerPriceRes: doSendBrokerDealerPriceRes,
    brokerDealerAllocationRes: doFetchBrokerDealerAllocationRes,
    sendPendingBrokerDealerRes: doSendPendingBrokerDealerRes,
});

function mapDispatchToProps(dispatch) {
    return {
        sendBrokerDealer: (data) => dispatch(sendBrokerDealer(data)),
        sendPendingBrokerDealer: (data) => dispatch(sendPendingBrokerDealer(data)),
        sendBrokerDealerPrice: (data) => dispatch(sendBrokerDealerPrice(data)),
        fetchBrokerDealerAllocation: (ext_id) => dispatch(fetchBrokerDealerAllocation(ext_id)),

        doSendBrokerDealerRes: (data) => dispatch(doSendBrokerDealerRes(data)),
        doSendPendingBrokerDealerRes: (data) => dispatch(doSendPendingBrokerDealerRes(data)),
        doSendBrokerDealerPriceRes: (data) => dispatch(doSendBrokerDealerPriceRes(data)),
        doFetchBrokerDealerAllocationRes: (data) => dispatch(doFetchBrokerDealerAllocationRes(data)),

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PricingWireComponent);
