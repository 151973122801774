import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { DialogActions } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
	}))(props => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});
  
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

class NotificationModal extends React.Component {


  constructor() {
    super();
    this.state = {
      isSubmit: null,
      reason: ''
    };
  }
  resetPasswordClick(email) {
    const data = {
      'email': email,
    };
    this.props.resetPasswordClick(data);
  }

  deleteCustomer(cus_email) {
      this.props.deleteCustomer(cus_email, this.props.reasons);
  }
  handleChange = (e) => {
    this.props.deleteHandleChange(e);
  }
  render() {
    const { confirmationType, customerInfo, reasons } = this.props;
    if (!this.props.show) {
      return null;
    }

    return (
        <Dialog
            onClose={this.props.onClose}
            aria-labelledby="customized-dialog-title"
            open={this.props.show}
            maxWidth={"sm"}
            fullWidth={true}
            scroll={'paper'}
        >
            <DialogTitle onClose={this.props.onClose} className="offering-detail-title">
              {confirmationType && confirmationType === 'password' ? 'Reset Password' : 'Delete Customer'}
            </DialogTitle>
            <DialogContent>
                <div className="col-md-12">
                    {confirmationType === 'password' && <h5>
                      Are you sure you want to send password recovery email to&nbsp;
                                <span > {customerInfo.email} ?</span>
                    </h5>}
                    {confirmationType === 'delete' &&
                    <div>
                      <h5 style={{textAlign: 'center'}}>Are you sure you want to delete user <span > {customerInfo.email} ?</span></h5>
                      <div className="mt-4">
                        <h5>Reason</h5>
                        <div>
                        <TextField 
													id="reason" 
													type="text" 
													placeholder="Enter reason" 
													onChange={this.handleChange} 
													value={reasons}
                          multiline
                          rows={3}
                          inputProps={{
                            maxLength: 200,
                          }}
                          style={{width: '100%'}}
												/>
                        </div>
                      </div>
                    </div>
                    }
                </div>
            </DialogContent>
            <DialogActions style={{ display: 'block' }}>
              {confirmationType === 'password' &&
                <div style={{ textAlign: 'center' }}>
                  <Button style={{ marginLeft: 10, marginRight: 10 }} variant="contained" size="large" color="primary" onClick={() => this.resetPasswordClick(customerInfo.email)}> Send </Button>
                    <Button style={{ marginLeft: 10, marginRight: 10 }} variant="contained" size="large" color="primary" onClick={this.props.onClose}>
                    Cancel
                    </Button>
                </div>
              }
              {confirmationType === 'delete' &&
                <div style={{ textAlign: 'center' }}>
                  <Button style={{ marginLeft: 10, marginRight: 10 }} variant="contained" size="large" color="secondary" onClick={() => this.deleteCustomer(customerInfo.email)} disabled={reasons !== '' ? false : true}>Delete</Button>
                  <Button style={{ marginLeft: 10, marginRight: 10 }} variant="contained" size="large" color="primary" onClick={this.props.onClose} >Cancel</Button>
                </div>
              }
            </DialogActions>
        </Dialog>
    );
  }
}

NotificationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  customerInfo: PropTypes.any,
  confirmationType: PropTypes.any,
  deleteCustomer: PropTypes.func,
  resetPasswordClick: PropTypes.func,
  reasons: PropTypes.any,
  deleteHandleChange: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
});
function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationModal);
