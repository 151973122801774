import React from 'react';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import LoginForm from './Form/form';
import { submit_login, doLoginRes } from '../../actions/loginActions';
import { setItem } from '../../utils/localStore';
import LayoutWrapperAuth from '../../component/LayoutWrapperAuth';
import styles from './login-jss';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);
class LoginComponent extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            isLoader: false,
            showModalPasswordResetNinty: false,
            showModalPasswordResetLess: false,
            remainingDay: '',
            isRemember: null,
        }
    }
    componentDidMount() {
        
    }
    static getDerivedStateFromProps(props, state) {
        if (props.loginRes.user.user) {
            if (props.loginRes.user.user && props.loginRes.user.user.data) {
                if (props.loginRes.user.user.message === 'Success') {
                    if (props.loginRes.user.user.data.last_password_changed >= 87 && props.loginRes.user.user.data.last_password_changed <= 89) {
                        const remainingDay = 90 - props.loginRes.user.user.data.last_password_changed;
                        setItem('auth_token', props.loginRes.user.user.data.token);
                        setItem('auth_token_2', props.loginRes.user.user.data.token);
                        if (props.loginRes.user.user.data.level === '3' || props.loginRes.user.user.data.level === 3) {
                            setItem('isReadOnly', true);
                        } else {
                            setItem('isReadOnly', false);
                        }
                        return {
                            remainingDay: remainingDay,
                            showModalPasswordResetLess: true,
                        };
                    } else if (props.loginRes.user.user.data.last_password_changed >= 90) {
                        setItem('auth_token_2', props.loginRes.user.user.data.token);
                        return {
                            showModalPasswordResetNinty: true
                        };
                    } else {
                        if (props.loginRes.user.user.data.level === '3' || props.loginRes.user.user.data.level === 3) {
                            setItem('isReadOnly', true);
                        } else {
                            setItem('isReadOnly', false);
                        }
                        localStorage.setItem('isDrawer', true);
                        setItem('auth_token', props.loginRes.user.user.data.token);
                        // props.history.push('/offerings')
                        // window.location.href="/offerings";
                        window.location.reload();
                    }
                    localStorage.setItem('isDrawer', true);
                }
            }
        }
        return null;
    }

    handleSubmit(data) {
        let sendRequest = data;
        this.props.handleFormSubmit(sendRequest);
    };

    lessChangePasswordCancelModal = () => {
        localStorage.removeItem('auth_token_2');
        this.setState({
            showModalPasswordResetLess: false,
        });
        // window.location.href="/offerings";
        this.props.history.push('/offerings')
    }

    goToChangePassword = () => {
        localStorage.removeItem('auth_token');
        // window.location.href="/changepassword";
        this.props.history.push('/changepassword')
        this.setState({
            showModalPasswordResetNinty: false,
            showModalPasswordResetLess: false,
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <LayoutWrapperAuth>
                    <div className={classes.root}>
                        <Helmet>
                            <title>login</title>
                        </Helmet>
                        <div className={classes.container}>
                            <div className={classes.userFormWrap}>
                                <LoginForm handleFormSubmit={(data) => { this.handleSubmit(data) }} />

                                {
                                    this.state.showModalPasswordResetNinty &&
                                    <Dialog
                                        open={true}
                                        >
                                        <DialogContent>
                                            <div style={{ background: '#fff', paddingTop: '1.5rem', paddingBottom: '1.5rem', fontSize: 16, textAlign: 'center' }}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="text_center">
                                                            <span className="loginchangepasswordModelheader">
                                                            You are required to change your password every three months. You have {this.state.remainingDay} days remaining to change your password. Kindly click the 
                                                            <div className="mt-3 mb-3">
                                                            <Button variant="contained" color="primary" onClick={() => this.goToChangePassword()}>Change Password</Button>
                                                            </div> 
                                                            to change your password now
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </DialogContent>
                                    </Dialog>
                                }
                                {
                                    this.state.showModalPasswordResetLess &&
                                    <Dialog
                                        open={true}
                                        >
                                        <DialogContent>
                                            <div style={{ background: '#fff', paddingTop: '1.5rem', paddingBottom: '1.5rem', fontSize: 16, textAlign: 'center' }}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="text_center">
                                                            <span className="loginchangepasswordModelheader">
                                                                You are required to change your password every three months. You have {this.state.remainingDay} days remaining to change your password. Kindly click the
                                                                <div className="mt-3 mb-3">
                                                                <Button variant="contained" color="primary" onClick={() => this.goToChangePassword()}>Change Password</Button>
                                                                </div> 
                                                                to change your password now
                                                            </span>
                                                        </div>
                                                        <div className="text_center mt-3 mb-3">
                                                            <Button variant="contained" color="primary" onClick={this.lessChangePasswordCancelModal}>Ok</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </DialogContent>
                                    </Dialog>
                                }
                            </div>
                        </div>
                    </div>
            </LayoutWrapperAuth>
        )
    }
}

LoginComponent.propTypes = {
    handleFormSubmit: PropTypes.func,
    loginRes: PropTypes.any
};

const mapStateToProps = createStructuredSelector({
    loginRes: doLoginRes
});

function mapDispatchToProps(dispatch) {
    return {
        handleFormSubmit: (data) => dispatch(submit_login(data)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const loginComp = compose(withConnect)(LoginComponent);

export default withStyles(styles)(loginComp);
