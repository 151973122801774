import * as types from './actionTypes';  
import sendNotificationApi from '../api/sendNotificationApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doSendNotification(request) {

  return {
    type: types.DO_SEND_NOTIFICATION, 
    request
  };
}

export function doSendNotificationRes(data) {  
  return {
    type: types.DO_SEND_NOTIFICATION_RES, 
    data
  };
}

export function sendNotifications(request) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    var files = request.attachments;
    if (files) {
      files = files[0];
    } else {
      files = null;
    }
    let attachmentsFile = [];
    if (files) {
      return function(dispatch) {
        dispatch(loadingRes(true));
        sendNotificationApi.doAttachment(request.attachments).then(fileRes => {
          if (fileRes.message === 'Success') {
            attachmentsFile.push(fileRes.data.url);
            request.attachments = attachmentsFile;
            try {
              sendNotificationApi.doSendNotification(request).then(data => {
                dispatch(doSendNotificationRes(data));
                dispatch(doSendNotificationRes(null));
                dispatch(loadingRes(false));
                if(data.error){
                  Swal.fire({
                    title: data.message,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false,
                    timer: 3000,
                    heightAuto: false
                  });
                  dispatch(loadingRes(false));
                }
              }).catch(error => {
                dispatch(loadingRes(false));
                return error
              });
            } catch (err) {

            }
          }
          if(fileRes.error){
            Swal.fire({
              title: fileRes.message,
              icon: 'error',
              confirmButtonText: 'OK',
              allowOutsideClick: false,
              timer: 3000,
              heightAuto: false
            });
            dispatch(loadingRes(false));
          }
        }).catch(error => {
          dispatch(loadingRes(false));
          return error
        });
      };
    } else {
      return function(dispatch) {
        request.attachments = [];
        dispatch(loadingRes(true));
        sendNotificationApi.doSendNotification(request).then(data => {
          dispatch(doSendNotificationRes(data));
          dispatch(doSendNotificationRes(null));
          dispatch(loadingRes(false));
          if(data.error){
            Swal.fire({
              title: data.message,
              icon: 'error',
              confirmButtonText: 'OK',
              allowOutsideClick: false,
              timer: 3000,
              heightAuto: false
            });
          }
        }).catch(error => {
          dispatch(loadingRes(false));
          return error
        });
      };

    }
  }else{
    logout()
  }
  
}