import * as types from './actionTypes';  
import customerActivityApi from '../api/customerActivityApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doFetchCustomerActivity(email) {

  return {
    type: types.DO_FETCH_CUSTOMER_ACTIVITY, 
    email
  };
}

export function doFetchCustomerActivityRes(data) {  
  return {
    type: types.DO_FETCH_CUSTOMER_ACTIVITY_RES, 
    data
  };
}

export function fetchCustomerActivity(email) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      customerActivityApi.doFetchCustomerActivity(email).then(data => {
        dispatch(doFetchCustomerActivityRes(data));
        dispatch(doFetchCustomerActivityRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
  
}