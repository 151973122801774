export default function validate(values) {
  const errors = {};
    if (!values.name) {
      errors.name = 'Please enter name';
    }
    if (!values.ticker_symbol) {
      errors.ticker_symbol = 'Please enter ticker symbol';
    }
    if (values.trade_date) {
      if (values.date_tbd !== true) {
        let pattern = /([0-9]{4})([-]{1})(([0-9]{1}[0-9]{1})|([1-9]{1}))([-]{1})(([0-9]{1}[0-9]{1})|([1-9]{1})[ ]{1}([0-9]{2}[:]{1}[0-9]{2}[:]{1}[0-9]{2}))|([TBD]{3})/
        if (!pattern.test(values.trade_date)) {
          errors.trade_date = 'Please enter correct date and time ';
        }
      }
    } else {
      if (values.date_tbd === false && values.trade_date === '') {
        errors.trade_date = 'Please enter correct date and time Or select TBD';
      }
    }
    if (values.settlement_date) {
      if (values.date_tbd !== true) {
        let pattern = /([0-9]{4})([-]{1})(([0-9]{1}[0-9]{1})|([1-9]{1}))([-]{1})(([0-9]{1}[0-9]{1})|([1-9]{1})[ ]{1}([0-9]{2}[:]{1}[0-9]{2}[:]{1}[0-9]{2}))|([TBD]{3})/
        if (!pattern.test(values.settlement_date)) {
          errors.settlement_date = 'Please enter correct date and time or TBD';
        }
      }
    } else {
      if (values.date_tbd === false) {
        errors.settlement_date = 'Please enter correct date and time Or select TBD';
      }
    }
    if (!values.description) {
      errors.description = 'Please enter description';
    }
    if (values.ordr_dollar_share === null) {
      errors.ordr_dollar_share = 'Please select order type';
    }
    var tick_pattern = (values.ordr_dollar_share === 1) ? new RegExp('^[0-9]') : new RegExp('^[0-9]+(.[0-9][0-9]?)?')
    if (values.ordr_dollar_share === 0 && values.min_ticket_size) {
      if (!tick_pattern.test(values.min_ticket_size)) {
        errors.min_ticket_size = 'Please enter correct number';
      }
    } else if (values.ordr_dollar_share === 1 && values.min_ticket_size) {
      if (!tick_pattern.test(values.min_ticket_size)) {
        errors.min_ticket_size = 'Please enter without decimal number';
      }
    }
    if (values.ordr_dollar_share === 0 && values.max_ticket_size) {
      if (!tick_pattern.test(values.max_ticket_size)) {
        errors.max_ticket_size = 'Please enter correct number';
      }
    } else if (values.ordr_dollar_share === 1 && values.max_ticket_size) {
      if (!tick_pattern.test(values.max_ticket_size)) {
        errors.max_ticket_size = 'Please enter without decimal number';
      }
    }
    if (!values.industry) {
      errors.industry = 'Please select category';
    }
    if (!values.bd_underwriter_mpid_id) {
      errors.bd_underwriter_mpid_id = 'Please select underwriter MPID';
    }
    if (!values.underwriter_concession_amount) {
      errors.underwriter_concession_amount = 'Please enter underwriter concession amount';
    }

    if (values.brochure_url) {
      let pattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/
      if(!pattern.test(values.brochure_url)){
        errors.brochure_url = 'Please enter valid brochure url';
      }
      if (values.showUrlField === true) {
        if(values.brochure_url.indexOf(' ') >= 0) {
          errors.brochure_url = 'Space is not allowed please retry again';
        }
      }
    }

    if ((values.isMaxPriceChecked === false && values.max_price === '')) {
      errors.max_price = 'Please enter max Price';
    }
    if ((values.isMinPriceChecked === false && values.min_price === '')) {
      errors.min_price = 'Please enter min Price';
    }
    if ((values.isMinPriceChecked === false && (Number(values.min_price) >  Number(values.max_price)))) {
      errors.min_price = 'Please enter min Price less than max price';
    }
    if ((values.isListedsharesChecked === false && values.final_shares === '')) {
      errors.final_shares = 'Please enter listed shares or select TBD';
    }
    if (values.exchange === '') {
      errors.exchange = 'Please select exchange';
    }
    if (values.selectedInvestmentVal && values.selectedInvestmentVal.length === 0) {
      errors.selectedInvestmentVal = 'Investment objective is required';
    }
  return errors;
}
