import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import './stats.scss';
import Dashboard from '../../component/Dashboard';
import OfferingListComponent from './offeringList';
import OfferingStatsComponent from './offeringStats';
import UserStatsComponent from './userStats';
import RevenueListComponent from './revenueList';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { fetchAllOfferings, doFetchAllOfferingsRes } from '../../actions/allOfferingsActions';
import { fetchOfferingStats, doFetchOfferingStatsRes } from '../../actions/offeringStatsActions';
import { getRevenueList, fetchRevenueListRes } from '../../actions/revenueListActions';
import { fetchUsersStats, doFetchUsersStatsRes } from '../../actions/userStatsActions';

class StatsComponent extends React.PureComponent {

	constructor() {
		super();
		this.state = {
			tabIndex: 0,
			allOfferingList: [],
			totalCount: 0,
			activeCount: 0,
			closedCount: 0,
			cancelledCount: 0,
			effectiveCount: 0,
			offeringMetaData: '',
			revenueList: [],
			pagenum: 1,
			totalUsers: 0,
			deletedUsers: 0,
			userPerbdData: [],
		}
	}
	componentDidMount() {
		this.props.getAllOfferings();
	}
	static getDerivedStateFromProps(props, state) {
		if (props.fetchAllOfferingsRes) {
			if (props.fetchAllOfferingsRes.data && props.fetchAllOfferingsRes.data.allOfferings) {
				if (props.fetchAllOfferingsRes.data.allOfferings.message === 'Success') {
					let arrData = props.fetchAllOfferingsRes.data.allOfferings.data;
                    for (let i = 0; i < arrData.length; i++) {

                        Object.keys(arrData[i]).forEach((field) => { /*eslint-env es6*/
                        if (arrData[i][field] === null) {
                            arrData[i][field] = "";
                        }
                        })
                    };
					return {
						allOfferingList: arrData,
					};
				}
			}
		}
		if (props.fetchOfferingStatsRes) {
			if (props.fetchOfferingStatsRes.data && props.fetchOfferingStatsRes.data.offeringStats) {
				if (props.fetchOfferingStatsRes.data.offeringStats.message === "Success") {
					return {
						totalCount: props.fetchOfferingStatsRes.data.offeringStats.data.total,
						activeCount: props.fetchOfferingStatsRes.data.offeringStats.data.active.count,
						closedCount: props.fetchOfferingStatsRes.data.offeringStats.data.closed.count,
						cancelledCount: props.fetchOfferingStatsRes.data.offeringStats.data.cancelled.count,
						effectiveCount: (props.fetchOfferingStatsRes.data.offeringStats.data.effective && props.fetchOfferingStatsRes.data.offeringStats.data.effective.count) ? props.fetchOfferingStatsRes.data.offeringStats.data.effective.count: 0,
						offeringMetaData: props.fetchOfferingStatsRes.data.offeringStats.data
					};
				}
			}
		}
		if (props.fetchRevenueListRes) {
			if (props.fetchRevenueListRes.data && props.fetchRevenueListRes.data.revenueList) {
				if (props.fetchRevenueListRes.data.revenueList.message === 'Success') {
					let arrData = props.fetchRevenueListRes.data.revenueList.data;
                    for (let i = 0; i < arrData.length; i++) {

                        Object.keys(arrData[i]).forEach((field) => { /*eslint-env es6*/
							if (arrData[i][field] === null) {
								arrData[i][field] = "";
							}
                        })
                    };
					return {
						revenueList: arrData,
					};
				}
			}
		}
		if (props.fetchUsersStatsRes) {
			if (props.fetchUsersStatsRes.data && props.fetchUsersStatsRes.data.usersStats) {
				if (props.fetchUsersStatsRes.data.usersStats.message === "Success") {
					return {
						totalUsers: props.fetchUsersStatsRes.data.usersStats.data.total,
						deletedUsers: props.fetchUsersStatsRes.data.usersStats.data.deleted,
						userPerbdData: props.fetchUsersStatsRes.data.usersStats.data.perbd
					};
				}
			}
		}
		return null;
	}
	handleTabChange = (event, newValue) => {
		this.setState({
			tabIndex: newValue,
		}, () => {
			if (this.state.tabIndex === 0) {
				if (this.state.allOfferingList.length === 0) {
					this.props.getAllOfferings();
				}
			}
			if (this.state.tabIndex === 1) {
				if (!this.state.offeringMetaData) {
					this.props.fetchOfferingStats();
				}
			}
			if (this.state.tabIndex === 2) {
				if (this.state.userPerbdData.length === 0) {
					this.props.fetchUsersStats();
				}
			}
			if (this.state.tabIndex === 3) {
				if (this.state.revenueList.length === 0) {
					let request = {
						pagenum: 1
					}
					this.props.getRevenueList(request);
				}
			}
		});
	}
	doPrevious = () => {
		this.setState({
			pagenum: this.state.pagenum + 1,
			revenueList: []
		}, () => {
			let request = {
				pagenum: this.state.pagenum
			}
			this.props.getRevenueList(request);
		})
	}
	doNext = () => {
		this.setState({
			pagenum: this.state.pagenum - 1,
			revenueList: []
		}, () => {
			let request = {
				pagenum: this.state.pagenum
			}
			this.props.getRevenueList(request);
		})
	}
	render() {
		const { tabIndex } = this.state;

		return (
			<Dashboard title="ClickIPO Stats" pageTitle="Stats" >
				<div className="stats_offering_content">
					<div>
						<Tabs value={tabIndex} onChange={this.handleTabChange} style={{ padding: '20px 0px 0px 0px' }}>
							<Tab label="Offering" />
							<Tab label="Offering Stats" />
							<Tab label="User Stats" />
							<Tab label="Revenue" />
						</Tabs>
						{
							tabIndex === 0 &&
							<Typography component="div" style={{ paddingTop: 24, paddingBottom: 24 }} className="offering-box-conent">
								<div style={{ maxWidth: "100%" }}>
									<OfferingListComponent allOfferingList={this.state.allOfferingList}/>
								</div>
							</Typography>
						}
						{
							tabIndex === 1 &&
							<Typography component="div" style={{ paddingTop: 24, paddingBottom: 24 }} className="offering-box-conent">
								<div style={{ maxWidth: "100%" }}>
									<OfferingStatsComponent totalCount={this.state.totalCount} activeCount={this.state.activeCount} closedCount={this.state.closedCount} cancelledCount={this.state.cancelledCount} effectiveCount={this.state.effectiveCount} offeringMetaData={this.state.offeringMetaData} />
								</div>
							</Typography>
						}
						{
							tabIndex === 2 &&
							<Typography component="div" style={{ paddingTop: 24, paddingBottom: 24 }} className="offering-box-conent">
								<div style={{ maxWidth: "100%" }}>
									<UserStatsComponent totalUsers={this.state.totalUsers} deletedUsers={this.state.deletedUsers}  userPerbdData={this.state.userPerbdData}/>
								</div>
							</Typography>
						}
						{
							tabIndex === 3 &&
							<Typography component="div" style={{ paddingTop: 24, paddingBottom: 24 }} className="offering-box-conent">
								<div style={{ maxWidth: "100%" }}>
									<RevenueListComponent pagenum={this.state.pagenum} doPrevious={this.doPrevious} doNext={this.doNext} revenueList={this.state.revenueList || [] } />
								</div>
							</Typography>
						}
					</div>
				</div>
			</Dashboard>
		)
	}
}

StatsComponent.propTypes = {
	fetchAllOfferingsRes: PropTypes.any,
	fetchOfferingStatsRes: PropTypes.any,
	fetchRevenueListRes: PropTypes.any,
	fetchUsersStatsRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	fetchAllOfferingsRes: doFetchAllOfferingsRes,
	fetchOfferingStatsRes: doFetchOfferingStatsRes,
	fetchRevenueListRes: fetchRevenueListRes,
	fetchUsersStatsRes: doFetchUsersStatsRes,
});

function mapDispatchToProps(dispatch) {
	return {
		fetchOfferingStats: () => dispatch(fetchOfferingStats()),
		getAllOfferings: () => dispatch(fetchAllOfferings()),
		getRevenueList: (data) => dispatch(getRevenueList(data)),
		fetchUsersStats: () => dispatch(fetchUsersStats()),
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(StatsComponent);
