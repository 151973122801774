import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';

import { withStyles } from '@material-ui/core/styles';
import logo from '../../../assets/images/clickipo_logo.png';
import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import ArrowForward from '@material-ui/icons/ArrowForward';
import TextField from '@material-ui/core/TextField';
import styles from '../../login/login-jss';

const fields = ['old_encrypted_password', 'encrypted_password'];
// validation functions
const required = value => (value == null ? 'Required' : undefined);

export const TextFieldRedux = ({ meta: { touched, error }, input, ...rest }) => {
    return (
        <div>
            <TextField
                style={{width: '100%'}}
                {...rest}
                {...input}
                value={input.value}
                error={touched && Boolean(error)}
                
            />
            
            <div>{touched && error && <span style={{color: 'red'}}>{error}</span>}</div>
        </div>
    );
};

function validate(values) {
    const errors = {}
    if (!values.old_encrypted_password) {
        errors.old_encrypted_password = 'Required'
    }
    if (!values.encrypted_password) {
        errors.encrypted_password = 'Required'
    }
    if (values.old_encrypted_password) {
        if (values.old_encrypted_password.search(/^\S+$/)) {
            errors.old_encrypted_password = 'Your password must not contain any space.'
        }
        if (values.old_encrypted_password.length < 10) {
            errors.old_encrypted_password = 'Your password must be at least 10 characters'
        }
    }
    if (values.encrypted_password) {
        if (values.encrypted_password.search(/^\S+$/)) {
            errors.encrypted_password = 'Your password must not contain any space.'
        }
        if (values.encrypted_password.length < 10) {
            errors.encrypted_password = 'Your password must be at least 10 characters'
        }
    }
    if (values.encrypted_password === values.old_encrypted_password) {
       errors.encrypted_password = 'The old and new password cannot be the same.'
    }
    return errors
}

class ChangePasswordForm extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            showPassword: false,
            showPasswordNew: false
        }
    }

    componentWillUnmount() {
        this.props.reset();
    }

    handleClickShowPassword = () => {
        const {
            showPassword
        } = this.state;
        this.setState({
            showPassword: !showPassword
        });
    };

    handleMouseDownPassword = event => {
        event.preventDefault();
    };
    handleClickShowPasswordNew = () => {
        const {
            showPasswordNew
        } = this.state;
        this.setState({
            showPasswordNew: !showPasswordNew
        });
    };

    handleMouseDownPasswordNew = event => {
        event.preventDefault();
    };

    goToLogin = () => {
        localStorage.removeItem('auth_token_2');
        window.location.href = '/';
    }

    render() {
        const { handleSubmit, handleFormSubmit, pristine, submitting, classes } = this.props
        return (
            <Fragment>
                <Hidden mdUp>
                    <NavLink to="/" className={classNames(classes.brand, classes.outer)}>
                        <img src={logo} alt={'logo'} />
                    </NavLink>
                </Hidden>
                <Paper className={classNames(classes.paperWrap, classes.petal)}>
                    <Hidden smDown>
                        <div className={classes.topBar}>
                            <NavLink to="/" className={classes.brand}>
                                <img src={logo} alt={'logo'} />
                            </NavLink>
                        </div>
                    </Hidden>
                    <Typography variant="h4" className={classes.title} gutterBottom>
                        Change Password
                    </Typography>
                    {/* <Typography variant="caption" className={classes.subtitle} gutterBottom align="center">
                        Submit your email and we'll send you a link to reset it.
                    </Typography> */}
                    <section className={classes.formWrap}>
                        <form onSubmit={handleSubmit(handleFormSubmit)}>
                            <div>
                                <FormControl className={classes.formControl}>
                                    <Field
                                    name="old_encrypted_password"
                                    component={TextFieldRedux}
                                    placeholder="Old Password"
                                    label="Old Password"
                                    type= {this.state.showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                                onMouseDown={this.handleMouseDownPassword}
                                            >
                                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    required
                                    validate={required}
                                    className={classes.field}
                                    />
                                </FormControl>
                            </div>
                            <div>
                                <FormControl className={classes.formControl}>
                                    <Field
                                    name="encrypted_password"
                                    component={TextFieldRedux}
                                    placeholder="New Password"
                                    label="New Password"
                                    type= {this.state.showPasswordNew ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={this.handleClickShowPasswordNew}
                                                onMouseDown={this.handleMouseDownPasswordNew}
                                            >
                                                {this.state.showPasswordNew ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    required
                                    validate={required}
                                    className={classes.field}
                                    />
                                </FormControl>
                            </div>
                            <div className={classes.btnArea}>
                                <Button variant="contained" color="primary" type="submit">
                                    Submit
                                    <ArrowForward className={classNames(classes.rightIcon, classes.iconSmall)} disabled={submitting || pristine} />
                                </Button>
                            </div>
                        </form>
                    </section>
                </Paper>
            </Fragment>
        )
    }
}

ChangePasswordForm.propTypes = {
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    handleFormSubmit: PropTypes.func,
};
const mapStateToProps = createStructuredSelector({
});
  
function mapDispatchToProps(dispatch) {
    return {
    };
}
const ReduxChangePasswordForm =  reduxForm({
    form: 'ReduxChangePasswordForm',
    fields,
    validate,
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnBlur: false,
})(ChangePasswordForm)

const changePassForm = connect(mapStateToProps, mapDispatchToProps)(ReduxChangePasswordForm);
export default withStyles(styles)(changePassForm);