

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getAdminUnderwriterMpid, adminUnderWriterMpidListRes } from '../../../actions/adminUnderWriterMpidActions';
import { numberWithCommasAndDollar, numberWithCommas } from '../../../helper/helper';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import GridTable from '@nadavshaar/react-grid-table';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
let isBrokerDealerOfferings = false;
class AdminBrokerDealerOfferingList extends React.PureComponent { 

    constructor(props) {
        super(props);
        isBrokerDealerOfferings = false;
        const columns = [
			{ id: 1, field: 'name', label: 'Name', visible: true, sortable: true, cellRenderer: this.nameTemps },
			{ id: 2, field: 'offering_type_name', label: 'Type', visible: true, sortable: true, cellRenderer: this.nameTemps },
			{ id: 3, field: 'created_at', label: 'Created Date', visible: true, sortable: true, cellRenderer: this.nameTemps },
			{ id: 4, field: 'trade_date', label: 'Trade Date', visible: true, sortable: true, cellRenderer: this.nameTemps },
			{ id: 5, field: 'requested_amount', label: 'Requested Amount', visible: true, sortable: true, cellRenderer: this.actionAmount },
			{ id: 6, field: 'requested_shares', label: 'Requested Shares', visible: true, sortable: true, cellRenderer: this.actionRequestedShares },
			{ id: 7, field: 'allocated_amount', label: 'Allocated Amount', visible: true, sortable: true, cellRenderer: this.actionAmount },
			{ id: 8, field: 'final_price', label: 'Final Price', visible: true, sortable: true, cellRenderer: this.actionAmount },
			{ id: 9, field: 'status', label: 'Status', visible: true, sortable: true },
        ];
        this.state = {
            cols: columns,
            userWriterMpid: [],
        };
    }
    componentDidMount() {
        const { mpid, offType } = this.props;
        isBrokerDealerOfferings = true;
        this.props.fetchUnderWriterMpidList(offType, mpid);
    }
    static getDerivedStateFromProps(props, state) {
		if (props.getUnderwriterMPID) {
            if (props.getUnderwriterMPID.data && props.getUnderwriterMPID.data.adminUnderWriterMpid) {
                if (props.getUnderwriterMPID.data.adminUnderWriterMpid.message === 'Success' && isBrokerDealerOfferings) {
                    isBrokerDealerOfferings = false;
                    let arrData = props.getUnderwriterMPID.data.adminUnderWriterMpid.data;
                    for (let i = 0; i < arrData.length; i++) {

                        Object.keys(arrData[i]).forEach((field) => { /*eslint-env es6*/
                        if (arrData[i][field] === null) {
                            arrData[i][field] = "";
                        }
                        })
                    };
                    return {
                        userWriterMpid: arrData,
                    };
                }
            }
        }
		return null
	}
    actionRequestedShares = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
        if (data.requested_shares) {
            return  <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
                {
                    (value !== '' && value !== undefined) &&
                    <Tooltip title={(value !== undefined || value !== null) ? numberWithCommas(value) : ''} interactive={!value} >
                        <div className="text-one-line" style={{width: '90%'}} >{numberWithCommas(value)}</div>
                    </Tooltip>
                }
                {
                    (value !== '' && value !== undefined) &&
                    <Tooltip title={'Copy to clipboard'} interactive >
                        <Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
                    </Tooltip>
                }
            </div>;
        }
    }
    actionAmount = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
        return  <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
            {
                (value !== '' && value !== undefined) &&
                <Tooltip title={(value !== undefined || value !== null) ? numberWithCommasAndDollar(value) : ''} interactive={!value} >
                    <div className="text-one-line" style={{width: '90%'}} >{numberWithCommasAndDollar(value)}</div>
                </Tooltip>
            }
            {
                (value !== '' && value !== undefined) &&
                <Tooltip title={'Copy to clipboard'} interactive >
                    <Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
                </Tooltip>
            }
        </div>;
    }
    nameTemps = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
        return  <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
            {
                (value !== '' && value !== undefined) &&
                <Tooltip title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
                    <div className="text-one-line" style={{width: '90%'}} >{value}</div>
                </Tooltip>
            }
            {
                (value !== '' && value !== undefined) &&
                <Tooltip title={'Copy to clipboard'} interactive >
                    <Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
                </Tooltip>
            }
        </div>;
    }
    goBack = () => {
        this.props.adminUnderWriterMpidListRes(null);
        this.props.closeOffering();
    }
    render() {
        const { brokerDealer } = this.props;
        return (
            <div className="main_outer_wrapper">
                <div className="admin_Main_outer_wrapper_message underwriterTableList">
                    <div className="row" style={{ paddingTop: 15 }}>
                        <div className="col-4">
                            <div className="EditMessage">

                                <h3 style={{ marginLeft: '25px', cursor: 'pointer', color: '#8DC73F' }}>
                                    <ArrowBackIosIcon style={{ cursor: 'pointer' }} onClick={this.goBack} /> {brokerDealer.name}
                                </h3>
                            </div>
                        </div>
                        <div className="col-4">
                            <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>Offerings</h3>
                        </div>
                        <div className="col-4"></div>
                    </div>
                    <div className="col-12 admin-brokerdealer-offerings-table" style={{ paddingTop: 15 }}>
                        <GridTable
                            columns={this.state.cols}
                            rows={this.state.userWriterMpid}
                            isPaginated={true}
                            isVirtualScroll={true}
                            showSearch={true}
                            showRowsInformation={false}
                            isHeaderSticky={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
AdminBrokerDealerOfferingList.propTypes = {
    getUnderwriterMPID: PropTypes.any,
    fetchUnderWriterMpidList: PropTypes.func,
    mpid: PropTypes.any,
    closeOffering: PropTypes.any,
    offType: PropTypes.any,
    brokerDealer: PropTypes.any,
};
const mapStateToProps = createStructuredSelector({
    getUnderwriterMPID: adminUnderWriterMpidListRes,

});
function mapDispatchToProps(dispatch) {
    return {
        fetchUnderWriterMpidList: (MPID, entity) => dispatch(getAdminUnderwriterMpid(MPID, entity)),
        adminUnderWriterMpidListRes: (data) => dispatch(adminUnderWriterMpidListRes(data)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminBrokerDealerOfferingList);
