import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { createStructuredSelector } from 'reselect';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dashboard from '../../../../component/Dashboard';
import { fetchMessageDetail, doMessageDetailRes } from '../../../../actions/messageDetailActions';
import { UpdateMessage, doUpdateMessageRes } from '../../../../actions/messageUpdateActions';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import validate from './formValidation';
class UpdateMessageForm extends React.Component { 
  constructor() {
    super();
    this.state = {
      name: '',
      subject: '',
      body: '',
      template: '',
      short_code: '',
      is_mandatory: false,
      messageDetails: '',
      isSubmited: false,
      tabIndex: 0
    }
  }
  componentDidMount() {
    if (this.props.location.state && this.props.location.state.tabIndex) {
      this.setState({
        tabIndex: this.props.location.state.tabIndex
      });
    }
    this.props.fetchMessageDetail(this.props.match.params.id);
  }
  
  static getDerivedStateFromProps(props, state) {
		if (props.messageDetailRes) {
      if (props.messageDetailRes.data && props.messageDetailRes.data.messageDetail) {
        if (props.messageDetailRes.data.messageDetail.message === "Success") {
          return {
            messageDetails: props.messageDetailRes.data.messageDetail.data,
            name: props.messageDetailRes.data.messageDetail.data.name,
            subject: props.messageDetailRes.data.messageDetail.data.subject,
            body: props.messageDetailRes.data.messageDetail.data.body,
            template: props.messageDetailRes.data.messageDetail.data.template,
            short_code: props.messageDetailRes.data.messageDetail.data.short_code,
            is_mandatory: props.messageDetailRes.data.messageDetail.data.is_mandatory === 0 ? false : true
          };
        }
      }
    }
    if (props.updateMessageRes) {
      if (props.updateMessageRes.data && props.updateMessageRes.data.messageUpdate) {
        if (props.updateMessageRes.data.messageUpdate.message === "Success") {
          props.history.push({
            pathname: `/system-setting`,
            state: {
                tabIndex: state.tabIndex,
            }
          });
        }
      }
    }
		return null
	}
  goBack = () => {
    this.props.history.goBack()
  }
  saveMessage = () => {
    this.setState({
      isSubmited: true,
    }, () => { });
    validate(this.state);
    const errors = validate(this.state);
    
    if (Object.keys(errors).length === 0) {
      let payload = {
        name: this.state.name,
        subject: this.state.subject,
        body: this.state.body,
        template: this.state.template,
        short_code: this.state.short_code,
        is_mandatory: this.state.is_mandatory === false ? 0 : 1
      }
      this.props.handleFormSubmit(payload);
    } 
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  handleChangeCheckbox = (e) => {
    this.setState({
      [e.target.name]: e.target.checked
    })
  }
  render() {
    const errors = validate(this.state);
    return (
      <Dashboard title="ClickIPO - Update message">
        <div className="admin_offering_content">
          <div className="">
            <div  className="Headingname">
              <ArrowBackIosIcon onClick={() => this.goBack()} style={{ cursor: 'pointer' }} /> Edit Message
            </div>
            <form autoComplete="off"  style={{ marginTop: 30 }}>
              <div className="row">
                <div className="col-md-6 col-sm-12"  style={{ marginTop: 20 }}>
                  <TextField name="name" fullWidth label="Name of campaign" value={this.state.name !== undefined ? this.state.name : ''} onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.name}</span>}
                </div>
                <div className="col-md-6 col-sm-12" style={{ marginTop: 20 }}>
                  <TextField name="short_code" fullWidth label="ID" value={this.state.short_code !== undefined ? this.state.short_code : ''} InputProps={{ readOnly: true, }} />
                </div>
                <div className="col-md-12 col-sm-12" style={{ marginTop: 20 }}>
                  <TextField name="subject" fullWidth label="Subject" value={this.state.subject !== undefined ? this.state.subject : ''} onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.subject}</span>}
                </div>
                <div className="col-md-12 col-sm-12" style={{ marginTop: 20 }}>
                  <TextField multiline name="body" fullWidth label="Body" value={this.state.body !== undefined ? this.state.body : ''} onChange={this.handleChange} />
                  {errors && this.state.isSubmited && <span className="errorvalidation">{errors.body}</span>}
                </div>
                <div className="col-md-12 col-sm-12" style={{ marginTop: 20 }}>
                  <FormControlLabel
                    control={<Checkbox checked={this.state.is_mandatory !== undefined ? this.state.is_mandatory : false} onChange={this.handleChangeCheckbox} name="is_mandatory" />}
                    label="Is-mandatory"
                  />
                </div>
              </div>
            </form>
            <div className="row">
              <div className="col-md-12" style={{ textAlign: 'center' }}>
                <Button variant="contained" color="primary" onClick={this.saveMessage} style={{ marginTop: '30px' }} >Save</Button>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    );
  }
}

UpdateMessageForm.propTypes = {
  messageDetailRes: PropTypes.any,
  updateMessageRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
  messageDetailRes: doMessageDetailRes,
  updateMessageRes: doUpdateMessageRes,
});

function mapDispatchToProps(dispatch) {
  return {
    fetchMessageDetail: (id) => dispatch(fetchMessageDetail(id)),
    handleFormSubmit: (data) => dispatch(UpdateMessage(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateMessageForm);
