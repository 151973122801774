import * as types from './actionTypes';  
import allocationListApi from '../api/allocationListApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doFetchAllocationList(request, isLoading) {

  return {
    type: types.DO_FETCH_ALLOCATION_LIST, 
    request, isLoading
  };
}

export function doFetchPendingAllocationListRes(data) {  
  return {
    type: types.DO_FETCH_PENDING_ALLOCATION_LIST_RES, 
    data
  };
}
export function doFetchCompleteAllocationListRes(data) {  
  return {
    type: types.DO_FETCH_COMPLETE_ALLOCATION_LIST_RES, 
    data
  };
}
export function fetchAllocationList(request, isLoading) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      if (isLoading) {
        dispatch(loadingRes(true));
      }
      allocationListApi.doFetchAllocationList(request).then(data => {
        if (data.data) {
          if (request.status === 'pending') {
            dispatch(doFetchPendingAllocationListRes(data));
            dispatch(doFetchPendingAllocationListRes(null));
          }
          if (request.status === 'completed') {
            dispatch(doFetchCompleteAllocationListRes(data));
            dispatch(doFetchCompleteAllocationListRes(null));
          }
        }
        if (isLoading) {
          dispatch(loadingRes(false));
        }
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        if (isLoading) {
          dispatch(loadingRes(false));
        }
        return error
      });
    };
  }else{
    logout()
  }
  
}