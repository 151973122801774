import * as types from './actionTypes';
import resetPasswordApi from '../api/resetPasswordApi';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doResetpassword(request) {
  return {
    type: types.DO_RESETPASSWORD,
    request
  };
}

export function doResetpasswordRes(data) {
  return {
    type: types.DO_RESETPASSWORD_RES,
    data
  };
}

export function reset_password(request) {
    return function(dispatch) {
      dispatch(loadingRes(true));
      resetPasswordApi.doResetpassword(request).then(data => {
        dispatch(doResetpasswordRes(data));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
}
