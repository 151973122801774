import * as types from './actionTypes';  
import allocationEmailApi from '../api/allocationEmailApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doSendAllocationEmail(ext_id) {

  return {
    type: types.DO_SEND_ALLOCATION_EMAIL, 
    ext_id
  };
}

export function doSendAllocationEmailRes(data) {  
  return {
    type: types.DO_SEND_ALLOCATION_EMAIL_RES, 
    data
  };
}
export function sendAllocationEmail(ext_id) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      allocationEmailApi.doSendAllocationEmail(ext_id).then(data => {
        dispatch(doSendAllocationEmailRes(data));
        dispatch(doSendAllocationEmailRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
  
}