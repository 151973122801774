import { BASE_URL } from '../constants';
import { getItem } from '../utils/localStore';
import { errorHandling, errorHandler } from '../utils/errorHandling';
import Swal from 'sweetalert2';

class issuerCommApi {

    static doFetchIssuerComm(data) {
        const TOKEN = getItem('auth_token');
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'GET',
                headers: ajaxRequestHeaders,
            }
            // ?ticker=
            return fetch(BASE_URL + `/get_issuers`, body).then(response => {
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){

        }
    }

    static doCreateIssuerComm(req) {
        const TOKEN = getItem('auth_token');
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'POST',
                headers: ajaxRequestHeaders,
                body: JSON.stringify(req)
            }
            return fetch(BASE_URL + `/create_issuer_admin`, body).then(response => {
                if (response.ok === true) {
                    Swal.fire({
                        title: 'Issuer Admin Created Successfully',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,
                        timer: 3000,
                        heightAuto: false
                    })
                }
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){

        }
    }

    static doUpdateIssuerComm(req) {
        const TOKEN = getItem('auth_token');
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'PUT',
                headers: ajaxRequestHeaders,
                body: JSON.stringify(req)
            }
            return fetch(BASE_URL + `/issuer_admin`, body).then(response => {
                if (response.ok === true) {
                    Swal.fire({
                        title: 'Issuer Admin Updated Successfully',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,
                        timer: 3000,
                        heightAuto: false
                    })
                }
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){

        }
    }
    static doDeleteIssuerComm(req) {
        const TOKEN = getItem('auth_token');
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'DELETE',
                headers: ajaxRequestHeaders,
                body: JSON.stringify(req)
            }
            return fetch(BASE_URL + `/issuer_admin`, body).then(response => {
                if (response.ok === true) {
                    Swal.fire({
                        title: 'Issuer Admin Deleted Successfully',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,
                        timer: 3000,
                        heightAuto: false
                    })
                }
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){

        }
    }
    
}

export default issuerCommApi;
