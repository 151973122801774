import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import '../admin.scss';
import { getItem } from '../../../utils/localStore';
import { fetchConfigList, doFetchConfigRes } from '../../../actions/configListActions';
import { updateConfig, doUpdateConfigRes } from '../../../actions/updateConfigActions';
import GridTable from '@nadavshaar/react-grid-table';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
let isConfigListing = false;
class ConfigComponent extends React.PureComponent {

	constructor(props) {
		super(props);
		isConfigListing = false;
		const columns = [
			{ id: 1, width: '33.33%', field: 'sys_platform', label: 'System Key', visible: true, sortable: true, cellRenderer: this.actionOtherNames },
			{ id: 2, width: '33.33%', field: 'sys_is_update_required', label: 'Update', visible: true, sortable: false, cellRenderer: this.systemUpdate },
			{ id: 3, width: '33.33%', field: 'sys_is_display_required', label: 'Display', visible: true, sortable: false, cellRenderer: this.systemDisplay },
		];
		this.state = {
			cols: columns,
			isReadOnly: JSON.parse(getItem('isReadOnly')),
			configListing: [],
			checkedItems: new Map(),
		}
	}

	componentDidMount() {
		isConfigListing = true;
		this.props.getConfigList();
	}

	static getDerivedStateFromProps(props, state) {
		if (props.getConfigData) {
			if (props.getConfigData.data && props.getConfigData.data.configList) {
				if (props.getConfigData.data.configList.message === 'Success' && isConfigListing) {
					isConfigListing = false;
					let arrData = props.getConfigData.data.configList.data;
                    for (let i = 0; i < arrData.length; i++) {

                        Object.keys(arrData[i]).forEach((field) => { /*eslint-env es6*/
                        if (arrData[i][field] === null) {
                            arrData[i][field] = "";
                        }
                        })
                    };
					return {
						configListing: arrData
					};
				}
			}
		}
		return null
	}
	handleChange = (status, value, data) => {
		if (status === 'update') {
			if (data.sys_is_update_required === true) {
				data.sys_is_update_required = false;
			} else {
				data.sys_is_update_required = true;
			}
		} else {
			if (data.sys_is_display_required === true) {
				data.sys_is_display_required = false;
			} else {
				data.sys_is_display_required = true;
			}
		}
		this.props.doUpdateConfigList(data);
	}

	systemUpdate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return <div className="rgt-cell-inner">
			{
				(!this.state.isReadOnly) ?
				<input type="checkbox" defaultValue={data.sys_is_update_required} defaultChecked={data.sys_is_update_required} onChange={(value) => this.handleChange('update', value, data)} />
				:
				<input type="checkbox" defaultValue={data.sys_is_update_required} defaultChecked={data.sys_is_update_required} onChange={(value) => this.handleChange('update', value, data)} disabled={true} />
			}
		</div>;
	}

	systemDisplay = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return <div className="rgt-cell-inner">
			{
				(!this.state.isReadOnly) ?
				<input type="checkbox" defaultValue={data.sys_is_display_required} defaultChecked={data.sys_is_display_required} onChange={(value) => this.handleChange('display', value, data)} />
				:
				<input type="checkbox" defaultValue={data.sys_is_display_required} defaultChecked={data.sys_is_display_required} onChange={(value) => this.handleChange('display', value, data)} disabled={true} />
			}
		</div>;
	}
	actionOtherNames = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return  <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
			<Tooltip title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
				<div className="text-one-line" style={{width: '90%'}} >{value}</div>
			</Tooltip>
			{
				value !== '' &&
				<Tooltip title={'Copy to clipboard'} interactive >
				<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
				</Tooltip>
			}
		</div>;
	}
	render() {
		const { configListing } = this.state;
		return (
			<div className="stats_offering_content_body">
				<div>
					<div className="row">
						<div className="col-md-12 config-table" style={{ paddingTop: 15, paddingBottom: 15 }}>
							<GridTable
								columns={this.state.cols}
								rows={configListing}
								isPaginated={true}
								isVirtualScroll={true}
								showSearch={true}
								showRowsInformation={false}
								isHeaderSticky={true}
							/>
						</div>

					</div>
				</div>
			</div>
		)
	}
}

ConfigComponent.propTypes = {
	getConfigData: PropTypes.any,
	getUpdateConfigData: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	getConfigData: doFetchConfigRes,
	getUpdateConfigData: doUpdateConfigRes,
});
function mapDispatchToProps(dispatch) {
	return {
		getConfigList: () => dispatch(fetchConfigList()),
		doUpdateConfigList: (data) => dispatch(updateConfig(data)),
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ConfigComponent);
