import * as types from './actionTypes';  
import createWarrantApi from '../api/createWarrantApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doCreateWarrant(request) {

  return {
    type: types.DO_CREATE_WARRANT, 
    request
  };
}

export function doCreateWarrantRes(data) {  
  return {
    type: types.DO_CREATE_WARRANT_RES, 
    data
  };
}
export function sendCreateWarrant(request) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      createWarrantApi.doCreateWarrant(request).then(data => {
        dispatch(doCreateWarrantRes(data));
        dispatch(doCreateWarrantRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        } else if(data.error === 0 && data.message === "Success") {
          Swal.fire({
						title: 'Warrant created successfully',
						icon: 'success',
						confirmButtonText: 'OK',
						allowOutsideClick: false,
						timer: 3000,
						heightAuto: false
					})
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
  
}

export function doUpdateWarrantRes(data) {  
  return {
    type: types.DO_UPDATE_WARRANT_RES, 
    data
  };
}
export function sendUpdateWarrant(request) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      createWarrantApi.doUpdateWarrant(request).then(data => {
        dispatch(doUpdateWarrantRes(data));
        dispatch(doUpdateWarrantRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        } else if(data.error === 0 && data.message === "Success") {
          Swal.fire({
						title: 'Warrant updated successfully',
						icon: 'success',
						confirmButtonText: 'OK',
						allowOutsideClick: false,
						timer: 3000,
						heightAuto: false
					})
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
}