import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Dashboard from '../../component/Dashboard';

import { fetchStatsOfferingOrders, doFetchStatsOfferingOrdersRes } from '../../actions/statsOfferingOrdersActions';
import { getOfferingDetail } from '../../actions/offeringDetailActions';

import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid,
  Label } from 'recharts';
import { convertDateTimeFormat, numberWithCommasAndDollar, numberWithCommas } from '../../helper/helper';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
class StatsOfferingBuildOut extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      offeringOrdersList: [],
      lastOfferingOrders: 0,
      offeringOrdersLength: 0,
      createdOffering: null,
      offeringMaxPrice: null,
    };
    
    this.dateFormate = this.dateFormate.bind(this);
    this.formatXAxis = this.formatXAxis.bind(this);
    this.formatYAxis = this.formatYAxis.bind(this);
    this.renderTooltip = this.renderTooltip.bind(this);
    this.goBack = this.goBack.bind(this);

    
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    if (id !== null) {
      let offeringCreate = this.props.location.state.offeringData;
      if (offeringCreate.created_at) {
        this.setState({
          createdOffering: offeringCreate.created_at,
          offeringMaxPrice: offeringCreate.max_price,
          offeringName: offeringCreate.name,
        });
      }
      let request = {
        ext_id: id
      }
      if (offeringCreate.mpid) {
        request.mpid = offeringCreate.mpid;
      }
      this.props.loadofferingOrders(request);
      this.props.getOfferingDetail(id);
    }
  }
  
  static getDerivedStateFromProps(props, state) {
    if (props.offeringOrdersRes) {
			if (props.offeringOrdersRes.data && props.offeringOrdersRes.data.statsOfferingOrders) {

				if (props.offeringOrdersRes.data.statsOfferingOrders.message === 'Success') {
					return {
						offeringOrdersList: props.offeringOrdersRes.data.statsOfferingOrders.data,
					};
				}
			}
		}
    return null;
  }

  goBack = () => {
    this.props.statsOfferingOrderSuccess(null);
    this.setState({
      offeringOrdersList: [],
    });
    this.props.history.goBack();
  }
  getPaddedComp = (comp) => {
    return ((parseInt(comp, 10) < 10) ? ('0' + comp) : comp)
  } 
  dateFormate = (date, format) => {
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    
    formattedDate = format,
    o = {
        "y+": date.getFullYear(), // year
        "M+": months[date.getMonth()], //month
        "d+": this.getPaddedComp(date.getDate()), //day
        "h+": this.getPaddedComp((date.getHours() > 12) ? date.getHours() % 12 : date.getHours()), //hour
         "H+": this.getPaddedComp(date.getHours()), //hour
        "m+": this.getPaddedComp(date.getMinutes()), //minute
        "s+": this.getPaddedComp(date.getSeconds()), //second
        "S+": this.getPaddedComp(date.getMilliseconds()), //millisecond,
        "b+": (date.getHours() >= 12) ? 'PM' : 'AM'
    };

    for (var k in o) {
        if (new RegExp("(" + k + ")").test(format)) {
            formattedDate = formattedDate.replace(RegExp.$1, o[k]);
        }
    }
    return formattedDate;
  };
  formatXAxis = (tickItem) => {
    const date = new Date(tickItem);
    const newDate = this.dateFormate(date, "d M");
    return newDate
  }

  formatYAxis = (tickItem) => {
    const amountY = numberWithCommasAndDollar(tickItem);
    return amountY
  }


  renderTooltip = (item) => {

    if( item && item.payload && item.payload.length > 0){
      return <div style={{padding: 8}}>
        <p style={{fontWeight: 'bold', fontSize: 15}}>{item.payload[0].payload.Date}</p>
        <p style={{fontWeight: 'bold', fontSize: 15, color: '#8DC73F'}}>{numberWithCommasAndDollar(item.payload[0].payload.Amount)}</p>
        <p style={{fontWeight: 'bold', fontSize: 15, color: '#0A5074'}}># {item.payload[0].payload.countInvestor}</p>
      </div>
    }

  }
  render() {
    const { offeringOrdersList, createdOffering, offeringMaxPrice } = this.state;
    let chartData = [];
    let statisticData = [];
    let largestOrder=0;
    let smallestOrder=0;
    let medianOrder=0;
    let hundreds=0;
    let twoPointFiveThousands=0;

    let totalRequestedAmount=0;
    let totalRequestedShare=0;
    if(offeringOrdersList.length > 0){
      let neworderData = offeringOrdersList;
      for(let items of neworderData){
        if(items.requested_amount / offeringMaxPrice >= 100){
          hundreds+=1
        }
        if(items.requested_amount >= 2500.00 ) {
          twoPointFiveThousands+=1
        }
        statisticData.push(items.requested_amount)
        totalRequestedAmount=Number(totalRequestedAmount) + Number(items.requested_amount);
      }

      totalRequestedShare = Math.floor(totalRequestedAmount / offeringMaxPrice);

      largestOrder = Math.max(...statisticData)
      smallestOrder= Math.min(...statisticData)
      const mid = Math.floor(statisticData.length / 2)
      let nums = [...statisticData].sort((a, b) => a - b);
      medianOrder = statisticData.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;

      if(convertDateTimeFormat(neworderData[0].created_at) !== convertDateTimeFormat(createdOffering)){
        neworderData.push({created_at: createdOffering, requested_amount: 0, investCount: 0}, {created_at: createdOffering, requested_amount: 0,investCount: 0});
      }
      let sortedData = neworderData.sort((a, b) => new Date(...a.created_at.split('/').reverse()) - new Date(...b.created_at.split('/').reverse()));
      let requestedAmount = 0;
      let countInvestor = 0;
      
      for(let items of sortedData){
        // if(items.status === 'active'){
          requestedAmount = requestedAmount + items.requested_amount;

          let graphTime = convertDateTimeFormat(items.created_at);
          let checkCount = 0;
          let chartDataIndex = 0;

          for(let chartitem of chartData){
            if(chartitem.Date === graphTime){
              requestedAmount = chartitem.Amount + items.requested_amount;
              countInvestor = chartitem.countInvestor + 1;
              checkCount = 1;
              chartData[chartDataIndex].Amount = requestedAmount;
              chartData[chartDataIndex].countInvestor = countInvestor;
              break;
            }
            chartDataIndex++;
          }
          if(checkCount===0){
            let investorCount = 0;
            if(chartData.length>0){
              investorCount = chartData[chartData.length-1].countInvestor + 1;
            }
            chartData.push({Date:graphTime, Amount: requestedAmount, countInvestor:investorCount});
          }
        // }
        
      }
    }
    return (
      <Dashboard title="ClickIPO Stats - Buildout" pageTitle="Buildout">
        <div className="stats_offering_content">
          <div className="row offeringSubheader">
            <div className="col-sm-5">
              <div className="offering_Detail_header">
                <div className="Headingname">
                <ArrowBackIosIcon style={{cursor: 'pointer'}} onClick={() => this.goBack()} />{this.state.offeringName} Orders 
                </div>
              </div>
            </div>
            <div className="col-sm-7">
              
            </div>
          </div>
          <div className="offeringOrderListScroll">
            <div>
              <div className="col-md-12">
                <div style={{ margin: 20 }}>
                  <div className="offering-detail-amountbox">
                    <div className="row" style={{ marginTop: 10 }}>
                      <div className="col-md-8">
                        <div style={{fontSize: 16, color: '#678899'}}>Total Requested Amount : </div>
                      </div>
                      <div className="col-md-4">
                        <div style={{fontSize: 16, color: '#678899'}}>{numberWithCommasAndDollar(totalRequestedAmount)}</div>
                      </div>
                    </div>
                    {(offeringMaxPrice) && <div className="row" style={{ marginTop: 10 }}>
                      <div className="col-md-8">
                        <div style={{fontSize: 16, color: '#678899'}}>Total Requested Shares : </div>
                      </div>
                      <div className="col-md-4">
                        <div style={{fontSize: 16, color: '#678899'}}>{numberWithCommas(totalRequestedShare)}</div>
                      </div>
                    </div>}
                  </div>
                  <div style={{width: '100%', height: '350px', margin: 20}}>
                    <div style={{width: '100%', height: '100%'}}>
                      <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                          data={chartData}
                          margin={{ top: 40, right: 40, bottom: 20, left: 55 }}
                        >
                          <CartesianGrid vertical={true} stroke="#eee" strokeDasharray="5 5"/>

                          <XAxis dataKey="Date" tickFormatter={this.formatXAxis} angle={-60} height={90}>
                            <Label value="Order Date" angle={0} position='bottom' style={{ textAnchor: 'middle',fontSize: 18, color:'#0A5074' }}>
                            </Label>
                          </XAxis>

                          <YAxis tickFormatter={this.formatYAxis} angle={-60} height={90}>
                            <Label value="Aggregate Requested Amount" angle={270} position='left' offset={40} style={{ textAnchor: 'middle',fontSize: 18, color:'#0A5074' }}></Label>
                          </YAxis>

                          <Tooltip
                            content={this.renderTooltip}
                            wrapperStyle={{
                              borderColor: 'white',
                              boxShadow: '2px 2px 3px 0px rgb(204, 204, 204)',
                            }}
                            contentStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
                            labelStyle={{ fontWeight: 'bold', color: '#666666' }}
                          />

                          <Line type="monotone" dataKey="Amount" activeDot={{r: 4}} stroke="#8DC73F" dot={true} strokeWidth={2} />

                        </LineChart>
                      </ResponsiveContainer>
                      <div className="noOfInvestor">Number of Investors</div>
                    </div>
                  </div>
                  <div className="offering-detail-amountbox">
                    <div className="row" style={{ marginTop: 10 }}>
                      <div className="col-md-8">
                        <div style={{fontSize: 16, color: '#678899'}}>Largest order amount : </div>
                      </div>
                      <div className="col-md-4">
                        <div style={{fontSize: 16, color: '#678899'}}>{numberWithCommasAndDollar(largestOrder)}</div>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: 10 }}>
                      <div className="col-md-8">
                        <div style={{fontSize: 16, color: '#678899'}}>Smallest  order amount : </div>
                      </div>
                      <div className="col-md-4">
                        <div style={{fontSize: 16, color: '#678899'}}>{numberWithCommasAndDollar(smallestOrder)}</div>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: 10 }}>
                      <div className="col-md-8">
                        <div style={{fontSize: 16, color: '#678899'}}>Median of the order amounts : </div>
                      </div>
                      <div className="col-md-4">
                        <div style={{fontSize: 16, color: '#678899'}}>{numberWithCommasAndDollar(medianOrder)}</div>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: 10 }}>
                      <div className="col-md-8">
                        <div style={{fontSize: 16, color: '#678899'}}>Number of round lots (orders that are 100 shares or more) :</div>
                      </div>
                      <div className="col-md-4">
                        <div style={{fontSize: 16, color: '#678899'}}>{numberWithCommas(hundreds)}</div>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: 10 }}>
                      <div className="col-md-8">
                        <div style={{fontSize: 16, color: '#678899'}}>Orders that are $2500 or more :</div>
                      </div>
                      <div className="col-md-4">
                        <div style={{fontSize: 16, color: '#678899'}}>{numberWithCommas(twoPointFiveThousands)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    );
  }
}

StatsOfferingBuildOut.propTypes = {
	offeringOrdersRes: PropTypes.any,
  offeringOrders: PropTypes.any,
  offering: PropTypes.any,
  fetchOfferings: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	offeringOrdersRes: doFetchStatsOfferingOrdersRes,
});

function mapDispatchToProps(dispatch) {
  return {
		loadofferingOrders: (request) => dispatch(fetchStatsOfferingOrders(request)),
		getOfferingDetail: (id) => dispatch(getOfferingDetail(id)),
		statsOfferingOrderSuccess: (data) => dispatch(doFetchStatsOfferingOrdersRes(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StatsOfferingBuildOut);
