import * as types from './actionTypes';  
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
// import Swal from 'sweetalert2';


export function offeringDetailRes(data) {  
  return {
    type: types.OFFERING_DETAIL_RES, 
    data
  };
}



export function getOfferingDetail(data) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      dispatch(offeringDetailRes(data));
    };
  }else{
    logout()
  }
}

