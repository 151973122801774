import * as types from '../actions/actionTypes'; 
import initialState from './initialState';

//all the whitelisted IPs related reducers here
export default function (state = initialState.user, action) { //we will change the state = {} soon
  switch(action.type) {
    case types.FETCH_WHITELISTED_IP_RES:
      return action.data
    case types.CREATE_WHITELISTED_IP_RES:
      return action.data
    case types.UPDATE_WHITELISTED_IP_RES:
      return action.data
    case types.DELETE_WHITELISTED_IP_RES:
      return action.data
    default:
      return state
  }
}