import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import './issuerComm.scss';
import { fetchIssuerCommList, doFetchIssuerCommListRes, deleteIssuerComm, doDeleteIssuerCommRes } from '../../actions/issuerCommActions';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import GridTable from '@nadavshaar/react-grid-table';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Dashboard from '../../component/Dashboard';
import { getItem } from '../../utils/localStore';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { DialogActions } from '@material-ui/core';
const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
	}))(props => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});
  
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);
let isIssuersList = false;
let isDeleteIssuer = false;
class IssuerComm extends React.PureComponent {
    constructor(props) {
        super(props);
        isIssuersList = false;
        isDeleteIssuer = false;
        var columns = [
            { id: 1, field: 'first_name', label: 'First Name', visible: true, sortable: true, cellRenderer: this.actionOtherNames },
            { id: 2, field: 'last_name', label: 'Last Name', visible: true, sortable: true, cellRenderer: this.actionOtherNames },
            { id: 3, field: 'email', label: 'Email', visible: true, sortable: true, cellRenderer: this.actionOtherNames },
            { id: 4, field: 'ticker_symbol', label: 'Ticker Symbol', visible: true, sortable: true, cellRenderer: this.actionOtherNames },
            { id: 5, field: 'name', label: 'Company Name', visible: true, sortable: true, cellRenderer: this.actionOtherNames },
            { id: 6, field: 'description', label: 'Description', visible: true, sortable: true, cellRenderer: this.actionOtherNames },
            // { id: 6, field: 'action', label: 'Action', visible: true, sortable: false, cellRenderer: this.actionAddBtn },
        ];
        this.state = {
            isReadOnly: JSON.parse(getItem('isReadOnly')),
            cols: columns,
            issuersList: [],
            brokerDealerData: null,
            openDeleteModal: false,
            issuerInfo: "",
        }
    }
    componentDidMount() {
        isIssuersList = true;
        this.props.getIssuerList();
    }
    static getDerivedStateFromProps(props, state) {
        if (props.fetchIssuerCommListRes) {
            if (props.fetchIssuerCommListRes.data && props.fetchIssuerCommListRes.data.issuerList) {
                if (props.fetchIssuerCommListRes.data.issuerList.message === 'Success' && isIssuersList) {
                    isIssuersList = false;
                    if (props.fetchIssuerCommListRes.data.issuerList.data.length > 0) {
                        let issuerData = props.fetchIssuerCommListRes.data.issuerList.data;
                        for (let i = 0; i < issuerData.length; i++) {

                            Object.keys(issuerData[i]).forEach((field) => { /*eslint-env es6*/
                                if (issuerData[i][field] === null) {
                                    issuerData[i][field] = "";
                                }
                            })
                        };
                        return {
                            issuersList: issuerData,
                        };
                    }

                }
            }
        }
        if (props.deleteIssuerCommRes) {
            if (props.deleteIssuerCommRes.data && props.deleteIssuerCommRes.data.deleteIssuer) {
                if (props.deleteIssuerCommRes.data.deleteIssuer.message === 'Success' && isDeleteIssuer) {
                    isDeleteIssuer = false;
                    props.getIssuerList();
                }
            }
        }
        return null
    }
    goToEdit = (data) => {
        this.props.history.push({
			pathname: `/edit-issuer`,
			state: data
		})
    }
    openConfirmModal = (data) => {
        this.setState({
            openDeleteModal: true,
            issuerInfo: data,
        });
    }
    closeDeleteModal = () => {
        this.setState({
            openDeleteModal: false,
        });
    }
    actionAddBtn = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
        return (<div className="rgt-cell-inner">
            {
                (!this.state.isReadOnly) &&
                <Tooltip title="Edit">
                    <Button style={{ width: 'auto', margin: 3 }} variant="outlined" size="medium" color="primary" onClick={() => this.goToEdit(data)}><EditIcon /></Button>
                </Tooltip>
            }
            {
                (!this.state.isReadOnly) &&
                <Tooltip title="Delete">
                    <Button style={{ width: 'auto', margin: 3 }} variant="outlined" size="medium" color="secondary" onClick={() => this.openConfirmModal(data)}><DeleteIcon /></Button>
                </Tooltip>
            }
        </div>);
    }
    actionOtherNames = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
        return <div className='rgt-cell-inner rgt-text-truncate' style={{ overflow: 'hidden', display: 'flex', alignItems: 'center' }}>
            {
                (value !== '' && value !== undefined) &&
                <Tooltip title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
                    <div className="text-one-line" style={{ width: '90%' }} >{value}</div>
                </Tooltip>
            }
            {
                (value !== '' && value !== undefined) &&
                <Tooltip title={'Copy to clipboard'} interactive >
                    <Button style={{ padding: 4, minWidth: 10, color: "#7e817c" }} onClick={() => navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{ fontSize: 14 }} /> </Button>
                </Tooltip>
            }
        </div>;
    }
    deleteIssuer = () => {
        let request = {
			'issuerId': this.state.issuerInfo.id
		}
		isDeleteIssuer = true;
		this.props.deleteIssuerRecord(request);
    }
    render() {
        const { issuersList } = this.state;
        return (
            <Dashboard title="ClickIPO Admin - Issuers" pageTitle="Issuer Comm">
                <div className="issuers_section_content">
                    <div>
                        {
                            (!this.state.isReadOnly) &&
                            <div style={{ textAlign: "right" }}>
                                <Button component={Link} to={'/create-issuer'} variant="contained" color="primary" style={{ marginTop: '15px' }} >New Issuer</Button>
                            </div>
                        }
                        <div className="row">
                            <div className="col-md-12 brokerdealer-system-setting-table" style={{ paddingTop: 15, paddingBottom: 15 }}>
                                <GridTable
                                    columns={this.state.cols}
                                    rows={issuersList}
                                    isPaginated={true}
                                    isVirtualScroll={true}
                                    showSearch={true}
                                    showRowsInformation={false}
                                    isHeaderSticky={true}
                                />
                            </div>
                            <Dialog
                                onClose={this.closeDeleteModal}
                                aria-labelledby="customized-dialog-title"
                                open={this.state.openDeleteModal}
                                maxWidth={"sm"}
                                fullWidth={true}
                                scroll={'paper'}
                                >
                                <DialogTitle onClose={this.closeDeleteModal} className="offering-detail-title">Delete Issuer</DialogTitle>
                                <DialogContent>
                                    <div className="col-md-12 editModelText" style={{textAlign: 'center'}}>
                                        <h5>Are you sure you want to delete this record ?</h5>
                                    </div>
                                </DialogContent>
                                <DialogActions style={{display: 'block'}}>
                                    <div style={{textAlign: 'center'}}>
                                        <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.deleteIssuer()}>
                                            Confirm
                                        </Button>
                                        <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="secondary" onClick={() => this.closeDeleteModal()}>
                                            Cancel
                                        </Button>
                                    </div>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </div>
                </div>
            </Dashboard>
        )
    }
}
IssuerComm.propTypes = {
    fetchIssuerCommListRes: PropTypes.any,
    deleteIssuerCommRes: PropTypes.any,
};
const mapStateToProps = createStructuredSelector({
    fetchIssuerCommListRes: doFetchIssuerCommListRes,
    deleteIssuerCommRes: doDeleteIssuerCommRes,
});
function mapDispatchToProps(dispatch) {
    return {
        getIssuerList: () => dispatch(fetchIssuerCommList()),
        deleteIssuerRecord: (req) => dispatch(deleteIssuerComm(req)),
    };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(IssuerComm);
