import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './customers.scss';
import { getItem } from '../../utils/localStore';
import Dashboard from '../../component/Dashboard';
// import { loadKey, saveKey } from '../../utils/localStore';
import  MultiSelectReact  from 'multi-select-react';
import { fetchAllCustomers, doFetchCustomerListRes, fetchConnectedCustomers, doFetchConnectedCustomerListRes, fetchNonConnectedCustomers, doFetchNonConnectedCustomerListRes, fetchDeletedCustomers, doFetchDeletedCustomerListRes } from '../../actions/customerListActions';
import { fetchCustomerActivity, doFetchCustomerActivityRes } from '../../actions/customerActivityActions';
import { fetchCustomerOrderInfo, doFetchCustomerOrderInfoRes } from '../../actions/customerOrderInfoActions';
import { sendCustomerResetPassword, doCustomerResetPassRes } from '../../actions/customerResetPassActions';
import { submitDeleteCustomer, doDeleteCustomerRes } from '../../actions/deleteCustomerActions';
import { sendUpdateCustomer, doUpdateCustomerRes } from '../../actions/updateCustomerActions';
import { dateTimeFormatConvert, numberWithCommas, currency, saveFile } from '../../helper/helper';
import NotificationModal from './notificationModal';
import ConfirmationModal from './confirmationModal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { DialogActions } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import SortIcon from '@material-ui/icons/Sort';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import GridTable from '@nadavshaar/react-grid-table';
import FileCopyIcon from '@material-ui/icons/FileCopy';
const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
	}))(props => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});
  
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);
let isActiveCustomer = false;
let isConnectedCustomer = false;
let isNonConnectedCustomer = false;
let isDeletedCustomer = false;
class CustomersComponent extends React.PureComponent {

	constructor(props) {
		super(props);
		isActiveCustomer = false;
		isConnectedCustomer = false;
		isNonConnectedCustomer = false;
		const activityColumns = [
			{ id: 1, field: 'email_type', label: 'Email Type', visible: true, sortable: true, cellRenderer: this.offeringsTemp },
			{ id: 2, field: 'created_at', label: 'Sent at', visible: true, sortable: true, cellRenderer: this.offeringsTemp },
			{ id: 3, field: 'delivered_at', label: 'Delivered at', visible: true, sortable: true, cellRenderer: this.offeringsTemp },
			{ id: 4, field: 'opened_at', label: 'Opened at', visible: true, sortable: true, cellRenderer: this.offeringsTemp },
			{ id: 5, field: 'bounced_at', label: 'Bounced at', visible: true, sortable: true, cellRenderer: this.offeringsTemp },
			{ id: 6, field: 'bounce_message', label: 'Bounce Message', visible: true, sortable: true, cellRenderer: this.offeringsTemp },
		];
		const orderInfoColumns = [
			{ id: 1, field: 'name', label: 'Offering Name', visible: true, sortable: true, cellRenderer: this.offeringsTemp },
			{ id: 2, field: 'offering_type_name', label: 'Offering Type', visible: true, sortable: true, cellRenderer: this.offeringsTemp },
			{ id: 3, field: 'trade_date', label: 'Anticipated Date', visible: true, sortable: true, cellRenderer: this.offeringsTemp },
			{ id: 4, field: 'requested_amount', label: 'Requested Amount', visible: true, sortable: true, cellRenderer: this.amountCurrencyTemp },
			{ id: 5, field: 'allocated_amount', label: 'Allocated Amount', visible: true, sortable: true, cellRenderer: this.amountCurrencyTemp },
			{ id: 6, field: 'requested_shares', label: 'Requested Shares', visible: true, sortable: true, cellRenderer: this.sharesTemplate },
			{ id: 6, field: 'allocated_shares', label: 'Allocated Shares', visible: true, sortable: true, cellRenderer: this.sharesTemplate },
			{ id: 6, field: 'status', label: 'Status', visible: true, sortable: true, cellRenderer: this.offeringsTemp },
		];
		const columns = [
			{
				id: 'checkbox',
				pinned: true,
				className: '',
				width: '54px',
				minResizeWidth: 0,
				maxResizeWidth: null,
				resizable: false,
				visible: true, sortable: false,
				cellRenderer: this.singleCheckBoxTable,
				headerCellRenderer: this.allCheckBoxTable,
			},
			{ id: 1, field: 'email', label: 'Email Address', width: '250px', visible: true, sortable: false, cellRenderer: this.emailTemp, headerCellRenderer: this.allCustomerCustomHeader },
			{ id: 2, field: 'first_name', label: 'Customer First Name', width: '280px', visible: true, sortable: false, cellRenderer: this.offeringsTemp, headerCellRenderer: this.allCustomerCustomHeader },
			{ id: 3, field: 'last_name', label: 'Customer Last Name', width: '280px', visible: true, sortable: false, cellRenderer: this.offeringsTemp, headerCellRenderer: this.allCustomerCustomHeader },
			{ id: 4, field: 'broker_dealer_name', label: 'Broker Dealer Name', width: '280px', visible: true, sortable: false, cellRenderer: this.offeringsTemp, headerCellRenderer: this.allCustomerCustomHeader },
			{ id: 5, field: 'created_at', label: 'Created', width: '250px', visible: true, sortable: false, cellRenderer: this.offeringsTemp, headerCellRenderer: this.allCustomerCustomHeader },
			{ id: 6, field: 'last_sign_in_at', label: 'Last Login', width: '250px', visible: true, sortable: false, cellRenderer: this.offeringsTemp, headerCellRenderer: this.allCustomerCustomHeader },
			{ id: 7, field: 'action', label: 'Action', width: '250px', visible: true, sortable: false, cellRenderer: this.actionTemplate }
		];
		const connColumns = [
			{
				id: 'checkbox',
				pinned: true,
				className: '',
				width: '54px',
				minResizeWidth: 0,
				maxResizeWidth: null,
				resizable: false,
				visible: true, sortable: false,
				cellRenderer: this.singleCheckBoxTableConn,
				headerCellRenderer: this.allCheckBoxTableConn,
			},
			{ id: 1, field: 'email', label: 'Email Address', width: '250px', visible: true, sortable: false, cellRenderer: this.emailTemp, headerCellRenderer: this.connCustomerCustomHeader },
			{ id: 2, field: 'first_name', label: 'Customer First Name', width: '280px', visible: true, sortable: false, cellRenderer: this.offeringsTemp, headerCellRenderer: this.connCustomerCustomHeader },
			{ id: 3, field: 'last_name', label: 'Customer Last Name', width: '280px', visible: true, sortable: false, cellRenderer: this.offeringsTemp, headerCellRenderer: this.connCustomerCustomHeader },
			{ id: 4, field: 'broker_dealer_name', label: 'Broker Dealer Name', width: '280px', visible: true, sortable: false, cellRenderer: this.offeringsTemp, headerCellRenderer: this.connCustomerCustomHeader },
			{ id: 5, field: 'created_at', label: 'Created', width: '250px', visible: true, sortable: false, cellRenderer: this.offeringsTemp, headerCellRenderer: this.connCustomerCustomHeader },
			{ id: 6, field: 'last_sign_in_at', label: 'Last Login', width: '250px', visible: true, sortable: false, cellRenderer: this.offeringsTemp, headerCellRenderer: this.connCustomerCustomHeader },
			{ id: 7, field: 'action', label: 'Action', width: '250px', visible: true, sortable: false, cellRenderer: this.actionTemplate }
		];
		const nonConnColumns = [
			{
				id: 'checkbox',
				pinned: true,
				className: '',
				width: '54px',
				minResizeWidth: 0,
				maxResizeWidth: null,
				resizable: false,
				visible: true, sortable: false,
				cellRenderer: this.singleCheckBoxTableNonConn,
				headerCellRenderer: this.allCheckBoxTableNonConn,
			},
			{ id: 1, field: 'email', label: 'Email Address', width: '250px', visible: true, sortable: false, cellRenderer: this.emailTemp, headerCellRenderer: this.nonConnCustomerCustomHeader },
			{ id: 2, field: 'first_name', label: 'Customer First Name', width: '280px', visible: true, sortable: false, cellRenderer: this.offeringsTemp, headerCellRenderer: this.nonConnCustomerCustomHeader },
			{ id: 3, field: 'last_name', label: 'Customer Last Name', width: '280px', visible: true, sortable: false, cellRenderer: this.offeringsTemp, headerCellRenderer: this.nonConnCustomerCustomHeader },
			{ id: 4, field: 'broker_dealer_name', label: 'Broker Dealer Name', width: '280px', visible: true, sortable: false, cellRenderer: this.offeringsTemp, headerCellRenderer: this.nonConnCustomerCustomHeader },
			{ id: 5, field: 'marketing_brokerage', label: 'Marketing Brokerage', width: '280px', visible: true, sortable: false, headerCellRenderer: this.nonConnCustomerCustomHeader },
			{ id: 6, field: 'created_at', label: 'Created', width: '250px', visible: true, sortable: false, cellRenderer: this.offeringsTemp, headerCellRenderer: this.nonConnCustomerCustomHeader },
			{ id: 7, field: 'last_sign_in_at', label: 'Last Login', width: '250px', visible: true, sortable: false, cellRenderer: this.offeringsTemp, headerCellRenderer: this.nonConnCustomerCustomHeader },
			{ id: 8, field: 'action', label: 'Action', width: '250px', visible: true, sortable: false, cellRenderer: this.actionTemplate }
		];
		const delColumns = [
			{
				id: 'checkbox',
				pinned: true,
				className: '',
				width: '54px',
				minResizeWidth: 0,
				maxResizeWidth: null,
				resizable: false,
				visible: true, sortable: false,
				cellRenderer: this.singleCheckBoxTableDeleted,
				headerCellRenderer: this.allCheckBoxTableDeleted,
			},
			{ id: 1, field: 'email', label: 'Email Address', width: '250px', visible: true, sortable: false, cellRenderer: this.emailTemp, headerCellRenderer: this.nonConnCustomerCustomHeader },
			{ id: 2, field: 'first_name', label: 'Customer First Name', width: '280px', visible: true, sortable: false, cellRenderer: this.offeringsTemp, headerCellRenderer: this.nonConnCustomerCustomHeader },
			{ id: 3, field: 'last_name', label: 'Customer Last Name', width: '280px', visible: true, sortable: false, cellRenderer: this.offeringsTemp, headerCellRenderer: this.nonConnCustomerCustomHeader },
			{ id: 4, field: 'broker_dealer_name', label: 'Broker Dealer Name', width: '280px', visible: true, sortable: false, cellRenderer: this.offeringsTemp, headerCellRenderer: this.nonConnCustomerCustomHeader },
			{ id: 5, field: 'created_at', label: 'Created', width: '250px', visible: true, sortable: false, cellRenderer: this.offeringsTemp, headerCellRenderer: this.nonConnCustomerCustomHeader },
			{ id: 6, field: 'last_sign_in_at', label: 'Last Login', width: '250px', visible: true, sortable: false, cellRenderer: this.offeringsTemp, headerCellRenderer: this.nonConnCustomerCustomHeader },
			{ id: 7, field: 'action', label: 'Action', width: '250px', visible: true, sortable: false, cellRenderer: this.actionTemplate }
		];
		this.state = {
			isReadOnly: JSON.parse(getItem('isReadOnly')),
			uiRender: false,
			orderInfoCols: orderInfoColumns,
			activityCols: activityColumns,
			cols: columns,
			colsConn: connColumns,
			colsNonConn: nonConnColumns,
			colsDel:delColumns,
			tabIndex: 0,
			globalFilter: '',
			globalFilterOrder: '',
			newActiveCustomer: 0,
			newConnectedCustomer: 0,
			newNonConnectedCustomer: 0,
			newDeletedCustomer: 0,
			activeCustomerLength: 0,
			connectedCustomerLength: 0,
			nonConnectedCustomerLength: 0,
			deletedCustomerLength: 0,
			allCustomersList: [],
			getNewCustomerListConnected: [],
			getNotConnectedCustomerList: [],
			getDeletedCustomerList: [],
			filterByActiveCus: 'first_name',
			filterByConnCus: 'first_name',
			filterByNotConnCus: 'first_name',
			filterByDeleted: 'first_name',
			searchingConnectedCust: '',
			searchingActiveCustomer: '',
			searchingNotConnectedCustomer: '',
			searchingDeletedCustomer: '',
			notificationshow: null,
			notificationType: '',
			selectedCustomerList: [],
			confirmModal: null,
			confirmationType: '',
			customerInfo: '',
			getNewCustomerList: [],
			isFetchActive: false,
			isFetchConnect: false,
			isFetchNonConnect: false,
			isFetchDeleted: false,
			openUserEditModal: false,
			userDetail: {},
			firstName: '',
			lastName: '',
			email: '',
			errorMessageFirstName: '',
			errorMessageLastName: '',
			draftValue: false,
			openDraftModal: false,
			openActivityModal: false,
			customerActivityList: [],
			openOrderInfoModal: false,
			customerOrderList: [],
			multiSelect: [
				{ label: 'Active', value: true, id: 0 },
				{ label: 'Closed', value: false, id: 1 },
				{ label: 'Cancelled', value: false, id: 2 },
				{ label: 'Effective', value: false, id: 3 },
			],
			searching: '',
			loading: false,
			pageNumber: 1,
			pageNumberConn: 1,
			pageNumberNonConn: 1,
			pageNumberDelConn: 1,
			checkedAll: false,
			checkedAllConn: false,
			checkedAllNonConn: false,
			checkedAllDeleted: false,
			loadAll: false,
			loadAllConn: false,
			loadAllNonConn: false,
			loadAllDeleted: false,
			tableFilter: {
                field: null,
                order: null,
			},
			tableFilterConn: {
                field: null,
                order: null,
			},
			tableFilterNonConn: {
                field: null,
                order: null,
			},
			tableFilterDeleted: {
                field: null,
                order: null,
			},
			isSortActive: null,
			deleteReason: ''
		}
	}
	componentDidMount() {
		let payload = {
			pagenum: this.state.pageNumber
		}
		this.props.fetchAllCustomerList(payload, true);
		isActiveCustomer = true;
	}
	static getDerivedStateFromProps(props, state) {
		if (props.fetchCustomerListRes) {
			if (props.fetchCustomerListRes.data && props.fetchCustomerListRes.data.customerList) {
				if (props.fetchCustomerListRes.data.customerList.message === 'Success' && isActiveCustomer) {
					isActiveCustomer = false;
					var isAllActiveCustomer = state.activeCustomerLength;
                    if(state.activeCustomerLength===0){
                        isAllActiveCustomer = props.fetchCustomerListRes.data.customerList.data.length
                    }
					if (state.isFetchActive) {
						return {
							newActiveCustomer: props.fetchCustomerListRes.data.customerList.data.length,
							activeCustomerLength: isAllActiveCustomer,
							getNewCustomerList: props.fetchCustomerListRes.data.customerList.data,
							allCustomersList: state.allCustomersList.concat(props.fetchCustomerListRes.data.customerList.data),
							isFetchActive: false,
							loadAll: false
						};
					} else {
						return {
							newActiveCustomer: props.fetchCustomerListRes.data.customerList.data.length,
							activeCustomerLength: isAllActiveCustomer,
							getNewCustomerList: props.fetchCustomerListRes.data.customerList.data,
							allCustomersList: props.fetchCustomerListRes.data.customerList.data,
						};
					}
					
				}
			}
		}
		if (props.fetchConnectedCustomerListRes) {
			if (props.fetchConnectedCustomerListRes.data && props.fetchConnectedCustomerListRes.data.customerConnectedList) {
				if (props.fetchConnectedCustomerListRes.data.customerConnectedList.message === 'Success' && isConnectedCustomer) {
					isConnectedCustomer = false;
					var isAllConnectedCustomer = state.connectedCustomerLength;
                    if(state.connectedCustomerLength===0){
                        isAllConnectedCustomer = props.fetchCustomerListRes.data.customerConnectedList.data.length
                    }
					if (state.isFetchConnect) {
						return {
							newConnectedCustomer: props.fetchCustomerListRes.data.customerConnectedList.data.length,
							connectedCustomerLength: isAllConnectedCustomer,
							getNewCustomerListConnected: state.getNewCustomerListConnected.concat(props.fetchCustomerListRes.data.customerConnectedList.data),
							isFetchConnect: false,
							loadAllConn: false
						};
					} else {
						return {
							newConnectedCustomer: props.fetchCustomerListRes.data.customerConnectedList.data.length,
							connectedCustomerLength: isAllConnectedCustomer,
							getNewCustomerListConnected: props.fetchCustomerListRes.data.customerConnectedList.data,
						};
					}
				}
			}
		}
		if (props.fetchNonConnectedCustomerListRes) {
			if (props.fetchNonConnectedCustomerListRes.data && props.fetchNonConnectedCustomerListRes.data.customerNonConnectedList) {
				if (props.fetchNonConnectedCustomerListRes.data.customerNonConnectedList.message === 'Success' && isNonConnectedCustomer) {
					isNonConnectedCustomer = false;
					var isAllNonConnectedCustomer = state.nonConnectedCustomerLength;
                    if(state.nonConnectedCustomerLength===0){
                        isAllNonConnectedCustomer =props.fetchNonConnectedCustomerListRes.data.customerNonConnectedList.data.length
                    }
					if (state.isFetchNonConnect) {
						return {
							newNonConnectedCustomer: props.fetchNonConnectedCustomerListRes.data.customerNonConnectedList.data.length,
							nonConnectedCustomerLength: isAllNonConnectedCustomer,
							getNotConnectedCustomerList: state.getNotConnectedCustomerList.concat(props.fetchNonConnectedCustomerListRes.data.customerNonConnectedList.data),
							isFetchNonConnect: false,
							loadAllNonConn: false
						};
					} else {
						return {
							newNonConnectedCustomer: props.fetchNonConnectedCustomerListRes.data.customerNonConnectedList.data.length,
							nonConnectedCustomerLength: isAllNonConnectedCustomer,
							getNotConnectedCustomerList: props.fetchNonConnectedCustomerListRes.data.customerNonConnectedList.data,
						};
					}
				}
			}
		}
		if (props.fetchDeletedCustomerListRes) {
			if (props.fetchDeletedCustomerListRes.data && props.fetchDeletedCustomerListRes.data.customerDeletedList) {
				if (props.fetchDeletedCustomerListRes.data.customerDeletedList.message === 'Success' && isDeletedCustomer) {
					isDeletedCustomer = false;
					var isAllDeletedCustomer = state.deletedCustomerLength;
							if(state.deletedCustomerLength===0){
								isAllDeletedCustomer =props.fetchDeletedCustomerListRes.data.customerDeletedList.data.length
							}
					if (state.isFetchDeleted) {
						return {
							newDeletedCustomer: props.fetchDeletedCustomerListRes.data.customerDeletedList.data.length,
							deletedCustomerLength: isAllDeletedCustomer,
							getDeletedCustomerList: state.getDeletedCustomerList.concat(props.fetchDeletedCustomerListRes.data.customerDeletedList.data),
							isFetchDeleted: false,
							loadAllDeleted: false
						};
					} else {
						return {
							newDeletedCustomer: props.fetchDeletedCustomerListRes.data.customerDeletedList.data.length,
							deletedCustomerLength: isAllDeletedCustomer,
							getDeletedCustomerList: props.fetchDeletedCustomerListRes.data.customerDeletedList.data,
						};
					}
				}
			}
		}
		if (props.fetchCustomerActivityRes) {
			if (props.fetchCustomerActivityRes.data && props.fetchCustomerActivityRes.data.customerActivity) {
				if (props.fetchCustomerActivityRes.data.customerActivity.message === 'Success') {
					
					if (props.fetchCustomerActivityRes.data.customerActivity.data) {
						props.fetchCustomerActivityRes.data.customerActivity.data.forEach((obj) => {
							for(var i in obj) { 
							  if(obj[i] === undefined || obj[i] === null) {
								obj[i] = '';
							  }
							}
						});
					}
					return {
						customerActivityList: props.fetchCustomerActivityRes.data.customerActivity.data,
						loading: false
					};
				}
			}
		}
		if (props.fetchCustomerOrderInfoRes) {
			if (props.fetchCustomerOrderInfoRes.data && props.fetchCustomerOrderInfoRes.data.customerOrderInfo) {
				if (props.fetchCustomerOrderInfoRes.data.customerOrderInfo.message === 'Success') {
					return {
						customerOrderList: props.fetchCustomerOrderInfoRes.data.customerOrderInfo.data,
						loading: false
					};
				}
			}
		}
		if (props.customerResetPassRes) {
			if (props.customerResetPassRes.data && props.customerResetPassRes.data.customerResetPass) {
				if (props.customerResetPassRes.data.customerResetPass.message === 'Success') {
					return {
						confirmModal: false
					};
				}
			}
		}
		if (props.deleteCustomerRes) {
			if (props.deleteCustomerRes.data && props.deleteCustomerRes.data.deleteCustomer) {
				if (props.deleteCustomerRes.data.deleteCustomer.message === 'Success') {
					if (state.tabIndex === 0) {
						let custList = state.allCustomersList;
						custList.splice(custList.indexOf(custList.find((row) => row.email === state.customerInfo.email)), 1);
						return {
							allCustomersList: custList,
							confirmModal: false,
							deleteReason: ''
						};
					}
					if (state.tabIndex === 1) {
						let custList = state.getNewCustomerListConnected;
						custList.splice(custList.indexOf(custList.find((row) => row.email === state.customerInfo.email)), 1);
						return {
							getNewCustomerListConnected: custList,
							confirmModal: false,
							deleteReason: ''
						};
					}
					if (state.tabIndex === 2) {
						let custList = state.getNotConnectedCustomerList;
						custList.splice(custList.indexOf(custList.find((row) => row.email === state.customerInfo.email)), 1);
						return {
							getNotConnectedCustomerList: custList,
							confirmModal: false,
							deleteReason: ''
						};
					}
					
				}
			}
		}
		if (props.updateCustomerRes) {
			if (props.updateCustomerRes.data && props.updateCustomerRes.data.updateCustomer) {
				if (props.updateCustomerRes.data.updateCustomer.message === 'Success') {
					if (state.tabIndex === 0) {
						for (let item of state.allCustomersList) {
							if (item.email === state.email) {
								item.first_name = state.firstName;
								item.last_name = state.lastName;
							}
						}
					} else if (state.tabIndex === 0) {
						for (let item of state.getNewCustomerListConnected) {
							if (item.email === state.email) {
								item.first_name = state.firstName;
								item.last_name = state.lastName;
							}
						}
					} else if (state.tabIndex === 0) {
						for (let item of state.getNotConnectedCustomerList) {
							if (item.email === state.email) {
								item.first_name = state.firstName;
								item.last_name = state.lastName;
							}
						}
					}
					return {
						openUserEditModal: false,
						errorMessageFirstName: '',
						errorMessageLastName: '',
						draftValue: false,
						openDraftModal: false,
					};
				}
			}
		}
		return null;
	}
	singleCheckBoxTable = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return (
			<Checkbox
				checked={data.checked !== undefined ? data.checked : false}
				onChange={(e) => this.handleChangeRowSelect(e, data, rowIndex)}
				value={data.email}
				color="primary"
				inputProps={{ 'aria-label': 'primary checkbox' }}
				disabled={this.state.isReadOnly}
			/>
		)
	}
	allCheckBoxTable = ({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => {
		return (
			<Checkbox
				checked={this.state.checkedAll}
				onChange={(e) => this.handleChangeSelectAll(e)}
				value="checkedAll"
				color="primary"
				inputProps={{ 'aria-label': 'primary checkbox' }}
				disabled={this.state.isReadOnly}
			/>
		)
	}
	singleCheckBoxTableConn = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return (
			<Checkbox
				checked={data.checked !== undefined ? data.checked : false}
				onChange={(e) => this.handleChangeRowSelectConnected(e, data, rowIndex)}
				value={data.email}
				color="primary"
				inputProps={{ 'aria-label': 'primary checkbox' }}
				disabled={this.state.isReadOnly}
			/>
		)
	}
	allCheckBoxTableConn = ({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => {
		return (
			<Checkbox
				checked={this.state.checkedAllConn}
				onChange={(e) => this.handleChangeSelectAllConnected(e)}
				value="checkedAll"
				color="primary"
				inputProps={{ 'aria-label': 'primary checkbox' }}
				disabled={this.state.isReadOnly}
			/>
		)
	}
	singleCheckBoxTableNonConn = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return (
			<Checkbox
				checked={data.checked !== undefined ? data.checked : false}
				onChange={(e) => this.handleChangeRowSelectNonConn(e, data, rowIndex)}
				value={data.email}
				color="primary"
				inputProps={{ 'aria-label': 'primary checkbox' }}
				disabled={this.state.isReadOnly}
			/>
		)
	}
	allCheckBoxTableNonConn = ({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => {
		return (
			<Checkbox
				checked={this.state.checkedAllNonConn}
				onChange={(e) => this.handleChangeSelectAllNonConn(e)}
				value="checkedAll"
				color="primary"
				inputProps={{ 'aria-label': 'primary checkbox' }}
				disabled={this.state.isReadOnly}
			/>
		)
	}
	singleCheckBoxTableDeleted = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return (
			<Checkbox
				checked={data.checked !== undefined ? data.checked : false}
				onChange={(e) => this.handleChangeRowSelectDeleted(e, data, rowIndex)}
				value={data.email}
				color="primary"
				inputProps={{ 'aria-label': 'primary checkbox' }}
				disabled={this.state.isReadOnly}
			/>
		)
	}
	allCheckBoxTableDeleted = ({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => {
		return (
			<Checkbox
				checked={this.state.checkedAllDeleted}
				onChange={(e) => this.handleChangeSelectAllDeleted(e)}
				value="checkedAll"
				color="primary"
				inputProps={{ 'aria-label': 'primary checkbox' }}
				disabled={this.state.isReadOnly}
			/>
		)
	}
	allCustomerCustomHeader = ({tableManager, column, mode, ref, checked, disabled, indeterminate, onChange}) => {
        return <div>{column.label} {this.onSortFunction(column.field)}</div>
    }
	connCustomerCustomHeader = ({tableManager, column, mode, ref, checked, disabled, indeterminate, onChange}) => {
        return <div>{column.label} {this.onSortFunction(column.field)}</div>
	}
	nonConnCustomerCustomHeader = ({tableManager, column, mode, ref, checked, disabled, indeterminate, onChange}) => {
        return <div>{column.label} {this.onSortFunction(column.field)}</div>
    }
	deletedCustomerCustomHeader = ({tableManager, column, mode, ref, checked, disabled, indeterminate, onChange}) => {
        return <div>{column.label} {this.onSortFunction(column.field)}</div>
    }
	onSelection = (items) => {
		this.setState({
			selectedCustomerList: items.value,
		});
	}

	downLoadCSV = async (csvData, fileName) => {
		let csvContent = '';
		if (fileName === 'allcustomers') {
			csvContent += 'First Name, Last Name, Email, Marketing Brokerage, Created Date \r\n';
			csvData.forEach(function (rowArray) {
				const newRow = rowArray.first_name + ',' + rowArray.last_name + ',' + rowArray.email + ',' + rowArray.marketing_brokerage + ',' + rowArray.created_at;
				csvContent += newRow + '\r\n'; // add carriage return
			});
		} else {
			csvContent += 'First Name, Last Name, Email \r\n';
			csvData.forEach(function (rowArray) {
				const newRow = rowArray.first_name + ',' + rowArray.last_name + ',' + rowArray.email;
				csvContent += newRow + '\r\n'; // add carriage return
			});
		}
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
		await saveFile(blob, fileName + '.csv');
	}
	offeringsTemp = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		if (value) {
			return  <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
				{
					value !== '' &&
					<Tooltip title={value} interactive >
						<div className="text-one-line" style={{width: '90%'}} >{value}</div>
					</Tooltip>
				}
				{
					value !== '' &&
					<Tooltip title={'Copy to clipboard'} interactive >
						<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
					</Tooltip>
				}
			</div>;
		}
		
		
	}
	emailTemp = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		if (value) {
			return  <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
				{
					value !== '' &&
					<Tooltip title={value} interactive >
						<div className="text-one-line" style={{width: '90%', color: '#8DC73F', cursor: 'pointer'}} onClick={() => this.showOrderInfoModal(data)}>{value}</div>
					</Tooltip>
				}
				{
					value !== '' &&
					<Tooltip title={'Copy to clipboard'} interactive >
						<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
					</Tooltip>
				}
			</div>;
		}
	}
	
	handleTabChange = (event, newValue) => {
		this.setState({
			tabIndex: newValue,
		}, () => {
			if (this.state.tabIndex === 0) {
				this.setState({
					filterByActiveCus: 'first_name',
					// filterByConnCus: 'first_name',
					// filterByNotConnCus: 'first_name',
					// searchingConnectedCust: null,
					searchingActiveCustomer: '',
					// searchingNotConnectedCustomer: null,
					// allCustomersList: [],
					checkedAll: false,
					tableFilter: {
						field: null,
						order: null,
					},
				})
				if (this.state.allCustomersList.length === 0) {
					let payload = {
						pagenum: this.state.pageNumber
					}
					this.props.fetchAllCustomerList(payload, true);
					isActiveCustomer = true;
				} else {
					for (let item of this.state.allCustomersList) {
						item.checked = false;
					}
				}
			} else if (this.state.tabIndex === 1) {
				this.setState({
					// filterByActiveCus: 'first_name',
					filterByConnCus: 'first_name',
					// filterByNotConnCus: 'first_name',
					searchingConnectedCust: '',
					// searchingActiveCustomer: null,
					// searchingNotConnectedCustomer: null,
					// getNewCustomerListConnected: [],
					checkedAllConn: false,
					tableFilterConn: {
						field: null,
						order: null,
					},
				})
				if (this.state.getNewCustomerListConnected.length === 0) {
					let payload = {
						pagenum: this.state.pageNumberConn
					}
					this.props.fetchConnectedCustomers(payload, true);
					isConnectedCustomer = true;
				} else {
					for (let item of this.state.getNewCustomerListConnected) {
						item.checked = false;
					}
				}
			} else if (this.state.tabIndex === 2) {
				this.setState({
					// filterByActiveCus: 'first_name',
					// filterByConnCus: 'first_name',
					filterByNotConnCus: 'first_name',
					// searchingConnectedCust: null,
					// searchingActiveCustomer: null,
					searchingNotConnectedCustomer: '',
					// getNotConnectedCustomerList: [],
					checkedAllNonConn: false,
					tableFilterNonConn: {
						field: null,
						order: null,
					},
				})
				if (this.state.getNotConnectedCustomerList.length === 0) {
					let payload = {
						pagenum: this.state.pageNumberNonConn
					}
					this.props.fetchNonConnectedCustomers(payload, true);
					isNonConnectedCustomer = true;
				} else {
					for (let item of this.state.getNotConnectedCustomerList) {
						item.checked = false;
					}
				}
				
			} else if (this.state.tabIndex === 3) {
				this.setState({
					// filterByActiveCus: 'first_name',
					// filterByConnCus: 'first_name',
					filterByDeleted: 'first_name',
					// searchingConnectedCust: null,
					// searchingActiveCustomer: null,
					// searchingNotConnectedCustomer: null,
					// getNotConnectedCustomerList: [],
					searchingDeletedCustomer: '',
					checkedAllDeleted: false,
					tableFilterDeleted: {
						field: null,
						order: null,
					},
				})
				if (this.state.getDeletedCustomerList.length === 0) {
					let payload = {
						pagenum: this.state.pageNumberDelConn
					}
					this.props.fetchDeletedCustomers(payload, true);
					isDeletedCustomer = true;
				} else {
					for (let item of this.state.getDeletedCustomerList) {
						item.checked = false;
					}
				}
				
			}
		});
	}
	actionTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return (<div className="rgt-cell-inner">
			<Tooltip title="Comms">
				<Button style={{ width: 'auto', margin: 3 }} variant="outlined" size="medium" color="primary" onClick={() => this.activityModal(data)}><AccountBoxIcon /></Button>
			</Tooltip>
			{
				(!this.state.isReadOnly) &&
				<Tooltip title="Edit">
					<Button style={{ width: 'auto', margin: 3 }} variant="outlined" size="medium" color="primary" onClick={() => this.openEditModal(data)}><EditIcon /></Button>
				</Tooltip>
			}
			{
				(!this.state.isReadOnly) &&
				<Tooltip title="Delete">
					<Button style={{ width: 'auto', margin: 3 }} variant="outlined" size="medium" color="secondary" onClick={() => this.openConfirmModal(data, 'delete')}><DeleteIcon /></Button>
				</Tooltip>
			}
		</div>);
	}
	toggleNotificationModal = () => {
		this.setState({
			notificationshow: !this.state.notificationshow,
		});
	}
	openResetPasswordModal = () => {
		this.setState({
			openUserEditModal: false,
			errorMessageFirstName: '',
			errorMessageLastName: '',
			draftValue: false,
			openDraftModal: false,
		});
		this.openConfirmModal(this.state.userDetail, 'password');
	}
	handleAllChanges = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
		if (this.state.userDetail.first_name !== this.state.firstName) {
			this.setState({
				draftValue: true,
			});
		} else if (this.state.userDetail.first_name !== this.state.lastName) {
			this.setState({
				draftValue: true,
			});
		}
	}

	updateCustomer = () => {
		if (this.state.firstName === '') {
			this.setState({
				errorMessageFirstName: 'Please enter first name'
			});
			return;
		} else if (this.state.lastName === '') {
			this.setState({
				errorMessageLastName: 'Please enter last name'
			});
			return;
		} else {
			let requestData = {
				first_name: this.state.firstName,
				last_name: this.state.lastName,
				email: this.state.email,
			}
			this.props.updateUserData(requestData);
		}
	}
	activityModal = (item) => {
		this.setState({
			userDetail: item,
			openActivityModal: true,
		});
		this.props.getActivityList(item.email);
		this.setState({
			loading: true,
		})
	}
	closeActivityModal = () => {
		this.setState({
			openActivityModal: false,
			customerActivityList: [],
		});
		this.props.getActivityListNull(null);
	}
	closeDraftModal = () => {
		this.setState({
			openUserEditModal: false,
			errorMessageFirstName: '',
			errorMessageLastName: '',
			draftValue: false,
			openDraftModal: false,
		});
	}
	openEditModal = (data) => {
		this.setState({
			openUserEditModal: true,
			userDetail: data,
			firstName: data.first_name,
			lastName: data.last_name,
			email: data.email,
		});
	}
	closeEditModal = () => {
		if (this.state.draftValue === true) {
			this.setState({
				openDraftModal: true,
			});
		} else {
			this.setState({
				openUserEditModal: false,
			});
		}

	}

	openConfirmModal = (userInfo, confirmtype) => {
		this.setState({
			confirmModal: true,
			confirmationType: confirmtype,
			customerInfo: userInfo,
		});
	}

	closeConfirmModal = () => {
		this.setState({
			confirmModal: false,
			deleteReason: ''
		});
	}
	confirmModaltoggle = () => {
		this.setState({
			confirmModal: !this.state.confirmModal,
			deleteReason: ''
		});
	}

	openSendEmail = () => {
		let selecCust = [];
		for (let item of this.state.allCustomersList) {
			if (item.checked === true) {
				selecCust.push(item)
			}
		}
		this.setState({
			selectedCustomerList: selecCust,
			notificationshow: true,
			notificationType: 'EMAIL',
		});
	}
	openSendPush = () => {
		let selecCust = [];
		for (let item of this.state.allCustomersList) {
			if (item.checked === true) {
				selecCust.push(item)
			}
		}
		this.setState({
			selectedCustomerList: selecCust,
			notificationshow: true,
			notificationType: 'PUSH',
		});
	}
	showOrderInfoModal = (data) => {
		this.setState({
			userDetail: data,
			openOrderInfoModal: true,
		});
		
		this.props.getOrderInfoList(data.email);
		this.setState({
			loading: true,
		});
	}

	closeOrderInfoModal = () => {
		this.props.fetchCustomerOrderInfoSuccess(null);
		this.setState({
			openOrderInfoModal: false,
			customerOrderList: [],
		});
	}
	amountCurrencyTemp = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		if (value) {
			return  <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
				{
					value !== '' &&
					<Tooltip title={`$ ${currency(value)}`} interactive >
						<div className="text-one-line" style={{width: '90%'}}>$ {currency(value)}</div>
					</Tooltip>
				}
				{
					value !== '' &&
					<Tooltip title={'Copy to clipboard'} interactive >
						<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
					</Tooltip>
				}
			</div>;
		}
	}
	sharesTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		if (value) {
			return  <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
				{
					value !== '' &&
					<Tooltip title={numberWithCommas(value)} interactive >
						<div className="text-one-line" style={{width: '90%'}}>{numberWithCommas(value)}</div>
					</Tooltip>
				}
				{
					value !== '' &&
					<Tooltip title={'Copy to clipboard'} interactive >
						<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
					</Tooltip>
				}
			</div>;
		}
	}
	searchCustomer = (event) => {
		if (this.state.filterByActiveCus === 'email') {
			this.setState({
				searchingActiveCustomer: event.target.value.toLowerCase().trim(),
			}, () => {
			});
		} else {
			this.setState({
				searchingActiveCustomer: event.target.value,
			}, () => {
			});
		}
	}

	searchConnectedCustomer = (event) => {
		if (this.state.filterByConnCus === 'email') {
			this.setState({
				searchingConnectedCust: event.target.value.toLowerCase().trim(),
			}, () => {
			});
		} else {
			this.setState({
				searchingConnectedCust: event.target.value,
			}, () => {
			});
		}
	}
	searchNotConnectedCustomer = (event) => {
		if (this.state.filterByNotConnCus === 'email') {
			this.setState({
				searchingNotConnectedCustomer: event.target.value.toLowerCase().trim(),
			}, () => {
			});
		} else {
			this.setState({
				searchingNotConnectedCustomer: event.target.value,
			}, () => {
			});
		}
	}
	searchDeletedCustomer = (event) => {
		if (this.state.filterByDeleted === 'email') {
			this.setState({
				searchingDeletedCustomer: event.target.value.toLowerCase().trim(),
			}, () => {
			});
		} else {
			this.setState({
				searchingDeletedCustomer: event.target.value,
			}, () => {
			});
		}
	}
	searchUserActivity = (event) => {
		this.setState({
			globalFilter: event.target.value,
		}, () => {
		});
	}
	searchOrderInfo = (event) => {
		this.setState({
			globalFilterOrder: event.target.value,
		}, () => {
		});
	}
	dropDownSearchByConnCus = (event) => {
		this.setState({
			filterByConnCus: event.target.value,
		}, () => {
		});
	}

	dropDownSearchByNotConnCus = (event) => {
		this.setState({
			filterByNotConnCus: event.target.value,
		}, () => {
		});
	}
	dropDownSearchByDeletedCus = (event) => {
		this.setState({
			filterByDeleted: event.target.value,
		}, () => {
		});
	}
	dropDownSearchByAllCus = (event) => {
		this.setState({
			filterByActiveCus: event.target.value,
		}, () => {
		});
	}
	optionClicked = (optionsList) => {
		this.setState({ multiSelect: optionsList });
	}

	selectedBadgeClicked = (optionsList) => {
		this.setState({ multiSelect: optionsList }, () => { });
	}
	doDeleteCustomer = (email, reason) => {
		let payload = {
			email: email,
			delete_reason: reason
		}
		this.props.submitDeleteCustomer(payload);
	}
	doResetPasswordClick = () => {
		this.props.sendCustomerResetPassword(this.state.customerInfo.email);
	}
	openGraph = () => {
		this.props.history.push({
			pathname: '/customer-graph',
			state: this.state.allCustomersList
		})
	}
	fetchData = () => {
		if (this.state.newActiveCustomer === this.state.activeCustomerLength) {
			let payload = {
				pagenum: this.state.pageNumber === -1?1:this.state.pageNumber + 1
			}
			if (this.state.tableFilter.field !== null) {
				payload.sortby = this.state.tableFilter.field;
				payload.sorttype = this.state.tableFilter.order;
			}
			
			if (this.state.searchingActiveCustomer !== '') {
				payload.search_keyword = this.state.searchingActiveCustomer;
				payload.search_key = this.state.filterByActiveCus;
			}
			this.props.fetchAllCustomerList(payload, true);
			isActiveCustomer = true;
			this.setState({
				pageNumber: payload.pagenum,
				isFetchActive: true,
				loadAll:false
			})
		}
		
	}
	fetchDataConnected = () => {
		if (this.state.newConnectedCustomer === this.state.connectedCustomerLength) {
			let payload = {
				pagenum: this.state.pageNumberConn === -1?1:this.state.pageNumberConn + 1,
			}
			if (this.state.tableFilterConn.field !== null) {
				payload.sortby = this.state.tableFilterConn.field;
				payload.sorttype = this.state.tableFilterConn.order;
			}
			
			if (this.state.searchingConnectedCust !== '') {
				payload.search_keyword = this.state.searchingConnectedCust;
				payload.search_key = this.state.filterByConnCus;
			}
			this.props.fetchConnectedCustomers(payload, true);
			isConnectedCustomer = true;
			this.setState({
				pageNumberConn: payload.pagenum,
				isFetchConnect: true,
				loadAllConn:false
			})
		}
	}
	fetchDataNonConn = () => {
		if (this.state.newNonConnectedCustomer === this.state.nonConnectedCustomerLength) {
			let payload = {
				pagenum: this.state.pageNumberNonConn === -1?1:this.state.pageNumberNonConn + 1,
			}
			if (this.state.tableFilterNonConn.field !== null) {
				payload.sortby = this.state.tableFilterNonConn.field;
				payload.sorttype = this.state.tableFilterNonConn.order;
			}
			if (this.state.searchingNotConnectedCustomer !== '') {
				payload.search_keyword = this.state.searchingNotConnectedCustomer;
				payload.search_key = this.state.filterByNotConnCus;
			}
			this.props.fetchNonConnectedCustomers(payload, true);
			isNonConnectedCustomer = true;
			this.setState({
				pageNumberNonConn: payload.pagenum,
				isFetchNonConnect: true,
				loadAllNonConn:false
			})
		}
	}
	fetchDataDeleted = () => {
		if (this.state.newDeletedCustomer === this.state.deletedCustomerLength) {
			let payload = {
				pagenum: this.state.pageNumberDelConn === -1?1:this.state.pageNumberDelConn + 1,
			}
			if (this.state.tableFilterDeleted.field !== null) {
				payload.sortby = this.state.tableFilterDeleted.field;
				payload.sorttype = this.state.tableFilterDeleted.order;
			}
			if (this.state.searchingDeletedCustomer !== '') {
				payload.search_keyword = this.state.searchingDeletedCustomer;
				payload.search_key = this.state.filterByDeleted;
			}
			this.props.fetchDeletedCustomers(payload, true);
			isDeletedCustomer = true;
			this.setState({
				pageNumberDelConn: payload.pagenum,
				isFetchDeleted: true,
				loadAllDeleted:false
			})
		}
	}
	handleChangeSelectAll = (e) => {
		let allCheck = this.state.allCustomersList;
		allCheck.forEach(all => all.checked = e.target.checked)
		this.setState({
			checkedAll: e.target.checked,
			allCustomersList: allCheck,
			uiRender: !this.state.uiRender
		})
	}
	handleChangeRowSelect = (e, data, index) => {
		let customerActive = this.state.allCustomersList;
		customerActive.forEach(element => {
			if (element.email === e.target.value) {
				element.checked = e.target.checked
			}
		});
		if (customerActive.every( (val, i, arr) => val.checked === true )) {
			this.setState({
				checkedAll: true,
			})
		} else {
			this.setState({
				checkedAll: false,
			})
		}
		this.setState({
			allCustomersList: customerActive,
			uiRender: !this.state.uiRender
		})
	}
	handleChangeSelectAllConnected = (e) => {
		let allCheck = this.state.getNewCustomerListConnected;
		allCheck.forEach(all => all.checked = e.target.checked)
		this.setState({
			checkedAllConn: e.target.checked,
			getNewCustomerListConnected: allCheck,
			uiRender: !this.state.uiRender
		})
	}
	handleChangeRowSelectConnected = (e, data, index) => {
		let customerActive = this.state.getNewCustomerListConnected;
		customerActive.forEach(element => {
			if (element.email === e.target.value) {
				element.checked = e.target.checked
			}
		});
		if (customerActive.every( (val, i, arr) => val.checked === true )) {
			this.setState({
				checkedAllConn: true,
			})
		} else {
			this.setState({
				checkedAllConn: false,
			})
		}
		this.setState({
			getNewCustomerListConnected: customerActive,
			uiRender: !this.state.uiRender
		})
	}
	handleChangeSelectAllNonConn = (e) => {
		let allCheck = this.state.getNotConnectedCustomerList;
		allCheck.forEach(all => all.checked = e.target.checked)
		this.setState({
			checkedAllNonConn: e.target.checked,
			getNotConnectedCustomerList: allCheck,
			uiRender: !this.state.uiRender
		})
	}
	handleChangeRowSelectNonConn = (e, data, index) => {
		let customerActive = this.state.getNotConnectedCustomerList;
		customerActive.forEach(element => {
			if (element.email === e.target.value) {
				element.checked = e.target.checked
			}
		});
		if (customerActive.every( (val, i, arr) => val.checked === true )) {
			this.setState({
				checkedAllNonConn: true,
			})
		} else {
			this.setState({
				checkedAllNonConn: false,
			})
		}
		this.setState({
			getNotConnectedCustomerList: customerActive,
			uiRender: !this.state.uiRender
		})
	}
	handleChangeSelectAllDeleted = (e) => {
		let allCheck = this.state.getDeletedCustomerList;
		allCheck.forEach(all => all.checked = e.target.checked)
		this.setState({
			checkedAllDeleted: e.target.checked,
			getDeletedCustomerList: allCheck,
			uiRender: !this.state.uiRender
		})
	}
	handleChangeRowSelectDeleted = (e, data, index) => {
		let customerActive = this.state.getDeletedCustomerList;
		customerActive.forEach(element => {
			if (element.email === e.target.value) {
				element.checked = e.target.checked
			}
		});
		if (customerActive.every( (val, i, arr) => val.checked === true )) {
			this.setState({
				checkedAllDeleted: true,
			})
		} else {
			this.setState({
				checkedAllDeleted: false,
			})
		}
		this.setState({
			getDeletedCustomerList: customerActive,
			uiRender: !this.state.uiRender
		})
	}
	onSortFunction(field) {
		if (this.state.tabIndex === 0) {
			return (<span>
				{
					((this.state.tableFilter.field !== field)) &&
					<IconButton onClick={() => { this.doSort(field, 'asc'); }} style={{ padding: 10 }}>
						<SortIcon style={{ fontSize: 18 }} />
					</IconButton>
				}
				{
					((this.state.tableFilter.order === 'asc') && (this.state.tableFilter.field === field)) &&
					<IconButton className="btn-custom-sort" onClick={() => { this.doSort(field, 'desc'); }} style={{ padding: 10 }}>
						<ArrowDropUpIcon style={{ fontSize: 18 }} />
					</IconButton>
				}
				{
					((this.state.tableFilter.order === 'desc') && (this.state.tableFilter.field === field)) &&
					<IconButton className="btn-custom-sort" onClick={() => { this.doSort(field, 'asc'); }} style={{ padding: 10 }}>
						<ArrowDropDownIcon style={{ fontSize: 18 }} />
					</IconButton>
				}
			</span>);
		} else if (this.state.tabIndex === 1) {
			return (<span>
				{
					((this.state.tableFilterConn.field !== field)) &&
					<IconButton onClick={() => { this.doSort(field, 'asc'); }} style={{ padding: 10 }}>
						<SortIcon style={{ fontSize: 18 }} />
					</IconButton>
				}
				{
					((this.state.tableFilterConn.order === 'asc') && (this.state.tableFilterConn.field === field)) &&
					<IconButton className="btn-custom-sort" onClick={() => { this.doSort(field, 'desc'); }} style={{ padding: 10 }}>
						<ArrowDropUpIcon style={{ fontSize: 18 }} />
					</IconButton>
				}
				{
					((this.state.tableFilterConn.order === 'desc') && (this.state.tableFilterConn.field === field)) &&
					<IconButton className="btn-custom-sort" onClick={() => { this.doSort(field, 'asc'); }} style={{ padding: 10 }}>
						<ArrowDropDownIcon style={{ fontSize: 18 }} />
					</IconButton>
				}
			</span>);
		} else if (this.state.tabIndex === 2) {
			return (<span>
				{
					((this.state.tableFilterNonConn.field !== field)) &&
					<IconButton onClick={() => { this.doSort(field, 'asc'); }} style={{ padding: 10 }}>
						<SortIcon style={{ fontSize: 18 }} />
					</IconButton>
				}
				{
					((this.state.tableFilterNonConn.order === 'asc') && (this.state.tableFilterNonConn.field === field)) &&
					<IconButton className="btn-custom-sort" onClick={() => { this.doSort(field, 'desc'); }} style={{ padding: 10 }}>
						<ArrowDropUpIcon style={{ fontSize: 18 }} />
					</IconButton>
				}
				{
					((this.state.tableFilterNonConn.order === 'desc') && (this.state.tableFilterNonConn.field === field)) &&
					<IconButton className="btn-custom-sort" onClick={() => { this.doSort(field, 'asc'); }} style={{ padding: 10 }}>
						<ArrowDropDownIcon style={{ fontSize: 18 }} />
					</IconButton>
				}
			</span>);
		} else if (this.state.tabIndex === 3) {
			return (<span>
				{
					((this.state.tableFilterDeleted.field !== field)) &&
					<IconButton onClick={() => { this.doSort(field, 'asc'); }} style={{ padding: 10 }}>
						<SortIcon style={{ fontSize: 18 }} />
					</IconButton>
				}
				{
					((this.state.tableFilterDeleted.order === 'asc') && (this.state.tableFilterDeleted.field === field)) &&
					<IconButton className="btn-custom-sort" onClick={() => { this.doSort(field, 'desc'); }} style={{ padding: 10 }}>
						<ArrowDropUpIcon style={{ fontSize: 18 }} />
					</IconButton>
				}
				{
					((this.state.tableFilterDeleted.order === 'desc') && (this.state.tableFilterDeleted.field === field)) &&
					<IconButton className="btn-custom-sort" onClick={() => { this.doSort(field, 'asc'); }} style={{ padding: 10 }}>
						<ArrowDropDownIcon style={{ fontSize: 18 }} />
					</IconButton>
				}
			</span>);
		}
        
	}
	resetAllActiveCustomers() {
		this.setState({
			newActiveCustomer: 0,
			activeCustomerLength: 0,
		});
	}
	resetAllConnCustomers() {
		this.setState({
			newConnectedCustomer: 0,
			connectedCustomerLength: 0,
		});
	}
	resetAllNonConnCustomers() {
		this.setState({
			newNonConnectedCustomer: 0,
			nonConnectedCustomerLength: 0,
		});
	}
	resetAllDeletedCustomers() {
		this.setState({
			newDeletedCustomer: 0,
			deletedCustomerLength: 0,
		});
	}
	doSort(field, order) {
		if (this.state.tabIndex === 0) {
			if (this.state.allCustomersList) {
				if (!this.state.loadAll) {
					if (order === null || order === 'asc') {
						this.setState({
							isSortActive: 'desc',
							tableFilter: {
								field,
								order,
							},
						}, () => { });
						let payload = {
							pagenum: 1,
							sortby: field,
							sorttype: order,
						}
						if (this.state.searchingActiveCustomer !== '') {
							payload.search_key = this.state.filterByActiveCus;
							payload.search_keyword = this.state.searchingActiveCustomer;
						}
						this.props.fetchAllCustomerList(payload, true);
						isActiveCustomer = true;
						this.resetAllActiveCustomers();
						this.setState({
							pageNumber: 1,
							isFetchActive: true
						})
					} else {
						this.setState({
							isSortActive: 'asc',
							tableFilter: {
								field,
								order,
							},
						}, () => { });
						let payload = {
							pagenum: 1,
							sortby: field,
							sorttype: order
						}
						if (this.state.searchingActiveCustomer !== '') {
							payload.search_key = this.state.filterByActiveCus;
							payload.search_keyword = this.state.searchingActiveCustomer;
						}
						this.props.fetchAllCustomerList(payload, true);
						isActiveCustomer = true;
						this.resetAllActiveCustomers();
						this.setState({
							pageNumber: 1,
							isFetchActive: true
						})
					}
				} else {
					if (order === null || order === 'asc') {
						this.setState({
							tableFilter: {
								field,
								order,
							},
						}, () => { });
						let allCustomersList = this.state.allCustomersList.sort((a, b) => (a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0));
						this.setState({
							allCustomersList: allCustomersList,
							isUIrender: !this.state.isUIrender
						});
					} else {
						this.setState({
							tableFilter: {
								field,
								order,
							},
						}, () => { });
						let allCustomersList = this.state.allCustomersList.sort((a, b) => (a[field] < b[field] ? 1 : a[field] > b[field] ? -1 : 0));
						this.setState({
							allCustomersList: allCustomersList,
							isUIrender: !this.state.isUIrender
						});
					}
				}
			}
		} else if (this.state.tabIndex === 1) {
			if (this.state.getNewCustomerListConnected) {
				if (!this.state.loadAllConn) {
					if (order === null || order === 'asc') {
						this.setState({
							isSortActive: 'desc',
							tableFilterConn: {
								field,
								order,
							},
						}, () => { });
						let payload = {
							pagenum: 1,
							sortby: field,
							sorttype: order,
						}
						if (this.state.searchingConnectedCust !== '') {
							payload.search_key = this.state.filterByConnCus;
							payload.search_keyword = this.state.searchingConnectedCust;
						}
						this.props.fetchConnectedCustomers(payload, true);
						isConnectedCustomer = true;
						this.resetAllConnCustomers();
						this.setState({
							pageNumberConn: 1,
							isFetchConnect: true
						})
					} else {
						this.setState({
							isSortActive: 'asc',
							tableFilterConn: {
								field,
								order,
							},
						}, () => { });
						let payload = {
							pagenum: 1,
							sortby: field,
							sorttype: order
						}
						if (this.state.searchingConnectedCust !== '') {
							payload.search_key = this.state.filterByConnCus;
							payload.search_keyword = this.state.searchingConnectedCust;
						}
						this.props.fetchConnectedCustomers(payload, true);
						isConnectedCustomer = true;
						this.resetAllConnCustomers();
						this.setState({
							pageNumberConn: 1
						})
					}
				} else {
					if (order === null || order === 'asc') {
						this.setState({
							tableFilterConn: {
								field,
								order,
							},
						}, () => { });
						let getNewCustomerListConnected = this.state.getNewCustomerListConnected.sort((a, b) => (a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0));
						this.setState({
							getNewCustomerListConnected: getNewCustomerListConnected,
							isUIrender: !this.state.isUIrender
						});
					} else {
						this.setState({
							tableFilterConn: {
								field,
								order,
							},
						}, () => { });
						let getNewCustomerListConnected = this.state.getNewCustomerListConnected.sort((a, b) => (a[field] < b[field] ? 1 : a[field] > b[field] ? -1 : 0));
						this.setState({
							getNewCustomerListConnected: getNewCustomerListConnected,
							isUIrender: !this.state.isUIrender
						});
					}
				}
			}
		} else if (this.state.tabIndex === 2) {
			if (this.state.getNotConnectedCustomerList) {
				if (!this.state.loadAllNonConn) {
					if (order === null || order === 'asc') {
						this.setState({
							isSortActive: 'desc',
							tableFilterNonConn: {
								field,
								order,
							},
						}, () => { });
						let payload = {
							pagenum: 1,
							sortby: field,
							sorttype: order
						}
						if (this.state.searchingNotConnectedCustomer !== '') {
							payload.search_key = this.state.filterByNotConnCus;
							payload.search_keyword = this.state.searchingNotConnectedCustomer;
						}
						this.props.fetchNonConnectedCustomers(payload, true);
						isNonConnectedCustomer = true;
						this.resetAllNonConnCustomers();
						this.setState({
							pageNumberNonConn: 1,
							isFetchNonConnect: true
						})
					} else {
						this.setState({
							isSortActive: 'asc',
							tableFilterNonConn: {
								field,
								order,
							},
						}, () => { });
						let payload = {
							pagenum: 1,
							sortby: field,
							sorttype: order
						}
						if (this.state.searchingNotConnectedCustomer !== '') {
							payload.search_key = this.state.filterByNotConnCus;
							payload.search_keyword = this.state.searchingNotConnectedCustomer;
						}
						this.props.fetchNonConnectedCustomers(payload, true);
						isNonConnectedCustomer = true;
						this.resetAllNonConnCustomers();
						this.setState({
							pageNumberNonConn: 1,
							isFetchNonConnect: true
						})
					}
				} else {
					if (order === null || order === 'asc') {
						this.setState({
							tableFilterNonConn: {
								field,
								order,
							},
						}, () => { });
						let getNotConnectedCustomerList = this.state.getNotConnectedCustomerList.sort((a, b) => (a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0));
						this.setState({
							getNotConnectedCustomerList: getNotConnectedCustomerList,
							isUIrender: !this.state.isUIrender
						});
					} else {
						this.setState({
							tableFilterNonConn: {
								field,
								order,
							},
						}, () => { });
						let getNotConnectedCustomerList = this.state.getNotConnectedCustomerList.sort((a, b) => (a[field] < b[field] ? 1 : a[field] > b[field] ? -1 : 0));
						this.setState({
							getNotConnectedCustomerList: getNotConnectedCustomerList,
							isUIrender: !this.state.isUIrender
						});
					}
				}
			}
		} else if (this.state.tabIndex === 3) {
			if (this.state.getDeletedCustomerList) {
				if (!this.state.loadAllDeleted) {
					if (order === null || order === 'asc') {
						this.setState({
							isSortActive: 'desc',
							tableFilterDeleted: {
								field,
								order,
							},
						}, () => { });
						let payload = {
							pagenum: 1,
							sortby: field,
							sorttype: order
						}
						if (this.state.searchingDeletedCustomer !== '') {
							payload.search_key = this.state.filterByDeleted;
							payload.search_keyword = this.state.searchingDeletedCustomer;
						}
						this.props.fetchDeletedCustomers(payload, true);
						isDeletedCustomer = true;
						this.resetAllDeletedCustomers();
						this.setState({
							pageNumberDelConn: 1,
							isFetchDeleted: true
						})
					} else {
						this.setState({
							isSortActive: 'asc',
							tableFilterDeleted: {
								field,
								order,
							},
						}, () => { });
						let payload = {
							pagenum: 1,
							sortby: field,
							sorttype: order
						}
						if (this.state.searchingDeletedCustomer !== '') {
							payload.search_key = this.state.filterByDeleted;
							payload.search_keyword = this.state.searchingDeletedCustomer;
						}
						this.props.fetchDeletedCustomers(payload, true);
						isDeletedCustomer = true;
						this.resetAllDeletedCustomers();
						this.setState({
							pageNumberDelConn: 1,
							isFetchDeleted: true
						})
					}
				} else {
					if (order === null || order === 'asc') {
						this.setState({
							tableFilterDeleted: {
								field,
								order,
							},
						}, () => { });
						let getDeletedCustomerList = this.state.getDeletedCustomerList.sort((a, b) => (a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0));
						this.setState({
							getDeletedCustomerList: getDeletedCustomerList,
							isUIrender: !this.state.isUIrender
						});
					} else {
						this.setState({
							tableFilterDeleted: {
								field,
								order,
							},
						}, () => { });
						let getDeletedCustomerList = this.state.getDeletedCustomerList.sort((a, b) => (a[field] < b[field] ? 1 : a[field] > b[field] ? -1 : 0));
						this.setState({
							getDeletedCustomerList: getDeletedCustomerList,
							isUIrender: !this.state.isUIrender
						});
					}
				}
			}
		}

	}
	handleClickSearch = () => {
		if (this.state.tabIndex === 0) {
			if (this.state.filterByActiveCus === 'email') {

				let payload = {
					pagenum: 1,
				}
				if (this.state.tableFilter.field !== null) {
					payload.sortby = this.state.tableFilter.field;
					payload.sorttype = this.state.tableFilter.order;
				}
				if (this.state.searchingActiveCustomer !== '') {
					payload.search_keyword = this.state.searchingActiveCustomer.toLowerCase().trim();
					payload.search_key = this.state.filterByActiveCus;
				}
				this.setState({pageNumber: 1});
				this.resetAllActiveCustomers();
				this.props.fetchAllCustomerList(payload, true);
				isActiveCustomer = true;
			} else {
				let payload = {
					pagenum: 1,
				}
				if (this.state.tableFilter.field !== null) {
					payload.sortby = this.state.tableFilter.field;
					payload.sorttype = this.state.tableFilter.order;
				}
				
				if (this.state.searchingActiveCustomer !== '') {
					payload.search_keyword = this.state.searchingActiveCustomer.toLowerCase().trim();
					if (this.state.filterByActiveCus !== null || this.state.filterByActiveCus !== '') {
						payload.search_key = this.state.filterByActiveCus;
					}
				}
				this.setState({pageNumber: 1})
				this.resetAllActiveCustomers();
				this.props.fetchAllCustomerList(payload, true);
				isActiveCustomer = true;
			}
		} else if (this.state.tabIndex === 1) {
			if (this.state.filterByConnCus === 'email') {
				let payload = {
					pagenum: 1,
				}
				if (this.state.tableFilterConn.field !== null) {
					payload.sortby = this.state.tableFilterConn.field;
					payload.sorttype = this.state.tableFilterConn.order;
				}
				
				if (this.state.searchingConnectedCust !== '') {
					payload.search_keyword = this.state.searchingConnectedCust.toLowerCase().trim();
					payload.search_key = this.state.filterByConnCus;
				}
				this.setState({pageNumberConn: 1});
				this.resetAllConnCustomers();
				this.props.fetchConnectedCustomers(payload, true);
				isConnectedCustomer = true;
			} else {
				let payload = {
					pagenum: 1,
				}
				if (this.state.tableFilterConn.field !== null) {
					payload.sortby = this.state.tableFilterConn.field;
					payload.sorttype = this.state.tableFilterConn.order;
				}
				
				if (this.state.searchingConnectedCust !== '') {
					payload.search_keyword = this.state.searchingConnectedCust;
					payload.search_key = this.state.filterByConnCus;
				}
				this.setState({pageNumberConn: 1});
				this.resetAllConnCustomers();
				this.props.fetchConnectedCustomers(payload, true);
				isConnectedCustomer = true;
			}
		} else if (this.state.tabIndex === 2) {
			if (this.state.filterByNotConnCus === 'email') {
				let payload = {
					pagenum: 1,
				}
				if (this.state.tableFilterNonConn.field !== null) {
					payload.sortby = this.state.tableFilterNonConn.field;
					payload.sorttype = this.state.tableFilterNonConn.order;
				}
				
				if (this.state.searchingNotConnectedCustomer !== '') {
					payload.search_keyword = this.state.searchingNotConnectedCustomer.toLowerCase().trim();
					payload.search_key = this.state.filterByNotConnCus;
				}
				this.setState({pageNumberNonConn: 1});
				this.resetAllNonConnCustomers();
				this.props.fetchNonConnectedCustomers(payload, true);
				isNonConnectedCustomer = true;
			} else {
				let payload = {
					pagenum: 1,
				}
				if (this.state.tableFilterNonConn.field !== null) {
					payload.sortby = this.state.tableFilterNonConn.field;
					payload.sorttype = this.state.tableFilterNonConn.order;
				}
				
				if (this.state.searchingNotConnectedCustomer !== '') {
					payload.search_keyword = this.state.searchingNotConnectedCustomer;
					payload.search_key = this.state.filterByNotConnCus;
				}
				this.setState({pageNumberNonConn: 1});
				this.resetAllNonConnCustomers();
				this.props.fetchNonConnectedCustomers(payload, true);
				isNonConnectedCustomer = true;
			}
		} else if (this.state.tabIndex === 3) {
			if (this.state.filterByDeleted === 'email') {
				let payload = {
					pagenum: 1,
				}
				if (this.state.tableFilterDeleted.field !== null) {
					payload.sortby = this.state.tableFilterDeleted.field;
					payload.sorttype = this.state.tableFilterDeleted.order;
				}
				
				if (this.state.searchingDeletedCustomer !== '') {
					payload.search_keyword = this.state.searchingDeletedCustomer.toLowerCase().trim();
					payload.search_key = this.state.filterByDeleted;
				}
				this.setState({pageNumberDelConn: 1});
				this.resetAllDeletedCustomers();
				this.props.fetchDeletedCustomers(payload, true);
				isDeletedCustomer = true;
			} else {
				let payload = {
					pagenum: 1,
				}
				if (this.state.tableFilterDeleted.field !== null) {
					payload.sortby = this.state.tableFilterDeleted.field;
					payload.sorttype = this.state.tableFilterDeleted.order;
				}
				
				if (this.state.searchingDeletedCustomer !== '') {
					payload.search_keyword = this.state.searchingDeletedCustomer;
					payload.search_key = this.state.filterByDeleted;
				}
				this.setState({pageNumberDelConn: 1});
				this.resetAllDeletedCustomers();
				this.props.fetchDeletedCustomers(payload, true);
				isDeletedCustomer = true;
			}
		}
	}
	doRefreshActiveCustomer = () => {
		if (this.state.searchingActiveCustomer === '') {
			let payload = {
				pagenum: 1,
			}
			if (this.state.tableFilter.field !== null) {
				payload.sortby = this.state.tableFilter.field;
				payload.sorttype = this.state.tableFilter.order;
			}
			this.setState({pageNumber: 1});
			this.resetAllActiveCustomers();
			this.props.fetchAllCustomerList(payload, true);
			isActiveCustomer = true;
		}
	}
	blurActiveCust = () => {
		this.setState({
			searchingActiveCustomer: ''
		}, () => {
			this.doRefreshActiveCustomer();
		})
	}
	doRefreshConnCustomer = () => {
		if (this.state.searchingConnectedCust === '') {
			let payload = {
				pagenum: 1,
			}
			if (this.state.tableFilterConn.field !== null) {
				payload.sortby = this.state.tableFilterConn.field;
				payload.sorttype = this.state.tableFilterConn.order;
			}
			this.setState({pageNumberConn: 1});
			this.resetAllConnCustomers();
			this.props.fetchConnectedCustomers(payload, true);
			isConnectedCustomer = true;
		}
	}
	blurConnCust = () => {
		this.setState({
			searchingConnectedCust: ''
		}, () => {
			this.doRefreshConnCustomer();
		})
	}
	doRefreshNonConnCustomer = () => {
		if (this.state.searchingNotConnectedCustomer === '') {
			let payload = {
				pagenum: 1,
			}
			if (this.state.tableFilterNonConn.field !== null) {
				payload.sortby = this.state.tableFilterNonConn.field;
				payload.sorttype = this.state.tableFilterNonConn.order;
			}
			this.setState({pageNumberNonConn: 1});
			this.resetAllNonConnCustomers();
			this.props.fetchNonConnectedCustomers(payload, true);
			isNonConnectedCustomer = true;
		}
	}
	blurNonConnCust = () => {
		this.doRefreshNonConnCustomer();
		this.setState({
			searchingNotConnectedCustomer: ''
		}, () => {
			this.doRefreshNonConnCustomer();
		})
	}

	doRefreshDeletedCustomer = () => {
		if (this.state.searchingDeletedCustomer === '') {
			let payload = {
				pagenum: 1,
			}
			if (this.state.tableFilterDeleted.field !== null) {
				payload.sortby = this.state.tableFilterDeleted.field;
				payload.sorttype = this.state.tableFilterDeleted.order;
			}
			this.setState({pageNumberDeleted: 1});
			this.resetAllDeletedCustomers();
			this.props.fetchDeletedCustomers(payload, true);
			isDeletedCustomer = true;
		}
	}
	blurDeletedCust = () => {
		this.doRefreshDeletedCustomer();
		this.setState({
			searchingDeletedCustomer: ''
		}, () => {
			this.doRefreshDeletedCustomer();
		})
	}
	
	loadMore = () => {
		this.fetchData();
	}
	loadMoreConn = () => {
		this.fetchDataConnected();
	}
	loadMoreNonConn = () => {
		this.fetchDataNonConn();
	}
	loadMoreDeleted = () => {
		this.fetchDataDeleted();
	}
	showAll = () => {
			let payload = {
				pagenum: -1
			}
			if (this.state.tableFilter.field !== null) {
				payload.sortby = this.state.tableFilter.field;
				payload.sorttype = this.state.tableFilter.order;
			}
			
			if (this.state.searchingActiveCustomer !== '') {
				payload.search_keyword = this.state.searchingActiveCustomer;
				payload.search_key = this.state.filterByActiveCus;
			}
			this.props.fetchAllCustomerList(payload, true);
			isActiveCustomer = true;
			this.setState({
				pageNumber: payload.pagenum,
				isFetchActive: false,
				loadAll:true
			})
	}
	showAllConn = () => {
			let payload = {
				pagenum: -1,
			}
			if (this.state.tableFilterConn.field !== null) {
				payload.sortby = this.state.tableFilterConn.field;
				payload.sorttype = this.state.tableFilterConn.order;
			}
			
			if (this.state.searchingConnectedCust !== '') {
				payload.search_keyword = this.state.searchingConnectedCust;
				payload.search_key = this.state.filterByConnCus;
			}
			this.props.fetchConnectedCustomers(payload, true);
			isConnectedCustomer = true;
			this.setState({
				pageNumberConn: payload.pagenum,
				isFetchConnect: false,
				loadAllConn:true
			})
	}
	showAllNonConn = () => {
			let payload = {
				pagenum: -1,
			}
			if (this.state.tableFilterNonConn.field !== null) {
				payload.sortby = this.state.tableFilterNonConn.field;
				payload.sorttype = this.state.tableFilterNonConn.order;
			}
			if (this.state.searchingNotConnectedCustomer !== '') {
				payload.search_keyword = this.state.searchingNotConnectedCustomer;
				payload.search_key = this.state.filterByNotConnCus;
			}
			this.props.fetchNonConnectedCustomers(payload, true);
			isNonConnectedCustomer = true;
			this.setState({
				pageNumberNonConn: payload.pagenum,
				isFetchNonConnect: false,
				loadAllNonConn:true
			})
	}
	showAllDeleted = () => {
			let payload = {
				pagenum: -1,
			}
			if (this.state.tableFilterDeleted.field !== null) {
				payload.sortby = this.state.tableFilterDeleted.field;
				payload.sorttype = this.state.tableFilterDeleted.order;
			}
			if (this.state.searchingDeletedCustomer !== '') {
				payload.search_keyword = this.state.searchingDeletedCustomer;
				payload.search_key = this.state.filterByDeleted;
			}
			this.props.fetchDeletedCustomers(payload, true);
			isDeletedCustomer = true;
			this.setState({
				pageNumberDelConn: payload.pagenum,
				isFetchDeleted: false,
				loadAllDeleted:true
			})
	}
	deleteHandleChange = (e) => {
		this.setState({
			deleteReason: e.target.value
		})
	}
	render() {
		const { tabIndex, isReadOnly, getNewCustomerListConnected, getNotConnectedCustomerList, getDeletedCustomerList, customerOrderList, allCustomersList, customerActivityList } = this.state;
		const selectedOptionsStyles = {
			color: "#3c763d",
			backgroundColor: "#dff0d8"
		}

		const optionsListStyles = {
			backgroundColor: "#8DC73F",
			color: "#fff"
		}

		let filterByStatus = [];

		if (customerOrderList) {

			for (let items of customerOrderList) {

				if (this.state.multiSelect[0].value && items.status === "active") {
					filterByStatus.push(items);
				}

				if (this.state.multiSelect[1].value && items.status === "closed") {
					filterByStatus.push(items);
				}
				if (this.state.multiSelect[2].value && items.status === "cancelled") {
					filterByStatus.push(items);
				}
				if (this.state.multiSelect[3].value && items.status === "effective") {
					filterByStatus.push(items);
				}
			}
		}
		const filteredData = this.state.searching ? filterByStatus.filter(row => row.name.toLowerCase().indexOf(this.state.searching.toLowerCase()) > -1) : filterByStatus;

		// const { filterByAllCus } = this.state;

		let customerFilterData = [];
		if (customerActivityList) {
			
			customerFilterData = customerActivityList.sort((a, b) => {
				var dateA = new Date(a.created_at), dateB = new Date(b.created_at);
				return dateB - dateA;
			});
		} else {
			customerFilterData = [];
		}

		if (allCustomersList) {
			for (const item of allCustomersList) {
				if (item.created_at) {
					item.created_at1 = dateTimeFormatConvert(item.created_at);
				} else {
					item.created_at1 = '';
				}
				if (item.marketing_brokerage == null) {
					item.marketing_brokerage = 'Not Available';
				}
			}
		}
		return (
			<Dashboard title="ClickIPO Customers" pageTitle="Customers" >
				<div className="customer_section_content">
					<div>
						<div className="row">
							<div  className="col-md-12" style={{ textAlign: 'right' }}>
								{
									(!isReadOnly) &&
									<Button variant="contained" color="primary" onClick={() => { this.openSendEmail(); }}>Send Email</Button>
								}
								{
									(!isReadOnly) &&
									<Button variant="contained" color="primary" style={{ marginLeft: 10 }} onClick={() => { this.openSendPush(); }}>Send Push Notification</Button>
								}
								<Button variant="contained" color="primary" style={{ marginLeft: 10 }} onClick={() => { this.openGraph(); }}>Graph</Button>
							</div>
						</div>
						
						<Tabs value={tabIndex} onChange={this.handleTabChange} style={{ padding: '20px 0px 0px 0px' }}>
							<Tab label="All Customers" />
							<Tab label="Connected Customers" />
							<Tab label="Not Connected Customers" />
							<Tab label="Deleted Customers" />
						</Tabs>
						{
							tabIndex === 0 &&
							<Typography component="div" style={{ paddingTop: 24, paddingBottom: 0 }} className="offering-box-conent">
								<div style={{ maxWidth: "100%" }}>
									<div className="row">
										<div className="col-md-5">
											<div className="totalCustomers">Total number of customers : {allCustomersList && <span> {allCustomersList.length}</span>}</div>
										</div>
										<div className="col-md-7">
											<div className=" customer-filters">
												<div className="filterbyContent">Search</div>
												<select className="selectField" value={this.state.filterByActiveCus} onChange={this.dropDownSearchByAllCus} style={{background: 'transparent'}} >
													<option value="first_name" >First Name</option>
													<option value="last_name" >Last Name</option>
													<option value="email" >Email</option>
													<option value="broker_dealer_name">Broker Dealer</option>
												</select>
												<TextField 
													id="searchInput" 
													type="text" 
													placeholder="Search" 
													value={this.state.searchingActiveCustomer} 
													onChange={this.searchCustomer} 
													style={{width: 250}}
													InputProps={{
														endAdornment: (
															<InputAdornment position="start">
																{
																	this.state.searchingActiveCustomer !== '' &&
																	<Button variant="contained" color="primary" style={{ borderRadius: '0px 4px 4px 0px', minWidth: 30, top: 19, right: 0, left: 8, height: 38, background: 'transparent', color: '#333', marginLeft: 0 }} onClick={() => this.blurActiveCust()}  ><CloseIcon className="close-icon-for-search" /></Button>
																}
																<Button variant="contained" color="primary" style={{borderRadius: '0px 4px 4px 0px', minWidth: 50, top: 19, right: 0, left: 8, height: 38, marginLeft: 0}} onClick={() => this.handleClickSearch()} disabled={this.state.searchingActiveCustomer === '' || this.state.searchingActiveCustomer === null ? true:false}><SearchIcon /></Button>
															</InputAdornment>
														)
													}}
												/>
												{allCustomersList && allCustomersList.length > 0 &&
													<Button variant="contained" color="primary" onClick={() => this.downLoadCSV(allCustomersList, 'allcustomers')}>Download CSV</Button>
												}
											</div>
										</div>
									</div>
									<div className="row newcustomerTableList">
										<div className="col-12 tableheight" style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 0 }}>
											<div className="table-responsive custom-customer-table">
												<GridTable
													columns={this.state.cols}
													rows={allCustomersList}
													isPaginated={false}
													isVirtualScroll={true}
													showSearch={false}
													showRowsInformation={false}
													isHeaderSticky={true}
												/>
												<div className="col-md-12 mt-2">
													{
														(this.state.newActiveCustomer === this.state.activeCustomerLength) && !this.state.loadAll ?
														<Button variant="contained" color="primary" onClick={() => this.loadMore()} style={{ marginRight: 8, marginBottom: 8 }} >Load more</Button>
														:
														<Button variant="contained" color="primary" style={{ marginRight: 8, marginBottom: 8 }} disabled={true}>No more data</Button>
													}
													<Button variant="contained" color="primary" style={{ marginBottom: 8 }} onClick={() => this.showAll()} disabled={this.state.loadAll}>Load all</Button>

												</div>
											</div>
										</div>
									</div>
								</div>
							</Typography>
						}
						{
							tabIndex === 1 &&
							<Typography component="div" style={{ paddingTop: 24, paddingBottom: 24 }} className="offering-box-conent">
								<div style={{ maxWidth: "100%" }}>
									<div className="row">
										<div className="col-md-5">
											<div className="totalCustomers">Total number of customers : {getNewCustomerListConnected && <span> {getNewCustomerListConnected.length}</span>}</div>
										</div>
										<div className="col-md-7">
											<div className="customer-filters">
												<div className="filterbyContent">Search</div>
												<select className="selectField" value={this.state.filterByConnCus} onChange={this.dropDownSearchByConnCus} style={{background: 'transparent'}} >
													<option value="first_name" >First Name</option>
													<option value="last_name" >Last Name</option>
													<option value="email" >Email</option>
													<option value="broker_dealer_name">Broker Dealer</option>
												</select>
												<TextField 
													id="searchInput" 
													type="text" 
													placeholder="Search" 
													onChange={this.searchConnectedCustomer} 
													value={this.state.searchingConnectedCust}
													style={{width: 250}}
													InputProps={{
														endAdornment: (
															<InputAdornment position="start">
																{
																	this.state.searchingConnectedCust !== '' &&
																	<Button variant="contained" color="primary" style={{ borderRadius: '0px 4px 4px 0px', minWidth: 30, top: 19, right: 0, left: 8, height: 38, background: 'transparent', color: '#333', marginLeft: 0 }} onClick={() => this.blurConnCust()}  ><CloseIcon className="close-icon-for-search" /></Button>
																}
																<Button variant="contained" color="primary" style={{borderRadius: '0px 4px 4px 0px', minWidth: 50, top: 19, right: 0, left: 8, height: 38, marginLeft: 0}} onClick={() => this.handleClickSearch()} disabled={this.state.searchingConnectedCust === '' || this.state.searchingConnectedCust === null ? true:false}><SearchIcon /></Button>
															</InputAdornment>
														)
													}}
												/>
												{getNewCustomerListConnected && getNewCustomerListConnected.length > 0 &&
													<Button variant="contained" color="primary" onClick={() => this.downLoadCSV(getNewCustomerListConnected, 'connectedcustomer')}>Download CSV</Button>}
											</div>
										</div>
									</div>
									<div className="row newcustomerTableList">
										<div className="col-12 tableheight" style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 15 }}>
											<div className="table-responsive custom-customer-table">
												
												<GridTable
													columns={this.state.colsConn}
													rows={getNewCustomerListConnected}
													isPaginated={false}
													isVirtualScroll={true}
													showSearch={false}
													showRowsInformation={false}
													isHeaderSticky={true}
												/>
												<div className="col-md-12 mt-2">
													{
														(this.state.newConnectedCustomer === this.state.connectedCustomerLength) && !this.state.loadAllConn ?
														<Button variant="contained" color="primary" onClick={() => this.loadMoreConn()} style={{ marginRight: 8, marginBottom: 8 }}>Load more</Button>
														:
														<Button variant="contained" color="primary" style={{ marginRight: 8, marginBottom: 8 }} disabled={true}>No more data</Button>
													}
													<Button variant="contained" color="primary" style={{ marginBottom: 8 }} onClick={() => this.showAllConn()} disabled={this.state.loadAllConn}>Load all</Button>

												</div>
											</div>
										</div>
									</div>
								</div>
							</Typography>
						}
						{
							tabIndex === 2 &&
							<Typography component="div" style={{ paddingTop: 24, paddingBottom: 24 }} className="offering-box-conent">
								<div style={{ maxWidth: "100%" }}>
									<div className="row">
										<div className="col-md-5">
											<div className="totalCustomers">Total number of customers : {getNotConnectedCustomerList && <span> {getNotConnectedCustomerList.length}</span>}</div>
										</div>
										<div className="col-md-7">
											<div className="customer-filters">
												<div className="filterbyContent">Search</div>
												<select className="selectField" value={this.state.filterByNotConnCus} onChange={this.dropDownSearchByNotConnCus} style={{background: 'transparent'}} >
													<option value="first_name" >First Name</option>
													<option value="last_name" >Last Name</option>
													<option value="email" >Email</option>
													<option value="broker_dealer_name">Broker Dealer</option>
												</select>
												<TextField 
													id="searchInput" 
													type="text" 
													placeholder="Search" 
													onChange={this.searchNotConnectedCustomer} 
													value={this.state.searchingNotConnectedCustomer}
													style={{width: 250}}
													InputProps={{
														endAdornment: (
															<InputAdornment position="start">
																{
																	this.state.searchingNotConnectedCustomer !== '' &&
																	<Button variant="contained" color="primary" style={{ borderRadius: '0px 4px 4px 0px', minWidth: 30, top: 19, right: 0, left: 8, height: 38, background: 'transparent', color: '#333', marginLeft: 0 }} onClick={() => this.blurNonConnCust()}  ><CloseIcon className="close-icon-for-search" /></Button>
																}
																<Button variant="contained" color="primary" style={{borderRadius: '0px 4px 4px 0px', minWidth: 50, top: 19, right: 0, left: 8, height: 38, marginLeft: 0}} onClick={() => this.handleClickSearch()} disabled={this.state.searchingNotConnectedCustomer === '' || this.state.searchingNotConnectedCustomer === null ? true:false}><SearchIcon /></Button>
															</InputAdornment>
														)
													}}
												/>
												{getNotConnectedCustomerList && getNotConnectedCustomerList.length > 0 &&
													<Button variant="contained" color="primary" onClick={() => this.downLoadCSV(getNotConnectedCustomerList, 'connectedcustomer')}>Download CSV</Button>}
											</div>
										</div>
									</div>
									<div className="row newcustomerTableList">
										<div className="col-12 tableheight" style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 15 }}>
											<div className="table-responsive custom-customer-table">
												
												<GridTable
													columns={this.state.colsNonConn}
													rows={getNotConnectedCustomerList}
													isPaginated={false}
													isVirtualScroll={true}
													showSearch={false}
													showRowsInformation={false}
													isHeaderSticky={true}
												/>
												<div className="col-md-12 mt-2">
													{
														(this.state.newNonConnectedCustomer === this.state.nonConnectedCustomerLength) && !this.state.loadAllNonConn ?
														<Button variant="contained" color="primary" onClick={() => this.loadMoreNonConn()} style={{ marginRight: 8, marginBottom: 8 }}>Load more</Button>
														:
														<Button variant="contained" color="primary" style={{ marginRight: 8, marginBottom: 8 }} disabled={true}>No more data</Button>
													}
													<Button variant="contained" color="primary" style={{ marginBottom: 8 }} onClick={() => this.showAllNonConn()} disabled={this.state.loadAllNonConn}>Load all</Button>

												</div>
											</div>
										</div>
									</div>
								</div>
							</Typography>
						}
						{
							tabIndex === 3 &&
							<Typography component="div" style={{ paddingTop: 24, paddingBottom: 24 }} className="offering-box-conent">
								<div style={{ maxWidth: "100%" }}>
									<div className="row">
										<div className="col-md-5">
											<div className="totalCustomers">Total number of customers : {getDeletedCustomerList && <span> {getDeletedCustomerList.length}</span>}</div>
										</div>
										<div className="col-md-7">
											<div className="customer-filters">
												<div className="filterbyContent">Search</div>
												<select className="selectField" value={this.state.filterByNotConnCus} onChange={this.dropDownSearchByDeletedCus} style={{background: 'transparent'}} >
													<option value="first_name" >First Name</option>
													<option value="last_name" >Last Name</option>
													<option value="email" >Email</option>
													<option value="broker_dealer_name">Broker Dealer</option>
												</select>
												<TextField 
													id="searchInput" 
													type="text" 
													placeholder="Search" 
													onChange={this.searchDeletedCustomer} 
													value={this.state.searchingDeletedCustomer}
													style={{width: 250}}
													InputProps={{
														endAdornment: (
															<InputAdornment position="start">
																{
																	this.state.searchingDeletedCustomer !== '' &&
																	<Button variant="contained" color="primary" style={{ borderRadius: '0px 4px 4px 0px', minWidth: 30, top: 19, right: 0, left: 8, height: 38, background: 'transparent', color: '#333', marginLeft: 0 }} onClick={() => this.blurDeletedCust()}  ><CloseIcon className="close-icon-for-search" /></Button>
																}
																<Button variant="contained" color="primary" style={{borderRadius: '0px 4px 4px 0px', minWidth: 50, top: 19, right: 0, left: 8, height: 38, marginLeft: 0}} onClick={() => this.handleClickSearch()} disabled={this.state.searchingDeletedCustomer === '' || this.state.searchingDeletedCustomer === null ? true:false}><SearchIcon /></Button>
															</InputAdornment>
														)
													}}
												/>
												{getNotConnectedCustomerList && getNotConnectedCustomerList.length > 0 &&
													<Button variant="contained" color="primary" onClick={() => this.downLoadCSV(getNotConnectedCustomerList, 'connectedcustomer')}>Download CSV</Button>}
											</div>
										</div>
									</div>
									<div className="row newcustomerTableList">
										<div className="col-12 tableheight" style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 15 }}>
											<div className="table-responsive custom-customer-table">
												
												<GridTable
													columns={this.state.colsDel}
													rows={getDeletedCustomerList}
													isPaginated={false}
													isVirtualScroll={true}
													showSearch={false}
													showRowsInformation={false}
													isHeaderSticky={true}
												/>
												<div className="col-md-12 mt-2">
													{
														(this.state.newDeletedCustomer === this.state.deletedCustomerLength) && !this.state.loadAllDeleted ?
														<Button variant="contained" color="primary" onClick={() => this.loadMoreDeleted()} style={{ marginRight: 8, marginBottom: 8 }}>Load more</Button>
														:
														<Button variant="contained" color="primary" style={{ marginRight: 8, marginBottom: 8 }} disabled={true}>No more data</Button>
													}
													<Button variant="contained" color="primary" style={{ marginBottom: 8 }} onClick={() => this.showAllDeleted()} disabled={this.state.loadAllDeleted}>Load all</Button>

												</div>
											</div>
										</div>
									</div>
								</div>
							</Typography>
						}
						
						<NotificationModal notificationType={this.state.notificationType} notificationshow={this.state.notificationshow} totalCustomer={allCustomersList} onClose={this.toggleNotificationModal} customerListing={this.state.selectedCustomerList}></NotificationModal>
						<ConfirmationModal confirmationType={this.state.confirmationType} show={this.state.confirmModal} onClose={this.confirmModaltoggle} customerInfo={this.state.customerInfo} resetPasswordClick={this.doResetPasswordClick} deleteCustomer={(email, reason) => this.doDeleteCustomer(email, reason)} reasons={this.state.deleteReason} deleteHandleChange={(e) => this.deleteHandleChange(e)}></ConfirmationModal>
						<Dialog
							onClose={this.closeEditModal}
							aria-labelledby="customized-dialog-title"
							open={this.state.openUserEditModal}
							maxWidth={"sm"}
							fullWidth={true}
							scroll={'paper'}
							>
							<DialogTitle onClose={this.closeEditModal} className="offering-detail-title">Update Customer</DialogTitle>
							<DialogContent>
								<div className="col-md-12">
									<div className="col-12" style={{ marginTop: '10px' }}>
										<div className="col-12" style={{ padding: '20px 20px 20px 20px' }}>
											<TextField type="text" fullWidth label="Email" value={this.state.userDetail.email} placeholder="" disabled />
										</div>
										<div className="col-12" style={{ padding: '20px 20px 20px 20px' }}>
											<TextField type="text" fullWidth name="firstName" label="First Name" defaultValue={this.state.userDetail.first_name} placeholder="" onChange={(e) => this.handleAllChanges(e)} onMouseDown={e => e.stopPropagation()} />
											{
												this.state.errorMessageFirstName !== '' &&
												<span style={{ color: 'red', fontSize: 16, marginTop: 5 }}>{this.state.errorMessageFirstName}</span>
											}
										</div>
										<div className="col-12" style={{ padding: '20px 20px 20px 20px' }}>
											<TextField type="text" fullWidth name="lastName" label="Last Name" defaultValue={this.state.userDetail.last_name} placeholder="" onChange={(e) => this.handleAllChanges(e)} onMouseDown={e => e.stopPropagation()} />
											{
												this.state.errorMessageFirstName !== '' &&
												<span style={{ color: 'red', fontSize: 16, marginTop: 5 }}>{this.state.errorMessageLastName}</span>
											}
										</div>
									</div>
								</div>
							</DialogContent>
								<DialogActions style={{ display: 'block' }}>
									<div style={{ textAlign: 'center' }}>
										<Button style={{ marginLeft: 10, marginRight: 10 }} variant="contained" size="large" color="primary" onClick={() => this.updateCustomer()}> Save </Button>
										<Button style={{ marginLeft: 10, marginRight: 10 }} variant="contained" size="large" color="primary" onClick={this.openResetPasswordModal} >Rest Password</Button>
									</div>
								</DialogActions>
						</Dialog>
						<Dialog
							onClose={this.closeActivityModal}
							aria-labelledby="customized-dialog-title"
							open={this.state.openActivityModal}
							maxWidth={"md"}
							fullWidth={true}
							scroll={'paper'}
							>
							<DialogTitle onClose={this.closeActivityModal} className="offering-detail-title">{this.state.userDetail.email} ({this.state.userDetail.first_name} {this.state.userDetail.last_name})</DialogTitle>
							<DialogContent>
								<div className="col-md-12 activity-list-table" style={{ marginTop: '16px' }}>
									<div >
										<GridTable
											columns={this.state.activityCols}
											rows={customerFilterData}
											isPaginated={true}
											isVirtualScroll={true}
											showSearch={true}
											showRowsInformation={false}
											isHeaderSticky={true}
										/>
									</div>
								</div>
							</DialogContent>
						</Dialog>
						<Dialog
							onClose={this.closeOrderInfoModal}
							aria-labelledby="customized-dialog-title"
							open={this.state.openOrderInfoModal}
							maxWidth={"xl"}
							fullWidth={true}
							scroll={'paper'}
							>
							<DialogTitle onClose={this.closeOrderInfoModal} className="offering-detail-title">Order Information</DialogTitle>
							<DialogContent>
								<div className="col-md-12">
									<div className="activity-modal-body" style={{ height: 750 }}>
										<div className="row">
											<div className="col-md-6">
												<div className="row">
													<div className="col-md-3">
														<div className="activity-customers">Email :</div>
													</div>
													<div className="col-md-9">
														<div className="activity-customers"><span>{this.state.userDetail.email}</span></div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-3">
														<div className="activity-customers">User Created:</div>
													</div>
													<div className="col-md-9">
														<div className="activity-customers"><span>{this.state.userDetail.created_at}</span></div>
													</div>
												</div>
											</div>
											<div className="col-md-3">

												<MultiSelectReact
													options={this.state.multiSelect}
													optionClicked={this.optionClicked.bind(this)}
													selectedBadgeClicked={this.selectedBadgeClicked.bind(this)}
													selectedOptionsStyles={selectedOptionsStyles}
													optionsListStyles={optionsListStyles}
												/>
											</div>
											<div className="col-md-3">

												<div>
													<TextField id="searchInput" type="text" placeholder="Search" onChange={(e) => this.setState({globalFilterOrder: e.target.value})}/>
												</div>
											</div>
										</div>
										<div className="customer-orderinfo-table" style={{ marginTop: '30px' }}>
											<GridTable
												columns={this.state.orderInfoCols}
												rows={filteredData}
												isPaginated={true}
												isVirtualScroll={true}
												showSearch={true}
												showRowsInformation={false}
												isHeaderSticky={true}
											/>
										</div>
									</div>
								</div>
							</DialogContent>
						</Dialog>
					</div>
				</div>
			</Dashboard>
		)
	}
}

CustomersComponent.propTypes = {
	fetchCustomerListRes: PropTypes.any,
	fetchConnectedCustomerListRes: PropTypes.any,
	fetchNonConnectedCustomerListRes: PropTypes.any,
	fetchCustomerActivityRes: PropTypes.any,
	fetchCustomerOrderInfoRes: PropTypes.any,
	customerResetPassRes: PropTypes.any,
	deleteCustomerRes: PropTypes.any,
	updateCustomerRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	fetchCustomerListRes: doFetchCustomerListRes,
	fetchConnectedCustomerListRes: doFetchConnectedCustomerListRes,
	fetchNonConnectedCustomerListRes: doFetchNonConnectedCustomerListRes,
	fetchDeletedCustomerListRes: doFetchDeletedCustomerListRes,
	fetchCustomerActivityRes: doFetchCustomerActivityRes,
	fetchCustomerOrderInfoRes: doFetchCustomerOrderInfoRes,
	customerResetPassRes: doCustomerResetPassRes,
	deleteCustomerRes: doDeleteCustomerRes,
	updateCustomerRes: doUpdateCustomerRes,
});

function mapDispatchToProps(dispatch) {
	return {
		fetchAllCustomerList: (data, isLoad) => dispatch(fetchAllCustomers(data, isLoad)),
		fetchConnectedCustomers: (data, isLoad) => dispatch(fetchConnectedCustomers(data, isLoad)),
		fetchNonConnectedCustomers: (data, isLoad) => dispatch(fetchNonConnectedCustomers(data, isLoad)),
		fetchDeletedCustomers: (data, isLoad) => dispatch(fetchDeletedCustomers(data, isLoad)),
		getActivityList: (email) => dispatch(fetchCustomerActivity(email)),
		getActivityListNull: (data) => dispatch(doFetchCustomerActivityRes(data)),
		getOrderInfoList: (email) => dispatch(fetchCustomerOrderInfo(email)),
		fetchCustomerOrderInfoSuccess: (data) => dispatch(doFetchCustomerOrderInfoRes(data)),
		sendCustomerResetPassword: (email) => dispatch(sendCustomerResetPassword(email)),
		submitDeleteCustomer: (data) => dispatch(submitDeleteCustomer(data)),
		updateUserData: (data) => dispatch(sendUpdateCustomer(data)),
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CustomersComponent);
