import * as types from './actionTypes';  
import updateConfigApi from '../api/updateConfigApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doUpdateConfig(request) {

  return {
    type: types.DO_UPDATE_CONFIG,
    request
    
  };
}

export function doUpdateConfigRes(data) {  
  return {
    type: types.DO_UPDATE_CONFIG_RES, 
    data
  };
}

export function updateConfig(request) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      updateConfigApi.doUpdateConfig(request).then(data => {
        dispatch(doUpdateConfigRes(data));
        dispatch(doUpdateConfigRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
  
}