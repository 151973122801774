
export default function validate(values) {
  const errors = {};
  if(values.offering_type_name === 'IPO' || values.offering_type_name === 'Follow-on' || values.offering_type_name === 'Overnight') {

  
    if (!values.name) {
      errors.name = 'Please enter name';
    }
    if (!values.ticker_symbol) {
      errors.ticker_symbol = 'Please enter ticker symbol';
    }
    if (!values.ext_id) {
      errors.ext_id = 'Please enter file/ext id';
    }
    if (values.trade_date) {
      if (values.date_tbd !== true) {
        // var errorVal = moment(values.trade_date, 'MM/DD/YYYY hh:mm:ss').isValid();
        var pattern = /([0-9]{4})([-]{1})(([0-9]{1}[0-9]{1})|([1-9]{1}))([-]{1})(([0-9]{1}[0-9]{1})|([1-9]{1})[ ]{1}([0-9]{2}[:]{1}[0-9]{2}[:]{1}[0-9]{2}))|([TBD]{3})/
        if (!pattern.test(values.trade_date)) {
          errors.trade_date = 'Please enter correct date and time ';
        }
      }
    } else {
      if (values.date_tbd === false && values.trade_date === '') {
        errors.trade_date = 'Please enter correct date and time Or select TBD';
      }
    }
    if (values.settlement_date) {
      if (values.date_tbd !== true) {
        var patternnn = /([0-9]{4})([-]{1})(([0-9]{1}[0-9]{1})|([1-9]{1}))([-]{1})(([0-9]{1}[0-9]{1})|([1-9]{1})[ ]{1}([0-9]{2}[:]{1}[0-9]{2}[:]{1}[0-9]{2}))|([TBD]{3})/
        if (!patternnn.test(values.settlement_date)) {
          errors.settlement_date = 'Please enter correct date and time or TBD';
        }
      }
    } else {
      if (values.date_tbd === false) {
        errors.settlement_date = 'Please enter correct date and time Or select TBD';
      }
    }
    if (!values.description) {
      errors.description = 'Please enter description';
    }
    if (!values.industry) {
      errors.industry = 'Please select category';
    }
    if (!values.bd_underwriter_mpid_id) {
      errors.bd_underwriter_mpid_id = 'Please select underwriter MPID';
    }
    if (!values.underwriter_concession_amount) {
      errors.underwriter_concession_amount = 'Please enter underwriter concession amount';
    }
    
    if (!values.prospectus_url) {
      errors.prospectus_url = 'Please enter prospectus url';
    } else if (values.prospectus_url) {
      var patternPro = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/
      if(!patternPro.test(values.prospectus_url)){
        errors.prospectus_url = 'Please enter valid prospectus url';
      }
      if(values.prospectus_url.indexOf(' ') >= 0) {
        errors.prospectus_url = 'Space is not allowed please retry again';
      }
      
    }

    if (values.brochure_url) {
      var patternBro = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/
      if(!patternBro.test(values.brochure_url)){
        errors.brochure_url = 'Please enter valid brochure url';
      }
      if (values.showUrlField === true) {
        if(values.brochure_url.indexOf(' ') >= 0) {
          errors.brochure_url = 'Space is not allowed please retry again';
        }
      }
    }

    // if (!values.final_shares) {
    //   errors.final_sharesErr = 'Please enter listed shares';
    // }
    if (values.offering_type_name === 'IPO') {
      if ((values.isMaxPriceChecked === false && values.max_price === '')) {
        errors.max_price = 'Please enter max Price Or TBD';
      }
      else if ((values.isMinPriceChecked === false && values.min_price === '')) {
        errors.min_price = 'Please enter min Price Or TBD';
      }
      else if ((values.isMaxPriceChecked === false &&  values.isMinPriceChecked === false)) {
        if ( parseFloat(values.max_price) < parseFloat(values.min_price)) {
         errors.max_price = 'Please enter Max Price greater than Min Price';
        }
      }

    }
    if ((values.isListedChecked === false && values.final_shares === '')) {
      errors.final_sharesErr = 'Please enter listed shares/ Or TBD';
    }
    if (values.exchange_value_name === '') {
      errors.exchange_value_nameErr = 'Please select exchange';
    }
    if (values.offering_type_name === 'Overnight') {
      if (values.activeWindow === '') {
        errors.activeWindow = 'Please enter Active Window';
      }
    }
    if (values.offering_type_name === 'Overnight') {
      if (values.follow_min_price === '') {
        errors.follow_min_price = 'Please enter min Price';
      }
    }
    if (values.offering_type_name === 'Overnight') {
      if (values.follow_max_price === '') {
        errors.follow_max_price = 'Please enter max Price';
      }
    }
  }
  if(values.offering_type_name === 'IPO' || values.offering_type_name === 'Follow-on') {
     if (values.isDsp === true || values.isDirectedShare === true) {
       if (values.dsp_attestation_file === '') {
         errors.dsp_attestation_file = 'Please choose attestation file';
       }
     }
  }
  if (values.offering_type_name === 'Notes') {
    if (values.issuer === '') {
      errors.issuer = 'Please enter issuer';
    }
    if (values.security === '') {
      errors.security = 'Please enter security';
    }
    if (values.exchange === '') {
      errors.exchange = 'Please enter exchange';
    }
    if (values.base_deal_size === '') {
      errors.base_deal_size = 'Please enter base deal size';
    }
    if (values.denominations === '') {
      errors.denominations = 'Please enter denominations';
    }
    if (values.lead_underwriter === '') {
      errors.lead_underwriter = 'Please select lead underwriter';
    }
  }
  var tick_pattern = (values.ordr_dollar_share === 1) ? new RegExp('^[0-9]') : new RegExp('^[0-9]+(.[0-9][0-9]?)?')
  //if (values.ordr_dollar_share === 1) {
    // if (values.min_ticket_size <= 0) {
    //   errors.min_ticket_size = 'Please enter min ticket size ';
    // }
    // else {
      //var reg = new RegExp('^[0-9]+$');
      if (values.ordr_dollar_share === 0 && values.min_ticket_size) {
        if (!tick_pattern.test(values.min_ticket_size)) {
          errors.min_ticket_size = 'Please enter correct number';
        }
      } else if (values.ordr_dollar_share === 1 && values.min_ticket_size) {
        if (!tick_pattern.test(values.min_ticket_size)) {
          errors.min_ticket_size = 'Please enter without decimal number';
        }
      }
    // }
    // if (values.max_ticket_size <= 0) {
    //   errors.max_ticket_size = 'Please enter max ticket size ';
    // }
    // else {
      //var reg1 = new RegExp('^[0-9]+$');
      if (values.ordr_dollar_share === 0 && values.max_ticket_size) {
        if (!tick_pattern.test(values.max_ticket_size)) {
          errors.max_ticket_size = 'Please enter correct number';
        }
      } else if (values.ordr_dollar_share === 1 && values.max_ticket_size) {
        if (!tick_pattern.test(values.max_ticket_size)) {
          errors.max_ticket_size = 'Please enter without decimal number';
        }
      }
    // }
    // if (Number(values.min_ticket_size) >  Number(values.max_ticket_size)) {
    //   errors.min_ticket_size = 'Please enter min ticket size less than max ticket size';
    // }
  //}
  if (values.available_to_order === '') {
    errors.available_to_order = "Please select availability option";
  }
  else {
      if ((values.date_tbd === true) || (values.isListedChecked === true) ||
          (values.isMinPriceChecked === true) || (values.isMaxPriceChecked === true)) {
            if (values.available_to_order === 1) {
                 errors.available_to_order = 'Please select the right option for Availability.'
          }
      }
  }
  // if (values.dsp === 2) {
  //   if (values.ioi_cutoff_datetime === '') {
  //     errors.ioi_cutoff_datetime = 'Please enter ioi cutoff datetime';
  //   }
  // }
  if (!values.requiredCheckboxOne) {
    errors.allCheckBoxedRequired = 'Please Checked all checkboxes';
  } else if (!values.requiredCheckboxTwo) {
    errors.allCheckBoxedRequired = 'Please Checked all checkboxes';
  } else if (!values.requiredCheckboxThree) {
    errors.allCheckBoxedRequired = 'Please Checked all checkboxes';
  } else if (!values.requiredCheckboxFour) {
    errors.allCheckBoxedRequired = 'Please Checked all checkboxes';
  } else if (!values.requiredCheckboxFive) {
    errors.allCheckBoxedRequired = 'Please Checked all checkboxes';
  } else if (!values.requiredCheckboxSix) {
    errors.allCheckBoxedRequired = 'Please Checked all checkboxes';
  } else if (!values.requiredCheckboxSeven) {
    errors.allCheckBoxedRequired = 'Please Checked all checkboxes';
  }
  return errors;
}
