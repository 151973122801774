import * as types from './actionTypes';  
import showAllocationMetaDataApi from '../api/showAllocationMetaDataApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doFetchShowAllocationMetadata(ext_id) {

  return {
    type: types.DO_FETCH_SHOW_ALLOCATION_METADATA,
    ext_id
  };
}

export function doFetchShowAllocationMetadataRes(data) {  
  return {
    type: types.DO_FETCH_SHOW_ALLOCATION_METADATA_RES, 
    data
  };
}
export function fetchShowAllocationMetaData(ext_id) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      showAllocationMetaDataApi.doFetchShowAllocationMetadata(ext_id).then(data => {
        dispatch(doFetchShowAllocationMetadataRes(data));
        dispatch(doFetchShowAllocationMetadataRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
  
}