import * as types from './actionTypes';  
import offeringCallBacksApi from '../api/offeringCallBacksApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function fetchOfferingCallBacks(request) {

  return {
    type: types.FETCH_OFFERING_CALLBACKS, 
    request
  };
}

export function fetchOfferingCallBacksRes(data) {  
  return {
    type: types.FETCH_OFFERING_CALLBACKS_RES, 
    data
  };
}

export function getOfferingCallBacks(request) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      offeringCallBacksApi.fetchOfferingCallBacks(request).then(data => {
        dispatch(fetchOfferingCallBacksRes(data));
        dispatch(fetchOfferingCallBacksRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
  
}