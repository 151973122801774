import React from 'react'
import logoImg from '../assets/images/clickipo_logo.png';
import LazyLoad from 'react-lazy-load';
import { logout } from '../helper/helper';
import Button from '@material-ui/core/Button';


export default function TokenError() {
    return (
        <div style={{backgroundColor: '#C5E29D', height:'100%', width: '100%'}}>
            <div style={{marginLeft:10}}>
                <LazyLoad offsetVertical={300} offset={500} offsetTop={200} offsetHorizontal={50} debounce={true}>
                    <img src={logoImg}  alt={'clickipo'} />
                </LazyLoad>
            </div>
            <div style={{textAlign:'center', width: 360, margin: '7% auto' }} >
                <p style={{color: '#245776', fontSize: 130, fontWeight: 600}}>401</p>
                <h1 style={{color: '#245776', fontSize: 35, }}>Invalid User Token</h1> 
                <Button variant="contained" color="primary" onClick={logout}>Go to login</Button>
           </div>
           {/* <div style={{backgroundColor: '#C5E29D', height:'450px', width: '100%'}}></div> */}
        </div>
    )
}
