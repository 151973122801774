import { combineReducers } from 'redux';
import userReducer from './userReducer';
import loadingReducer from './loadingReducer';
import { reducer as formReducer } from 'redux-form';
import changePasswordReducer from './changePasswordReducer';
import resetPasswordReducer from './resetPasswordReducer';
import offeringActiveReducer from './offeringActiveReducer';
import offeringEffectiveReducer from './offeringEffectiveReducer';
import offeringCancelledReducer from './offeringCancelledReducer';
import offeringClosedReducer from './offeringClosedReducer';
import forgotPasswordReducer from './forgotPasswordReducer';
import offeringUserInfoReducer from './offeringUserInfoReducer';
import brokerDealerListReducer from './brokerDealerListReducer';
import underWriterMpidReducer from './underWriterMpidReducer';
import offeringIndustriesReducer from './offeringIndustriesReducer';
import createOfferingReducer from './createOfferingReducer';
import lastClosingPriceReducer from './lastClosingPriceReducer';
import offeringDetailReducer from './offeringDetailReducer';
import logoUploadForOfferingReducer from './logoUploadForOfferingReducer';
import brochureUrlReducer from './brochureUrlReducer';
import attestationUrlReducer from './attestationUrlReducer';
import faqUrlReducer from './faqUrlReducer';
import updateOfferingReducer from './updateOfferingReducer';
import notifyUserReducer from './notifyUserReducer';
import dspUsersReducer from './dspUsersReducer';
import sixtyminuteEmailReducer from './sixtyminuteEmailReducer';
import archiveOfferingReducer from './archiveOfferingReducer';
import unarchiveOfferingReducer from './unarchiveOfferingReducer';
import updateOfferingReconfirmRequestReducer from './updateOfferingReconfirmRequestReducer';
import updateProspectusReducer from './updateProspectusReducer';
import ratingListReducer from './ratingListReducer';
import setUnderwriterReducer from './setUnderwriterReducer';
import currentCallReducer from './currentCallReducer';
import offeringOrdersReducer from './offeringOrdersReducer';
import followerListReducer from './followerListReducer';
import updateRequestedAmountReducer from './updateRequestedAmountReducer';
import deleteOrderReducer from './deleteOrderReducer';
import sendNotificationReducer from './sendNotificationReducer';
import userStatsReducer from './userStatsReducer';
import offeringStatsReducer from './offeringStatsReducer';
import allOfferingsReducer from './allOfferingsReducer';
import bizInsightsListReducer from './bizInsightsListReducer';
import statsFollowerListReducer from './statsFollowerListReducer';
import statsOfferingOrdersReducer from './statsOfferingOrdersReducer';
import adminUnderWriterMpidReducer from './adminUnderWriterMpidReducer';
import brokerDealerAdminsReducer from './brokerDealerAdminsReducer';
import createAdminReducer from './createAdminReducer';
import underwriterPerformanceReducer from './underwriterPerformanceReducer';
import configListReducer from './configListReducer';
import updateConfigReducer from './updateConfigReducer';
import messageAddReducer from './messageAddReducer';
import messageListReducer from './messageListReducer';
import messageUpdateReducer from './messageUpdateReducer';
import messageDetailReducer from './messageDetailReducer';
import createBrokerdealerReducer from './createBrokerdealerReducer';
import createUnderwriterReducer from './createUnderwriterReducer';
import customerListReducer from './customerListReducer';
import customerConnectedListReducer from './customerConnectedListReducer';
import customerNonConnectedListReducer from './customerNonConnectedListReducer';
import customerDeletedListReducer from './customerDeletedListReducer';
import customerActivityReducer from './customerActivityReducer';
import customerOrderInfoReducer from './customerOrderInfoReducer';
import customerResetPassReducer from './customerResetPassReducer';
import deleteCustomerReducer from './deleteCustomerReducer';
import updateCustomerReducer from './updateCustomerReducer';
import allocationCompletedListReducer from './allocationCompletedListReducer';
import allocationPendingListReducer from './allocationPendingListReducer';
import createAllocationReducer from './createAllocationReducer';
import deleteAllocationReducer from './deleteAllocationReducer';
import allocationOfferingListReducer from './allocationOfferingListReducer';
import runAllocationReducer from './runAllocationReducer';
import createWarrantReducer from './createWarrantReducer';
import updateWarrantReducer from './updateWarrantReducer';
import ioiNotificationReducer from './ioiNotificationReducer';
import allocationEmailReducer from './allocationEmailReducer';
import brokerDealerAllocationReducer from './brokerDealerAllocationReducer';
import sendBrokerDealerReducer from './sendBrokerDealerReducer';
import sendBrokerDealerPriceReducer from './sendBrokerDealerPriceReducer';
import showAllocationReducer from './showAllocationReducer';
import showAllocationMetaDataReducer from './showAllocationMetaDataReducer';
import saveAllocationReducer from './saveAllocationReducer';
import completeAllocationReducer from './completeAllocationReducer';
import callIntactFileReducer from './callIntactFileReducer';
import callShowIntactFileReducer from './callShowIntactFileReducer';
import allocationOfferingReducer from './allocationOfferingReducer';
import allocationDetailReducer from './allocationDetailReducer';
import deleteAllOrderReducer from './deleteAllOrderReducer';
import userCreationByDateReducer from './userCreationByDateReducer';
import offeringAttributionReducer from './offeringAttributionReducer';
import offeringStatsGraphReducer from './offeringStatsGraphReducer';
import ordersMetaDataReducer from './ordersMetaDataReducer';
import sendPendingBrokerDealerReducer from './sendPendingBrokerDealerReducer';
import revenueListReducer from './revenueListReducer';
import offeringCallBacksReducer from './offeringCallBacksReducer';
import whitelistedIPsReducer from './whitelistedIPsReducer';
import callBraggartReducer from './callBraggartReducer';
import currentDateTimeReducer from './currentDateTimeReducer';
import createIssuerReducer from './createIssuerReducer';
import updateIssuerReducer from './updateIssuerReducer';
import issuerListReducer from './issuerListReducer';
import deleteIssuerReducer from './deleteIssuerReducer';
import companyInfoReducer from './companyInfoReducer';
import offeringAssetTypesReducer from './offeringAssetTypesReducer';

export default combineReducers({
  form: formReducer,
  user: userReducer,
  isLoading: loadingReducer,
  changePassword: changePasswordReducer,
  resetPassword: resetPasswordReducer,
  offeringActive: offeringActiveReducer,
  offeringCancelled: offeringCancelledReducer,
  offeringEffective: offeringEffectiveReducer,
  offeringClosed: offeringClosedReducer,
  forgotPassword: forgotPasswordReducer,
  offeringDetail: offeringDetailReducer,
  offeringUserInfo: offeringUserInfoReducer,
  bdList: brokerDealerListReducer,
  underWriterMpid: underWriterMpidReducer,
  offeringIndustries: offeringIndustriesReducer,
  createOffering: createOfferingReducer,
  lastClosingPrice: lastClosingPriceReducer,
  offeringLogo: logoUploadForOfferingReducer,
  brochureUrl: brochureUrlReducer,
  attestationUrl: attestationUrlReducer,
  faqUrl: faqUrlReducer,
  updateOffering: updateOfferingReducer,
  notifyUser: notifyUserReducer,
  dspUsers: dspUsersReducer,
  sixtyminuteEmail: sixtyminuteEmailReducer,
  archiveOffering: archiveOfferingReducer,
  unarchiveOffering: unarchiveOfferingReducer,
  reconfirmRequest: updateOfferingReconfirmRequestReducer,
  updateProspectus: updateProspectusReducer,
  ratingList: ratingListReducer,
  setUnderwriter: setUnderwriterReducer,
  currentCall: currentCallReducer,
  offeringOrders: offeringOrdersReducer,
  followerList: followerListReducer,
  updateRequestedAmount: updateRequestedAmountReducer,
  deleteOrder: deleteOrderReducer,
  sendNotification: sendNotificationReducer,
  usersStats: userStatsReducer,
  offeringStats: offeringStatsReducer,
  allOfferings: allOfferingsReducer,
  bizInsightsList: bizInsightsListReducer,
  statsFollowerList: statsFollowerListReducer,
  statsOfferingOrders: statsOfferingOrdersReducer,
  adminUnderWriterMpid: adminUnderWriterMpidReducer,
  brokerDealerAdmins: brokerDealerAdminsReducer,
  createAdmin: createAdminReducer,
  underwriterPerformance: underwriterPerformanceReducer,
  configList: configListReducer,
  updateConfig: updateConfigReducer,
  messageAdd: messageAddReducer,
  messageList: messageListReducer,
  messageUpdate: messageUpdateReducer,
  messageDetail: messageDetailReducer,
  createUnderwriter: createUnderwriterReducer,
  createBrokerdealer: createBrokerdealerReducer,
  customerList: customerListReducer,
  customerConnectedList: customerConnectedListReducer,
  customerNonConnectedList: customerNonConnectedListReducer,
  customerDeletedList: customerDeletedListReducer,
  customerActivity: customerActivityReducer,
  customerOrderInfo: customerOrderInfoReducer,
  customerResetPass: customerResetPassReducer,
  deleteCustomer: deleteCustomerReducer,
  updateCustomer: updateCustomerReducer,
  allocationCompletedList: allocationCompletedListReducer,
  allocationPendingList: allocationPendingListReducer,
  createAllocation: createAllocationReducer,
  deleteAllocation: deleteAllocationReducer,
  allocationOfferingList: allocationOfferingListReducer,
  allocationOffering: allocationOfferingReducer,
  runAllocation: runAllocationReducer,
  createWarrant: createWarrantReducer,
  updateWarrant: updateWarrantReducer,
  ioiNotification: ioiNotificationReducer,
  allocationEmail: allocationEmailReducer,
  brokerDealerAllocation: brokerDealerAllocationReducer,
  sendBrokerDealer: sendBrokerDealerReducer,
  sendBrokerDealerPrice: sendBrokerDealerPriceReducer,
  showAllocation: showAllocationReducer,
  saveAllocation: saveAllocationReducer,
  completeAllocation: completeAllocationReducer,
  callIntactUpload: callIntactFileReducer,
  callShowIntactFile: callShowIntactFileReducer,
  allocationDetail: allocationDetailReducer,
  deleteAllOrder: deleteAllOrderReducer,
  userCreationByDate: userCreationByDateReducer,
  offeringAttribution: offeringAttributionReducer,
  offeringStatsGraph: offeringStatsGraphReducer,
  showAllocationMetaData: showAllocationMetaDataReducer,
  ordersMetaData: ordersMetaDataReducer,
  sendPendingBrokerDealer: sendPendingBrokerDealerReducer,
  revenueList: revenueListReducer,
  offeringCallBacks: offeringCallBacksReducer,
  whiteListedIPs: whitelistedIPsReducer,
  callBraggart: callBraggartReducer,
  currentDateTime: currentDateTimeReducer,
  createIssuer: createIssuerReducer,
  updateIssuer: updateIssuerReducer,
  issuerList: issuerListReducer,
  deleteIssuer: deleteIssuerReducer,
  companyInfo: companyInfoReducer,
  offeringAssetTypes: offeringAssetTypesReducer,
});