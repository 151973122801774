import { BASE_URL } from '../constants';
import { getItem } from '../utils/localStore';
import { errorHandling, errorHandler } from '../utils/errorHandling';

class lastClosingPriceApi {

    static lastClosingPrice(data) {
        const TOKEN = getItem('auth_token');
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'GET',
                headers: ajaxRequestHeaders,
            }
            return fetch(BASE_URL + `/offerings/last_closing_price/?ticker_symbol=${data.ticker_symbol}&offering_type=${data.offering_type_name}`, body).then(response => {
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){

        }
    }
}

export default lastClosingPriceApi;
