import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './stats.scss';
// import { getRevenueList, fetchRevenueListRes } from '../../actions/revenueListActions';

import { getCommaSeparatedTwoDecimalsNumber, numberWithCommasAndDollar } from '../../helper/helper';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import GridTable from '@nadavshaar/react-grid-table';
import Tooltips from '@material-ui/core/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
class RevenueListComponent extends React.PureComponent {
	constructor() {
		super();
		const columns = [
			{ id: 1, field: 'period', label: 'Period', visible: true, sortable: true, cellRenderer: this.periodTemplate },
			{ id: 2, field: 'bd_commission', label: 'BD Commission', visible: true, sortable: true, cellRenderer: this.bdCommTemplate },
			{ id: 3, width: '250px', field: 'clickipo_commission', label: 'Clickipo Commission', visible: true, sortable: true, cellRenderer: this.clickipoCommTemplate },
			{ id: 4, width: '200px', field: 'total_concession', label: 'Total Concession', visible: true, sortable: true, cellRenderer: this.totalConcTemplate },
			{ id: 5, width: '250px', field: 'bd_commission_share', label: 'BD Commission Share', visible: true, sortable: true, cellRenderer: this.bdCommShareTemplate },
			{ id: 6, width: '290px', field: 'cipo_commission_share', label: 'Clickipo Commission Share', visible: true, sortable: true, cellRenderer: this.clickipoCommShareTemplate },
		];
		this.state = {
			cols: columns,
			tabIndex: 0
		}
	}
	periodTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return  <div className='rgt-cell-inner rgt-text-truncate' style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
			<Tooltips title={(value !== undefined || value !== null) ? value : ''} interactive={!value} >
				<div className="text-one-line" style={{width: '90%'}} >{value}</div>
			</Tooltips>
			{
				value !== '' &&
				<Tooltips title={'Copy to clipboard'} interactive >
					<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{fontSize: 14}}/> </Button>
				</Tooltips>
			}
		</div>;
	}
	bdCommTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled }) => {
		return <div className='rgt-cell-inner rgt-text-truncate' style={{ overflow: 'hidden', display: 'flex', alignItems: 'center' }}>
			<Tooltips title={(value !== undefined || value !== null) ? numberWithCommasAndDollar(value) : ''} interactive={!value} >
				<div className="text-one-line" style={{ width: '90%' }} >{numberWithCommasAndDollar(value)}</div>
			</Tooltips>
			{
				value !== '' &&
				<Tooltips title={'Copy to clipboard'} interactive >
					<Button style={{ padding: 4, minWidth: 10, color: "#7e817c" }} onClick={() => navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{ fontSize: 14 }} /> </Button>
				</Tooltips>
			}
		</div>;
	}
	clickipoCommTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return <div className='rgt-cell-inner rgt-text-truncate' style={{ overflow: 'hidden', display: 'flex', alignItems: 'center' }}>
			<Tooltips title={(value !== undefined || value !== null) ? numberWithCommasAndDollar(value) : ''} interactive={!value} >
				<div className="text-one-line" style={{ width: '90%' }} >{numberWithCommasAndDollar(value)}</div>
			</Tooltips>
			{
				value !== '' &&
				<Tooltips title={'Copy to clipboard'} interactive >
					<Button style={{ padding: 4, minWidth: 10, color: "#7e817c" }} onClick={() => navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{ fontSize: 14 }} /> </Button>
				</Tooltips>
			}
		</div>;
	}
	totalConcTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return <div className='rgt-cell-inner rgt-text-truncate' style={{ overflow: 'hidden', display: 'flex', alignItems: 'center' }}>
			<Tooltips title={(value !== undefined || value !== null) ? numberWithCommasAndDollar(value) : ''} interactive={!value} >
				<div className="text-one-line" style={{ width: '90%' }} >{numberWithCommasAndDollar(value)}</div>
			</Tooltips>
			{
				value !== '' &&
				<Tooltips title={'Copy to clipboard'} interactive >
					<Button style={{ padding: 4, minWidth: 10, color: "#7e817c" }} onClick={() => navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{ fontSize: 14 }} /> </Button>
				</Tooltips>
			}
		</div>;
	}
	bdCommShareTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return <div className='rgt-cell-inner rgt-text-truncate' style={{ overflow: 'hidden', display: 'flex', alignItems: 'center' }}>
			<Tooltips title={(value !== undefined || value !== null) ? getCommaSeparatedTwoDecimalsNumber(value) : ''} interactive={!value} >
				<div className="text-one-line" style={{ width: '90%' }} >{getCommaSeparatedTwoDecimalsNumber(value)}</div>
			</Tooltips>
			{
				value !== '' &&
				<Tooltips title={'Copy to clipboard'} interactive >
					<Button style={{ padding: 4, minWidth: 10, color: "#7e817c" }} onClick={() => navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{ fontSize: 14 }} /> </Button>
				</Tooltips>
			}
		</div>;
	}
	clickipoCommShareTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return <div className='rgt-cell-inner rgt-text-truncate' style={{ overflow: 'hidden', display: 'flex', alignItems: 'center' }}>
			<Tooltips title={(value !== undefined || value !== null) ? getCommaSeparatedTwoDecimalsNumber(value) : ''} interactive={!value} >
				<div className="text-one-line" style={{ width: '90%' }} >{getCommaSeparatedTwoDecimalsNumber(value)}</div>
			</Tooltips>
			{
				value !== '' &&
				<Tooltips title={'Copy to clipboard'} interactive >
					<Button style={{ padding: 4, minWidth: 10, color: "#7e817c" }} onClick={() => navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small" style={{ fontSize: 14 }} /> </Button>
				</Tooltips>
			}
		</div>;
	}
	renderTooltip = (item) => {
		if (item && item.payload && item.payload.length > 0) {
			return <div style={{ padding: 8, background: '#fff' }}>
				<p style={{ fontWeight: 'bold', fontSize: 15, color: '#000', marginBottom: 0 }}>{item.payload[0].payload.new_period}</p>
				<p style={{ fontWeight: 'bold', fontSize: 15, color: '#0A5074', marginBottom: 0 }}>BD Commission: {numberWithCommasAndDollar(item.payload[0].payload.bd_commission !== null ? item.payload[0].payload.bd_commission : 0)}</p>
				<p style={{ fontWeight: 'bold', fontSize: 15, color: '#9ac051', marginBottom: 0 }}>Clickipo Commission: {numberWithCommasAndDollar(item.payload[0].payload.clickipo_commission !== null ? item.payload[0].payload.clickipo_commission : 0)}</p>
			</div>
		}

	}
	formatXAxis = (tickItem) => {
		return tickItem;
	}
	handleTabChange = (event, newValue) => {
		this.setState({
			tabIndex: newValue,
		}, () => { });
	}
	render() {
		const { tabIndex } = this.state;
		const { revenueList } = this.props;
		console.log(revenueList)
		let chartData = revenueList ? revenueList : [];
		var monthNames = [
			'Jan', 'Feb', 'Mar',
			'Apr', 'May', 'Jun', 'Jul',
			'Aug', 'Sep', 'Oct',
			'Nov', 'Dec',
		];
		if (chartData.length > 0) {
			chartData.forEach((item) => {
				if (item.period) {
					let splitDate = item.period.split('/')
					item.new_period = monthNames[splitDate[0] - 1] + ' ' + splitDate[1];
				}
				if (item.bd_commission === null) {
					item.bd_commission = 0;
				}
				if (item.bd_commission === null) {
					item.bd_commission = 0;
				}
			});
		}
		return (
			<div className="stats_offering_content_body">
				<div>
					<Tabs value={tabIndex} onChange={this.handleTabChange} >
						<Tab label="Graph" />
						<Tab label="Table" />
					</Tabs>
					{
						tabIndex === 0 &&
						<Typography component="div" style={{ paddingTop: 24, paddingBottom: 24 }}>
							<div style={{ maxWidth: "100%" }}>
								<div>
									{
										chartData && chartData.length > 1 &&
										<div style={{textAlign: 'center', marginBottom: 16}}>
											<div>(Showing data between {chartData[chartData.length - 1].period} and {chartData[0].period})</div>
										</div>
									}
									{
										chartData && chartData.length === 1 &&
										<div style={{textAlign: 'center', marginBottom: 16}}>
											<div>(Showing data of {chartData[0].period})</div>
										</div>
									}
								</div>
								<div className="col-md-12 mt-3">
									<div style={{width: '100%', height: '100%'}}>
										<div>
											<ResponsiveContainer width='100%' height={450} >
												<BarChart
													height={380}
													data={chartData}
													margin={{
													top: 5, right: 30, left: 20, bottom: 5,
													}}
												>
													<XAxis dataKey="new_period" tick={{fontSize: 13}} tickFormatter={this.formatXAxis} angle={-60} height={90} allowDataOverflow={false} textAnchor="end" interval="preserveStart" />
													{/* <XAxis dataKey="new_period" tick={{fontSize: 13}} tickFormatter={this.formatXAxis} angle={-60} height={90} allowDataOverflow={false} textAnchor="end" interval="preserveStart" xAxisId={1} hide /> */}
													<YAxis />
													<Tooltip
													cursor={false}
													content={this.renderTooltip}
													wrapperStyle={{
														borderColor: 'white',
														boxShadow: '2px 2px 3px 0px rgb(204, 204, 204)',
														background: 'white'
													}}
													contentStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
													labelStyle={{ fontWeight: 'bold', color: '#666666' }}
													/>
													<Legend />
													
													<Bar name="BD Commission" dataKey="bd_commission" barSize={25}fill="#0A5074"></Bar>
													<Bar name="Clickipo Commission" dataKey="clickipo_commission" barSize={25} fill="#9ac051" ></Bar>
												</BarChart>
											</ResponsiveContainer>
										</div>
										<div className="row mt-4">
											<div className="col-md-6" style={{textAlign: 'right'}}>
												<Button variant="contained" color="primary" onClick={() => this.props.doPrevious()} disabled={this.props.revenueList.length === 0 ? true : false}>Previous</Button>
											</div>
											<div className="col-md-6">
												<Button  variant="contained" color="primary" onClick={() => this.props.doNext()} disabled={this.props.pagenum === 1 ? true : false}>Next</Button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Typography>
					}
					{
						tabIndex === 1 &&
						<Typography component="div" style={{ paddingTop: 24, paddingBottom: 24 }}>
							<div style={{ maxWidth: "100%" }}>
								<div>
									{
										chartData && chartData.length > 1 &&
										<div style={{textAlign: 'center', marginBottom: 16}}>
											<div>(Showing data between {chartData[chartData.length - 1].period} and {chartData[0].period})</div>
										</div>
									}
									{
										chartData && chartData.length === 1 &&
										<div style={{textAlign: 'center', marginBottom: 16}}>
											<div>(Showing data of {chartData[0].period})</div>
										</div>
									}
								</div>
								<div className="col-md-12 revenuetable" >
									<GridTable
										columns={this.state.cols}
										rows={revenueList}
										isPaginated={false}
										isVirtualScroll={true}
										showSearch={true}
										showRowsInformation={false}
										isHeaderSticky={true}
									/>
									<div className="row mt-4">
										<div className="col-md-12">
											<Button variant="contained" color="primary" onClick={() => this.props.doPrevious()} disabled={this.props.revenueList.length === 0 ? true : false} style={{marginRight: 16}}>Previous</Button>
											<Button  variant="contained" color="primary" onClick={() => this.props.doNext()} disabled={this.props.pagenum === 1 ? true : false} style={{marginLeft: 16}}>Next</Button>
										</div>
									</div>
								</div>
							</div>
						</Typography>
					}
						
				</div>
			</div>
		)
	}
}

RevenueListComponent.propTypes = {
};

const mapStateToProps = createStructuredSelector({
});

function mapDispatchToProps(dispatch) {
	return {
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(RevenueListComponent);
