import * as types from './actionTypes';  
import deleteAllocationApi from '../api/deleteAllocationApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doDeleteAllocation(request) {

  return {
    type: types.DO_DELETE_ALLOCATION, 
    request
  };
}

export function doDeleteAllocationRes(data) {  
  return {
    type: types.DO_DELETE_ALLOCATION_RES, 
    data
  };
}

export function submitDeleteAllocation(request) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      deleteAllocationApi.doDeleteAllocation(request).then(data => {
        dispatch(doDeleteAllocationRes(data));
        dispatch(doDeleteAllocationRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
  
}