import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import "./orders.scss";
import Dashboard from "../../component/Dashboard";
import {
  fetchOrdersMetaData,
  doFetchOrdersMetadataRes,
} from "../../actions/ordersMetaDataActions";
import {
  currency,
  numberWithCommasAndDollar,
  numberWithCommas,
  saveFile,
} from "../../helper/helper";
import {
  fetchOfferingOrders,
  doFetchOfferingOrdersRes,
} from "../../actions/offeringOrdersActions";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import GridTable from "@nadavshaar/react-grid-table";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { DialogActions } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import { Calendar } from "primereact/components/calendar/Calendar";
import InputAdornment from "@material-ui/core/InputAdornment";
import Swal from "sweetalert2";
import { loadingRes } from "../../actions/loadingActions";
import { getItem } from "../../utils/localStore";
import { BASE_URL } from "../../constants";
import Checkbox from '@material-ui/core/Checkbox';
// import exportOrdersData from './orderExportTableData';

const DialogTitle = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(),
    top: theme.spacing(),
    color: theme.palette.grey[500],
  },
}))((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
const styles = {
  block: {
    maxWidth: 250,
  },
  radioButton: {
    marginBottom: 16,
    color: "#8DC73F",
  },
};
const customStyles = {
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    background: "#ffffff",
    zIndex: 9999,
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
};
const optionsConditions = [
  {
    label: ">=",
    value: ">=",
  },
  {
    label: "=",
    value: "=",
  },
  {
    label: "<=",
    value: "<=",
  },
];
let isOrders = false;
class OrdersComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    isOrders = false;
    let columns = [
      {
        id: 1,
        width: "250px",
        field: "org_name",
        label: "Broker dealer name",
        visible: true,
        sortable: true,
      },
      {
        id: 2,
        width: "200px",
        field: "count",
        label: "Order count",
        visible: true,
        sortable: true,
        cellRenderer: this.orderCount,
      },
      {
        id: 3,
        width: "250px",
        field: "requested_amount",
        label: "Requested amount",
        visible: true,
        sortable: true,
        cellRenderer: this.requestedAmountTemplate,
      },

      {
        id: 4,
        width: "250px",
        field: "allocated_amount",
        label: "Allocated amount",
        visible: true,
        sortable: true,
        cellRenderer: this.allocatesAmountTemplate,
      },
    ];
    let offeringData = this.props.location.state.offeringData;
    if (offeringData) {
      if (offeringData.ordr_dollar_share === 0) {
        columns.push(
          {
            id: 5,
            width: "250px",
            field: "max_requested_amount",
            label: "Max requested amount",
            visible: true,
            sortable: true,
            cellRenderer: this.maxRequestedAmountTemplate,
          },

          {
            id: 6,
            width: "250px",
            field: "min_requested_amount",
            label: "Min requested amount",
            visible: true,
            sortable: true,
            cellRenderer: this.minRequestedAmountTemplate,
          }
        );
      }
      if (offeringData.ordr_dollar_share === 1) {
        columns.push(
          {
            id: 5,
            width: "250px",
            field: "max_requested_shares",
            label: "Max requested shares",
            visible: true,
            sortable: true,
            cellRenderer: this.maxRequestedShareTemplate,
          },

          {
            id: 6,
            width: "250px",
            field: "min_requested_shares",
            label: "Min requested shares",
            visible: true,
            sortable: true,
            cellRenderer: this.minRequestedShareTemplate,
          }
        );
      }
    }
    this.state = {
      cols: columns,
      offeringName: "",
      uiRender: false,
      offeringStatus: "",
      offering: "",
      ordersMetaData: null,
      tabIndex: props.location.state.tabIndex,
      openExportOrderModal: false,
      offeringOrders: [],
      brokerDealerStatus: "0",
      brokerDealerName: "",
      exportOrders: [
        {
          checked: true,
          columnName: "User Name",
          columnKey: "name",
          condition: "",
          value: ""
        },
        {
          checked: true,
          columnName: "Account Name",
          columnKey: "account_name",
          condition: "",
          value: ""
        },
        {
          checked: true,
          columnName: "User Email",
          columnKey: "email",
          condition: "",
          value: ""
        },
        {
          checked: true,
          columnName: "Prospectus",
          columnKey: "created_at",
          condition: "",
          value: ""
        },
        {
          checked: true,
          columnName: "Final Prospectus",
          columnKey: "final_prospectus_datetime",
          condition: "",
          value: ""
        },
        {
          checked: true,
          columnName: "Requested Amount",
          columnKey: "requested_amount",
          condition: "",
          value: ""
        },
        {
          checked: true,
          columnName: "Allocated Amount",
          columnKey: "allocated_amount",
          condition: "",
          value: ""
        },
        {
          checked: true,
          columnName: "Requested Shares",
          columnKey: "requested_shares",
          condition: "",
          value: ""
        },
        {
          checked: true,
          columnName: "Allocated Shares",
          columnKey: "allocated_shares",
          condition: "",
          value: ""
        },
        {
          checked: true,
          columnName: "IPO Buying Power",
          columnKey: "buying_power_as_of",
          condition: "",
          value: ""
        },
        {
          checked: true,
          columnName: "Reconfirm",
          columnKey: "reconfirmed_dt",
          condition: "",
          value: ""
        }
      ],
      isRender: false,
    };
    this.colOptions = [];

    for (const col of columns) {
      this.colOptions.push({ label: col.header, value: col });
    }
  }
  componentDidMount() {
    let id = this.props.match.path.split("orders/")[0];
    if (id !== null) {
      let offeringCreate = this.props.location.state.offeringData;
      if (offeringCreate.created_at) {
        this.setState(
          {
            offering: offeringCreate,
            offeringName: offeringCreate.name,
            offeringStatus:
              offeringCreate.status === "effective"
                ? "closed"
                : offeringCreate.status,
          },
          () => { }
        );
      }
      this.props.fetchOrdersMetaData(offeringCreate.ext_id);
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.ordersMetadataRes) {
      if (
        props.ordersMetadataRes.data &&
        props.ordersMetadataRes.data.ordersMetaData
      ) {
        if (props.ordersMetadataRes.data.ordersMetaData.message === "Success") {
          return {
            ordersMetaData: props.ordersMetadataRes.data.ordersMetaData.data,
          };
        }
      }
    }
    if (props.offeringOrdersRes) {
      if (
        props.offeringOrdersRes.data &&
        props.offeringOrdersRes.data.offeringOrders
      ) {
        if (
          props.offeringOrdersRes.data.offeringOrders.message === "Success" &&
          isOrders
        ) {
          isOrders = false;
          return {
            offeringOrders: props.offeringOrdersRes.data.offeringOrders.data,
          };
        }
      }
    }
    return null;
  }
  allocatesAmountTemplate = ({
    tableManager,
    value,
    field,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    let classname = "rgt-cell-inner ";
    if (data.status === "cancelled") {
      classname = "rgt-cell-inner red_color";
    }
    return (
      <div className={classname}> $ {currency(data.allocated_amount)}</div>
    );
  };
  requestedAmountTemplate = ({
    tableManager,
    value,
    field,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    let classname = "rgt-cell-inner ";
    if (data.status === "cancelled") {
      classname = "rgt-cell-inner red_color";
    }
    return <div className={classname}>$ {currency(data.requested_amount)}</div>;
  };
  maxRequestedAmountTemplate = ({
    tableManager,
    value,
    field,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    let classname = "rgt-cell-inner ";
    if (data.status === "cancelled") {
      classname = "rgt-cell-inner red_color";
    }
    return (
      <div className={classname}> $ {currency(data.max_requested_amount)}</div>
    );
  };
  minRequestedAmountTemplate = ({
    tableManager,
    value,
    field,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    let classname = "rgt-cell-inner ";
    if (data.status === "cancelled") {
      classname = "rgt-cell-inner red_color";
    }
    return (
      <div className={classname}>$ {currency(data.min_requested_amount)}</div>
    );
  };
  maxRequestedShareTemplate = ({
    tableManager,
    value,
    field,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    let classname = "rgt-cell-inner ";
    if (data.status === "cancelled") {
      classname = "rgt-cell-inner red_color";
    }
    return (
      <div className={classname}>
        {" "}
        {numberWithCommas(data.max_requested_shares)}
      </div>
    );
  };
  minRequestedShareTemplate = ({
    tableManager,
    value,
    field,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    let classname = "rgt-cell-inner ";
    if (data.status === "cancelled") {
      classname = "rgt-cell-inner red_color";
    }
    return (
      <div className={classname}>
        {numberWithCommas(data.min_requested_shares)}
      </div>
    );
  };
  goBack = () => {
    // if (this.state.offeringListCheck === false) {
    // 	this.props.fetchOfferings(this.state.offeringStatus);
    // }
    // this.props.history.goBack();
    this.props.history.push({
      pathname: `/offerings`,
      state: {
        tabIndex: this.state.tabIndex,
      },
    });
  };
  orderCount = ({
    tableManager,
    value,
    field,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return (
      <div className="rgt-cell-inner">
        <div className="order-count" onClick={() => this.goOrders(data)}>
          {data.count}
        </div>
      </div>
    );
  };
  goOrders = (rowData) => {
    this.props.history.push({
      pathname: `/brokerdealer-order/${rowData.mpid}`,
      state: {
        brokers: rowData,
        offering: this.state.offering,
      },
    });
  };
  goBuildOut = () => {
    this.props.history.push({
      pathname: `/orders-buildout/${this.state.offering.ext_id}`,
      state: {
        offeringData: this.state.offering,
      },
    });
  };

  fetchBrokerData = async () => {
    this.props.loadingRes(true);
    const { ordersMetaData } = this.state;
    let metaDataList = [];
    if (ordersMetaData && ordersMetaData.broker_dealers) {
      metaDataList = ordersMetaData.broker_dealers;
    }
    let allBdData = [];
    if (this.state.brokerDealerStatus === "0") {
      allBdData = metaDataList;
    } else {
      allBdData = metaDataList.filter((item) => {
        return item.mpid === this.state.brokerDealerName?.value;
      });
    }
    let allFetchedData = [];
    for (const brokerData of allBdData) {
      let currentPage = 1;

      const { mpid } = brokerData;
      while (true) {
        let payload = {
          ext_id: this.state.offering?.ext_id,
          status:
            this.state.offering?.status === "effective"
              ? "closed"
              : this.state.offering?.status,
          mpid: mpid,
          pagenum: currentPage,
        };
        // Calculate the API URL with the current page number
        const TOKEN = getItem("auth_token");
        const ajaxRequestHeaders = new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: TOKEN,
        });
        let body = {
          method: "GET",
          headers: ajaxRequestHeaders,
        };
        var queryString = Object.keys(payload)
          .map((key) => key + "=" + payload[key])
          .join("&");
        const apiUrl = BASE_URL + `/offerings/orders?${queryString}`;

        try {
          const response = await fetch(apiUrl, body);
          const responseData = await response.json();
          if (responseData?.message === "Success") {
            if (!responseData?.data || responseData?.data?.length === 0) {
              break;
            } else {
              allFetchedData = allFetchedData.concat(responseData?.data);
            }
          }
          currentPage++;
        } catch (error) {
          console.error("API request failed:", error);
          break; // Exit the loop on error
        }
      }
      // Use allFetchedData as needed for the current orders
    }
    this.exportAllDataForOrders(allFetchedData);
  };

  openExportOrder = () => {
    this.setState({
      openExportOrderModal: true,
    });
  };
  closeExportOrderModal = () => {
    this.setState({
      openExportOrderModal: false,
      offeringOrders: [],
      brokerDealerStatus: "0",
      brokerDealerName: "",
      exportOrders: [
        {
          checked: true,
          columnName: "User Name",
          columnKey: "name",
          condition: "",
          value: ""
        },
        {
          checked: true,
          columnName: "Account Name",
          columnKey: "account_name",
          condition: "",
          value: ""
        },
        {
          checked: true,
          columnName: "User Email",
          columnKey: "email",
          condition: "",
          value: ""
        },
        {
          checked: true,
          columnName: "Prospectus",
          columnKey: "created_at",
          condition: "",
          value: ""
        },
        {
          checked: true,
          columnName: "Final Prospectus",
          columnKey: "final_prospectus_datetime",
          condition: "",
          value: ""
        },
        {
          checked: true,
          columnName: "Requested Amount",
          columnKey: "requested_amount",
          condition: "",
          value: ""
        },
        {
          checked: true,
          columnName: "Allocated Amount",
          columnKey: "allocated_amount",
          condition: "",
          value: ""
        },
        {
          checked: true,
          columnName: "Requested Shares",
          columnKey: "requested_shares",
          condition: "",
          value: ""
        },
        {
          checked: true,
          columnName: "Allocated Shares",
          columnKey: "allocated_shares",
          condition: "",
          value: ""
        },
        {
          checked: true,
          columnName: "IPO Buying Power",
          columnKey: "buying_power_as_of",
          condition: "",
          value: ""
        },
        {
          checked: true,
          columnName: "Reconfirm",
          columnKey: "reconfirmed_dt",
          condition: "",
          value: ""
        }
      ],
      isRender: false,
    });
  };
  exportAllDataForOrders = (allOrders) => {
    let allFilterArr = allOrders;
    let itemArr = [];
    var exportArr = this.state.exportOrders?.filter((it) => it.checked);
    const hasValueInField = this.state.exportOrders?.some(item => {
      return item?.value?.toString()?.trim() !== '';
    });
    if (hasValueInField) {
      for (let item of exportArr) {
        if (item.condition) {
          if (item.columnKey === "name" || item.columnKey === "email" || item.columnKey === "account_name") {
            itemArr.push({
              column: item.columnKey,
              condition: "",
              value: item.value,
              type: "text"
            });
          } else if (item.columnKey === "created_at" || item.columnKey === "final_prospectus_datetime" || item.columnKey === "reconfirmed_dt") {
            itemArr.push({
              column: item.columnKey,
              condition: item.condition?.value,
              value: item.value,
              type: "date"
            })
          } else {
            itemArr.push({
              column: item.columnKey,
              condition: item.condition?.value,
              value: item.value,
              type: "number"
            })
          }
        }
      }
      const filteredData = this.filterData(allFilterArr, itemArr);
      this.csvDownload(filteredData);
      this.props.loadingRes(false);

    } else {
      this.csvDownload(allFilterArr);
      this.props.loadingRes(false);
    }
  };
  filterData = (mainArray, filterArray) => {
    return mainArray.filter(item => {
      return filterArray.every(filter => {
        const { column, condition, value, type } = filter;
        if (type === "text") {
          return item[column] === value;
        } else if (type === "date") {
          switch (condition) {
            case '=':
              return new Date(item[column]).setHours(0, 0, 0, 0) === new Date(value).setHours(0, 0, 0, 0);
            case '>=':
              return new Date(item[column]) >= new Date(value);
            case '<=':
              return new Date(item[column]) <= new Date(value);
            default:
              return false;
          }
        } else {
          switch (condition) {
            case '=':
              return parseFloat(item[column]) === parseFloat(value);
            case '>=':
              return parseFloat(item[column]) >= parseFloat(value);
            case '<=':
              return parseFloat(item[column]) <= parseFloat(value);
            default:
              return false;
          }
        }
      });
    });
  }
  validateArray = (array) => {
    // Validation for condition and value
    const conditionValueMismatch = array.some(item => {
      return item.checked && item.condition !== '' && item.value?.toString()?.trim() === '';
    });
    if (conditionValueMismatch) {
      Swal.fire({
        title: "Condition are selected but text not. Please enter text",
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        timer: 3000,
        heightAuto: false,
      });
      return false;
    }
    const conditionAndValueMismatch = array.some(item => {
      return item.checked && (item.columnKey !== "name" && item.columnKey !== "email") && item.condition === '' && item.value?.toString()?.trim() !== '';
    });
    if (conditionAndValueMismatch) {
      Swal.fire({
        title: "Condition are not selected but text are. Please select condition",
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        timer: 3000,
        heightAuto: false,
      });
      return false;
    }
    const allUnchecked = array.every(item => !item.checked);
    if (allUnchecked) {
      Swal.fire({
        title: "At least one column must be checked.",
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        timer: 3000,
        heightAuto: false,
      });
      return false;
    }

    // console.log('Validation successful.');
    return true;
  };
  exportDataForOrders = () => {
    if (this.state.brokerDealerStatus === "1") {
      if (this.state.brokerDealerName !== "") {
        let isValidate = this.validateArray(this.state.exportOrders);
        if (isValidate) {
          this.fetchBrokerData();
        }
      } else {
        Swal.fire({
          title: "Please select brokerdealer",
          icon: "error",
          confirmButtonText: "OK",
          allowOutsideClick: false,
          timer: 3000,
          heightAuto: false,
        });
      }
    } else {
      let isValidate = this.validateArray(this.state.exportOrders);
      if (isValidate) {
        this.fetchBrokerData();
      }
    }
  };
  csvDownload = async (arrayData) => {
    const newArray = this.state.exportOrders.filter(item => item.checked).map(item => item.columnName);
    const keysArray = this.state.exportOrders.filter(item => item.checked).map(item => item.columnKey);
    const filteredArray = arrayData.map(item => {
      const filteredObject = {};
      keysArray.forEach(key => {
        if (item.hasOwnProperty(key)) {
          filteredObject[key] = item[key];
        }
      });
      return filteredObject;
    });

    let csvContent = "";
    csvContent += `${newArray.join(',')}\r\n`;

    filteredArray.forEach((rowObject) => {
      const row = keysArray.map(key => rowObject[key] || '').join(',');
      // const row = rowArray.join(',');
      csvContent += `${row}\r\n`;
    });

    let fileName = this.state.brokerDealerStatus === "0" ? "all_orders.csv" : `${this.state.brokerDealerName?.value.trim()}.csv`;
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    await saveFile(blob, fileName);
    this.closeExportOrderModal();
  };

  changeAllBrokerFetch = (e) => {
    this.setState(
      {
        brokerDealerStatus: e.target.value,
      },
      () => {
        if (e.target.value === "0") {
          this.setState({
            brokerDealerName: "",
          });
        }
      }
    );
  };
  handleChangeBrokers = (e) => {
    this.setState({
      brokerDealerName: e,
    });
  };
  handleChangeCheckbox = (e, index) => {
    const exportOrderData = [...this.state.exportOrders];
    if(!e.target.checked) {
      exportOrderData[index].condition = "";
      exportOrderData[index].value = "";
    }
    exportOrderData[index].checked = e.target.checked;
    this.setState({
      exportOrders: exportOrderData
    }, () => {
      this.setState({ isRender: !this.state.isRender });
    });
  }
  handleChangeCondition = (e, index) => {
    const exportOrderData = [...this.state.exportOrders];
    exportOrderData[index].condition = e;
    this.setState({
      exportOrders: exportOrderData
    }, () => {
      this.setState({ isRender: !this.state.isRender });
    });
  };
  handleChangeTable = (e, index) => {
    const exportOrderData = [...this.state.exportOrders];
    exportOrderData[index].value = e.target.value;
    this.setState({
      exportOrders: exportOrderData
    }, () => {
      this.setState({ isRender: !this.state.isRender });
    });
  }
  render() {
    const { offering, ordersMetaData } = this.state;
    let metaDataList = [];
    if (ordersMetaData && ordersMetaData.broker_dealers) {
      metaDataList = ordersMetaData.broker_dealers;
    }
    const optionBrokers = [];
    if (metaDataList) {
      if (metaDataList?.length > 0) {
        for (let broker of metaDataList) {
          optionBrokers.push({
            value: broker.mpid,
            label: broker.org_name,
          });
        }
      }
    }
    return (
      <Dashboard title="ClickIPO Offerings - Orders" pageTitle="Orders">
        <div className="orders_content">
          <div>
            <div className="row offeringSubheader">
              <div className="col-md-8">
                <div className="orders-page_header">
                  <div className="Headingname">
                    <ArrowBackIosIcon
                      onClick={() => this.goBack()}
                      style={{ cursor: "pointer" }}
                    />{" "}
                    {this.state.offeringName} ({offering.ticker_symbol}) Orders{" "}
                    {this.state.uiRender}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="orders-page_header"
                  style={{ textAlign: "right" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.openExportOrder();
                    }}
                    style={{ marginRight: 15 }}
                  >
                    Export Orders
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.goBuildOut();
                    }}
                    style={{ marginRight: 15 }}
                  >
                    Build Out
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <div className="row orderpage-subheading">
                <div className="col-md-12">
                  {offering && this.state.offeringStatus === "closed" && (
                    <div className="totalAmountOfferingDetail">
                      {offering.min_price !== null && (
                        <span>
                          Min Price:{" "}
                          {numberWithCommasAndDollar(offering.min_price)}
                        </span>
                      )}
                      {offering.min_price !== null && (
                        <span style={{ paddingLeft: 24 }}>
                          Max Price:{" "}
                          {numberWithCommasAndDollar(offering.max_price)}
                        </span>
                      )}
                      {offering.min_price !== null && (
                        <span style={{ paddingLeft: 24 }}>
                          Final Price:{" "}
                          {numberWithCommasAndDollar(offering.final_price)}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="row" style={{ marginTop: 10, marginBottom: 10 }}>
                <div className="col-md-12">
                  {ordersMetaData && offering && (
                    <div className="totalAmountOfferingDetail">
                      {offering.ordr_dollar_share === 0 ? (
                        <span style={{ paddingLeft: 24 }}>
                          Total Requested Amount:{" "}
                          {numberWithCommasAndDollar(
                            ordersMetaData.requested_amount
                          )}
                        </span>
                      ) : (
                        <span style={{ paddingLeft: 24 }}>
                          Total Requested Shares:{" "}
                          {numberWithCommas(ordersMetaData.requested_shares)}
                        </span>
                      )}
                      <span style={{ paddingLeft: 24 }}>
                        Round Lot: {ordersMetaData.round_lot_requested}
                      </span>
                      <span style={{ paddingLeft: 24 }}>
                        $2500 Lot: {`${ordersMetaData["2500_lot_requested"]}`}
                      </span>
                      {offering.ordr_dollar_share === 0 ? (
                        <span style={{ paddingLeft: 24 }}>
                          Min Requested Amount:{" "}
                          {numberWithCommasAndDollar(
                            ordersMetaData.min_requested_amount
                          )}
                        </span>
                      ) : (
                        <span style={{ paddingLeft: 24 }}>
                          Min Requested Shares:{" "}
                          {numberWithCommas(
                            ordersMetaData.min_requested_shares
                          )}
                        </span>
                      )}
                      {offering.ordr_dollar_share === 0 ? (
                        <span style={{ paddingLeft: 24 }}>
                          Max Requested Amount:{" "}
                          {numberWithCommasAndDollar(
                            ordersMetaData.max_requested_amount
                          )}
                        </span>
                      ) : (
                        <span style={{ paddingLeft: 24 }}>
                          Max Requested Shares:{" "}
                          {numberWithCommas(
                            ordersMetaData.max_requested_shares
                          )}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className="mt-3 orderlist-table">
                  <GridTable
                    columns={this.state.cols}
                    rows={metaDataList}
                    isPaginated={true}
                    isVirtualScroll={true}
                    showSearch={true}
                    showRowsInformation={false}
                    isHeaderSticky={true}
                  />
                </div>
                <Dialog
                  onClose={this.closeExportOrderModal}
                  aria-labelledby="customized-dialog-title"
                  open={this.state.openExportOrderModal}
                  maxWidth={"md"}
                  fullWidth={true}
                  scroll={"paper"}
                >
                  <DialogTitle
                    onClose={this.closeExportOrderModal}
                    className="offering-detail-title"
                  >
                    Export Orders
                  </DialogTitle>
                  <DialogContent className="custom-order-export-modal">
                    <div className="col-md-12 editModelText">
                      <div className="col-md-12 editAmountModelbody">
                        <div className="row">
                          <div
                            className="col-md-12 runAllocationmodal"
                            style={{ marginTop: 20, textAlign: "center" }}
                          >
                            <RadioGroup
                              row
                              name="run"
                              value={this.state.brokerDealerStatus}
                              onChange={(e) => this.changeAllBrokerFetch(e)}
                              style={{ display: "inline-block" }}
                            >
                              <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label="All brokerdealer orders"
                                style={styles.radioButton}
                              />
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Single brokerdealer orders"
                                style={styles.radioButton}
                              />
                            </RadioGroup>
                          </div>
                          <div className="col-md-12 col-sm-12 no_paading">
                            {this.state.brokerDealerStatus === "1" && (
                              <div className="mb-4">
                                <Select
                                  placeholder="Select brokerdealer"
                                  options={optionBrokers}
                                  name="brokerdealer"
                                  styles={customStyles}
                                  onChange={this.handleChangeBrokers}
                                  value={this.state.brokerDealerName}
                                />
                              </div>
                            )}
                            <div className="mb-4">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>Column</th>
                                    <th>Conditions</th>
                                    <th>Value</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    this.state.exportOrders && this.state.exportOrders.map((op, i) =>
                                      <tr key={i}>
                                        <td>
                                          <Checkbox
                                            checked={op.checked}
                                            onChange={(e) => this.handleChangeCheckbox(e, i)}
                                            color="primary"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                          />
                                        </td>
                                        <td>
                                          {op.columnName}
                                        </td>
                                        <td style={{ width: 250 }}>
                                          <div>
                                            {
                                              (op?.columnKey !== "name" && op?.columnKey !== "account_name" && op?.columnKey !== "email") ?
                                                <Select
                                                  className="w-100"
                                                  placeholder="Select Condition"
                                                  options={optionsConditions}
                                                  name="selectedCondition"
                                                  styles={customStyles}
                                                  onChange={(e) => this.handleChangeCondition(e, i)}
                                                  value={op.condition}
                                                  disabled={!op.checked}
                                                />
                                                :
                                                <div style={{ textAlign: "center" }}> = </div>
                                            }
                                          </div>
                                        </td>
                                        <td>
                                          {(op?.columnKey === "name" || op?.columnKey === "account_name" || op?.columnKey === "email") && (
                                            <div>
                                              <TextField
                                                className="w-100"
                                                type="text"
                                                name={op.columnKey}
                                                placeholder="Enter text"
                                                onChange={(e) => this.handleChangeTable(e, i)}
                                                value={op.value}
                                                disabled={!op.checked}
                                              />
                                            </div>
                                          )}
                                          {(op?.columnKey === "requested_amount" || op?.columnKey === "allocated_amount" || op?.columnKey === "buying_power_as_of") && (
                                            <TextField
                                              className="w-100 export-order-amount-field"
                                              type="number"
                                              name={op.columnKey}
                                              placeholder="Enter amount"
                                              InputProps={{
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    {" "}
                                                    ${" "}
                                                  </InputAdornment>
                                                ),
                                              }}
                                              onChange={(e) => this.handleChangeTable(e, i)}
                                              value={op.value}
                                            />
                                          )}
                                          {(op?.columnKey === "requested_shares" || op?.columnKey === "allocated_shares") && (
                                            <TextField
                                              className="w-100"
                                              type="number"
                                              name={op.columnKey}
                                              placeholder="Enter share"
                                              onChange={(e) => this.handleChangeTable(e, i)}
                                              value={op.value}
                                            />
                                          )}
                                          {(op?.columnKey === "created_at" || op?.columnKey === "final_prospectus_datetime" || op?.columnKey === "reconfirmed_dt") && (
                                            <div className="order-csv-cal">
                                              <Calendar
                                                placeholder="Choose date"
                                                classNmae="calendar prospectus-calender-ui w-100"
                                                name={op.columnKey}
                                                value={op.value}
                                                onChange={(e) => this.handleChangeTable(e, i)}
                                                style={{ height: "42px" }}
                                              />
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </DialogContent>
                  <DialogActions style={{ display: "block" }}>
                    <div style={{ textAlign: "center" }}>
                      <Button
                        style={{ marginLeft: 10, marginRight: 10 }}
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={() => this.closeExportOrderModal()}
                      >
                        Cancel
                      </Button>
                      <Button
                        style={{ marginLeft: 10, marginRight: 10 }}
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={() => this.exportDataForOrders()}
                      >
                        Submit
                      </Button>
                    </div>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    );
  }
}

OrdersComponent.propTypes = {
  ordersMetadataRes: PropTypes.any,
  offeringOrdersRes: PropTypes.any,
};
const mapStateToProps = createStructuredSelector({
  ordersMetadataRes: doFetchOrdersMetadataRes,
  offeringOrdersRes: doFetchOfferingOrdersRes,
});
function mapDispatchToProps(dispatch) {
  return {
    fetchOrdersMetaData: (ext_id) => dispatch(fetchOrdersMetaData(ext_id)),
    loadofferingOrders: (payload, isLoading) =>
      dispatch(fetchOfferingOrders(payload, isLoading)),
    loadingRes: (data) => dispatch(loadingRes(data)),
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(OrdersComponent);
