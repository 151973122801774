import * as types from './actionTypes';
import {logout} from '../helper/helper';
import createOfferingApi from '../api/createOfferingApi';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { uploadOfferingLogo } from './logoUploadForOfferingActions';
import { loadingRes } from './loadingActions';
export function doCreateOffering(data) {
  return {
    type: types.CREATE_OFFERING,
    data
  };
}

export function doCreateOfferingRes(data) {
  return {
    type: types.CREATE_OFFERING_RES,
    data
  };
}

export function submitCreateOffering(request, file) {
  const TOKEN = getItem('auth_token');
  let logo = file;
  if (logo) {
    logo = logo[0];
  } else {
    logo = null;
  }
  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      
      createOfferingApi.doCreateOffering(request).then(data => {
        if (data.message === "Success") {
          if (logo) {
            dispatch(uploadOfferingLogo(logo, request.ext_id));
            setTimeout(() => {
              dispatch(doCreateOfferingRes(data));
              dispatch(doCreateOfferingRes(null));
            }, 1500);
          } else {
            dispatch(doCreateOfferingRes(data));
            dispatch(doCreateOfferingRes(null));
          }
        }
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        } 
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
}