import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { createStructuredSelector } from 'reselect';
import Dashboard from '../../../component/Dashboard';
import { createIssuerComm, doCreateIssuerCommRes } from '../../../actions/issuerCommActions';
import { fetchCompanyInfo, companyInfoRes } from '../../../actions/companyInfoActions';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MenuItem from '@material-ui/core/MenuItem';
import validate from './formValidation';

class CreateIssuerForm extends React.Component {
    constructor() {
        super();

        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            tickerSymbol: '',
            companyName: '',
            description: '',
            exchange: '',
            isSubmited: false,
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.createIssuerCommRes) {
            if (props.createIssuerCommRes.data && props.createIssuerCommRes.data.createIssuer) {
                if (props.createIssuerCommRes.data.createIssuer.message === "Success") {
                    props.history.goBack();
                }
            }
        }
        if (props.companyInfoRes) {
            if (props.companyInfoRes.data && props.companyInfoRes.data.companyInfo) {

                if (props.companyInfoRes.data.companyInfo.message === 'Success') {
                    let nameVal = '';
                    let companyDesc = '';

                    if (props.companyInfoRes.data.companyInfo.data) {
                        nameVal = props.companyInfoRes.data.companyInfo.data.name;
                        companyDesc = props.companyInfoRes.data.companyInfo.data.description;
                    }


                    return {
                        companyName: nameVal,
                        description: companyDesc
                    };
                }
            }
        }
        return null
    }
    goBack = () => {
        this.props.history.goBack()
    }
    saveIssuer = () => {
        this.setState({
            isSubmited: true,
        }, () => { });
        validate(this.state);
        const errors = validate(this.state);
        console.log(Object.keys(errors))
        if (Object.keys(errors).length === 0) {
            let payload = {
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                email: this.state.email,
                ticker_symbol: this.state.tickerSymbol,
                name: this.state.companyName,
                description: this.state.description,
                role_type: 'issuer_admin',
                exchange: this.state.exchange
            }
            this.props.handleFormSubmit(payload);
        }
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleChangeCheckbox = (e) => {
        this.setState({
            [e.target.name]: e.target.checked
        })
    }
    handleChangeFile = (e) => {
        if (e.target.files && e.target.files[0]) {
            this.setState({
                previewLogo: URL.createObjectURL(e.target.files[0]),
                file: e.target.files,
            })
        }

    }
    fetchFinalPrice = () => {
        if (this.state.tickerSymbol !== '') {
            let payload = {
                ticker_symbol: this.state.tickerSymbol,
            }
            this.props.fetchCompanyInfo(payload);
        }
    }
    render() {
        const errors = validate(this.state);
        return (
            <Dashboard title="ClickIPO - Create New Issuer" pageTitle="Create New Issuer">
                <div className="admin_offering_content">
                    <div className="">
                        <div className="Headingname">
                            <ArrowBackIosIcon onClick={() => this.goBack()} style={{ cursor: 'pointer' }} /> Create New Issuer
                        </div>
                        <form autoComplete="off" style={{ marginTop: 30 }}>
                            <div className="row">
                                <div className="col-md-6 col-sm-12" style={{ marginTop: 20 }}>
                                    <label className="update-label">
                                        First Name *
                                    </label>
                                    <TextField name="firstName" value={this.state.firstName} fullWidth onChange={this.handleChange} />
                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.firstName}</span>}
                                </div>
                                <div className="col-md-6 col-sm-12" style={{ marginTop: 20 }}>
                                    <label className="update-label">
                                        Last Name *
                                    </label>
                                    <TextField name="lastName" value={this.state.lastName} fullWidth onChange={this.handleChange} />
                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.lastName}</span>}
                                </div>
                                <div className="col-md-6 col-sm-12" style={{ marginTop: 20 }}>
                                    <label className="update-label">
                                        Email *
                                    </label>
                                    <TextField name="email" fullWidth value={this.state.email} onChange={this.handleChange} />
                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.email}</span>}
                                </div>
                                <div className="col-md-6 col-sm-12" style={{ marginTop: 20 }}>
                                    <label className="update-label">
                                        Ticker Symbol *
                                    </label>
                                    <TextField name="tickerSymbol" value={this.state.tickerSymbol} fullWidth onChange={this.handleChange}
                                        onBlur={() => this.fetchFinalPrice()} />
                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.tickerSymbol}</span>}
                                </div>
                                <div className="col-md-6 col-sm-12" style={{ marginTop: 20 }}>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <label className="update-label">
                                                Company Name *
                                            </label>
                                            <TextField name="companyName" value={this.state.companyName} fullWidth onChange={this.handleChange} />
                                            {errors && this.state.isSubmited && <span className="errorvalidation">{errors.companyName}</span>}
                                        </div>
                                        <div className="col-md-12 col-sm-12" style={{ marginTop: 20 }}>
                                            <label>
                                                Exchange *
                                            </label>
                                            <Select
                                                name="exchange"
                                                value={this.state.exchange}
                                                onChange={this.handleChange}
                                                fullWidth
                                            >
                                                <MenuItem value={'XNYS'}>XNYS - NEW YORK STOCK EXCHANGE</MenuItem>
                                                <MenuItem value={'XNAS'}>XNAS - NASDAQ GLOBAL / CAPITAL / GLOBAL SELECT MARKET</MenuItem>
                                            </Select>
                                            {errors && this.state.isSubmited && <span className="errorvalidation">{errors.exchange}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12" style={{ marginTop: 20 }}>

                                    <label className="update-label">
                                        Description *
                                    </label>
                                    <TextField name="description" value={this.state.description} fullWidth multiline rows={4} onChange={this.handleChange} />
                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.description}</span>}

                                </div>
                            </div>
                        </form>
                        <div className="row">
                            <div className="col-md-12" style={{ textAlign: 'center' }}>
                                <Button variant="contained" color="primary" onClick={this.saveIssuer} style={{ marginTop: '30px' }} >Submit</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dashboard>
        );
    }
}

CreateIssuerForm.propTypes = {
    createIssuerCommRes: PropTypes.any,
    companyInfoRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    createIssuerCommRes: doCreateIssuerCommRes,
    companyInfoRes: companyInfoRes,
});

function mapDispatchToProps(dispatch) {
    return {
        handleFormSubmit: (data) => dispatch(createIssuerComm(data)),
        fetchCompanyInfo: (data) => dispatch(fetchCompanyInfo(data)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateIssuerForm);
