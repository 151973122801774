import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';
import Dashboard from '../../../component/Dashboard';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { Calendar } from 'primereact/calendar';
import { validatePrice, getAllocationPriceRangeNum, getAllocationUpdatePriceRangeNum } from '../../../helper/helper';
import { dateTimeFormat } from '../../../helper/helper';
import validate from './formValidation';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TextField from '@material-ui/core/TextField';

import { getUnderwriterMpid, underWriterMpidListRes } from '../../../actions/underWriterMpidActions';
import { fetchAllocationDetail, doFetchAllocationDetailRes } from '../../../actions/allocationDetailActions';

class UpdateAllocationForm extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor() {
    super();
    this.state = {
      allocationId: '',
      offeringId: '',
      final_price: '',
      continueCount: 0,
      sendEmail: false,
      max_date: '',
      max_time: '',
      max_price: '',
      UnderwriterValue: '',
      UnderwriterId: '',
      isPrice: false,
      selected_bd_underwriter_mpid_id: '',
      bd_underwriter_mpid_id: '',
      amountOfShares: '',
      cusipid: '',
      asked_shares: '',
      received_shares: '',
      underwriter_concession_amount: '',
      selected_offeringId: '',
      trade_date: '',
      settlement_date: '',
      isUIRender: false,
      offering_name: '',
      DisableDropdown: true,
      isChangeOffering: false,
      isLoad: true,
      date1: '',
      isSubmit: false,
      allocated: '',
      isDsp: 0,
      DspUnderwriterValue: 0,
      ioi_cutoff_datetime: null,
      ioi_cutoff_datetime_show: null,
      dsp_click_shares: '',

      userMpid: [],
      allocationDetail: null,
      tabIndex: 0,
    };
    this.handleContinue = this.handleContinue.bind(this);
    this.handleSendNotification = this.handleSendNotification.bind(this);
    this.mpIDhandleChange = this.mpIDhandleChange.bind(this);
    this.changeBrokerValue = this.changeBrokerValue.bind(this);
  }

  componentDidMount() {
    const allocationData = this.props.location.state;
    const tabIndexx = this.props.location.tabIndex;
    this.setState({
      allocationId: allocationData.ext_id,
      tabIndex: tabIndexx
    }, () => {});
    this.handlefetchUserMpid();
    this.props.fetchAllocationDetail(allocationData.ext_id);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.underWriterMpidListRes) {
      if (props.underWriterMpidListRes.data && props.underWriterMpidListRes.data.underWriterMpid ) {
        if (props.underWriterMpidListRes.data.underWriterMpid.message === 'Success' ) {
          return {
            userMpid: props.underWriterMpidListRes.data.underWriterMpid.data
          };
        }
      }
    }
    if (props.allocationDetailRes) {
      if (props.allocationDetailRes.data && props.allocationDetailRes.data.allocationDetail ) {
        if (props.allocationDetailRes.data.allocationDetail.message === 'Success' ) {
          let isDspVal = 0;
          let DspUnderwriterValueVal = 0;
          let ioi_cutoff_datetime_val = null;
          let ioi_cutoff_datetime_show_val = null;
          let dsp_click_shares_val = '';
          let selected_offeringId_val = '';
          let allocated_val = '';
          let offering_name_val = '';
          let offeringId_val = '';
          let final_price_val = '';
          let amountOfShares_val = '';
          let asked_shares_val = '';
          let underwriter_concession_amount_val = '';
          let trade_date_val = '';
          let settlement_date_val = '';
          if (props.allocationDetailRes.data.allocationDetail.data) {
            const selectedOfferingObject = {
              value: state.allocationId,
              label: props.allocationDetailRes.data.allocationDetail.data.name,
            };
            isDspVal = props.allocationDetailRes.data.allocationDetail.data.dsp;
            if (props.allocationDetailRes.data.allocationDetail.data.dsp_underwriter_concession_amount) {
                DspUnderwriterValueVal = props.allocationDetailRes.data.allocationDetail.data.dsp_underwriter_concession_amount;
            }
            if (props.allocationDetailRes.data.allocationDetail.data.dsp === 2) {
              ioi_cutoff_datetime_val = props.allocationDetailRes.data.allocationDetail.data.ioi_cutoff_datetime;
              ioi_cutoff_datetime_show_val = new Date(props.allocationDetailRes.data.allocationDetail.data.ioi_cutoff_datetime);
              dsp_click_shares_val = props.allocationDetailRes.data.allocationDetail.data.dsp_click_shares;
            }
            selected_offeringId_val = selectedOfferingObject;
            allocated_val = props.allocationDetailRes.data.allocationDetail.data.allocated;
            offering_name_val = props.allocationDetailRes.data.allocationDetail.data.name;
            if (props.allocationDetailRes.data.allocationDetail.data.final_price !== null) {
              offeringId_val = state.allocationId;
              final_price_val = props.allocationDetailRes.data.allocationDetail.data.final_price;
            }
            
            if (props.allocationDetailRes.data.allocationDetail.data.click_shares >= 0 && props.allocationDetailRes.data.allocationDetail.data.click_shares !== null) {
              amountOfShares_val =  props.allocationDetailRes.data.allocationDetail.data.click_shares;
            }
            if (props.allocationDetailRes.data.allocationDetail.data.asked_shares >= 0 && props.allocationDetailRes.data.allocationDetail.data.asked_shares !== null) {
              asked_shares_val = props.allocationDetailRes.data.allocationDetail.data.asked_shares;
            }
            if (props.allocationDetailRes.data.allocationDetail.data.underwriter_concession_amount) {
              underwriter_concession_amount_val = props.allocationDetailRes.data.allocationDetail.data.underwriter_concession_amount;
            }
            if (props.allocationDetailRes.data.allocationDetail.data.trade_date !== null && props.allocationDetailRes.data.allocationDetail.data.trade_date !== undefined) {
              trade_date_val = new Date(props.allocationDetailRes.data.allocationDetail.data.trade_date);
            } else {
              trade_date_val = '';
            }
            if (props.allocationDetailRes.data.allocationDetail.data.settlement_date !== null && props.allocationDetailRes.data.allocationDetail.data.settlement_date !== undefined) {
              settlement_date_val = new Date(props.allocationDetailRes.data.allocationDetail.data.trade_date);
            } else {
              settlement_date_val = '';
            }
          }
          let selected_bd_underwriter_mpid_id_val = '';
          let cusipid_val = '';
          let received_shares_val = '';
          if (props.allocationDetailRes.data.allocationDetail.data && state.userMpid) {
            const selectedMPIDObject = {
              value: props.allocationDetailRes.data.allocationDetail.data.bd_underwriter_mpid_id,
              label: props.allocationDetailRes.data.allocationDetail.data.bd_underwriter_mpid_id,
            };
            if (props.allocationDetailRes.data.allocationDetail.data.bd_underwriter_mpid_id) {
              selected_bd_underwriter_mpid_id_val = selectedMPIDObject;
            }
            if (props.allocationDetailRes.data.allocationDetail.data.cusip_id !== null && props.allocationDetailRes.data.allocationDetail.data.cusip_id !== undefined) {
              cusipid_val = props.allocationDetailRes.data.allocationDetail.data.cusip_id;
            }
            if (props.allocationDetailRes.data.allocationDetail.data.received_shares >= 0 && props.allocationDetailRes.data.allocationDetail.data.received_shares !== null) {
              received_shares_val = props.allocationDetailRes.data.allocationDetail.data.received_shares;
            }
          }
          return {
            allocationDetail: props.allocationDetailRes.data.allocationDetail.data,
            isDsp: isDspVal,
            DspUnderwriterValue: DspUnderwriterValueVal,
            ioi_cutoff_datetime: ioi_cutoff_datetime_val,
            ioi_cutoff_datetime_show: ioi_cutoff_datetime_show_val,
            dsp_click_shares: dsp_click_shares_val,
            selected_offeringId: selected_offeringId_val,
            allocated: allocated_val,
            offering_name: offering_name_val,
            offeringId: offeringId_val,
            final_price: final_price_val,
            amountOfShares: amountOfShares_val,
            asked_shares: asked_shares_val,
            underwriter_concession_amount: underwriter_concession_amount_val,
            trade_date: trade_date_val,
            settlement_date: settlement_date_val,
            selected_bd_underwriter_mpid_id: selected_bd_underwriter_mpid_id_val,
            cusipid: cusipid_val,
            received_shares: received_shares_val,
          };
        }
      }
    }
    return null;
  }
  handlefetchOffering(event) {
    this.setState({
      sendEmail: false,
      trade_date: this.state.trade_date,
      settlement_date: this.state.settlement_date,
      isPrice: true,
      underwriter_concession_amount: '',
      selected_offeringId: event,
      isChangeOffering: false,
      isLoad: false,
      istrade_dateChange: false,
      isSettlement_dateChange: false,
    }, () => {});

    if (event.value) {
    }
  }

  handlefetchUserMpid() {
    setTimeout(() => {
      this.props.fetchUserMpid();
    }, 500);
  }

  handleAllChanges() {
    this.setState({
      cusipid: this.cusipid.value,
      final_price: this.final_price.value,
      trade_date: this.state.trade_date,
      amountOfShares: this.amountOfShares.value,
     // allocated_shares:this.received_shares.value,
      received_shares: this.received_shares.value,
      asked_shares: this.asked_shares.value,
      underwriter_concession_amount: this.Underwriter.value,
    }, () => {});
  }

  handlePriceChanges() {
    this.setState({
      sendEmail: false,
      isPrice: false,
      final_price: this.final_price.value,
      isChangeOffering: true,
      isSubmit: true,
    }, () => {});

    if (this.final_price.value !== '') {
      let priceRange = [];
      if (this.state.isChangeOffering) {
        priceRange = getAllocationPriceRangeNum(this.props.AllocationData);
      } else {
        priceRange = getAllocationUpdatePriceRangeNum(this.props.offering);
      }

      const res = validatePrice(priceRange, parseInt(this.final_price.value))
      if (res) {
        this.setState({
          sendEmail: false,
          continueCount: 2,
          isPrice: false,
          final_price: this.final_price.value,
        }, () => {});
      } else {
        this.setState({
          sendEmail: true,
        }, () => {});
      }
    }
  }

  handleContinue(e) {
    validate(this.state);
    e.preventDefault();
    this.setState({
      isSubmit: true,
    }, () => {});
    var offering_bd_brokerages_attributes = [];
    if (this.props.AllocationData.broker_details.length > 0) {
      offering_bd_brokerages_attributes = this.props.AllocationData.broker_details.map((item) => {
        return { 'broker_dealer_name': item.name, 'bd_commission_percentage': item.commission_percentage };
      });
    } else {
      offering_bd_brokerages_attributes = [];
    }
    const FinalData = {
      received_shares: this.received_shares.value,
      ext_id: this.state.selected_offeringId.value,
      final_price: this.state.final_price,
      click_shares: this.amountOfShares.value,
      asked_shares: this.asked_shares.value,
      trade_date: dateTimeFormat(this.state.trade_date),
      underwriter_concession_amount: this.Underwriter.value,
      underwriter_mpid_id:this.state.selected_bd_underwriter_mpid_id.value,
      offering_bd_brokerages_attributes: offering_bd_brokerages_attributes,
      cusip_id: this.cusipid.value,
    };

    validate(this.state);
    const errors = validate(this.state);
    if (Object.keys(errors).length === 0) {
      this.props.postAllocation(FinalData, this.state.allocated);
    }
  }

  mpIDhandleChange(event) {
    this.setState({
      selected_bd_underwriter_mpid_id: event,
      bd_underwriter_mpid_id: event.value,
    }, () => {});
  }

  handleSendNotification(id) {
    this.setState({
      continueCount: 2,
    }, () => {});
    this.submit(() => {
      this.props.sendEmailNotification(this.selectOffering.value, this.max_price.value);
    });
  }

  handleUserMpid() {
    this.props.fetchCommission(this.selectUserMpId.value);
  }

  changeBrokerValue(item, event) {
    this.setState({ isUIRender: true });
    // item.commission_percentage = event.target.value,
    setTimeout(() => {
      this.setState({ isUIRender: false });
    }, 100);
  }

  changetrade_date(evant) {
    this.setState({
      trade_date: evant.value, istrade_dateChange: true,
    }, () => {});
  }
  changeSettlementDate = (evant) => {
    this.setState({
      settlement_date: evant.value, isSettlement_dateChange: true,
    }, () => {});
  }
  goToBack() {
    this.props.doFetchAllocationDetailRes();
    this.props.history.push({
		  pathname:`/allocations`,
		  state:{
        tabIndex: this.state.tabIndex,
		  }
		});
  }
  selectIoiCutoff = (e) => {
    if (e.value) {
      this.setState({
        ioi_cutoff_datetime: dateTimeFormat(e.value),
        ioi_cutoff_datetime_show: e.value,
      }, () => {});
    } else {
      this.setState({
        ioi_cutoff_datetime: null,
      }, () => {});
    }
  }
  
  handledspClickSharesChanges = (e) => {
    this.setState({
      dsp_click_shares: e.target.value,
    });
  }
  handleDspUnderwriterValueChanges = (e) => {
    this.setState({
      DspUnderwriterValue: e.target.value,
    })
  }
  render() {
    const {  offerings } = this.props;
    
    const { userMpid, allocationDetail } = this.state;
    const errors = validate(this.state);

    // let DateTime = '';

    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();

    const prevMonth = (month === 0) ? 11 : month - 1;
    const prevYear = (prevMonth === 11) ? year - 1 : year;
    // const nextMonth = (month === 11) ? 0 : month + 1;

    const minDate = new Date();

    minDate.setDate(today.getDate());
    minDate.setMonth(today.getMonth());
    minDate.setFullYear(prevYear);

    // DateTime = (
    //   <div className="col-md-4 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
    //     <label className="update-label">Trade Date *</label>
    //     <div>
    //       <Calendar readOnlyInput minDate={minDate} value={this.state.trade_date} onChange={(e) => this.changetrade_date(e)} placeholder="MM/DD/YYYY" yearNavigator yearRange="2018:2050" disabled={true}></Calendar>
    //     </div>
    //     {/* <input type="text" className="update-input" ref={(Elmt)=>{this.trade_date=Elmt}} value={this.state.trade_date}  placeholder="MM/DD/YYYY" onChange={this.handleAllChanges.bind(this)}/>*/}
    //     <label style={{ color: 'red', fontSize: 14 }} ref={(Elmt) => { this.trade_dateErr = Elmt; }}></label>
    //   </div>);
      
    const typeAheadUserMpidList = [];

    let brokerDealers = [];
    if (userMpid && userMpid.length > 0) {
      userMpid.map((item, i) => {
        typeAheadUserMpidList.push({ value: item.executing_broker_mpid, label: item.executing_broker_mpid });
        return (<option value={item.executing_broker_mpid} id={item.id} key={i}>
          {item.executing_broker_mpid}
        </option>);
      });
    }
    if (allocationDetail) {
      if (allocationDetail.broker_details && allocationDetail.broker_details.length > 0) {
        brokerDealers = allocationDetail.broker_details.map((item, i) => {
          return (
            <div key={i} className="col-md-4 col-sm-12" style={{ padding: '0px 30px 0px 0px' }}>
              <label className="update-label">{item.name} (%)</label>
              <div>
                <TextField type="text" id={item.broker_dealer_id} fullWidth className="update-input BrokerCommission" value={item.commission_percentage} placeholder="commission" onChange={(event) => this.changeBrokerValue(item, event)} disabled={true} />
              </div>
              <label style={{ color: "red", fontSize: 14 }}></label>
            </div>
          );
        });
      }
    }

    let review = '';
    review = (
      <div>
        <div className="row" style={{ padding: '0px 20px 0px 20px' }}>
          {
            brokerDealers
          }
        </div>
      </div>
    );

    const typeAheadOfferingList = [];
    if (offerings && offerings.length > 0) {
      offerings.map((item) => {
        typeAheadOfferingList.push({ value: item.id, label: item.name })
        return (
          <option value={item.id} key={item.id}>
            {item.name}
          </option>
        );
      });
    }

    return (
      <Dashboard title="ClickIPO Admin - Update Allocation"  >
        <div className="allocation_content">
          <div>
            <div className="allocation_Detail_header">
              <div className="headingname-show-allocation" >
                <ArrowBackIosIcon onClick={() => this.goToBack()} style={{cursor: 'pointer'}} /> Allocation Detail
              </div>
            </div>


            <div className="create-allocation-form" style={{marginTop: 40}}>
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <label className="update-label">Offering name *</label>
                  <div>
                    <TextField type="text" fullWidth value={this.state.offering_name} placeholder="" readOnly />
                  </div>
                </div>
                {/* {
                  DateTime
                } */}
                <div className="col-md-4 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
                  <label className="update-label">Trade Date *</label>
                  <div>
                    <Calendar readOnlyInput minDate={minDate} value={this.state.trade_date} onChange={(e) => this.changetrade_date(e)} placeholder="MM/DD/YYYY" yearNavigator yearRange="2018:2050" disabled={true} style={{width: '100%'}}></Calendar>
                  </div>
                  {/* <input type="text" className="update-input" ref={(Elmt)=>{this.trade_date=Elmt}} value={this.state.trade_date}  placeholder="MM/DD/YYYY" onChange={this.handleAllChanges.bind(this)}/>*/}
                  <label style={{ color: 'red', fontSize: 14 }} ref={(Elmt) => { this.trade_dateErr = Elmt; }}></label>
                </div>
                <div className="col-md-4 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
                  <label className="update-label">Settlement Date *</label>
                  <div>
                    <Calendar readOnlyInput minDate={minDate} value={this.state.settlement_date} onChange={(e) => this.changeSettlementDate(e)} placeholder="MM/DD/YYYY" yearNavigator yearRange="2018:2050" disabled={true} style={{width: '100%'}}></Calendar>
                  </div>
                  {/* <input type="text" className="update-input" ref={(Elmt)=>{this.trade_date=Elmt}} value={this.state.trade_date}  placeholder="MM/DD/YYYY" onChange={this.handleAllChanges.bind(this)}/>*/}
                  <label style={{ color: 'red', fontSize: 14 }} ref={(Elmt) => { this.settlement_dateErr = Elmt; }}></label>
                </div>
                <div className="col-md-4 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
                  <label className="update-label">CUSIP ID *</label>
                  <div>
                    <TextField type="text" fullWidth ref={(Elmt) => { this.cusipid = Elmt; }} value={this.state.cusipid} placeholder="" onChange={this.handleAllChanges.bind(this)} disabled={true} />
                  </div>
                  <label style={{ color: 'red', fontSize: 14 }} ref={(Elmt) => { this.cusipidErr = Elmt; }}></label>
                  {this.state.isSubmit && errors && <label style={{ color: 'red', fontSize: 14 }}>{errors.cusipidErr}</label>}
                </div>
                <div className="col-md-4 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
                  <label className="update-label">Underwriter Requested Shares *</label>
                  <div>
                    <TextField type="text" fullWidth ref={(Elmt) => { this.asked_shares = Elmt; }} value={this.state.asked_shares} placeholder="" onChange={this.handleAllChanges.bind(this)} disabled={true} />
                  </div>
                  <label style={{ color: 'red', fontSize: 14 }} ref={(Elmt) => { this.asked_sharesErr = Elmt; }}></label>
                  {this.state.isSubmit && errors && <label style={{ color: 'red', fontSize: 14 }}>{errors.asked_sharesErr}</label>}
                </div>
                <div className="col-md-4 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
                  <label className="update-label">Received Shares *</label>
                  <div>
                    <TextField type="text" fullWidth ref={(Elmt) => { this.received_shares = Elmt; }} value={this.state.received_shares} placeholder="" onChange={this.handleAllChanges.bind(this)} disabled={true}/>
                  </div>
                  {/* <label style={{ color: "red", fontSize: 14 }} ref={(Elmt) => { this.received_sharesErr = Elmt }}></label> */}
                  {this.state.isSubmit && errors && <label style={{ color: 'red', fontSize: 14 }}>{errors.received_sharesErr}</label>}
                </div>

                <div className="col-md-4 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
                  <label className="update-label">Click IPO Shares (Shares to be allocated to customers) *</label>
                  <div>
                    <TextField type="text" fullWidth ref={(Elmt) => { this.amountOfShares = Elmt; }} value={this.state.amountOfShares} placeholder="" onChange={this.handleAllChanges.bind(this)} disabled={true} />
                  </div>
                  <label style={{ color: 'red', fontSize: 14 }} ref={(Elmt) => { this.amoutOfShareErr = Elmt; }}></label>
                  {this.state.isSubmit && errors && <label style={{ color: 'red', fontSize: 14 }}>{errors.amoutOfShareErr}</label>}
                </div>

                <div className="col-md-4 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
                  <label className="update-label">Final Price *</label>
                  <div>
                    <TextField type="text" fullWidth ref={(Elmt) => { this.final_price = Elmt }} value={this.state.final_price} placeholder="" onChange={this.handleAllChanges.bind(this)} disabled={true} />
                  </div>
                  <label style={{ color: 'red', fontSize: 14 }} ref={(Elmt) => { this.final_priceErr = Elmt }}></label>
                  {this.state.isSubmit && errors && <label style={{ color: 'red', fontSize: 14 }}>{errors.final_priceErr}</label>}
                </div>

                {/* <div className="row">  */}
                <div className="col-md-4 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
                  <label className="update-label">Underwriter MPID *</label>
                  <div>
                    <Select
                      className="allocationofffering"
                      name="selected_bd_underwriter"
                      id="bd_underwriter_mpid_id"
                      value={this.state.selected_bd_underwriter_mpid_id}
                      ref={(select) => { this.selectUserMpId = select; }}
                      onChange={this.mpIDhandleChange}
                      options={typeAheadUserMpidList}
                      isDisabled={true}
                    />
                  </div>
                  <label style={{ color: 'red', fontSize: 14 }} ref={(Elmt) => { this.selected_bd_underwriter_mpid_id = Elmt; }}></label>
                  {this.state.isSubmit && errors && <label style={{ color: 'red', fontSize: 14 }}>{errors.Errselected_bd_underwriter_mpid_id}</label>}
                </div>
                <div className="col-md-4 col-sm-12" style={{ padding: '0px 20px 0px 20px' }}>
                  <label className="update-label">Underwriter concession *</label>
                  <div>
                    <TextField type="text" fullWidth ref={(Elmt) => { this.Underwriter = Elmt; }} value={this.state.underwriter_concession_amount} onChange={this.handleAllChanges.bind(this)} placeholder="" disabled={true} />
                  </div>
                  <label style={{ color: 'red', fontSize: 14 }} ref={(Elmt) => { this.UnderwriterErr = Elmt; }}></label>
                  {this.state.isSubmit && errors && <label style={{ color: 'red', fontSize: 14 }}>{errors.UnderwriterErr}</label>}
                </div>
              </div>
              {
                review
              }
              {(this.state.isDsp===1 || this.state.isDsp===2) && 
              <div className="row">
                <div className="col-md-4 col-lg-4" style={{ padding: '0px 20px' }}>
                  <label className="update-label">DSP Underwriter concession ($) *</label>
                  <div>
                    <TextField type="text" fullWidth value={this.state.DspUnderwriterValue} onChange={(e) => this.handleDspUnderwriterValueChanges(e)} placeholder="" disabled={true} />
                  </div>
                  <label style={{ color: 'red', fontSize: 14, paddingRight: '14px' }} ref={(Elmt) => { this.DspUnderwriterErr = Elmt; }}></label>
                  {this.state.isSubmit && errors && <label style={{ color: 'red', fontSize: 14 }}>{errors.DspUnderwriterErr}</label>}
                </div>
                {
                  (this.state.isDsp === 2) &&
                  <div className="col-md-4 col-lg-4" style={{ padding: '0px 20px' }}>
                    <label className="update-label">DSP Shares *</label>
                    <div>
                      <TextField type="text" fullWidth value={this.state.dsp_click_shares} placeholder="" onChange={this.handledspClickSharesChanges.bind(this)} disabled={true} />
                    </div>
                    <label style={{ color: 'red', fontSize: 14, paddingRight: '14px' }} ref={(Elmt) => { this.dsp_click_sharesErr = Elmt; }}></label>
                    {this.state.isSubmit && errors && <label style={{ color: 'red', fontSize: 14, paddingRight: '14px' }}>{errors.dsp_click_sharesErr}</label>}
                  </div>
                }
                {
                  (this.state.isDsp === 2) &&
                  <div className="col-md-4 col-lg-4" style={{ padding: '0px 20px' }}>
                    <label className="update-label">IOI cutoff datetime  *</label>
                    <div>
                      <Calendar value={this.state.ioi_cutoff_datetime_show} ref={(Elmt) => { this.ioi_cutoff_datetime = Elmt; }} onChange={(e) => this.selectIoiCutoff(e)} minDate={minDate} placeholder="MM/DD/YYYY " showTime showSeconds disabled={true}/>
                    </div>
                    {this.state.isSubmit && errors && <label style={{ color: 'red', fontSize: 14, paddingRight: '14px' }}>{errors.ioi_cutoff_datetime}</label>}
                  </div>
                }
              </div>
            }
            </div>

            {/* { (showUpdateButton) &&
            <div style={{ padding: '0px 50px 0px 50px' }}>
              <div className="row">
                <div className="col col-sm-12 " style={{ marginBottom: '30px'}}>
                  <input
                    type="submit"
                    style={{
                      marginTop: '30px',
                      background: '#8dc73f',
                      padding: '13px 28px',
                      color: 'white',
                      marginRight: '70px',
                      float: 'right',
                    }}
                    value="Update"
                  />
                </div>
              </div>
            </div>
              } */}
          </div>
        </div>
      </Dashboard>
    );
  }
}

UpdateAllocationForm.propTypes = {
  handleSubmit: PropTypes.func,
  // handleContinue: PropTypes.func,
  submitting: PropTypes.bool,
  handleDateTimeSubmit: PropTypes.func,
  offerings: PropTypes.any,
  offering: PropTypes.any,
  // dispatch: PropTypes.any,
  AllocationData: PropTypes.any,
  getAllocationTypeRes: PropTypes.any,
  fetchCommission: PropTypes.any,
  getUpdateAllocationRes: PropTypes.any,
  UserMpid: PropTypes.any,
  fetchAllocation: PropTypes.any,
  postAllocation: PropTypes.any,
  sendEmailNotification: PropTypes.any,
  resetData: PropTypes.any,
  history: PropTypes.any,
  fetchUserMpid: PropTypes.any,

  
  allocationDetailRes: PropTypes.any,
  underWriterMpidListRes: PropTypes.any,
};

const clickIPOCreateUpdateAllocationForm = reduxForm({
  form: 'clickIPOCreateUpdateAllocationForm',
  validate,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  touchOnBlur: false,
})(UpdateAllocationForm);

const mapStateToProps = createStructuredSelector({
  allocationDetailRes: doFetchAllocationDetailRes,
  underWriterMpidListRes: underWriterMpidListRes,

});

function mapDispatchToProps(dispatch) {
  return {
    fetchUserMpid: () => dispatch(getUnderwriterMpid()),
    fetchAllocationDetail: (id) => dispatch(fetchAllocationDetail(id)),
    doFetchAllocationDetailRes: () => dispatch(doFetchAllocationDetailRes(null)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(clickIPOCreateUpdateAllocationForm));
