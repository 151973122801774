import * as types from './actionTypes';  
import offeringApi from '../api/offeringApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import { loadingRes } from './loadingActions';


export function doOffering(request, loading) {

  return {
    type: types.DO_OFFERING,
    request,
    loading
  };
}

export function doOfferingActiveRes(data) {  
  return {
    type: types.DO_OFFERING_ACTIVE_RES, 
    data
  };
}
export function doOfferingEffectiveRes(data) {  
  return {
    type: types.DO_OFFERING_EFFECTIVE_RES, 
    data
  };
}
export function doOfferingCancelledRes(data) {  
  return {
    type: types.DO_OFFERING_CANCELLED_RES, 
    data
  };
}
export function doOfferingClosedRes(data) {  
  return {
    type: types.DO_OFFERING_CLOSED_RES, 
    data
  };
}

export function getOffering(request, loading) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      if (loading) {
        dispatch(loadingRes(true));
      }
      offeringApi.doOffering(request).then(data => {
        if (request.status === 'active') {
          dispatch(doOfferingActiveRes(data));
          dispatch(doOfferingActiveRes(null));
        }
        if (request.status === 'effective') {
          dispatch(doOfferingEffectiveRes(data));
          dispatch(doOfferingEffectiveRes(null));
        }
        if (request.status === 'cancelled') {
          dispatch(doOfferingCancelledRes(data));
          dispatch(doOfferingCancelledRes(null));
        }
        if (request.status === 'closed') {
          dispatch(doOfferingClosedRes(data));
          dispatch(doOfferingClosedRes(null));
        }
        if (loading) {
          dispatch(loadingRes(false));
        }
      }).catch(error => {
        if (loading) {
          dispatch(loadingRes(false));
        }
        return error
      });
    };
  }else{
    logout()
  }
}

