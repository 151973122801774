import React from 'react';
import PropTypes from 'prop-types';
import Dashboard from '../../component/Dashboard';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import _ from 'lodash';
class BrokerDealerCustomerGraph extends React.Component {

    constructor() {
        super();
        this.state = {
            customerList: [],
        };

    }

    componentDidMount() {
        let customerData = this.props.location.state;
        this.setState({
            customerList: customerData
        })
    }
	renderTooltip = (item) => {
		if (item && item.payload && item.payload.length > 0) {
			return <div style={{ padding: 8, background: '#fff' }}>
				<p style={{ fontWeight: 'bold', fontSize: 15, color: '#000' }}>{item.payload[0].payload.bd}</p>
				<p style={{ fontWeight: 'bold', fontSize: 15, color: '#0A5074' }}>Customers: {item.payload[0].payload.customers}</p>
			</div>
		}

	}

    goBack = () => {
        this.props.history.goBack();
    }
    render() {
        const { customerList } = this.state;
        let chartData = [];
        let customerData = [];
        for (let item of customerList) {
            if (item.broker_dealer_name) {
                customerData.push(item);
            }
        }
        let customers = [];
        if (customerData) {
            customers = _.chain(customerData)
                  .groupBy("broker_dealer_name")
                  .map((value, key) => ({ bd: key, users: value }))
                  .value()
            for (let itm of customers) {
                itm.customers = itm.users.length
            }
        }
        chartData = customers;
        return (
            <Dashboard title="ClickIPO Customers" pageTitle="Customers Graph" >
				<div className="customer_section_content">
                    <div className="col-md-12 mt-2">
                        <div className="customer-page_header">
                            <div className="Headingname">
                                <ArrowBackIosIcon onClick={() => this.goBack()} style={{ cursor: 'pointer' }} /> Back to customers
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-5">
                        <div style={{width: '100%', height: '100%'}}>
                            <div >
                                <ResponsiveContainer width='100%' height={550} >
                                    <BarChart
                                        data={chartData}
                                        margin={{
                                            top: 5, right: 30, left: 20, bottom: 5,
                                          }}
                                          barSize={20}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="bd" tick={{fontSize: 13}} />
                                        <YAxis label={{ value: 'Number of users', angle: -90, position: 'insideLeft' }} />
                                        <Tooltip
                                        content={this.renderTooltip}
                                        wrapperStyle={{
                                            borderColor: 'white',
                                            boxShadow: '2px 2px 3px 0px rgb(204, 204, 204)',
                                            background: 'white'
                                        }}
                                        contentStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
                                        labelStyle={{ fontWeight: 'bold', color: '#666666' }}
                                        />
                                        <Bar dataKey="customers" fill="#8884d8" background={{ fill: '#eee' }} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </Dashboard>
        );
    }
}

BrokerDealerCustomerGraph.propTypes = {
    customerList: PropTypes.any,
};

export default BrokerDealerCustomerGraph;
