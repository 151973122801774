import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { currency, numberWithCommas, getCommaSeparatedTwoDecimalsNumber } from '../../helper/helper';
import NumberFormat from 'react-number-format';
import Dashboard from '../../component/Dashboard';
import { getItem } from '../../utils/localStore';
import { fetchShowAllocations, doFetchShowAllocationRes } from '../../actions/showAllocationActions';
import { fetchShowAllocationMetaData, doFetchShowAllocationMetadataRes } from '../../actions/showAllocationMetaDataActions';
import { saveAllocation, doSaveAllocationRes } from '../../actions/saveAllocationActions';
import { sendCompleteAllocation, doCompleteAllocationRes } from '../../actions/completeAllocationActions';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import SortIcon from '@material-ui/icons/Sort';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { DialogActions } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import GridTable from '@nadavshaar/react-grid-table';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { decryptData } from '../../utils/util';
const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(),
        top: theme.spacing(),
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
let isShowAllocation = false;
let showAllocationData;
let Total_allocated_shares = 0;
let Total_allocated_amount = 0;
let Total_requested_shares = 0;
let Total_requested_amount = 0;
let isGrater = false;
let isSave = true;
let allocatedMoreThanRequested = false;
let last_allocated_shares = 0;
let last_allocated_amount = 0;
let filterData = [];
let tempNewAllocations = [];
class ShowAllocationsList extends React.Component {
    constructor(props) {
        super(props);
        isShowAllocation = false;
        isGrater = false;
        isSave = true;
        allocatedMoreThanRequested = false;
        var columns = [
            { id: 'serial', label: 'Serial#', width: '100px', visible: true, pinned: true, sortable: false, cellRenderer: this.serialNumber },
            { id: 1, field: 'name', label: 'User Name', visible: true, sortable: false, cellRenderer: this.keyNamesTemplate, headerCellRenderer: this.userCustomHeader },
            { id: 2, field: 'account_name', label: 'User Account', visible: true, sortable: false, cellRenderer: this.allocatesAccountTemplate, headerCellRenderer: this.userCustomHeader },
            { id: 3, field: 'email', label: 'Email', visible: true, sortable: false, cellRenderer: this.allocatesEmailTemplate, headerCellRenderer: this.userCustomHeader },
            { id: 4, field: 'mpid', label: 'Mpid', visible: true, sortable: false, cellRenderer: this.keyNamesTemplate, headerCellRenderer: this.userCustomHeader },
            { id: 5, field: 'broker_dealer_name', label: 'Broker Dealer Name', visible: true, width: '250px', sortable: false, cellRenderer: this.keyNamesTemplate, headerCellRenderer: this.userCustomHeader },
            { id: 6, field: 'allocated_shares', label: 'Allocated Shares', visible: true, width: '250px', sortable: false, cellRenderer: this.allocatedSharesTemplate, headerCellRenderer: this.userCustomHeader },
            { id: 7, field: 'allocated_amount', label: 'Allocated Amount', visible: true, width: '250px', sortable: false, cellRenderer: this.allocatesAmountTemplate, headerCellRenderer: this.userCustomHeader },
            { id: 8, field: 'requested_shares', label: 'Requested Shares', visible: true, width: '250px', sortable: false, cellRenderer: this.requestedSharesTemplate, headerCellRenderer: this.userCustomHeader },
            { id: 9, field: 'requested_amount', label: 'Requested Amount', visible: true, width: '250px', sortable: false, cellRenderer: this.requestedAmountTemplate, headerCellRenderer: this.userCustomHeader },
            { id: 10, field: 'buying_power', label: 'Buying Power', width: '250px', visible: true, sortable: false, cellRenderer: this.buyingpowerTemplate, headerCellRenderer: this.userCustomHeader },
            { id: 11, field: 'dsp', label: 'DSP User', visible: true, sortable: false, cellRenderer: this.dspTemplate, headerCellRenderer: this.userCustomHeader }
        ];
        if (JSON.parse(localStorage.getItem('columnArr'))) {
            let colArr = JSON.parse(localStorage.getItem('columnArr'));
            for (let item of columns) {
                for (let col of colArr) {
                    if (col.id === item.id) {
                        item.visible = col.visible
                    }
                }
            }
        }
        this.state = {
            isReadOnly: JSON.parse(getItem('isReadOnly')),
            cols: columns,
            isSubmit: null,
            ext_id: '',
            width: 1200,
            gloableSearch: '',
            isAllocatedChange: null,
            isUIrender: null,
            Total_initial_shares: 0,
            Total_allocated_amount: 0,
            allocatedShares: 0,
            final_price: 0,
            click_shares: 0,
            updateStates: false,
            isSaveConfirm: false,
            isComplete: false,
            isCompleteConfirm: false,
            showAllocationShare: false,
            doSortData: null,
            isSort: null,
            sortField: null,
            isOpenEdit: false,
            tableFilter: {
                field: null,
                order: null,
            },
            offeringName: '',
            showAllocations: [],
            tabIndex: 0,
            showAllocationMeta: null,
            pagenum: 1,
            resetPageNum: -1,
            lastShowAllocations: 0,
            isFetchShowAllocation: false,
            filterBy: 'name',
            allocationShowLength: 0,
            selectedBrokers: 'all',
            brokerDealerList: [],
            showGreaterAllocatedAmount: false,
            isSortingConfirm: false,
            isShowAll: false,
            isSortSearch: '',
            loadMoreAll: 'more',
            updateStatesForEdit: false,
            showLotAllocationData: null,
            isLoadAll: false,
            offeringType: '',
        };

        this.colOptions = [];

        for (const col of columns) {
            this.colOptions.push({ label: col.label, value: col });
        }

        this.SharesEditor = this.SharesEditor.bind(this);
        this.inputTextEditor = this.inputTextEditor.bind(this);
        this.closeSaveConfirmModal = this.closeSaveConfirmModal.bind(this);
        this.closeCompleteConfirmModal = this.closeCompleteConfirmModal.bind(this);
        this.resetSuccessFinalize = this.resetSuccessFinalize.bind(this);
        this.openCompleteModal = this.openCompleteModal.bind(this);
        this.closeCompleteModal = this.closeCompleteModal.bind(this);
        this.sortingRemote = this.sortingRemote.bind(this);
        this.doSort = this.doSort.bind(this);
    }
    componentDidMount() {
        let mkLocalData = localStorage.getItem('allocationKeys');
        const originalDataAllocation = decryptData(mkLocalData);
        if (originalDataAllocation) {
            this.setState({
                ext_id: originalDataAllocation.allocationInfo.ext_id,
                final_price: originalDataAllocation.allocationInfo.final_price,
                click_shares: originalDataAllocation.allocationInfo.click_shares,
                editAllocation: originalDataAllocation.isEdit,
                offeringName: originalDataAllocation.allocationInfo.name,
                tabIndex: originalDataAllocation.tabIndex,
                offeringType: originalDataAllocation.allocationInfo.type
            }, () => {
                if (this.state.isReadOnly) {
                    this.setState({
                        editAllocation: false,
                    })
                }
            });
            let allocationExtId = originalDataAllocation.allocationInfo.ext_id;
            let param = {
                ext_id: allocationExtId,
                pagenum: this.state.pagenum
            }
            isShowAllocation = true;
            this.props.fetchShowAllocationMetaData(allocationExtId)
            this.props.fetchShowAllocations(param, true)
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.showAllocationRes) {
            if (props.showAllocationRes.data && props.showAllocationRes.data.showAllocation) {
                if (props.showAllocationRes.data.showAllocation.message === 'Success' && isShowAllocation) {
                    isShowAllocation = false;
                    isSave = true;
                    let newData = props.showAllocationRes.data.showAllocation.data;

                    var isAllocationShowLength = state.allocationShowLength;
                    if (state.allocationShowLength === 0) {
                        isAllocationShowLength = newData.length
                    }

                    if (state.isFetchShowAllocation) {
                        let concatData = state.showAllocations.concat(newData);
                        return {
                            lastShowAllocations: newData.length,
                            allocationShowLength: isAllocationShowLength,
                            showAllocations: concatData
                        };
                    } else {
                        return {
                            lastShowAllocations: newData.length,
                            allocationShowLength: isAllocationShowLength,
                            showAllocations: newData
                        };
                    }
                }
            }
        }
        if (props.showAllocationMetadataRes) {
            if (props.showAllocationMetadataRes.data && props.showAllocationMetadataRes.data.showAllocationMetaData) {
                if (props.showAllocationMetadataRes.data.showAllocationMetaData.message === 'Success') {
                    Total_allocated_shares = props.showAllocationMetadataRes.data.showAllocationMetaData.data.allocated_shares;
                    Total_allocated_amount = props.showAllocationMetadataRes.data.showAllocationMetaData.data.allocated_amount;
                    Total_requested_shares = props.showAllocationMetadataRes.data.showAllocationMetaData.data.requested_shares;
                    Total_requested_amount = props.showAllocationMetadataRes.data.showAllocationMetaData.data.requested_amount;
                    return {
                        showAllocationMeta: props.showAllocationMetadataRes.data.showAllocationMetaData.data,
                        brokerDealerList: props.showAllocationMetadataRes.data.showAllocationMetaData.data.broker_dealer,
                        showLotAllocationData: props.showAllocationMetadataRes.data.showAllocationMetaData.data,
                    };
                }
            }
        }
        if (props.saveAllocationRes) {
            if (props.saveAllocationRes.data && props.saveAllocationRes.data.saveAllocation) {
                if (props.saveAllocationRes.data.saveAllocation.message === 'Success') {
                    isSave = true;
                    let param = {
                        ext_id: state.ext_id,
                        pagenum: state.isLoadAll ? -1 : 1
                    }
                    isShowAllocation = true;
                    props.fetchShowAllocations(param, true);
                    props.fetchShowAllocationMetaData(state.ext_id);
                    isGrater =  false;
                    allocatedMoreThanRequested = false;
                    return {
                        allocationShowLength: 0,
                        showAllocations: [],
                        isSaveConfirm: false,
                        pagenum: state.isLoadAll ? -1 : 1,
                        tableFilter: {
                            field: null,
                            order: null
                        },
                        gloableSearch: ''
                    };
                }
            }
        }
        if (props.completeAllocationRes) {
            if (props.completeAllocationRes.data && props.completeAllocationRes.data.completeAllocation) {
                if (props.completeAllocationRes.data.completeAllocation.message === 'Success') {
                    setTimeout(() => {
                        props.history.goBack();
                    }, 200);
                    return {
                        isCompleteConfirm: false,
                        tableFilter: {
                            field: null,
                            order: null,
                        },
                    };
                }
            }
        }
        return null;
    }
    componentWillUnmount() {
        localStorage.setItem('columnArr', JSON.stringify(this.state.cols));
        localStorage.removeItem('allocationKeys');
    }
    userCustomHeader = ({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => {
        return <div>{column.label} {this.onSortFunction(column.field)}</div>
    }
    serialNumber = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
        return (
            <div className='rgt-cell-inner' style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                <span className='rgt-text-truncate'>{rowIndex}</span>
            </div>
        )
    }
    keyNamesTemplate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
        return <div className="rgt-cell-inner rgt-text-truncate" style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
            <Tooltip title={value !== undefined ? value : ''}  interactive>
                <div className="text-one-line" style={{width: '90%'}}>{value}</div>
            </Tooltip>
            {
                value !== '' &&
                <Tooltip title={'Copy to clipboard'} interactive >
                    <Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small"/> </Button>
                </Tooltip>
            }
        </div>;
    }
    buyingpowerTemplate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
        if (Number(data.allocated_amount) > Number(data.buying_power)) {
            data.greater = true;
        } else {
            data.greater = false;
        }

        if (data.allocated_amount > data.requested_amount) {
            data.allocatedMore = true;
        } else {
            data.allocatedMore = false;
        }

        return <div className={(data.greater === true || data.allocatedMore === true ? 'rgt-cell-inner rgt-text-truncate update-error' : 'rgt-cell-inner rgt-text-truncate')} style={{ display: 'inline-flex', width: '100%' }} >
            <div style={{ float: 'left', width: '15px' }}>{'$'}</div>
            <div style={{ float: 'right', width: '100%', textAlign: 'right' }}>{getCommaSeparatedTwoDecimalsNumber(data.buying_power)}</div>
        </div>;
    }


    allocatesEmailTemplate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
        if (Number(data.allocated_amount) > Number(data.buying_power)) {
            data.greater = true;
        } else {
            data.greater = false;
        }

        if (data.allocated_amount > data.requested_amount) {
            data.allocatedMore = true;
        } else {
            data.allocatedMore = false;
        }
        return <div className={'rgt-cell-inner rgt-text-truncate ' + (data.greater === true || data.allocatedMore === true ? 'update-error' : '')} style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
            <Tooltip title={value !== undefined ? value : ''}  interactive>
                <div className="text-one-line" style={{width: '90%'}}>{value}</div>
            </Tooltip>
            {
                value !== '' &&
                <Tooltip title={'Copy to clipboard'} interactive >
                    <Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small"/> </Button>
                </Tooltip>
            }
        </div>;
    }

    allocatesAccountTemplate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
        if (Number(data.allocated_amount) > Number(data.buying_power)) {
            data.greater = true;
        } else {
            data.greater = false;
        }

        if (data.allocated_amount > data.requested_amount) {
            data.allocatedMore = true;
        } else {
            data.allocatedMore = false;
        }
        return <div className={'rgt-cell-inner rgt-text-truncate ' + (data.greater === true || data.allocatedMore === true ? 'update-error' : '')} style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
            <Tooltip title={value !== undefined ? value : ''}  interactive>
                <div className="text-one-line" style={{width: '90%'}}>{value}</div>
            </Tooltip>
            {
                value !== '' &&
                <Tooltip title={'Copy to clipboard'} interactive >
                    <Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small"/> </Button>
                </Tooltip>
            }
        </div>;
    }
    allocatesAmountTemplate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
        if (data.allocated_amount !== null) {
            data.allocated_amount = Number(data.allocated_amount);
        }

        if (Number(data.allocated_amount) > Number(data.buying_power)) {
            data.greater = true;
        } else {
            data.greater = false;
        }

        if (data.allocated_amount > data.requested_amount) {
            data.allocatedMore = true;
        } else {
            data.allocatedMore = false;
        }


        return <div className={(data.greater === true || data.allocatedMore === true ? 'rgt-cell-inner rgt-text-truncate update-error' : 'rgt-cell-inner rgt-text-truncate')} style={{ display: 'inline-flex', width: '100%' }} >
            <div style={{ float: 'left', width: '15px' }}>{'$'}</div>
            <div style={{ float: 'right', width: '100%', textAlign: 'right' }}>{getCommaSeparatedTwoDecimalsNumber(data.allocated_amount !== null ? data.allocated_amount : 0)}</div>
        </div>;
    }

    requestedAmountTemplate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {

        return <div className={(data.greater === true ? 'rgt-cell-inner rgt-text-truncate update-error' : 'rgt-cell-inner rgt-text-truncate')} style={{ display: 'inline-flex', width: '100%' }}>
            <div style={{ float: 'left', width: '15px' }}>{'$'}</div>
            <div style={{ float: 'right', width: '100%', textAlign: 'right' }}>{getCommaSeparatedTwoDecimalsNumber(data.requested_amount)}</div>
        </div>;
    }
    requestedSharesTemplate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
        if (Number(data.allocated_amount) > Number(data.buying_power)) {
            data.greater = true;
        } else {
            data.greater = false;
        }

        if (data.allocated_amount > data.requested_amount) {
            data.allocatedMore = true;
        } else {
            data.allocatedMore = false;
        }
        return <div className={'rgt-cell-inner rgt-text-truncate ' + (data.greater === true || data.allocatedMore === true ? 'update-error' : '')} style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
            <Tooltip title={value !== undefined ? numberWithCommas(value) : ''}  interactive>
                <div className="text-one-line" style={{width: '90%'}}>{numberWithCommas(value)}</div>
            </Tooltip>
            {
                value !== '' &&
                <Tooltip title={'Copy to clipboard'} interactive >
                    <Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small"/> </Button>
                </Tooltip>
            }
        </div>;
    }
    allocatedSharesTemplate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {

        return <div className="rgt-cell-inner rgt-text-truncate">
            {
                (this.state.editAllocation && data.dsp === 0) &&
                <div>
                    {
                        (!data.isOpenEdit) &&
                        <div>
                            <p style={{ cursor: 'pointer', fontWeight: 'bold', color: '#689F38' }} onClick={() => { this.changeEditField(data, rowIndex); }}>{numberWithCommas(data.allocated_shares)}</p>
                        </div>
                    }
                    {
                        (data.isOpenEdit) &&
                        <div>
                            {this.state.editAllocation ? this.SharesEditor(data) : false}
                        </div>
                    }
                </div>
            }
            {
                (this.state.editAllocation && data.dsp === 2) &&
                <div><p>{numberWithCommas(data.allocated_shares)}</p></div>
            }
            {
                (!this.state.editAllocation) &&
                <div><p>{numberWithCommas(data.allocated_shares)}</p></div>
            }
        </div>;
    }
    dspTemplate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
        return <div className="rgt-cell-inner rgt-text-truncate">
            {
                data.dsp === 0 &&
                <div></div>
            }
            {
                data.dsp === 1 &&
                <div> Directed Allocation </div>
            }
            {
                data.dsp === 2 &&
                <div> DSP </div>
            }
        </div>
    }
    onEditorValueChange(data, value) {
        // if (this.state.isShowAll) {
        //     if (Number(data.allocated_shares) !== Number(value)) {
        //         this.setState({
        //             isEditShare: true
        //         })
        //     }
        // }
        if (data.allocated_shares === '0' || data.allocated_shares === '' || data.allocated_shares === 0) {
            data.allocated_shares = '';
        }
        if (Number(data.allocated_amount) > Number(data.buying_power)) {
            data.greater = true;
            isSave = true;
        } else {
            if (this.state.isUIrender) {
                isSave = false;
            }
        }
        data.isEdit = true;
        data.allocated_shares = value;
        isGrater = false;
        this.setState({ isAllocatedChange: false, isUIrender: !this.state.isUIrender }, () => { });
        let lastData = (data.allocated_shares === '' || data.allocated_shares === null) ? 0 : Number(data.allocated_shares);
        // if (lastData) {
        if (Number(lastData) !== Number(last_allocated_shares)) {
            let new_Total_allocated_shares = Number(Total_allocated_shares) - Number(last_allocated_shares);
            Total_allocated_shares = new_Total_allocated_shares + Number(lastData);
            let newAmount = Number(lastData) * Number(this.state.final_price);
            let new_Total_allocated_amount = Number(Total_allocated_amount) - Number(last_allocated_amount)
            Total_allocated_amount = new_Total_allocated_amount + Number(newAmount);
            this.setState({ isUIrender: !this.state.isUIrender }, () => {

            });
            last_allocated_shares = lastData;
            last_allocated_amount = newAmount;
        }
        // }
        setTimeout(() => {
            isGrater = false;
            this.setState({
                updateStates: true,
            });

        }, 500);
    }

    doSort(field, order) {
        if (this.state.showAllocations) {
            if (this.state.pagenum !== -1) {
                this.setState({
                    tableFilter: {
                        field,
                        order,
                    },
                }, () => {
                    tempNewAllocations = [];
                    for (const items of this.state.showAllocations) {
                        if (items.isEdit && items.allocated_shares === '') {
                            tempNewAllocations.push({ email: items.email, allocated_amount: items.allocated_amount, allocated_shares: Number('0'), buying_power: items.buying_power, name: items.name, account_name: items.account_name });
                        } else if (items.isEdit && items.allocated_shares !== '') {
                            tempNewAllocations.push({ email: items.email, allocated_amount: items.allocated_amount, allocated_shares: Number(items.allocated_shares), buying_power: items.buying_power, name: items.name, account_name: items.account_name });
                        }
                        isSave = false;
                        this.setState({
                            isSaveConfirm: false,
                        });
                    }

                    if (tempNewAllocations.length > 0) {
                        this.setState({
                            isSortingConfirm: true,
                            isSortSearch: 'sort'
                        })
                    } else {
                        if (order === null || order === 'asc') {
                            this.setState({
                                isSort: 'desc',
                                tableFilter: {
                                    field,
                                    order,
                                },
                            }, () => { });
                            let payload = {
                                ext_id: this.state.ext_id,
                                pagenum: 1,
                                sortby: field,
                                sorttype: order
                            }
                            if (this.state.gloableSearch !== '') {
                                payload.search_key = this.state.filterBy;
                                payload.search_keyword = this.state.gloableSearch;
                            }
                            isShowAllocation = true;
                            this.props.fetchShowAllocations(payload, true);
                            filterData = [];
                            isGrater =  false;
                            allocatedMoreThanRequested = false;
                            this.setState({
                                pagenum: payload.pagenum,
                                showAllocations: [],
                                allocationShowLength: 0,
                                lastShowAllocations: 0
                            })
                        } else {
                            this.setState({
                                isSort: 'asc',
                                tableFilter: {
                                    field,
                                    order,
                                },
                            }, () => { });
                            let payload = {
                                ext_id: this.state.ext_id,
                                pagenum: 1,
                                sortby: field,
                                sorttype: order,
                            }
                            if (this.state.gloableSearch !== '') {
                                payload.search_key = this.state.filterBy;
                                payload.search_keyword = this.state.gloableSearch;
                            }
                            isShowAllocation = true;
                            this.props.fetchShowAllocations(payload, true);
                            filterData = [];
                            isGrater =  false;
                            allocatedMoreThanRequested = false;
                            this.setState({
                                pagenum: payload.pagenum,
                                showAllocations: [],
                                allocationShowLength: 0,
                                lastShowAllocations: 0
                            })
                        }
                    }
                })
            } else {
                if (order === null || order === 'asc') {
                    this.setState({
                        tableFilter: {
                            field,
                            order,
                        },
                    }, () => { });
                    showAllocationData = showAllocationData.sort((a, b) => (a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0));
                    this.setState({
                        isUIrender: !this.state.isUIrender
                    });
                } else {
                    this.setState({
                        tableFilter: {
                            field,
                            order,
                        },
                    }, () => { });
                    showAllocationData = showAllocationData.sort((a, b) => (a[field] < b[field] ? 1 : a[field] > b[field] ? -1 : 0));
                    this.setState({
                        isUIrender: !this.state.isUIrender
                    });
                }
            }

        }
    }

    SharesEditor(data) {
        data.allocated_amount = Number(data.allocated_shares) * Number(this.state.final_price);
        if (data.allocated_amount > data.requested_amount) {
            data.greater = true;
            data.allocatedMore = true;
            isSave = true;
            allocatedMoreThanRequested = true;
        } else {
            data.greater = false;
            data.allocatedMore = false;
            isSave = false;
            allocatedMoreThanRequested = false;
        }
        return this.inputTextEditor(data, 'allocated_shares');
    }

    sortingRemote(data) {
        if (data.allocated_shares === '') {
            data.allocated_shares = 0;
        }
        data.isOpenEdit = false;
        this.setState({
            isOpenEdit: false
        }, () => { });
    }

    inputTextEditor(data) {
        if (Number(data.allocated_amount) > Number(data.buying_power) || data.allocated_amount > data.requested_amount) {
            data.greater = true;
        } else {
            data.greater = false;
        }
        return <NumberFormat value={data.allocated_shares} onValueChange={(values) => {
            const { formattedValue } = values;
            this.onEditorValueChange(data, formattedValue)
        }} onBlur={(e) => { this.sortingRemote(data); }} style={{ width: '100%' }} />
    }

    saveAllocations() {
        tempNewAllocations = [];
        for (const items of this.state.showAllocations) {
            if (items.isEdit && items.allocated_shares === '') {
                tempNewAllocations.push({ email: items.email, allocated_amount: items.allocated_amount, allocated_shares: Number('0'), buying_power: items.buying_power, name: items.name, account_name: items.account_name });
            } else if (items.isEdit && items.allocated_shares !== '') {
                tempNewAllocations.push({ email: items.email, allocated_amount: items.allocated_amount, allocated_shares: Number(items.allocated_shares), buying_power: items.buying_power, name: items.name, account_name: items.account_name });
            }
            isSave = false;
            this.setState({
                isSaveConfirm: false,
            });
        }

        const sendAllocationRequest = {
            ext_id: this.state.ext_id,
            data_str: tempNewAllocations,
        };
        if (sendAllocationRequest.data_str.length > 0) {
            this.props.saveAllocationData(sendAllocationRequest);
        }

        this.setState({
            updateStates: false,
        });
    }

    openSaveConfirmModal = () => {
        this.setState({
            isSaveConfirm: true,
        });
    }

    closeSaveConfirmModal = () => {
        isSave = false;
        this.setState({
            isSaveConfirm: false,
        });
    }


    completeAllocations() {
        this.props.completeAllocation(this.state.ext_id);
        this.openCompleteModal();
    }

    openCompleteModal = () => {
        this.setState({
            isComplete: true,
        });
    }

    closeCompleteModal = () => {
        this.setState({
            isComplete: false,
        });
    }

    openCompleteConfirmModal = () => {
        this.setState({
            isCompleteConfirm: true,
        });
    }

    closeCompleteConfirmModal = () => {
        this.setState({
            isCompleteConfirm: false,
        });
    }
    resetSuccessFinalize() {
        this.setState({
            tableFilter: {
                field: null,
                order: null,
            },
        }, () => { });
        setTimeout(() => {
            this.props.history.goBack();
        }, 200);
    }

    changeEditField = (item, index) => {
        for (let i = 0; i < showAllocationData.length; i++) {
            if (showAllocationData[i] !== index) {
                showAllocationData[i].isOpenEdit = false
            }
        }
        item.isOpenEdit = true;
        last_allocated_shares = item.allocated_shares;
        last_allocated_amount = item.allocated_amount;
        this.setState({
            isOpenEdit: true,
        }, () => { });
    }

    onSortFunction(field) {
        return (<span>
            {
                ((this.state.tableFilter.field !== field)) &&
                <IconButton onClick={() => { this.doSort(field, 'asc'); }} style={{ padding: 6 }}>
                    <SortIcon style={{ fontSize: 18 }} />
                </IconButton>
            }
            {
                ((this.state.tableFilter.order === 'asc') && (this.state.tableFilter.field === field)) &&
                <IconButton className="btn-custom-sort" onClick={() => { this.doSort(field, 'desc'); }} style={{ padding: 6 }}>
                    <ArrowDropUpIcon style={{ fontSize: 18 }} />
                </IconButton>
            }

            {
                ((this.state.tableFilter.order === 'desc') && (this.state.tableFilter.field === field)) &&
                <IconButton className="btn-custom-sort" onClick={() => { this.doSort(field, 'asc'); }} style={{ padding: 6 }}>
                    <ArrowDropDownIcon style={{ fontSize: 18 }} />
                </IconButton>
            }
        </span>);
    }
    goToBack = () => {
        this.setState({
            tableFilter: {
                field: null,
                order: null,
            },
        }, () => { });
        this.props.resetshowAllocations();
        this.props.history.push({
            pathname: `/allocations`,
            state: {
                tabIndex: this.state.tabIndex,
            }
        });
    }

    onColumnToggle = (event) => {
        this.setState({ selectedCols: event.value });
        let column = this.state.cols
        var z = column.filter(function (val) {
            return event.value.indexOf(val) === -1;
        });
        var y = column.filter(function (val) {
            return event.value.indexOf(val) !== -1;
        });
        for (let item of column) {
            for (let items of z) {
                if (item.field === items.field) {
                    item.visible = false
                }
            }
        }
        for (let item of column) {
            for (let items of y) {
                if (item.field === items.field) {
                    item.visible = true
                }
            }
        }
        this.setState({ cols: column });
    }
    fetchData = () => {
        if (this.state.lastShowAllocations === this.state.allocationShowLength) {
            let request = {
                ext_id: this.state.ext_id,
                pagenum: this.state.pagenum + 1
            }
            if (this.state.tableFilter.field !== null) {
                request.sortby = this.state.tableFilter.field;
                request.sorttype = this.state.tableFilter.order;
            }
            if (this.state.gloableSearch !== '') {
                request.search_key = this.state.filterBy;
                request.search_keyword = this.state.gloableSearch;
            }
            this.setState({
                pagenum: request.pagenum,
                isFetchShowAllocation: true,
                lastShowAllocations: 0
            });
            isShowAllocation = true;
            this.props.fetchShowAllocations(request, true);
        }
    }
    doSearchAllocation = () => {
        if (this.state.pagenum !== -1) {
            tempNewAllocations = [];
            for (const items of this.state.showAllocations) {
                if (items.isEdit && items.allocated_shares === '') {
                    tempNewAllocations.push({ email: items.email, allocated_amount: items.allocated_amount, allocated_shares: Number('0'), buying_power: items.buying_power, name: items.name, account_name: items.account_name });
                } else if (items.isEdit && items.allocated_shares !== '') {
                    tempNewAllocations.push({ email: items.email, allocated_amount: items.allocated_amount, allocated_shares: Number(items.allocated_shares), buying_power: items.buying_power, name: items.name, account_name: items.account_name });
                }
                isSave = false;
                this.setState({
                    isSaveConfirm: false,
                });
            }
            if (tempNewAllocations.length > 0) {
                this.setState({
                    isSortSearch: 'search',
                    isSortingConfirm: true
                })
            } else {
                if (this.state.gloableSearch !== '') {
                    let request = {
                        ext_id: this.state.ext_id,
                        pagenum: 1,
                        search_key: this.state.filterBy,
                        search_keyword: this.state.gloableSearch
                    }
                    if (this.state.tableFilter.field !== null) {
                        request.sortby = this.state.tableFilter.field;
                        request.sorttype = this.state.tableFilter.order;
                    }
                    isShowAllocation = true;
                    this.props.fetchShowAllocations(request, true);
                    filterData = [];
                    isGrater =  false;
                    allocatedMoreThanRequested = false;
                    this.setState({
                        pagenum: request.pagenum,
                        showAllocations: [],
                        allocationShowLength: 0,
                        lastShowAllocations: 0
                    })
                }
            }
        }
    }
    dropDownSearch = (event) => {
        this.setState({
            filterBy: event.target.value,
        }, () => {
        });
    }
    hendleSearch = (event) => {
        if (this.state.filterBy === 'email') {
            this.setState({
                gloableSearch: event.target.value.toLowerCase().trim(),
            }, () => {
            });
        } else {
            this.setState({
                gloableSearch: event.target.value,
            }, () => {
            });
        }
    }
    doRefreshAllocation = () => {
        let request = {
            ext_id: this.state.ext_id,
            pagenum: 1
        }
        if (this.state.tableFilter.field !== null) {
            request.sortby = this.state.tableFilter.field;
            request.sorttype = this.state.tableFilter.order;
        }
        filterData = [];
        isGrater =  false;
        allocatedMoreThanRequested = false;
        this.setState({
            pagenum: request.pagenum,
            showAllocations: [],
            allocationShowLength: 0,
            lastShowAllocations: 0
        });
        isShowAllocation = true;
        this.props.fetchShowAllocations(request, true);
    }
    handleBlur = () => {
        this.setState({
            gloableSearch: ''
        }, () => {
            if (this.state.pagenum !== -1) {
                this.doRefreshAllocation();
            }
        });
    }

    changeBrokers = (e) => {
        this.setState({
            selectedBrokers: e.target.value
        }, () => {
            if (this.state.selectedBrokers !== 'all') {
                for (let item of this.state.brokerDealerList) {
                    if (item.name === this.state.selectedBrokers) {
                        Total_allocated_shares = item.allocated_shares;
                        Total_allocated_amount = item.allocated_amount;
                        Total_requested_shares = item.requested_shares;
                        Total_requested_amount = item.requested_amount;
                        this.setState({ showLotAllocationData: item })
                    }
                }
                this.setState({ isUIrender: !this.state.isUIrender })
            } else {
                Total_allocated_shares = this.state.showAllocationMeta.allocated_shares;
                Total_allocated_amount = this.state.showAllocationMeta.allocated_amount;
                Total_requested_shares = this.state.showAllocationMeta.requested_shares;
                Total_requested_amount = this.state.showAllocationMeta.requested_amount;
                this.setState({ showLotAllocationData: this.state.showAllocationMeta })
                this.setState({ isUIrender: !this.state.isUIrender })
            }
        })
    }
    showGreaterAmount = () => {
        this.setState({
            showGreaterAllocatedAmount: true,
        }, () => {
            if (this.state.showGreaterAllocatedAmount) {
                filterData = filterData.filter((row) => Number(row.allocated_amount) > Number(row.buying_power) && Number(row.allocated_amount) !== 0);
                this.setState({
                    isUIrender: !this.state.isUIrender
                })
            }
        });
    }
    closeGreaterAmount = () => {
        this.setState({
            showGreaterAllocatedAmount: false,
        });
    }
    loadMore = () => {
        filterData = [];
        this.fetchData();
        this.setState({ loadMoreAll: 'more' });
    }
    loadAllData = () => {
        tempNewAllocations = [];
        for (const items of this.state.showAllocations) {
            if (items.isEdit && items.allocated_shares === '') {
                tempNewAllocations.push({ email: items.email, allocated_amount: items.allocated_amount, allocated_shares: Number('0'), buying_power: items.buying_power, name: items.name, account_name: items.account_name });
            } else if (items.isEdit && items.allocated_shares !== '') {
                tempNewAllocations.push({ email: items.email, allocated_amount: items.allocated_amount, allocated_shares: Number(items.allocated_shares), buying_power: items.buying_power, name: items.name, account_name: items.account_name });
            }
            isSave = false;
        }
        if (tempNewAllocations.length > 0) {
            this.setState({
                isSortingConfirm: true,
                isLoadAll: true
            })
        } else {
            this.showAll();
        }
    }
    showAll = () => {
        filterData = [];
        isGrater =  false;
        allocatedMoreThanRequested = false;
        this.setState({
            isShowAll: true,
            showAllocations: [],
            loadMoreAll: 'all'
        }, () => {
            let param = {
                ext_id: this.state.ext_id,
                pagenum: -1
            }
            isShowAllocation = true;
            this.props.fetchShowAllocations(param, true);
            this.setState({
                isUIrender: !this.state.isUIrender,
                pagenum: -1,
                gloableSearch: '',
                isFetchShowAllocation: false
            })
        })

    }
    closeSortingConfirmModal = () => {
        this.setState({
            isSortingConfirm: false,
        })
    }
    doSortField = () => {
        this.saveAllocations();
        this.setState({
            isSortingConfirm: false
        }, () => {
            Total_allocated_shares = this.state.showAllocationMeta.allocated_shares;
            Total_allocated_amount = this.state.showAllocationMeta.allocated_amount;
            Total_requested_shares = this.state.showAllocationMeta.requested_shares;
            Total_requested_amount = this.state.showAllocationMeta.requested_amount;
        })
    }
    doAbandoned = () => {
        this.setState({
            isSortingConfirm: false
        }, () => {
            let param = {
                ext_id: this.state.ext_id,
                pagenum: this.state.isLoadAll ? -1 : 1
            }
            isShowAllocation = true;
            this.props.fetchShowAllocations(param, true);
            filterData = [];
            isGrater =  false;
            allocatedMoreThanRequested = false;
            this.setState({
                isUIrender: !this.state.isUIrender,
                pagenum: 1,
                gloableSearch: '',
                showAllocations: [],
                allocationShowLength: 0,
                lastShowAllocations: 0,
                isFetchShowAllocation: false

            });
            Total_allocated_shares = this.state.showAllocationMeta.allocated_shares;
            Total_allocated_amount = this.state.showAllocationMeta.allocated_amount;
            // Total_requested_shares = this.state.showAllocationMeta.requested_shares;
            // Total_requested_amount = this.state.showAllocationMeta.requested_amount;
        })
    }
    customFooter = () => {
        return <div style={{ fontWeight: 'bold', padding: '12px 20px' }}>
            <span>
                Total Allocated Shares:
                {
                    Total_allocated_shares &&
                    <span style={{ paddingLeft: 5 }}>{numberWithCommas(Total_allocated_shares)}</span>
                } |
                Total Allocated Amount:
                {
                    (Total_allocated_amount || Total_allocated_amount === 0) &&
                    <span style={{ paddingLeft: 5 }}>${getCommaSeparatedTwoDecimalsNumber(Total_allocated_amount)}</span>
                } |
                Total Requested Shares:
                {
                    Total_requested_shares &&
                    <span style={{ paddingLeft: 5 }}>{numberWithCommas(Total_requested_shares)}</span>
                } |
                Total Requested Amount:
                {
                    Total_requested_amount &&
                    <span style={{ paddingLeft: 5 }}>${getCommaSeparatedTwoDecimalsNumber(Total_requested_amount)}</span>
                }
            </span>
        </div>
    }

    onColumnShowHide = (columns) => {
        this.setState({
            cols: columns
        }, () => {
            localStorage.setItem('columnArr', JSON.stringify(this.state.cols));
        })
    }
    render() {
        const { showAllocations, editAllocation } = this.state;
        showAllocationData = showAllocations;
        for (const items of showAllocationData) {
            if (items.greater) {
                isGrater = true;
            }
        }
        if (showAllocationData && !this.state.showGreaterAllocatedAmount) {
            filterData = [];
            for (let item of showAllocationData) {
                if (this.state.selectedBrokers !== 'all') {
                    if (item.broker_dealer_name === this.state.selectedBrokers) {
                        filterData.push(item);
                    }
                } else {
                    filterData.push(item);
                }
            }
            if (this.state.pagenum === -1) {
                filterData = this.state.gloableSearch ? filterData.filter((row) => row.name.toLowerCase().indexOf(this.state.gloableSearch.toLowerCase()) > -1 || row.email.toLowerCase().indexOf(this.state.gloableSearch.toLowerCase()) > -1 || row.account_name.toLowerCase().indexOf(this.state.gloableSearch.toLowerCase()) > -1 || row.mpid.toLowerCase().indexOf(this.state.gloableSearch.toLowerCase()) > -1 || row.broker_dealer_name.toLowerCase().indexOf(this.state.gloableSearch.toLowerCase()) > -1) : filterData;
                //  || row.allocated_shares.toString().toLowerCase().indexOf(this.state.gloableSearch.toLowerCase()) > -1 || row.allocated_amount.toString().toLowerCase().indexOf(this.state.gloableSearch.toLowerCase()) > -1 || row.requested_shares.toString().toLowerCase().indexOf(this.state.gloableSearch.toLowerCase()) > -1 || row.requested_amount.toString().toLowerCase().indexOf(this.state.gloableSearch.toLowerCase()) > -1 || row.buying_power.toString().toLowerCase().indexOf(this.state.gloableSearch.toLowerCase()) > -1
            }
        }

        return (
            <Dashboard title="ClickIPO Admin - Show Allocation" pageTitle="Show Allocation" >
                <div className="allocation_content_show_allocation">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="headingname-show-allocation"><ArrowBackIosIcon onClick={() => this.goToBack()} style={{ cursor: 'pointer' }} /> {this.state.offeringName} </div>
                        </div>

                    </div>
                    <div className="show-allocation-body-section">
                        <div className="row" style={{ paddingTop: 20, paddingBottom: 20 }}>
                            <div className="col-md-3">
                                <div style={{ textAlign: 'left' }}>
                                    {/* <MultiSelect value={this.state.cols} options={this.colOptions} onChange={this.onColumnToggle} style={{ width: '250px' }} /> */}
                                </div>
                                <div className="mt-2">
                                    {
                                        this.state.showGreaterAllocatedAmount &&
                                        <div className="greater-buying-power">
                                            Greater buying power
                                            <div className="close-greater-buying-power" onClick={() => this.closeGreaterAmount()}>&times;</div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                {
                                    this.state.showAllocationMeta &&
                                    <div style={{ textAlign: 'center' }}>
                                        <p style={{ display: 'inline-flex' }}>
                                            <span className="dataTableTotal" style={{ paddingLeft: 20 }}>Orders: {this.state.showAllocationMeta.count} </span>
                                        </p>
                                        <p style={{ display: 'inline-flex' }}>
                                            <span className="dataTableTotal" style={{ paddingLeft: 20 }}>Final Offering Price: ${this.state.final_price} </span>
                                        </p>
                                        <br />
                                        <p style={{ display: 'inline-flex' }}>
                                            <span className="dataTableTotal" style={{ paddingLeft: 20 }}>Click Shares: {this.state.click_shares} </span>
                                        </p>
                                        <p style={{ display: 'inline-flex' }}>
                                            <span className="dataTableTotal" style={{ paddingLeft: 20 }}>Total Shares Allocated: {this.state.showAllocationMeta.allocated_shares === null ? 0 : this.state.showAllocationMeta.allocated_shares} </span>
                                        </p>
                                        <br />
                                        <p style={{ display: 'inline-flex' }}>
                                            <span className="dataTableTotal" style={{ paddingLeft: 20 }}>Round lot allocated: {this.state.showLotAllocationData['round_lot_allocated'] === null ? 0 : this.state.showLotAllocationData['round_lot_allocated']} </span>
                                        </p>
                                        <p style={{ display: 'inline-flex' }}>
                                            <span className="dataTableTotal" style={{ paddingLeft: 20 }}>2500 lot allocated: {this.state.showLotAllocationData['2500_lot_allocated'] === null ? 0 : this.state.showLotAllocationData['2500_lot_allocated']} </span>
                                        </p>
                                    </div>
                                }
                            </div>
                            <div className="col-md-3">
                                <div style={{ textAlign: 'right' }}>
                                    <TextField
                                        type="text"
                                        placeholder="Search.."
                                        onChange={(e) => this.hendleSearch(e)}
                                        value={this.state.gloableSearch}
                                        style={{ width: 250 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    {
                                                        this.state.gloableSearch !== '' &&
                                                        <Button variant="contained" color="primary" style={{ borderRadius: '0px 4px 4px 0px', minWidth: 30, top: 19, right: 0, left: 8, height: 38, background: 'transparent', color: '#333' }} onClick={() => this.handleBlur()}  ><CloseIcon className="close-icon-for-search" /></Button>
                                                    }
                                                    <Button variant="contained" color="primary" style={{ borderRadius: '0px 4px 4px 0px', minWidth: 50, top: 19, right: 0, left: 8, height: 38 }} onClick={() => this.doSearchAllocation()} disabled={this.state.searching !== '' ? false : true}><SearchIcon /></Button>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <div className="offeringGlobalSearch mt-2" style={{ textAlign: 'left' }}>
                                        {/* <div className="filterbyContent">Search</div>
                                        <select className="selectField" value={this.state.filterBy} onChange={this.dropDownSearch} style={{background: 'transparent'}} >
                                            <option value="name" >Name</option>
                                            <option value="email" >Email</option>
                                            <option value="broker_dealer_name">Broker Dealer</option>
                                        </select> */}
                                        <FormControl style={{ width: 250 }}>
                                            <Select
                                                labelId="brokerdealer-label"
                                                id="brokerdealer"
                                                value={this.state.selectedBrokers}
                                                onChange={this.changeBrokers}
                                            >
                                                <MenuItem value={'all'}>{'All broker dealers'}</MenuItem>
                                                {
                                                    this.state.brokerDealerList && this.state.brokerDealerList.map((broker, i) =>
                                                        <MenuItem key={i} value={broker.name}>{broker.name}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="show-allocation-body-content">

                            <div className="table-responsive">
                                <GridTable
                                    columns={this.state.cols}
                                    rows={filterData}
                                    isPaginated={false}
                                    isVirtualScroll={true}
                                    showSearch={false}
                                    showRowsInformation={false}
                                    isHeaderSticky={true}
                                    onColumnsChange={this.onColumnShowHide}
                                    components={{ Footer: this.customFooter }}
                                />
                            </div>
                            <div className="row" style={{ paddingRight: 30, paddingTop: 10 }}>
                                <div className="col-md-3">
                                    {
                                        (this.state.lastShowAllocations === this.state.allocationShowLength) ?
                                            <Button variant="contained" color="primary" onClick={() => this.loadMore()} style={{ marginRight: 8, marginBottom: 8 }} disabled={this.state.pagenum === -1}>Load more</Button>
                                            :
                                            <Button variant="contained" color="primary" style={{ marginRight: 8, marginBottom: 8 }} disabled={true}>No more data</Button>
                                    }
                                    <Button variant="contained" color="primary" style={{ marginBottom: 8 }} onClick={() => this.loadAllData()} disabled={this.state.pagenum === -1 ? true : false}>Load all</Button>

                                </div>
                                {editAllocation && <div className="col-md-6">
                                    {isGrater && <p className="ErrorMessage" style={{ textAlign: 'left', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.showGreaterAmount()}>Allocated amount is greater than buying power, please change the allocated shares</p>}
                                    {allocatedMoreThanRequested && <p className="ErrorMessage" style={{ textAlign: 'left' }}>Allocating more than what the customer is requesting</p>}
                                </div>
                                }
                                {
                                    editAllocation && showAllocationData && showAllocationData.length > 0 && this.state.offeringType !== 'Warrant' &&
                                    <div className="col-md-3">
                                        <Button disabled={isSave} variant="contained" color="secondary" onClick={() => this.openSaveConfirmModal()} style={{ marginRight: 8, marginBottom: 8 }}>Save</Button>
                                        <Button disabled={this.state.updateStates} variant="contained" color="primary" style={{ marginBottom: 8 }} onClick={() => this.openCompleteConfirmModal()}>Finalize</Button>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                    <Dialog
                        onClose={this.closeSaveConfirmModal}
                        aria-labelledby="customized-dialog-title"
                        open={this.state.isSaveConfirm}
                        maxWidth={"sm"}
                        fullWidth={true}
                        scroll={'paper'}
                    >
                        <DialogTitle onClose={this.closeSaveConfirmModal} >Confirmation</DialogTitle>
                        <DialogContent>
                            <div className="col-md-12" style={{ textAlign: 'center' }}>
                                <h5>Are you sure you want to submit the changes?</h5>
                            </div>
                        </DialogContent>
                        <DialogActions style={{ display: 'block' }}>
                            <div style={{ textAlign: 'center' }}>
                                <Button style={{ marginLeft: 10, marginRight: 10 }} variant="contained" size="large" color="primary" onClick={() => this.saveAllocations()}> Save </Button>
                                <Button style={{ marginLeft: 10, marginRight: 10 }} variant="contained" size="large" color="secondary" onClick={() => this.closeSaveConfirmModal()}> Cancel </Button>
                            </div>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        onClose={this.closeCompleteConfirmModal}
                        aria-labelledby="customized-dialog-title"
                        open={this.state.isCompleteConfirm}
                        maxWidth={"sm"}
                        fullWidth={true}
                        scroll={'paper'}
                    >
                        <DialogTitle onClose={this.closeCompleteConfirmModal} >Confirmation</DialogTitle>
                        <DialogContent>
                            <div className="col-md-12" style={{ textAlign: 'center' }}>
                                <h5> Please confirm this is the final version of this allocation. You have allocated  {Total_allocated_shares} shares at a price of $ {this.state.final_price} for a total of $ {currency(Total_allocated_amount)}</h5>
                            </div>
                        </DialogContent>
                        <DialogActions style={{ display: 'block' }}>
                            <div style={{ textAlign: 'center' }}>
                                <Button style={{ marginLeft: 10, marginRight: 10 }} variant="contained" size="large" color="primary" onClick={() => this.completeAllocations()}> Finalize </Button>
                                <Button style={{ marginLeft: 10, marginRight: 10 }} variant="contained" size="large" color="secondary" onClick={() => this.closeCompleteConfirmModal()}> Cancel </Button>
                            </div>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        onClose={this.closeSortingConfirmModal}
                        aria-labelledby="customized-dialog-title"
                        open={this.state.isSortingConfirm}
                        maxWidth={"sm"}
                        fullWidth={true}
                        scroll={'paper'}
                    >
                        <DialogTitle onClose={this.closeSortingConfirmModal} >Confirmation</DialogTitle>
                        <DialogContent>
                            <div className="col-md-12" style={{ textAlign: 'center' }}>
                                <h5>You have made changes to the allocated shares. Do you want to save your work?</h5>
                            </div>
                        </DialogContent>
                        <DialogActions style={{ display: 'block' }}>
                            <div style={{ textAlign: 'center' }}>
                                <Button style={{ marginLeft: 10, marginRight: 10 }} variant="contained" size="large" color="primary" onClick={() => this.doSortField()}> Save </Button>
                                <Button style={{ marginLeft: 10, marginRight: 10 }} variant="contained" size="large" color="secondary" onClick={() => this.doAbandoned()}>Abandon</Button>
                            </div>
                        </DialogActions>
                    </Dialog>
                </div>
            </Dashboard>
        )
    }
}

ShowAllocationsList.propTypes = {
    showAllocationRes: PropTypes.any,
    showAllocationMetadataRes: PropTypes.any,
    completeAllocationRes: PropTypes.any,
    saveAllocationRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    showAllocationRes: doFetchShowAllocationRes,
    showAllocationMetadataRes: doFetchShowAllocationMetadataRes,
    saveAllocationRes: doSaveAllocationRes,
    completeAllocationRes: doCompleteAllocationRes,

});

function mapDispatchToProps(dispatch) {
    return {
        fetchShowAllocations: (param, isLoading) => { dispatch(fetchShowAllocations(param, isLoading)) },
        fetchShowAllocationMetaData: (ext_id) => { dispatch(fetchShowAllocationMetaData(ext_id)) },
        saveAllocationData: (data) => { dispatch(saveAllocation(data)) },
        completeAllocation: (id) => { dispatch(sendCompleteAllocation(id)) },
        resetshowAllocations: () => { dispatch(doFetchShowAllocationRes(null)) },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllocationsList);
