import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './admin.scss';
import Dashboard from '../../component/Dashboard';
import BrokerdealersComponent from './brokerdealers/brokerdealers';
import UnderwritersComponent from './underwriters/underwriters';
import ConfigComponent from './config/config';
import MessagesComponent from './messages/messages';
import OfferingImpactComponent from './offeringImpact/offeringImpact';
import WhiteListedIPsComponent from './whiteListedIPs/whiteListedIPs';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

class AdminComponent extends React.PureComponent {

	constructor() {
		super();
		this.state = {
			tabIndex: 0,
		}
	}
	componentDidMount() {
		let activeTab = localStorage.getItem('settingTab');
		if (activeTab) {
			this.setState({
				tabIndex: Number(activeTab)
			}, () => {
			});
		}
		if (this.props.location.state) {
			if (this.props.location.state.tabIndex) {
				this.setState({
					tabIndex: this.props.location.state.tabIndex
				}, () => {
				});
			}
		}
	}
	componentWillUnmount() {
		localStorage.removeItem('settingTab');
	}
	handleTabChange = (event, newValue) => {
		localStorage.setItem('settingTab', newValue);
		this.setState({
			tabIndex: newValue,
		}, () => { });
	}
	render() {
		const { tabIndex } = this.state;

		return (
			<Dashboard title="ClickIPO Admin" pageTitle="System Setting" >
				<div className="admin_offering_content">
					<div>
						
						<Tabs value={tabIndex} onChange={this.handleTabChange} style={{ padding: '20px 0px 0px 0px' }}>
							<Tab label="Broker Dealers" />
							<Tab label="Underwriters" />
							<Tab label="Messages" />
							<Tab label="Config" />
							<Tab label="Offering Impact" />
							<Tab label="WhiteListed IPs" />
						</Tabs>
						{
							tabIndex === 0 &&
							<Typography component="div" style={{ paddingTop: 24, paddingBottom: 24 }} className="offering-box-conent">
								<div style={{ maxWidth: "100%" }}>
									<BrokerdealersComponent />
								</div>
							</Typography>
						}
						{
							tabIndex === 1 &&
							<Typography component="div" style={{ paddingTop: 24, paddingBottom: 24 }} className="offering-box-conent">
								<div style={{ maxWidth: "100%" }}>
									<UnderwritersComponent tabIndex={tabIndex} history={this.props.history} />
								</div>
							</Typography>
						}
						{
							tabIndex === 2 &&
							<Typography component="div" style={{ paddingTop: 24, paddingBottom: 24 }} className="offering-box-conent">
								<div style={{ maxWidth: "100%" }}>
									<MessagesComponent tabIndex={tabIndex} history={this.props.history}/>
								</div>
							</Typography>
						}
						{
							tabIndex === 3 &&
							<Typography component="div" style={{ paddingTop: 24, paddingBottom: 24 }} className="offering-box-conent">
								<div style={{ maxWidth: "100%" }}>
									<ConfigComponent />
								</div>
							</Typography>
						}
						{
							tabIndex === 4 &&
							<Typography component="div" style={{ paddingTop: 24, paddingBottom: 24 }} className="offering-box-conent">
								<div style={{ maxWidth: "100%" }}>
									<OfferingImpactComponent />
								</div>
							</Typography>
						}
						{
							tabIndex === 5 &&
							<Typography component="div" style={{ paddingTop: 24, paddingBottom: 24 }} className="offering-box-conent">
								<div style={{ maxWidth: "100%" }}>
									<WhiteListedIPsComponent tabIndex={tabIndex} history={this.props.history} />
								</div>
							</Typography>
						}
					</div>
				</div>
			</Dashboard>
		)
	}
}

AdminComponent.propTypes = {

};

const mapStateToProps = createStructuredSelector({
});

function mapDispatchToProps(dispatch) {
	return {
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AdminComponent);
