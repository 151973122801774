import CryptoJS from 'crypto-js';
import { SECRET_KEY } from '../constants';
export const encryptData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
}

export const decryptData = (data) => {
    const bytes = CryptoJS.AES.decrypt(data, SECRET_KEY);
    try {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    catch (err) {
        return null;
    }
}