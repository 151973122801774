import * as types from './actionTypes';  
import runAllocationApi from '../api/runAllocationApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doRunAllocation(request) {

  return {
    type: types.DO_RUN_ALLOCATION, 
    request
  };
}

export function doRunAllocationRes(data) {  
  return {
    type: types.DO_RUN_ALLOCATION_RES, 
    data
  };
}
export function sendRunAllocation(request) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      runAllocationApi.doRunAllocation(request).then(data => {
        if (data.error === 0) {
          dispatch(doRunAllocationRes(data));
          dispatch(doRunAllocationRes(null));
        }
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
  
}