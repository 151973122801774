import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import '../admin.scss';
import { getItem } from '../../../utils/localStore';
import { fetchWhiteListedIPs, doFetchWhiteListedIPsRes, deleteWhiteListedIP, doDeleteWhiteListedIpRes } from '../../../actions/whitelistedIPsActions';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import GridTable from '@nadavshaar/react-grid-table';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { DialogActions } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
	}))(props => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});
  
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);
let isWhiteList = false;
let isDeleteIP = false;
class WhiteListedIPsComponent extends React.PureComponent {
	constructor(props) {
		super(props);
		isWhiteList = false;
		isDeleteIP = false;
		const columns = [
			{ id: 1, width: '33.33%', field: 'ip_host', label: 'Host IP', visible: true, sortable: true, cellRenderer: this.descriptionField },
			{ id: 2, width: '33.33%', field: 'description', label: 'Description', visible: true, sortable: false, cellRenderer: this.descriptionField },
			{ id: 3, width: '33.33%', field: 'action', label: 'Action', visible: true, sortable: false, cellRenderer: this.actionField }
		];
		this.state = {
			cols: columns,
			isReadOnly: JSON.parse(getItem('isReadOnly')),
			whiteListedIPsList: [],
			isOpenDeleteIPModal: false,
			selectedIP: ''
		}
	}

	componentDidMount() {
		isWhiteList = true;
		this.props.fetchWhiteListedIPs();
	}

	static getDerivedStateFromProps(props, state) {
		if (props.whiteListedIPsRes) {
			if (props.whiteListedIPsRes.data && props.whiteListedIPsRes.data.whiteListedIPs) {
				if (props.whiteListedIPsRes.data.whiteListedIPs.message === 'Success' && isWhiteList) {
					isWhiteList = false;
					let arrData = props.whiteListedIPsRes.data.whiteListedIPs.data;
                    for (let i = 0; i < arrData.length; i++) {

                        Object.keys(arrData[i]).forEach((field) => { /*eslint-env es6*/
                        if (arrData[i][field] === null) {
                            arrData[i][field] = "";
                        }
                        })
                    };
					return {
						whiteListedIPsList: arrData
					};
				}
			}
		}
		if (props.deleteWhiteListedIpRes) {
			if (props.deleteWhiteListedIpRes.data && props.deleteWhiteListedIpRes.data.whiteListedIPs) {
				if (props.deleteWhiteListedIpRes.data.whiteListedIPs.message === 'Success' && isDeleteIP) {
					isDeleteIP = false;
					state.whiteListedIPsList.splice(state.whiteListedIPsList.indexOf(state.whiteListedIPsList.find((row) => row.ip_host === state.selectedIP)), 1);
				}
			}
		}
		return null
	}
	
	descriptionField = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return <div className="rgt-cell-inner rgt-text-truncate" style={{overflow: 'hidden', display: 'flex', alignItems: 'center'}}>
			{
				(value !== '' && value !== undefined) &&
				<Tooltip title={value !== undefined ? value : ''}  interactive>
					<div className="text-one-line" style={{width: '90%'}}>{value}</div>
				</Tooltip>
			}
			{
				(value !== '' && value !== undefined) &&
				<Tooltip title={'Copy to clipboard'} interactive >
					<Button style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small"/> </Button>
				</Tooltip>
			}
		</div>;
	}
	actionField = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return <div className="rgt-cell-inner">
			{
				(!this.state.isReadOnly) ?
				<Button component={Link} to={{ pathname: `/update-whitelistedIP`, state: { whitelistedIP: data, tabIndex: this.props.tabIndex } }} variant="contained" color="primary" style={{ margin: '5px' }} ><EditIcon /></Button>
				:
				<Button variant="contained" color="primary" style={{ margin: '5px' }} disabled ><EditIcon /></Button>
			}
			{
				(!this.state.isReadOnly) ?
				<Button variant="contained" color="secondary" style={{ margin: '5px' }} onClick={() => this.openDeleteIPModal(data)} ><DeleteIcon /></Button>
				:
				<Button variant="contained" color="secondary" style={{ margin: '5px' }} disabled ><DeleteIcon /></Button>
			}	
		</div>;
	}
	goToCreateNewIP = () => {
		this.props.history.push({
			pathname: '/create-whitelistedIP',
			state: {
				tabIndex: this.props.tabIndex
			}
		})
	}
	openDeleteIPModal = (item) => {
		this.setState({
			isOpenDeleteIPModal: true,
			selectedIP: item.ip_host
		});
	}
	closeDeleteIPModal = () => {
		this.setState({
			isOpenDeleteIPModal: false
		});
	}
	deleteIP = () => {
		this.setState({
			isOpenDeleteIPModal: false
		});
		let request = {
			'ip_host': this.state.selectedIP
		}
		isDeleteIP = true;
		this.props.deleteWhiteListedIP(request);
	}
	
	render() {
		const { whiteListedIPsList, isReadOnly } = this.state;
		return (
			<div className="newMessageListStyle">
				<div>
					<div className="row">
						<div className="col-md-12">
							<div style={{textAlign: 'right'}}>
								{
									(!isReadOnly) &&
									<Button variant="contained" color="primary" onClick={() => this.goToCreateNewIP()}  >Add New Host IP</Button>
								}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12 message-system-setting-table" style={{ paddingTop: 15, paddingBottom: 15 }}>
							<GridTable
								columns={this.state.cols}
								rows={whiteListedIPsList}
								isPaginated={true}
								isVirtualScroll={true}
								showSearch={true}
								showRowsInformation={false}
								isHeaderSticky={true}
							/>
						</div>
					</div>
					<Dialog
						onClose={this.closeDeleteIPModal}
						aria-labelledby="customized-dialog-title"
						open={this.state.isOpenDeleteIPModal}
						maxWidth={"sm"}
						fullWidth={true}
						scroll={'paper'}
						>
						<DialogTitle onClose={this.closeDeleteIPModal} className="offering-detail-title">Delete IP</DialogTitle>
						<DialogContent>
							<div className="col-md-12 editModelText" style={{textAlign: 'center'}}>
								<h5>Are you sure you want to delete this record ?</h5>
							</div>
						</DialogContent>
						<DialogActions style={{display: 'block'}}>
							<div style={{textAlign: 'center'}}>
								<Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.deleteIP()}>
									Confirm
								</Button>
								<Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="secondary" onClick={() => this.closeDeleteIPModal()}>
									Cancel
								</Button>
							</div>
						</DialogActions>
					</Dialog>
				</div>
			</div>
		)
	}
}

WhiteListedIPsComponent.propTypes = {
	whiteListedIPsRes: PropTypes.any,
	deleteWhiteListedIpRes: PropTypes.any,
	history: PropTypes.any,
};
const mapStateToProps = createStructuredSelector({
	whiteListedIPsRes: doFetchWhiteListedIPsRes,
	deleteWhiteListedIpRes: doDeleteWhiteListedIpRes,
});
function mapDispatchToProps(dispatch) {
	return {
		fetchWhiteListedIPs: () => dispatch(fetchWhiteListedIPs()),
		deleteWhiteListedIP: (data) => dispatch(deleteWhiteListedIP(data)),
	};
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(WhiteListedIPsComponent);
