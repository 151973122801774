import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './updateOffering.scss';
import Dashboard from '../../component/Dashboard';
import UpdateOfferingForm from './updateOfferingForm/updateOfferingForm';
import { doUpdateOfferingRes } from '../../actions/updateOfferingActions';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

class UpdateOfferingComponent extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            offeringData: props.location.state.item,
            tabIndex: props.location.state.tabIndex,
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.updateOfferingRes) {
            if (props.updateOfferingRes.data && props.updateOfferingRes.data.updateOffering) {
                if (props.updateOfferingRes.data.updateOffering.message === "Success") {
                    props.history.goBack();
                }
            }
        }
        return null;
    }
    handleBack = () => {
        // this.props.history.goBack();
        this.props.history.push({
            pathname: `/offerings`,
            state: {
                tabIndex: this.state.tabIndex,
            }
        });
    }

    handleSubmit(data) {
        this.setState({ isLoader: false });
    }

    render() {
        return (
            <Dashboard title="ClickIPO Admin - Update Offering" pageTitle="Update Offering" >
                <div className="create_offering_content_wrapper create_offering">
                    <div className="createprofile_heading">
                        <div className="createprofile_back_icon_text"  >
                            <span className="createprofile_go_back"><ArrowBackIosIcon onClick={this.handleBack} style={{ cursor: 'pointer' }} /> Back to offering</span>
                        </div>
                    </div>
                    <div className="editprofile_content">
                        <div className="form_content_editprofile edit_profile_form_fields_wrapper">
                            <UpdateOfferingForm handleFormSubmit={(data) => { this.handleSubmit(data) }} offeringData={this.state.offeringData} />
                        </div>
                    </div>
                </div>
            </Dashboard>
        )
    }
}

UpdateOfferingComponent.propTypes = {
    handleFormSubmit: PropTypes.func,
    updateOfferingRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    updateOfferingRes: doUpdateOfferingRes,
});

function mapDispatchToProps(dispatch) {
    return {
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(UpdateOfferingComponent);
