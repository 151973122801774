export default function validate(values) {
  const errors = {};
  if (!values.oldHostIP) {
    errors.oldHostIP = 'Please enter old host ip';
  }
  if (!values.hostIP) {
    errors.hostIP = 'Please enter host ip';
  }
  if (!values.description) {
    errors.description = 'Please enter description';
  }
  return errors;
}
