import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import styles from './sidebar-jss';
import ListIcon from '@material-ui/icons/List';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import BookmarksRoundedIcon from '@material-ui/icons/BookmarksRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MessageIcon from '@material-ui/icons/Message';
import { logout } from '../../helper/helper';
import { getItem } from '../../utils/localStore';
const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
  return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
});

let page="";
// eslint-disable-next-line
class MainMenu extends React.Component {
  openurl(geturl) {
    const url = geturl;
    window.open(url, '_blank');
  }
  componentDidMount() {
    page =window.location.pathname;
    page =page.split('/');
  }
  render() {
    const {
      classes
    } = this.props;
    const LEVEL = getItem('level');
    return (
      <div>
        {
          (LEVEL === 0 || LEVEL === '0' || LEVEL === 3 || LEVEL === '3') &&
          <div>
            <ListItem
              button
              className={classNames(classes.head, page[1] === 'offerings' || page[1] === 'update-offering' || page[1] === 'create-offering' || page[1] === 'orders' || page[1] === 'orders-buildout' || page[1] === 'brokerdealer-order' || page[1] === 'offering-callbacks' ? ` ${classes.active}` : '')}
              component={LinkBtn}
              to={{pathname: "/offerings",
              DSPView: true}}
            >
              <ListItemIcon className={classes.icon}>
                <ListIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.primary }} invariant="inset" primary={'Offerings'} />
            </ListItem>

            <ListItem
              button
              className={classNames(classes.head, page[1] === 'allocations' || page[1] === 'show-allocation' || page[1] === 'create-allocation' || page[1] === 'allocation-detail' || page[1] === 'pricing-wire' ? ` ${classes.active}` : '')}
              component={LinkBtn}
              to={{pathname: "/allocations",
              DSPView: true}}
            >
              <ListItemIcon className={classes.icon}>
                <AttachMoneyIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.primary }} invariant="inset" primary={'Allocations'} />
            </ListItem>

            <ListItem
              button
              className={classNames(classes.head, page[1] === 'customers' || page[1] === 'customer-graph' ? ` ${classes.active}` : '')}
              component={LinkBtn}
              to={{pathname: "/customers",
              DSPView: true}}
            >
              <ListItemIcon className={classes.icon}>
                <BookmarksRoundedIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.primary }} invariant="inset" primary={'Customers'} />
            </ListItem>

            <ListItem
              button
              className={classNames(classes.head, page[1] === 'issuers' || page[1] === 'create-issuer' ? ` ${classes.active}` : '')}
              component={LinkBtn}
              to={{pathname: "/issuers",
              DSPView: true}}
            >
              <ListItemIcon className={classes.icon}>
                <MessageIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.primary }} invariant="inset" primary={'Issuer Comm'} />
            </ListItem>

            <ListItem
              button
              className={classNames(classes.head, page[1] === 'system-setting' || page[1] === 'brokerdealer-admin' || page[1] === 'create-broker-admin' || page[1] === 'create-underwriter-admin' || page[1] === 'underwriter-admin' || page[1] === 'create-brokerdealer' || page[1] === 'create-underwriter' || page[1] === 'create-message' || page[1] === 'update-message' ? ` ${classes.active}` : '')}
              component={LinkBtn}
              to={{pathname: "/system-setting",
              DSPView: true}}
            >
              <ListItemIcon className={classes.icon}>
                <SettingsApplicationsIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.primary }} invariant="inset" primary={'System setting'} />
            </ListItem>

            <ListItem
              button
              className={classNames(classes.head, page[1] === 'stats' || page[1] === 'buildout' ? ` ${classes.active}` : '')}
              component={LinkBtn}
              to={{pathname: "/stats",
              DSPView: true}}
            >
              <ListItemIcon className={classes.icon}>
                <TrendingUpIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.primary }} invariant="inset" primary={'Stats'} />
            </ListItem>
          </div>
        }
        {
          (LEVEL === 1 || LEVEL === '1') &&
          <div>
            <ListItem
              button
              className={classNames(classes.head, page[1] === 'system-setting' || page[1] === 'brokerdealer-admin' || page[1] === 'create-broker-admin' || page[1] === 'create-underwriter-admin' || page[1] === 'underwriter-admin' || page[1] === 'create-brokerdealer' || page[1] === 'create-underwriter' || page[1] === 'create-message' || page[1] === 'update-message' ? ` ${classes.active}` : '')}
              component={LinkBtn}
              to={{pathname: "/system-setting",
              DSPView: true}}
            >
              <ListItemIcon className={classes.icon}>
                <SettingsApplicationsIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.primary }} invariant="inset" primary={'System setting'} />
            </ListItem>
          </div>
        }
        {
          (LEVEL === 2 || LEVEL === '2') &&
          <div>
            <ListItem
              button
              className={classNames(classes.head, page[1] === 'customers' || page[1] === 'customer-graph' ? ` ${classes.active}` : '')}
              component={LinkBtn}
              to={{pathname: "/customers",
              DSPView: true}}
            >
              <ListItemIcon className={classes.icon}>
                <BookmarksRoundedIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.primary }} invariant="inset" primary={'Customers'} />
            </ListItem>
          </div>
        }
        <ListItem
          button
          className={classes.head}
          onClick={logout}
        >
          <ListItemIcon className={classes.icon}>
            <ExitToAppRoundedIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} variant="inset" primary={'Sign Out'} />
        </ListItem>
      </div>
    );
  }
}

MainMenu.propTypes = {
  classes: PropTypes.object,
  toggleDrawerOpen: PropTypes.func
};

const mapStateToProps = state => ({
  force: state, // force active class for sidebar menu
});

const mapDispatchToProps = dispatch => ({
  
});

const MainMenuMapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainMenu);

export default withStyles(styles)(MainMenuMapped);
