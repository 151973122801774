import * as types from './actionTypes';  
import archiveOfferingApi from '../api/archiveOfferingApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doArchiveOffering(ext_id, status) {

  return {
    type: types.DO_ARCHIVE_OFFERING, 
    ext_id,
    status
  };
}

export function doArchiveOfferingRes(data) {  
  return {
    type: types.DO_ARCHIVE_OFFERING_RES, 
    data
  };
}

export function sendArchiveOffering(ext_id) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      archiveOfferingApi.doArchiveOffering(ext_id).then(data => {
        dispatch(doArchiveOfferingRes(data));
        dispatch(doArchiveOfferingRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
  
}

export function doUnarchiveOffering(ext_id, status) {

  return {
    type: types.DO_UNARCHIVE_OFFERING, 
    ext_id,
    status
  };
}

export function doUnarchiveOfferingRes(data) {  
  return {
    type: types.DO_UNARCHIVE_OFFERING_RES, 
    data
  };
}

export function sendUnarchiveOffering(ext_id) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      archiveOfferingApi.doUnarchiveOffering(ext_id).then(data => {
        dispatch(doUnarchiveOfferingRes(data));
        dispatch(doUnarchiveOfferingRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
  
}