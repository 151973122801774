import * as types from './actionTypes';  
import notifyUserApi from '../api/notifyUserApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doNotifyUser(payload) {

  return {
    type: types.DO_NOTIFY_USER, 
    payload
  };
}

export function doNotifyUserRes(data) {  
  return {
    type: types.DO_NOTIFY_USER_RES, 
    data
  };
}

export function sendNotifyUser(payload) {
  const TOKEN = getItem('auth_token');
  
  if(TOKEN){
    var files = payload.attachments;
    if (files) {
      files = files[0];
    } else {
      files = null;
    }
    // let fileAttachments = [];
    let attachmentsFile = [];
    if (files) {
      return function(dispatch) {
        dispatch(loadingRes(true));
        notifyUserApi.doAttachment(payload.attachments).then(fileRes => {
          if (fileRes.message === 'Success') {
            attachmentsFile.push(fileRes.data.url);
            payload.attachments = attachmentsFile;
            try {
              notifyUserApi.doNotifyUser(payload).then(data => {
                dispatch(doNotifyUserRes(data));
                dispatch(doNotifyUserRes(null));
                dispatch(loadingRes(false));
                if(data.error){
                  Swal.fire({
                    title: data.message,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false,
                    timer: 3000,
                    heightAuto: false
                  });
                  dispatch(loadingRes(false));
                }
              }).catch(error => {
                dispatch(loadingRes(false));
                return error
              });
            } catch (err) {

            }
          }
          if(fileRes.error){
            Swal.fire({
              title: fileRes.message,
              icon: 'error',
              confirmButtonText: 'OK',
              allowOutsideClick: false,
              timer: 3000,
              heightAuto: false
            });
            dispatch(loadingRes(false));
          }
        }).catch(error => {
          dispatch(loadingRes(false));
          return error
        });
      };
    } else {
      return function(dispatch) {
        dispatch(loadingRes(true));
        payload.attachments = [];
        notifyUserApi.doNotifyUser(payload).then(data => {
          dispatch(doNotifyUserRes(data));
          dispatch(doNotifyUserRes(null));
          dispatch(loadingRes(false));
          if(data.error){
            Swal.fire({
              title: data.message,
              icon: 'error',
              confirmButtonText: 'OK',
              allowOutsideClick: false,
              timer: 3000,
              heightAuto: false
            });
          }
        }).catch(error => {
          dispatch(loadingRes(false));
          return error
        });
      };
      
    }
  }else{
    logout()
  }
  
}