
import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Dashboard from '../../../../component/Dashboard';
import CreateBrokerDealerForm from './forms';
import { submitCreateAdminForm, doCreateAdminRes } from '../../../../actions/createAdminActions';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

class CreateBrokerAdmin extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      borkerInfo: {}
    }
  }
  componentDidMount() {
    let brokerData = this.props.location.state.borkerAllData;
    this.setState({
      borkerInfo: brokerData,
    });
    console.log(brokerData)
  }
  
  static getDerivedStateFromProps(props, state) {
		if (props.createAdminRes) {
      if (props.createAdminRes.data && props.createAdminRes.data.createAdmin) {
        if (props.createAdminRes.data.createAdmin.message === 'Success') {
          props.goBack();
        }
      }
    }
		return null
	}
  submitForm = (data) => {
    data.organization_name = this.state.borkerInfo.name;
    data.type = this.state.borkerInfo.type;
    data.organization_mpid = this.state.borkerInfo.mpid;
    this.props.handleFormSubmit(data);
  }
  render() {
    const { goBack } = this.props;
    return (
      <Dashboard title="Create BrokerDealer User" >
        <div className="admin_offering_content">
        <div>
          <h3 style={{ marginLeft: '16px', cursor: 'pointer', color: '#8DC73F'}}>
            <ArrowBackIosIcon onClick={goBack} style={{ cursor: 'pointer' }} />  Add BrokerDealer User 
          </h3>
        </div>
          <CreateBrokerDealerForm handleFormSubmit={(data) => this.submitForm(data)} />
        </div>
      </Dashboard>
    );
  }
}

CreateBrokerAdmin.propTypes = {
  goBack: PropTypes.func,
  handleFormSubmit: PropTypes.func,
  createAdminRes: PropTypes.any,
};


const mapStateToProps = createStructuredSelector({
  createAdminRes: doCreateAdminRes,
});


function mapDispatchToProps(dispatch, ownProps) {
  return {
    goBack: () => { ownProps.history.goBack(); },
    handleFormSubmit: (data) => dispatch(submitCreateAdminForm(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBrokerAdmin);
